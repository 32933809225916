import * as React from 'react';
import { useContext } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Select, { components, OptionProps, SingleValueProps } from 'react-select';
import { IUser } from '../../Users/UsersTable';
import { DomainContext, IOffice } from '../../../contexts/DomainContext';
import { FileContext, IFile } from '../FileContext';
import { lawyerSelectValue } from '../../../formatters/PeopleFormatter';
import { PeopleContext } from '../../../contexts/PeopleContext';
import { MyUserContext } from '../../../contexts/MyUserContext';

export const CounselRequestLawyer: React.FC<{ trySubmit: boolean }> = (props) => {
  const fileContext = useContext(FileContext);
  const myUserContext = useContext(MyUserContext);
  const setFile = fileContext.setFile;
  const domainContext = useContext(DomainContext);
  const offices = domainContext.offices;
  const peopleContext = useContext(PeopleContext);

  const LawyerOption = (props: OptionProps<IUser, boolean>) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <div>{lawyerSelectValue(data, domainContext.offices)}</div>
      </components.Option>
    );
  };

  const LawyerSingleValue = (props: SingleValueProps<IUser>) => {
    const { data } = props;
    return <components.SingleValue {...props}>{lawyerSelectValue(data, domainContext.offices)}</components.SingleValue>;
  };

  return (
    <Card className={'mb-3'}>
      <CardHeader>Counsel Request Lawyer</CardHeader>
      <CardBody className={'d-flex flex-column'}>
        <Select
          name={'lawyerId'}
          isDisabled={myUserContext.isLawyer}
          className={'mb-3'}
          options={
            peopleContext.lawyers
              .filter((l) => myUserContext.isIntakeOffice || l.officeId === myUserContext.officeId)
              .sort((a: IUser, b: IUser) => a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase())) as IUser[]
          }
          value={peopleContext.lawyers.find((i: IUser) => i.userId === fileContext.file.lawyerId)}
          onChange={(value: any) => setFile((f: IFile) => ({ ...f, lawyerId: value?.userId || '' }))}
          getOptionValue={(option: IUser) => option.userId}
          getOptionLabel={(option: IUser) =>
            option.lastName.toUpperCase() +
            ', ' +
            option.firstName +
            ' (' +
            offices.find((o: IOffice) => o.officeId === option.officeId)?.name +
            ')'
          }
          components={{ SingleValue: LawyerSingleValue, Option: LawyerOption }}
          styles={{
            singleValue: (base) => ({
              ...base,
              position: 'relative',
              top: 0,
              transform: 'translateY(0)',
              height: '100%',
              padding: '0.25em 0',
            }),
          }}
          isClearable={true}
          menuPlacement={'auto'}
        />
        {props.trySubmit && !fileContext.file.lawyerId && (
          <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
        )}
      </CardBody>
    </Card>
  );
};
