import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as React from 'react';

interface IConfirmModalProps {
  isOpen: boolean;
  title: string;
  confirmButton?: string;
  cancelButton?: string;
  onConfirm: () => void;
  onCancel: () => void;
  onDismiss?: () => void;
  confirmButtonColor?: 'warning' | 'danger' | 'primary';
}

export const ConfirmModal: React.FC<IConfirmModalProps> = (props) => {
  const dismiss = () => {
    if (props.onDismiss) {
      props.onDismiss();
    } else {
      props.onCancel();
    }
  };

  return (
    <Modal isOpen={props.isOpen}>
      <ModalHeader toggle={dismiss}>{props.title}</ModalHeader>
      <ModalBody>{props.children}</ModalBody>
      <ModalFooter>
        <Button color={props.confirmButtonColor ?? 'success'} onClick={props.onConfirm}>
          {props.confirmButton || 'Confirm'}
        </Button>
        <Button color='light' onClick={props.onCancel}>
          {props.cancelButton || 'Cancel'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
