import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import * as React from 'react';
import { useContext, useState } from 'react';
import { FileContext, IExtensionRequest, REMINDER_TYPE_EXTENSION_REQUESTED } from '../FileContext';
import { authAxios } from '../../../services/AxiosService';
import { formatDateForServer, formatDateTime } from '../../../formatters/DateTimeFormatter';
import { notifySuccess } from '../../../services/ToastService';
import { useParams } from 'react-router';
import DatePicker from 'react-datepicker';

export const ExtensionRequestAction = () => {
  const fileContext = useContext(FileContext);
  const [newExpiryDate, setNewExpiryDate] = useState<Date | undefined>(undefined);
  const [tryApprove, setTryApprove] = useState(false);
  const [tryDeny, setTryDeny] = useState(false);
  const [reasonForRejection, setReasonForRejection] = useState('');
  const params = useParams<{ fileId: string }>();

  const approveExtension = (extensionRequestId: string) => {
    if (newExpiryDate !== undefined) {
      //approve
      authAxios
        .post('/api/files/admin/approve-extension', {
          fileId: params.fileId,
          extensionRequestId: extensionRequestId,
          newExpiryDate: formatDateForServer(newExpiryDate),
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            notifySuccess('Request Approved.');
            const updatedExtensions = fileContext.extensionRequests.map((e) => {
              if (e.extensionRequestId === extensionRequestId) {
                return {
                  ...e,
                  approved: true,
                  newExpiryDate: newExpiryDate ? formatDateForServer(newExpiryDate) : undefined,
                } as IExtensionRequest;
              } else {
                return e;
              }
            });
            fileContext.setExtensionRequests(updatedExtensions);
            setNewExpiryDate(undefined);
            setReasonForRejection('');
            fileContext.setActiveOnscreenReminders((s) =>
              s.filter((s) => s.reminderTypeId !== REMINDER_TYPE_EXTENSION_REQUESTED)
            );
            fileContext.setExpiryDate(newExpiryDate);
          }
        });
    } else {
      setTryApprove(true);
    }
  };

  const denyExtension = (extensionRequestId: string) => {
    if (reasonForRejection !== '') {
      //deny
      authAxios
        .post('/api/files/admin/deny-extension', {
          fileId: params.fileId,
          extensionRequestId: extensionRequestId,
          reasonForRejection: reasonForRejection,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            notifySuccess('Request Denied.');
            const updatedExtensions = fileContext.extensionRequests.map((e) => {
              if (e.extensionRequestId === extensionRequestId) {
                return {
                  ...e,
                  approved: false,
                  reasonForRejection: reasonForRejection,
                } as IExtensionRequest;
              } else {
                return e;
              }
            });
            fileContext.setExtensionRequests(updatedExtensions);
            setNewExpiryDate(undefined);
            setReasonForRejection('');
            fileContext.setActiveOnscreenReminders((s) =>
              s.filter((s) => s.reminderTypeId !== REMINDER_TYPE_EXTENSION_REQUESTED)
            );
          }
        });
    } else {
      setTryDeny(true);
    }
  };

  return (
    <Card className={'bg-primary-light'} style={{ width: '100%' }}>
      <CardHeader>Extension Requests</CardHeader>
      <CardBody className={'p-0'}>
        {fileContext.extensionRequests.length > 0 && (
          <ListGroup flush={true}>
            <ListGroupItem>
              <Row>
                <Col sm={3}>Reason</Col>
                <Col sm={2}>Date Requested</Col>
                <Col sm={7}>Action / Status</Col>
              </Row>
            </ListGroupItem>
            {fileContext.extensionRequests.map((er) => {
              return (
                <ListGroupItem key={er.extensionRequestId}>
                  <Row>
                    <Col sm={3}>{er.reasonForExtension}</Col>
                    <Col sm={2}>{formatDateTime(new Date(er.timestamp))}</Col>
                    <Col sm={7}>
                      {er.approved === undefined ? (
                        <Row>
                          <Col>
                            <Row>
                              <FormGroup className={'w-100 mr-1'}>
                                <Label for={'expiry-date'}>Updated Expiry Date</Label>
                                <DatePicker
                                  selected={newExpiryDate}
                                  name={'expiry-date'}
                                  onChange={(date) => {
                                    if (date) {
                                      setNewExpiryDate(date);
                                    }
                                  }}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dateFormat='MMM dd, yyyy'
                                  dropdownMode='select'
                                  className={'date-picker rounded mb-0'}
                                  placeholderText={'MMM dd, yyyy'}
                                />
                                {tryApprove && newExpiryDate === undefined && (
                                  <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                                    This field is mandatory
                                  </span>
                                )}
                              </FormGroup>
                            </Row>
                            <Row>
                              <Button color={'success'} onClick={() => approveExtension(er.extensionRequestId)}>
                                Extend
                              </Button>
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <FormGroup className={'w-100 mx-1'}>
                                <Label for={'expiry-date'}>Reason For Rejection</Label>
                                <Input
                                  value={reasonForRejection}
                                  type={'text'}
                                  autoComplete={'off'}
                                  name={'Reason For Rejection'}
                                  onChange={(e: any) => {
                                    setReasonForRejection(e.target.value);
                                  }}
                                />
                                {tryDeny && reasonForRejection === '' && (
                                  <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                                    This field is mandatory
                                  </span>
                                )}
                              </FormGroup>
                            </Row>
                            <Row>
                              <Button
                                className={'ml-2'}
                                color={'danger'}
                                onClick={() => denyExtension(er.extensionRequestId)}
                              >
                                Deny
                              </Button>
                            </Row>
                          </Col>
                        </Row>
                      ) : (
                        <span>
                          {er.approved
                            ? 'Approved to: ' + (er.newExpiryDate ? er.newExpiryDate : '')
                            : 'Denied: ' + er.reasonForRejection}
                        </span>
                      )}
                    </Col>
                  </Row>
                </ListGroupItem>
              );
            })}
          </ListGroup>
        )}
      </CardBody>
    </Card>
  );
};
