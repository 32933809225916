const dateOptionsDay = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  weekday: 'short',
} as const;

const dateOptions = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
} as const;

const dateOptionsForServer = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
} as const;

const dateTimeOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  hour: '2-digit',
  minute: 'numeric',
  hour12: true,
} as const;

const monthOptions = {
  month: 'long',
} as const;

export const formatMonth = (date?: Date) => {
  if (!date) {
    return '';
  }

  return new Intl.DateTimeFormat('en-CA', monthOptions).format(date);
};

export const formatDateFromISONumber = (isoDateTime: number) => {
  if (!isoDateTime) {
    return '';
  }

  const date = new Date(isoDateTime);
  return new Intl.DateTimeFormat('en-CA', dateOptions).format(date).replace('.', '');
};

export const formatDateFromISO = (isoDateTime: string) => {
  if (!isoDateTime) {
    return '';
  }

  const date = new Date(isoDateTime);
  return new Intl.DateTimeFormat('en-CA', dateOptions).format(date).replace('.', '');
};

export const formatDate = (date?: Date, noDateText?: string) => {
  if (!date) {
    return noDateText || '';
  }

  return new Intl.DateTimeFormat('en-CA', dateOptions).format(date).replace('.', '');
};

export const formatDateDayOfWeek = (date?: Date, noDateText?: string) => {
  if (!date) {
    return noDateText || '';
  }

  return new Intl.DateTimeFormat('en-CA', dateOptionsDay).format(date).replace('.', '');
};

export const formatDateForServer = (date?: Date) => {
  if (!date) {
    return '';
  }

  return new Intl.DateTimeFormat('fr-CA', dateOptionsForServer).format(date);
};

export const formatDateTime = (date?: Date) => {
  if (!date) {
    return '';
  }

  return new Intl.DateTimeFormat('en-US', dateTimeOptions).format(date).replace('.', '');
};
