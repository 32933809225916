import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Navbar, NavbarBrand } from 'reactstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FaChevronDown, FaChevronUp, FaPlus } from 'react-icons/fa';
import { Column, TableInstance, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import {
  DomainContext,
  IStage,
  STAGE_COUNSEL_REQUEST,
  STAGE_LAWYER_REQUESTED,
  STAGE_RETURN_FOR_CONFLICT,
} from 'src/contexts/DomainContext';

import { useDefaultColumn } from 'src/hooks/ReactTableHooks';
import { DropdownMultiSelectCheckboxes } from '../DropdownMultiSelectCheckboxes/DropdownMultiSelectCheckboxes';
import { TablePagination } from '../TablePagination/TablePagination';
import { MyUserContext } from 'src/contexts/MyUserContext';
import { FilesContext } from 'src/contexts/FilesContext';
import { INonClientFile } from './NonClientFileContext';
import { authAxios } from 'src/services/AxiosService';
import { DateRangeDropdown } from '../DateRangeDropdown/DateRangeDropdown';
import { PersistantTableGlobalFilter } from '../TableGlobalFilter/PersistantTableGlobalFilter';
import { NonClientFilesTableContext } from './NonClientFilesTableContext';

interface IProps {
  selectRow?: (userId: string) => void;
}

export const NonClientFilesTable: React.FC<IProps> = (props) => {
  const [reportData, setReportData] = useState<INonClientFile[]>([]);
  const domainContext = useContext(DomainContext);
  const myUserContext = useContext(MyUserContext);
  const filesContext = useContext(FilesContext);
  const searchContext = useContext(NonClientFilesTableContext);

  const stages = domainContext.stages;
  const history = useHistory();
  const location = useLocation<{ deletedFileId?: string }>();

  useEffect(() => {
    authAxios.get('/api/non-client-files').then((response) => {
      setReportData(response.data);
    });
  }, []);
  const deletedFileId = location?.state?.deletedFileId;

  const legalAssistantColumns: Column<INonClientFile>[] = useMemo(
    () => [
      {
        Header: 'Law Office Number',
        accessor: 'lawOfficeNumber',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'File Date',
        id: 'fileDate',
        accessor: 'fileDateFormatted',
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          if (rowA.original['fileDate'] && rowB.original['fileDate']) {
            if (rowA.original['fileDate'] > rowB.original['fileDate']) return 1;
            if (rowB.original['fileDate'] > rowA.original['fileDate']) return -1;
            return 0;
          } else {
            return 0;
          }
        },
        filter: (rows: any, id: any, filterValue: { startDate?: Date; endDate?: Date }) => {
          return rows.filter((row: any) => {
            if (!filterValue.startDate || !filterValue.endDate) {
              return true;
            } else {
              const rowValue = row.original['fileDate'];
              return rowValue > filterValue.startDate.getTime() && rowValue < filterValue.endDate.getTime();
            }
          });
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => (
          <DateRangeDropdown
            startDate={filterValue?.startDate}
            endDate={filterValue?.endDate}
            setRange={(dates: { startDate?: Date; endDate?: Date }) => {
              setFilter(dates);
            }}
            keyPrefix={'fileDate'}
          />
        ),
      },
      {
        Header: 'Stage',
        id: 'stageId',
        accessor: (file: INonClientFile) => {
          return stages.find((s: IStage) => s.stageId === file.stageId)?.name;
        },
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          const rowAStage = stages.find((s: IStage) => s.stageId === rowA.original[id]);
          const rowBStage = stages.find((s: IStage) => s.stageId === rowB.original[id]);

          if (rowAStage && rowBStage && rowAStage.order > rowBStage.order) return 1;
          if (rowAStage && rowBStage && rowBStage.order > rowAStage.order) return -1;
          return 0;
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue || []}
              keyPrefix='stageId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={stages.map((s: IStage) => ({
                value: s.stageId,
                display: s.name,
              }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['stageId'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
      },
    ],
    [stages]
  );

  const data: INonClientFile[] = useMemo(() => {
    return reportData.filter((f: any) => f.fileId !== deletedFileId);
  }, [deletedFileId, reportData]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, filters, globalFilter },
    setAllFilters,
  }: TableInstance<INonClientFile> = useTable(
    {
      columns: legalAssistantColumns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        filters: searchContext.filters,
        globalFilter: searchContext.selectedGlobalSearch,
        sortBy: [
          {
            id: 'fileDate',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    searchContext.setFilters(filters);
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const selectRow = (fileId: string) => {
    if (props.selectRow) {
      props.selectRow(fileId);
    } else {
      history.push('/non-client-files/' + fileId);
    }
  };

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'}>
        <NavbarBrand className='mr-auto'>Non Client Files</NavbarBrand>
        <Button
          className={'mr-2'}
          color={'primary'}
          tag={Link}
          onClick={() => {
            searchContext.setSelectedGlobalSearch('');
            setGlobalFilter('');
            setAllFilters([]);
          }}
        >
          Clear Filters
        </Button>
        {(myUserContext.isLegalAssistant || myUserContext.isIntakeOffice) && (
          <Button color={'success'} className={'ml-2'} tag={Link} to={{ pathname: '/non-client-files/add' }}>
            <FaPlus className='mr-2' />
            Add Non Client File
          </Button>
        )}
      </Navbar>
      {filesContext.state.type === 'FILE_STATE_LOADED' ? (
        <div className={'table-responsive'} style={{ minHeight: '800px' }}>
          <table className={'table table-bordered table-hover'} {...getTableProps()}>
            <thead style={{ overflow: 'visible' }}>
              {headerGroups.map((headerGroup: any, index: number) => (
                <tr key={`files-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      key={`files-table-thead-tr-${index}-${column.id}`}
                      width={column.width}
                      style={{ maxWidth: column.width }}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                      <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
              <tr>
                <PersistantTableGlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  colSpan={visibleColumns.length}
                  value={searchContext.selectedGlobalSearch}
                  setValue={searchContext.setSelectedGlobalSearch}
                />
              </tr>
            </thead>
            <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
              {page.map((row: any) => {
                prepareRow(row);
                return (
                  <tr
                    key={`files-table-tr-${row.id}`}
                    {...row.getRowProps()}
                    className={
                      (myUserContext.role === 'LegalAssistant' || myUserContext.role === 'Lawyer') &&
                      row.original.stageId === STAGE_LAWYER_REQUESTED
                        ? 'bg-primary-medium'
                        : (myUserContext.role === 'Intake' || myUserContext.role === 'SuperAdmin') &&
                          (row.original.stageId === STAGE_COUNSEL_REQUEST ||
                            row.original.stageId === STAGE_RETURN_FOR_CONFLICT)
                        ? 'bg-primary-medium'
                        : ''
                    }
                  >
                    {row.cells.map((cell: any) => {
                      return (
                        <td
                          key={`files-table-td-${cell.row.id}-${cell.column.id}`}
                          {...cell.getCellProps()}
                          onClick={() => selectRow(row.original.fileId)}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div>Loading</div>
      )}
      <TablePagination
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizes={[20, 50, 100, 500]}
        recordCount={rows.length}
      />
    </div>
  );
};
