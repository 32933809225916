import * as React from 'react';
import { FormEvent, useContext, useEffect, useState } from 'react';
import { Button, Col, Container, CustomInput, FormGroup, Input, Label, Navbar, NavbarBrand, Row } from 'reactstrap';
import { authAxios } from '../../services/AxiosService';
import { useParams } from 'react-router';
import Select from 'react-select';
import { formatDateTime } from '../../formatters/DateTimeFormatter';
import { notifyError, notifySuccess } from '../../services/ToastService';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { useHistory } from 'react-router-dom';
import { DomainContext, IDocumentType } from '../../contexts/DomainContext';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import { IDocument } from './Documents';
import { downloadDocument } from './DocumentService';
import { IUser } from '../Users/UsersTable';
import { PeopleContext } from '../../contexts/PeopleContext';
import { FileContext } from '../Files/FileContext';
import { MyUserContext } from '../../contexts/MyUserContext';

export const FileDocumentDetails: React.FC = () => {
  const params = useParams<{ fileId: string; documentId: string }>();
  const [doc, setDoc] = useState<IDocument | undefined>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isConfirmDownloadOpen, setIsConfirmDownloadOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const history = useHistory();
  const domainContext = useContext(DomainContext);
  const peopleContext = useContext(PeopleContext);
  const myUserContext = useContext(MyUserContext);
  const documentTypes = domainContext.documentTypes;
  const fileContext = useContext(FileContext);

  useEffect(() => {
    authAxios.get('/api/files/' + params.fileId + '/documents/' + params.documentId).then((response) => {
      if (response.data) {
        setDoc(response.data);
      }
    });
  }, [params.fileId, params.documentId]);

  const disabledSelectStyles = {
    control: (styles: any, state: any) => {
      return {
        ...styles,
        borderColor: '#ced4da',
      };
    },
    singleValue: (styles: any, state: any) => {
      return {
        ...styles,
        color: '#495057',
      };
    },
  };

  const canEditDoc = () => {
    if (myUserContext.isLegalAssistant || myUserContext.isLawyer) {
      return doc?.uploadedByRole === 'LegalAssistant' || doc?.uploadedByRole === 'Lawyer';
    } else if (myUserContext.isSuperAdmin) {
      return doc?.uploadedByRole === 'SuperAdmin';
    } else if (myUserContext.isSupportWorker) {
      return false;
    } else {
      return false;
    }
  };

  const saveDoc = () => {
    const data = {
      fileId: params.fileId,
      documentId: params.documentId,
      documentTypeId: doc?.documentTypeId,
      description: doc?.description,
      authorizedSupportWorkers: doc?.authorizedSupportWorkers,
      privileged: doc?.privileged,
    };
    authAxios.put('/api/documents/update-document', data).then((response) => {
      if (response.data.status === 'OK') {
        setIsDirty(false);
        notifySuccess('File updated.');
      } else {
        notifyError(response.data.errorMessage);
      }
    });
  };

  const deleteDoc = () => {
    const data = {
      fileId: params.fileId,
      documentId: params.documentId,
    };
    authAxios.post('/api/documents/remove-file-document', data).then((response) => {
      if (response.data.status === 'OK') {
        notifySuccess('Document deleted.');
        history.push({
          pathname: '/files/' + params.fileId + '/documents',
          state: { deletedDocumentIds: [params.documentId] },
        });
      } else {
        notifyError(response.data.errorMessage);
      }
    });
  };

  return (
    <>
      <LeavePageConfirm isDirty={isDirty} />
      <div>
        <form
          onSubmit={(e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }}
        >
          <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
            <Row className={'flex-fill'}>
              <Col md={3} xl={2}>
                <NavbarBrand>Document Details</NavbarBrand>
              </Col>
              {canEditDoc() && (
                <Col className={'d-flex'}>
                  <Button className={'ml-auto'} color={'danger'} onClick={() => setIsDeleteModalOpen(true)}>
                    Delete
                  </Button>
                  <Button className={'ml-2'} color={'success'} onClick={() => saveDoc()}>
                    Save Details
                  </Button>
                </Col>
              )}
            </Row>
          </Navbar>
          <Container fluid={true} className={'my-3'}>
            <Row>
              <Col md={{ size: 6, offset: 3 }}>
                <FormGroup>
                  <Label>File Name</Label>
                  <Input
                    type='text'
                    placeholder='Type here...'
                    name='docFileName'
                    disabled={true}
                    value={doc?.fileName}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Document Type</Label>
                  <Select
                    styles={disabledSelectStyles}
                    name={'documentTypeId'}
                    options={documentTypes}
                    placeholder={<span className='placeholder'>Select...</span>}
                    value={documentTypes.find((d: IDocumentType) => d.documentTypeId === doc?.documentTypeId)}
                    onChange={(value: any) => {
                      setIsDirty(true);
                      setDoc((d) =>
                        d
                          ? {
                              ...d,
                              documentTypeId: value.documentTypeId,
                            }
                          : undefined
                      );
                    }}
                    getOptionValue={(option: IDocumentType) => option.documentTypeId}
                    getOptionLabel={(option: IDocumentType) => option.name}
                    isDisabled={!canEditDoc()}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Document Description</Label>
                  <Input
                    type='text'
                    placeholder='Type here...'
                    name='docDescription'
                    value={doc?.description}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const desc = e.target.value;
                      setIsDirty(true);
                      setDoc((d) => (d ? { ...d, description: desc } : undefined));
                    }}
                    disabled={!canEditDoc()}
                  />
                </FormGroup>
                {doc?.uploadedByRole !== 'SuperAdmin' && (
                  <FormGroup>
                    <Label for={'authorizedSupportWorkers'}>Authorized Support Workers</Label>
                    <Select
                      classNamePrefix={'user-select'}
                      id={'authorizedSupportWorkers'}
                      name={'authorizedSupportWorkers'}
                      options={
                        peopleContext.supportWorkers
                          .filter((sw) => fileContext.supportWorkers.includes(sw.userId) && sw.active)
                          .sort((a: IUser, b: IUser) =>
                            a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase())
                          ) as IUser[]
                      }
                      value={peopleContext.supportWorkers.filter((i: IUser) =>
                        doc?.authorizedSupportWorkers?.includes(i.userId)
                      )}
                      onChange={(value: any) => {
                        setIsDirty(true);
                        setDoc((d) =>
                          d
                            ? {
                                ...d,
                                authorizedSupportWorkers: value ? value.map((u: any) => u.userId) : [],
                              }
                            : undefined
                        );
                      }}
                      getOptionValue={(option: IUser) => option.userId}
                      getOptionLabel={(option: IUser) =>
                        option.lastName.toUpperCase() + ', ' + option.firstName + (option.active ? '' : ' (Inactive)')
                      }
                      isClearable={true}
                      isMulti={true}
                      isDisabled={!canEditDoc()}
                      menuPlacement={'auto'}
                    />
                  </FormGroup>
                )}
                <FormGroup>
                  <Label>Uploaded By</Label>
                  <Input
                    type='text'
                    disabled={true}
                    placeholder='Type here...'
                    name='uploadedBy'
                    value={doc?.uploadedBy}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Uploaded Date</Label>
                  <Input
                    type='text'
                    placeholder='Type here...'
                    name='uploadDate'
                    disabled={true}
                    value={doc?.uploadDate ? formatDateTime(new Date(doc.uploadDate)) : ''}
                  />
                </FormGroup>
                {canEditDoc() && (
                  <div>
                    <FormGroup className={'mb-1'}>
                      <CustomInput
                        type={'checkbox'}
                        id={'privileged'}
                        key={'privileged'}
                        name={'privileged'}
                        label={'Privileged'}
                        checked={doc?.privileged}
                        disabled={!canEditDoc()}
                        onChange={() => {
                          setDoc((d) =>
                            d
                              ? {
                                  ...d,
                                  privileged: !d.privileged,
                                }
                              : undefined
                          );
                          setIsDirty(true);
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Privileged documents will not be included if the file is transferred to another lawyer.{' '}
                      </Label>
                    </FormGroup>
                  </div>
                )}
                <FormGroup>
                  <Button
                    className={'ml-auto'}
                    color={'primary'}
                    onClick={() => {
                      if (doc?.uploadId) {
                        setIsConfirmDownloadOpen(true);
                      }
                    }}
                  >
                    Download
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
      <ConfirmModal
        isOpen={isDeleteModalOpen}
        title={'Close File'}
        onConfirm={() => {
          setIsDeleteModalOpen(false);
          deleteDoc();
        }}
        onCancel={() => setIsDeleteModalOpen(false)}
        confirmButton={'Delete Document'}
        confirmButtonColor={'danger'}
      >
        <div>Are you sure you want to delete this document?</div>
      </ConfirmModal>
      <ConfirmModal
        isOpen={isConfirmDownloadOpen}
        title={'Download Document'}
        onConfirm={() => {
          if (doc?.uploadId) {
            downloadDocument(fileContext.file.fileId, doc?.documentId);
          }
          setIsConfirmDownloadOpen(false);
        }}
        onCancel={() => setIsConfirmDownloadOpen(false)}
        confirmButton={'Download'}
        confirmButtonColor={'primary'}
      >
        <div>
          You are downloading private and confidential records of YLSS. These documents should not be copied,
          distributed or reproduced.
        </div>
      </ConfirmModal>
    </>
  );
};
