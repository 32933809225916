import * as React from 'react';
import { useContext, useMemo, useState } from 'react';
import {
  DomainContext,
  STAGE_ASSIGNED_TO_LAWYER,
  STAGE_BOARD_REFUSED,
  STAGE_CANCELLED_COUNSEL_REQUEST,
  STAGE_CLOSED,
  STAGE_COUNSEL_REQUEST,
  STAGE_COUNSEL_REQUEST_DRAFT,
  STAGE_ERROR,
  STAGE_LAWYER_REQUESTED,
  STAGE_REFUSED,
  STAGE_STOPPED,
} from '../../contexts/DomainContext';
import { MyUserContext } from '../../contexts/MyUserContext';

type ContextType = {
  selectedGlobalSearch?: string;
  setSelectedGlobalSearch: React.Dispatch<React.SetStateAction<string | undefined>>;
  filters?: { id: string; value: any }[];
  defaultFilters: { id: string; value: any }[];
  setFilters: React.Dispatch<React.SetStateAction<{ id: string; value: any }[]>>;
  includeClosed: boolean;
  setIncludeClosed: React.Dispatch<React.SetStateAction<boolean>>;
};

export const FilesTableContext = React.createContext<ContextType>({
  selectedGlobalSearch: undefined,
  setSelectedGlobalSearch: () => {},
  filters: [],
  defaultFilters: [],
  setFilters: () => {},
  includeClosed: false,
  setIncludeClosed: () => {},
});

export const FilesTableProvider = (props: any) => {
  const [selectedGlobalSearch, setSelectedGlobalSearch] = useState<string | undefined>('');

  const myUserContext = useContext(MyUserContext);
  const domainContext = useContext(DomainContext);
  const stages = domainContext.stages;
  const [includeClosed, setIncludeClosed] = useState(false);

  const defaultFiltersIntake: { id: string; value: string[] }[] = useMemo(() => {
    return [
      {
        id: 'stageId',
        value: stages
          .map((s) => s.stageId)
          .filter(
            (sId) =>
              sId !== STAGE_CLOSED &&
              sId !== STAGE_COUNSEL_REQUEST_DRAFT &&
              sId !== STAGE_ERROR &&
              sId !== STAGE_CANCELLED_COUNSEL_REQUEST &&
              sId !== STAGE_REFUSED &&
              sId !== STAGE_BOARD_REFUSED &&
              sId !== STAGE_STOPPED
          ),
      },
    ];
  }, [stages]);

  const defaultFiltersLawOffice = [
    {
      id: 'stageId',
      value: [STAGE_LAWYER_REQUESTED, STAGE_ASSIGNED_TO_LAWYER, STAGE_COUNSEL_REQUEST_DRAFT, STAGE_COUNSEL_REQUEST],
    },
  ];

  const defaultFiltersSupportWorkers = [
    {
      id: 'stageId',
      value: [STAGE_ASSIGNED_TO_LAWYER],
    },
  ];

  const [filters, setFilters] = useState<{ id: string; value: any }[]>(
    myUserContext.isSupportWorker
      ? defaultFiltersSupportWorkers
      : myUserContext.isLegalAssistant || myUserContext.isLawyer
      ? defaultFiltersLawOffice
      : defaultFiltersIntake
  );

  const defaultFilters = myUserContext.isSupportWorker
    ? defaultFiltersSupportWorkers
    : myUserContext.isLegalAssistant || myUserContext.isLawyer
    ? defaultFiltersLawOffice
    : defaultFiltersIntake;

  return (
    <FilesTableContext.Provider
      value={{
        selectedGlobalSearch,
        setSelectedGlobalSearch,
        filters,
        defaultFilters,
        setFilters,
        includeClosed,
        setIncludeClosed,
      }}
    >
      {props.children}
    </FilesTableContext.Provider>
  );
};

export const FilesTableConsumer = FilesTableContext.Consumer;
