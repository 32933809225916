import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Container, CustomInput, Modal, ModalBody, ModalHeader, Navbar, NavbarBrand } from 'reactstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FaChevronDown, FaChevronUp, FaPlus } from 'react-icons/fa';
import {
  FilterValue,
  TableInstance,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import {
  STAGE_COUNSEL_REQUEST,
  STAGE_LAWYER_REQUESTED,
  STAGE_RETURN_FOR_CONFLICT,
  STAGE_CLOSED,
  STAGE_COUNSEL_REQUEST_DRAFT,
  STAGE_ERROR,
  STAGE_REFUSED,
  STAGE_BOARD_REFUSED,
  STAGE_STOPPED,
} from '../../contexts/DomainContext';
import { IFile, IReminder, REMINDER_TYPE_EXTENSION_REQUESTED, REMINDER_TYPE_FILE_EXPIRY } from '../Files/FileContext';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { TablePagination } from '../TablePagination/TablePagination';
import { MyUserContext } from '../../contexts/MyUserContext';
import { PersistantTableGlobalFilter } from '../TableGlobalFilter/PersistantTableGlobalFilter';
import { FilesTableContext } from '../Files/FilesTableContext';
import { FilesContext } from '../../contexts/FilesContext';
import { authAxios } from '../../services/AxiosService';
import { RecordNonFileTime } from '../Users/UserRecordNonFileTime';
import { useSupportWorkerColumns } from './useSupportWorkerColumns';
import { useLegalAssistantColumns } from './useLegalAssistantColumns';
import { useIntakeColumns } from './useIntakeColumns';

interface IProps {
  selectRow?: (userId: string) => void;
}

export const FilesTable: React.FC<IProps> = (props) => {
  const myUserContext = useContext(MyUserContext);
  const searchContext = useContext(FilesTableContext);
  const filesContext = useContext(FilesContext);
  const files = filesContext.state.files;

  const history = useHistory();
  const location = useLocation<{ deletedFileId?: string }>();
  const [activeOnscreenReminders, setActiveOnscreenReminders] = useState<IReminder[]>([]);
  const [openTimeRecordedPopup, setOpenTimeRecordedPopup] = useState(false);
  const legalAssistantColumns = useLegalAssistantColumns();
  const supportWorkerColumns = useSupportWorkerColumns();
  const intakeColumns = useIntakeColumns();

  useEffect(() => {
    if (!searchContext.includeClosed) {
      filesContext.dispatch({ type: 'FILE_ACTION_DO_RELOAD' });
    }
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('Mounting the file table');
    const interval = setInterval(() => getReminders(), 1000 * 60 * 5);
    return () => clearInterval(interval);
  }, []);

  const getReminders = () => {
    authAxios.get('/api/files/active-onscreen-reminders').then((response) => {
      const reminders: IReminder[] = response.data.reminders;
      setActiveOnscreenReminders(reminders);
    });
  };

  useEffect(() => {
    getReminders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deletedFileId = location?.state?.deletedFileId;

  const resolveReminder = (fileId: string, reminderId: string) => {
    authAxios.post('/api/files/resolve-reminder', { fileId: fileId, reminderId: reminderId }).then((response) => {
      if (response.data.status === 'OK') {
        setActiveOnscreenReminders((r) => r.filter((reminder) => reminder.reminderId !== reminderId));
      }
    });
  };

  const data: IFile[] = useMemo(() => {
    return files.filter((f: any) => f?.fileId && f.fileId !== deletedFileId);
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    setFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, filters, globalFilter },
    setAllFilters,
  }: TableInstance<IFile> = useTable(
    {
      columns: myUserContext.isSupportWorker
        ? supportWorkerColumns
        : myUserContext.role === 'LegalAssistant' || myUserContext.role === 'Lawyer'
        ? legalAssistantColumns
        : intakeColumns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        globalFilter: searchContext.selectedGlobalSearch,
        filters: searchContext.filters,
        sortBy:
          myUserContext.role === 'LegalAssistant' || myUserContext.role === 'Lawyer'
            ? [
                {
                  id: 'stageId',
                  desc: false,
                },
                {
                  id: 'lawyerAssignedDate',
                  desc: false,
                },
              ]
            : myUserContext.role === 'Intake' || myUserContext.role === 'SuperAdmin'
            ? [
                {
                  id: 'stageId',
                  desc: false,
                },
                {
                  id: 'fileNumber',
                  desc: true,
                },
              ]
            : [
                {
                  id: 'submittedDate',
                  desc: true,
                },
              ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    searchContext.setFilters(filters);
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const selectRow = (fileId: string) => {
    if (props.selectRow) {
      props.selectRow(fileId);
    } else {
      history.push('/files/' + fileId);
    }
  };

  return (
    <div className={'files-table'}>
      <Navbar color={'light'} light={true} expand={'xs'}>
        <NavbarBrand className='mr-auto'>Files</NavbarBrand>
        <CustomInput
          type='checkbox'
          id={'active'}
          name={'active'}
          label={
            myUserContext.isLegalAssistant || myUserContext.isLawyer || myUserContext.isSupportWorker
              ? 'Include Closed Files'
              : 'Include All File Stages'
          }
          checked={searchContext.includeClosed}
          onChange={() => {
            searchContext.setIncludeClosed((s) => {
              if (s) {
                filesContext.dispatch({ type: 'FILE_ACTION_ACTIVE_ONLY' });
                setFilter('stageId', (f: FilterValue) =>
                  f.filter((s: any) => {
                    if (myUserContext.isLegalAssistant || myUserContext.isLawyer || myUserContext.isSupportWorker) {
                      return s !== STAGE_CLOSED;
                    } else {
                      return (
                        s !== STAGE_CLOSED &&
                        s !== STAGE_COUNSEL_REQUEST_DRAFT &&
                        s !== STAGE_ERROR &&
                        s !== STAGE_REFUSED &&
                        s !== STAGE_BOARD_REFUSED &&
                        s !== STAGE_STOPPED
                      );
                    }
                  })
                );
              } else {
                filesContext.dispatch({ type: 'FILE_ACTION_ALL_FILES' });
                setFilter('stageId', (f: FilterValue) => {
                  if (myUserContext.isLegalAssistant || myUserContext.isLawyer || myUserContext.isSupportWorker) {
                    return [...f, STAGE_CLOSED];
                  } else {
                    return [...f, STAGE_CLOSED, STAGE_ERROR, STAGE_REFUSED, STAGE_BOARD_REFUSED, STAGE_STOPPED];
                  }
                });
              }
              return !s;
            });
          }}
        />
        <Button
          className={'ml-2'}
          color={'primary'}
          onClick={() => {
            searchContext.setSelectedGlobalSearch('');
            setGlobalFilter('');
            setAllFilters(searchContext.defaultFilters);
          }}
        >
          Clear Filters
        </Button>
        {(myUserContext.isLegalAssistant || myUserContext.isLawyer) && (
          <Button
            className={'ml-2'}
            color={'secondary'}
            onClick={() => {
              setOpenTimeRecordedPopup(true);
            }}
          >
            <FaPlus className='mr-2' />
            Add Time
          </Button>
        )}
        {!myUserContext.isSupportWorker && !myUserContext.isManagement && (
          <Button
            color={'success'}
            className={'ml-2'}
            tag={Link}
            to={{ pathname: '/files/add', state: { counselRequest: true } }}
          >
            <FaPlus className='mr-2' />
            Add Counsel Request
          </Button>
        )}
        {myUserContext.isIntakeOffice && (
          <Button
            color={'success'}
            className={'ml-2'}
            tag={Link}
            to={{ pathname: '/files/add', state: { dutyCounsel: true } }}
          >
            <FaPlus className='mr-2' />
            Add Duty Counsel
          </Button>
        )}
        {myUserContext.isIntakeOffice && (
          <Button color={'success'} className={'ml-2'} tag={Link} to={{ pathname: '/files/add' }}>
            <FaPlus className='mr-2' />
            Add File
          </Button>
        )}
      </Navbar>
      {activeOnscreenReminders.length > 0 && (
        <Container fluid={true} className={'my-3'}>
          {activeOnscreenReminders.map((r) => (
            <Alert
              color={
                r.reminderTypeId === REMINDER_TYPE_FILE_EXPIRY || r.reminderTypeId === REMINDER_TYPE_EXTENSION_REQUESTED
                  ? 'danger'
                  : 'success'
              }
              isOpen={true}
              key={r.reminderId}
            >
              <div className={'d-flex'}>
                NOTIFICATION: {r.text}
                <Button className={'ml-auto p-0'} color={'link'} onClick={() => history.push('/files/' + r.fileId)}>
                  View File
                </Button>
                {r.reminderTypeId !== REMINDER_TYPE_FILE_EXPIRY && (
                  <Button className={'ml-4 p-0'} color={'link'} onClick={() => resolveReminder(r.fileId, r.reminderId)}>
                    Resolve
                  </Button>
                )}
              </div>
            </Alert>
          ))}
        </Container>
      )}
      {filesContext.state.type === 'FILE_STATE_LOADED' ? (
        <div className={'table-responsive'} style={{ minHeight: '800px' }}>
          <table className={'table table-bordered table-hover'} {...getTableProps()}>
            <thead style={{ overflow: 'visible' }}>
              {headerGroups.map((headerGroup: any, index: number) => (
                <tr key={`files-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      key={`files-table-thead-tr-${index}-${column.id}`}
                      width={column.width}
                      style={{ maxWidth: column.width }}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                      <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
              <tr>
                <PersistantTableGlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  colSpan={visibleColumns.length}
                  value={searchContext.selectedGlobalSearch}
                  setValue={searchContext.setSelectedGlobalSearch}
                />
              </tr>
            </thead>
            <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
              {page.map((row: any) => {
                prepareRow(row);
                return (
                  <tr
                    key={`files-table-tr-${row.id}`}
                    {...row.getRowProps()}
                    className={
                      (myUserContext.role === 'LegalAssistant' || myUserContext.role === 'Lawyer') &&
                      (row.original.stageId === STAGE_LAWYER_REQUESTED ||
                        (row.original.stageId === STAGE_COUNSEL_REQUEST_DRAFT &&
                          row.original.reasonCounselRequestRejected &&
                          row.original.reasonCounselRequestRejected.length > 0))
                        ? 'bg-primary-medium'
                        : (myUserContext.role === 'Intake' || myUserContext.role === 'SuperAdmin') &&
                          (row.original.stageId === STAGE_COUNSEL_REQUEST ||
                            row.original.stageId === STAGE_RETURN_FOR_CONFLICT)
                        ? 'bg-primary-medium'
                        : ''
                    }
                  >
                    {row.cells.map((cell: any) => {
                      return (
                        <td
                          key={`files-table-td-${cell.row.id}-${cell.column.id}`}
                          {...cell.getCellProps()}
                          onClick={() => selectRow(row.original.fileId)}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Modal
            isOpen={openTimeRecordedPopup}
            toggle={() => setOpenTimeRecordedPopup(!openTimeRecordedPopup)}
            className='logout-modal'
            style={{ width: '60rem', maxWidth: '60rem' }}
          >
            <ModalHeader>Add Lawyer Time</ModalHeader>
            <ModalBody>
              <RecordNonFileTime
                setOpenTimeRecordedPopup={setOpenTimeRecordedPopup}
                openTimeRecordedPopup={openTimeRecordedPopup}
              />
            </ModalBody>
          </Modal>
        </div>
      ) : (
        <div>Loading</div>
      )}
      <TablePagination
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizes={[20, 50, 100, 500]}
        recordCount={rows.length}
      />
    </div>
  );
};
