import * as React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Navbar, NavbarBrand, Row } from 'reactstrap';
import { FaFileDownload, FaPrint } from 'react-icons/fa';
import { useContext, useEffect, useState } from 'react';
import { IReportingFile } from '../Files/FileContext';
import {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_OTHER,
  IApplicationType,
  ICircuitLocation,
  STAGE_BOARD_REFUSED,
  STAGE_BOARD_REVIEW,
  STAGE_CANCELLED_COUNSEL_REQUEST,
  STAGE_COUNSEL_REQUEST,
  STAGE_COUNSEL_REQUEST_DRAFT,
  STAGE_DUTY_COUNSEL,
  STAGE_ERROR,
  STAGE_PENDING,
  STAGE_READY_FOR_REVIEW,
  STAGE_REFUSED,
  STAGE_STOPPED,
} from '../../contexts/DomainContext';
import { IndividualsContext } from '../../contexts/IndividualsContext';
import DatePicker from 'react-datepicker';
import { DomainContext } from '../../contexts/DomainContext';
import { Link } from 'react-router-dom';
import { formatDate } from '../../formatters/DateTimeFormatter';
import { ReportContext } from './ReportContext';
import { MyUserContext } from '../../contexts/MyUserContext';

export const TotalPeopleServed = () => {
  const individualsContext = useContext(IndividualsContext);
  const domainContext = useContext(DomainContext);
  const reportContext = useContext(ReportContext);
  const myUserContext = useContext(MyUserContext);
  const circuitLocations = domainContext.circuitLocations;
  const [filteredFiles, setFilteredFiles] = useState<IReportingFile[]>([]);
  const [total, setTotal] = useState<IReportingFile[]>([]);
  const [notIncluded, setNotIncluded] = useState<IReportingFile[]>([]);
  const allFiles = reportContext.allFiles;
  const fromDate = reportContext.fromDate;
  const setFromDate = reportContext.setFromDate;
  const toDate = reportContext.toDate;
  const setToDate = reportContext.setToDate;

  useEffect(() => {
    let totalFiltered: IReportingFile[] = [];
    allFiles.forEach((f) => {
      if (
        f.appDate &&
        f.appDate >= fromDate.getTime() &&
        f.appDate <= toDate.getTime() &&
        f.sId !== STAGE_PENDING &&
        f.sId !== STAGE_READY_FOR_REVIEW &&
        f.sId !== STAGE_REFUSED &&
        f.sId !== STAGE_BOARD_REVIEW &&
        f.sId !== STAGE_BOARD_REFUSED &&
        f.sId !== STAGE_COUNSEL_REQUEST_DRAFT &&
        f.sId !== STAGE_COUNSEL_REQUEST &&
        f.sId !== STAGE_CANCELLED_COUNSEL_REQUEST &&
        f.sId !== STAGE_DUTY_COUNSEL &&
        f.sId !== STAGE_STOPPED &&
        f.sId !== STAGE_ERROR
      ) {
        totalFiltered = totalFiltered.concat(f);
      }
    });
    //filter duplicate files
    totalFiltered = totalFiltered.filter((f) => {
      if (f.fNum !== undefined) {
        const duplicates: IReportingFile[] = totalFiltered.filter(
          (file) => file.fNum !== undefined && file.fNum === f.fNum
        );
        if (duplicates.length > 0) {
          if (!duplicates.some((file) => file.fSeqNum > f.fSeqNum)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    });

    let totalFiles: IReportingFile[] = [];
    let notIncludedTotal: IReportingFile[] = [];
    setFilteredFiles(totalFiltered);
    totalFiltered.forEach((f) => {
      const client = individualsContext.state.individuals.find((i) => i.individualId === f.cId);
      if (
        domainContext.applicationTypes.some((a: IApplicationType) => a.applicationTypeId === f.appTypeId) && client
          ? client.birthDate && client.genderId
          : false
      ) {
        totalFiles = totalFiles.concat(f);
      } else {
        notIncludedTotal = notIncludedTotal.concat(f);
      }
    });
    setTotal(totalFiles);
    setNotIncluded(notIncludedTotal);
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFiles, fromDate, toDate]);

  let totalMaleAgeGroup1Indigenous: IReportingFile[] = [];
  let totalMaleAgeGroup1Non: IReportingFile[] = [];
  let totalMaleAgeGroup2Indigenous: IReportingFile[] = [];
  let totalMaleAgeGroup2Non: IReportingFile[] = [];
  let totalMaleAgeGroup3Indigenous: IReportingFile[] = [];
  let totalMaleAgeGroup3Non: IReportingFile[] = [];
  let totalMaleAgeGroup4Indigenous: IReportingFile[] = [];
  let totalMaleAgeGroup4Non: IReportingFile[] = [];
  let totalMaleAgeGroup5Indigenous: IReportingFile[] = [];
  let totalMaleAgeGroup5Non: IReportingFile[] = [];

  let totalFemaleAgeGroup1Indigenous: IReportingFile[] = [];
  let totalFemaleAgeGroup1Non: IReportingFile[] = [];
  let totalFemaleAgeGroup2Indigenous: IReportingFile[] = [];
  let totalFemaleAgeGroup2Non: IReportingFile[] = [];
  let totalFemaleAgeGroup3Indigenous: IReportingFile[] = [];
  let totalFemaleAgeGroup3Non: IReportingFile[] = [];
  let totalFemaleAgeGroup4Indigenous: IReportingFile[] = [];
  let totalFemaleAgeGroup4Non: IReportingFile[] = [];
  let totalFemaleAgeGroup5Indigenous: IReportingFile[] = [];
  let totalFemaleAgeGroup5Non: IReportingFile[] = [];

  let totalOtherAgeGroup1Indigenous: IReportingFile[] = [];
  let totalOtherAgeGroup1Non: IReportingFile[] = [];
  let totalOtherAgeGroup2Indigenous: IReportingFile[] = [];
  let totalOtherAgeGroup2Non: IReportingFile[] = [];
  let totalOtherAgeGroup3Indigenous: IReportingFile[] = [];
  let totalOtherAgeGroup3Non: IReportingFile[] = [];
  let totalOtherAgeGroup4Indigenous: IReportingFile[] = [];
  let totalOtherAgeGroup4Non: IReportingFile[] = [];
  let totalOtherAgeGroup5Indigenous: IReportingFile[] = [];
  let totalOtherAgeGroup5Non: IReportingFile[] = [];

  const dateDiffInYears = (dateOld: Date, dateNew: Date) => {
    const ynew = dateNew.getFullYear();
    const mnew = dateNew.getMonth();
    const dnew = dateNew.getDate();
    const yold = dateOld.getFullYear();
    const mold = dateOld.getMonth();
    const dold = dateOld.getDate();
    let diff = Math.abs(ynew - yold);
    if (mold > mnew) {
      diff = diff - 1;
    } else {
      if (mold === mnew) {
        if (dold > dnew) {
          diff = diff - 1;
        }
      }
    }
    return diff;
  };

  const undefinedCircuitLcations = () => {
    const locationFiles = filteredFiles.filter(
      (ff) => !circuitLocations.some((c) => c.circuitLocationId === ff.cirLocId)
    );

    let locationMaleAgeGroup1Indigenous: IReportingFile[] = [];
    let locationMaleAgeGroup1Non: IReportingFile[] = [];
    let locationMaleAgeGroup2Indigenous: IReportingFile[] = [];
    let locationMaleAgeGroup2Non: IReportingFile[] = [];
    let locationMaleAgeGroup3Indigenous: IReportingFile[] = [];
    let locationMaleAgeGroup3Non: IReportingFile[] = [];
    let locationMaleAgeGroup4Indigenous: IReportingFile[] = [];
    let locationMaleAgeGroup4Non: IReportingFile[] = [];
    let locationMaleAgeGroup5Indigenous: IReportingFile[] = [];
    let locationMaleAgeGroup5Non: IReportingFile[] = [];

    let locationFemaleAgeGroup1Indigenous: IReportingFile[] = [];
    let locationFemaleAgeGroup1Non: IReportingFile[] = [];
    let locationFemaleAgeGroup2Indigenous: IReportingFile[] = [];
    let locationFemaleAgeGroup2Non: IReportingFile[] = [];
    let locationFemaleAgeGroup3Indigenous: IReportingFile[] = [];
    let locationFemaleAgeGroup3Non: IReportingFile[] = [];
    let locationFemaleAgeGroup4Indigenous: IReportingFile[] = [];
    let locationFemaleAgeGroup4Non: IReportingFile[] = [];
    let locationFemaleAgeGroup5Indigenous: IReportingFile[] = [];
    let locationFemaleAgeGroup5Non: IReportingFile[] = [];

    let locationOtherAgeGroup1Indigenous: IReportingFile[] = [];
    let locationOtherAgeGroup1Non: IReportingFile[] = [];
    let locationOtherAgeGroup2Indigenous: IReportingFile[] = [];
    let locationOtherAgeGroup2Non: IReportingFile[] = [];
    let locationOtherAgeGroup3Indigenous: IReportingFile[] = [];
    let locationOtherAgeGroup3Non: IReportingFile[] = [];
    let locationOtherAgeGroup4Indigenous: IReportingFile[] = [];
    let locationOtherAgeGroup4Non: IReportingFile[] = [];
    let locationOtherAgeGroup5Indigenous: IReportingFile[] = [];
    let locationOtherAgeGroup5Non: IReportingFile[] = [];

    locationFiles.forEach((f) => {
      const client = individualsContext.state.individuals.find((i) => i.individualId === f.cId);
      if (client) {
        const age = client.birthDate ? dateDiffInYears(new Date(client.birthDate), new Date(f.appDate)) : undefined;
        if (client.genderId && client.genderId === GENDER_MALE) {
          if (age !== undefined && age < 18) {
            if (client.indigenous || client.visibleMinority) {
              locationMaleAgeGroup1Indigenous = locationMaleAgeGroup1Indigenous.concat(f);
              totalMaleAgeGroup1Indigenous = totalMaleAgeGroup1Indigenous.concat(f);
            } else {
              locationMaleAgeGroup1Non = locationMaleAgeGroup1Non.concat(f);
              totalMaleAgeGroup1Non = totalMaleAgeGroup1Non.concat(f);
            }
          } else if (age !== undefined && age >= 18 && age <= 29) {
            if (client.indigenous || client.visibleMinority) {
              locationMaleAgeGroup2Indigenous = locationMaleAgeGroup2Indigenous.concat(f);
              totalMaleAgeGroup2Indigenous = totalMaleAgeGroup2Indigenous.concat(f);
            } else {
              locationMaleAgeGroup2Non = locationMaleAgeGroup2Non.concat(f);
              totalMaleAgeGroup2Non = totalMaleAgeGroup2Non.concat(f);
            }
          } else if (age !== undefined && age >= 30 && age <= 34) {
            if (client.indigenous || client.visibleMinority) {
              locationMaleAgeGroup3Indigenous = locationMaleAgeGroup3Indigenous.concat(f);
              totalMaleAgeGroup3Indigenous = totalMaleAgeGroup3Indigenous.concat(f);
            } else {
              locationMaleAgeGroup3Non = locationMaleAgeGroup3Non.concat(f);
              totalMaleAgeGroup3Non = totalMaleAgeGroup3Non.concat(f);
            }
          } else if (age !== undefined && age >= 35 && age <= 49) {
            if (client.indigenous || client.visibleMinority) {
              locationMaleAgeGroup4Indigenous = locationMaleAgeGroup4Indigenous.concat(f);
              totalMaleAgeGroup4Indigenous = totalMaleAgeGroup4Indigenous.concat(f);
            } else {
              locationMaleAgeGroup4Non = locationMaleAgeGroup4Non.concat(f);
              totalMaleAgeGroup4Non = totalMaleAgeGroup4Non.concat(f);
            }
          } else if (age !== undefined && age >= 50) {
            if (client.indigenous || client.visibleMinority) {
              locationMaleAgeGroup5Indigenous = locationMaleAgeGroup5Indigenous.concat(f);
              totalMaleAgeGroup5Indigenous = totalMaleAgeGroup5Indigenous.concat(f);
            } else {
              locationMaleAgeGroup5Non = locationMaleAgeGroup5Non.concat(f);
              totalMaleAgeGroup5Non = totalMaleAgeGroup5Non.concat(f);
            }
          }
        } else if (client.genderId && client.genderId === GENDER_FEMALE) {
          if (age !== undefined && age < 18) {
            if (client.indigenous || client.visibleMinority) {
              locationFemaleAgeGroup1Indigenous = locationFemaleAgeGroup1Indigenous.concat(f);
              totalFemaleAgeGroup1Indigenous = totalFemaleAgeGroup1Indigenous.concat(f);
            } else {
              locationFemaleAgeGroup1Non = locationFemaleAgeGroup1Non.concat(f);
              totalFemaleAgeGroup1Non = totalFemaleAgeGroup1Non.concat(f);
            }
          } else if (age !== undefined && age >= 18 && age <= 29) {
            if (client.indigenous || client.visibleMinority) {
              locationFemaleAgeGroup2Indigenous = locationFemaleAgeGroup2Indigenous.concat(f);
              totalFemaleAgeGroup2Indigenous = totalFemaleAgeGroup2Indigenous.concat(f);
            } else {
              locationFemaleAgeGroup2Non = locationFemaleAgeGroup2Non.concat(f);
              totalFemaleAgeGroup2Non = totalFemaleAgeGroup2Non.concat(f);
            }
          } else if (age !== undefined && age >= 30 && age <= 34) {
            if (client.indigenous || client.visibleMinority) {
              locationFemaleAgeGroup3Indigenous = locationFemaleAgeGroup3Indigenous.concat(f);
              totalFemaleAgeGroup3Indigenous = totalFemaleAgeGroup3Indigenous.concat(f);
            } else {
              locationFemaleAgeGroup3Non = locationFemaleAgeGroup3Non.concat(f);
              totalFemaleAgeGroup3Non = totalFemaleAgeGroup3Non.concat(f);
            }
          } else if (age !== undefined && age >= 35 && age <= 49) {
            if (client.indigenous || client.visibleMinority) {
              locationFemaleAgeGroup4Indigenous = locationFemaleAgeGroup4Indigenous.concat(f);
              totalFemaleAgeGroup4Indigenous = totalFemaleAgeGroup4Indigenous.concat(f);
            } else {
              locationFemaleAgeGroup4Non = locationFemaleAgeGroup4Non.concat(f);
              totalFemaleAgeGroup4Non = totalFemaleAgeGroup4Non.concat(f);
            }
          } else if (age !== undefined && age >= 50) {
            if (client.indigenous || client.visibleMinority) {
              locationFemaleAgeGroup5Indigenous = locationFemaleAgeGroup5Indigenous.concat(f);
              totalFemaleAgeGroup5Indigenous = totalFemaleAgeGroup5Indigenous.concat(f);
            } else {
              locationFemaleAgeGroup5Non = locationFemaleAgeGroup5Non.concat(f);
              totalFemaleAgeGroup5Non = totalFemaleAgeGroup5Non.concat(f);
            }
          }
        } else if (client.genderId && client.genderId === GENDER_OTHER) {
          if (age !== undefined && age < 18) {
            if (client.indigenous || client.visibleMinority) {
              locationOtherAgeGroup1Indigenous = locationOtherAgeGroup1Indigenous.concat(f);
              totalOtherAgeGroup1Indigenous = totalOtherAgeGroup1Indigenous.concat(f);
            } else {
              locationOtherAgeGroup1Non = locationOtherAgeGroup1Non.concat(f);
              totalOtherAgeGroup1Non = totalOtherAgeGroup1Non.concat(f);
            }
          } else if (age !== undefined && age >= 18 && age <= 29) {
            if (client.indigenous || client.visibleMinority) {
              locationOtherAgeGroup2Indigenous = locationOtherAgeGroup2Indigenous.concat(f);
              totalOtherAgeGroup2Indigenous = totalOtherAgeGroup2Indigenous.concat(f);
            } else {
              locationOtherAgeGroup2Non = locationOtherAgeGroup2Non.concat(f);
              totalOtherAgeGroup2Non = totalOtherAgeGroup2Non.concat(f);
            }
          } else if (age !== undefined && age >= 30 && age <= 34) {
            if (client.indigenous || client.visibleMinority) {
              locationOtherAgeGroup3Indigenous = locationOtherAgeGroup3Indigenous.concat(f);
              totalOtherAgeGroup3Indigenous = totalOtherAgeGroup3Indigenous.concat(f);
            } else {
              locationOtherAgeGroup3Non = locationOtherAgeGroup3Non.concat(f);
              totalOtherAgeGroup3Non = totalOtherAgeGroup3Non.concat(f);
            }
          } else if (age !== undefined && age >= 35 && age <= 49) {
            if (client.indigenous || client.visibleMinority) {
              locationOtherAgeGroup4Indigenous = locationOtherAgeGroup4Indigenous.concat(f);
              totalOtherAgeGroup4Indigenous = totalOtherAgeGroup4Indigenous.concat(f);
            } else {
              locationOtherAgeGroup4Non = locationOtherAgeGroup4Non.concat(f);
              totalOtherAgeGroup4Non = totalOtherAgeGroup4Non.concat(f);
            }
          } else if (age !== undefined && age >= 50) {
            if (client.indigenous || client.visibleMinority) {
              locationOtherAgeGroup5Indigenous = locationOtherAgeGroup5Indigenous.concat(f);
              totalOtherAgeGroup5Indigenous = totalOtherAgeGroup5Indigenous.concat(f);
            } else {
              locationOtherAgeGroup5Non = locationOtherAgeGroup5Non.concat(f);
              totalOtherAgeGroup5Non = totalOtherAgeGroup5Non.concat(f);
            }
          }
        }
      }
    });
    return domainContext.applicationTypes
      .map((applicationType, index) => {
        const locationMaleAgeGroup1IndigenousApplication = locationMaleAgeGroup1Indigenous.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationMaleAgeGroup1NonApplication = locationMaleAgeGroup1Non.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationMaleAgeGroup2IndigenousApplication = locationMaleAgeGroup2Indigenous.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationMaleAgeGroup2NonApplication = locationMaleAgeGroup2Non.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationMaleAgeGroup3IndigenousApplication = locationMaleAgeGroup3Indigenous.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationMaleAgeGroup3NonApplication = locationMaleAgeGroup3Non.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationMaleAgeGroup4IndigenousApplication = locationMaleAgeGroup4Indigenous.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationMaleAgeGroup4NonApplication = locationMaleAgeGroup4Non.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationMaleAgeGroup5IndigenousApplication = locationMaleAgeGroup5Indigenous.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationMaleAgeGroup5NonApplication = locationMaleAgeGroup5Non.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const totalMale =
          locationMaleAgeGroup1IndigenousApplication.length +
          locationMaleAgeGroup1NonApplication.length +
          locationMaleAgeGroup2IndigenousApplication.length +
          locationMaleAgeGroup2NonApplication.length +
          locationMaleAgeGroup3IndigenousApplication.length +
          locationMaleAgeGroup3NonApplication.length +
          locationMaleAgeGroup4IndigenousApplication.length +
          locationMaleAgeGroup4NonApplication.length +
          locationMaleAgeGroup5IndigenousApplication.length +
          locationMaleAgeGroup5NonApplication.length;

        const locationFemaleAgeGroup1IndigenousApplication = locationFemaleAgeGroup1Indigenous.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationFemaleAgeGroup1NonApplication = locationFemaleAgeGroup1Non.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationFemaleAgeGroup2IndigenousApplication = locationFemaleAgeGroup2Indigenous.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationFemaleAgeGroup2NonApplication = locationFemaleAgeGroup2Non.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationFemaleAgeGroup3IndigenousApplication = locationFemaleAgeGroup3Indigenous.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationFemaleAgeGroup3NonApplication = locationFemaleAgeGroup3Non.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationFemaleAgeGroup4IndigenousApplication = locationFemaleAgeGroup4Indigenous.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationFemaleAgeGroup4NonApplication = locationFemaleAgeGroup4Non.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationFemaleAgeGroup5IndigenousApplication = locationFemaleAgeGroup5Indigenous.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationFemaleAgeGroup5NonApplication = locationFemaleAgeGroup5Non.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );

        const totalFemale =
          locationFemaleAgeGroup1IndigenousApplication.length +
          locationFemaleAgeGroup1NonApplication.length +
          locationFemaleAgeGroup2IndigenousApplication.length +
          locationFemaleAgeGroup2NonApplication.length +
          locationFemaleAgeGroup3IndigenousApplication.length +
          locationFemaleAgeGroup3NonApplication.length +
          locationFemaleAgeGroup4IndigenousApplication.length +
          locationFemaleAgeGroup4NonApplication.length +
          locationFemaleAgeGroup5IndigenousApplication.length +
          locationFemaleAgeGroup5NonApplication.length;

        const locationOtherAgeGroup1IndigenousApplication = locationOtherAgeGroup1Indigenous.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationOtherAgeGroup1NonApplication = locationOtherAgeGroup1Non.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationOtherAgeGroup2IndigenousApplication = locationOtherAgeGroup2Indigenous.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationOtherAgeGroup2NonApplication = locationOtherAgeGroup2Non.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationOtherAgeGroup3IndigenousApplication = locationOtherAgeGroup3Indigenous.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationOtherAgeGroup3NonApplication = locationOtherAgeGroup3Non.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationOtherAgeGroup4IndigenousApplication = locationOtherAgeGroup4Indigenous.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationOtherAgeGroup4NonApplication = locationOtherAgeGroup4Non.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationOtherAgeGroup5IndigenousApplication = locationOtherAgeGroup5Indigenous.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );
        const locationOtherAgeGroup5NonApplication = locationOtherAgeGroup5Non.filter(
          (f) => f.appTypeId === applicationType.applicationTypeId
        );

        const totalOther =
          locationOtherAgeGroup1IndigenousApplication.length +
          locationOtherAgeGroup1NonApplication.length +
          locationOtherAgeGroup2IndigenousApplication.length +
          locationOtherAgeGroup2NonApplication.length +
          locationOtherAgeGroup3IndigenousApplication.length +
          locationOtherAgeGroup3NonApplication.length +
          locationOtherAgeGroup4IndigenousApplication.length +
          locationOtherAgeGroup4NonApplication.length +
          locationOtherAgeGroup5IndigenousApplication.length +
          locationOtherAgeGroup5NonApplication.length;

        return (
          <tr key={applicationType.applicationTypeId}>
            {index === 0 && (
              <th className={'header-side-bottom'} rowSpan={domainContext.applicationTypes.length + 1}>
                Undefined Location
              </th>
            )}
            <th className={'header-side'}>{applicationType.name}</th>
            <td className={'report-row'}>{locationMaleAgeGroup1IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationMaleAgeGroup1NonApplication.length}</td>
            <td className={'report-row'}>{locationMaleAgeGroup2IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationMaleAgeGroup2NonApplication.length}</td>
            <td className={'report-row'}>{locationMaleAgeGroup3IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationMaleAgeGroup3NonApplication.length}</td>
            <td className={'report-row'}>{locationMaleAgeGroup4IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationMaleAgeGroup4NonApplication.length}</td>
            <td className={'report-row'}>{locationMaleAgeGroup5IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationMaleAgeGroup5NonApplication.length}</td>
            <td className={'total'}>
              <b>{totalMale}</b>
            </td>
            <td className={'report-row'}>{locationFemaleAgeGroup1IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationFemaleAgeGroup1NonApplication.length}</td>
            <td className={'report-row'}>{locationFemaleAgeGroup2IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationFemaleAgeGroup2NonApplication.length}</td>
            <td className={'report-row'}>{locationFemaleAgeGroup3IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationFemaleAgeGroup3NonApplication.length}</td>
            <td className={'report-row'}>{locationFemaleAgeGroup4IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationFemaleAgeGroup4NonApplication.length}</td>
            <td className={'report-row'}>{locationFemaleAgeGroup5IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationFemaleAgeGroup5NonApplication.length}</td>
            <td className={'total'}>
              <b>{totalFemale}</b>
            </td>
            <td className={'report-row'}>{locationOtherAgeGroup1IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationOtherAgeGroup1NonApplication.length}</td>
            <td className={'report-row'}>{locationOtherAgeGroup2IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationOtherAgeGroup2NonApplication.length}</td>
            <td className={'report-row'}>{locationOtherAgeGroup3IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationOtherAgeGroup3NonApplication.length}</td>
            <td className={'report-row'}>{locationOtherAgeGroup4IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationOtherAgeGroup4NonApplication.length}</td>
            <td className={'report-row'}>{locationOtherAgeGroup5IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationOtherAgeGroup5NonApplication.length}</td>
            <td className={'total'}>
              <b>{totalOther}</b>
            </td>
            <td className={'total'}>
              <b>{totalMale + totalFemale + totalOther}</b>
            </td>
          </tr>
        );
      })
      .concat(
        <tr key={'total-undefined'}>
          <th className={'header-side-bottom'} />
          <td className={'total-bottom'}>
            <b>{locationMaleAgeGroup1Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationMaleAgeGroup1Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationMaleAgeGroup2Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationMaleAgeGroup2Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationMaleAgeGroup3Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationMaleAgeGroup3Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationMaleAgeGroup4Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationMaleAgeGroup4Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationMaleAgeGroup5Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationMaleAgeGroup5Non.length}</b>
          </td>
          <td className={'total-bottom'} />
          <td className={'total-bottom'}>
            <b>{locationFemaleAgeGroup1Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationFemaleAgeGroup1Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationFemaleAgeGroup2Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationFemaleAgeGroup2Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationFemaleAgeGroup3Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationFemaleAgeGroup3Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationFemaleAgeGroup4Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationFemaleAgeGroup4Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationFemaleAgeGroup5Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationFemaleAgeGroup5Non.length}</b>
          </td>
          <td className={'total-bottom'} />
          <td className={'total-bottom'}>
            <b>{locationOtherAgeGroup1Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationOtherAgeGroup1Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationOtherAgeGroup2Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationOtherAgeGroup2Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationOtherAgeGroup3Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationOtherAgeGroup3Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationOtherAgeGroup4Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationOtherAgeGroup4Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationOtherAgeGroup5Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationOtherAgeGroup5Non.length}</b>
          </td>
          <td className={'total-bottom'} />
          <td className={'total-bottom'} />
        </tr>
      );
  };

  const downloadCSV = () => {
    const genderRow = [
      '',
      '',
      'Male',
      'Male',
      'Male',
      'Male',
      'Male',
      'Male',
      'Male',
      'Male',
      'Male',
      'Male',
      'Male Total',
      'Female',
      'Female',
      'Female',
      'Female',
      'Female',
      'Female',
      'Female',
      'Female',
      'Female',
      'Female',
      'Female Total',
      'Other',
      'Other',
      'Other',
      'Other',
      'Other',
      'Other',
      'Other',
      'Other',
      'Other',
      'Other',
      'Other Total',
      'Total',
    ];
    const ageRow = [
      '',
      '',
      '17 & Under',
      '17 & Under',
      '18 - 29',
      '18 - 29',
      '30 - 34',
      '30 - 34',
      '35 - 49',
      '35 - 49',
      '50 & Above',
      '50 & Above',
      '',
      '17 & Under',
      '17 & Under',
      '18 - 29',
      '18 - 29',
      '30 - 34',
      '30 - 34',
      '35 - 49',
      '35 - 49',
      '50 & Above',
      '50 & Above',
      '',
      '17 & Under',
      '17 & Under',
      '18 - 29',
      '18 - 29',
      '30 - 34',
      '30 - 34',
      '35 - 49',
      '35 - 49',
      '50 & Above',
      '50 & Above',
      '',
      '',
    ];
    const minorityRow = [
      '',
      '',
      'Indig. & Vis Min.',
      'Non',
      'Indig. & Vis Min.',
      'Non',
      'Indig. & Vis Min.',
      'Non',
      'Indig. & Vis Min.',
      'Non',
      'Indig. & Vis Min.',
      'Non',
      '',
      'Indig. & Vis Min.',
      'Non',
      'Indig. & Vis Min.',
      'Non',
      'Indig. & Vis Min.',
      'Non',
      'Indig. & Vis Min.',
      'Non',
      'Indig. & Vis Min.',
      'Non',
      '',
      'Indig. & Vis Min.',
      'Non',
      'Indig. & Vis Min.',
      'Non',
      'Indig. & Vis Min.',
      'Non',
      'Indig. & Vis Min.',
      'Non',
      'Indig. & Vis Min.',
      'Non',
      '',
      '',
    ];
    const data = circuitLocations
      .map((cl) => {
        const totalMaleLocation = totalMaleAgeGroup1Indigenous
          .concat(totalMaleAgeGroup1Non)
          .concat(totalMaleAgeGroup2Indigenous)
          .concat(totalMaleAgeGroup2Non)
          .concat(totalMaleAgeGroup3Indigenous)
          .concat(totalMaleAgeGroup3Non)
          .concat(totalMaleAgeGroup4Indigenous)
          .concat(totalMaleAgeGroup4Non)
          .concat(totalMaleAgeGroup5Indigenous)
          .concat(totalMaleAgeGroup5Non)
          .filter((f) => f.cirLocId === cl.circuitLocationId);
        const totalFemaleLocation = totalFemaleAgeGroup1Indigenous
          .concat(totalFemaleAgeGroup1Non)
          .concat(totalFemaleAgeGroup2Indigenous)
          .concat(totalFemaleAgeGroup2Non)
          .concat(totalFemaleAgeGroup3Indigenous)
          .concat(totalFemaleAgeGroup3Non)
          .concat(totalFemaleAgeGroup4Indigenous)
          .concat(totalFemaleAgeGroup4Non)
          .concat(totalFemaleAgeGroup5Indigenous)
          .concat(totalFemaleAgeGroup5Non)
          .filter((f) => f.cirLocId === cl.circuitLocationId);
        const totalOtherLocation = totalOtherAgeGroup1Indigenous
          .concat(totalOtherAgeGroup1Non)
          .concat(totalOtherAgeGroup2Indigenous)
          .concat(totalOtherAgeGroup2Non)
          .concat(totalOtherAgeGroup3Indigenous)
          .concat(totalOtherAgeGroup3Non)
          .concat(totalOtherAgeGroup4Indigenous)
          .concat(totalOtherAgeGroup4Non)
          .concat(totalOtherAgeGroup5Indigenous)
          .concat(totalOtherAgeGroup5Non)
          .filter((f) => f.cirLocId === cl.circuitLocationId);

        return domainContext.applicationTypes
          .map((at) => {
            const totalMale = totalMaleLocation.filter(
              (f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId
            ).length;
            const totalFemale = totalFemaleLocation.filter(
              (f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId
            ).length;
            const totalOther = totalOtherLocation.filter(
              (f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId
            ).length;
            return [
              cl.name,
              at.name,
              totalMaleAgeGroup1Indigenous
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalMaleAgeGroup1Non
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalMaleAgeGroup2Indigenous
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalMaleAgeGroup2Non
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalMaleAgeGroup3Indigenous
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalMaleAgeGroup3Non
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalMaleAgeGroup4Indigenous
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalMaleAgeGroup4Non
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalMaleAgeGroup5Indigenous
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalMaleAgeGroup5Non
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalMale.toString(),
              totalFemaleAgeGroup1Indigenous
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalFemaleAgeGroup1Non
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalFemaleAgeGroup2Indigenous
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalFemaleAgeGroup2Non
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalFemaleAgeGroup3Indigenous
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalFemaleAgeGroup3Non
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalFemaleAgeGroup4Indigenous
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalFemaleAgeGroup4Non
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalFemaleAgeGroup5Indigenous
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalFemaleAgeGroup5Non
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalFemale.toString(),
              totalOtherAgeGroup1Indigenous
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalOtherAgeGroup1Non
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalOtherAgeGroup2Indigenous
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalOtherAgeGroup2Non
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalOtherAgeGroup3Indigenous
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalOtherAgeGroup3Non
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalOtherAgeGroup4Indigenous
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalOtherAgeGroup4Non
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalOtherAgeGroup5Indigenous
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalOtherAgeGroup5Non
                .filter((f) => f.cirLocId === cl.circuitLocationId && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalOther.toString(),
              (totalMale + totalFemale + totalOther).toString(),
            ];
          })
          .concat([
            [
              cl.name,
              'TOTAL',
              totalMaleAgeGroup1Indigenous.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalMaleAgeGroup1Non.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalMaleAgeGroup2Indigenous.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalMaleAgeGroup2Non.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalMaleAgeGroup3Indigenous.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalMaleAgeGroup3Non.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalMaleAgeGroup4Indigenous.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalMaleAgeGroup4Non.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalMaleAgeGroup5Indigenous.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalMaleAgeGroup5Non.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              '',
              totalFemaleAgeGroup1Indigenous.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalFemaleAgeGroup1Non.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalFemaleAgeGroup2Indigenous.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalFemaleAgeGroup2Non.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalFemaleAgeGroup3Indigenous.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalFemaleAgeGroup3Non.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalFemaleAgeGroup4Indigenous.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalFemaleAgeGroup4Non.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalFemaleAgeGroup5Indigenous.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalFemaleAgeGroup5Non.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              '',
              totalOtherAgeGroup1Indigenous.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalOtherAgeGroup1Non.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalOtherAgeGroup2Indigenous.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalOtherAgeGroup2Non.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalOtherAgeGroup3Indigenous.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalOtherAgeGroup3Non.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalOtherAgeGroup4Indigenous.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalOtherAgeGroup4Non.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalOtherAgeGroup5Indigenous.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              totalOtherAgeGroup5Non.filter((f) => f.cirLocId === cl.circuitLocationId).length.toString(),
              '',
              '',
            ],
          ])
          .concat([
            [
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
            ],
          ]);
      })
      .concat([
        domainContext.applicationTypes
          .map((at) => {
            const totalMale = totalMaleAgeGroup1Indigenous
              .concat(totalMaleAgeGroup1Non)
              .concat(totalMaleAgeGroup2Indigenous)
              .concat(totalMaleAgeGroup2Non)
              .concat(totalMaleAgeGroup3Indigenous)
              .concat(totalMaleAgeGroup3Non)
              .concat(totalMaleAgeGroup4Indigenous)
              .concat(totalMaleAgeGroup4Non)
              .concat(totalMaleAgeGroup5Indigenous)
              .concat(totalMaleAgeGroup5Non)
              .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId).length;
            const totalFemale = totalFemaleAgeGroup1Indigenous
              .concat(totalFemaleAgeGroup1Non)
              .concat(totalFemaleAgeGroup2Indigenous)
              .concat(totalFemaleAgeGroup2Non)
              .concat(totalFemaleAgeGroup3Indigenous)
              .concat(totalFemaleAgeGroup3Non)
              .concat(totalFemaleAgeGroup4Indigenous)
              .concat(totalFemaleAgeGroup4Non)
              .concat(totalFemaleAgeGroup5Indigenous)
              .concat(totalFemaleAgeGroup5Non)
              .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId).length;
            const totalOther = totalOtherAgeGroup1Indigenous
              .concat(totalOtherAgeGroup1Non)
              .concat(totalOtherAgeGroup2Indigenous)
              .concat(totalOtherAgeGroup2Non)
              .concat(totalOtherAgeGroup3Indigenous)
              .concat(totalOtherAgeGroup3Non)
              .concat(totalOtherAgeGroup4Indigenous)
              .concat(totalOtherAgeGroup4Non)
              .concat(totalOtherAgeGroup5Indigenous)
              .concat(totalOtherAgeGroup5Non)
              .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId).length;
            return [
              'Undefined Location',
              at.name,
              totalMaleAgeGroup1Indigenous
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalMaleAgeGroup1Non
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalMaleAgeGroup2Indigenous
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalMaleAgeGroup2Non
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalMaleAgeGroup3Indigenous
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalMaleAgeGroup3Non
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalMaleAgeGroup4Indigenous
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalMaleAgeGroup4Non
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalMaleAgeGroup5Indigenous
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalMaleAgeGroup5Non
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalMale.toString(),
              totalFemaleAgeGroup1Indigenous
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalFemaleAgeGroup1Non
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalFemaleAgeGroup2Indigenous
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalFemaleAgeGroup2Non
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalFemaleAgeGroup3Indigenous
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalFemaleAgeGroup3Non
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalFemaleAgeGroup4Indigenous
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalFemaleAgeGroup4Non
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalFemaleAgeGroup5Indigenous
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalFemaleAgeGroup5Non
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalFemale.toString(),
              totalOtherAgeGroup1Indigenous
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalOtherAgeGroup1Non
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalOtherAgeGroup2Indigenous
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalOtherAgeGroup2Non
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalOtherAgeGroup3Indigenous
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalOtherAgeGroup3Non
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalOtherAgeGroup4Indigenous
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalOtherAgeGroup4Non
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalOtherAgeGroup5Indigenous
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalOtherAgeGroup5Non
                .filter((f) => f.cirLocId === undefined && f.appTypeId === at.applicationTypeId)
                .length.toString(),
              totalOther.toString(),
              (totalMale + totalFemale + totalOther).toString(),
            ];
          })
          .concat([
            [
              'Undefined Location',
              'TOTAL',
              totalMaleAgeGroup1Indigenous.filter((f) => f.cirLocId === undefined).length.toString(),
              totalMaleAgeGroup1Non.filter((f) => f.cirLocId === undefined).length.toString(),
              totalMaleAgeGroup2Indigenous.filter((f) => f.cirLocId === undefined).length.toString(),
              totalMaleAgeGroup2Non.filter((f) => f.cirLocId === undefined).length.toString(),
              totalMaleAgeGroup3Indigenous.filter((f) => f.cirLocId === undefined).length.toString(),
              totalMaleAgeGroup3Non.filter((f) => f.cirLocId === undefined).length.toString(),
              totalMaleAgeGroup4Indigenous.filter((f) => f.cirLocId === undefined).length.toString(),
              totalMaleAgeGroup4Non.filter((f) => f.cirLocId === undefined).length.toString(),
              totalMaleAgeGroup5Indigenous.filter((f) => f.cirLocId === undefined).length.toString(),
              totalMaleAgeGroup5Non.filter((f) => f.cirLocId === undefined).length.toString(),
              '',
              totalFemaleAgeGroup1Indigenous.filter((f) => f.cirLocId === undefined).length.toString(),
              totalFemaleAgeGroup1Non.filter((f) => f.cirLocId === undefined).length.toString(),
              totalFemaleAgeGroup2Indigenous.filter((f) => f.cirLocId === undefined).length.toString(),
              totalFemaleAgeGroup2Non.filter((f) => f.cirLocId === undefined).length.toString(),
              totalFemaleAgeGroup3Indigenous.filter((f) => f.cirLocId === undefined).length.toString(),
              totalFemaleAgeGroup3Non.filter((f) => f.cirLocId === undefined).length.toString(),
              totalFemaleAgeGroup4Indigenous.filter((f) => f.cirLocId === undefined).length.toString(),
              totalFemaleAgeGroup4Non.filter((f) => f.cirLocId === undefined).length.toString(),
              totalFemaleAgeGroup5Indigenous.filter((f) => f.cirLocId === undefined).length.toString(),
              totalFemaleAgeGroup5Non.filter((f) => f.cirLocId === undefined).length.toString(),
              '',
              totalOtherAgeGroup1Indigenous.filter((f) => f.cirLocId === undefined).length.toString(),
              totalOtherAgeGroup1Non.filter((f) => f.cirLocId === undefined).length.toString(),
              totalOtherAgeGroup2Indigenous.filter((f) => f.cirLocId === undefined).length.toString(),
              totalOtherAgeGroup2Non.filter((f) => f.cirLocId === undefined).length.toString(),
              totalOtherAgeGroup3Indigenous.filter((f) => f.cirLocId === undefined).length.toString(),
              totalOtherAgeGroup3Non.filter((f) => f.cirLocId === undefined).length.toString(),
              totalOtherAgeGroup4Indigenous.filter((f) => f.cirLocId === undefined).length.toString(),
              totalOtherAgeGroup4Non.filter((f) => f.cirLocId === undefined).length.toString(),
              totalOtherAgeGroup5Indigenous.filter((f) => f.cirLocId === undefined).length.toString(),
              totalOtherAgeGroup5Non.filter((f) => f.cirLocId === undefined).length.toString(),
              '',
              '',
            ],
          ]),
      ])
      .concat([
        [
          [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
          ],
          [
            'TOTAL',
            '',
            totalMaleAgeGroup1Indigenous.length.toString(),
            totalMaleAgeGroup1Non.length.toString(),
            totalMaleAgeGroup2Indigenous.length.toString(),
            totalMaleAgeGroup2Non.length.toString(),
            totalMaleAgeGroup3Indigenous.length.toString(),
            totalMaleAgeGroup3Non.length.toString(),
            totalMaleAgeGroup4Indigenous.length.toString(),
            totalMaleAgeGroup4Non.length.toString(),
            totalMaleAgeGroup5Indigenous.length.toString(),
            totalMaleAgeGroup5Non.length.toString(),
            '',
            totalFemaleAgeGroup1Indigenous.length.toString(),
            totalFemaleAgeGroup1Non.length.toString(),
            totalFemaleAgeGroup2Indigenous.length.toString(),
            totalFemaleAgeGroup2Non.length.toString(),
            totalFemaleAgeGroup3Indigenous.length.toString(),
            totalFemaleAgeGroup3Non.length.toString(),
            totalFemaleAgeGroup4Indigenous.length.toString(),
            totalFemaleAgeGroup4Non.length.toString(),
            totalFemaleAgeGroup5Indigenous.length.toString(),
            totalFemaleAgeGroup5Non.length.toString(),
            '',
            totalOtherAgeGroup1Indigenous.length.toString(),
            totalOtherAgeGroup1Non.length.toString(),
            totalOtherAgeGroup2Indigenous.length.toString(),
            totalOtherAgeGroup2Non.length.toString(),
            totalOtherAgeGroup3Indigenous.length.toString(),
            totalOtherAgeGroup3Non.length.toString(),
            totalOtherAgeGroup4Indigenous.length.toString(),
            totalOtherAgeGroup4Non.length.toString(),
            totalOtherAgeGroup5Indigenous.length.toString(),
            totalOtherAgeGroup5Non.length.toString(),
            '',
            '',
          ],
        ],
      ])
      .flat();
    const rows = [genderRow, ageRow, minorityRow].concat(data);
    const from = formatDate(fromDate);
    const to = formatDate(toDate);

    const csvContent = rows.map((e) => e.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });

    const link = document.createElement('a');
    link.setAttribute('href', window.URL.createObjectURL(blob));
    link.setAttribute('download', 'Total People Served - From ' + from + ' to ' + to + '.csv');
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
        <Row className={'flex-fill'}>
          <Col md={3} xl={2}>
            <NavbarBrand>Total People Served</NavbarBrand>
          </Col>
          <Col className={'d-flex'}>
            <Button className={'ml-auto'} color={'primary'} onClick={() => downloadCSV()}>
              Export Report to CSV <FaFileDownload />
            </Button>
            <Button className={'ml-2'} color={'warning'} onClick={() => window.print()}>
              Print <FaPrint />
            </Button>
          </Col>
        </Row>
      </Navbar>
      <Container className={'container--no-margin-print'}>
        <Row className={'my-5 '}>
          <Col>
            <Card className={'no-print'}>
              <CardHeader>Select File Application Date Range</CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Label className={'flex second-label'} style={{ paddingRight: '10px' }}>
                      From
                    </Label>
                    <DatePicker
                      className={'form-control date-select flex'}
                      selected={fromDate}
                      onChange={(date) => {
                        if (date) {
                          setFromDate(date);
                        }
                      }}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      shouldCloseOnSelect={true}
                      dateFormat={'yyyy-MM-dd'}
                    />
                  </Col>
                  <Col>
                    <Label
                      className={'flex second-label'}
                      for={'EndDate'}
                      style={{ paddingRight: '10px', paddingLeft: '10px' }}
                    >
                      To
                    </Label>
                    <DatePicker
                      className={'form-control date-select flex'}
                      selected={toDate}
                      onChange={(date) => {
                        if (date) {
                          setToDate(date);
                        }
                      }}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      shouldCloseOnSelect={true}
                      dateFormat={'yyyy-MM-dd'}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col className={'no-print'}>
            <p className={'text-muted'}>
              *This report includes all files with application dates in the range selected. It only includes files
              approved by intake meaning:
              <ul>
                <li>Files in Approved Stage</li>
                <li>Files in Lawyer Requested Stage</li>
                <li>Files in Board Approved Stage</li>
                <li>Files in Return for Conflict Stage</li>
                <li>Files in Assigned to Lawyer Stage</li>
                <li>Files in Closed Stage</li>
                <li>Client with birthdate</li>
                <li>Client with gender</li>
              </ul>
            </p>
          </Col>
        </Row>
      </Container>
      <div className={'report mt-5 mx-3'}>
        <header className={'report-header'}>
          <h2 className={'text-center'}>Total People Served</h2>
          <h3 className={'text-center h5 text-muted'}>
            {'Total files in table: ' +
              total.length +
              ', Not shown: ' +
              notIncluded.length +
              ' for dates between: ' +
              formatDate(fromDate) +
              ' and ' +
              formatDate(toDate)}
          </h3>
        </header>

        <table className={'report1'}>
          <thead>
            <tr>
              <th className={'header-side'} colSpan={2} rowSpan={3} />
              <th className={'header'} colSpan={11}>
                Male
              </th>
              <th className={'header'} colSpan={11}>
                Female
              </th>
              <th className={'header'} colSpan={11}>
                Other
              </th>
              <th className={'header'} rowSpan={3}>
                Total
              </th>
            </tr>
            <tr>
              <th className={'headerspan-row1'}>17 & Under</th>
              <th className={'headerspan-row1'}>17 & Under</th>
              <th className={'headerspan-row1'}>18 - 29</th>
              <th className={'headerspan-row1'}>18 - 29</th>
              <th className={'headerspan-row1'}>30 - 34</th>
              <th className={'headerspan-row1'}>30 - 34</th>
              <th className={'headerspan-row1'}>35 - 49</th>
              <th className={'headerspan-row1'}>35 - 49</th>
              <th className={'headerspan-row1'}>50 & Above</th>
              <th className={'headerspan-row1'}>50 & Above</th>
              <th className={'headerspan-row1'} />
              <th className={'headerspan-row1'}>17 & Under</th>
              <th className={'headerspan-row1'}>17 & Under</th>
              <th className={'headerspan-row1'}>18 - 29</th>
              <th className={'headerspan-row1'}>18 - 29</th>
              <th className={'headerspan-row1'}>30 - 34</th>
              <th className={'headerspan-row1'}>30 - 34</th>
              <th className={'headerspan-row1'}>35 - 49</th>
              <th className={'headerspan-row1'}>35 - 49</th>
              <th className={'headerspan-row1'}>50 & Above</th>
              <th className={'headerspan-row1'}>50 & Above</th>
              <th className={'headerspan-row1'} />
              <th className={'headerspan-row1'}>17 & Under</th>
              <th className={'headerspan-row1'}>17 & Under</th>
              <th className={'headerspan-row1'}>18 - 29</th>
              <th className={'headerspan-row1'}>18 - 29</th>
              <th className={'headerspan-row1'}>30 - 34</th>
              <th className={'headerspan-row1'}>30 - 34</th>
              <th className={'headerspan-row1'}>35 - 49</th>
              <th className={'headerspan-row1'}>35 - 49</th>
              <th className={'headerspan-row1'}>50 & Above</th>
              <th className={'headerspan-row1'}>50 & Above</th>
              <th className={'headerspan-row1'} />
            </tr>
            <tr>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'} />
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'} />
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'} />
            </tr>
          </thead>
          <tbody>
            {circuitLocations
              .sort((a: ICircuitLocation, b: ICircuitLocation) =>
                b.name.toUpperCase().localeCompare(a.name.toUpperCase())
              )
              .map((location) => {
                const locationFiles = filteredFiles.filter((ff) => ff.cirLocId === location.circuitLocationId);

                let locationMaleAgeGroup1Indigenous: IReportingFile[] = [];
                let locationMaleAgeGroup1Non: IReportingFile[] = [];
                let locationMaleAgeGroup2Indigenous: IReportingFile[] = [];
                let locationMaleAgeGroup2Non: IReportingFile[] = [];
                let locationMaleAgeGroup3Indigenous: IReportingFile[] = [];
                let locationMaleAgeGroup3Non: IReportingFile[] = [];
                let locationMaleAgeGroup4Indigenous: IReportingFile[] = [];
                let locationMaleAgeGroup4Non: IReportingFile[] = [];
                let locationMaleAgeGroup5Indigenous: IReportingFile[] = [];
                let locationMaleAgeGroup5Non: IReportingFile[] = [];

                let locationFemaleAgeGroup1Indigenous: IReportingFile[] = [];
                let locationFemaleAgeGroup1Non: IReportingFile[] = [];
                let locationFemaleAgeGroup2Indigenous: IReportingFile[] = [];
                let locationFemaleAgeGroup2Non: IReportingFile[] = [];
                let locationFemaleAgeGroup3Indigenous: IReportingFile[] = [];
                let locationFemaleAgeGroup3Non: IReportingFile[] = [];
                let locationFemaleAgeGroup4Indigenous: IReportingFile[] = [];
                let locationFemaleAgeGroup4Non: IReportingFile[] = [];
                let locationFemaleAgeGroup5Indigenous: IReportingFile[] = [];
                let locationFemaleAgeGroup5Non: IReportingFile[] = [];

                let locationOtherAgeGroup1Indigenous: IReportingFile[] = [];
                let locationOtherAgeGroup1Non: IReportingFile[] = [];
                let locationOtherAgeGroup2Indigenous: IReportingFile[] = [];
                let locationOtherAgeGroup2Non: IReportingFile[] = [];
                let locationOtherAgeGroup3Indigenous: IReportingFile[] = [];
                let locationOtherAgeGroup3Non: IReportingFile[] = [];
                let locationOtherAgeGroup4Indigenous: IReportingFile[] = [];
                let locationOtherAgeGroup4Non: IReportingFile[] = [];
                let locationOtherAgeGroup5Indigenous: IReportingFile[] = [];
                let locationOtherAgeGroup5Non: IReportingFile[] = [];

                locationFiles.forEach((f) => {
                  const client = individualsContext.state.individuals.find((i) => i.individualId === f.cId);
                  if (client) {
                    const age = client.birthDate
                      ? dateDiffInYears(new Date(client.birthDate), new Date(f.appDate))
                      : undefined;
                    if (client.genderId && client.genderId === GENDER_MALE) {
                      if (age !== undefined && age < 18) {
                        if (client.indigenous || client.visibleMinority) {
                          locationMaleAgeGroup1Indigenous = locationMaleAgeGroup1Indigenous.concat(f);
                          totalMaleAgeGroup1Indigenous = totalMaleAgeGroup1Indigenous.concat(f);
                        } else {
                          locationMaleAgeGroup1Non = locationMaleAgeGroup1Non.concat(f);
                          totalMaleAgeGroup1Non = totalMaleAgeGroup1Non.concat(f);
                        }
                      } else if (age !== undefined && age >= 18 && age <= 29) {
                        if (client.indigenous || client.visibleMinority) {
                          locationMaleAgeGroup2Indigenous = locationMaleAgeGroup2Indigenous.concat(f);
                          totalMaleAgeGroup2Indigenous = totalMaleAgeGroup2Indigenous.concat(f);
                        } else {
                          locationMaleAgeGroup2Non = locationMaleAgeGroup2Non.concat(f);
                          totalMaleAgeGroup2Non = totalMaleAgeGroup2Non.concat(f);
                        }
                      } else if (age !== undefined && age >= 30 && age <= 34) {
                        if (client.indigenous || client.visibleMinority) {
                          locationMaleAgeGroup3Indigenous = locationMaleAgeGroup3Indigenous.concat(f);
                          totalMaleAgeGroup3Indigenous = totalMaleAgeGroup3Indigenous.concat(f);
                        } else {
                          locationMaleAgeGroup3Non = locationMaleAgeGroup3Non.concat(f);
                          totalMaleAgeGroup3Non = totalMaleAgeGroup3Non.concat(f);
                        }
                      } else if (age !== undefined && age >= 35 && age <= 49) {
                        if (client.indigenous || client.visibleMinority) {
                          locationMaleAgeGroup4Indigenous = locationMaleAgeGroup4Indigenous.concat(f);
                          totalMaleAgeGroup4Indigenous = totalMaleAgeGroup4Indigenous.concat(f);
                        } else {
                          locationMaleAgeGroup4Non = locationMaleAgeGroup4Non.concat(f);
                          totalMaleAgeGroup4Non = totalMaleAgeGroup4Non.concat(f);
                        }
                      } else if (age !== undefined && age >= 50) {
                        if (client.indigenous || client.visibleMinority) {
                          locationMaleAgeGroup5Indigenous = locationMaleAgeGroup5Indigenous.concat(f);
                          totalMaleAgeGroup5Indigenous = totalMaleAgeGroup5Indigenous.concat(f);
                        } else {
                          locationMaleAgeGroup5Non = locationMaleAgeGroup5Non.concat(f);
                          totalMaleAgeGroup5Non = totalMaleAgeGroup5Non.concat(f);
                        }
                      }
                    } else if (client.genderId && client.genderId === GENDER_FEMALE) {
                      if (age !== undefined && age < 18) {
                        if (client.indigenous || client.visibleMinority) {
                          locationFemaleAgeGroup1Indigenous = locationFemaleAgeGroup1Indigenous.concat(f);
                          totalFemaleAgeGroup1Indigenous = totalFemaleAgeGroup1Indigenous.concat(f);
                        } else {
                          locationFemaleAgeGroup1Non = locationFemaleAgeGroup1Non.concat(f);
                          totalFemaleAgeGroup1Non = totalFemaleAgeGroup1Non.concat(f);
                        }
                      } else if (age !== undefined && age >= 18 && age <= 29) {
                        if (client.indigenous || client.visibleMinority) {
                          locationFemaleAgeGroup2Indigenous = locationFemaleAgeGroup2Indigenous.concat(f);
                          totalFemaleAgeGroup2Indigenous = totalFemaleAgeGroup2Indigenous.concat(f);
                        } else {
                          locationFemaleAgeGroup2Non = locationFemaleAgeGroup2Non.concat(f);
                          totalFemaleAgeGroup2Non = totalFemaleAgeGroup2Non.concat(f);
                        }
                      } else if (age !== undefined && age >= 30 && age <= 34) {
                        if (client.indigenous || client.visibleMinority) {
                          locationFemaleAgeGroup3Indigenous = locationFemaleAgeGroup3Indigenous.concat(f);
                          totalFemaleAgeGroup3Indigenous = totalFemaleAgeGroup3Indigenous.concat(f);
                        } else {
                          locationFemaleAgeGroup3Non = locationFemaleAgeGroup3Non.concat(f);
                          totalFemaleAgeGroup3Non = totalFemaleAgeGroup3Non.concat(f);
                        }
                      } else if (age !== undefined && age >= 35 && age <= 49) {
                        if (client.indigenous || client.visibleMinority) {
                          locationFemaleAgeGroup4Indigenous = locationFemaleAgeGroup4Indigenous.concat(f);
                          totalFemaleAgeGroup4Indigenous = totalFemaleAgeGroup4Indigenous.concat(f);
                        } else {
                          locationFemaleAgeGroup4Non = locationFemaleAgeGroup4Non.concat(f);
                          totalFemaleAgeGroup4Non = totalFemaleAgeGroup4Non.concat(f);
                        }
                      } else if (age !== undefined && age >= 50) {
                        if (client.indigenous || client.visibleMinority) {
                          locationFemaleAgeGroup5Indigenous = locationFemaleAgeGroup5Indigenous.concat(f);
                          totalFemaleAgeGroup5Indigenous = totalFemaleAgeGroup5Indigenous.concat(f);
                        } else {
                          locationFemaleAgeGroup5Non = locationFemaleAgeGroup5Non.concat(f);
                          totalFemaleAgeGroup5Non = totalFemaleAgeGroup5Non.concat(f);
                        }
                      }
                    } else if (client.genderId && client.genderId === GENDER_OTHER) {
                      if (age !== undefined && age < 18) {
                        if (client.indigenous || client.visibleMinority) {
                          locationOtherAgeGroup1Indigenous = locationOtherAgeGroup1Indigenous.concat(f);
                          totalOtherAgeGroup1Indigenous = totalOtherAgeGroup1Indigenous.concat(f);
                        } else {
                          locationOtherAgeGroup1Non = locationOtherAgeGroup1Non.concat(f);
                          totalOtherAgeGroup1Non = totalOtherAgeGroup1Non.concat(f);
                        }
                      } else if (age !== undefined && age >= 18 && age <= 29) {
                        if (client.indigenous || client.visibleMinority) {
                          locationOtherAgeGroup2Indigenous = locationOtherAgeGroup2Indigenous.concat(f);
                          totalOtherAgeGroup2Indigenous = totalOtherAgeGroup2Indigenous.concat(f);
                        } else {
                          locationOtherAgeGroup2Non = locationOtherAgeGroup2Non.concat(f);
                          totalOtherAgeGroup2Non = totalOtherAgeGroup2Non.concat(f);
                        }
                      } else if (age !== undefined && age >= 30 && age <= 34) {
                        if (client.indigenous || client.visibleMinority) {
                          locationOtherAgeGroup3Indigenous = locationOtherAgeGroup3Indigenous.concat(f);
                          totalOtherAgeGroup3Indigenous = totalOtherAgeGroup3Indigenous.concat(f);
                        } else {
                          locationOtherAgeGroup3Non = locationOtherAgeGroup3Non.concat(f);
                          totalOtherAgeGroup3Non = totalOtherAgeGroup3Non.concat(f);
                        }
                      } else if (age !== undefined && age >= 35 && age <= 49) {
                        if (client.indigenous || client.visibleMinority) {
                          locationOtherAgeGroup4Indigenous = locationOtherAgeGroup4Indigenous.concat(f);
                          totalOtherAgeGroup4Indigenous = totalOtherAgeGroup4Indigenous.concat(f);
                        } else {
                          locationOtherAgeGroup4Non = locationOtherAgeGroup4Non.concat(f);
                          totalOtherAgeGroup4Non = totalOtherAgeGroup4Non.concat(f);
                        }
                      } else if (age !== undefined && age >= 50) {
                        if (client.indigenous || client.visibleMinority) {
                          locationOtherAgeGroup5Indigenous = locationOtherAgeGroup5Indigenous.concat(f);
                          totalOtherAgeGroup5Indigenous = totalOtherAgeGroup5Indigenous.concat(f);
                        } else {
                          locationOtherAgeGroup5Non = locationOtherAgeGroup5Non.concat(f);
                          totalOtherAgeGroup5Non = totalOtherAgeGroup5Non.concat(f);
                        }
                      }
                    }
                  }
                });
                return domainContext.applicationTypes
                  .map((applicationType, index) => {
                    const locationMaleAgeGroup1IndigenousApplication = locationMaleAgeGroup1Indigenous.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationMaleAgeGroup1NonApplication = locationMaleAgeGroup1Non.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationMaleAgeGroup2IndigenousApplication = locationMaleAgeGroup2Indigenous.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationMaleAgeGroup2NonApplication = locationMaleAgeGroup2Non.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationMaleAgeGroup3IndigenousApplication = locationMaleAgeGroup3Indigenous.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationMaleAgeGroup3NonApplication = locationMaleAgeGroup3Non.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationMaleAgeGroup4IndigenousApplication = locationMaleAgeGroup4Indigenous.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationMaleAgeGroup4NonApplication = locationMaleAgeGroup4Non.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationMaleAgeGroup5IndigenousApplication = locationMaleAgeGroup5Indigenous.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationMaleAgeGroup5NonApplication = locationMaleAgeGroup5Non.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const totalMale =
                      locationMaleAgeGroup1IndigenousApplication.length +
                      locationMaleAgeGroup1NonApplication.length +
                      locationMaleAgeGroup2IndigenousApplication.length +
                      locationMaleAgeGroup2NonApplication.length +
                      locationMaleAgeGroup3IndigenousApplication.length +
                      locationMaleAgeGroup3NonApplication.length +
                      locationMaleAgeGroup4IndigenousApplication.length +
                      locationMaleAgeGroup4NonApplication.length +
                      locationMaleAgeGroup5IndigenousApplication.length +
                      locationMaleAgeGroup5NonApplication.length;

                    const locationFemaleAgeGroup1IndigenousApplication = locationFemaleAgeGroup1Indigenous.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationFemaleAgeGroup1NonApplication = locationFemaleAgeGroup1Non.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationFemaleAgeGroup2IndigenousApplication = locationFemaleAgeGroup2Indigenous.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationFemaleAgeGroup2NonApplication = locationFemaleAgeGroup2Non.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationFemaleAgeGroup3IndigenousApplication = locationFemaleAgeGroup3Indigenous.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationFemaleAgeGroup3NonApplication = locationFemaleAgeGroup3Non.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationFemaleAgeGroup4IndigenousApplication = locationFemaleAgeGroup4Indigenous.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationFemaleAgeGroup4NonApplication = locationFemaleAgeGroup4Non.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationFemaleAgeGroup5IndigenousApplication = locationFemaleAgeGroup5Indigenous.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationFemaleAgeGroup5NonApplication = locationFemaleAgeGroup5Non.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );

                    const totalFemale =
                      locationFemaleAgeGroup1IndigenousApplication.length +
                      locationFemaleAgeGroup1NonApplication.length +
                      locationFemaleAgeGroup2IndigenousApplication.length +
                      locationFemaleAgeGroup2NonApplication.length +
                      locationFemaleAgeGroup3IndigenousApplication.length +
                      locationFemaleAgeGroup3NonApplication.length +
                      locationFemaleAgeGroup4IndigenousApplication.length +
                      locationFemaleAgeGroup4NonApplication.length +
                      locationFemaleAgeGroup5IndigenousApplication.length +
                      locationFemaleAgeGroup5NonApplication.length;

                    const locationOtherAgeGroup1IndigenousApplication = locationOtherAgeGroup1Indigenous.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationOtherAgeGroup1NonApplication = locationOtherAgeGroup1Non.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationOtherAgeGroup2IndigenousApplication = locationOtherAgeGroup2Indigenous.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationOtherAgeGroup2NonApplication = locationOtherAgeGroup2Non.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationOtherAgeGroup3IndigenousApplication = locationOtherAgeGroup3Indigenous.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationOtherAgeGroup3NonApplication = locationOtherAgeGroup3Non.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationOtherAgeGroup4IndigenousApplication = locationOtherAgeGroup4Indigenous.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationOtherAgeGroup4NonApplication = locationOtherAgeGroup4Non.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationOtherAgeGroup5IndigenousApplication = locationOtherAgeGroup5Indigenous.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );
                    const locationOtherAgeGroup5NonApplication = locationOtherAgeGroup5Non.filter(
                      (f) => f.appTypeId === applicationType.applicationTypeId
                    );

                    const totalOther =
                      locationOtherAgeGroup1IndigenousApplication.length +
                      locationOtherAgeGroup1NonApplication.length +
                      locationOtherAgeGroup2IndigenousApplication.length +
                      locationOtherAgeGroup2NonApplication.length +
                      locationOtherAgeGroup3IndigenousApplication.length +
                      locationOtherAgeGroup3NonApplication.length +
                      locationOtherAgeGroup4IndigenousApplication.length +
                      locationOtherAgeGroup4NonApplication.length +
                      locationOtherAgeGroup5IndigenousApplication.length +
                      locationOtherAgeGroup5NonApplication.length;

                    return (
                      <tr key={applicationType.applicationTypeId}>
                        {index === 0 && (
                          <th className={'header-side-bottom'} rowSpan={domainContext.applicationTypes.length + 1}>
                            {location.name}
                          </th>
                        )}
                        <th className={'header-side'}>{applicationType.name}</th>
                        <td className={'report-row'}>{locationMaleAgeGroup1IndigenousApplication.length}</td>
                        <td className={'report-row'}>{locationMaleAgeGroup1NonApplication.length}</td>
                        <td className={'report-row'}>{locationMaleAgeGroup2IndigenousApplication.length}</td>
                        <td className={'report-row'}>{locationMaleAgeGroup2NonApplication.length}</td>
                        <td className={'report-row'}>{locationMaleAgeGroup3IndigenousApplication.length}</td>
                        <td className={'report-row'}>{locationMaleAgeGroup3NonApplication.length}</td>
                        <td className={'report-row'}>{locationMaleAgeGroup4IndigenousApplication.length}</td>
                        <td className={'report-row'}>{locationMaleAgeGroup4NonApplication.length}</td>
                        <td className={'report-row'}>{locationMaleAgeGroup5IndigenousApplication.length}</td>
                        <td className={'report-row'}>{locationMaleAgeGroup5NonApplication.length}</td>
                        <td className={'total'}>
                          <b>{totalMale}</b>
                        </td>
                        <td className={'report-row'}>{locationFemaleAgeGroup1IndigenousApplication.length}</td>
                        <td className={'report-row'}>{locationFemaleAgeGroup1NonApplication.length}</td>
                        <td className={'report-row'}>{locationFemaleAgeGroup2IndigenousApplication.length}</td>
                        <td className={'report-row'}>{locationFemaleAgeGroup2NonApplication.length}</td>
                        <td className={'report-row'}>{locationFemaleAgeGroup3IndigenousApplication.length}</td>
                        <td className={'report-row'}>{locationFemaleAgeGroup3NonApplication.length}</td>
                        <td className={'report-row'}>{locationFemaleAgeGroup4IndigenousApplication.length}</td>
                        <td className={'report-row'}>{locationFemaleAgeGroup4NonApplication.length}</td>
                        <td className={'report-row'}>{locationFemaleAgeGroup5IndigenousApplication.length}</td>
                        <td className={'report-row'}>{locationFemaleAgeGroup5NonApplication.length}</td>
                        <td className={'total'}>
                          <b>{totalFemale}</b>
                        </td>
                        <td className={'report-row'}>{locationOtherAgeGroup1IndigenousApplication.length}</td>
                        <td className={'report-row'}>{locationOtherAgeGroup1NonApplication.length}</td>
                        <td className={'report-row'}>{locationOtherAgeGroup2IndigenousApplication.length}</td>
                        <td className={'report-row'}>{locationOtherAgeGroup2NonApplication.length}</td>
                        <td className={'report-row'}>{locationOtherAgeGroup3IndigenousApplication.length}</td>
                        <td className={'report-row'}>{locationOtherAgeGroup3NonApplication.length}</td>
                        <td className={'report-row'}>{locationOtherAgeGroup4IndigenousApplication.length}</td>
                        <td className={'report-row'}>{locationOtherAgeGroup4NonApplication.length}</td>
                        <td className={'report-row'}>{locationOtherAgeGroup5IndigenousApplication.length}</td>
                        <td className={'report-row'}>{locationOtherAgeGroup5NonApplication.length}</td>
                        <td className={'total'}>
                          <b>{totalOther}</b>
                        </td>
                        <td className={'total'}>
                          <b>{totalMale + totalFemale + totalOther}</b>
                        </td>
                      </tr>
                    );
                  })
                  .concat(
                    <tr key={'total-' + location.circuitLocationId}>
                      <th className={'header-side-bottom'} />
                      <td className={'total-bottom'}>
                        <b>{locationMaleAgeGroup1Indigenous.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationMaleAgeGroup1Non.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationMaleAgeGroup2Indigenous.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationMaleAgeGroup2Non.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationMaleAgeGroup3Indigenous.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationMaleAgeGroup3Non.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationMaleAgeGroup4Indigenous.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationMaleAgeGroup4Non.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationMaleAgeGroup5Indigenous.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationMaleAgeGroup5Non.length}</b>
                      </td>
                      <td className={'total-bottom'} />
                      <td className={'total-bottom'}>
                        <b>{locationFemaleAgeGroup1Indigenous.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationFemaleAgeGroup1Non.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationFemaleAgeGroup2Indigenous.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationFemaleAgeGroup2Non.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationFemaleAgeGroup3Indigenous.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationFemaleAgeGroup3Non.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationFemaleAgeGroup4Indigenous.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationFemaleAgeGroup4Non.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationFemaleAgeGroup5Indigenous.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationFemaleAgeGroup5Non.length}</b>
                      </td>
                      <td className={'total-bottom'} />
                      <td className={'total-bottom'}>
                        <b>{locationOtherAgeGroup1Indigenous.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationOtherAgeGroup1Non.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationOtherAgeGroup2Indigenous.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationOtherAgeGroup2Non.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationOtherAgeGroup3Indigenous.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationOtherAgeGroup3Non.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationOtherAgeGroup4Indigenous.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationOtherAgeGroup4Non.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationOtherAgeGroup5Indigenous.length}</b>
                      </td>
                      <td className={'total-bottom'}>
                        <b>{locationOtherAgeGroup5Non.length}</b>
                      </td>
                      <td className={'total-bottom'} />
                      <td className={'total-bottom'} />
                    </tr>
                  );
              })}
            {undefinedCircuitLcations()}
            <tr key={'total'}>
              <th className={'header-side'} colSpan={2}>
                Total
              </th>
              <td className={'total'}>
                <b>{totalMaleAgeGroup1Indigenous.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalMaleAgeGroup1Non.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalMaleAgeGroup2Indigenous.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalMaleAgeGroup2Non.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalMaleAgeGroup3Indigenous.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalMaleAgeGroup3Non.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalMaleAgeGroup4Indigenous.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalMaleAgeGroup4Non.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalMaleAgeGroup5Indigenous.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalMaleAgeGroup5Non.length}</b>
              </td>
              <td className={'total'} />
              <td className={'total'}>
                <b>{totalFemaleAgeGroup1Indigenous.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalFemaleAgeGroup1Non.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalFemaleAgeGroup2Indigenous.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalFemaleAgeGroup2Non.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalFemaleAgeGroup3Indigenous.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalFemaleAgeGroup3Non.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalFemaleAgeGroup4Indigenous.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalFemaleAgeGroup4Non.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalFemaleAgeGroup5Indigenous.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalFemaleAgeGroup5Non.length}</b>
              </td>
              <td className={'total'} />
              <td className={'total'}>
                <b>{totalOtherAgeGroup1Indigenous.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalOtherAgeGroup1Non.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalOtherAgeGroup2Indigenous.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalOtherAgeGroup2Non.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalOtherAgeGroup3Indigenous.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalOtherAgeGroup3Non.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalOtherAgeGroup4Indigenous.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalOtherAgeGroup4Non.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalOtherAgeGroup5Indigenous.length}</b>
              </td>
              <td className={'total'}>
                <b>{totalOtherAgeGroup5Non.length}</b>
              </td>
              <td className={'total'} />
              <td className={'total'} />
            </tr>
          </tbody>
        </table>
      </div>
      <div className={'px-5 pb-5 no-print'}>
        <p>Files not included: </p>
        <ul>
          {notIncluded.map((f) => (
            <li key={f.fId}>
              {myUserContext.isReportingOnly ? (
                <div>{f.fNum + (f.fSeqNum > 1 ? '-' + f.fSeqNum : '')}</div>
              ) : (
                <Link to={'/files/' + f.fId}>{f.fNum + (f.fSeqNum > 1 ? '-' + f.fSeqNum : '')}</Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
