import * as React from 'react';
import { Label, LabelProps } from 'reactstrap';

interface IRequiredLabel extends LabelProps {
  required: boolean;
}

export const RequiredLabel: React.FC<IRequiredLabel> = ({ children, required, ...props }) => {
  return (
    <Label {...props}>
      {children} <span className={`text-required${required ? '' : ' sr-only'}`}>(required)</span>
    </Label>
  );
};
