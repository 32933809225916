import * as React from 'react';
import { useContext, useState } from 'react';
import { Button, Card, CardBody, CardHeader, FormGroup, Input, Label, ListGroup, ListGroupItem } from 'reactstrap';
import { STAGE_PENDING } from '../../../contexts/DomainContext';
import { authAxios } from '../../../services/AxiosService';
import { notifyError, notifySuccess } from '../../../services/ToastService';
import { FileContext } from '../FileContext';
import { useParams } from 'react-router';
import { ConfirmModal } from '../../ConfirmModal/ConfirmModal';

export const StoppedActions: React.FC = () => {
  const params = useParams<{ fileId: string }>();
  const fileContext = useContext(FileContext);
  const file = fileContext.file;
  const setFile = fileContext.setFile;

  const [resumeModal, setResumeModal] = useState(false);

  const updateStage = (stageId: string) => {
    if (params.fileId) {
      const data = { fileId: params.fileId, stageId };
      authAxios.post('/api/files/admin/start-stage', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('File stage has been updated.');
          setFile((f) => ({ ...f, stageId: stageId }));
        } else {
          notifyError(response.data.status);
        }
      });
    }
  };

  return (
    <>
      <Card className={'bg-primary-light'}>
        <CardHeader>Application Process</CardHeader>
        <CardBody style={{ padding: 0 }}>
          <ListGroup flush={true}>
            <ListGroupItem className={'bg-primary-light'}>
              <FormGroup>
                <Label>Stopped Reason</Label>
                <Input value={file.reasonStopped} type={'text'} readOnly={true} />
              </FormGroup>
              <Button
                color='success'
                className='ml-auto'
                onClick={() => {
                  setResumeModal(true);
                }}
              >
                Resume
              </Button>
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>

      <ConfirmModal
        isOpen={resumeModal}
        title={'Resume'}
        onConfirm={() => {
          setResumeModal(false);
          updateStage(STAGE_PENDING);
        }}
        onCancel={() => setResumeModal(false)}
        confirmButton={'Resume'}
      >
        <div>Are you sure you want to Resume this application?</div>
      </ConfirmModal>
    </>
  );
};
