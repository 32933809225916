import * as React from 'react';
import { FormEvent, useContext, useEffect, useState } from 'react';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import { Button, Card, CardBody, CardHeader, Col, Input, Label, Navbar, NavbarBrand, Row } from 'reactstrap';
import { useParams } from 'react-router';
import { authAxios } from '../../services/AxiosService';
import { useHistory } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../services/ToastService';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { MyUserContext } from '../../contexts/MyUserContext';
import { IIntervention, SupportWorkerRequestContext } from 'src/components/SupportRequests/SupportWorkerRequestContext';
import {
  DomainContext,
  IInterventionStatus,
  IInterventionType,
  INTERVENTION_STATUS_IN_PROGRESS,
  INTERVENTION_STATUS_INCOMPLETE,
  SUPPORT_REQUEST,
} from 'src/contexts/DomainContext';
import Select from 'react-select';
import { InterventionNotes } from 'src/components/SupportRequests/InterventionNotes';
import { InterventionRecordHours } from 'src/components/SupportRequests/InterventionRecordHours';

export const SupportWorkerRequestInterventionsDetails: React.FC = () => {
  const myUserContext = useContext(MyUserContext);
  const domainContext = useContext(DomainContext);
  const { setInterventions, interventions, supportWorkerRequest } = useContext(SupportWorkerRequestContext);
  const interventionStatuses = domainContext.interventionStatuses;
  const interventionTypes = domainContext.interventionTypes;

  const [isDirty, setIsDirty] = useState(false);
  const params = useParams<{ supportWorkerRequestId: string; interventionId?: string }>();
  const [interventionTypeId, setInterventionTypeId] = useState('');
  const [details, setDetails] = useState('');
  const [interventionStatusId, setInterventionStatusId] = useState(INTERVENTION_STATUS_IN_PROGRESS);
  const [reason, setReason] = useState<string | undefined>(undefined);
  const history = useHistory();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);

  useEffect(() => {
    if (params.interventionId) {
      authAxios
        .get(
          '/api/support-worker-requests/admin/' +
            params.supportWorkerRequestId +
            '/interventions/' +
            params.interventionId
        )
        .then((response) => {
          const intervention: IIntervention = response.data;
          setInterventionTypeId(intervention.interventionTypeId);
          setDetails(intervention.details);
          setInterventionStatusId(intervention.interventionStatusId);
          setReason(intervention.reason);
        });
    }
  }, [params.supportWorkerRequestId, params.interventionId]);

  const save = () => {
    if (
      !interventionStatusId ||
      !interventionTypeId ||
      (interventionStatusId === INTERVENTION_STATUS_INCOMPLETE && !reason)
    ) {
      setTrySubmit(true);
    } else {
      if (params.interventionId) {
        setTrySubmit(false);
        //update
        authAxios
          .post('/api/support-worker-requests/admin/update-intervention', {
            supportWorkerRequestId: params.supportWorkerRequestId,
            interventionId: params.interventionId,
            interventionTypeId: interventionTypeId,
            details: details,
            interventionStatusId: interventionStatusId,
            reason: reason,
          })
          .then((response) => {
            if (response.data.status === 'OK') {
              setIsDirty(false);
              notifySuccess('Intervention updated.');
              const updatedIntervention = {
                interventionId: params.interventionId,
                supportWorkerRequestId: params.supportWorkerRequestId,
                interventionTypeId: interventionTypeId,
                details: details,
                interventionStatusId: interventionStatusId,
                reason: reason,
              } as IIntervention;
              const updatedInterventions = interventions
                .filter((i) => i.interventionId !== params.interventionId)
                .concat(updatedIntervention);
              setInterventions(updatedInterventions);
            } else {
              notifyError('Error updating intervention.');
            }
          });
      } else {
        //create
        authAxios
          .post('/api/support-worker-requests/admin/create-intervention', {
            supportWorkerRequestId: params.supportWorkerRequestId,
            interventionTypeId: interventionTypeId,
            details: details,
            interventionStatusId: interventionStatusId,
            reason: reason,
          })
          .then((response) => {
            if (response.data.status === 'OK') {
              setIsDirty(false);
              notifySuccess('Intervention created.');
              const interventionId = response.data.interventionId;
              const intervention = {
                interventionId: interventionId,
                supportWorkerRequestId: params.supportWorkerRequestId,
                interventionTypeId: interventionTypeId,
                details: details,
                interventionStatusId: interventionStatusId,
                reason: reason,
              } as IIntervention;
              setInterventions((i) => i.concat(intervention));
              history.push('/support-worker-requests/' + params.supportWorkerRequestId + '/interventions/');
            } else {
              notifyError('Error creating intervention.');
            }
          });
      }
    }
  };

  const deleteIntervention = () => {
    if (params.interventionId) {
      authAxios
        .post('/api/support-worker-requests/admin/delete-intervention', {
          supportWorkerRequestId: params.supportWorkerRequestId,
          interventionId: params.interventionId,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            notifySuccess('Intervention deleted.');
            setInterventions((interventions) =>
              interventions.filter((i) => i.interventionId !== params.interventionId)
            );
            history.replace('/support-worker-requests/' + params.supportWorkerRequestId + '/interventions');
          } else {
            notifyError(response.data.errorMessage);
          }
        });
    }
  };

  const disabledSelectStyles = {
    control: (styles: any, state: any) => {
      return {
        ...styles,
        borderColor: '#ced4da',
      };
    },
    singleValue: (styles: any, state: any) => {
      return {
        ...styles,
        color: '#495057',
      };
    },
  };

  return (
    <div>
      <LeavePageConfirm isDirty={isDirty} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
          <Row className={'flex-fill'}>
            <Col md={3} xl={2}>
              <NavbarBrand>{params.interventionId ? 'Edit Intervention' : 'Add Intervention'}</NavbarBrand>
            </Col>
            {myUserContext.isSupportWorker && supportWorkerRequest.status === SUPPORT_REQUEST.ACCEPTED && (
              <Col className={'d-flex'}>
                {params.interventionId ? (
                  <Button color='danger' className={'ml-auto'} onClick={() => setDeleteModalOpen(true)}>
                    Delete Intervention
                  </Button>
                ) : (
                  <Button
                    color='link'
                    className={'ml-auto'}
                    onClick={() =>
                      history.push('/support-worker-requests/' + params.supportWorkerRequestId + '/interventions')
                    }
                  >
                    Cancel
                  </Button>
                )}
                <Button color='success' className={'ml-2'} onClick={() => save()}>
                  Save Intervention
                </Button>
              </Col>
            )}
          </Row>
        </Navbar>
        <div className={'m-3'}>
          <Card className={'mb-3'}>
            <CardHeader>Details</CardHeader>
            <CardBody>
              <Row>
                <Col sm={3} md={3} lg={3}>
                  <Label>Intervention Type</Label>
                  <Select
                    styles={disabledSelectStyles}
                    name={'interventionTypeId'}
                    options={interventionTypes}
                    value={interventionTypes.find(
                      (it: IInterventionType) => it.interventionTypeId === interventionTypeId
                    )}
                    onChange={(value: any) => {
                      const interventionType = value?.interventionTypeId || '';
                      setInterventionTypeId(interventionType);
                      setIsDirty(true);
                    }}
                    getOptionValue={(option: IInterventionType) => option.interventionTypeId}
                    getOptionLabel={(option: IInterventionType) => option.name}
                    isClearable={true}
                    isDisabled={
                      !myUserContext.isSupportWorker || supportWorkerRequest.status !== SUPPORT_REQUEST.ACCEPTED
                    }
                  />
                  {trySubmit && !interventionTypeId && (
                    <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                  )}
                </Col>
                <Col sm={4} md={4} lg={4}>
                  <Label>Details</Label>
                  <Input
                    type={'text'}
                    placeholder='Type here...'
                    name='Details'
                    value={details}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const interventionDetails = e.target.value;
                      setDetails(interventionDetails);
                      setIsDirty(true);
                    }}
                    disabled={
                      !myUserContext.isSupportWorker || supportWorkerRequest.status !== SUPPORT_REQUEST.ACCEPTED
                    }
                  />
                </Col>
                <Col sm={2} md={2} lg={2}>
                  <Label>Status</Label>
                  <Select
                    styles={disabledSelectStyles}
                    name={'interventionStatusId'}
                    options={interventionStatuses}
                    value={interventionStatuses.find(
                      (it: IInterventionStatus) => it.interventionStatusId === interventionStatusId
                    )}
                    onChange={(value: any) => {
                      const interventionStatus = value?.interventionStatusId || '';
                      setInterventionStatusId(interventionStatus);
                      if (interventionStatus !== INTERVENTION_STATUS_INCOMPLETE) {
                        setReason(undefined);
                      }
                      setIsDirty(true);
                    }}
                    getOptionValue={(option: IInterventionStatus) => option.interventionStatusId}
                    getOptionLabel={(option: IInterventionStatus) => option.name}
                    isClearable={true}
                    isDisabled={
                      !myUserContext.isSupportWorker || supportWorkerRequest.status !== SUPPORT_REQUEST.ACCEPTED
                    }
                  />
                  {trySubmit && !interventionStatusId && (
                    <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                  )}
                </Col>
                {interventionStatusId === INTERVENTION_STATUS_INCOMPLETE && (
                  <Col sm={3} md={3} lg={3}>
                    <Label>Reason</Label>
                    <Input
                      type={'text'}
                      placeholder='Type here...'
                      name='Reason'
                      value={reason ? reason : ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const interventionIncompleteReason = e.target.value;
                        setReason(interventionIncompleteReason);
                        setIsDirty(true);
                      }}
                      disabled={
                        !myUserContext.isSupportWorker || supportWorkerRequest.status !== SUPPORT_REQUEST.ACCEPTED
                      }
                    />
                    {trySubmit && interventionStatusId === INTERVENTION_STATUS_INCOMPLETE && !reason && (
                      <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                    )}
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
          {params.interventionId && (
            <Row>
              <Col sm={8} md={8} lg={8}>
                <InterventionNotes />
              </Col>
              <Col sm={4} md={4} lg={4}>
                <InterventionRecordHours />
              </Col>
            </Row>
          )}
        </div>
      </form>
      <ConfirmModal
        isOpen={deleteModalOpen}
        title={'Delete Intervention'}
        onConfirm={() => {
          setDeleteModalOpen(false);
          deleteIntervention();
        }}
        onCancel={() => setDeleteModalOpen(false)}
        confirmButton={'Delete Intervention'}
      >
        <div>Are you sure you want to delete this intervention?</div>
      </ConfirmModal>
    </div>
  );
};
