import * as React from 'react';
import { useContext, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import { DomainContext, IReason, STAGE_APPROVED, STAGE_BOARD_REVIEW } from '../../../contexts/DomainContext';
import { authAxios } from '../../../services/AxiosService';
import { notifyError, notifySuccess } from '../../../services/ToastService';
import { FileContext } from '../FileContext';
import { useParams } from 'react-router';
import { ConfirmModal } from '../../ConfirmModal/ConfirmModal';
import Select from 'react-select';

interface IProps {
  isEditing: boolean;
}

export const RefusedActions: React.FC<IProps> = (props: IProps) => {
  const params = useParams<{ fileId: string }>();
  const fileContext = useContext(FileContext);
  const file = fileContext.file;
  const setFile = fileContext.setFile;
  const domainContext = useContext(DomainContext);
  const reasons = domainContext.reasons;

  const [boardReviewModal, setBoardReviewModal] = useState(false);
  const [approveEDModal, setApproveEDModal] = useState(false);

  const updateStage = (stageId: string, edApproval: boolean) => {
    if (params.fileId) {
      const data = { fileId: params.fileId, stageId, EDApproval: edApproval };
      authAxios.post('/api/files/admin/start-stage', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('File stage has been updated.');
          setFile((f) => ({ ...f, stageId: stageId }));
        } else {
          notifyError(response.data.status);
        }
      });
    }
  };

  return (
    <>
      <Card className={'bg-primary-light'}>
        <CardHeader>Application Process</CardHeader>
        <CardBody style={{ padding: 0 }}>
          <ListGroup flush={true}>
            <ListGroupItem className={'bg-primary-light'}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Reason for Refusal</Label>
                    <Select
                      name={'reasonId'}
                      options={reasons}
                      value={reasons.find((r) => r.reasonId === file.reasonId)}
                      onChange={(value: any) => {
                        fileContext.setFile((f) => ({ ...f, reasonId: value?.reasonId || '' }));
                      }}
                      getOptionValue={(option: IReason) => option.reasonId}
                      getOptionLabel={(option: IReason) => option.name}
                      isDisabled={!props.isEditing}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Refusal Notes</Label>
                    <Input
                      value={file.reasonRejected}
                      type={'text'}
                      readOnly={!props.isEditing}
                      onChange={(e: any) => {
                        const reasonRejected = e.target.value;
                        fileContext.setFile((f) => ({ ...f, reasonRejected }));
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button
                color='success'
                className='ml-auto'
                onClick={() => {
                  setBoardReviewModal(true);
                }}
              >
                Start Board Review
              </Button>
              <Button
                color='success'
                className='ml-2'
                onClick={() => {
                  setApproveEDModal(true);
                }}
              >
                Approved by Executive Director
              </Button>
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>

      <ConfirmModal
        isOpen={boardReviewModal}
        title={'Start Board Review'}
        onConfirm={() => {
          setBoardReviewModal(false);
          updateStage(STAGE_BOARD_REVIEW, false);
        }}
        onCancel={() => setBoardReviewModal(false)}
        confirmButton={'Start Board Review'}
      >
        <div>Are you sure you want to start a Board Review for this Application?</div>
      </ConfirmModal>
      <ConfirmModal
        isOpen={approveEDModal}
        title={'Approve File'}
        onConfirm={() => {
          setApproveEDModal(false);
          updateStage(STAGE_APPROVED, true);
        }}
        onCancel={() => setApproveEDModal(false)}
        confirmButton={'Approve'}
      >
        <div>Are you sure you want to Approve this file?</div>
      </ConfirmModal>
    </>
  );
};
