import React, { useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Navbar, NavbarBrand, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { getJwtFromStorage } from '../../services/AuthenticationService';
import { formatDateForServer } from '../../formatters/DateTimeFormatter';

const addAndClickLink = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'download');
  document.body.appendChild(link);
  link.click();
};

export const Exports: React.FC = () => {
  const defaultFromDate = new Date();
  const [fromDate, setFromDate] = useState<Date>(
    new Date(defaultFromDate.getMonth() < 3 ? defaultFromDate.getFullYear() - 1 : defaultFromDate.getFullYear(), 3, 1)
  );
  const defaultToDate = new Date();
  defaultToDate.setUTCHours(23, 59, 59, 999);
  const [toDate, setToDate] = useState<Date>(defaultToDate);

  const exportAllFiles = () => {
    if (fromDate && toDate) {
      const url = `/api/reports/file-export/${getJwtFromStorage()}?fromDate=${formatDateForServer(
        fromDate
      )}&toDate=${formatDateForServer(toDate)}`;
      addAndClickLink(url);
    }
  };

  const exportAllSupportRequests = () => {
    if (fromDate && toDate) {
      const url = `/api/reports/support-request-export/${getJwtFromStorage()}?fromDate=${formatDateForServer(
        fromDate
      )}&toDate=${formatDateForServer(toDate)}`;
      addAndClickLink(url);
    }
  };

  const exportAllChargesMatters = () => {
    if (fromDate && toDate) {
      const url = `/api/reports/charges-matters-export/${getJwtFromStorage()}?fromDate=${formatDateForServer(
        fromDate
      )}&toDate=${formatDateForServer(toDate)}`;
      addAndClickLink(url);
    }
  };

  const exportSupportWorkerHours = () => {
    if (fromDate && toDate) {
      const url = `/api/reports/support-worker-hours-export/${getJwtFromStorage()}?fromDate=${formatDateForServer(
        fromDate
      )}&toDate=${formatDateForServer(toDate)}`;
      addAndClickLink(url);
    }
  };

  const exportDutyCounselAppearancesAppDate = () => {
    if (fromDate && toDate) {
      const url = `/api/reports/dc-export-app-date/${getJwtFromStorage()}?fromDate=${formatDateForServer(
        fromDate
      )}&toDate=${formatDateForServer(toDate)}`;
      addAndClickLink(url);
    }
  };

  const exportDutyCounselAppearancesDCDate = () => {
    if (fromDate && toDate) {
      const url = `/api/reports/dc-export-dc-date/${getJwtFromStorage()}?fromDate=${formatDateForServer(
        fromDate
      )}&toDate=${formatDateForServer(toDate)}`;
      addAndClickLink(url);
    }
  };

  const exportLawyerHoursAppDate = () => {
    if (fromDate && toDate) {
      const url = `/api/reports/lawyer-hour-app-date/${getJwtFromStorage()}?fromDate=${formatDateForServer(
        fromDate
      )}&toDate=${formatDateForServer(toDate)}`;
      addAndClickLink(url);
    }
  };

  const exportLawyerHoursEntryDate = () => {
    if (fromDate && toDate) {
      const url = `/api/reports/lawyer-hour-entry-date/${getJwtFromStorage()}?fromDate=${formatDateForServer(
        fromDate
      )}&toDate=${formatDateForServer(toDate)}`;
      addAndClickLink(url);
    }
  };

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
        <Row className={'flex-fill'}>
          <Col md={3} xl={2}>
            <NavbarBrand>Data Exports to CSV</NavbarBrand>
          </Col>
        </Row>
      </Navbar>
      <Container className={'my-5 container--no-margin-print'}>
        <Card>
          <CardHeader>Select Date Range</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <Label className={'flex second-label'} style={{ paddingRight: '10px' }}>
                  From
                </Label>
                <DatePicker
                  className={'form-control date-select flex'}
                  selected={fromDate}
                  onChange={(date) => {
                    if (date) {
                      setFromDate(date);
                    }
                  }}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  shouldCloseOnSelect={true}
                  dateFormat={'yyyy-MM-dd'}
                />
              </Col>
              <Col>
                <Label
                  className={'flex second-label'}
                  for={'EndDate'}
                  style={{ paddingRight: '10px', paddingLeft: '10px' }}
                >
                  To
                </Label>
                <DatePicker
                  className={'form-control date-select flex'}
                  selected={toDate}
                  onChange={(date) => {
                    if (date) {
                      setToDate(date);
                    }
                  }}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  shouldCloseOnSelect={true}
                  dateFormat={'yyyy-MM-dd'}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Row className={'mt-5'}>
          <p>
            <b>Files: </b>Export all files with an application date in the range above.
          </p>
        </Row>
        <Row>
          <Button onClick={() => exportAllFiles()}>Export Files (File Application Date)</Button>
        </Row>
        <Row className={'mt-4'}>
          <p>
            <b>Support Requests: </b>Export all support requests with a submitted date in the range above.
          </p>
        </Row>
        <Row>
          <Button onClick={() => exportAllSupportRequests()}>Support Requests (Submitted Date)</Button>
        </Row>
        <Row className={'mt-4'}>
          <p>
            <b>Charges/Matters: </b>Export all charges/matters on files with an application date in the range above.
          </p>
        </Row>
        <Row>
          <Button onClick={() => exportAllChargesMatters()}>Export Charges / Matters (File Application Date)</Button>
        </Row>
        <Row className={'mt-4'}>
          <p>
            <b>Support Worker Hours:</b> Export all support worker time entries with an entry date in the range above.
            (Generic time entries only)
          </p>
        </Row>
        <Row>
          <Button onClick={() => exportSupportWorkerHours()}>Export Support Worker Hours (Entry Date)</Button>
        </Row>
        <Row className={'mt-4'}>
          <p>
            <b>Lawyer Hours: </b>
          </p>
        </Row>
        <Row className={'mt-0 ml-1'}>
          <Col>
            <Row className={'mt-0'}>
              <p>
                Export all lawyer hour entries on files with an application date in the range above. (File Specific time
                entries only)
              </p>
            </Row>
            <Row>
              <Button onClick={() => exportLawyerHoursAppDate()}>Export Lawyer Hours (File Application Date)</Button>
            </Row>
          </Col>
          <Col>
            <Row className={'mt-0'}>
              <p>
                Export all lawyer hour entries with an entry date in the range above. (Includes both file time entries
                and generic time entries)
              </p>
            </Row>
            <Row>
              <Button onClick={() => exportLawyerHoursEntryDate()}>Export Lawyer Hours (Entry Date)</Button>
            </Row>
          </Col>
        </Row>
        <Row className={'mt-4'}>
          <p>
            <b>Duty Counsel Appearances: </b>
          </p>
        </Row>
        <Row className={'mt-0 ml-1'}>
          <Col>
            <Row className={'mt-0'}>
              <p>Export all duty counsel appearances on files with an application date in the range above.</p>
            </Row>
            <Row>
              <Button onClick={() => exportDutyCounselAppearancesAppDate()}>
                Export Duty Counsel Appearances (File Application Date)
              </Button>
            </Row>
          </Col>
          <Col>
            <Row className={'mt-0'}>
              <p>Export all duty counsel appearances with an appearance date in the range above.</p>
            </Row>
            <Row>
              <Button onClick={() => exportDutyCounselAppearancesDCDate()}>
                Export Duty Counsel Appearances (Appearance Date)
              </Button>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
