import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Link, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import * as H from 'history';
import { Badge, Button, Col, Container, Nav, NavItem, NavLink, Row } from 'reactstrap';

import { FileDetails } from './FileDetails';
import { authAxios } from '../../services/AxiosService';
import { FileContext, IFile, IFileMeta, IReminder } from './FileContext';
import { IIndividual } from '../Individuals/IndividualContext';
import { IndividualDetails } from '../Individuals/IndividualDetails';
import { FinancialEligibility } from './FinancialEligibility';
import { FileActivityLog } from './FileActivityLog';
import { PrintApplication } from './PrintApplication';
import { INDIVIDUAL_STATE_LOADED, IndividualsContext } from '../../contexts/IndividualsContext';
import { DutyCounselAppearancesPage } from './DutyCounselAppearancesPage';
import { FileProgress } from './FileProgress';
import { MyUserContext } from '../../contexts/MyUserContext';
import { FileNotes } from './FileNotes';
import { FileNotesDetails } from './FileNotesDetails';
import { PrintFileDetails } from './PrintFileDetails';
import { PrintIntakeLabel } from './PrintIntakeLabel';
import { FileReminders } from './FileReminders';
import { useWindowDimensions } from '../../hooks/WindowHooks';
import { FileReminderDetails } from './FileReminderDetails';
import { Documents } from '../Documents/Documents';
import { FileDocumentDetails } from '../Documents/FileDocumentDetails';
import { DocumentAccessLog } from '../Documents/DocumentAccessLog';
import { getUserId } from '../../services/AuthenticationService';

export const File = () => {
  const fileContext = useContext(FileContext);
  const myUserContext = useContext(MyUserContext);
  const params = useParams<{ fileId: string; tab?: string }>();
  const individualsContext = useContext(IndividualsContext);
  const file = fileContext.file;
  const location = useLocation<{ overrideReload?: boolean }>();
  const history = useHistory();
  const overrideReload = location?.state?.overrideReload;
  const { xs, sm } = useWindowDimensions();
  const verticalMenu = xs || sm;

  useEffect(() => {
    if (!overrideReload) {
      authAxios.get(`/api/files/admin/${params.fileId}/support-workers`).then((response) => {
        if (response.data && response.data?.status !== 'KO') {
          fileContext.setSupportWorkers(response.data);
        }
      });

      authAxios.get('/api/files/admin/' + params.fileId).then((response) => {
        if (response.data) {
          const file: IFile = response.data;
          fileContext.setFileAndAttributes(file);
          fileContext.setHoursRecords(file.hoursRecorded.sort((a, b) => a.date - b.date));
        } else {
          history.push('/files/');
        }
      });
    } else {
      console.log('SKIPPING RELOAD');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.fileId]);

  useEffect(() => {
    if (!overrideReload) {
      authAxios.get('/api/files/admin/' + params.fileId + '/meta').then((response) => {
        const file: IFileMeta = response.data;
        fileContext.setFileMeta(file);
      });
    } else {
      console.log('SKIPPING RELOAD');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.fileId]);

  useEffect(() => {
    if (!myUserContext.isSupportWorker) {
      console.log('Mounting the file table');
      const interval = setInterval(() => getReminders(), 1000 * 60 * 5);
      return () => clearInterval(interval);
    }
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!overrideReload) {
      getReminders();
    } else {
      console.log('SKIPPING RELOAD');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.fileId]);

  const getReminders = () => {
    authAxios.get('/api/files/admin/' + params.fileId + '/active-onscreen-reminders').then((response) => {
      const reminders: IReminder[] = response.data.reminders;
      fileContext.setActiveOnscreenReminders(reminders);
    });
  };

  useEffect(() => {
    if (!overrideReload) {
      authAxios.get('/api/files/admin/' + params.fileId + '/duty-counsel-appearances').then((response) => {
        if (response.data) {
          fileContext.setDutyCounselAppearances(response.data);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.fileId]);

  useEffect(() => {
    history.replace(history.location.pathname, { overrideReload: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const client = individualsContext.state.individuals.find((i: IIndividual) => i.individualId === file.clientId);

  if (file.clientId && !client && individualsContext.state.type === INDIVIDUAL_STATE_LOADED) {
    individualsContext.dispatch({ type: 'INDIVIDUAL_ACTION_DO_RELOADED' });
  }

  const tab: string = params.tab || 'details';

  const NavItemLink = (to: H.LocationDescriptor, active: boolean, name: any, count?: number) => {
    return (
      <NavItem>
        <NavLink tag={Link} to={to} active={active}>
          {name}
          {!!count && count > 0 && (
            <Badge color={'success'} className={'ml-2'}>
              {count}
            </Badge>
          )}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <>
      {!!file && !!client ? (
        <article className='page'>
          <header className={tab === 'print-application' ? 'page-header d-print-none' : 'page-header'}>
            <Container fluid={true}>
              <Row className={'align-items-center'}>
                <Col>
                  <div className='d-flex flex-column py-3'>
                    <Button
                      color={'link'}
                      className={'order-2 p-0'}
                      style={{ alignSelf: 'flex-start' }}
                      onClick={() =>
                        myUserContext.isSuperAdmin || myUserContext.isManagement
                          ? history.push('/individuals/' + client?.individualId)
                          : history.push('/files/' + file.fileId + '/client/' + client?.individualId)
                      }
                    >
                      <h1 className='page-header__title h3' style={{ color: 'black', textAlign: 'left' }}>
                        {client?.lastName.toUpperCase() + ', ' + client?.firstName}
                      </h1>
                    </Button>
                    <h2 className='h5 order-1 text-primary m-0'>
                      <Button tag={Link} color={'link'} className={'p-0 font-weight-bold'} to={'/files/' + file.fileId}>
                        {file?.fileNumber}
                        {file?.fileSeqNumber > 1 && `-${file?.fileSeqNumber}`}
                      </Button>
                    </h2>
                  </div>
                </Col>
                <Col md={7}>{params.fileId && file?.stageId && <FileProgress />}</Col>
              </Row>
              <div className={verticalMenu ? 'd-block d-md-none pb-4' : 'd-none d-md-block'}>
                <Nav tabs={!verticalMenu} pills={verticalMenu} vertical={verticalMenu}>
                  {NavItemLink('/files/' + params.fileId + '/details', tab === 'details', 'Details')}
                  {file?.clientId &&
                    NavItemLink(
                      '/files/' + params.fileId + '/client/' + file?.clientId,
                      tab === 'client',
                      'Client Details'
                    )}
                  {((myUserContext.isSupportWorker && fileContext.supportWorkers.includes(getUserId())) ||
                    !myUserContext.isSupportWorker) &&
                    NavItemLink('/files/' + params.fileId + '/reminders', tab === 'reminders', 'Reminders')}
                  {(myUserContext.isIntakeOffice || myUserContext.isSuperAdmin || myUserContext.isManagement) &&
                    NavItemLink(
                      '/files/' + params.fileId + '/financial-eligibility',
                      tab === 'financial-eligibility',
                      'Financial Eligibility'
                    )}
                  {!myUserContext.isManagement &&
                    ((myUserContext.isSupportWorker && fileContext.supportWorkers.includes(getUserId())) ||
                      !myUserContext.isSupportWorker) &&
                    NavItemLink(
                      '/files/' + params.fileId + '/documents',
                      tab === 'documents' || tab === 'client-documents',
                      'Documents'
                    )}
                  {!myUserContext.isSupportWorker &&
                    NavItemLink(
                      '/files/' + params.fileId + '/notes',
                      tab === 'notes',
                      'Notes',
                      fileContext.fileMeta.noteCount
                    )}
                  {(myUserContext.isIntakeOffice || myUserContext.isSuperAdmin || myUserContext.isManagement) &&
                    NavItemLink(
                      '/files/' + params.fileId + '/duty-counsel-appearances',
                      tab === 'duty-counsel-appearances',
                      'Duty Counsel Appearances'
                    )}
                  {(myUserContext.isIntakeOffice || myUserContext.isSuperAdmin || myUserContext.isManagement) &&
                    NavItemLink(
                      '/files/' + params.fileId + '/print-application',
                      tab === 'print-application',
                      'Print Application'
                    )}
                  {(myUserContext.isIntakeOffice || myUserContext.isSuperAdmin || myUserContext.isManagement) &&
                    NavItemLink('/files/' + params.fileId + '/print-label', tab === 'print-label', 'Print Label')}
                  {myUserContext.isLegalAssistant &&
                    NavItemLink(
                      '/files/' + params.fileId + '/print-file-details',
                      tab === 'print-file-details',
                      'Print File Details'
                    )}
                  {(myUserContext.isIntakeOffice || myUserContext.isSuperAdmin || myUserContext.isManagement) &&
                    NavItemLink('/files/' + params.fileId + '/activity-log', tab === 'activity-log', 'Activity Log')}
                  {(myUserContext.isLegalAssistant || myUserContext.isLawyer) &&
                    NavItemLink(
                      '/files/' + params.fileId + '/document-access-log',
                      tab === 'document-access-log',
                      'Document Access Log'
                    )}
                </Nav>
              </div>
            </Container>
          </header>

          <Route exact={true} path='/files/:fileId/(details)?' component={FileDetails} />
          <Route exact={true} path='/files/:fileId/client/:individualId'>
            <IndividualDetails readOnly={myUserContext.isManagement} allowCreateFile={false} />
          </Route>
          <Route exact={true} path='/files/:fileId/reminders' component={FileReminders} />
          <Route exact={true} path='/files/:fileId/reminders/:reminderId' component={FileReminderDetails} />
          <Route exact={true} path='/files/:fileId/financial-eligibility' component={FinancialEligibility} />
          <Route exact={true} path='/files/:fileId/duty-counsel-appearances' component={DutyCounselAppearancesPage} />
          <Route exact={true} path='/files/:fileId/print-application' component={PrintApplication} />
          <Route exact={true} path='/files/:fileId/print-label'>
            <PrintIntakeLabel />
          </Route>
          <Route exact={true} path='/files/:fileId/print-file-details' component={PrintFileDetails} />
          <Route exact={true} path='/files/:fileId/activity-log' component={FileActivityLog} />
          <Route exact={true} path='/files/:fileId/document-access-log' component={DocumentAccessLog} />
          <Switch>
            <Route exact={true} path='/files/:fileId/documents'>
              <Documents newDocumentPath={`/api/documents/add-file-document/${file.fileId}`} />
            </Route>
            <Route exact={true} path='/files/:fileId/documents/:documentId'>
              <FileDocumentDetails />
            </Route>
          </Switch>
          <Switch>
            <Route exact={true} path='/files/:fileId/notes'>
              <FileNotes entityType={'files'} />
            </Route>
            <Route exact={true} path='/files/:fileId/notes/add'>
              <FileNotesDetails
                createCompleteAction={() =>
                  fileContext.setFileMeta((m: IFileMeta) => ({
                    ...m,
                    noteCount: m?.noteCount ? m.noteCount + 1 : 1,
                  }))
                }
                deleteCompleteAction={() =>
                  fileContext.setFileMeta((m: IFileMeta) => ({ ...m, noteCount: m.noteCount - 1 }))
                }
                entityType={'files'}
              />
            </Route>
            <Route exact={true} path='/files/:fileId/notes/:noteId'>
              <FileNotesDetails
                createCompleteAction={() =>
                  fileContext.setFileMeta((m: IFileMeta) => ({
                    ...m,
                    noteCount: m?.noteCount ? m.noteCount + 1 : 1,
                  }))
                }
                deleteCompleteAction={() =>
                  fileContext.setFileMeta((m: IFileMeta) => ({ ...m, noteCount: m.noteCount - 1 }))
                }
                entityType={'files'}
              />
            </Route>
          </Switch>
        </article>
      ) : (
        <div>Loading</div>
      )}
    </>
  );
};
