import axios, { AxiosInstance, AxiosResponse } from 'axios';
import * as AuthenticationService from './AuthenticationService';
import LoadingService from '../components/Loading/LoadingService';

const addErrorHandling = (ax: AxiosInstance) => {
  ax.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: any) => {
      if (error.response && (error.response.status === 502 || error.response.status === 503)) {
        return Promise.reject(error);
      }

      if (error.response && error.response.data && error.response.data.userMessage) {
        console.log('Error includes UserMessage: ', error.response.data.userMessage);
      }

      console.log('Issue with request', error);
      return Promise.reject(error);
    }
  );
};

const addAuth = (ax: AxiosInstance) => {
  ax.interceptors.request.use((config) => {
    // Do something before request is sent
    const jwtToken = AuthenticationService.getJwtFromStorage();
    if (config.headers) {
      config.headers.Authorization = 'Bearer ' + jwtToken;
    }
    return config;
  });

  ax.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: any) => {
      // If we get an auth error, just refresh the page to kick them out
      if (error.response.status === 401 || error.response.status === 403) {
        AuthenticationService.removeJwtFromStorage();
        setTimeout(() => window.location.reload());
      }
      return Promise.reject(error);
    }
  );
};

const addLoading = (ax: AxiosInstance) => {
  ax.interceptors.request.use((config) => {
    if (!(config as any).disableLoading) {
      (config as any).loadingKey = LoadingService.add();
    }
    return config;
  });

  ax.interceptors.response.use(
    (response: AxiosResponse) => {
      if ((response.config as any).loadingKey) {
        LoadingService.remove((response.config as any).loadingKey);
      }
      return response;
    },
    (error: any) => {
      if (error.response && error.response.config) {
        if (!error.response.config.disableLoading) {
          if (error.response.config.loadingKey) {
            LoadingService.remove(error.response.config.loadingKey);
          } else {
            console.log('Failed to unregister loading modal from error:', error);
            LoadingService.removeAll();
          }
        }
      }
      return Promise.reject(error);
    }
  );
};

export const authAxios = axios.create();
export const anonAxios = axios.create();
export const defaultAxios = axios.create();
export const defaultAnonAxios = axios.create();

[authAxios, anonAxios, defaultAxios].forEach(addErrorHandling);
[authAxios, anonAxios].forEach(addLoading);
[authAxios, defaultAxios].forEach(addAuth);
