import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import { formatDate, formatDateForServer } from '../../formatters/DateTimeFormatter';
import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { IHoursRecord } from '../Files/FileContext';
import { authAxios } from '../../services/AxiosService';
import { notifyError, notifySuccess } from '../../services/ToastService';
import { SUPPORT_REQUEST, TIME_TRACKING_ACTIVITY_INTERVENTION } from '../../contexts/DomainContext';
import DatePicker from 'react-datepicker';
import { FaTimes } from 'react-icons/fa';
import { MyUserContext } from '../../contexts/MyUserContext';
import { SupportWorkerRequestContext } from 'src/components/SupportRequests/SupportWorkerRequestContext';
import { IIndividual } from 'src/components/Individuals/IndividualContext';
import { IndividualsContext } from 'src/contexts/IndividualsContext';
import * as AuthenticationService from 'src/services/AuthenticationService';

export const InterventionRecordHours = () => {
  const params = useParams<{ supportWorkerRequestId: string; interventionId: string }>();
  const myUserContext = useContext(MyUserContext);
  const { supportWorkerRequest } = useContext(SupportWorkerRequestContext);
  const individualsContext = useContext(IndividualsContext);
  const individuals = individualsContext.state.individuals;
  const [hoursRecords, setHoursRecords] = useState<IHoursRecord[]>([]);
  const [hours, setHours] = useState<number>();
  const [date, setDate] = useState<Date | undefined>();
  const [tryAddHours, setTryAddHours] = useState(false);
  const authUserId = AuthenticationService.getUserId();

  const client = individuals.find((i: IIndividual) => i.individualId === supportWorkerRequest.clientId);

  useEffect(() => {
    if (params.interventionId) {
      authAxios
        .get(
          '/api/support-worker-requests/admin/' +
            params.supportWorkerRequestId +
            '/interventions/' +
            params.interventionId +
            '/time-tracking'
        )
        .then((response) => {
          setHoursRecords(response.data.hoursRecords);
        });
    }
  }, [params.supportWorkerRequestId, params.interventionId]);

  const totalHours: number = useMemo(() => {
    let total = 0;
    hoursRecords.forEach((l) => {
      total = total + l.hours;
    });
    return total;
  }, [hoursRecords]);

  const recordHours = () => {
    if (hours && date) {
      authAxios
        .post('/api/support-worker-requests/admin/record-intervention-hours', {
          supportWorkerRequestId: params.supportWorkerRequestId,
          interventionId: params.interventionId,
          userId: authUserId,
          hours: hours,
          date: formatDateForServer(date),
          timeTrackingActivityId: TIME_TRACKING_ACTIVITY_INTERVENTION,
          details: client?.lastName.toUpperCase() + ', ' + client?.firstName,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            notifySuccess('Hours updated.');
            setHoursRecords((s) => [
              ...s,
              {
                hoursRecordId: response.data.hoursRecordId,
                userId: authUserId,
                timeTrackingActivityId: TIME_TRACKING_ACTIVITY_INTERVENTION,
                hours: hours,
                date: date?.getTime(),
                timestamp: new Date().getTime(),
              } as IHoursRecord,
            ]);
            setDate(undefined);
            setTryAddHours(false);
            setHours(undefined);
          } else {
            notifyError(response.data.errorMessage);
          }
        });
    } else {
      setTryAddHours(true);
    }
  };

  const deleteHoursRecord = (hoursRecordId: string) => {
    authAxios
      .post('/api/support-worker-requests/admin/delete-intervention-hours', {
        supportWorkerRequestId: params.supportWorkerRequestId,
        interventionId: params.interventionId,
        hoursRecordId,
      })
      .then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('Hours deleted.');
          setHoursRecords((s) => s.filter((e) => e.hoursRecordId !== hoursRecordId));
        } else {
          notifyError(response.data.errorMessage);
        }
      });
  };

  return (
    <Card className={'bg-primary-light'} style={{ width: '100%' }}>
      <CardHeader>Time Tracking</CardHeader>
      {myUserContext.isSupportWorker && supportWorkerRequest.status === SUPPORT_REQUEST.ACCEPTED && (
        <CardBody>
          <Row>
            <Col sm={5}>
              <FormGroup>
                <Label>Date</Label>
                <DatePicker
                  selected={date}
                  onChange={(date) => {
                    if (date) {
                      setDate(date);
                    }
                  }}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dateFormat='MMM dd, yyyy'
                  dropdownMode='select'
                  className='date-picker rounded mb-0'
                  placeholderText={'Select...'}
                />
                {tryAddHours && date === undefined && <FormText color={'danger'}>This field is mandatory</FormText>}
              </FormGroup>
            </Col>
            <Col sm={4}>
              <FormGroup>
                <Label>Hours</Label>
                <Input
                  type='number'
                  name='userHours'
                  id='exampleNumber'
                  value={hours ? hours : ''}
                  onChange={(e: any) => {
                    const val = e.target.value;
                    setHours(val ? Number(parseFloat(val).toFixed(2)) : 0);
                  }}
                />
                {tryAddHours && !hours && <FormText color={'danger'}>This field is mandatory</FormText>}
              </FormGroup>
            </Col>
            <Col sm={3}>
              <FormGroup>
                <Button style={{ marginTop: '27px' }} color={'primary'} onClick={() => recordHours()}>
                  Add Hours
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      )}
      {hoursRecords.length > 0 && (
        <ListGroup flush={true} style={{ borderTop: '1px solid lightgrey' }}>
          <ListGroupItem className={'grey-background'}>
            <Row>
              <Col xs={8}>
                <Label style={{ fontSize: '1rem' }}>Date</Label>
              </Col>
              <Col xs={1} className={'d-flex justify-content-end'}>
                <Label style={{ fontSize: '1rem' }}>Hours</Label>
              </Col>
              {!myUserContext.isSupportWorker && <Col xs={3}> </Col>}
            </Row>
          </ListGroupItem>
          {hoursRecords.map((entry) => {
            return (
              <ListGroupItem key={entry.hoursRecordId}>
                <Row className={'align-items-center'}>
                  <Col xs={8}>{formatDate(new Date(entry.date))}</Col>
                  <Col xs={1} className={'d-flex justify-content-end'}>
                    {entry.hours}
                  </Col>
                  <Col xs={2}> </Col>
                  {myUserContext.isSupportWorker && supportWorkerRequest.status === SUPPORT_REQUEST.ACCEPTED && (
                    <Col xs={1} className={'pl-2'}>
                      <Button
                        color={'link'}
                        className={'p-0 text-danger'}
                        onClick={() => {
                          deleteHoursRecord(entry.hoursRecordId);
                        }}
                      >
                        <FaTimes />
                      </Button>
                    </Col>
                  )}
                </Row>
              </ListGroupItem>
            );
          })}
          <ListGroupItem className={'grey-background'}>
            <Row>
              <Col xs={8}>
                <Label style={{ fontSize: '1rem' }}>TOTAL</Label>
              </Col>

              <Col xs={1} className={'d-flex justify-content-end'}>
                <Label style={{ fontSize: '1rem' }}>{totalHours}</Label>
              </Col>
              <Col xs={2}> </Col>
              <Col xs={1}> </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      )}
    </Card>
  );
};
