import * as React from 'react';
import { FormEvent, useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { authAxios } from '../../services/AxiosService';
import { notifyError, notifySuccess } from '../../services/ToastService';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import { IOffence } from './OffenceTable';
import { formatDateTime } from '../../formatters/DateTimeFormatter';
import {
  APPLICATION_TYPE_ID_ADULT_CRIMINAL,
  APPLICATION_TYPE_ID_CIVIL_FAMILY,
  APPLICATION_TYPE_ID_TERRITORIAL_STATUTES,
  APPLICATION_TYPE_ID_YOUTH_CRIMINAL,
  DomainContext,
  IApplicationType,
  IOffenceCategoryType,
} from '../../contexts/DomainContext';
import Select from 'react-select';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { OffencesContext } from './OffencesContext';

interface IProps {
  createCompleteAction?: (offence: IOffence) => void;
  applicationTypeId?: string;
  cancel?: () => void;
}

export const OffenceDetails: React.FC<IProps> = (props) => {
  const domainContext = useContext(DomainContext);
  const applicationTypes = domainContext.applicationTypes;
  const offencesContext = useContext(OffencesContext);
  const allOffences = offencesContext.offences;
  const [categoryTypes, setCategoryTypes] = useState(
    offencesContext.categoryTypes.filter((ct) =>
      props.applicationTypeId ? ct.applicationTypes.includes(props.applicationTypeId) : true
    )
  );
  const [trySubmit, setTrySubmit] = useState(false);
  const [number, setNumber] = useState('');
  const [dateCreated, setDateCreated] = useState('');
  const [description, setDescription] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const [applicationTypeId, setApplicationTypeId] = useState(props.applicationTypeId ? props.applicationTypeId : '');
  const [group, setGroup] = useState<number | undefined>(undefined);
  const [active, setActive] = useState(true);
  const [categoryTypeId, setCategoryTypeId] = useState<string | undefined>(undefined);
  const [savedActiveSate, setSavedActiveState] = useState(true);
  const history = useHistory();
  const params = useParams<{ offenceId: string }>();
  const [leavePageConfirmOpen, setLeavePageConfirmOpen] = useState(false);
  const [chargeAlreadyExistsOpen, setChargeAlreadyExistsOpen] = useState(false);

  const groups = [{ val: 1 }, { val: 2 }, { val: 3 }, { val: 4 }, { val: 5 }];
  const hasGroup = [APPLICATION_TYPE_ID_ADULT_CRIMINAL, APPLICATION_TYPE_ID_YOUTH_CRIMINAL];
  const hasCategories = [
    APPLICATION_TYPE_ID_ADULT_CRIMINAL,
    APPLICATION_TYPE_ID_YOUTH_CRIMINAL,
    APPLICATION_TYPE_ID_CIVIL_FAMILY,
    APPLICATION_TYPE_ID_TERRITORIAL_STATUTES,
  ];

  useEffect(() => {
    if (params.offenceId) {
      authAxios.get('/api/offences/admin/' + params.offenceId).then((response) => {
        const offence: IOffence = response.data;
        setNumber(offence.number);
        setDateCreated(offence.dateCreated ? formatDateTime(new Date(offence.dateCreated)).replace('.', '') : '');
        setDescription(offence.description ? offence.description : '');
        setApplicationTypeId(offence.applicationTypeId ? offence.applicationTypeId : '');
        setGroup(offence.group);
        setActive(offence.active);
        setSavedActiveState(offence.active);
        setCategoryTypeId(offence.categoryTypeId);
        setCategoryTypes(
          offencesContext.categoryTypes.filter((ct) =>
            offence.applicationTypeId ? ct.applicationTypes.includes(offence.applicationTypeId) : true
          )
        );
      });
    }
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.offenceId]);

  useEffect(() => {
    setCategoryTypes(
      offencesContext.categoryTypes.filter((ct) =>
        applicationTypeId ? ct.applicationTypes.includes(applicationTypeId) : true
      )
    );
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationTypeId]);

  const formFullyFilled = () => {
    return number !== '' && applicationTypeId !== '';
  };

  const createNewOffence = () => {
    authAxios
      .post('/api/offences/admin/create', { number, description, applicationTypeId, group, active, categoryTypeId })
      .then((response) => {
        if (response.data.status === 'OK') {
          const offenceId = response.data.offenceId;
          notifySuccess('Offence created.');
          setIsDirty(false);
          offencesContext.setOffences((o: IOffence[]) =>
            o.concat([
              {
                offenceId: offenceId,
                number: number,
                fileCount: 0,
                description: description,
                applicationTypeId: applicationTypeId,
                group: group,
                categoryTypeId: categoryTypeId,
                dateCreated: new Date().getTime(),
                active: active,
              } as IOffence,
            ])
          );
          if (props.createCompleteAction) {
            props.createCompleteAction({
              offenceId,
              number,
              description,
              applicationTypeId,
              group,
              active,
              categoryTypeId,
            } as IOffence);
          } else {
            history.push('/offences/' + offenceId);
          }
        } else {
          notifyError(response.data.errorMessage);
        }
      });
  };

  const saveDetails = () => {
    if (!formFullyFilled()) {
      setTrySubmit(true);
    } else {
      if (params.offenceId) {
        authAxios
          .post('/api/offences/admin/update', {
            offenceId: params.offenceId,
            number,
            description,
            applicationTypeId,
            group,
            active,
            categoryTypeId,
          })
          .then((response) => {
            if (response.data.status === 'OK') {
              notifySuccess('Offence details updated.');
              setIsDirty(false);
              setSavedActiveState(active);
              offencesContext.setOffences((offences: IOffence[]) => {
                return offences.map((o) => {
                  if (o.offenceId === params.offenceId) {
                    return {
                      ...o,
                      number: number,
                      description: description,
                      applicationTypeId: applicationTypeId,
                      group: group,
                      active: active,
                      categoryTypeId: categoryTypeId,
                    };
                  } else {
                    return o;
                  }
                });
              });
              history.push('/offences');
            } else {
              notifyError(response.data.errorMessage);
            }
          });
      } else {
        if (allOffences.find((o) => o.number.toLowerCase() === number.toLowerCase())) {
          setChargeAlreadyExistsOpen(true);
        } else {
          createNewOffence();
        }
      }
    }
  };

  const setConfirmModalOpen = () => {
    setLeavePageConfirmOpen(true);
  };

  const dropdownSelectStyles = {
    menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
  };

  return (
    <div>
      <LeavePageConfirm isDirty={isDirty} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom'}>
          <Row className={'flex-fill'}>
            <Col md={3} xl={2}>
              <NavbarBrand>{params.offenceId ? 'Offence Details' : 'Add New Offence'}</NavbarBrand>
            </Col>
            {params.offenceId || props.createCompleteAction ? (
              <Col className={'d-flex'}>
                {props.cancel !== undefined && (
                  <Button className={'ml-auto grey-button'} onClick={isDirty ? setConfirmModalOpen : props.cancel}>
                    Cancel
                  </Button>
                )}
                <Button color='success' className={props.cancel ? 'ml-2' : 'ml-auto'} onClick={() => saveDetails()}>
                  Save Details
                </Button>
              </Col>
            ) : (
              <Col className={'d-flex'}>
                <Button className={'ml-auto grey-button'} onClick={() => history.push('/offences')}>
                  Cancel
                </Button>
                <Button color='success' className={'ml-2'} onClick={() => saveDetails()}>
                  Save Details
                </Button>
              </Col>
            )}
          </Row>
        </Navbar>
        <Container fluid={true} className={'my-3'}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for={'applicationTypeId'}>
                  Application Type <span className='text-required'> (required)</span>
                </Label>
                <Select
                  name={'applicationTypeId'}
                  isDisabled={
                    !savedActiveSate || (props.applicationTypeId !== '' && props.applicationTypeId !== undefined)
                  }
                  options={applicationTypes}
                  value={applicationTypes.find((ft) => ft.applicationTypeId === applicationTypeId)}
                  type={'text'}
                  onChange={(value: any) => {
                    setApplicationTypeId(value?.applicationTypeId || '');
                    setIsDirty(true);
                    if (!hasGroup.includes(value?.applicationTypeId)) {
                      setGroup(undefined);
                    }
                    if (!hasCategories.includes(value?.applicationTypeId)) {
                      setCategoryTypeId(undefined);
                    }
                  }}
                  getOptionValue={(option: IApplicationType) => option.applicationTypeId}
                  getOptionLabel={(option: IApplicationType) => option.name}
                  isClearable={true}
                  styles={dropdownSelectStyles}
                />
                {trySubmit && applicationTypeId === '' && (
                  <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                )}
              </FormGroup>
              <FormGroup>
                <Label for={'number'}>
                  Charge / Matter {!params.offenceId && <span className='text-required'> (required)</span>}
                </Label>
                <Input
                  value={number}
                  type={'text'}
                  name={'Number'}
                  onChange={(e: any) => {
                    setNumber(e.target.value);
                    setIsDirty(true);
                  }}
                  readOnly={!!params.offenceId}
                  invalid={trySubmit && number === ''}
                />
                <FormFeedback>This field is mandatory</FormFeedback>
              </FormGroup>
              {hasGroup.includes(applicationTypeId) && (
                <FormGroup>
                  <Label for={'group'}>Group</Label>
                  <Select
                    name={'group'}
                    options={groups.filter((g) => g.val !== 5)}
                    value={groups.find((g) => g.val === group)}
                    type={'text'}
                    onChange={(value: any) => {
                      setGroup(value.val);
                      setIsDirty(true);
                    }}
                    getOptionValue={(option: any) => option.val}
                    getOptionLabel={(option: any) => option.val}
                    isClearable={true}
                    styles={dropdownSelectStyles}
                  />
                  {trySubmit && groups === undefined && (
                    <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                  )}
                </FormGroup>
              )}
              {hasCategories.includes(applicationTypeId) && (
                <FormGroup>
                  <Label for={'group'}>Category</Label>
                  <Select
                    name={'category'}
                    options={categoryTypes}
                    value={categoryTypes.find((g) => g.categoryTypeId === categoryTypeId)}
                    type={'text'}
                    onChange={(value: any) => {
                      setCategoryTypeId(value ? value.categoryTypeId : '');
                      setIsDirty(true);
                    }}
                    getOptionValue={(option: IOffenceCategoryType) => option.categoryTypeId}
                    getOptionLabel={(option: IOffenceCategoryType) => option.name}
                    isClearable={true}
                    styles={dropdownSelectStyles}
                  />
                </FormGroup>
              )}
              {params.offenceId && (
                <FormGroup>
                  <Label for={'dateCreated'}>Date Created</Label>
                  <Input value={dateCreated} type={'text'} name={'Date Created'} readOnly={true} />
                </FormGroup>
              )}
              <FormGroup>
                <CustomInput
                  type='checkbox'
                  id={'active'}
                  name={'active'}
                  label={'Active'}
                  checked={active}
                  onChange={() => {
                    setActive((s) => !s);
                    setIsDirty(true);
                  }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for={'description'}>Description</Label>
                <Input
                  value={description}
                  type={'textarea'}
                  readOnly={!savedActiveSate}
                  name={'Description'}
                  placeholder={'Enter description here...'}
                  style={{ minHeight: '210px' }}
                  onChange={(e: any) => {
                    setDescription(e.target.value);
                    setIsDirty(true);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </Container>
      </form>
      <Modal isOpen={leavePageConfirmOpen}>
        <ModalHeader>You have unsaved changes, are you sure you want to leave?</ModalHeader>
        <ModalBody>Your current changes will be discarded. You won&apos;t be able to undo this action.</ModalBody>
        <ModalFooter>
          <Button color='danger' onClick={props.cancel}>
            Yes, Leave page
          </Button>
          <Button color='secondary' onClick={() => setLeavePageConfirmOpen((s: boolean) => !s)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={chargeAlreadyExistsOpen}>
        <ModalHeader>This charge already exists</ModalHeader>
        <ModalBody>This Charge / Matter already exists, are you sure you want to create a duplicate?</ModalBody>
        <ModalFooter>
          <Button color='danger' onClick={createNewOffence}>
            Yes, Create Duplicate
          </Button>
          <Button color='secondary' onClick={() => setChargeAlreadyExistsOpen((s: boolean) => !s)}>
            No, Don&apos;t Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
