import * as React from 'react';
import { FormEvent, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, CustomInput, Navbar, NavbarBrand, Row } from 'reactstrap';
import { FaPlus } from 'react-icons/all';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { TablePagination } from '../TablePagination/TablePagination';
import { Column, TableInstance, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { PeopleContext } from '../../contexts/PeopleContext';
import { formatDateTime } from '../../formatters/DateTimeFormatter';
import { FileReminderDetails } from './FileReminderDetails';
import { useLocation, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { DomainContext } from '../../contexts/DomainContext';
import { authAxios } from '../../services/AxiosService';
import { FileContext, IReminder } from './FileContext';
import { MyUserContext } from '../../contexts/MyUserContext';

export const FileReminders = () => {
  const params = useParams<{ fileId: string }>();
  const usersContext = useContext(PeopleContext);
  const domainContext = useContext(DomainContext);
  const fileContext = useContext(FileContext);
  const myUserContext = useContext(MyUserContext);
  const file = fileContext.file;
  const users = usersContext.staff;
  const [showNewReminder, setShowNewReminder] = useState(false);
  const history = useHistory();
  const [showResolved, setShowResolved] = useState(false);
  const [reminders, setReminders] = useState<IReminder[]>([]);
  const location = useLocation<{ deletedReminderId?: string }>();

  const deletedReminderId = location?.state?.deletedReminderId;

  useEffect(() => {
    authAxios.get('/api/files/admin/' + params.fileId + '/reminders').then((response) => {
      if (response.data.status === 'OK') {
        setReminders(response.data.reminders);
      }
    });
  }, [params.fileId]);

  const columns: Column<IReminder>[] = useMemo(
    () => [
      {
        Header: 'Reminder Date',
        id: 'date',
        accessor: 'date',
        width: 200,
        Cell: (cell: any) => {
          if (cell && cell.row.original && cell.row.original.date) {
            return <span>{formatDateTime(new Date(cell.row.original.date))}</span>;
          } else {
            return <span />;
          }
        },
      },
      {
        Header: 'Reminder',
        id: 'text',
        accessor: 'text',
        Cell: (cell: any) => {
          if (cell && cell.row.original && cell.row.original.text) {
            return <main dangerouslySetInnerHTML={{ __html: cell.row.original.text }} />;
          } else {
            return <span />;
          }
        },
      },
      {
        Header: 'Users to Notify',
        id: 'users',
        accessor: (r: IReminder) => {
          const userList = users.filter((u) => r.staffToNotify.includes(u.userId));
          return userList.map((u) => u.lastName.toUpperCase() + ' ' + u.firstName).join(', ');
        },
      },
      {
        Header: 'Notification Type',
        id: 'notificationTypeIds',
        accessor: (r: IReminder) => {
          return domainContext.notificationTypes
            .filter((n) => r.notificationTypeIds.includes(n.notificationTypeId))
            .map((nt) => nt.name)
            .join(', ');
        },
      },
    ],
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const data: IReminder[] = useMemo(
    () =>
      reminders
        .filter((e) => showResolved || !e.resolved)
        .filter((e) => e?.reminderId && e.reminderId !== deletedReminderId),
    [reminders, showResolved, deletedReminderId]
  );

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    // visibleColumns,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // preGlobalFilteredRows,
    // setGlobalFilter,
    state: { pageIndex, pageSize },
  }: TableInstance<IReminder> = useTable(
    {
      columns: columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        sortBy: [
          {
            id: 'date',
            desc: false,
          },
        ],
        // hiddenColumns: ['userCreated', 'text'],
      },
    },
    // useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div>
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        {!showNewReminder && (
          <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
            <Row className={'flex-fill'}>
              <Col md={2} xl={2}>
                <NavbarBrand>Reminders</NavbarBrand>
              </Col>
              <CustomInput
                className={'mt-2'}
                type='checkbox'
                id={'showResolved'}
                name={'showResolved'}
                label={'Show Resolved Reminders'}
                checked={showResolved}
                onChange={() => {
                  setShowResolved(!showResolved);
                }}
              />
              <Col className={'d-flex'}>
                {(!file.isLockedDown || myUserContext.isSupportWorker) && (
                  <Button className={'ml-auto'} color={'primary'} onClick={() => setShowNewReminder(true)}>
                    <FaPlus /> New Reminder
                  </Button>
                )}
              </Col>
            </Row>
          </Navbar>
        )}
        {showNewReminder && (
          <FileReminderDetails
            onCreate={(e) => setReminders((r) => [e, ...r])}
            onSave={() => setShowNewReminder(false)}
            onCancel={() => setShowNewReminder(false)}
          />
        )}
        <table className={'table table-bordered'} {...getTableProps()}>
          <thead style={{ overflow: 'visible' }}>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`files-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={`files-table-thead-tr-${index}-${column.id}`}
                    width={column.width}
                    style={{ maxWidth: column.width }}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                    <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <tr
                  key={`files-table-tr-${row.id}`}
                  {...row.getRowProps()}
                  className={row.original.date <= new Date().getTime() && !row.original.resolved ? 'alert-row' : ''}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        key={`files-table-td-${cell.row.id}-${cell.column.id}`}
                        {...cell.getCellProps()}
                        onClick={() =>
                          history.push('/files/' + params.fileId + '/reminders/' + cell.row.original.reminderId)
                        }
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <TablePagination
          pageCount={pageCount}
          pageOptions={pageOptions}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageSizes={[20, 50, 100, 500]}
        />
      </form>
    </div>
  );
};
