import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { authAxios } from '../../services/AxiosService';
import { useHistory, useLocation } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Column, TableInstance, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import {
  DomainContext,
  IApplicationType,
  IFileType,
  IStage,
  STAGE_CANCELLED_COUNSEL_REQUEST,
  STAGE_COUNSEL_REQUEST,
  STAGE_COUNSEL_REQUEST_DRAFT,
  STAGE_ERROR,
  STAGE_LAWYER_REQUESTED,
  STAGE_RETURN_FOR_CONFLICT,
} from '../../contexts/DomainContext';
import { IFile, MOST_SERIOUS_GROUPS } from '../Files/FileContext';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { IUser } from '../Users/UsersTable';
import { DropdownMultiSelectCheckboxes } from '../DropdownMultiSelectCheckboxes/DropdownMultiSelectCheckboxes';
import { TablePagination } from '../TablePagination/TablePagination';
import { IOffence } from '../Offences/OffenceTable';
import { MyUserContext } from '../../contexts/MyUserContext';
import { clientSelectValue } from '../../formatters/PeopleFormatter';
import { INDIVIDUAL_STATE_LOADED, IndividualsContext } from '../../contexts/IndividualsContext';
import { PeopleContext } from '../../contexts/PeopleContext';
import { FilesContext } from '../../contexts/FilesContext';
import { formatDateFromISONumber } from '../../formatters/DateTimeFormatter';
import { IIndividual } from '../Individuals/IndividualContext';
import { DateRangeDropdown } from '../DateRangeDropdown/DateRangeDropdown';
import { useMostSerious } from '../../hooks/useMostSerious';

interface IProps {
  selectRow?: (userId: string) => void;
  individualId: string;
}

export const AssociatedFilesTable: React.FC<IProps> = (props) => {
  const [associatedFiles, setAssociatedFiles] = useState<IFile[]>([]);
  const domainContext = useContext(DomainContext);
  const individualsContext = useContext(IndividualsContext);
  const myUserContext = useContext(MyUserContext);
  const peopleContext = useContext(PeopleContext);
  const lawyerList = peopleContext.lawyers;
  const individuals = individualsContext.state.individuals;
  const fileContext = useContext(FilesContext);
  const files = fileContext.state.files;

  const applicationTypes = domainContext.applicationTypes;
  const fileTypes = domainContext.fileTypes;
  const stages = domainContext.stages;
  const offences = domainContext.offences;
  const history = useHistory();
  const location = useLocation<{ deletedFileId?: string }>();
  const mostSerious = useMostSerious();

  const deletedFileId = location?.state?.deletedFileId;

  useEffect(() => {
    if (props.individualId) {
      authAxios.get('/api/files/' + props.individualId + '/client-files').then((response) => {
        setAssociatedFiles(
          response.data.map((e: any) => {
            const lawyer = lawyerList.find((l: IUser) => l.userId === e.file.lawyerId);
            const lawyerFormatted = lawyer ? `${lawyer?.lastName.toUpperCase()}, ${lawyer?.firstName}` : '';

            return {
              ...e.file,
              mostSerious: mostSerious(e.file.applicationTypeId, e.file.charges),
              lawyerFormatted,
              relationship: e.relationship,
            };
          })
        );
      });
    }
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: Column<IFile>[] = useMemo(
    () => [
      {
        Header: 'File #',
        accessor: 'fileNumber',
        width: 30,
      },
      {
        Header: 'Client',
        id: 'clientId',
        accessor: (file: IFile) => {
          const client = individuals.find((i: IIndividual) => i.individualId === file.clientId);

          if (!client && individualsContext.state.type === INDIVIDUAL_STATE_LOADED) {
            console.log('CANT FIND CLIENT RELOADING');
            individualsContext.dispatch({ type: 'INDIVIDUAL_ACTION_DO_RELOADED' });
          }
          return client
            ? client.lastName.toUpperCase() +
                ', ' +
                client.firstName +
                ' ' +
                (client.birthDate ? formatDateFromISONumber(client.birthDate) : '') +
                ' ' +
                client.aliases.join(', ')
            : '';
        },
        Cell: (cell: any) => {
          if (cell && cell.row.original && cell.row.original.clientId) {
            const client = individuals.find((c) => c.individualId === cell.row.original.clientId);
            if (client) {
              return clientSelectValue(client);
            } else {
              return <span />;
            }
          } else {
            return <span />;
          }
        },
      },
      {
        Header: 'Relationship',
        accessor: 'relationship',
        width: 30,
      },
      {
        Header: 'Court File #',
        accessor: 'courtNumber',
      },
      {
        Header: 'Stage',
        id: 'stageId',
        accessor: (file: IFile) => {
          return stages.find((s: IStage) => s.stageId === file.stageId)?.name;
        },
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          const rowAStage = stages.find((s: IStage) => s.stageId === rowA.original[id]);
          const rowBStage = stages.find((s: IStage) => s.stageId === rowB.original[id]);
          const fileNumberA = rowA.original['fileNumber'];
          const fileNumberB = rowB.original['fileNumber'];

          if (rowAStage && rowBStage) {
            if (
              (rowAStage.stageId === STAGE_COUNSEL_REQUEST || rowAStage.stageId === STAGE_RETURN_FOR_CONFLICT) &&
              rowBStage.stageId !== STAGE_COUNSEL_REQUEST &&
              rowBStage.stageId !== STAGE_RETURN_FOR_CONFLICT
            ) {
              return -1;
            } else if (
              (rowBStage.stageId === STAGE_COUNSEL_REQUEST || rowBStage.stageId === STAGE_RETURN_FOR_CONFLICT) &&
              rowAStage.stageId !== STAGE_COUNSEL_REQUEST &&
              rowAStage.stageId !== STAGE_RETURN_FOR_CONFLICT
            ) {
              return 1;
            } else if (
              (rowAStage.stageId === STAGE_COUNSEL_REQUEST || rowAStage.stageId === STAGE_RETURN_FOR_CONFLICT) &&
              (rowBStage.stageId === STAGE_COUNSEL_REQUEST || rowBStage.stageId === STAGE_RETURN_FOR_CONFLICT)
            ) {
              if (rowAStage.name > rowBStage.name) {
                return 1;
              } else if (rowAStage.name < rowBStage.name) {
                return -1;
              } else {
                return fileNumberB.localeCompare(fileNumberA);
              }
            } else if (
              rowBStage.stageId !== STAGE_COUNSEL_REQUEST &&
              rowBStage.stageId !== STAGE_RETURN_FOR_CONFLICT &&
              rowAStage.stageId !== STAGE_COUNSEL_REQUEST &&
              rowAStage.stageId !== STAGE_RETURN_FOR_CONFLICT
            ) {
              return fileNumberB.localeCompare(fileNumberA);
            }
          }
          return 0;
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='stageId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={stages.map((s: IStage) => ({
                value: s.stageId,
                display: s.name,
              }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['stageId'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
      },
      {
        Header: 'Lawyer',
        accessor: 'lawyerFormatted',
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = [], preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='lawyerId'
              style={{ minWidth: 200 }}
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={lawyerList
                .filter((e) => e.active)
                .sort((a: IUser, b: IUser) => a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase()))
                .map((s: IUser) => ({
                  value: s.userId,
                  display: s.lastName.toUpperCase() + ', ' + s.firstName,
                }))
                .concat([{ value: '', display: 'Unassigned' }])}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['lawyerId'];
              if (rowValue === undefined) {
                return filterValue.includes('');
              } else {
                return filterValue.includes(rowValue);
              }
            }
          });
        },
      },
      {
        Header: 'App Type',
        id: 'applicationTypeId',
        width: 40,
        accessor: (file: IFile) => {
          return applicationTypes.find(
            (applicationType: IApplicationType) => applicationType.applicationTypeId === file.applicationTypeId
          )?.name;
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = [], preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='officeId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={applicationTypes.map((s: IApplicationType) => ({
                value: s.applicationTypeId,
                display: s.name,
              }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['applicationTypeId'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
      },
      {
        Header: 'File Type',
        id: 'fileTypeId',
        width: 40,
        accessor: (file: IFile) => {
          return fileTypes.find((fileType: IFileType) => fileType.fileTypeId === file.fileTypeId)?.name;
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = [], preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='officeId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={fileTypes.map((s: IFileType) => ({
                value: s.fileTypeId,
                display: s.name,
              }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['fileTypeId'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
      },
      {
        Header: 'Charges',
        id: 'charges',
        accessor: (f: IFile) => {
          const chargeList = f.charges
            ? f.charges.map((charge) => offences.find((o) => o.offenceId === charge.offenceId))
            : [];
          return chargeList
            .sort((a: IOffence | undefined, b: IOffence | undefined) => {
              if (a && b && a.group && b.group) {
                if (a.group > b.group) {
                  return 1;
                } else if (b.group > a.group) {
                  return -1;
                } else {
                  return 0;
                }
              }
              return 0;
            })
            .map((offence) => (offence ? offence?.number : ''))
            .join(', ');
        },
      },
      {
        Header: 'Most Serious',
        width: 50,
        accessor: 'mostSerious',
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = [], preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='charges'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={MOST_SERIOUS_GROUPS}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = mostSerious(row.original.applicationTypeId, row.original.charges).toString();
              return filterValue.includes(rowValue);
            }
          });
        },
      },
      {
        Header: 'App Date',
        accessor: 'appDateFormatted',
        width: 75,
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          if (rowA.original['appDate'] && rowB.original['appDate']) {
            if (rowA.original['appDate'] > rowB.original['appDate']) return 1;
            if (rowB.original['appDate'] > rowA.original['appDate']) return -1;
            return 0;
          } else if (rowA.original['appDate'] && !rowB.original['appDate']) {
            return 1;
          } else if (rowB.original['appDate'] && !rowA.original['appDate']) {
            return -1;
          } else {
            return 0;
          }
        },
        filter: (rows: any, id: any, filterValue: { startDate?: Date; endDate?: Date }) => {
          return rows.filter((row: any) => {
            if (!filterValue.startDate || !filterValue.endDate) {
              return true;
            } else {
              const rowValue = row.original['appDate'];
              return rowValue > filterValue.startDate.getTime() && rowValue < filterValue.endDate.getTime();
            }
          });
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => (
          <DateRangeDropdown
            startDate={filterValue?.startDate}
            endDate={filterValue?.endDate}
            setRange={(dates: { startDate?: Date; endDate?: Date }) => {
              setFilter(dates);
            }}
            keyPrefix={'applicationDate'}
          />
        ),
      },
      {
        Header: 'Expiry Date',
        accessor: 'expiryDateFormatted',
        width: 75,
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          if (rowA.original['expiryDateFilter'] && rowB.original['expiryDateFilter']) {
            if (rowA.original['expiryDateFilter'] > rowB.original['expiryDateFilter']) return 1;
            if (rowB.original['expiryDateFilter'] > rowA.original['expiryDateFilter']) return -1;
            return 0;
          } else if (rowA.original['expiryDateFilter'] && !rowB.original['expiryDateFilter']) {
            return 1;
          } else if (rowB.original['expiryDateFilter'] && !rowA.original['expiryDateFilter']) {
            return -1;
          } else {
            return 0;
          }
        },
        filter: (rows: any, id: any, filterValue: { startDate?: Date; endDate?: Date }) => {
          return rows.filter((row: any) => {
            if (!filterValue.startDate || !filterValue.endDate) {
              return true;
            } else {
              const rowValue = row.original['expiryDateFilter'];
              return rowValue > filterValue.startDate.getTime() && rowValue < filterValue.endDate.getTime();
            }
          });
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => (
          <DateRangeDropdown
            startDate={filterValue?.startDate}
            endDate={filterValue?.endDate}
            setRange={(dates: { startDate?: Date; endDate?: Date }) => {
              setFilter(dates);
            }}
            keyPrefix={'expiryDate'}
          />
        ),
      },
    ],
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [individuals, applicationTypes, stages, offences, lawyerList]
  );

  const data: IFile[] = useMemo(() => {
    if (props.individualId) {
      return associatedFiles;
    } else {
      return files.filter((f: any) => f.fileId !== deletedFileId);
    }
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, associatedFiles]);

  const defaultFilters: any[] = useMemo(() => {
    return [
      {
        id: 'stageId',
        value: stages
          .map((s) => s.stageId)
          .filter(
            (sId) =>
              sId !== STAGE_ERROR && sId !== STAGE_CANCELLED_COUNSEL_REQUEST && sId !== STAGE_COUNSEL_REQUEST_DRAFT
          ),
      },
    ];
  }, [stages]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: TableInstance<IFile> = useTable(
    {
      columns: columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        filters: defaultFilters,
        sortBy: [
          { id: 'stageId', desc: false },
          { id: 'fileNumber', desc: true },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const selectRow = (fileId: string) => {
    if (props.selectRow) {
      props.selectRow(fileId);
    } else {
      history.push('/files/' + fileId);
    }
  };

  return (
    <div className={'files-table'}>
      <div className={'table-responsive'} style={{ minHeight: '800px' }}>
        <table className={'table table-bordered table-hover'} {...getTableProps()}>
          <thead style={{ overflow: 'visible' }}>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`files-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={`files-table-thead-tr-${index}-${column.id}`}
                    width={column.width}
                    style={{ maxWidth: column.width }}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                    <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <tr
                  key={`files-table-tr-${row.id}`}
                  {...row.getRowProps()}
                  className={
                    (myUserContext.role === 'LegalAssistant' || myUserContext.role === 'Lawyer') &&
                    row.original.stageId === STAGE_LAWYER_REQUESTED
                      ? 'bg-primary-medium'
                      : (myUserContext.role === 'Intake' || myUserContext.role === 'SuperAdmin') &&
                        (row.original.stageId === STAGE_COUNSEL_REQUEST ||
                          row.original.stageId === STAGE_RETURN_FOR_CONFLICT)
                      ? 'bg-primary-medium'
                      : ''
                  }
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        key={`files-table-td-${cell.row.id}-${cell.column.id}`}
                        {...cell.getCellProps()}
                        onClick={() => selectRow(row.original.fileId)}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizes={[20, 50, 100, 500]}
        recordCount={rows.length}
      />
    </div>
  );
};
