import * as H from 'history';
import * as React from 'react';
import { Link, Route, useParams } from 'react-router-dom';
import { Container, Nav, NavItem, NavLink } from 'reactstrap';

import { authAxios } from '../../services/AxiosService';
import { OfficeDetails } from './OfficeDetails';
import { IOffice } from '../../contexts/DomainContext';
import { useEffect, useState } from 'react';

export const Office = () => {
  const [office, setOffice] = useState<IOffice | undefined>(undefined);
  const params = useParams<{ officeId: string; tab?: string }>();
  const officeId = params.officeId;

  useEffect(() => {
    authAxios.get('/api/offices/admin/' + officeId).then((response) => {
      const office = response.data;
      setOffice(office);
    });
  }, [officeId]);

  const tab: string = params.tab || 'details';

  const NavItemLink = (to: H.LocationDescriptor, active: boolean, name: any) => {
    return (
      <NavItem>
        <NavLink tag={Link} to={to} active={active}>
          {name}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <article className='page'>
      <header className='page-header'>
        <Container fluid={true}>
          {office !== undefined && (
            <div className='d-flex flex-column py-3'>
              <h1 className='page-header__title order-2'>{office.name}</h1>
              <h2 className='page-header__id order-1 text-primary'>{office.email}</h2>
            </div>
          )}
          <div className='d-none d-md-block'>
            <Nav tabs={true}>
              {NavItemLink('/offices/' + params.officeId + '/details', tab === 'details', 'Details')}
            </Nav>
          </div>
          <div className='d-block d-md-none pb-4'>
            <Nav pills={true} vertical={true}>
              {NavItemLink('/offices/' + params.officeId + '/details', tab === 'details', 'Details')}
            </Nav>
          </div>
        </Container>
      </header>

      <Route exact={true} path='/offices/:officeId/(details)?' component={OfficeDetails} />
    </article>
  );
};
