import * as React from 'react';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import { FormEvent, useState } from 'react';
import { Button, Col, Container, FormGroup, Label, Navbar, NavbarBrand, Row, Input, FormFeedback } from 'reactstrap';
import { useParams } from 'react-router';
import DatePicker from 'react-datepicker';
import { IUser } from '../Users/UsersTable';
import Select from 'react-select';
import { useContext } from 'react';
import { FileContext, IReminder } from './FileContext';
import { authAxios } from '../../services/AxiosService';
import { notifyError, notifySuccess } from '../../services/ToastService';
import { getUserId } from '../../services/AuthenticationService';
import { useEffect } from 'react';
import {
  DomainContext,
  INotificationType,
  NOTIFICATION_TYPE_ONSCREEN,
  OFFICE_INTAKE,
} from '../../contexts/DomainContext';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { useHistory } from 'react-router-dom';
import { setMinutes, setHours } from 'date-fns';
import { PeopleContext } from '../../contexts/PeopleContext';
import { MyUserContext } from '../../contexts/MyUserContext';

interface IFileReminderDetailsProps {
  onSave?: () => void;
  onCreate?: (r: IReminder) => void;
  onCancel?: () => void;
}

export const FileReminderDetails: React.FC<IFileReminderDetailsProps> = (props) => {
  const params = useParams<{ fileId: string; reminderId?: string }>();
  const peopleContext = useContext(PeopleContext);
  const myUserContext = useContext(MyUserContext);
  const fileContext = useContext(FileContext);
  const file = fileContext.file;
  const domainContext = useContext(DomainContext);
  const history = useHistory();
  const notificationTypeList = domainContext.notificationTypes;
  const staff = peopleContext.staff;
  const [showDeleteReminder, setShowDeleteReminder] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState<IUser[]>(
    myUserContext.isSupportWorker ? peopleContext.supportWorkers.filter((sw) => sw.userId === getUserId()) : []
  );
  const [isDirty, setIsDirty] = useState(false);
  const [text, setText] = useState<string | undefined>();
  const [date, setDate] = useState<Date | null>();
  const [trySave, setTrySave] = useState(false);
  const [notificationTypes, setNotificationTypes] = useState<INotificationType[]>([
    { notificationTypeId: NOTIFICATION_TYPE_ONSCREEN, name: 'On Screen' } as INotificationType,
  ]);
  const [isResolved, setIsResolved] = useState(false);
  const currentDate = new Date();
  const lockDownFields = file.isLockedDown && !myUserContext.isSupportWorker;
  const lawyer = fileContext.file.lawyerId
    ? peopleContext.staff.find((s) => s.userId === fileContext.file.lawyerId)
    : undefined;

  useEffect(() => {
    if (params.reminderId) {
      authAxios.get('/api/files/admin/' + params.fileId + '/reminders/' + params.reminderId).then((response) => {
        const reminder: IReminder = response.data;
        setText(reminder.text);
        setDate(reminder.date ? new Date(reminder.date) : null);
        setSelectedStaff(staff.filter((s) => reminder.staffToNotify.includes(s.userId)));
        setNotificationTypes(
          notificationTypeList.filter((n) => reminder.notificationTypeIds.includes(n.notificationTypeId))
        );
        setIsResolved(reminder.resolved);
      });
    }
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.reminderId, params.fileId]);

  const save = () => {
    if (
      !text ||
      !date ||
      !selectedStaff ||
      selectedStaff.length === 0 ||
      !notificationTypes ||
      notificationTypes.length === 0
    ) {
      setTrySave(true);
      return;
    }

    if (params.reminderId) {
      //update
      authAxios
        .post('/api/files/update-reminder', {
          fileId: params.fileId,
          reminderId: params.reminderId,
          text: text,
          date: date?.getTime(),
          staffToNotify: selectedStaff.map((s) => s.userId),
          notificationTypeIds: notificationTypes.map((n) => n.notificationTypeId),
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            notifySuccess('Reminder updated.');
          } else {
            notifyError('Error updating reminder.');
          }
        });
    } else {
      //create
      authAxios
        .post('/api/files/create-reminder', {
          fileId: params.fileId,
          text: text,
          date: date?.getTime(),
          staffToNotify: selectedStaff.map((s) => s.userId),
          notificationTypeIds: notificationTypes.map((n) => n.notificationTypeId),
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            notifySuccess('Reminder created.');
            const reminder = {
              reminderId: response.data.reminderId,
              text: text ? text : '',
              date: date?.getTime(),
              staffToNotify: selectedStaff.map((s) => s.userId),
              notificationTypeIds: notificationTypes.map((n) => n.notificationTypeId),
              resolved: false,
            } as IReminder;
            props.onCreate && props.onCreate(reminder);
          } else {
            notifyError('Error creating note.');
          }
        });
      if (props.onSave) {
        props.onSave();
      }
    }
  };

  const handleCancel = () => {
    setIsDirty(false);
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const deleteReminder = () => {
    if (params.reminderId) {
      authAxios
        .post('/api/files/delete-reminder', { fileId: params.fileId, reminderId: params.reminderId })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            notifySuccess('Reminder deleted.');
            fileContext.setActiveOnscreenReminders((r) => r.filter((rem) => rem.reminderId !== params.reminderId));
            history.push({
              pathname: '/files/' + params.fileId + '/reminders',
              state: { deletedReminderId: params.reminderId },
            });
          } else {
            notifyError('Error deleting reminder.');
          }
        });
    }
  };

  const resolveReminder = () => {
    authAxios
      .post('/api/files/resolve-reminder', { fileId: params.fileId, reminderId: params.reminderId })
      .then((response) => {
        if (response.data.status === 'OK') {
          setIsResolved(true);
          fileContext.setActiveOnscreenReminders((r) => r.filter((rem) => rem.reminderId !== params.reminderId));
        }
      });
  };

  return (
    <>
      <LeavePageConfirm isDirty={isDirty} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
        className={'no-print'}
      >
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
          <NavbarBrand>{params.reminderId ? 'Edit Reminder' : 'Add Reminder'}</NavbarBrand>
          <div className={'ml-auto d-flex flex-row-reverse'}>
            {!isResolved && !lockDownFields && (
              <Button color='success' className={'ml-2'} onClick={save} disabled={!isDirty}>
                Save Reminder
              </Button>
            )}
            {!isResolved &&
              !lockDownFields &&
              (params.reminderId ? (
                <div>
                  <Button color='danger' className={'ml-2'} onClick={() => setShowDeleteReminder(true)}>
                    Delete Reminder
                  </Button>
                </div>
              ) : (
                <Button color='link' className={'ml-auto'} onClick={handleCancel}>
                  Cancel
                </Button>
              ))}
          </div>
        </Navbar>
        <Container fluid={true}>
          <Row className={'mt-3'}>
            <Col md={12} lg={2} className={'text-lg-right'}>
              <Label htmlFor={'note'} className={'text-body h6 mb-0 font-weight-bold'}>
                Reminder
              </Label>
              <br />
            </Col>
            <Col md={8} lg={7} xl={6}>
              <FormGroup className={'mb-2'}>
                <Input
                  type='textarea'
                  disabled={isResolved || lockDownFields}
                  placeholder='Type reminder here...'
                  name='reminderText'
                  style={{ minHeight: '100px' }}
                  value={text}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setText(e.target.value);
                    setIsDirty(true);
                  }}
                  invalid={trySave && !text}
                />
                <FormFeedback>This field is mandatory</FormFeedback>
              </FormGroup>
              <FormGroup className={'mt-0'}>
                <Label htmlFor={'noteDate'}>Date</Label>
                <DatePicker
                  selected={date}
                  disabled={isResolved || lockDownFields}
                  id={'noteDate'}
                  onChange={(dateToSet) => {
                    setIsDirty(true);
                    if (dateToSet) {
                      setDate(dateToSet);
                    }
                  }}
                  className={'form-control'}
                  minDate={currentDate}
                  minTime={
                    date?.getDate() === currentDate.getDate()
                      ? setHours(setMinutes(currentDate, currentDate.getMinutes()), currentDate.getHours())
                      : setHours(setMinutes(currentDate, 0), 0)
                  }
                  maxTime={setHours(setMinutes(currentDate, 59), 23)}
                  placeholderText={'MMM dd, yyyy hh:mm aa'}
                  peekNextMonth
                  showMonthDropdown
                  showTimeSelect={true}
                  showYearDropdown
                  dateFormat='MMM dd, yyyy hh:mm aa'
                />
                {trySave && date === undefined && (
                  <div style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                    This field is mandatory
                  </div>
                )}
              </FormGroup>
              {params.reminderId &&
                date &&
                date.getTime() < new Date().getTime() &&
                !isResolved &&
                selectedStaff.map((s) => s.userId).includes(getUserId()) && (
                  <FormGroup>
                    <Button color={'warning'} onClick={() => resolveReminder()}>
                      Resolve
                    </Button>
                  </FormGroup>
                )}
            </Col>
            <Col>
              <FormGroup>
                <Label>Users to Notify</Label>
                <Select
                  name={'staffIds'}
                  isDisabled={isResolved || lockDownFields || myUserContext.isSupportWorker}
                  options={
                    lawyer
                      ? peopleContext.staff.filter(
                          (s) =>
                            (s.officeId === lawyer.officeId && s.role === 'LegalAssistant') ||
                            s.userId === lawyer.userId ||
                            fileContext.supportWorkers.includes(s.userId)
                        )
                      : peopleContext.staff.filter(
                          (s) => s.officeId === OFFICE_INTAKE && s.userId !== 'DATA_CONVERSION'
                        )
                  }
                  onChange={(value: any) => {
                    setSelectedStaff(value || []);
                    setIsDirty(true);
                  }}
                  value={selectedStaff}
                  getOptionValue={(option: IUser) => option.userId}
                  getOptionLabel={(option: IUser) =>
                    option.lastName +
                    ', ' +
                    option.firstName +
                    (option.role === 'SupportWorker' ? ' (Support Worker)' : '')
                  }
                  isClearable={true}
                  isMulti={true}
                />
                {trySave && (!selectedStaff || selectedStaff.length === 0) && (
                  <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Notification Type</Label>
                <Select
                  name={'notificationTypes'}
                  isDisabled={isResolved || lockDownFields}
                  options={notificationTypeList}
                  onChange={(value: any) => {
                    setNotificationTypes(value);
                    setIsDirty(true);
                  }}
                  value={notificationTypes}
                  getOptionValue={(option: INotificationType) => option.notificationTypeId}
                  getOptionLabel={(option: INotificationType) => option.name}
                  isClearable={true}
                  isMulti={true}
                />
                {trySave && (!notificationTypes || notificationTypes.length === 0) && (
                  <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                )}
              </FormGroup>
            </Col>
          </Row>
        </Container>
      </form>
      <ConfirmModal
        isOpen={showDeleteReminder}
        title={'Delete Reminder'}
        onConfirm={() => {
          setShowDeleteReminder(false);
          deleteReminder();
        }}
        onCancel={() => setShowDeleteReminder(false)}
        confirmButton={'Delete Reminder'}
      >
        <div>Are you sure you want to delete this reminder?</div>
      </ConfirmModal>
    </>
  );
};
