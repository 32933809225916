import * as React from 'react';
import { useContext, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormFeedback,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import { STAGE_READY_FOR_REVIEW, STAGE_STOPPED } from '../../../contexts/DomainContext';
import { authAxios } from '../../../services/AxiosService';
import { notifyError, notifySuccess } from '../../../services/ToastService';
import { FileContext } from '../FileContext';
import { useHistory, useParams } from 'react-router';
import { ConfirmModal } from '../../ConfirmModal/ConfirmModal';
import { formatDateForServer } from '../../../formatters/DateTimeFormatter';
import { RequiredLabel } from '../../RequiredLabel';

export const PendingActions: React.FC<{
  setIsDirty: (isDirty: boolean) => void;
  isDirty: boolean;
  setTrySubmit: (trySubmit: boolean) => void;
}> = (props) => {
  const params = useParams<{ fileId: string }>();
  const fileContext = useContext(FileContext);
  const setFile = fileContext.setFile;
  const [tryStop, setTryStop] = useState(false);

  const [moveToReviewModal, setMoveToReviewModal] = useState(false);
  const [stopApplicationReason, setStopApplicationReason] = useState('');
  const [stopApplicationModal, setStopApplicationModal] = useState(false);

  const history = useHistory();

  const formFullyFilled = () => {
    return (
      fileContext.applicationTypeId !== undefined && fileContext.clientId !== '' && fileContext.charges?.length > 0
    );
  };

  const stopApplication = () => {
    const stageId = STAGE_STOPPED;
    if (params.fileId) {
      const data = { fileId: params.fileId, stageId, reason: stopApplicationReason };
      authAxios.post('/api/files/admin/start-stage', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('File stage has been updated.');
          setStopApplicationReason('');
          setFile((f) => ({ ...f, stageId: stageId, reasonStopped: stopApplicationReason }));
        } else {
          notifyError(response.data.status);
        }
      });
    }
  };

  const updateStage = (stageId: string) => {
    if (params.fileId) {
      const data = { fileId: params.fileId, stageId };
      authAxios.post('/api/files/admin/start-stage', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('File stage has been updated.');
          setFile((f) => ({ ...f, stageId: stageId }));
        } else {
          notifyError(response.data.status);
        }
      });
    }
  };

  const submitFile = () => {
    if (formFullyFilled()) {
      if (params.fileId) {
        const data = fileContext.getEditData();
        authAxios.post('/api/files/admin/update', data).then((response) => {
          if (response.data.status === 'OK') {
            notifySuccess('File updated.');
            updateStage(STAGE_READY_FOR_REVIEW);
            props.setIsDirty(false);
          } else {
            notifyError(response.data.errorMessage);
          }
        });
      } else {
        const data = {
          applicationDate: formatDateForServer(fileContext.appDate),
          clientId: fileContext.clientId,
          applicationTypeId: fileContext.applicationTypeId,
          thirdParties: fileContext.thirdParties,
          charges: fileContext.charges,
          courtNumber: fileContext.courtFileNumber,
          nextAppearance: fileContext.nextAppearanceDate ? fileContext.nextAppearanceDate.getTime() : undefined,
        };
        authAxios.post('/api/files/admin/submit', data).then((response) => {
          if (response.data.status === 'OK') {
            props.setIsDirty(false);
            const file = response.data.file;
            setFile({ ...file, stageId: STAGE_READY_FOR_REVIEW });
            notifySuccess('File created.');
            history.replace({ pathname: '/files/' + file.fileId, state: { overrideReload: true } });
          } else {
            notifyError(response.data.errorMessage);
          }
        });
      }
    } else {
      props.setTrySubmit(true);
    }
  };

  return (
    <>
      <Card className={'bg-primary-light'}>
        <CardHeader>Application Process</CardHeader>
        <CardBody style={{ padding: 0 }}>
          <ListGroup flush={true}>
            <ListGroupItem className={'bg-primary-light'}>
              <Button
                color='success'
                className='ml-auto'
                onClick={() => {
                  setMoveToReviewModal(true);
                }}
              >
                Move to Review
              </Button>
            </ListGroupItem>
            <ListGroupItem className={'bg-primary-light'}>
              <FormGroup>
                <RequiredLabel required={!stopApplicationReason} for={'reason'}>
                  Reason
                </RequiredLabel>
                <Input
                  value={stopApplicationReason}
                  placeholder={'Add reason here...'}
                  type={'text'}
                  name={'Reason'}
                  onChange={(e: any) => setStopApplicationReason(e.target.value)}
                  invalid={tryStop && !stopApplicationReason}
                />
                <FormFeedback>This field is mandatory</FormFeedback>
              </FormGroup>
              <Button
                color='danger'
                className='ml-auto'
                onClick={() => {
                  if (stopApplicationReason) {
                    setStopApplicationModal(true);
                  } else {
                    setTryStop(true);
                  }
                }}
              >
                Stop Application
              </Button>
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>

      <ConfirmModal
        isOpen={stopApplicationModal}
        title={'Approve'}
        onConfirm={() => {
          setStopApplicationModal(false);
          setTryStop(false);
          stopApplication();
        }}
        onCancel={() => setStopApplicationModal(false)}
        confirmButton={'Stop'}
      >
        <div className={'mb-4'}>Are you sure you want to stop this application?</div>

        <FormFeedback>This field is mandatory</FormFeedback>
      </ConfirmModal>

      <ConfirmModal
        isOpen={moveToReviewModal}
        title={'Move to Review'}
        onConfirm={() => {
          setMoveToReviewModal(false);
          submitFile();
        }}
        onCancel={() => setMoveToReviewModal(false)}
        confirmButton={'Move to Review'}
      >
        <div>Are you sure you want to Move to Review?</div>
      </ConfirmModal>
    </>
  );
};
