import * as React from 'react';
import { useState } from 'react';
import { useAsyncDebounce } from 'react-table';

export const TableGlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter, colSpan }: any) => {
  const count = preGlobalFilteredRows?.length || 0;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <th colSpan={colSpan} className={'p-0'}>
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        aria-label={`Search ${count} records`}
        placeholder={`Search ${count} records…`}
        className={'border-0 m-0 w-100'}
        style={{ padding: '0.75em' }}
      />
    </th>
  );
};
