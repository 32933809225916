import * as React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Navbar, NavbarBrand, Row } from 'reactstrap';
import { FaPrint } from 'react-icons/fa';
import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { PeopleContext } from '../../contexts/PeopleContext';
import { IUser } from '../Users/UsersTable';
import { authAxios } from '../../services/AxiosService';
import { IReportingFile } from '../Files/FileContext';
import { DomainContext, FILE_TYPE_DUTY_COUNSEL, STAGE_CLOSED } from '../../contexts/DomainContext';
import { IndividualsContext } from '../../contexts/IndividualsContext';
import { IOffence } from '../Offences/OffenceTable';
import { formatDate } from '../../formatters/DateTimeFormatter';
import DatePicker from 'react-datepicker';
import { ReportContext } from './ReportContext';

export const ClosedFilesByLawyer = () => {
  const [selectedLawyer, setSelectedLawyer] = useState();
  const peopleContext = useContext(PeopleContext);
  const individualsContext = useContext(IndividualsContext);
  const domainContext = useContext(DomainContext);
  const [filteredFiles, setFilteredFiles] = useState<IReportingFile[]>([]);
  const [fileCount, setFileCount] = useState<number>(0);
  const individuals = individualsContext.state.individuals;
  const [offences, setOffences] = useState<IOffence[]>([]);
  const reportContext = useContext(ReportContext);
  const allFiles = reportContext.allFiles;
  const fromDate = reportContext.fromDate;
  const setFromDate = reportContext.setFromDate;
  const toDate = reportContext.toDate;
  const setToDate = reportContext.setToDate;

  useEffect(() => {
    authAxios.get('/api/offences/admin').then((response) => {
      setOffences(response.data);
    });
  }, []);

  useEffect(() => {
    if (selectedLawyer) {
      setFilteredFiles(
        allFiles.filter(
          (f) =>
            f.sId === STAGE_CLOSED &&
            f.clsDate &&
            f.clsDate >= fromDate.getTime() &&
            f.clsDate <= toDate.getTime() &&
            f.lId &&
            f.lId === selectedLawyer &&
            f.fTId !== FILE_TYPE_DUTY_COUNSEL
        )
      );
      setFileCount(
        allFiles.filter(
          (f) =>
            f.sId === STAGE_CLOSED &&
            f.clsDate &&
            f.clsDate >= fromDate.getTime() &&
            f.clsDate <= toDate.getTime() &&
            f.lId &&
            f.lId === selectedLawyer &&
            f.fTId !== FILE_TYPE_DUTY_COUNSEL
        ).length
      );
    } else {
      setFilteredFiles([]);
      setFileCount(0);
    }
  }, [selectedLawyer, allFiles, fromDate, toDate]);

  const mostSerious = (charges: IOffence[]) => {
    let result: number | undefined = undefined;
    charges.forEach((c) => {
      if (c.group && result) {
        if (c.group < result) {
          result = c.group;
        }
      } else if (c.group) {
        result = c.group;
      }
    });
    return result;
  };

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
        <Row className={'flex-fill'}>
          <Col md={3} xl={2}>
            <NavbarBrand>Closed Files By Lawyer</NavbarBrand>
          </Col>
          <Col className={'d-flex'}>
            <Button className={'ml-auto'} color={'warning'} onClick={() => window.print()}>
              Print <FaPrint />
            </Button>
          </Col>
        </Row>
      </Navbar>
      <Container className={'my-5 container--no-margin-print'}>
        <Row>
          <Col>
            <Card className={'no-print'}>
              <CardHeader>Select File Closed Date Range</CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Label className={'flex second-label'} style={{ paddingRight: '10px' }}>
                      From
                    </Label>
                    <DatePicker
                      className={'form-control date-select flex'}
                      selected={fromDate}
                      onChange={(date) => {
                        if (date) {
                          setFromDate(date);
                        }
                      }}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      shouldCloseOnSelect={true}
                      dateFormat={'yyyy-MM-dd'}
                    />
                  </Col>
                  <Col>
                    <Label
                      className={'flex second-label'}
                      for={'EndDate'}
                      style={{ paddingRight: '10px', paddingLeft: '10px' }}
                    >
                      To
                    </Label>
                    <DatePicker
                      className={'form-control date-select flex'}
                      selected={toDate}
                      onChange={(date) => {
                        if (date) {
                          setToDate(date);
                        }
                      }}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      shouldCloseOnSelect={true}
                      dateFormat={'yyyy-MM-dd'}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card className={'no-print'}>
              <CardHeader>Select Lawyer</CardHeader>
              <CardBody>
                <div className={'flex align-items-center'}>
                  <Label className={'flex second-label'} style={{ paddingRight: '13px' }}>
                    Lawyer
                  </Label>
                  <Select
                    style={{ maxWidth: '240px' }}
                    options={peopleContext.lawyers.sort((a, b) =>
                      a.lastName + a.firstName > b.lastName + b.firstName ? 1 : -1
                    )}
                    placeholder={'Select lawyer...'}
                    value={peopleContext.lawyers.find((u: IUser) => u.userId === selectedLawyer)}
                    onChange={(value: any) => {
                      setSelectedLawyer(value?.userId || '');
                    }}
                    getOptionValue={(option: IUser) => option.userId}
                    getOptionLabel={(option: IUser) => option.lastName.toUpperCase() + ', ' + option.firstName}
                    isClearable={true}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className={'report mt-5'}>
          <header className={'report-header'}>
            <h2 className={'text-center'}>Closed Files By Lawyer</h2>
            {selectedLawyer && (
              <h3 className={'text-center h5 text-muted'}>
                {'Number of Files Closed Between ' +
                  formatDate(fromDate) +
                  ' and ' +
                  formatDate(toDate) +
                  ' for ' +
                  peopleContext.lawyers.find((u: IUser) => u.userId === selectedLawyer)?.lastName.toUpperCase() +
                  ', ' +
                  peopleContext.lawyers.find((u: IUser) => u.userId === selectedLawyer)?.firstName +
                  ': ' +
                  fileCount}
              </h3>
            )}
          </header>

          <table className={'report1'}>
            <tr>
              <th className={'header'}>File Number</th>
              <th className={'header'}>Client</th>
              <th className={'header'}>Application Type</th>
              <th className={'header'}>File Type</th>
              <th className={'header'}>Charges</th>
              <th className={'header'}>Most Serious</th>
              <th className={'header'}>Application Date</th>
              <th className={'header'}>Closed Date</th>
            </tr>
            {filteredFiles
              .sort((a, b) => (a.clsDate! < b.clsDate! ? 1 : -1))
              .map((f) => {
                return (
                  <tr key={f.fId}>
                    <td className={'report-row text-left'}>{f.fNum}</td>
                    <td className={'report-row'}>
                      {individuals.find((i) => i.individualId === f.cId)?.lastName.toUpperCase() +
                        ', ' +
                        individuals.find((i) => i.individualId === f.cId)?.firstName}
                    </td>
                    <td className={'report-row'}>
                      {domainContext.applicationTypes.find((a) => a.applicationTypeId === f.appTypeId)?.name}
                    </td>
                    <td className={'report-row'}>
                      {domainContext.fileTypes.find((ft) => ft.fileTypeId === f.fTId)?.name}
                    </td>
                    <td className={'report-row'}>
                      {offences
                        .filter((o) => f.chrgs.map((c) => c.offenceId).includes(o.offenceId))
                        .map((o) => o.number)
                        .join(', ')}
                    </td>
                    <td className={'report-row'}>
                      {mostSerious(offences.filter((o) => f.chrgs.map((c) => c.offenceId).includes(o.offenceId)))}
                    </td>
                    <td className={'report-row'}>{formatDate(new Date(f.appDate))}</td>
                    <td className={'report-row'}>{formatDate(new Date(f.clsDate!))}</td>
                  </tr>
                );
              })}
          </table>
        </div>
      </Container>
    </div>
  );
};
