import * as React from 'react';
import { FormEvent } from 'react';
import { Button, Col, Navbar, NavbarBrand, Row } from 'reactstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { TablePagination } from '../TablePagination/TablePagination';
import { useMemo } from 'react';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { Column, TableInstance, usePagination, useSortBy, useTable } from 'react-table';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useContext } from 'react';
import { MyUserContext } from '../../contexts/MyUserContext';
import { IIntervention, SupportWorkerRequestContext } from 'src/components/SupportRequests/SupportWorkerRequestContext';
import { DomainContext, IInterventionStatus, IInterventionType, SUPPORT_REQUEST } from 'src/contexts/DomainContext';
import { DropdownMultiSelectCheckboxes } from 'src/components/DropdownMultiSelectCheckboxes/DropdownMultiSelectCheckboxes';

export const SupportWorkerRequestInterventionsTable: React.FC<{ entityType: string }> = (props) => {
  const params = useParams<{ supportWorkerRequestId: string }>();

  const myUserContext = useContext(MyUserContext);
  const domainContext = useContext(DomainContext);
  const supportWorkerRequestsContext = useContext(SupportWorkerRequestContext);
  const { interventions, supportWorkerRequest } = supportWorkerRequestsContext;
  const interventionStatuses = domainContext.interventionStatuses;
  const interventionTypes = domainContext.interventionTypes;
  const history = useHistory();

  const columns: Column<IIntervention>[] = useMemo(
    () => [
      {
        Header: 'Type',
        id: 'interventionTypeId',
        accessor: (intervention: IIntervention) => {
          return interventionTypes.find(
            (t: IInterventionType) => t.interventionTypeId === intervention.interventionTypeId
          )?.name;
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='interventionTypeId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={interventionTypes.map((s: IInterventionType) => ({
                value: s.interventionTypeId,
                display: s.name,
              }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['interventionTypeId'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
      },
      {
        Header: 'Details',
        accessor: 'details',
      },
      {
        Header: 'Status',
        id: 'interventionStatusId',
        accessor: (intervention: IIntervention) => {
          return interventionStatuses.find(
            (t: IInterventionStatus) => t.interventionStatusId === intervention.interventionStatusId
          )?.name;
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='interventionStatusId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={interventionStatuses.map((s: IInterventionStatus) => ({
                value: s.interventionStatusId,
                display: s.name,
              }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['interventionStatusId'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
      },
    ],
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.supportWorkerRequestId]
  );

  const data: IIntervention[] = useMemo(() => interventions, [interventions]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: TableInstance<IIntervention> = useTable(
    {
      columns: columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        sortBy: [
          {
            id: 'interventionStatusId',
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    usePagination
  );

  const selectRow = (interventionId: string) => {
    history.push('/support-worker-requests/' + params.supportWorkerRequestId + '/interventions/' + interventionId);
  };

  return (
    <div>
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
          <Row className={'flex-fill'}>
            <Col md={3} xl={2}>
              <NavbarBrand>Interventions</NavbarBrand>
            </Col>
            {myUserContext.isSupportWorker && supportWorkerRequest.status === SUPPORT_REQUEST.ACCEPTED && (
              <Col className={'d-flex'}>
                <Button
                  color='success'
                  className={'ml-auto'}
                  onClick={() =>
                    history.push('/support-worker-requests/' + params.supportWorkerRequestId + '/interventions/add')
                  }
                >
                  New Intervention
                </Button>
              </Col>
            )}
          </Row>
        </Navbar>
        <div className={'table-responsive'} style={{ minHeight: '500px' }}>
          <table className={'table table-bordered table-hover'} {...getTableProps()}>
            <thead style={{ overflow: 'visible' }}>
              {headerGroups.map((headerGroup: any, index: number) => (
                <tr key={`files-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      key={`files-table-thead-tr-${index}-${column.id}`}
                      width={column.width}
                      style={{ maxWidth: column.width }}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                      <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
              {page.map((row: any) => {
                prepareRow(row);
                return (
                  <tr key={`files-table-tr-${row.id}`} {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      return (
                        <td
                          key={`files-table-td-${cell.row.id}-${cell.column.id}`}
                          {...cell.getCellProps()}
                          onClick={() => selectRow(row.original.interventionId)}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <TablePagination
          pageCount={pageCount}
          pageOptions={pageOptions}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageSizes={[20, 50, 100, 500]}
        />
      </form>
    </div>
  );
};
