import * as React from 'react';
import { useEffect, useState } from 'react';
import { IOffence } from './OffenceTable';
import { authAxios } from '../../services/AxiosService';
import { IOffenceCategoryType } from '../../contexts/DomainContext';

type ContextType = {
  offences: IOffence[];
  setOffences: React.Dispatch<React.SetStateAction<IOffence[]>>;
  categoryTypes: IOffenceCategoryType[];
};

export const OffencesContext = React.createContext<ContextType>({
  offences: [] as IOffence[],
  setOffences: () => {},
  categoryTypes: [] as IOffenceCategoryType[],
});

export const OffencesProvider = (props: any) => {
  const [offences, setOffences] = useState([] as IOffence[]);
  const [categoryTypes, setCategoryTypes] = useState<IOffenceCategoryType[]>([]);

  useEffect(() => {
    authAxios.get('/api/offences/admin').then((response) => {
      setOffences(response.data);
    });
    authAxios.get('/api/offences/category-types').then((response) => {
      setCategoryTypes(
        response.data.sort((a: IOffenceCategoryType, b: IOffenceCategoryType) =>
          a.name.toUpperCase().localeCompare(b.name.toUpperCase())
        ) as IOffenceCategoryType[]
      );
    });
  }, []);

  return (
    <OffencesContext.Provider
      value={{
        offences,
        setOffences,
        categoryTypes,
      }}
    >
      {props.children}
    </OffencesContext.Provider>
  );
};

export const OffencesConsumer = OffencesContext.Consumer;
