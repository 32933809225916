import { useContext, useMemo } from 'react';
import { IFile } from '../Files/FileContext';
import { clientSelectValuesValue } from '../../formatters/PeopleFormatter';
import { IOffence } from '../Offences/OffenceTable';
import {
  defaultStagesLawOfficeNoClosed,
  defaultStagesLawOfficeWithClosed,
  DomainContext,
  IStage,
  STAGE_COUNSEL_REQUEST_DRAFT,
} from '../../contexts/DomainContext';
import { DropdownMultiSelectCheckboxes } from '../DropdownMultiSelectCheckboxes/DropdownMultiSelectCheckboxes';
import * as React from 'react';
import { FilesTableContext } from '../Files/FilesTableContext';
import { Column } from 'react-table';
import { DateRangeDropdown } from '../DateRangeDropdown/DateRangeDropdown';

export const useLegalAssistantColumns = (): Column<IFile>[] => {
  const domainContext = useContext(DomainContext);
  const offences = domainContext.offences;
  const stages = domainContext.stages;
  const searchContext = useContext(FilesTableContext);

  return useMemo(
    () => [
      {
        Header: 'Law Office Number',
        accessor: 'lawOfficeNumber',
      },
      {
        Header: 'Client',
        id: 'clientId',
        accessor: (file: IFile) => {
          return `${file.clientName} ${file.clientAliases} ${file.clientDateOfBirth}`;
        },
        Cell: (cell: any) => {
          if (cell && cell.row.original && cell.row.original.clientId) {
            return clientSelectValuesValue(
              cell.row.original.clientName,
              cell.row.original.clientAliases,
              cell.row.original.clientDateOfBirth
            );
          } else {
            return <span />;
          }
        },
      },
      {
        Header: 'Lawyer',
        accessor: 'lawyerFormatted',
      },
      {
        Header: 'Court File Number',
        accessor: 'courtNumber',
      },
      {
        Header: 'Charges',
        id: 'charges',
        accessor: (f: IFile) => {
          const chargeList = f.charges
            ? f.charges.map((charge) => offences.find((o) => o.offenceId === charge.offenceId))
            : [];
          return chargeList
            .sort((a: IOffence | undefined, b: IOffence | undefined) => {
              if (a && b && a.group && b.group) {
                if (a.group > b.group) {
                  return 1;
                } else if (b.group > a.group) {
                  return -1;
                } else {
                  return 0;
                }
              }
              return 0;
            })
            .map((offence) => (offence ? offence?.number : ''))
            .join(', ');
        },
      },
      {
        Header: 'Lawyer Requested Date',
        accessor: 'lawyerRequestedDateFormatted',
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          if (rowA.original['lawyerRequestedDate'] && rowB.original['lawyerRequestedDate']) {
            if (rowA.original['lawyerRequestedDate'] > rowB.original['lawyerRequestedDate']) return 1;
            if (rowB.original['lawyerRequestedDate'] > rowA.original['lawyerRequestedDate']) return -1;
            return 0;
          } else if (rowA.original['lawyerRequestedDate'] && !rowB.original['lawyerRequestedDate']) {
            return 1;
          } else if (rowB.original['lawyerRequestedDate'] && !rowA.original['lawyerRequestedDate']) {
            return -1;
          } else {
            return 0;
          }
        },
        filter: (rows: any, id: any, filterValue: { startDate?: Date; endDate?: Date }) => {
          return rows.filter((row: any) => {
            if (!filterValue.startDate || !filterValue.endDate) {
              return true;
            } else {
              const rowValue = row.original['lawyerRequestedDate'];
              return rowValue > filterValue.startDate.getTime() && rowValue < filterValue.endDate.getTime();
            }
          });
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => (
          <DateRangeDropdown
            startDate={filterValue?.startDate}
            endDate={filterValue?.endDate}
            setRange={(dates: { startDate?: Date; endDate?: Date }) => {
              setFilter(dates);
            }}
            keyPrefix={'lawyerRequestedDate'}
          />
        ),
      },
      {
        Header: 'Assigned Lawyer Date',
        id: 'lawyerAssignedDate',
        accessor: 'lawyerAssignedDateFormatted',
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          if (rowA.original['lawyerAssignedDate'] && rowB.original['lawyerAssignedDate']) {
            if (rowA.original['lawyerAssignedDate'] > rowB.original['lawyerAssignedDate']) return 1;
            if (rowB.original['lawyerAssignedDate'] > rowA.original['lawyerAssignedDate']) return -1;
            return 0;
          } else if (rowA.original['lawyerAssignedDate'] && !rowB.original['lawyerAssignedDate']) {
            return 1;
          } else if (rowB.original['lawyerRequestedDate'] && !rowA.original['lawyerRequestedDate']) {
            return -1;
          } else if (rowA.original['lawyerRequestedDate'] && rowB.original['lawyerRequestedDate']) {
            if (rowA.original['lawyerRequestedDate'] > rowB.original['lawyerRequestedDate']) return 1;
            if (rowB.original['lawyerRequestedDate'] > rowA.original['lawyerRequestedDate']) return -1;
            return 0;
          } else {
            return 0;
          }
        },
        filter: (rows: any, id: any, filterValue: { startDate?: Date; endDate?: Date }) => {
          return rows.filter((row: any) => {
            if (!filterValue.startDate || !filterValue.endDate) {
              return true;
            } else {
              const rowValue = row.original['lawyerAssignedDate'];
              return rowValue > filterValue.startDate.getTime() && rowValue < filterValue.endDate.getTime();
            }
          });
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => (
          <DateRangeDropdown
            startDate={filterValue?.startDate}
            endDate={filterValue?.endDate}
            setRange={(dates: { startDate?: Date; endDate?: Date }) => {
              setFilter(dates);
            }}
            keyPrefix={'lawyerAssignedDate'}
          />
        ),
      },
      {
        Header: 'Stage',
        id: 'stageId',
        accessor: (file: IFile) => {
          return stages.find((s: IStage) => s.stageId === file.stageId)?.name;
        },
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          const rowAStage = stages.find((s: IStage) => s.stageId === rowA.original[id]);
          const rowBStage = stages.find((s: IStage) => s.stageId === rowB.original[id]);

          if (rowAStage && rowBStage) {
            if (
              rowAStage.stageId === STAGE_COUNSEL_REQUEST_DRAFT &&
              rowBStage.stageId === STAGE_COUNSEL_REQUEST_DRAFT
            ) {
              if (
                rowA.original.reasonCounselRequestRejected &&
                rowA.original.reasonCounselRequestRejected.length > 0 &&
                (!rowB.original.reasonCounselRequestRejected || rowB.original.reasonCounselRequestRejected.length === 0)
              ) {
                return -1;
              } else if (
                rowB.original.reasonCounselRequestRejected &&
                rowB.original.reasonCounselRequestRejected.length > 0 &&
                (!rowA.original.reasonCounselRequestRejected || rowA.original.reasonCounselRequestRejected.length === 0)
              ) {
                return 1;
              } else {
                return 0;
              }
            } else if (
              rowAStage.stageId === STAGE_COUNSEL_REQUEST_DRAFT &&
              rowA.original.reasonCounselRequestRejected &&
              rowA.original.reasonCounselRequestRejected.length > 0 &&
              rowBStage.stageId !== STAGE_COUNSEL_REQUEST_DRAFT
            ) {
              return -1;
            } else if (
              rowBStage.stageId === STAGE_COUNSEL_REQUEST_DRAFT &&
              rowB.original.reasonCounselRequestRejected &&
              rowB.original.reasonCounselRequestRejected.length > 0 &&
              rowAStage.stageId !== STAGE_COUNSEL_REQUEST_DRAFT
            ) {
              return 1;
            } else {
              if (rowAStage.order > rowBStage.order) {
                return 1;
              } else if (rowBStage.order > rowAStage.order) {
                return -1;
              } else {
                return 0;
              }
            }
          } else {
            return 0;
          }
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='stageId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={stages
                .filter((s) =>
                  searchContext.includeClosed
                    ? defaultStagesLawOfficeWithClosed.includes(s.stageId)
                    : defaultStagesLawOfficeNoClosed.includes(s.stageId)
                )
                .map((s: IStage) => ({
                  value: s.stageId,
                  display: s.name,
                }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['stageId'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
      },
    ],
    [offences, stages, searchContext.includeClosed]
  );
};
