import * as React from 'react';
import * as AuthenticationService from '../services/AuthenticationService';
import { useEffect } from 'react';

interface IAuthState {
  type: AuthState;
  expiringSoon?: boolean;
}

interface AuthStateWithDispatch {
  state: IAuthState;
  dispatch: React.Dispatch<AuthAction>;
}

export const AUTH_STATE_LOADING = 'AUTH_STATE_LOADING';
export const AUTH_STATE_SIGNED_OUT = 'AUTH_STATE_SIGNED_OUT';
export const AUTH_STATE_SIGNED_IN = 'AUTH_STATE_SIGNED_IN';
export type AuthState = 'AUTH_STATE_LOADING' | 'AUTH_STATE_SIGNED_OUT' | 'AUTH_STATE_SIGNED_IN';

export const AUTH_ACTION_LOADING = 'AUTH_ACTION_LOADING';
export const AUTH_ACTION_SIGN_OUT = 'AUTH_ACTION_SIGN_OUT';
export const AUTH_ACTION_SIGN_IN = 'AUTH_ACTION_SIGN_IN';
export const AUTH_ACTION_EXPIRING_SOON = 'AUTH_ACTION_EXPIRING_SOON';
export const AUTH_ACTION_EXPIRED = 'AUTH_ACTION_EXPIRED';
export type AuthAction =
  | 'AUTH_ACTION_LOADING'
  | 'AUTH_ACTION_SIGN_IN'
  | 'AUTH_ACTION_SIGN_OUT'
  | 'AUTH_ACTION_EXPIRING_SOON'
  | 'AUTH_ACTION_EXPIRED';

export const AuthContext = React.createContext<AuthStateWithDispatch>({
  state: { type: AUTH_STATE_LOADING },
  dispatch: () => ({}),
});

const authReducer: React.Reducer<IAuthState, AuthAction> = (state: IAuthState, action: AuthAction) => {
  console.log(`IN REDUCER STATE: ${state.type}  ACTION: ${action} `);
  switch (action) {
    case AUTH_ACTION_LOADING:
      return { type: AUTH_STATE_LOADING };
    case AUTH_ACTION_SIGN_IN:
      return { type: AUTH_STATE_SIGNED_IN, expiringSoon: false };
    case AUTH_ACTION_EXPIRING_SOON:
      return { ...state, expiringSoon: true };
    case AUTH_ACTION_SIGN_OUT:
      return { type: AUTH_STATE_SIGNED_OUT };
    default:
      return state;
  }
};

export const AuthProvider: React.FC = (props) => {
  const [state, dispatch] = React.useReducer(authReducer, { type: AUTH_STATE_LOADING });

  useEffect(() => {
    if (AuthenticationService.getJwtFromStorage() !== null) {
      dispatch(AUTH_ACTION_SIGN_IN);
    } else {
      dispatch(AUTH_ACTION_SIGN_OUT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value: AuthStateWithDispatch = { state, dispatch };
  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};
