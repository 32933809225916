import { useContext, useMemo } from 'react';
import { IFile } from '../Files/FileContext';
import { clientSelectValuesValue } from '../../formatters/PeopleFormatter';
import { IOffence } from '../Offences/OffenceTable';
import {
  DomainContext,
  IStage,
  supportWorkerVisibleStagesNoClosed,
  supportWorkerVisibleStagesWithClosed,
} from '../../contexts/DomainContext';
import { DropdownMultiSelectCheckboxes } from '../DropdownMultiSelectCheckboxes/DropdownMultiSelectCheckboxes';
import * as React from 'react';
import { FilesTableContext } from '../Files/FilesTableContext';
import { Column } from 'react-table';

export const useSupportWorkerColumns = (): Column<IFile>[] => {
  const domainContext = useContext(DomainContext);
  const offences = domainContext.offences;
  const stages = domainContext.stages;
  const searchContext = useContext(FilesTableContext);

  return useMemo(
    () => [
      {
        Header: 'Court File Number',
        accessor: 'courtNumber',
      },
      {
        Header: 'Client',
        id: 'clientId',
        accessor: (file: IFile) => {
          return `${file.clientName} ${file.clientAliases} ${file.clientDateOfBirth}`;
        },
        Cell: (cell: any) => {
          if (cell && cell.row.original && cell.row.original.clientId) {
            return clientSelectValuesValue(
              cell.row.original.clientName,
              cell.row.original.clientAliases,
              cell.row.original.clientDateOfBirth
            );
          } else {
            return <span />;
          }
        },
      },
      {
        Header: 'Lawyer',
        accessor: 'lawyerFormatted',
      },
      {
        Header: 'Charges',
        id: 'charges',
        accessor: (f: IFile) => {
          const chargeList = f.charges
            ? f.charges.map((charge) => offences.find((o) => o.offenceId === charge.offenceId))
            : [];
          return chargeList
            .sort((a: IOffence | undefined, b: IOffence | undefined) => {
              if (a && b && a.group && b.group) {
                if (a.group > b.group) {
                  return 1;
                } else if (b.group > a.group) {
                  return -1;
                } else {
                  return 0;
                }
              }
              return 0;
            })
            .map((offence) => (offence ? offence?.number : ''))
            .join(', ');
        },
      },
      {
        Header: 'Stage',
        id: 'stageId',
        accessor: (file: IFile) => {
          return stages.find((s: IStage) => s.stageId === file.stageId)?.name;
        },
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          const rowAStage = stages.find((s: IStage) => s.stageId === rowA.original[id]);
          const rowBStage = stages.find((s: IStage) => s.stageId === rowB.original[id]);

          if (rowAStage && rowBStage) {
            if (rowAStage.name > rowBStage.name) {
              return 1;
            } else if (rowAStage.name < rowBStage.name) {
              return -1;
            } else {
              return 0;
            }
          }
          return 0;
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue || []}
              keyPrefix='stageId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={stages
                .filter((s) =>
                  searchContext.includeClosed
                    ? supportWorkerVisibleStagesWithClosed.includes(s.stageId)
                    : supportWorkerVisibleStagesNoClosed.includes(s.stageId)
                )
                .map((s: IStage) => ({
                  value: s.stageId,
                  display: s.name,
                }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (!filterValue || filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['stageId'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
      },
    ],
    [offences, stages, searchContext.includeClosed]
  );
};
