import { Card, CardHeader } from 'reactstrap';
import { formatDateTime } from '../../formatters/DateTimeFormatter';
import * as React from 'react';
import { useContext } from 'react';
import { FileContext } from './FileContext';
import { PeopleContext } from '../../contexts/PeopleContext';
import { DomainContext } from '../../contexts/DomainContext';

export const FileReasonRejected = () => {
  const domainContext = useContext(DomainContext);
  const peopleContext = useContext(PeopleContext);
  const fileContext = useContext(FileContext);
  const file = fileContext.file;
  const offices = domainContext.offices;

  return (
    <Card>
      <CardHeader>
        <span>Reason Rejected</span>
      </CardHeader>
      <table className='table'>
        <thead>
          <tr>
            <th className={'pl-3'}>Lawyer Name</th>
            <th>Office</th>
            <th>Reason Rejected</th>
            <th>Date Rejected</th>
          </tr>
        </thead>
        <tbody>
          {file.rejectedLawyers &&
            file.rejectedLawyers
              .filter((rl) => rl.lawyerId === file.lawyerId)
              .map((rejectedLawyer, key) => {
                const lawyerInfo = peopleContext.lawyers.find((l) => l.userId === rejectedLawyer.lawyerId);
                const office = offices.find((o) => o.officeId === lawyerInfo?.officeId);
                return (
                  <tr key={rejectedLawyer.lawyerId + '-' + key}>
                    <td>{lawyerInfo ? lawyerInfo.lastName.toUpperCase() + ', ' + lawyerInfo.firstName : ''}</td>
                    <td>{office ? office.name : ''}</td>
                    <td>{rejectedLawyer.reason}</td>
                    <td>{formatDateTime(new Date(rejectedLawyer.dateRejected)).replace('.', '')}</td>
                  </tr>
                );
              })}
        </tbody>
      </table>
    </Card>
  );
};
