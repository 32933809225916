import { Button, Card, CardHeader, FormFeedback, FormGroup, Input, Label, ListGroup, ListGroupItem } from 'reactstrap';
import * as React from 'react';
import { useContext, useState } from 'react';
import { authAxios } from '../../../services/AxiosService';
import { notifyError, notifySuccess } from '../../../services/ToastService';
import { STAGE_ASSIGNED_TO_LAWYER, STAGE_RETURN_FOR_CONFLICT } from '../../../contexts/DomainContext';
import { useHistory, useParams } from 'react-router';
import { FileContext, IRejectedLawyer } from '../FileContext';
import { MyUserContext } from '../../../contexts/MyUserContext';
import { ConfirmModal } from '../../ConfirmModal/ConfirmModal';

export const LawyerRequestActions = () => {
  const params = useParams<{ fileId: string }>();
  const fileContext = useContext(FileContext);
  const myUserContext = useContext(MyUserContext);
  const file = fileContext.file;
  const setFile = fileContext.setFile;
  const history = useHistory();

  const [lawyerReason, setLawyerReason] = useState('');
  const [trySubmit, setTrySubmit] = useState(false);

  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);

  const acceptRequest = () => {
    const data = { fileId: params.fileId, lawyerId: file?.lawyerId };
    authAxios.post('/api/files/admin/accept-lawyer-request', data).then((response) => {
      if (response.data.status === 'OK') {
        notifySuccess('Request Accepted.');
        fileContext.setLawOfficeNumber(response.data.lawOfficeNumber);
        setFile((f) => ({ ...f, stageId: STAGE_ASSIGNED_TO_LAWYER, lawOfficeNumber: response.data.lawOfficeNumber }));
      } else {
        notifyError(response.data.errorMessage);
      }
    });
  };

  const rejectRequest = (reason?: string) => {
    if (reason) {
      const data = { fileId: params.fileId, lawyerId: file?.lawyerId, reason };
      authAxios.post('/api/files/admin/reject-lawyer-request', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('Request Rejected.');
          setFile((f) => ({
            ...f,
            lawyerId: undefined,
            officeId: undefined,
            stageId: STAGE_RETURN_FOR_CONFLICT,
            rejectedLawyers: [
              {
                lawyerId: f.lawyerId ? f.lawyerId : '',
                reason: reason,
                dateRejected: new Date().getTime(),
              },
            ].concat(f.rejectedLawyers ? f.rejectedLawyers : []) as IRejectedLawyer[],
          }));
          if (!myUserContext.isIntakeOffice) {
            history.push({ pathname: '/files', state: { deletedFileId: params.fileId } });
          }
        } else {
          notifyError(response.data.errorMessage);
        }
      });
    } else {
      setTrySubmit(true);
    }
  };

  return (
    <>
      <Card className={'bg-primary-light mb-3'}>
        <CardHeader>Accept or Reject Lawyer Request</CardHeader>
        <ListGroup flush={true}>
          <ListGroupItem className={'bg-primary-light'}>
            <Button color='success' className='ml-auto' onClick={() => setAcceptModal(true)}>
              Accept Request
            </Button>
          </ListGroupItem>
          <ListGroupItem className={'bg-primary-light'}>
            <FormGroup>
              <Label for={'reason'}>If rejecting, please provide a reason</Label>
              <Input
                value={lawyerReason}
                type={'text'}
                name={'Reason'}
                autoComplete={'off'}
                onChange={(e: any) => setLawyerReason(e.target.value)}
                invalid={trySubmit && lawyerReason === ''}
              />
              <FormFeedback>This field is mandatory</FormFeedback>
            </FormGroup>
            <Button
              color='danger'
              className='ml-auto'
              onClick={() => {
                setTrySubmit(true);
                if (lawyerReason) {
                  setRejectModal(true);
                }
              }}
            >
              Reject Request
            </Button>
          </ListGroupItem>
        </ListGroup>
      </Card>
      <ConfirmModal
        isOpen={acceptModal}
        title={'Accept'}
        onConfirm={() => {
          setAcceptModal(false);
          acceptRequest();
        }}
        onCancel={() => setAcceptModal(false)}
        confirmButton={'Accept'}
      >
        <div>Are you sure you want to accept the request?</div>
      </ConfirmModal>
      <ConfirmModal
        isOpen={rejectModal}
        title={'Reject'}
        onConfirm={() => {
          setRejectModal(false);
          rejectRequest(lawyerReason);
        }}
        onCancel={() => setRejectModal(false)}
        confirmButton={'Reject'}
      >
        <div>Are you sure you want to reject the request?</div>
      </ConfirmModal>
    </>
  );
};
