import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { authAxios } from '../../services/AxiosService';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Column, TableInstance, usePagination, useSortBy, useTable } from 'react-table';
import { DomainContext, IOffice } from '../../contexts/DomainContext';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { TablePagination } from '../TablePagination/TablePagination';
import { IHoursRecord } from '../Files/FileContext';
import { IUser } from '../Users/UsersTable';
import Select, { components, OptionProps, SingleValueProps } from 'react-select';
import { PeopleContext } from '../../contexts/PeopleContext';
import { lawyerSelectValue } from '../../formatters/PeopleFormatter';
import DatePicker from 'react-datepicker';
import { FaPlus } from 'react-icons/all';
import { formatDateDayOfWeek, formatDateForServer } from '../../formatters/DateTimeFormatter';
import { RecordNonFileTime } from '../Users/UserRecordNonFileTime';
import { getUserId } from '../../services/AuthenticationService';
import { MyUserContext } from '../../contexts/MyUserContext';
import * as AuthenticationService from '../../services/AuthenticationService';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';

export const TimeTracking: React.FC = () => {
  const domainContext = useContext(DomainContext);
  const peopleContext = useContext(PeopleContext);
  const myUserContext = useContext(MyUserContext);
  const timeTrackingActivities = domainContext.timeTrackingActivities.filter((tta) => {
    if (myUserContext.isSupportWorker) {
      return tta.userRole === 'SupportWorker';
    } else {
      return tta.userRole === 'Lawyer';
    }
  });
  const offices = domainContext.offices;
  const [selectedUser, setSelectedUser] = useState(
    myUserContext.isLawyer || myUserContext.isSupportWorker ? getUserId() : undefined
  );
  const fromDate = new Date();
  fromDate.setHours(12, 0, 0, 0);
  const toDate = new Date();
  toDate.setHours(12, 0, 0, 0);
  const firstDayOfWeek = fromDate.getDate() - fromDate.getDay();
  const [selectedToDate, setSelectedToDate] = useState<Date>(new Date(toDate.setDate(firstDayOfWeek + 6)));
  const [selectedFromDate, setSelectedFromDate] = useState<Date>(new Date(fromDate.setDate(firstDayOfWeek)));
  const [userTimeTracking, setUserTimeTracking] = useState<IHoursRecord[]>([]);
  const [fileTimeTracking, setFileTimeTracking] = useState<IHoursRecord[]>([]);
  const [interventionTimeTracking, setInterventionTimeTracking] = useState<IHoursRecord[]>([]);
  const [filteredEntries, setFilteredEntries] = useState<IHoursRecord[]>([]);
  const [dailyTotals, setDailyTotals] = useState<Map<string, number>>(new Map());
  const [openAddTimeModal, setOpenAddTimeModal] = useState(false);
  const [entryToEdit, setEntryToEdit] = useState<IHoursRecord | undefined>(undefined);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (selectedUser && selectedFromDate && selectedToDate) {
      authAxios
        .get(
          `/api/users/admin/${selectedUser}/time-tracking?startDate=${selectedFromDate.getTime()}&endDate=${selectedToDate.getTime()}`
        )
        .then((response) => {
          setUserTimeTracking(response.data);
        });
      authAxios
        .get(
          `/api/files/time-tracking/${selectedUser}?startDate=${selectedFromDate.getTime()}&endDate=${selectedToDate.getTime()}`
        )
        .then((response) => {
          setFileTimeTracking(response.data);
        });
      if (myUserContext.isSupportWorker) {
        authAxios
          .get(
            `/api/support-worker-requests/time-tracking/${selectedUser}?startDate=${selectedFromDate.getTime()}&endDate=${selectedToDate.getTime()}`
          )
          .then((response) => {
            setInterventionTimeTracking(response.data);
          });
      }
    } else {
      setInterventionTimeTracking([]);
      setUserTimeTracking([]);
      setFileTimeTracking([]);
    }
  }, [selectedUser, selectedFromDate, selectedToDate, myUserContext.isSupportWorker]);

  useEffect(() => {
    const state: any = location.state;
    let newEntryList: IHoursRecord[] = [];
    if (state) {
      const newEntries: IHoursRecord[] = state.newHours;
      newEntryList = newEntries.filter(
        (e) => e.userId === selectedUser && e.date >= selectedFromDate.getTime() && e.date <= selectedToDate.getTime()
      );
    }

    setFilteredEntries(
      [...userTimeTracking, ...fileTimeTracking, ...interventionTimeTracking]
        .filter((e) => {
          return e.date >= selectedFromDate.getTime() && e.date <= selectedToDate.getTime();
        })
        .concat(newEntryList)
        .filter((v, i, a) => a.findIndex((t) => t.hoursRecordId === v.hoursRecordId) === i)
    );
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFromDate, selectedToDate, userTimeTracking, fileTimeTracking, location.state]);

  useEffect(() => {
    const mapTemp = new Map<string, number>();
    filteredEntries.forEach((e) => {
      const totalSoFar = mapTemp.get(formatDateForServer(new Date(e.date)));
      const updatedTotal: number = (totalSoFar ? Number(totalSoFar.toFixed(2)) : 0.0) + e.hours;
      mapTemp.set(formatDateForServer(new Date(e.date)), Number(updatedTotal.toFixed(2)));
    });
    setDailyTotals(mapTemp);
  }, [filteredEntries]);

  const totalDailyValuesFromMap = () => {
    let total = 0;
    dailyTotals.forEach((value) => {
      total = total + value;
    });
    return total.toFixed(2);
  };

  const addNewTimeEntry = (
    hoursRecordId: string,
    timeEntryUserId: string,
    timeTrackingActivityId: string,
    date: number,
    hours: number,
    details?: string
  ) => {
    if (timeEntryUserId === selectedUser && date >= selectedFromDate.getTime() && date <= selectedToDate.getTime()) {
      setFilteredEntries((entries) => [
        ...entries,
        {
          hoursRecordId,
          userId: timeEntryUserId,
          timeTrackingActivityId: timeTrackingActivityId,
          date,
          hours,
          timestamp: new Date().getTime(),
          details: details,
        } as IHoursRecord,
      ]);
    }
  };

  const updateTimeEntry = (
    hoursRecordId: string,
    timeEntryUserId: string,
    timeTrackingActivityId: string,
    date: number,
    hours: number,
    fileId?: string,
    details?: string,
    fileNumber?: string,
    clientName?: string
  ) => {
    if (timeEntryUserId === selectedUser && date >= selectedFromDate.getTime() && date <= selectedToDate.getTime()) {
      setFilteredEntries((entries) =>
        entries.map((e) => {
          if (e.hoursRecordId === hoursRecordId) {
            return {
              hoursRecordId,
              userId: timeEntryUserId,
              timeTrackingActivityId: timeTrackingActivityId,
              date,
              hours,
              fileId,
              timestamp: new Date().getTime(),
              details: details,
              fileNumber: fileNumber,
              clientName: clientName,
            } as IHoursRecord;
          } else {
            return e;
          }
        })
      );
    }
  };

  const deleteTimeEntry = (hoursRecordId: string, timeEntryUserId: string) => {
    if (timeEntryUserId === selectedUser) {
      setFilteredEntries(filteredEntries.filter((e) => e.hoursRecordId !== hoursRecordId));
    }
  };

  const columns: Column<IHoursRecord>[] = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
        Cell: (cell: any) => (cell?.row?.original?.date ? formatDateDayOfWeek(new Date(cell.row.original.date)) : ''),
        width: 70,
        minWidth: 70,
      },
      {
        Header: 'Activity',
        id: 'timeTrackingActivityId',
        accessor: (record: IHoursRecord) =>
          timeTrackingActivities.find((t) => t.timeTrackingActivityId === record.timeTrackingActivityId)?.name,
        width: 150,
        minWidth: 150,
      },
      myUserContext.isSupportWorker
        ? {
            Header: 'Details',
            id: 'details',
            accessor: 'details',
            width: 400,
            minWidth: 400,
            maxWidth: 400,
          }
        : {
            Header: 'File Information',
            id: 'fileId',
            width: 150,
            minWidth: 150,
            accessor: (record: IHoursRecord) => {
              return record.fileNumber
                ? record.fileNumber + (record.clientName ? ' (' + record.clientName + ')' : '')
                : '';
            },
          },
      {
        Header: 'Hours',
        id: 'hours',
        accessor: (cell: IHoursRecord) => {
          return Number(cell.hours.toFixed(2));
        },
        width: 50,
        minWidth: 50,
      },
      {
        Header: 'Hours Daily Total',
        accessor: 'hoursRecordId',
        width: 50,
        minWidth: 50,
        Cell: (cell: any) => {
          if (cell?.row?.original?.date) {
            const total = dailyTotals.get(formatDateForServer(new Date(cell.row.original.date)));
            return <span>{total ? total : ''}</span>;
          } else {
            return <span />;
          }
        },
      },
    ],
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredEntries, dailyTotals]
  );
  const data: IHoursRecord[] = useMemo(() => filteredEntries, [filteredEntries]);

  const LawyerOption = (props: OptionProps<IUser, boolean>) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <div>{lawyerSelectValue(data, domainContext.offices)}</div>
      </components.Option>
    );
  };

  const LawyerSingleValue = (props: SingleValueProps<IUser>) => {
    const { data } = props;
    return <components.SingleValue {...props}>{lawyerSelectValue(data, domainContext.offices)}</components.SingleValue>;
  };

  const selectRow = (row: IHoursRecord) => {
    setEntryToEdit(row);
    setOpenAddTimeModal(true);
  };

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,

    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: TableInstance<IHoursRecord> = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 50,
        sortBy: [
          {
            id: 'date',
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    usePagination
  );

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'}>
        <NavbarBrand className='mr-auto'>
          Time Tracking{' '}
          {(myUserContext.isLawyer || myUserContext.isSupportWorker) && ' for ' + AuthenticationService.getUserName()}
        </NavbarBrand>
        {!myUserContext.isManagement && (
          <Button
            className={'ml-2'}
            color={'secondary'}
            onClick={() => {
              setOpenAddTimeModal(true);
            }}
          >
            <FaPlus className='mr-2' />
            Add Generic Time
          </Button>
        )}
        {!myUserContext.isManagement && !myUserContext.isSupportWorker && (
          <Button
            className={'ml-2'}
            color={'secondary'}
            onClick={() => {
              history.push('/time-tracking-multiple-files');
            }}
          >
            <FaPlus className='mr-2' />
            Add File Time
          </Button>
        )}
      </Navbar>
      <Row className={'justify-content-center'}>
        <Card
          className={
            'm-3 bg-primary-light' + (myUserContext.isSupportWorker || myUserContext.isLawyer ? ' w-50' : ' w-100')
          }
        >
          <CardHeader>
            {(myUserContext.isSuperAdmin || myUserContext.isLegalAssistant || myUserContext.isManagement) &&
              'Lawyer and '}
            Date Selection
          </CardHeader>
          <CardBody>
            <Row>
              {(myUserContext.isSuperAdmin || myUserContext.isLegalAssistant || myUserContext.isManagement) && (
                <Col xs={12} sm={6}>
                  <Label for={'lawyerId'}>Lawyer</Label>
                  <Select
                    name={'lawyerId'}
                    id={'lawyerId'}
                    options={
                      peopleContext.lawyers
                        .filter((e) => e.active)
                        .sort((a: IUser, b: IUser) =>
                          a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase())
                        ) as IUser[]
                    }
                    value={peopleContext.lawyers.find((i: IUser) => i.userId === selectedUser)}
                    onChange={(value: any) => setSelectedUser(value?.userId || '')}
                    getOptionValue={(option: IUser) => option.userId}
                    getOptionLabel={(option: IUser) =>
                      option.lastName.toUpperCase() +
                      ', ' +
                      option.firstName +
                      ' (' +
                      offices.find((o: IOffice) => o.officeId === option.officeId)?.name +
                      ')'
                    }
                    components={{ SingleValue: LawyerSingleValue, Option: LawyerOption }}
                    styles={{
                      singleValue: (base) => ({
                        ...base,
                        position: 'relative',
                        top: 0,
                        transform: 'translateY(0)',
                        height: '100%',
                        padding: '0.25em 0',
                      }),
                    }}
                    isClearable={true}
                    menuPlacement={'auto'}
                    isDisabled={myUserContext.isLawyer}
                  />
                </Col>
              )}
              <Col>
                <Row>
                  <Col>
                    <Label>From Date</Label>
                    <DatePicker
                      className={'form-control date-select flex'}
                      selected={selectedFromDate}
                      onChange={(date) => {
                        if (date) {
                          date.setHours(0, 0, 0);
                          setSelectedFromDate(date);
                        }
                      }}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      shouldCloseOnSelect={true}
                      dateFormat={'EEE, MMM dd, yyyy'}
                    />
                  </Col>
                  <Col>
                    <Label>To Date</Label>
                    <DatePicker
                      className={'form-control date-select flex'}
                      selected={selectedToDate}
                      onChange={(date) => {
                        if (date) {
                          date.setHours(23, 59, 59, 59);
                          setSelectedToDate(date);
                        }
                      }}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      shouldCloseOnSelect={true}
                      dateFormat={'EEE, MMM dd, yyyy'}
                    />
                  </Col>
                </Row>
                <Row className={'ml-2 mt-3'}>
                  <span>
                    Total time in date range: <b>{totalDailyValuesFromMap()}</b> hours
                  </span>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
      <div className={'table-responsive'}>
        <table className={'table table-bordered table-hover'} {...getTableProps()} style={{ tableLayout: 'fixed' }}>
          <thead>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`users-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={`users-table-thead-tr-${index}-${column.id}`}
                    {...column.getHeaderProps({
                      ...column.getSortByToggleProps(),
                      style: {
                        minWidth: column.minWidth,
                        width: column.width,
                        maxWidth: column.maxWidth,
                      },
                    })}
                  >
                    {column.render('Header')}
                    <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
            {page.map((row: any) => {
              prepareRow(row);

              return (
                <tr key={`users-table-tr-${row.id}`} {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        key={`users-table-td-${cell.row.id}-${cell.column.id}`}
                        {...cell.getCellProps()}
                        onClick={() => {
                          if (!myUserContext.isManagement) {
                            selectRow(row.original);
                          }
                        }}
                        style={{ wordWrap: 'break-word' }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizes={[20, 50, 100, 500]}
      />
      <Modal
        isOpen={openAddTimeModal}
        toggle={() => {
          setOpenAddTimeModal(!openAddTimeModal);
          setEntryToEdit(undefined);
        }}
        className='logout-modal'
        style={{ width: '60rem', maxWidth: '60rem' }}
      >
        <ModalHeader>
          {entryToEdit ? 'Update' : 'Add'} Time
          {myUserContext.isSuperAdmin || myUserContext.isLegalAssistant ? (
            entryToEdit && entryToEdit.fileNumber ? (
              <span>
                {' for '}
                <b>{entryToEdit.fileNumber ? entryToEdit.fileNumber : ''}</b>
              </span>
            ) : (
              ''
            )
          ) : (
            ''
          )}
          {myUserContext.isLawyer || myUserContext.isSupportWorker ? (
            entryToEdit && entryToEdit.fileNumber ? (
              <span>
                {' for '}
                <b>{entryToEdit.fileNumber ? entryToEdit.fileNumber : AuthenticationService.getUserName()}</b>
              </span>
            ) : (
              <span>
                {' for '}
                <b>{AuthenticationService.getUserName()}</b>
              </span>
            )
          ) : (
            ''
          )}
        </ModalHeader>
        <ModalBody>
          <RecordNonFileTime
            setOpenTimeRecordedPopup={setOpenAddTimeModal}
            openTimeRecordedPopup={openAddTimeModal}
            newHoursAdded={addNewTimeEntry}
            updateExistingEntry={updateTimeEntry}
            deleteExistingEntry={deleteTimeEntry}
            entryToEdit={entryToEdit}
            setEntryToEdit={setEntryToEdit}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};
