import * as React from 'react';
import { useContext, useState } from 'react';
import { Button, Card, CardBody, CardHeader, FormGroup, Label, ListGroup, ListGroupItem } from 'reactstrap';
import { authAxios } from '../../../services/AxiosService';
import { notifyError, notifySuccess } from '../../../services/ToastService';
import { useHistory, useParams } from 'react-router';
import { ConfirmModal } from '../../ConfirmModal/ConfirmModal';
import DatePicker from 'react-datepicker';
import { MyUserContext } from '../../../contexts/MyUserContext';
import { useCloseFile } from '../../../hooks/useCloseFile';

export const ReturnForConflictActions: React.FC = () => {
  const params = useParams<{ fileId: string }>();
  const history = useHistory();

  const myUserContext = useContext(MyUserContext);

  const [newFileModal, setNewFileModal] = useState(false);
  const [closedDate, setClosedDate] = useState<Date>(new Date());
  const [closeFileModal, setCloseFileModal] = useState(false);

  const createNewFile = () => {
    if (params.fileId) {
      const data = { fileId: params.fileId, closeOldFile: true };
      authAxios.post('/api/files/admin/create-new-file', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('File stage has been updated.');
          authAxios.post('/api/documents/duplicate-file-documents', {
            oldFileId: params.fileId,
            newFileId: response.data.fileId,
          });
          history.push('/files/' + response.data.fileId);
        } else {
          notifyError(response.data.status);
        }
      });
    }
  };

  const closeFile = useCloseFile();

  return (
    <>
      <Card className={'bg-primary-light'}>
        <CardHeader>Application Process</CardHeader>
        <CardBody style={{ padding: 0 }}>
          <ListGroup flush={true}>
            <ListGroupItem className={'bg-primary-light'}>
              <Button
                color='success'
                className='ml-auto'
                onClick={() => {
                  setNewFileModal(true);
                }}
              >
                Create New File
              </Button>
            </ListGroupItem>
            {myUserContext.isSuperAdmin && (
              <ListGroupItem className={'bg-primary-light'}>
                <FormGroup>
                  <Label style={{ fontWeight: 'initial' }}>Closed Date</Label>
                  <DatePicker
                    selected={closedDate}
                    onChange={(date) => {
                      if (date) {
                        setClosedDate(date);
                      }
                    }}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dateFormat='MMM dd, yyyy'
                    dropdownMode='select'
                    className='date-picker rounded mb-0'
                    placeholderText={'Select...'}
                  />
                </FormGroup>
                <Button
                  color='success'
                  onClick={() => {
                    setCloseFileModal(true);
                  }}
                >
                  Close File
                </Button>
              </ListGroupItem>
            )}
          </ListGroup>
        </CardBody>
      </Card>

      <ConfirmModal
        isOpen={newFileModal}
        title={'New File'}
        onConfirm={() => {
          setNewFileModal(false);
          createNewFile();
        }}
        onCancel={() => setNewFileModal(false)}
        confirmButton={'New File'}
      >
        <div>Are you sure you want to create this application?</div>
      </ConfirmModal>
      <ConfirmModal
        isOpen={closeFileModal}
        title={'Close File'}
        onConfirm={() => {
          setCloseFileModal(false);
          closeFile(params.fileId, closedDate);
        }}
        onCancel={() => setCloseFileModal(false)}
        confirmButton={'Close File'}
      >
        <div>Are you sure you want to close this application?</div>
      </ConfirmModal>
    </>
  );
};
