import * as React from 'react';
import { useEffect } from 'react';
import { authAxios } from '../services/AxiosService';
import { IIndividual } from '../components/Individuals/IndividualContext';
import * as DateTimeFormatter from '../formatters/DateTimeFormatter';

interface IIndividualState {
  type: IndividualState;
  individuals: IIndividual[];
}

interface IndividualStateWithDispatch {
  state: IIndividualState;
  dispatch: React.Dispatch<IndividualAction>;
}

export const INDIVIDUAL_STATE_LOADING = 'INDIVIDUAL_STATE_LOADING';
export const INDIVIDUAL_STATE_LOADED = 'INDIVIDUAL_STATE_LOADED';
export const INDIVIDUAL_STATE_RELOADING = 'INDIVIDUAL_STATE_RELOADING';
export type IndividualState = 'INDIVIDUAL_STATE_LOADING' | 'INDIVIDUAL_STATE_LOADED' | 'INDIVIDUAL_STATE_RELOADING';

export const INDIVIDUAL_ACTION_LOADING = { type: 'INDIVIDUAL_ACTION_LOADING' };
export const INDIVIDUAL_ACTION_DO_RELOADED = { type: 'INDIVIDUAL_ACTION_DO_RELOADED' };
export const INDIVIDUAL_ACTION_SET_DATA = { type: 'INDIVIDUAL_ACTION_SET_DATA', data: [] as IIndividual[] };
export const INDIVIDUAL_ACTION_CREATE_INDIVIDUAL = {
  type: 'INDIVIDUAL_ACTION_CREATE_INDIVIDUAL',
  data: {} as IIndividual,
};
export const INDIVIDUAL_ACTION_UPDATE_INDIVIDUAL = {
  type: 'INDIVIDUAL_ACTION_UPDATE_INDIVIDUAL',
  data: {} as IIndividual,
};

export type IndividualAction = {
  type:
    | 'INDIVIDUAL_ACTION_LOADING'
    | 'INDIVIDUAL_ACTION_DO_RELOADED'
    | 'INDIVIDUAL_ACTION_SET_DATA'
    | 'INDIVIDUAL_ACTION_UPDATE_INDIVIDUAL'
    | 'INDIVIDUAL_ACTION_CREATE_INDIVIDUAL';
  data?: any;
};

export const IndividualsContext = React.createContext<IndividualStateWithDispatch>({
  state: { type: 'INDIVIDUAL_STATE_LOADING', individuals: [] as IIndividual[] },
  dispatch: () => ({}),
});

const makeIndividualForContext = (e: IIndividual) => {
  return {
    ...e,
    lastName: e.lastName.toUpperCase(),
    aliasesFormatted: e.aliases.join(' '),
    birthDateFormatted: e.birthDate ? DateTimeFormatter.formatDate(new Date(e.birthDate)) : '',
    formattedName: `${e.lastName.toUpperCase()}, ${e.firstName}`,
    searchString: `${e.lastName}, ${e.firstName} ${e.middleName} ${e.aliases.join(' ')}`.toUpperCase(),
  };
};

const individualsReducer: React.Reducer<IIndividualState, IndividualAction> = (
  state: IIndividualState,
  action: IndividualAction
) => {
  switch (action.type) {
    case INDIVIDUAL_ACTION_LOADING.type:
      return { ...state, type: INDIVIDUAL_STATE_LOADING };
    case INDIVIDUAL_ACTION_DO_RELOADED.type:
      return { ...state, type: INDIVIDUAL_STATE_RELOADING };
    case INDIVIDUAL_ACTION_SET_DATA.type:
      return { ...state, individuals: action.data, type: INDIVIDUAL_STATE_LOADED };
    case INDIVIDUAL_ACTION_UPDATE_INDIVIDUAL.type:
      return {
        ...state,
        individuals: [
          ...state.individuals.filter((e) => e.individualId !== action.data.individualId),
          makeIndividualForContext({
            ...action.data,
            lastUpdated: new Date().getTime(),
            lastUpdatedFormatted: DateTimeFormatter.formatDate(new Date()),
          }),
        ],
      };
    case INDIVIDUAL_ACTION_CREATE_INDIVIDUAL.type:
      return {
        ...state,
        individuals: [
          ...state.individuals,
          makeIndividualForContext({
            ...action.data,
            lastUpdated: new Date().getTime(),
            lastUpdatedFormatted: DateTimeFormatter.formatDate(new Date()),
          }),
        ],
      };
    default:
      return state;
  }
};

export const IndividualsProvider: React.FC = (props) => {
  const [state, dispatch] = React.useReducer(individualsReducer, { type: INDIVIDUAL_STATE_LOADING, individuals: [] });

  useEffect(() => {
    if (state.type === INDIVIDUAL_STATE_LOADING || state.type === INDIVIDUAL_STATE_RELOADING) {
      console.log('DOING CALL');
      authAxios.get('/api/individuals').then((response) => {
        const individuals: IIndividual[] = response.data.map((e: any) => makeIndividualForContext(e));
        dispatch({ type: 'INDIVIDUAL_ACTION_SET_DATA', data: individuals });
      });
    }
  }, [state.type]);

  const value: IndividualStateWithDispatch = { state, dispatch };
  return <IndividualsContext.Provider value={value}>{props.children}</IndividualsContext.Provider>;
};
