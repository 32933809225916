import * as React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Navbar, NavbarBrand, Row } from 'reactstrap';
import { FaPrint } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import { useContext, useEffect, useState } from 'react';
import { ReportContext } from './ReportContext';
import { authAxios } from '../../services/AxiosService';
import {
  DomainContext,
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_NONE,
  GENDER_OTHER,
  GENERIC_TIME_TYPE_DC_BAIL,
  GENERIC_TIME_TYPE_DC_CHAMBERS,
  GENERIC_TIME_TYPE_DC_CHILD_PROTECTION,
  GENERIC_TIME_TYPE_DC_CIRCUIT,
  GENERIC_TIME_TYPE_DC_CWC,
  GENERIC_TIME_TYPE_DC_DOCKET,
  GENERIC_TIME_TYPE_DC_DVTO,
  GENERIC_TIME_TYPE_DC_GENERAL_PREP,
  GENERIC_TIME_TYPE_DC_TRAVEL,
  GENERIC_TIME_TYPE_DC_YOUTH,
  ICircuitLocation,
} from '../../contexts/DomainContext';
import { formatDate } from '../../formatters/DateTimeFormatter';
import { Link } from 'react-router-dom';
import { MyUserContext } from '../../contexts/MyUserContext';

interface IGenderData {
  genderId: string;
  seventeenAndUnder: number;
  eighteenAndAbove: number;
}

interface IPeopleAssistedLocation {
  circuitLocationId: string;
  genders: IGenderData[];
}

interface INumAppearanceCourtType {
  courtTypeId: string;
  genderData: IGenderData[];
}

interface INumAppearancesLocation {
  circuitLocationId: string;
  courtTypes: INumAppearanceCourtType[];
}

interface IStaffLawyerHour {
  timeTrackingActivityId: string;
  total: number;
}

interface INotIncludedFile {
  fileId: string;
  fileNumberFormatted: string;
}

interface IDutyCounselReportData {
  peopleAssisted: IPeopleAssistedLocation[];
  numAppearances: INumAppearancesLocation[];
  staffLawyerHours: IStaffLawyerHour[];
  numInCustodyCallsLawyer: number;
  notIncluded: INotIncludedFile[];
}

export const DutyCounsel = () => {
  const reportContext = useContext(ReportContext);
  const domainContext = useContext(DomainContext);
  const myUserContext = useContext(MyUserContext);
  const fromDate = reportContext.fromDate;
  const setFromDate = reportContext.setFromDate;
  const toDate = reportContext.toDate;
  const setToDate = reportContext.setToDate;
  const circuitLocations = domainContext.circuitLocations.sort((a: ICircuitLocation, b: ICircuitLocation) =>
    b.name.toUpperCase().localeCompare(a.name.toUpperCase())
  );
  const dutyCounselCourtTypes = domainContext.dutyCounselCourtTypes;
  const genders = [GENDER_MALE, GENDER_FEMALE, GENDER_OTHER, GENDER_NONE];
  const genericDutyCounselTimeTypes = [
    GENERIC_TIME_TYPE_DC_BAIL,
    GENERIC_TIME_TYPE_DC_CHAMBERS,
    GENERIC_TIME_TYPE_DC_CHILD_PROTECTION,
    GENERIC_TIME_TYPE_DC_CWC,
    GENERIC_TIME_TYPE_DC_DOCKET,
    GENERIC_TIME_TYPE_DC_DVTO,
    GENERIC_TIME_TYPE_DC_GENERAL_PREP,
    GENERIC_TIME_TYPE_DC_YOUTH,
    GENERIC_TIME_TYPE_DC_CIRCUIT,
    GENERIC_TIME_TYPE_DC_TRAVEL,
  ];
  const timeTypes = domainContext.timeTrackingActivities.filter((tt) =>
    genericDutyCounselTimeTypes.includes(tt.timeTrackingActivityId)
  );
  const [dutyCounselReportData, setDutyCounselReportData] = useState<IDutyCounselReportData>();

  useEffect(() => {
    if (fromDate && toDate) {
      authAxios
        .get(`/api/reports/duty-counsel-report?fromDate=${fromDate.getTime()}&toDate=${toDate.getTime()}`)
        .then((response) => {
          setDutyCounselReportData(response.data);
        });
    }
  }, [fromDate, toDate]);

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
        <Row className={'flex-fill'}>
          <Col md={3} xl={2}>
            <NavbarBrand>Duty Counsel</NavbarBrand>
          </Col>
          <Col className={'d-flex'}>
            <Button className={'ml-auto'} color={'warning'} onClick={() => window.print()}>
              Print <FaPrint />
            </Button>
          </Col>
        </Row>
      </Navbar>
      <Container className={'container--no-margin-print'}>
        <Row className={'my-5 '}>
          <Col>
            <Card className={'no-print'}>
              <CardHeader>Select Date Range</CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Label className={'flex second-label'} style={{ paddingRight: '10px' }}>
                      From
                    </Label>
                    <DatePicker
                      className={'form-control date-select flex'}
                      selected={fromDate}
                      onChange={(date) => {
                        if (date) {
                          setFromDate(date);
                        }
                      }}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      shouldCloseOnSelect={true}
                      dateFormat={'yyyy-MM-dd'}
                    />
                  </Col>
                  <Col>
                    <Label
                      className={'flex second-label'}
                      for={'EndDate'}
                      style={{ paddingRight: '10px', paddingLeft: '10px' }}
                    >
                      To
                    </Label>
                    <DatePicker
                      className={'form-control date-select flex'}
                      selected={toDate}
                      onChange={(date) => {
                        if (date) {
                          setToDate(date);
                        }
                      }}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      shouldCloseOnSelect={true}
                      dateFormat={'yyyy-MM-dd'}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col className={'no-print'}>
            <p className={'text-muted'}>
              <b>People Assisted</b> counts the number of files in any stage with all of the following:
              <ul>
                <li>an application date in the date range</li>
                <li>at least 1 Duty Counsel Appearance</li>
              </ul>
              <p className={'mb-0'}>
                * Files with a client with no birthdate and have the application type Youth Criminal will fall into the
                17 & Under column. All other application types will fall into the 18 & Over Column.
              </p>
              <p>* Files with a client with no gender will show under the Missing Gender category.</p>
            </p>
            <p className={'text-muted'}>
              <b>Number of Appearances</b> counts the number of Duty Counsel Appearances with all of the following:
              <ul className={'mb-1'}>
                <li>an appearance date in the date range</li>
                <li>on a file with a client with a birthdate & gender</li>
              </ul>
              <p className={'mb-0'}>* All appearances with type Bail will have a Whitehorse location</p>
              <p className={'mb-0'}>
                * Appearances on files with a client with no birthdate and have the application type Youth Criminal will
                fall into the 17 & Under column. All other application types will fall into the 18 & Over Column.
              </p>
              <p>* Appearances on files with a client with no gender will show under the Missing Gender category.</p>
            </p>
            <p className={'text-muted'}>
              <b>Staff Lawyer hours</b> sums the non-file lawyer time tracking hours with all of the following:
              <ul>
                <li>an entry date in the date range</li>
                <li>
                  an activity of either <b>D/C - Bail</b>, <b>D/C - Chambers</b>, <b>D/C - Child Protection</b>,{' '}
                  <b>D/C - CWC</b>, <b>D/C - Docket</b>, <b>D/C - DVTO</b>, <b>D/C - General Preparation</b>,{' '}
                  <b>D/C - Youth</b>, <b>D/C - Circuit</b>, or <b>Travel - Circuit</b>
                </li>
              </ul>
            </p>
            <p className={'text-muted'}>
              <b>Staff Lawyer In-Custody Calls</b> sums the number of non-file lawyer time tracking entries with all of
              the following:
              <ul>
                <li>an entry date in the date range</li>
                <li>
                  an activity of <b>In-Custody Call</b>
                </li>
              </ul>
            </p>
          </Col>
        </Row>
        <div className={'report my-5 mx-3'}>
          <header className={'report-header'}>
            <h2 className={'text-center'}>Duty Counsel Report</h2>
            <h3 className={'text-center h5 text-muted'}>
              {'Between ' + formatDate(fromDate) + ' and ' + formatDate(toDate)}
            </h3>
          </header>
          <table className={'report1'}>
            <thead>
              <tr>
                <th className={'headerspan'} />
                <th className={'headerspan'} />
                <th className={'headerspan'} />
                <th className={'header'} colSpan={2}>
                  Male
                </th>
                <th className={'header'} colSpan={2}>
                  Female
                </th>
                <th className={'header'} colSpan={2}>
                  Other
                </th>
                <th className={'header'} colSpan={2}>
                  Missing Gender
                </th>
                <th className={'header'} />
              </tr>
              <tr>
                <th className={'headerspan'} />
                <th className={'headerspan'} />
                <th className={'headerspan'} />
                <th className={'headerspan-sticky'}>17 & Under</th>
                <th className={'headerspan-sticky'}>18 & Over</th>
                <th className={'headerspan-sticky'}>17 & Under</th>
                <th className={'headerspan-sticky'}>18 & Over</th>
                <th className={'headerspan-sticky'}>17 & Under</th>
                <th className={'headerspan-sticky'}>18 & Over</th>
                <th className={'headerspan-sticky'}>17 & Under</th>
                <th className={'headerspan-sticky'}>18 & Over</th>
                <th className={'headerspan-sticky'}>Total</th>
              </tr>
            </thead>
            <tbody>
              {circuitLocations.map((cl, index) => {
                return (
                  <tr key={cl.circuitLocationId}>
                    {index === 0 && (
                      <th className={'headerspan'} rowSpan={circuitLocations.length + 2} colSpan={2}>
                        Number of People Assisted (# of files)
                      </th>
                    )}
                    <th className={'header-side'}>{cl.name}</th>
                    {genders.map((g) => (
                      <>
                        <td className={'report-row'}>
                          {dutyCounselReportData
                            ? dutyCounselReportData.peopleAssisted
                                .find((p) => p.circuitLocationId === cl.circuitLocationId)
                                ?.genders.find((pa) => pa.genderId === g)?.seventeenAndUnder
                            : 0}
                        </td>
                        <td className={'report-row'}>
                          {dutyCounselReportData
                            ? dutyCounselReportData.peopleAssisted
                                .find((p) => p.circuitLocationId === cl.circuitLocationId)
                                ?.genders.find((pa) => pa.genderId === g)?.eighteenAndAbove
                            : 0}
                        </td>
                      </>
                    ))}
                    <td className={'total'}>
                      <b>
                        {dutyCounselReportData
                          ? domainContext.genders
                              .map((g) => {
                                const genderTotals = dutyCounselReportData.peopleAssisted
                                  .find((p) => p.circuitLocationId === cl.circuitLocationId)
                                  ?.genders.find((pa) => pa.genderId === g.genderId);
                                return genderTotals
                                  ? genderTotals.seventeenAndUnder + genderTotals.eighteenAndAbove
                                  : 0;
                              })
                              .reduce((acc, total) => {
                                return acc + total;
                              }, 0)
                          : 0}
                      </b>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <th className={'header-side'}>Undefined Location</th>
                {genders.map((g) => (
                  <>
                    <td className={'report-row'}>
                      {dutyCounselReportData
                        ? dutyCounselReportData.peopleAssisted
                            .find((p) => p.circuitLocationId === 'UNDEFINED')
                            ?.genders.find((pa) => pa.genderId === g)?.seventeenAndUnder
                        : 0}
                    </td>
                    <td className={'report-row'}>
                      {dutyCounselReportData
                        ? dutyCounselReportData.peopleAssisted
                            .find((p) => p.circuitLocationId === 'UNDEFINED')
                            ?.genders.find((pa) => pa.genderId === g)?.eighteenAndAbove
                        : 0}
                    </td>
                  </>
                ))}
                <td className={'total'}>
                  <b>
                    {dutyCounselReportData
                      ? domainContext.genders
                          .map((g) => {
                            const genderTotals = dutyCounselReportData.peopleAssisted
                              .find((p) => p.circuitLocationId === 'UNDEFINED')
                              ?.genders.find((pa) => pa.genderId === g.genderId);
                            return genderTotals ? genderTotals.seventeenAndUnder + genderTotals.eighteenAndAbove : 0;
                          })
                          .reduce((acc, total) => {
                            return acc + total;
                          }, 0)
                      : 0}
                  </b>
                </td>
              </tr>
              <tr>
                <th className={'header-side'}>Total</th>
                {genders.map((g) => (
                  <>
                    <td className={'total'}>
                      <b>
                        {dutyCounselReportData
                          ? domainContext.circuitLocations
                              .map((cl) => {
                                return dutyCounselReportData.peopleAssisted
                                  .find((p) => p.circuitLocationId === cl.circuitLocationId)
                                  ?.genders.find((pa) => pa.genderId === g)?.seventeenAndUnder;
                              })
                              .concat(
                                dutyCounselReportData.peopleAssisted
                                  .find((p) => p.circuitLocationId === 'UNDEFINED')
                                  ?.genders.find((pa) => pa.genderId === g)?.seventeenAndUnder
                              )
                              .reduce((acc, total) => {
                                return (acc ? acc : 0) + (total ? total : 0);
                              }, 0)
                          : 0}
                      </b>
                    </td>
                    <td className={'total'}>
                      <b>
                        {dutyCounselReportData
                          ? domainContext.circuitLocations
                              .map((cl) => {
                                return dutyCounselReportData.peopleAssisted
                                  .find((p) => p.circuitLocationId === cl.circuitLocationId)
                                  ?.genders.find((pa) => pa.genderId === g)?.eighteenAndAbove;
                              })
                              .concat(
                                dutyCounselReportData.peopleAssisted
                                  .find((p) => p.circuitLocationId === 'UNDEFINED')
                                  ?.genders.find((pa) => pa.genderId === g)?.eighteenAndAbove
                              )
                              .reduce((acc, total) => {
                                return (acc ? acc : 0) + (total ? total : 0);
                              }, 0)
                          : 0}
                      </b>
                    </td>
                  </>
                ))}
                <td className={'total'} />
              </tr>
              {circuitLocations
                .map((cl, index) =>
                  dutyCounselCourtTypes
                    .map((ct, innerIndex) => (
                      <tr key={ct.dutyCounselCourtTypeId}>
                        {innerIndex === 0 && (
                          <th className={'headerspan'} rowSpan={dutyCounselCourtTypes.length + 2}>
                            Number of Appearances (# of court entries)
                          </th>
                        )}
                        {innerIndex === 0 && (
                          <th className={'header-side'} rowSpan={dutyCounselCourtTypes.length + 2}>
                            {cl.name}
                          </th>
                        )}
                        <th className={'header-side'}>{ct.name}</th>
                        {genders.map((g) => (
                          <>
                            <td className={'report-row'}>
                              {dutyCounselReportData
                                ? dutyCounselReportData.numAppearances
                                    .find((p) => p.circuitLocationId === cl.circuitLocationId)
                                    ?.courtTypes.find((ctData) => ctData.courtTypeId === ct.dutyCounselCourtTypeId)
                                    ?.genderData.find((gData) => gData.genderId === g)?.seventeenAndUnder
                                : 0}
                            </td>
                            <td className={'report-row'}>
                              {dutyCounselReportData
                                ? dutyCounselReportData.numAppearances
                                    .find((p) => p.circuitLocationId === cl.circuitLocationId)
                                    ?.courtTypes.find((ctData) => ctData.courtTypeId === ct.dutyCounselCourtTypeId)
                                    ?.genderData.find((gData) => gData.genderId === g)?.eighteenAndAbove
                                : 0}
                            </td>
                          </>
                        ))}
                        <td className={'total'}>
                          <b>
                            {dutyCounselReportData
                              ? genders
                                  .map((g) => {
                                    const genderTotals = dutyCounselReportData.numAppearances
                                      .find((p) => p.circuitLocationId === cl.circuitLocationId)
                                      ?.courtTypes.find((ctData) => ctData.courtTypeId === ct.dutyCounselCourtTypeId)
                                      ?.genderData.find((gData) => gData.genderId === g);
                                    return genderTotals
                                      ? genderTotals.seventeenAndUnder + genderTotals.eighteenAndAbove
                                      : 0;
                                  })
                                  .reduce((acc, total) => {
                                    return acc + total;
                                  }, 0)
                              : 0}
                          </b>
                        </td>
                      </tr>
                    ))
                    .concat(
                      <tr>
                        <th className={'header-side'}>Undefined Court Type</th>
                        {genders.map((g) => (
                          <>
                            <td className={'report-row'}>
                              {dutyCounselReportData
                                ? dutyCounselReportData.numAppearances
                                    .find((p) => p.circuitLocationId === cl.circuitLocationId)
                                    ?.courtTypes.find((ctData) => ctData.courtTypeId === 'UNDEFINED')
                                    ?.genderData.find((gData) => gData.genderId === g)?.seventeenAndUnder
                                : 0}
                            </td>
                            <td className={'report-row'}>
                              {dutyCounselReportData
                                ? dutyCounselReportData.numAppearances
                                    .find((p) => p.circuitLocationId === cl.circuitLocationId)
                                    ?.courtTypes.find((ctData) => ctData.courtTypeId === 'UNDEFINED')
                                    ?.genderData.find((gData) => gData.genderId === g)?.eighteenAndAbove
                                : 0}
                            </td>
                          </>
                        ))}
                        <td className={'total'}>
                          <b>
                            {dutyCounselReportData
                              ? genders
                                  .map((g) => {
                                    const genderTotals = dutyCounselReportData.numAppearances
                                      .find((p) => p.circuitLocationId === cl.circuitLocationId)
                                      ?.courtTypes.find((ctData) => ctData.courtTypeId === 'UNDEFINED')
                                      ?.genderData.find((gData) => gData.genderId === g);
                                    return genderTotals
                                      ? genderTotals.seventeenAndUnder + genderTotals.eighteenAndAbove
                                      : 0;
                                  })
                                  .reduce((acc, total) => {
                                    return acc + total;
                                  }, 0)
                              : 0}
                          </b>
                        </td>
                      </tr>
                    )
                    .concat(
                      <tr>
                        <th className={'header-side'}>Total</th>
                        {genders.map((g) => (
                          <>
                            <td className={'total'}>
                              <b>
                                {dutyCounselReportData
                                  ? dutyCounselReportData.numAppearances
                                      .find((p) => p.circuitLocationId === cl.circuitLocationId)
                                      ?.courtTypes.map((ct) => {
                                        const genderData = ct.genderData.find((gd) => gd.genderId === g);
                                        return genderData ? genderData.seventeenAndUnder : 0;
                                      })
                                      .reduce((acc, total) => {
                                        return acc + total;
                                      }, 0)
                                  : 0}
                              </b>
                            </td>
                            <td className={'total'}>
                              <b>
                                {dutyCounselReportData
                                  ? dutyCounselReportData.numAppearances
                                      .find((p) => p.circuitLocationId === cl.circuitLocationId)
                                      ?.courtTypes.map((ct) => {
                                        const genderData = ct.genderData.find((gd) => gd.genderId === g);
                                        return genderData ? genderData.eighteenAndAbove : 0;
                                      })
                                      .reduce((acc, total) => {
                                        return acc + total;
                                      }, 0)
                                  : 0}
                              </b>
                            </td>
                          </>
                        ))}
                        <td className={'total'} />
                      </tr>
                    )
                )
                .concat(
                  dutyCounselCourtTypes
                    .map((ct, innerIndex) => (
                      <tr key={ct.dutyCounselCourtTypeId}>
                        {innerIndex === 0 && (
                          <th className={'headerspan'} rowSpan={dutyCounselCourtTypes.length + 2}>
                            Number of Appearances (# of court entries)
                          </th>
                        )}
                        {innerIndex === 0 && (
                          <th className={'header-side'} rowSpan={dutyCounselCourtTypes.length + 2}>
                            Undefined Location
                          </th>
                        )}
                        <th className={'header-side'}>{ct.name}</th>
                        {genders.map((g) => (
                          <>
                            <td className={'report-row'}>
                              {dutyCounselReportData
                                ? dutyCounselReportData.numAppearances
                                    .find((p) => p.circuitLocationId === 'UNDEFINED')
                                    ?.courtTypes.find((ctData) => ctData.courtTypeId === ct.dutyCounselCourtTypeId)
                                    ?.genderData.find((gData) => gData.genderId === g)?.seventeenAndUnder
                                : 0}
                            </td>
                            <td className={'report-row'}>
                              {dutyCounselReportData
                                ? dutyCounselReportData.numAppearances
                                    .find((p) => p.circuitLocationId === 'UNDEFINED')
                                    ?.courtTypes.find((ctData) => ctData.courtTypeId === ct.dutyCounselCourtTypeId)
                                    ?.genderData.find((gData) => gData.genderId === g)?.eighteenAndAbove
                                : 0}
                            </td>
                          </>
                        ))}
                        <td className={'total'}>
                          <b>
                            {dutyCounselReportData
                              ? genders
                                  .map((g) => {
                                    const genderTotals = dutyCounselReportData.numAppearances
                                      .find((p) => p.circuitLocationId === 'UNDEFINED')
                                      ?.courtTypes.find((ctData) => ctData.courtTypeId === ct.dutyCounselCourtTypeId)
                                      ?.genderData.find((gData) => gData.genderId === g);
                                    return genderTotals
                                      ? genderTotals.seventeenAndUnder + genderTotals.eighteenAndAbove
                                      : 0;
                                  })
                                  .reduce((acc, total) => {
                                    return acc + total;
                                  }, 0)
                              : 0}
                          </b>
                        </td>
                      </tr>
                    ))
                    .concat(
                      <tr>
                        <th className={'header-side'}>Undefined Court Type</th>
                        {genders.map((g) => (
                          <>
                            <td className={'report-row'}>
                              {dutyCounselReportData
                                ? dutyCounselReportData.numAppearances
                                    .find((p) => p.circuitLocationId === 'UNDEFINED')
                                    ?.courtTypes.find((ctData) => ctData.courtTypeId === 'UNDEFINED')
                                    ?.genderData.find((gData) => gData.genderId === g)?.seventeenAndUnder
                                : 0}
                            </td>
                            <td className={'report-row'}>
                              {dutyCounselReportData
                                ? dutyCounselReportData.numAppearances
                                    .find((p) => p.circuitLocationId === 'UNDEFINED')
                                    ?.courtTypes.find((ctData) => ctData.courtTypeId === 'UNDEFINED')
                                    ?.genderData.find((gData) => gData.genderId === g)?.eighteenAndAbove
                                : 0}
                            </td>
                          </>
                        ))}
                        <td className={'total'}>
                          <b>
                            {dutyCounselReportData
                              ? genders
                                  .map((g) => {
                                    const genderTotals = dutyCounselReportData.numAppearances
                                      .find((p) => p.circuitLocationId === 'UNDEFINED')
                                      ?.courtTypes.find((ctData) => ctData.courtTypeId === 'UNDEFINED')
                                      ?.genderData.find((gData) => gData.genderId === g);
                                    return genderTotals
                                      ? genderTotals.seventeenAndUnder + genderTotals.eighteenAndAbove
                                      : 0;
                                  })
                                  .reduce((acc, total) => {
                                    return acc + total;
                                  }, 0)
                              : 0}
                          </b>
                        </td>
                      </tr>
                    )
                    .concat(
                      <tr>
                        <th className={'header-side'}>Total</th>
                        {genders.map((g) => (
                          <>
                            <td className={'total'}>
                              <b>
                                {dutyCounselReportData
                                  ? dutyCounselReportData.numAppearances
                                      .find((p) => p.circuitLocationId === 'UNDEFINED')
                                      ?.courtTypes.map((ct) => {
                                        const genderData = ct.genderData.find((gd) => gd.genderId === g);
                                        return genderData ? genderData.seventeenAndUnder : 0;
                                      })
                                      .reduce((acc, total) => {
                                        return acc + total;
                                      }, 0)
                                  : 0}
                              </b>
                            </td>
                            <td className={'total'}>
                              <b>
                                {dutyCounselReportData
                                  ? dutyCounselReportData.numAppearances
                                      .find((p) => p.circuitLocationId === 'UNDEFINED')
                                      ?.courtTypes.map((ct) => {
                                        const genderData = ct.genderData.find((gd) => gd.genderId === g);
                                        return genderData ? genderData.eighteenAndAbove : 0;
                                      })
                                      .reduce((acc, total) => {
                                        return acc + total;
                                      }, 0)
                                  : 0}
                              </b>
                            </td>
                          </>
                        ))}
                        <td className={'total'} />
                      </tr>
                    )
                )}
              <tr>
                <th className={'header-side'} colSpan={3}>
                  Total Number of Appearances
                </th>
                {genders.map((g) => (
                  <>
                    <td className={'total'}>
                      <b>
                        {dutyCounselReportData
                          ? dutyCounselReportData.numAppearances
                              .map((p) => {
                                return p.courtTypes.map((ct) => {
                                  const genderData = ct.genderData.find((gd) => gd.genderId === g);
                                  return genderData ? genderData.seventeenAndUnder : 0;
                                });
                              })
                              .flat()
                              .reduce((acc, total) => {
                                return acc + total;
                              }, 0)
                          : 0}
                      </b>
                    </td>
                    <td className={'total'}>
                      <b>
                        {dutyCounselReportData
                          ? dutyCounselReportData.numAppearances
                              .map((p) => {
                                return p.courtTypes.map((ct) => {
                                  const genderData = ct.genderData.find((gd) => gd.genderId === g);
                                  return genderData ? genderData.eighteenAndAbove : 0;
                                });
                              })
                              .flat()
                              .reduce((acc, total) => {
                                return acc + total;
                              }, 0)
                          : 0}
                      </b>
                    </td>
                  </>
                ))}
                <td className={'total'} />
              </tr>
            </tbody>
          </table>
          <table className={'report1 mt-5'}>
            <thead>
              <tr>
                <th className={'headerspan'} />
                <th className={'headerspan'}>Time Entry Type</th>
                <th className={'headerspan'}>Total</th>
              </tr>
            </thead>
            <tbody>
              {timeTypes
                .map((tt, index) => {
                  return (
                    <tr key={tt.timeTrackingActivityId}>
                      {index === 0 && (
                        <th className={'headerspan'} rowSpan={timeTypes.length + 1}>
                          Staff Lawyer Hours (sum of hours)
                        </th>
                      )}
                      <td className={'total'}>{tt.name}</td>
                      <td className={'report-row'}>
                        {dutyCounselReportData
                          ? dutyCounselReportData.staffLawyerHours.find(
                              (lh) => lh.timeTrackingActivityId === tt.timeTrackingActivityId
                            )?.total
                          : 0}
                      </td>
                    </tr>
                  );
                })
                .concat(
                  <tr>
                    <td className={'total'}>Total</td>
                    <td className={'total'}>
                      <b>
                        {dutyCounselReportData
                          ? dutyCounselReportData.staffLawyerHours
                              .map((slh) => slh.total)
                              .reduce((acc, total) => acc + total, 0)
                              .toFixed(2)
                          : 0}
                      </b>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
          <table className={'report1 mt-5'}>
            <tbody>
              <tr>
                <th className={'headerspan'} style={{ width: '50%' }}>
                  Number of In Custody Calls Staff Lawyer (# of time entries)
                </th>
                <td className={'report-row'} style={{ width: '50%' }}>
                  {dutyCounselReportData ? dutyCounselReportData.numInCustodyCallsLawyer : 0}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={'px-5 pb-5 no-print'}>
          <p>Files missing gender and/or age: </p>
          <ul>
            {dutyCounselReportData?.notIncluded.map((f) => (
              <li key={f.fileId}>
                {myUserContext.isReportingOnly ? (
                  <div>{f.fileNumberFormatted}</div>
                ) : (
                  <Link to={'/files/' + f.fileId}>{f.fileNumberFormatted}</Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </div>
  );
};
