export const isValidPassword = (password: string) => {
  const passwordArray = password.split('');
  const aChar = /[a-zA-Z]/;
  const aNumber = /[0-9]/;
  const obj = {
    result: true,
    error: '',
  };

  if (passwordArray.length < 8) {
    obj.result = false;
    obj.error = 'Not long enough!';
    return obj.result;
  }

  let numChar = 0;
  let numNums = 0;

  for (const i of passwordArray) {
    if (aChar.test(i)) {
      numChar++;
    } else if (aNumber.test(i)) {
      numNums++;
    }
  }

  if (numChar < 1 || numNums < 1) {
    obj.result = false;
    obj.error = 'Wrong Format!';
    return obj.result;
  }
  return obj.result;
};
