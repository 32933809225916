import * as React from 'react';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  Col,
  CustomInput,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import { IOffence } from '../Offences/OffenceTable';
import { authAxios } from '../../services/AxiosService';
import { FileContext, ICharge } from './FileContext';
import { FaTimes } from 'react-icons/all';
import {
  CHARGE_OUTCOME_SOP_ACQUITTAL,
  DomainContext,
  editableStages,
  STAGE_ASSIGNED_TO_LAWYER,
  STAGE_CLOSED,
  STAGE_PENDING,
} from '../../contexts/DomainContext';
import { MyUserContext } from '../../contexts/MyUserContext';

interface IProps {
  trySubmit: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  applicationTypeId: string;
  isEditingAllowed: boolean;
}

interface IPleaType {
  pleaTypeId: string;
  name: string;
}

interface IOutcomeType {
  outcomeTypeId: string;
  name: string;
}

interface IElectionType {
  electionTypeId: string;
  name: string;
}

export const FileCharges = (props: IProps) => {
  const fileContext = useContext(FileContext);
  const domainContext = useContext(DomainContext);
  const offences = domainContext.offences;
  const file = fileContext.file;
  const fileCharges = fileContext.charges;
  const setFileCharges = fileContext.setCharges;
  const myUserContext = useContext(MyUserContext);
  const [showForm, setShowForm] = useState(file.stageId === undefined || file.stageId === STAGE_PENDING);
  const [pleaTypes, setPleaTypes] = useState<IPleaType[]>([]);
  const [outcomeTypes, setOutcomeTypes] = useState<IOutcomeType[]>([]);
  const [electionTypes, setElectionTypes] = useState<IElectionType[]>([]);

  useEffect(() => {
    authAxios.get('/api/offences/plea-types').then((response) => {
      setPleaTypes(response.data);
    });
    authAxios.get('/api/offences/outcome-types').then((response) => {
      setOutcomeTypes(response.data);
    });
    authAxios.get('/api/offences/election-types').then((response) => {
      setElectionTypes(response.data);
    });
  }, []);

  useEffect(() => {
    setShowForm(file.stageId === undefined || file.stageId === STAGE_PENDING);
  }, [file.stageId]);

  const isEditingAllowedOnlySuperAdminIntake = () => {
    return (
      props.isEditingAllowed &&
      ((!myUserContext.isLegalAssistant && !myUserContext.isLawyer) ||
        !file.stageId ||
        editableStages.includes(file?.stageId))
    );
  };

  const disabledSelectStyles = {
    control: (styles: any, state: any) => {
      return {
        ...styles,
        borderColor: '#ced4da',
      };
    },
    singleValue: (styles: any, state: any) => {
      return {
        ...styles,
        color: '#495057',
      };
    },
  };

  const groupedOptions = () => {
    const activeOffences = offences.filter((o) => o.active);
    const adultOffences = activeOffences.filter((o) => o.applicationTypeId === '5ed8bcb6-87e3-42d6-9aea-3ffa3939391a');
    const youthOffences = activeOffences.filter((o) => o.applicationTypeId === '2a4a2269-40a4-4718-9884-da2d51c0d5ab');
    const otherOffences = activeOffences.filter(
      (o) =>
        o.applicationTypeId !== '5ed8bcb6-87e3-42d6-9aea-3ffa3939391a' &&
        o.applicationTypeId !== '2a4a2269-40a4-4718-9884-da2d51c0d5ab'
    );

    const groupArray = [];

    const adultGroup1 = adultOffences.filter((o) => o.group === 1);
    const adultGroup2 = adultOffences.filter((o) => o.group === 2);
    const adultGroup3 = adultOffences.filter((o) => o.group === 3);
    const adultGroup4 = adultOffences.filter((o) => o.group === 4);
    const adultGroup5 = adultOffences.filter((o) => o.group === 5);

    if (adultGroup1.length > 0) {
      groupArray.push({
        label: 'Adult Group 1',
        options: adultGroup1.sort((a, b) => a.number.localeCompare(b.number)),
      });
    }
    if (adultGroup2.length > 0) {
      groupArray.push({
        label: 'Adult Group 2',
        options: adultGroup2.sort((a, b) => a.number.localeCompare(b.number)),
      });
    }
    if (adultGroup3.length > 0) {
      groupArray.push({
        label: 'Adult Group 3',
        options: adultGroup3.sort((a, b) => a.number.localeCompare(b.number)),
      });
    }
    if (adultGroup4.length > 0) {
      groupArray.push({
        label: 'Adult Group 4',
        options: adultGroup4.sort((a, b) => a.number.localeCompare(b.number)),
      });
    }
    if (adultGroup5.length > 0) {
      groupArray.push({
        label: 'Adult Group 5',
        options: adultGroup5.sort((a, b) => a.number.localeCompare(b.number)),
      });
    }

    const youthGroup1 = youthOffences.filter((o) => o.group === 1);
    const youthGroup2 = youthOffences.filter((o) => o.group === 2);
    const youthGroup3 = youthOffences.filter((o) => o.group === 3);
    const youthGroup4 = youthOffences.filter((o) => o.group === 4);
    const youthGroup5 = youthOffences.filter((o) => o.group === 5);

    if (youthGroup1.length > 0) {
      groupArray.push({
        label: 'Youth Group 1',
        options: youthGroup1.sort((a, b) => a.number.localeCompare(b.number)),
      });
    }
    if (youthGroup2.length > 0) {
      groupArray.push({
        label: 'Youth Group 2',
        options: youthGroup2.sort((a, b) => a.number.localeCompare(b.number)),
      });
    }
    if (youthGroup3.length > 0) {
      groupArray.push({
        label: 'Youth Group 3',
        options: youthGroup3.sort((a, b) => a.number.localeCompare(b.number)),
      });
    }
    if (youthGroup4.length > 0) {
      groupArray.push({
        label: 'Youth Group 4',
        options: youthGroup4.sort((a, b) => a.number.localeCompare(b.number)),
      });
    }
    if (youthGroup5.length > 0) {
      groupArray.push({
        label: 'Youth Group 5',
        options: youthGroup5.sort((a, b) => a.number.localeCompare(b.number)),
      });
    }

    domainContext.applicationTypes
      .filter(
        (at) =>
          at.applicationTypeId !== '5ed8bcb6-87e3-42d6-9aea-3ffa3939391a' &&
          at.applicationTypeId !== '2a4a2269-40a4-4718-9884-da2d51c0d5ab'
      )
      .forEach((at) => {
        const atOffences = otherOffences.filter((e) => e.applicationTypeId === at.applicationTypeId);
        groupArray.push({ label: at.name, options: atOffences.sort((a, b) => a.number.localeCompare(b.number)) });
      });

    return groupArray;
  };

  const addCharge = (newCharge: string) => {
    if (newCharge) {
      setFileCharges((c) => [
        {
          offenceId: newCharge,
          pleaTypeId: undefined,
          outcomes: [],
          breachNumber: '',
          electionTypeId: undefined,
        } as ICharge,
        ...(c || []),
      ]);
      props.setIsDirty(true);
    }
  };

  const cancelAddingNewCharge = () => {
    setShowForm(false);
  };

  const listOfSelectedGroups = () => {
    const offencesSelected = fileCharges
      ? fileCharges.map((c) => offences.find((o) => o.offenceId === c.offenceId))
      : [];
    const groups: { applicationTypeId?: string; group?: number; display: string }[] = [];
    offencesSelected.forEach((o) => {
      if (o && !groups.find((e) => e.applicationTypeId === o.applicationTypeId && e.group === o.group)) {
        groups.push({
          applicationTypeId: o.applicationTypeId,
          group: o.group,
          display:
            domainContext.applicationTypes.find((at) => at.applicationTypeId === o.applicationTypeId)?.name +
              (o.group ? ` ${o.group}` : '') || '',
        });
      }
    });
    return groups.sort((a, b) => a.display.localeCompare(b.display));
  };

  const selectedChargesInGroup = (group: { applicationTypeId?: string; group?: number }) => {
    const offencesSelected: IOffence[] = [];
    fileCharges.forEach((c) => {
      const offence = offences.find((o) => o.offenceId === c.offenceId);
      if (offence && offence.applicationTypeId === group.applicationTypeId && offence.group === group.group) {
        offencesSelected.push(offence);
      }
    });
    return fileCharges
      .map((charge, index) => {
        return { ...charge, fileIndex: index };
      })
      .filter((o) => offencesSelected.map((o) => o.offenceId).includes(o.offenceId))
      .sort((a, b) => {
        const offenceA = offences.find((o) => o.offenceId === a.offenceId);
        const offenceB = offences.find((o) => o.offenceId === b.offenceId);
        if (offenceA && offenceB) {
          return offenceA.number.localeCompare(offenceB.number);
        } else if (offenceA) {
          return 1;
        } else if (offenceB) {
          return -1;
        } else {
          return 0;
        }
      });
  };

  return (
    <>
      <Card className={'mb-3'}>
        <CardHeader className={'d-flex align-items-center flex-wrap'}>
          {showForm && isEditingAllowedOnlySuperAdminIntake() ? (
            <Select
              name={'chargeId'}
              className={'flex-fill'}
              placeholder={'Select charge/matter…'}
              options={groupedOptions()}
              value={null}
              onChange={(value: any) => {
                addCharge(value?.offenceId);
              }}
              getOptionValue={(option: IOffence) => option.offenceId}
              getOptionLabel={(option: IOffence) => option.number}
              isClearable={true}
              menuPlacement={'auto'}
              styles={{
                menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
              }}
            />
          ) : (
            'Charges / Matters'
          )}

          {file.stageId !== STAGE_PENDING && file.stageId !== undefined && (
            <div className={'card-header__actions'}>
              {showForm && isEditingAllowedOnlySuperAdminIntake() && (
                <Button color={'link'} onClick={() => cancelAddingNewCharge()}>
                  Done
                </Button>
              )}
              {!showForm && isEditingAllowedOnlySuperAdminIntake() && (
                <Button
                  color={'primary'}
                  className='btn-sm'
                  onClick={() => {
                    setShowForm(true);
                  }}
                >
                  Add Charge
                </Button>
              )}
            </div>
          )}
          {props.trySubmit && !(fileCharges?.length > 0) && (
            <>
              <div className={'break'} />
              <div className={'card-header__error'}>At least one charge / matter must be added</div>
            </>
          )}
        </CardHeader>
        {fileCharges?.length > 0 &&
          (listOfSelectedGroups().length > 0 ? (
            listOfSelectedGroups().map((group, groupIndex) => (
              <div className={'p-3'} key={groupIndex}>
                <Label className={'pb-0'}>{'Group ' + group.display}</Label>
                <ListGroup>
                  {selectedChargesInGroup(group).map((charge: any) => {
                    const offence = offences.find((o) => o.offenceId === charge.offenceId);
                    const criminal = [
                      '5ed8bcb6-87e3-42d6-9aea-3ffa3939391a',
                      '2a4a2269-40a4-4718-9884-da2d51c0d5ab',
                    ].find((at) => {
                      return at === offence?.applicationTypeId;
                    });
                    return (
                      <ListGroupItem key={charge.fileIndex}>
                        <Row>
                          <Col xs={11}>
                            <Row>
                              <Col>{offence?.number}</Col>
                              <Col>
                                <Input
                                  value={charge.breachNumber}
                                  type={'text'}
                                  name={'letter'}
                                  id={'letter'}
                                  disabled={
                                    !props.isEditingAllowed || myUserContext.isLegalAssistant || myUserContext.isLawyer
                                  }
                                  onChange={(e: any) => {
                                    const number = e.target.value;
                                    setFileCharges((c) => [
                                      ...c.slice(0, charge.fileIndex),
                                      {
                                        ...c[charge.fileIndex],
                                        breachNumber: number ? number : '',
                                      } as ICharge,
                                      ...c.slice(charge.fileIndex + 1),
                                    ]);
                                    props.setIsDirty(true);
                                  }}
                                />
                              </Col>
                              {(file.stageId === STAGE_ASSIGNED_TO_LAWYER || file.stageId === STAGE_CLOSED) &&
                                criminal && (
                                  <>
                                    <Col xs={12} md={5} lg={12} className={'mt-1 mt-md-0 mt-lg-1'}>
                                      <Select
                                        styles={disabledSelectStyles}
                                        options={pleaTypes}
                                        placeholder={'Plea... '}
                                        value={pleaTypes.find((pt) => pt.pleaTypeId === charge.pleaTypeId)}
                                        isClearable={true}
                                        onChange={(e: any) => {
                                          props.setIsDirty(true);
                                          setFileCharges((c) => [
                                            ...c.slice(0, charge.fileIndex),
                                            {
                                              ...c[charge.fileIndex],
                                              pleaTypeId: e ? e.pleaTypeId : '',
                                            } as ICharge,
                                            ...c.slice(charge.fileIndex + 1),
                                          ]);
                                        }}
                                        getOptionLabel={(option: IPleaType) => option.name}
                                        getOptionValue={(option: IPleaType) => option.pleaTypeId}
                                        isDisabled={!props.isEditingAllowed}
                                      />
                                    </Col>
                                    <Col xs={12} md={5} lg={12} className={'mt-1 mt-md-0 mt-lg-1'}>
                                      <Select
                                        styles={disabledSelectStyles}
                                        options={
                                          outcomeTypes
                                            .filter((ot) => ot.outcomeTypeId !== CHARGE_OUTCOME_SOP_ACQUITTAL)
                                            .sort((a: IOutcomeType, b: IOutcomeType) =>
                                              a.name.toUpperCase().localeCompare(b.name.toUpperCase())
                                            ) as IOutcomeType[]
                                        }
                                        placeholder={'Outcome... '}
                                        isMulti={true}
                                        isClearable={true}
                                        value={charge.outcomes.map((o: string) =>
                                          outcomeTypes.find((ot) => ot.outcomeTypeId === o)
                                        )}
                                        onChange={(e: any) => {
                                          props.setIsDirty(true);
                                          setFileCharges((c) => [
                                            ...c.slice(0, charge.fileIndex),
                                            {
                                              ...c[charge.fileIndex],
                                              outcomes: e === null ? [] : e.map((o: any) => o.outcomeTypeId),
                                            } as ICharge,
                                            ...c.slice(charge.fileIndex + 1),
                                          ]);
                                        }}
                                        getOptionLabel={(option: IOutcomeType) => option.name}
                                        getOptionValue={(option: IOutcomeType) => option.outcomeTypeId}
                                        isDisabled={!props.isEditingAllowed}
                                      />
                                    </Col>
                                    <Col xs={12} md={5} lg={12} className={'mt-1 mt-md-0 mt-lg-1'}>
                                      <Select
                                        styles={disabledSelectStyles}
                                        options={electionTypes}
                                        placeholder={'Election... '}
                                        isClearable={true}
                                        value={electionTypes.find((et) => et.electionTypeId === charge.electionTypeId)}
                                        onChange={(e: any) => {
                                          props.setIsDirty(true);
                                          setFileCharges((c) => [
                                            ...c.slice(0, charge.fileIndex),
                                            {
                                              ...c[charge.fileIndex],
                                              electionTypeId: e ? e.electionTypeId : undefined,
                                            } as ICharge,
                                            ...c.slice(charge.fileIndex + 1),
                                          ]);
                                        }}
                                        getOptionLabel={(option: IElectionType) => option.name}
                                        getOptionValue={(option: IElectionType) => option.electionTypeId}
                                        isDisabled={!props.isEditingAllowed}
                                      />
                                    </Col>
                                  </>
                                )}
                            </Row>
                          </Col>
                          {isEditingAllowedOnlySuperAdminIntake() && (
                            <Col xs={1}>
                              <Button
                                color={'link'}
                                className={'p-0 text-danger'}
                                onClick={() => {
                                  props.setIsDirty(true);
                                  setFileCharges((c) => [
                                    ...c.slice(0, charge.fileIndex),
                                    ...c.slice(charge.fileIndex + 1),
                                  ]);
                                }}
                              >
                                <FaTimes />
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </ListGroupItem>
                    );
                  })}
                </ListGroup>
              </div>
            ))
          ) : (
            <ListGroup flush={true}>
              {fileCharges.map((charge, index) => (
                <ListGroupItem key={index} className={isEditingAllowedOnlySuperAdminIntake() ? 'pt-1 pb-1' : ''}>
                  <Row style={{ marginRight: 0 }}>
                    <Col style={{ verticalAlign: 'middle', margin: 'auto' }}>
                      {offences.find((o) => o.offenceId === charge.offenceId)?.number}
                    </Col>
                    {isEditingAllowedOnlySuperAdminIntake() && (
                      <Button
                        color={'link'}
                        className={'pr-0 text-danger'}
                        style={{ textAlign: 'right' }}
                        onClick={() => {
                          props.setIsDirty(true);
                          setFileCharges((c) => [...c.slice(0, index), ...c.slice(index + 1)]);
                        }}
                      >
                        <FaTimes />
                      </Button>
                    )}
                  </Row>
                </ListGroupItem>
              ))}
            </ListGroup>
          ))}
        {(file.stageId === STAGE_ASSIGNED_TO_LAWYER || file.stageId === STAGE_CLOSED) && (
          <div style={{ zIndex: 0 }}>
            <FormGroup className={listOfSelectedGroups().length > 0 ? 'ml-3 mt-0 mb-2' : 'ml-3 mt-2 mb-2'}>
              <CustomInput
                type={'checkbox'}
                id={'appeal'}
                key={'appeal'}
                name={'isAppealRecommended'}
                label={'Is an appeal of this outcome recommended?'}
                checked={fileContext.isAppealRecommended}
                disabled={!props.isEditingAllowed}
                onChange={() => {
                  fileContext.setIsAppealRecommended(!fileContext.isAppealRecommended);
                  props.setIsDirty(true);
                }}
              />
            </FormGroup>
            <FormGroup className={'mx-3 mb-2'}>
              <CustomInput
                type={'checkbox'}
                id={'restorativeJustice'}
                key={'restorativeJustice'}
                name={'isRestorativeJustice'}
                label={'Referred to Restorative Justice?'}
                checked={fileContext.referredToRestorativeJustice}
                disabled={!props.isEditingAllowed}
                onChange={() => {
                  fileContext.setReferredToRestorativeJustice(!fileContext.referredToRestorativeJustice);
                  props.setIsDirty(true);
                }}
              />
            </FormGroup>
            <FormGroup className={'mx-3'}>
              <Label>Findings</Label>
              <Input
                value={fileContext.chargeFindings}
                type={'textarea'}
                aria-rowcount={3}
                name={'reason'}
                id={'reason'}
                disabled={!props.isEditingAllowed}
                style={{ minHeight: '100px', height: '100px' }}
                onChange={(e: any) => {
                  const value = e.target.value;
                  fileContext.setChargeFindings(value);
                  props.setIsDirty(true);
                }}
              />
            </FormGroup>
          </div>
        )}
      </Card>
    </>
  );
};
