import { STAGE_CLOSED } from '../contexts/DomainContext';
import { authAxios } from '../services/AxiosService';
import { notifyError, notifySuccess } from '../services/ToastService';
import { FileContext, REMINDER_TYPE_FILE_EXPIRY } from '../components/Files/FileContext';
import { useCallback, useContext } from 'react';

export const useCloseFile = () => {
  const { setFile, setActiveOnscreenReminders } = useContext(FileContext);
  return useCallback(
    (fileId: string, closedDate: Date) => {
      if (fileId) {
        const data = { fileId: fileId, stageId: STAGE_CLOSED, overrideDate: closedDate.getTime() };
        authAxios.post('/api/files/admin/start-stage', data).then((response) => {
          if (response.data.status === 'OK') {
            notifySuccess('File stage has been updated.');
            setFile((f) => ({ ...f, stageId: STAGE_CLOSED, closedDate: closedDate.getTime() }));
            setActiveOnscreenReminders((s) => s.filter((s) => s.reminderTypeId !== REMINDER_TYPE_FILE_EXPIRY));
          } else {
            notifyError(response.data.status);
          }
        });
      }
    },
    [setActiveOnscreenReminders, setFile]
  );
};
