import * as React from 'react';
import { useContext } from 'react';
import { Card, CardBody, CardHeader, Col, FormGroup, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { FileContext } from './FileContext';
import { IUser } from '../Users/UsersTable';
import { PeopleContext } from 'src/contexts/PeopleContext';

export const FileSupportWorkers = () => {
  const fileContext = useContext(FileContext);
  const peopleContext = useContext(PeopleContext);

  const supportWorkers = peopleContext.supportWorkers
    .filter((i: IUser) => fileContext.supportWorkers.includes(i.userId))
    .sort((a: IUser, b: IUser) => a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase()));
  return (
    <>
      <Card className={'mb-3'}>
        <CardHeader className={'d-flex align-items-center'}>Support Workers</CardHeader>
        <CardBody>
          {supportWorkers.length > 0 && (
            <Row>
              <Col>
                <FormGroup className={'my-0'}>
                  <Label for={'supportWorkerId'}>Support Workers</Label>
                  <ListGroup>
                    {supportWorkers.map((e) => (
                      <ListGroupItem key={e.userId}>
                        {e.lastName.toUpperCase() + ', ' + e.firstName + (e.active ? '' : ' (Inactive)')}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </FormGroup>
              </Col>
            </Row>
          )}
          {supportWorkers.length === 0 && <span className={'p-2'}>No support workers</span>}
        </CardBody>
      </Card>
    </>
  );
};
