import * as React from 'react';
import { FormEvent, useContext, useEffect, useState } from 'react';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import { Button, Col, FormGroup, Input, Label, Navbar, NavbarBrand, Row } from 'reactstrap';
import { useParams } from 'react-router';
import { authAxios } from '../../services/AxiosService';
import { useHistory } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../services/ToastService';
import { INote } from './FileNotes';
import { formatDateTime } from '../../formatters/DateTimeFormatter';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { FileContext } from './FileContext';
import { MyUserContext } from '../../contexts/MyUserContext';

interface IProps {
  createCompleteAction: () => void;
  deleteCompleteAction: () => void;
  entityType: string;
}

export const FileNotesDetails: React.FC<IProps> = (props) => {
  const fileContext = useContext(FileContext);
  const myUserContext = useContext(MyUserContext);
  const file = fileContext.file;
  const [isDirty, setIsDirty] = useState(false);
  const params = useParams<{ fileId: string; noteId?: string }>();
  const [textBox, setTextBox] = useState('');
  const [lastUpdated, setLastUpdated] = useState<number | undefined>(undefined);
  const history = useHistory();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  useEffect(() => {
    if (params.noteId) {
      authAxios.get('/api/files/admin/' + params.fileId + '/notes/' + params.noteId).then((response) => {
        const note: INote = response.data;
        setTextBox(note.text);
        setLastUpdated(note.dateLastUpdated);
      });
    }
  }, [params.noteId, params.fileId]);

  const save = () => {
    if (params.noteId) {
      //update
      if (!file.isLockedDown || myUserContext.isSupportWorker) {
        authAxios
          .post('/api/files/admin/update-note', { fileId: params.fileId, noteId: params.noteId, text: textBox })
          .then((response) => {
            if (response.data.status === 'OK') {
              setIsDirty(false);
              notifySuccess('Note updated.');
              setLastUpdated(new Date().getTime());
            } else {
              notifyError('Error updating note.');
            }
          });
      }
    } else {
      //create
      authAxios.post('/api/files/admin/create-note', { fileId: params.fileId, text: textBox }).then((response) => {
        if (response.data.status === 'OK') {
          setIsDirty(false);
          notifySuccess('Note created.');
          history.push('/' + props.entityType + '/' + params.fileId + '/notes/' + response.data.noteId);
          props.createCompleteAction();
        } else {
          notifyError('Error creating note.');
        }
      });
    }
  };

  const deleteNote = () => {
    if (params.noteId) {
      authAxios
        .post('/api/files/admin/delete-note', { fileId: params.fileId, noteId: params.noteId })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            notifySuccess('Note deleted.');
            history.replace('/' + props.entityType + '/' + params.fileId + '/notes', { noteToDelete: params.noteId });
            props.deleteCompleteAction();
          } else {
            notifyError('Error deleting note.');
          }
        });
    }
  };

  return (
    <div>
      <LeavePageConfirm isDirty={isDirty} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
          <Row className={'flex-fill'}>
            <Col md={3} xl={2}>
              <NavbarBrand>
                {params.noteId ? 'Edit Note' : 'Add Note'}
                {params.noteId && (
                  <small className={'text-muted'}>
                    &nbsp;&nbsp;Last Updated: {lastUpdated !== undefined ? formatDateTime(new Date(lastUpdated)) : ''}
                  </small>
                )}
              </NavbarBrand>
            </Col>
            {(!file.isLockedDown || myUserContext.isSupportWorker) && (
              <Col className={'d-flex'}>
                {params.noteId ? (
                  <Button color='danger' className={'ml-auto'} onClick={() => setDeleteModalOpen(true)}>
                    Delete Note
                  </Button>
                ) : (
                  <Button
                    color='link'
                    className={'ml-auto'}
                    onClick={() => history.push('/' + props.entityType + '/' + params.fileId + '/notes')}
                  >
                    Cancel
                  </Button>
                )}
                <Button color='success' className={'ml-2'} onClick={() => save()}>
                  Save Note
                </Button>
              </Col>
            )}
            {file.isLockedDown && !myUserContext.isSupportWorker && !params.noteId && (
              <Col className={'d-flex'}>
                <Button
                  color='link'
                  className={'ml-auto'}
                  onClick={() => history.push('/' + props.entityType + '/' + params.fileId + '/notes')}
                >
                  Cancel
                </Button>
                <Button color='success' className={'ml-2'} onClick={() => save()}>
                  Save Note
                </Button>
              </Col>
            )}
          </Row>
        </Navbar>
        <div className={'m-3'}>
          <FormGroup>
            <Label>Note Text</Label>
            <Input
              value={textBox}
              disabled={file.isLockedDown && !myUserContext.isSupportWorker && params.noteId !== undefined}
              type={'textarea'}
              name={'note'}
              id={'note'}
              onChange={(e: any) => {
                const value = e.target.value;
                setTextBox(value);
                setIsDirty(true);
              }}
            />
          </FormGroup>
        </div>
      </form>
      <ConfirmModal
        isOpen={deleteModalOpen}
        title={'Delete Note'}
        onConfirm={() => {
          setDeleteModalOpen(false);
          deleteNote();
        }}
        onCancel={() => setDeleteModalOpen(false)}
        confirmButton={'Delete Note'}
      >
        <div>Are you sure you want to delete this note?</div>
      </ConfirmModal>
    </div>
  );
};
