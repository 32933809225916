import * as React from 'react';
import { useEffect, useState } from 'react';
import { Col, Container, FormFeedback, FormGroup, Input, Label, Navbar, NavbarBrand, Row } from 'reactstrap';
import { useParams } from 'react-router';
import { authAxios } from '../../services/AxiosService';
import { formatDateFromISO } from '../../formatters/DateTimeFormatter';
import { IEmail } from './UserEmailHistory';
import { InputType } from 'reactstrap/lib/Input';

export const UserEmailView: React.FC = () => {
  const [email, setEmail] = useState<IEmail>();
  const params = useParams<{ userId: string; emailMessageId: string }>();

  useEffect(() => {
    if (params.userId && params.emailMessageId) {
      authAxios.get('/api/users/admin/' + params.userId + '/emails/' + params.emailMessageId).then((response) => {
        setEmail(response.data);
      });
    }
  }, [params.emailMessageId, params.userId]);

  const formGroupInput = (name: string, label: string, type: InputType, val: any) => (
    <FormGroup>
      <Label for={name}>{label}</Label>
      <Input label={label} value={val} type={type} name={name} id={name} readOnly={true} />
      <FormFeedback>This field is mandatory</FormFeedback>
    </FormGroup>
  );

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'}>
        <Row className='flex-fill'>
          <Col md={3} xl={2}>
            <NavbarBrand>Email</NavbarBrand>
          </Col>
        </Row>
      </Navbar>
      <Container fluid={true} className={'my-3'}>
        <Row>
          <Col sm={12} md={4} className='mt-3'>
            {formGroupInput('sentTo', 'Sent To', 'text', email?.sentTo)}
          </Col>
          <Col sm={12} md={4} className='mt-3'>
            {formGroupInput('email', 'Email', 'text', email?.email)}
          </Col>
          <Col sm={12} md={4} className='mt-3'>
            {formGroupInput('emailRecipientType', 'Recipient Type', 'text', email?.emailRecipientType)}
          </Col>
        </Row>
        <Row>
          <Col className='mt-3'>
            <FormGroup>
              <Label>Sent Date</Label>
              <Input type='text' readOnly={true} value={email ? formatDateFromISO(email.sentDate) : ''} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className='mt-3'>
            <FormGroup>
              <Label>Subject</Label>
              <Input type='text' readOnly={true} value={email?.subject} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className='mt-3'>
            <FormGroup>
              <Label>Content</Label>
              <div className='email-content' dangerouslySetInnerHTML={{ __html: email ? email.content : '' }} />
            </FormGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
