import * as React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  CustomInput,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { useContext, useEffect, useState } from 'react';
import { clientSelectValue } from '../../formatters/PeopleFormatter';
import Select, { components, OptionProps, SingleValueProps } from 'react-select';
import { IIndividual } from '../Individuals/IndividualContext';
import { IndividualsContext } from '../../contexts/IndividualsContext';
import DatePicker from 'react-datepicker';
import { ICharge, IFile, IHoursRecord } from '../Files/FileContext';
import { DomainContext, ITimeTrackingActivity, STAGE_ASSIGNED_TO_LAWYER } from '../../contexts/DomainContext';
import { authAxios } from '../../services/AxiosService';
import { formatDateForServer } from '../../formatters/DateTimeFormatter';
import { notifyError, notifySuccess } from '../../services/ToastService';
import { getUserId } from '../../services/AuthenticationService';
import { MyUserContext } from '../../contexts/MyUserContext';
import { PeopleContext } from '../../contexts/PeopleContext';
import { IUser } from '../Users/UsersTable';
import * as AuthenticationService from '../../services/AuthenticationService';
import { useHistory } from 'react-router';

export interface IFileTimeAddition {
  fileId: string;
  lawOfficeNumber: string;
  courtNumber: string;
  charges: ICharge[];
  activityToLog?: string;
  timeToAdd?: number;
  checked: boolean;
}

export const RecordFileTime: React.FC = () => {
  const individualsContext = useContext(IndividualsContext);
  const domainContext = useContext(DomainContext);
  const myUserContext = useContext(MyUserContext);
  const peopleContext = useContext(PeopleContext);
  const individuals = individualsContext.state.individuals;
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
  const [selectedLawyer, setSelectedLawyer] = useState<string | undefined>(
    myUserContext.isLawyer ? getUserId() : undefined
  );
  const [selectedClient, setSelectedClient] = useState<string | undefined>();
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(new Date());
  const [clientFiles, setClientFiles] = useState<IFileTimeAddition[]>([]);
  const timeTrackingActivities = domainContext.timeTrackingActivities.filter((t) => t.activityType === 'File');
  const [trySaveTime, setTrySaveTime] = useState(false);
  const history = useHistory();
  const [timeToDistribute, setTimeToDistribute] = useState<number | undefined>(undefined);
  const [activityToDistribute, setActivityToDistribute] = useState<string | undefined>(undefined);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [tryDistributeTime, setTryDistributeTime] = useState(false);
  const [overrideDistributeTimeModal, setOverrideDistributeTimeModal] = useState(false);

  const SingleValue = (props: SingleValueProps<IIndividual>) => {
    const { data } = props;
    return <components.SingleValue {...props}>{clientSelectValue(data)}</components.SingleValue>;
  };

  const Option = (props: OptionProps<IIndividual, boolean>) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <div>{clientSelectValue(data)}</div>
      </components.Option>
    );
  };

  useEffect(() => {
    if (selectedClient && selectedLawyer) {
      authAxios.get('/api/files/' + selectedClient + '/client-files').then((response) => {
        const files = response.data.map((f: any) => f.file);
        const filterFiles: IFile[] = files.filter(
          (f: IFile) =>
            f.clientId === selectedClient && f.lawyerId === selectedLawyer && f.stageId === STAGE_ASSIGNED_TO_LAWYER
        );
        const fileTimeAddition: IFileTimeAddition[] = filterFiles.map(
          (f) =>
            ({
              fileId: f.fileId,
              lawOfficeNumber: f.lawOfficeNumber,
              courtNumber: f.courtNumber,
              charges: f.charges,
              activityToLog: undefined,
              timeToAdd: undefined,
              checked: false,
            } as IFileTimeAddition)
        );
        setClientFiles(fileTimeAddition);
      });
    }
  }, [selectedClient, selectedLawyer]);

  const isFormFullyFilled = () => {
    const unfilledEntry = clientFiles.some((f) => {
      return (
        (f.activityToLog !== undefined || f.timeToAdd !== undefined) &&
        (f.activityToLog === undefined || f.timeToAdd === undefined)
      );
    });
    return !unfilledEntry && selectedDate !== undefined && selectedLawyer !== undefined;
  };

  const saveTime = () => {
    if (isFormFullyFilled()) {
      const dataToPost = clientFiles
        .filter((f) => f.activityToLog !== undefined && f.timeToAdd !== undefined && f.timeToAdd !== 0)
        .map((f) => {
          return {
            fileId: f.fileId,
            hours: f.timeToAdd ? f.timeToAdd : 0,
            userId: selectedLawyer,
            date: selectedDate ? formatDateForServer(selectedDate) : formatDateForServer(new Date()),
            timeTrackingActivityId: f.activityToLog,
          };
        });
      authAxios
        .post('/api/files/admin/record-multiple-hours', {
          recordHoursRequests: dataToPost,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            notifySuccess('Hours updated.');
            const hourRecords: IHoursRecord[] = response.data.hourRecords;
            history.push({ pathname: '/time-tracking', state: { newHours: hourRecords } });
          } else {
            notifyError(response.data.errorMessage);
          }
        });
    } else {
      setTrySaveTime(true);
    }
  };

  const distributeTime = () => {
    if (timeToDistribute && timeToDistribute !== 0 && activityToDistribute) {
      const numChecked = clientFiles.filter((cf) => cf.checked).length;
      const timeToAddEachFile = numChecked === 0 ? 0 : Number((timeToDistribute / numChecked).toFixed(2));
      setClientFiles((s: IFileTimeAddition[]) =>
        s.map((f) => {
          if (f.checked) {
            return {
              ...f,
              activityToLog: activityToDistribute,
              timeToAdd: timeToAddEachFile,
            } as IFileTimeAddition;
          } else {
            return f;
          }
        })
      );
    } else {
      setTryDistributeTime(true);
    }
  };

  const hasCheckedFile = () => {
    return clientFiles.some((file: IFileTimeAddition) => file.checked);
  };

  const totalTime = () => {
    return clientFiles
      .map((f) => f.timeToAdd)
      .reduce((partialSum: number, a) => partialSum + (a ? a : 0), 0)
      ?.toFixed(2);
  };

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'}>
        <NavbarBrand className='mr-auto'>
          Add File Time {myUserContext.isLawyer ? ' for ' + AuthenticationService.getUserName() : ''}
        </NavbarBrand>
        <Button
          color='light'
          className={'ml-auto'}
          onClick={() => {
            history.push({ pathname: '/time-tracking' });
          }}
        >
          Cancel
        </Button>
        <Button
          className={'ml-2'}
          color='success'
          onClick={() => {
            saveTime();
          }}
          disabled={!(isFormFullyFilled() && Number(totalTime()) > 0)}
        >
          Add Time
        </Button>
      </Navbar>
      <Container fluid={true} className={'my-3'}>
        <Row>
          {!myUserContext.isLawyer && (
            <Col>
              <FormGroup>
                <Label for={'client'}>Select Lawyer</Label>
                <Select
                  className={'w-100'}
                  classNamePrefix={'people-select'}
                  name={'lawyerId'}
                  options={peopleContext.lawyers.filter((e) => e.active)}
                  value={peopleContext.lawyers.find((i: IUser) => i.userId === selectedLawyer)}
                  onChange={(value: any) => {
                    if (value) {
                      setSelectedLawyer(value?.userId || undefined);
                    } else {
                      setSelectedLawyer(undefined);
                      setClientFiles([]);
                    }
                  }}
                  getOptionValue={(option: IUser) => option.userId}
                  getOptionLabel={(o: IUser) => o.displayName}
                  isClearable={true}
                  styles={{
                    menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
                  }}
                />
              </FormGroup>
            </Col>
          )}
          <Col>
            <FormGroup>
              <Label for={'client'}>Select Client</Label>
              <Select
                className={'w-100'}
                classNamePrefix={'people-select'}
                name={'clientId'}
                options={individuals
                  .filter((e) => e.active)
                  .sort((a: IIndividual, b: IIndividual) =>
                    a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase())
                  )}
                value={individuals.find((i: IIndividual) => i.individualId === selectedClient)}
                onChange={(value: any) => {
                  if (value) {
                    setSelectedClient(value?.individualId || undefined);
                  } else {
                    setSelectedClient(undefined);
                    setClientFiles([]);
                  }
                }}
                getOptionValue={(option: IIndividual) => option.individualId}
                getOptionLabel={(o: IIndividual) => `${o.formattedName}`}
                isClearable={true}
                components={{ SingleValue, Option }}
                styles={{
                  singleValue: (base) => ({
                    ...base,
                    position: 'relative',
                    top: 0,
                    transform: 'translateY(0)',
                    height: '100%',
                    padding: '0.25em 0',
                  }),
                  menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </FormGroup>
          </Col>
          <Col>
            <Label>Date to Add Time For</Label>
            <DatePicker
              className={'form-control date-select flex'}
              selected={selectedDate}
              onChange={(date) => {
                if (date) {
                  setSelectedDate(date);
                }
              }}
              showMonthDropdown={true}
              showYearDropdown={true}
              shouldCloseOnSelect={true}
              dateFormat={'EEE, MMM dd, yyyy'}
            />
            {trySaveTime && selectedDate === undefined && (
              <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                This field is mandatory
              </span>
            )}
          </Col>
        </Row>
        {clientFiles.length > 0 && (
          <Row>
            <Col>
              <Row className={'mb-2'}>
                <Col>Select Files for Time Distribution</Col>
              </Row>
              <ListGroup>
                <ListGroupItem className={'card-header'}>
                  <Row>
                    <Col style={{ width: '50px', maxWidth: '50px' }}>
                      <CustomInput
                        type={'checkbox'}
                        id={'select-all-checkbox'}
                        key={'select-all-checkbox'}
                        name={'select-all-checkbox'}
                        checked={selectAllChecked}
                        onChange={() => {
                          const newSelectAllCheckedState = !selectAllChecked;
                          setClientFiles((s: IFileTimeAddition[]) =>
                            s.map((f) => {
                              return {
                                ...f,
                                checked: newSelectAllCheckedState,
                              } as IFileTimeAddition;
                            })
                          );
                          setSelectAllChecked(newSelectAllCheckedState);
                        }}
                      />
                    </Col>
                    <Col lg={1} md={1}>
                      File #
                    </Col>
                    <Col lg={2} md={2}>
                      Court File #
                    </Col>
                    <Col>Charges</Col>
                    <Col lg={3} md={3}>
                      Activity
                    </Col>
                    <Col lg={2} md={2}>
                      Time (hours)
                    </Col>
                  </Row>
                </ListGroupItem>
                {clientFiles.map((file) => {
                  return (
                    <ListGroupItem key={file.fileId} className={file.checked ? 'bg-primary-light' : ''}>
                      <Row>
                        <Col style={{ width: '50px', maxWidth: '50px' }}>
                          <CustomInput
                            type={'checkbox'}
                            id={file.fileId + '-checkbox'}
                            key={file.fileId + '-checkbox'}
                            name={file.fileId + '-checkbox'}
                            checked={file.checked}
                            onChange={() => {
                              setClientFiles((s: IFileTimeAddition[]) =>
                                s.map((f) => {
                                  if (f.fileId === file.fileId) {
                                    return {
                                      ...f,
                                      checked: !f.checked,
                                    } as IFileTimeAddition;
                                  } else {
                                    return f;
                                  }
                                })
                              );
                            }}
                          />
                        </Col>
                        <Col lg={1} md={1}>
                          {file.lawOfficeNumber}
                        </Col>
                        <Col lg={2} md={2}>
                          {file.courtNumber}
                        </Col>
                        <Col>
                          {domainContext.offences
                            .filter((o) => file.charges.map((c) => c.offenceId).includes(o.offenceId))
                            .map((o) => o.number)
                            .join(', ')}
                        </Col>
                        <Col lg={3} md={3}>
                          <Select
                            className={'w-100'}
                            name={'activityIds'}
                            options={timeTrackingActivities.filter((t) => t.active)}
                            value={timeTrackingActivities.find(
                              (t: ITimeTrackingActivity) => t.timeTrackingActivityId === file.activityToLog
                            )}
                            onChange={(value: any) => {
                              setClientFiles((s: IFileTimeAddition[]) =>
                                s.map((f) => {
                                  if (f.fileId === file.fileId) {
                                    return {
                                      ...f,
                                      activityToLog: value ? value.timeTrackingActivityId : undefined,
                                    } as IFileTimeAddition;
                                  } else {
                                    return f;
                                  }
                                })
                              );
                            }}
                            getOptionValue={(option: ITimeTrackingActivity) => option.timeTrackingActivityId}
                            getOptionLabel={(o: ITimeTrackingActivity) => o.name}
                            isClearable={true}
                          />
                          {trySaveTime &&
                            file.activityToLog === undefined &&
                            file.timeToAdd !== undefined &&
                            file.timeToAdd !== 0 && (
                              <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                                This field is mandatory
                              </span>
                            )}
                        </Col>
                        <Col lg={2} md={2}>
                          <Input
                            type='number'
                            name='time'
                            value={file.timeToAdd}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const val = e.target.value;
                              setClientFiles((s: IFileTimeAddition[]) =>
                                s.map((f) => {
                                  if (f.fileId === file.fileId) {
                                    return {
                                      ...f,
                                      timeToAdd: val ? Number(parseFloat(val).toFixed(2)) : undefined,
                                    } as IFileTimeAddition;
                                  } else {
                                    return f;
                                  }
                                })
                              );
                            }}
                          />
                          {trySaveTime && file.activityToLog !== undefined && file.timeToAdd === undefined && (
                            <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                              This field is mandatory
                            </span>
                          )}
                        </Col>
                      </Row>
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
              <Row className={'mt-2'}>
                <Col lg={1} md={1} />
                <Col lg={2} md={2} />
                <Col lg={3} md={3} />
                <Col lg={4} md={4} />
                <Col lg={2} md={2}>
                  Total Time (hours): {totalTime()}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {clientFiles.length > 0 && (
          <Row className={'mt-3'}>
            <Col>
              <Card className={'w-100'}>
                <CardHeader>Distribute Time</CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for={'client'}>Time to Distribute (hours)</Label>
                        <Input
                          type='number'
                          name='time'
                          value={timeToDistribute}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value;
                            setTimeToDistribute(val ? Number(parseFloat(val).toFixed(2)) : undefined);
                          }}
                        />
                        {tryDistributeTime && (timeToDistribute === undefined || timeToDistribute === 0) && (
                          <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                            This field is mandatory
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for={'activityToDistribute'}>Activity to Distribute</Label>
                        <Select
                          name={'activityToDistribute'}
                          options={timeTrackingActivities.filter((t) => t.active)}
                          value={timeTrackingActivities.find(
                            (t: ITimeTrackingActivity) => t.timeTrackingActivityId === activityToDistribute
                          )}
                          onChange={(value: any) => {
                            setActivityToDistribute(value ? value.timeTrackingActivityId : undefined);
                          }}
                          getOptionValue={(option: ITimeTrackingActivity) => option.timeTrackingActivityId}
                          getOptionLabel={(o: ITimeTrackingActivity) => o.name}
                          isClearable={true}
                        />
                        {tryDistributeTime && activityToDistribute === undefined && (
                          <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                            This field is mandatory
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <Button
                        style={{ marginTop: '27px' }}
                        onClick={() => {
                          if (
                            clientFiles.some(
                              (f: IFileTimeAddition) =>
                                f.checked && (f.timeToAdd !== undefined || f.activityToLog !== undefined)
                            )
                          ) {
                            setOverrideDistributeTimeModal(true);
                          } else {
                            distributeTime();
                          }
                        }}
                        disabled={!hasCheckedFile()}
                      >
                        Distribute Time
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
      <Modal
        isOpen={openDeleteConfirmationModal}
        toggle={() => {
          setOpenDeleteConfirmationModal(!openDeleteConfirmationModal);
        }}
        className='logout-modal'
        style={{ width: '60rem', maxWidth: '60rem' }}
      >
        <ModalHeader>Delete Confirmation</ModalHeader>
        <ModalBody>Are you sure you want to delete this time entry?</ModalBody>
        <ModalFooter>
          <Button color='danger'>Delete</Button>
          <Button color='light' onClick={() => setOpenDeleteConfirmationModal(!openDeleteConfirmationModal)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={overrideDistributeTimeModal}
        toggle={() => {
          setOverrideDistributeTimeModal(!overrideDistributeTimeModal);
        }}
        className='logout-modal'
        style={{ width: '60rem', maxWidth: '60rem' }}
      >
        <ModalHeader>Override Existing Entries</ModalHeader>
        <ModalBody>
          Are you sure you want to distribute time on existing time entries? This will override the activity and time on
          any selected file.
        </ModalBody>
        <ModalFooter>
          <Button
            color='light'
            onClick={() => {
              setOverrideDistributeTimeModal(!overrideDistributeTimeModal);
            }}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => {
              distributeTime();
              setOverrideDistributeTimeModal(!overrideDistributeTimeModal);
            }}
          >
            Distribute Time
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
