import * as React from 'react';
import { FormEvent, useContext, useEffect, useMemo, useState } from 'react';

import * as FileUploadService from '../FileUpload/FileUploadService';
import LoadingService from '../Loading/LoadingService';
import { authAxios } from '../../services/AxiosService';
import { useLocation } from 'react-router';
import { Column, TableInstance, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { formatDateTime } from '../../formatters/DateTimeFormatter';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { Col, Navbar, NavbarBrand, Row } from 'reactstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { TablePagination } from '../TablePagination/TablePagination';
import { useHistory, useParams } from 'react-router-dom';
import { DomainContext, IDocumentType } from '../../contexts/DomainContext';

import { DateRangeDropdown } from '../DateRangeDropdown/DateRangeDropdown';
import { FileUpload } from '../FileUpload/FileUpload';
import { DropdownMultiSelectCheckboxes } from '../DropdownMultiSelectCheckboxes/DropdownMultiSelectCheckboxes';
import { PeopleContext } from '../../contexts/PeopleContext';
import { MyUserContext } from '../../contexts/MyUserContext';
import { downloadDocument } from './DocumentService';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';

export interface IDocument {
  documentId: string;
  documentTypeId: string;
  description?: string;
  fileName: string;
  documentState: string;
  uploadId: string;
  uploadDate?: number;
  uploadedBy?: string;
  uploadedByRole?: string;
  documentOwner?: string;
  documentOwnerId?: string;
  authorizedSupportWorkers?: string[];
  privileged: boolean;
}

interface IDocToDownload {
  uploadId: string;
  fileId: string;
  documentId: string;
  fileName: string;
}

export const Documents: React.FC<{ newDocumentPath: string }> = (props) => {
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadSuccessful, setUploadSuccessful] = useState<boolean>(false);
  const { supportWorkers } = useContext(PeopleContext);
  const myUserContext = useContext(MyUserContext);
  const { documentTypes } = useContext(DomainContext);
  const [isConfirmDownloadOpen, setIsConfirmDownloadOpen] = useState<IDocToDownload | undefined>(undefined);

  const history = useHistory();
  const location = useLocation();
  const params = useParams<{ fileId: string }>();

  const newDocumentPath = props.newDocumentPath;

  useEffect(() => {
    let deletedDocumentIds = [] as string[];
    const state: any = location.state;
    if (state !== undefined && state.deletedDocumentIds) {
      deletedDocumentIds = state.deletedDocumentIds;
    }
    authAxios.get(`/api/files/admin/${params.fileId}/documents`).then((response) => {
      if (response.data) {
        const docs: IDocument[] = response.data;
        setDocuments(docs.filter((d) => !deletedDocumentIds.includes(d.documentId)));
      }
    });
  }, [location.state, params.fileId]);

  const nonSupportWorkerColumns: Column<IDocument>[] = useMemo(
    () => [
      {
        Header: 'File Name',
        accessor: 'fileName',
        width: undefined,
      },
      {
        Header: 'Document Type',
        id: 'documentTypeId',
        width: 250,
        accessor: (cell: any) => {
          return documentTypes.find((dt: IDocumentType) => dt.documentTypeId === cell.documentTypeId)?.name;
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = [], preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='documentTypeId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={documentTypes.map((s: IDocumentType) => ({
                value: s.documentTypeId,
                display: s.name,
              }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['documentTypeId'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: undefined,
      },
      {
        Header: 'Uploaded By',
        accessor: 'uploadedBy',
        width: 225,
      },
      {
        Header: 'Date Uploaded',
        id: 'uploadDate',
        width: 250,
        accessor: 'uploadDate',
        Cell: (cell: any) => {
          if (cell && cell.row.original && cell.row.original.uploadDate) {
            return <span>{formatDateTime(new Date(cell.row.original.uploadDate))}</span>;
          } else {
            return <span />;
          }
        },
        filter: (rows: any, id: any, filterValue: { startDate?: Date; endDate?: Date }) => {
          return rows.filter((row: any) => {
            if (!filterValue.startDate || !filterValue.endDate) {
              return true;
            } else {
              const rowValue = row.original['uploadDate'];
              return rowValue > filterValue.startDate.getTime() && rowValue < filterValue.endDate.getTime();
            }
          });
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => (
          <DateRangeDropdown
            startDate={filterValue?.startDate}
            endDate={filterValue?.endDate}
            setRange={(dates: { startDate?: Date; endDate?: Date }) => {
              setFilter(dates);
            }}
            keyPrefix={'uploadDate'}
          />
        ),
      },
      {
        Header: 'Authorized Support Workers',
        id: 'authorizedSupportWorkers',
        accessor: (cell: any) => {
          const supportWorkerList = supportWorkers.filter((sw) => cell.authorizedSupportWorkers.includes(sw.userId));
          return supportWorkerList
            .map((sw) => sw.lastName.toUpperCase() + ' ' + sw.firstName + (sw.active ? '' : ' (Inactive)'))
            .join(', ');
        },
        width: 350,
      },
      {
        Header: 'Privileged',
        id: 'privileged',
        accessor: (doc: IDocument) => {
          return doc.privileged ? 'Yes' : 'No';
        },
        width: 100,
      },
    ],
    [documentTypes, supportWorkers]
  );

  const supportWorkerColumns: Column<IDocument>[] = useMemo(
    () => [
      {
        Header: 'File Name',
        accessor: 'fileName',
        width: undefined,
      },
      {
        Header: 'Document Type',
        id: 'documentTypeId',
        width: 250,
        accessor: (cell: any) => {
          return documentTypes.find((dt: IDocumentType) => dt.documentTypeId === cell.documentTypeId)?.name;
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = [], preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='documentTypeId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={documentTypes.map((s: IDocumentType) => ({
                value: s.documentTypeId,
                display: s.name,
              }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['documentTypeId'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: undefined,
      },
      {
        Header: 'Uploaded By',
        accessor: 'uploadedBy',
        width: 225,
      },
      {
        Header: 'Date Uploaded',
        id: 'uploadDate',
        width: 250,
        accessor: 'uploadDate',
        Cell: (cell: any) => {
          if (cell && cell.row.original && cell.row.original.uploadDate) {
            return <span>{formatDateTime(new Date(cell.row.original.uploadDate))}</span>;
          } else {
            return <span />;
          }
        },
        filter: (rows: any, id: any, filterValue: { startDate?: Date; endDate?: Date }) => {
          return rows.filter((row: any) => {
            if (!filterValue.startDate || !filterValue.endDate) {
              return true;
            } else {
              const rowValue = row.original['uploadDate'];
              return rowValue > filterValue.startDate.getTime() && rowValue < filterValue.endDate.getTime();
            }
          });
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => (
          <DateRangeDropdown
            startDate={filterValue?.startDate}
            endDate={filterValue?.endDate}
            setRange={(dates: { startDate?: Date; endDate?: Date }) => {
              setFilter(dates);
            }}
            keyPrefix={'uploadDate'}
          />
        ),
      },
    ],
    [documentTypes]
  );

  const data: IDocument[] = useMemo(() => documents, [documents]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: TableInstance<IDocument> = useTable(
    {
      columns: myUserContext.isSupportWorker ? supportWorkerColumns : nonSupportWorkerColumns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,

        sortBy: [
          {
            id: 'uploadDate',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const selectRow = (documentId: string, uploadId: string, fileName: string) => {
    if (myUserContext.isLawyer || myUserContext.isLegalAssistant || myUserContext.isSuperAdmin) {
      history.push(`/files/${params.fileId}/documents/${documentId}`);
    } else if (myUserContext.isSupportWorker) {
      setIsConfirmDownloadOpen({ uploadId, fileId: params.fileId, documentId, fileName } as IDocToDownload);
    }
  };

  const doNewDocumentToUpload = (files: File[]) => {
    if (!newDocumentPath) {
      console.log('Nowhere to upload these... bailing!', files);
      return;
    }
    console.log("Let's upload these ", files);
    setIsUploading(true);

    const loadingKey: string = LoadingService.add();

    const doUploadOneFile = (file: File) => {
      return FileUploadService.uploadFile(file, newDocumentPath).then((response: any) => {
        if (response.data.status === 'OK') {
          console.log(response.data.document);
          setDocuments((docs: IDocument[]) => [...docs, response.data.document as IDocument]);
        }
      });
    };

    const sequenceUploads = Array.from(files).reduce((p, file: File) => {
      return p.then(() => doUploadOneFile(file).catch((err) => console.log('Upload failed.', err)));
    }, Promise.resolve());

    sequenceUploads
      .then(() => {
        console.log('Uploaded all files.');
        LoadingService.remove(loadingKey);
        setIsUploading(true);
        setUploadSuccessful(true);
        setTimeout(() => {
          setUploadSuccessful(false);
        }, 3000);
      })
      .catch((error) => {
        console.log('One or more upload failed.', error);
        LoadingService.remove(loadingKey);
        setIsUploading(false);
        setUploadSuccessful(false);
      });
  };

  return (
    <>
      {(myUserContext.isLegalAssistant || myUserContext.isLawyer || myUserContext.isSuperAdmin) && (
        <div className={'p-3'}>
          <FileUpload
            onDrop={(e: any) => doNewDocumentToUpload(e)}
            isUploading={isUploading}
            uploadSuccessful={uploadSuccessful}
          />
        </div>
      )}
      <div>
        <form
          onSubmit={(e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }}
        >
          <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
            <Row className={'flex-fill'}>
              <Col md={3} xl={2}>
                <NavbarBrand>Documents</NavbarBrand>
              </Col>
            </Row>
          </Navbar>
          <div className={'table-responsive'} style={{ minHeight: '500px' }}>
            <table className={'table table-bordered table-hover'} {...getTableProps()}>
              <thead style={{ overflow: 'visible' }}>
                {headerGroups.map((headerGroup: any, index: number) => (
                  <tr key={`files-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <th
                        key={`files-table-thead-tr-${index}-${column.id}`}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        style={{
                          width: column.width,
                          ...column.getHeaderProps(column.getSortByToggleProps()).style,
                        }}
                      >
                        {column.render('Header')}
                        <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                        <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
                {page.map((row: any) => {
                  prepareRow(row);
                  return (
                    <tr key={`files-table-tr-${row.id}`} {...row.getRowProps()}>
                      {row.cells.map((cell: any) => {
                        return (
                          <td
                            key={`files-table-td-${cell.row.id}-${cell.column.id}`}
                            {...cell.getCellProps()}
                            onClick={() => {
                              return selectRow(row.original.documentId, row.original.uploadId, row.original.fileName);
                            }}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <TablePagination
            pageCount={pageCount}
            pageOptions={pageOptions}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageSize={pageSize}
            pageSizes={[20, 50, 100, 500]}
          />
        </form>
      </div>
      <ConfirmModal
        isOpen={isConfirmDownloadOpen !== undefined}
        title={'Download Document'}
        onConfirm={() => {
          if (isConfirmDownloadOpen !== undefined) {
            downloadDocument(isConfirmDownloadOpen.fileId, isConfirmDownloadOpen.documentId);
          }
          setIsConfirmDownloadOpen(undefined);
        }}
        onCancel={() => setIsConfirmDownloadOpen(undefined)}
        confirmButton={'Download'}
        confirmButtonColor={'primary'}
      >
        <div>
          You are downloading private and confidential records of YLSS. These documents should not be copied,
          distributed or reproduced.
        </div>
      </ConfirmModal>
    </>
  );
};
