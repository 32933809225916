import * as React from 'react';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  UncontrolledDropdown,
} from 'reactstrap';
import logo from '../Login/ylss-logo.png';
import * as AuthenticationService from '../../services/AuthenticationService';
import { FaSignOutAlt, FaSync, FaUser } from 'react-icons/fa';
import { ConfirmModal } from 'src/components/ConfirmModal/ConfirmModal';
import { AUTH_ACTION_SIGN_OUT, AuthContext } from 'src/contexts/AuthContext';
import { EnvironmentInfoBar } from './EnvironmentBar';
import { MyUserContext } from 'src/contexts/MyUserContext';

export const NavigationBar: React.FC = () => {
  const [modal, setModal] = useState(false);
  const authContext = useContext(AuthContext);
  const myUserContext = useContext(MyUserContext);

  const reportsMenuSuperAdmin = (
    <UncontrolledDropdown nav={true} className={'nav-link__with-divider'}>
      <DropdownToggle nav={true} caret={true} active={window.location.pathname.includes('-reports')}>
        Reports
      </DropdownToggle>
      <DropdownMenu style={{ zIndex: 1021 }}>
        <Link to={'/reports/active-files-reports'}>
          <DropdownItem>Active Files By Lawyer</DropdownItem>
        </Link>
        <Link to={'/reports/closed-files-reports'}>
          <DropdownItem>Closed Files By Lawyer</DropdownItem>
        </Link>
        <Link to={'/reports/total-people-served'}>
          <DropdownItem>Total People Served</DropdownItem>
        </Link>
        <Link to={'/reports/applications'}>
          <DropdownItem>Applications</DropdownItem>
        </Link>
        <Link to={'/reports/criminal-files'}>
          <DropdownItem>Criminal Offences</DropdownItem>
        </Link>
        <Link to={'/reports/specialized-court'}>
          <DropdownItem>Specialized Court</DropdownItem>
        </Link>
        <Link to={'/reports/family-matters'}>
          <DropdownItem>Family Matters</DropdownItem>
        </Link>
        <Link to={'/reports/duty-counsel'}>
          <DropdownItem>Duty Counsel</DropdownItem>
        </Link>
        <Link to={'/reports/exports'}>
          <DropdownItem>Data Exports</DropdownItem>
        </Link>
        <Link to={'/reports/board-report'}>
          <DropdownItem>Board Reports</DropdownItem>
        </Link>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const reportsMenuReportingRole = (
    <UncontrolledDropdown nav={true} className={'nav-link__with-divider'}>
      <DropdownToggle nav={true} caret={true} active={window.location.pathname.includes('-reports')}>
        Reports
      </DropdownToggle>
      <DropdownMenu style={{ zIndex: 1021 }}>
        <Link to={'/reports/total-people-served'}>
          <DropdownItem>Total People Served</DropdownItem>
        </Link>
        <Link to={'/reports/applications'}>
          <DropdownItem>Applications</DropdownItem>
        </Link>
        <Link to={'/reports/criminal-files'}>
          <DropdownItem>Criminal Offences</DropdownItem>
        </Link>
        <Link to={'/reports/specialized-court'}>
          <DropdownItem>Specialized Court</DropdownItem>
        </Link>
        <Link to={'/reports/family-matters'}>
          <DropdownItem>Family Matters</DropdownItem>
        </Link>
        <Link to={'/reports/duty-counsel'}>
          <DropdownItem>Duty Counsel</DropdownItem>
        </Link>
        <Link to={'/reports/exports'}>
          <DropdownItem>Data Exports</DropdownItem>
        </Link>
        <Link to={'/reports/board-report'}>
          <DropdownItem>Board Reports</DropdownItem>
        </Link>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const reportsMenuLegalAssistant = (
    <UncontrolledDropdown nav={true} className={'nav-link__with-divider'}>
      <DropdownToggle nav={true} caret={true} active={window.location.pathname.includes('-reports')}>
        Reports
      </DropdownToggle>
      <DropdownMenu style={{ zIndex: 1021 }}>
        <Link to={'/reports/active-files-reports'}>
          <DropdownItem>Active Files By Lawyer</DropdownItem>
        </Link>
        <Link to={'/reports/files-for-destruction'}>
          <DropdownItem>Files for Destruction</DropdownItem>
        </Link>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const userMenu = () => {
    const userMenu = (
      <UncontrolledDropdown nav={true} className={'ml-sm-auto'} style={{ zIndex: 1021 }}>
        <DropdownToggle nav={true} caret={true} className='initialsContainerDropdown'>
          <div className='initialsContainer'>
            <span className='userInitials'>
              {AuthenticationService.getUserName()
                .split(' ')
                .reverse()
                .map((s: string) => s.substr(0, 1).toUpperCase())
                .join('')}
            </span>
          </div>
        </DropdownToggle>
        <DropdownMenu right={true}>
          <Link to='/my-account'>
            <DropdownItem>
              <FaUser /> My Account
            </DropdownItem>
          </Link>
          {authContext.state.expiringSoon && (
            <DropdownItem onClick={() => AuthenticationService.renew()}>
              <FaSync /> Renew Session
            </DropdownItem>
          )}
          <DropdownItem onClick={() => logoutToggle()}>
            <FaSignOutAlt /> Log Out
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );

    if (AuthenticationService.getUserSuperAdmin() || AuthenticationService.getUserManagement()) {
      return (
        <Nav navbar={true} className={'flex-grow-1 flex-wrap'}>
          <NavItem active={window.location.pathname === '/' || window.location.pathname.includes('/files')}>
            <Link to={{ pathname: '/files' }} className={'nav-link'}>
              Files
            </Link>
          </NavItem>
          <NavItem active={window.location.pathname.includes('/individuals')}>
            <Link to={{ pathname: '/individuals' }} className={'nav-link'}>
              Individuals
            </Link>
          </NavItem>
          <NavItem active={window.location.pathname.includes('/workload-report')}>
            <Link to={{ pathname: '/workload-report' }} className={'nav-link'}>
              Workloads
            </Link>
          </NavItem>
          <NavItem active={window.location.pathname.includes('/intake-report')}>
            <Link to={{ pathname: '/intake-report' }} className={'nav-link'}>
              Intake Report
            </Link>
          </NavItem>

          <NavItem active={window.location.pathname.includes('/users')}>
            <Link to={{ pathname: '/users' }} className={'nav-link'}>
              Users
            </Link>
          </NavItem>
          <NavItem active={window.location.pathname.includes('/offices')}>
            <Link to={{ pathname: '/offices' }} className={'nav-link'}>
              Offices
            </Link>
          </NavItem>
          <NavItem active={window.location.pathname.includes('/offences')}>
            <Link to={{ pathname: '/offences' }} className={'nav-link'}>
              Offences
            </Link>
          </NavItem>
          <NavItem active={window.location.pathname.includes('/time-tracking')}>
            <Link to={{ pathname: '/time-tracking' }} className={'nav-link'}>
              Time Tracking
            </Link>
          </NavItem>
          {reportsMenuSuperAdmin}
          <div className={'flex ml-sm-auto'}>{userMenu}</div>
        </Nav>
      );
    }
    if (AuthenticationService.loggedIn() && myUserContext.isReportingOnly) {
      return (
        <Nav navbar={true} className={'flex-grow-1 flex-wrap'}>
          {reportsMenuReportingRole}
          <div className={'flex ml-sm-auto'}>{userMenu}</div>
        </Nav>
      );
    }
    if (AuthenticationService.loggedIn() && !myUserContext.isReportingOnly) {
      return (
        <Nav navbar={true} className={'flex-grow-1 flex-wrap'}>
          {!myUserContext.isSupportWorker && (
            <NavItem active={window.location.pathname === '/' || window.location.pathname.includes('/files')}>
              <Link to={{ pathname: '/' }} className={'nav-link'}>
                Files
              </Link>
            </NavItem>
          )}
          {!myUserContext.isSupportWorker && (
            <NavItem
              active={window.location.pathname === '/' || window.location.pathname.includes('/non-client-files')}
            >
              <Link to={{ pathname: '/non-client-files' }} className={'nav-link'}>
                Non Client Files
              </Link>
            </NavItem>
          )}
          {myUserContext.isLegalAssistant && (
            <NavItem active={window.location.pathname.includes('/individuals')}>
              <Link to={{ pathname: '/individuals' }} className={'nav-link'}>
                Individuals
              </Link>
            </NavItem>
          )}
          {(myUserContext.isLegalAssistant || myUserContext.isLawyer || myUserContext.isSupportWorker) && (
            <NavItem active={window.location.pathname.includes('/support-worker-requests')}>
              <Link to={{ pathname: '/support-worker-requests' }} className={'nav-link'}>
                Support Requests
              </Link>
            </NavItem>
          )}
          {!myUserContext.isSupportWorker && (
            <NavItem active={window.location.pathname.includes('/workload-report')}>
              <Link to={{ pathname: '/workload-report' }} className={'nav-link'}>
                Workloads
              </Link>
            </NavItem>
          )}
          <NavItem active={window.location.pathname.includes('/time-tracking')}>
            <Link to={{ pathname: '/time-tracking' }} className={'nav-link'}>
              Time Tracking
            </Link>
          </NavItem>
          {myUserContext.isLegalAssistant && reportsMenuLegalAssistant}
          <div className={'flex ml-sm-auto'}>{userMenu}</div>
        </Nav>
      );
    }

    return (
      <Link to={'/login?redirect=' + window.location.pathname}>
        <Button outline={true} color='warning'>
          Log In
        </Button>
      </Link>
    );
  };

  const logout = () => {
    AuthenticationService.removeJwtFromStorage();
    authContext.dispatch(AUTH_ACTION_SIGN_OUT);
  };

  const logoutToggle = () => {
    setModal((s) => !s);
  };

  return (
    <header>
      <Navbar color='purple' dark={true} expand='xs' className='navigation-bar flex-wrap'>
        <EnvironmentInfoBar />
        <a href={'#main'} className={'skip-navigation nav-link'}>
          Skip Navigation
        </a>
        <NavbarBrand tag={Link} to={'/'}>
          <img src={logo} style={{ height: 50 }} className='ylss-logo' alt='YLSS Logo' />
        </NavbarBrand>
        <hr className='d-block d-md-none w-100' />
        {userMenu()}
      </Navbar>
      <ConfirmModal
        isOpen={modal}
        title={'Confirm Logout'}
        onConfirm={() => logout()}
        onCancel={() => logoutToggle()}
        confirmButton={'Logout'}
      >
        You are about to logout.
      </ConfirmModal>
    </header>
  );
};
