import * as React from 'react';
import { useContext } from 'react';
import {
  STAGE_APPROVED,
  STAGE_ASSIGNED_TO_LAWYER,
  STAGE_BOARD_APPROVED,
  STAGE_BOARD_REFUSED,
  STAGE_BOARD_REVIEW,
  STAGE_CLOSED,
  STAGE_COUNSEL_REQUEST,
  STAGE_COUNSEL_REQUEST_DRAFT,
  STAGE_DUTY_COUNSEL,
  STAGE_ERROR,
  STAGE_LAWYER_REQUESTED,
  STAGE_PENDING,
  STAGE_READY_FOR_REVIEW,
  STAGE_REFUSED,
  STAGE_RETURN_FOR_CONFLICT,
  STAGE_STOPPED,
} from '../../contexts/DomainContext';
import { FileContext } from './FileContext';

export const FileProgress: React.FC = () => {
  const fileContext = useContext(FileContext);
  const file = fileContext.file;

  const Tail: React.FC = () => {
    if (file.stageId === STAGE_ERROR) {
      return (
        <>
          <li className={`progress-item ${file?.stageId === STAGE_ERROR ? 'progress-item--active' : ''}`}>Error</li>
        </>
      );
    } else if (file.stageId === STAGE_REFUSED || file.stageId === STAGE_BOARD_REVIEW) {
      return (
        <>
          <li className={`progress-item ${file?.stageId === STAGE_REFUSED ? 'progress-item--active' : ''}`}>Refused</li>
          <li className={`progress-item ${file?.stageId === STAGE_BOARD_REVIEW ? 'progress-item--active' : ''}`}>
            Board Review
          </li>
          <li className={`progress-item`}>Board Decision</li>
        </>
      );
    } else if (file.stageId === STAGE_BOARD_REFUSED) {
      return (
        <>
          <li className={`progress-item`}>Refused</li>
          <li className={`progress-item`}>Board Review</li>
          <li className={`progress-item ${file?.stageId === STAGE_BOARD_REFUSED ? 'progress-item--active' : ''}`}>
            Board Refused
          </li>
        </>
      );
    } else {
      return (
        <>
          {file.boardReviewed && (
            <>
              <li className={`progress-item`}>Board Review</li>
            </>
          )}
          <li
            className={`progress-item ${
              file?.stageId === STAGE_BOARD_APPROVED ||
              file?.stageId === STAGE_APPROVED ||
              file?.stageId === STAGE_RETURN_FOR_CONFLICT
                ? 'progress-item--active'
                : ''
            }`}
          >
            Intake Conflict Check
          </li>
          <li className={`progress-item ${file?.stageId === STAGE_LAWYER_REQUESTED ? 'progress-item--active' : ''}`}>
            Law Office <span className={'nowrap'}>Conflict Check</span>
          </li>
          <li className={`progress-item ${file?.stageId === STAGE_ASSIGNED_TO_LAWYER ? 'progress-item--active' : ''}`}>
            Assigned
          </li>
          <li className={`progress-item ${file?.stageId === STAGE_CLOSED ? 'progress-item--active' : ''}`}>Closed</li>
        </>
      );
    }
  };

  return (
    <ol className={'d-flex flex-row align-items-start p-0 py-4 m-0'}>
      {file?.counselRequest && (
        <li className={`progress-item ${file?.stageId === STAGE_COUNSEL_REQUEST_DRAFT ? 'progress-item--active' : ''}`}>
          Draft Counsel Request
        </li>
      )}
      {file?.counselRequest && (
        <li className={`progress-item ${file?.stageId === STAGE_COUNSEL_REQUEST ? 'progress-item--active' : ''}`}>
          Counsel Request
        </li>
      )}
      {(file?.stageId === STAGE_DUTY_COUNSEL || fileContext.dutyCounselAppearances.length > 0) && (
        <li className={`progress-item ${file?.stageId === STAGE_DUTY_COUNSEL ? 'progress-item--active' : ''}`}>
          Duty Counsel
        </li>
      )}
      <li className={`progress-item ${file?.stageId === STAGE_PENDING ? 'progress-item--active' : ''}`}>Draft</li>
      {file?.stageId === STAGE_STOPPED && (
        <li className={`progress-item ${file?.stageId === STAGE_STOPPED ? 'progress-item--active' : ''}`}>Stopped</li>
      )}
      <li className={`progress-item ${file?.stageId === STAGE_READY_FOR_REVIEW ? 'progress-item--active' : ''}`}>
        Application Approval
      </li>
      <Tail />
    </ol>
  );
};
