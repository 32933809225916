import React, { useState } from 'react';
import { InputType } from 'reactstrap/lib/Input';

export const useTextFormInput = (initialValue: string) => {
  const [value, setValue] = useState<string>(initialValue);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return {
    value,
    onChange: handleChange,
    type: 'text' as InputType,
  };
};

export const usePasswordFormInput = (initialValue: string): IUseInput => {
  const [value, setValue] = useState<string>(initialValue);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return {
    value,
    onChange: handleChange,
    type: 'password' as InputType,
  };
};

export const usePasswordFormInputWithSet = (initialValue: string): IUseInputWithSet => {
  const [value, setValue] = useState<string>(initialValue);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return {
    input: { value, onChange: handleChange, type: 'password' as InputType },
    setValue,
  };
};

export interface IUseInput {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type: InputType;
  value: string;
}

export interface IUseInputWithSet {
  input: IUseInput;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}
