import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Navbar, NavbarBrand, Row } from 'reactstrap';
import { FaPrint } from 'react-icons/fa';
import Select from 'react-select';
import { PeopleContext } from 'src/contexts/PeopleContext';
import { IUser } from '../Users/UsersTable';
import { authAxios } from 'src/services/AxiosService';
import { IReportingFile, IReportingFileWithClientName } from '../Files/FileContext';
import { DomainContext, FILE_TYPE_DUTY_COUNSEL, STAGE_ASSIGNED_TO_LAWYER } from 'src/contexts/DomainContext';
import { IndividualsContext } from 'src/contexts/IndividualsContext';
import { IOffence } from '../Offences/OffenceTable';
import { formatDate } from 'src/formatters/DateTimeFormatter';
import { ReportContext } from './ReportContext';

export const ActiveFilesByLawyer = () => {
  const [selectedLawyer, setSelectedLawyer] = useState();
  const peopleContext = useContext(PeopleContext);
  const individualsContext = useContext(IndividualsContext);
  const domainContext = useContext(DomainContext);
  const reportContext = useContext(ReportContext);
  const [filteredFiles, setFilteredFiles] = useState<IReportingFileWithClientName[]>([]);
  const [fileCount, setFileCount] = useState<number>(0);
  const individuals = individualsContext.state.individuals;
  const [offences, setOffences] = useState<IOffence[]>([]);
  const allFiles = reportContext.allFiles;

  useEffect(() => {
    authAxios.get('/api/offences/admin').then((response) => {
      setOffences(response.data);
    });
  }, []);

  useEffect(() => {
    if (selectedLawyer) {
      const reportingFiles = allFiles.filter(
        (f) =>
          f.sId === STAGE_ASSIGNED_TO_LAWYER && f.lId && f.lId === selectedLawyer && f.fTId !== FILE_TYPE_DUTY_COUNSEL
      );
      setFilteredFiles(
        reportingFiles.map((f: IReportingFile) => {
          const individual = individuals.find((i) => i.individualId === f.cId);
          return {
            ...f,
            cFName: individual ? individual.firstName : '',
            cLName: individual ? individual.lastName : '',
          } as IReportingFileWithClientName;
        })
      );
      setFileCount(reportingFiles.length);
    } else {
      setFilteredFiles([]);
      setFileCount(0);
    }
  }, [selectedLawyer, allFiles]);

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
        <Row className={'flex-fill'}>
          <Col md={3} xl={2}>
            <NavbarBrand>Active Reports By Lawyer</NavbarBrand>
          </Col>
          <Col className={'d-flex'}>
            <Button className={'ml-auto'} color={'warning'} onClick={() => window.print()}>
              Print <FaPrint />
            </Button>
          </Col>
        </Row>
      </Navbar>
      <Container className={'my-5 container--no-margin-print'}>
        <Card className={'no-print'}>
          <CardHeader>Select Lawyer</CardHeader>
          <CardBody>
            <div className={'flex align-items-center'}>
              <Label className={'flex second-label'} style={{ paddingRight: '13px' }}>
                Lawyer
              </Label>
              {/* peopleContext.lawyers is populated with the "staff" end point which filters to the office for Legal Assistants and to the user for Lawyers  */}
              <Select
                style={{ maxWidth: '240px' }}
                options={peopleContext.lawyers.sort((a, b) =>
                  a.lastName + a.firstName > b.lastName + b.firstName ? 1 : -1
                )}
                placeholder={'Select lawyer...'}
                value={peopleContext.lawyers.find((u: IUser) => u.userId === selectedLawyer)}
                onChange={(value: any) => {
                  setSelectedLawyer(value?.userId || '');
                }}
                getOptionValue={(option: IUser) => option.userId}
                getOptionLabel={(option: IUser) => option.lastName.toUpperCase() + ', ' + option.firstName}
                isClearable={true}
              />
            </div>
          </CardBody>
        </Card>
        <div className={'report mt-5'}>
          <header className={'report-header'}>
            <h2 className={'text-center'}>Active Files By Lawyer</h2>
            {selectedLawyer && (
              <h3 className={'text-center h5 text-muted'}>
                {'Number of Files for ' +
                  peopleContext.lawyers.find((u: IUser) => u.userId === selectedLawyer)?.lastName.toUpperCase() +
                  ', ' +
                  peopleContext.lawyers.find((u: IUser) => u.userId === selectedLawyer)?.firstName +
                  ': ' +
                  fileCount}
              </h3>
            )}
          </header>

          <table className={'report1'}>
            <tr>
              <th className={'header'}>File #</th>
              <th className={'header'}>Client</th>
              <th className={'header'}>Application Type</th>
              <th className={'header'}>File Type</th>
              <th className={'header'}>Charges</th>
              <th className={'header'}>Circuit</th>
              <th className={'header'}>App Date</th>
              <th className={'header'}>Expiry Date</th>
              <th className={'header'}>Court File #</th>
              <th className={'header'}>Law Office #</th>
            </tr>
            {filteredFiles
              .sort((a, b) =>
                `${a.cLName.toUpperCase()} ${a.cFName} ${a.fNum} ${a.fSeqNum}`.localeCompare(
                  `${b.cLName.toUpperCase()} ${b.cFName} ${b.fNum} ${b.fSeqNum}`
                )
              )
              .map((f) => {
                return (
                  <tr key={f.fId}>
                    <td className={'report-row'} width={80}>
                      {f.fNum + (f.fSeqNum > 1 ? '-' + f.fSeqNum : '')}
                    </td>
                    <td className={'report-row'}>{`${f.cLName.toUpperCase()}, ${f.cFName}`}</td>
                    <td className={'report-row'}>
                      {domainContext.applicationTypes.find((a) => a.applicationTypeId === f.appTypeId)?.name}
                    </td>
                    <td className={'report-row'}>
                      {domainContext.fileTypes.find((ft) => ft.fileTypeId === f.fTId)?.name}
                    </td>
                    <td className={'report-row'}>
                      {offences
                        .filter((o) => f.chrgs.map((c) => c.offenceId).includes(o.offenceId))
                        .map((o) => o.number)
                        .join(', ')}
                    </td>
                    <td className={'report-row'}>
                      {/*{mostSerious(offences.filter((o) => f.chrgs.map((c) => c.offenceId).includes(o.offenceId)))}*/}
                      {domainContext.circuitLocations.find((c) => c.circuitLocationId === f.cirLocId)?.name}
                    </td>
                    <td className={'report-row'}>{formatDate(new Date(f.appDate))}</td>
                    <td className={'report-row'}>{formatDate(new Date(f.eDate))}</td>
                    <td className={'report-row'}>{f.cfNum}</td>
                    <td className={'report-row'}>{f.lawOfficeNumber}</td>
                  </tr>
                );
              })}
          </table>
        </div>
      </Container>
    </div>
  );
};
