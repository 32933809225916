import * as React from 'react';
import { useState } from 'react';
import { SUPPORT_REQUEST } from 'src/contexts/DomainContext';

type ContextType = {
  selectedStatuses: string[];
  setSelectedStatuses: React.Dispatch<React.SetStateAction<string[]>>;
};

export const SupportWorkerRequestsTableContext = React.createContext<ContextType>({
  selectedStatuses: [],
  setSelectedStatuses: () => {},
});

export const SupportWorkerRequestsTableProvider = (props: any) => {
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(
    SUPPORT_REQUEST.STATES.filter((s) => s.value !== SUPPORT_REQUEST.PENDING).map((s) => s.value)
  );

  return (
    <SupportWorkerRequestsTableContext.Provider
      value={{
        selectedStatuses,
        setSelectedStatuses,
      }}
    >
      {props.children}
    </SupportWorkerRequestsTableContext.Provider>
  );
};

export const SupportWorkerRequestsTableConsumer = SupportWorkerRequestsTableContext.Consumer;
