import * as React from 'react';
import { useState } from 'react';
import { Button, CustomInput, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { CSSProperties } from 'react';

interface IProps {
  options: Array<{ value: string; display: string }>;
  values: string[];
  setValues: (s: string[]) => void;
  keyPrefix: string;
  style?: CSSProperties;
}

export const DropdownMultiSelectCheckboxes: React.FC<IProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((s) => !s);
  };

  return (
    <Dropdown key={props.keyPrefix + '_dropdown'} isOpen={isOpen} toggle={toggleDropdown} style={props.style}>
      <DropdownToggle
        caret={true}
        className={'d-flex align-items-center justify-content-between w-100 text-left'}
        outline={props.values.length === 0}
        size={'sm'}
      >
        {props.values.length === 0 ? 'Show All' : 'Selected: ' + props.values.length}
      </DropdownToggle>
      <DropdownMenu className={'p-3'}>
        {props.options.map(({ value, display }) => (
          <CustomInput
            type={'checkbox'}
            id={props.keyPrefix + '_' + value}
            key={props.keyPrefix + '_' + value}
            name={value}
            label={display}
            checked={props.values.find((id: string) => value === id) !== undefined}
            onChange={() => {
              if (props.values.find((id: string) => value === id) !== undefined) {
                props.setValues(props.values.filter((id: string) => id !== value));
              } else {
                props.setValues([...props.values, value]);
              }
            }}
          />
        ))}
        <Button onClick={() => props.setValues([])} block={true} outline={true} color={'primary'} className={'mt-2'}>
          Clear All
        </Button>
      </DropdownMenu>
    </Dropdown>
  );
};
