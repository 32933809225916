import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import * as React from 'react';
import { useContext, useState } from 'react';
import { authAxios } from '../../../services/AxiosService';
import { notifyError, notifySuccess } from '../../../services/ToastService';
import { STAGE_RETURN_FOR_CONFLICT } from '../../../contexts/DomainContext';
import { useHistory, useParams } from 'react-router';
import { FileContext } from '../FileContext';
import { ConfirmModal } from '../../ConfirmModal/ConfirmModal';
import { MyUserContext } from '../../../contexts/MyUserContext';
import { PeopleContext } from '../../../contexts/PeopleContext';

export const RemoveAssignedLawyer = () => {
  const params = useParams<{ fileId: string }>();
  const fileContext = useContext(FileContext);
  const myUser = useContext(MyUserContext);
  const file = fileContext.file;
  const setFile = fileContext.setFile;

  const [rejectLawyerModalOpen, setRejectLawyerModalOpen] = useState(false);
  const [addAnotherLawyerModal, setAddAnotherLawyerModal] = useState(false);
  const [addLawyer, setAddLawyer] = useState(false);
  const [removeLawyerBool, setRemoveLawyerBool] = useState(false);
  const [checkDocumentsModal, setCheckDocumentsModal] = useState(false);
  const [removeLawyerReason, setRemoveLawyerReason] = useState('');
  const [tryRemoveLawyer, setTryRemoveLawyer] = useState(false);
  const peopleContext = useContext(PeopleContext);
  const users = peopleContext.staff;
  const history = useHistory();

  const createNewFile = () => {
    if (params.fileId) {
      const data = { fileId: params.fileId, closeOldFile: false };
      authAxios.post('/api/files/admin/create-new-file', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('File stage has been updated.');
          authAxios.post('/api/documents/duplicate-file-documents', {
            oldFileId: params.fileId,
            newFileId: response.data.fileId,
          });
          history.push('/files/' + response.data.fileId);
        } else {
          notifyError(response.data.status);
        }
      });
    }
  };

  const removeLawyer = () => {
    if (params.fileId && removeLawyerReason !== '') {
      const data = { fileId: params.fileId, reason: removeLawyerReason };
      authAxios.post('/api/files/admin/remove-lawyer', data).then((response) => {
        setRejectLawyerModalOpen(false);
        if (response.data.status === 'OK') {
          notifySuccess('Stage updated.');
          setRemoveLawyerReason('');
          setFile((f) => ({
            ...f,
            rejectedLawyers: [
              {
                lawyerId: f.lawyerId ? f.lawyerId : '',
                reason: removeLawyerReason,
                dateRejected: new Date().getTime(),
              },
            ].concat(f.rejectedLawyers ? f.rejectedLawyers : []),
            stageId: STAGE_RETURN_FOR_CONFLICT,
          }));
        } else {
          notifyError(response.data.errorMessage);
        }
      });
    } else {
      setTryRemoveLawyer(true);
    }
  };

  return (
    <div>
      <Card className={'bg-primary-light'}>
        <CardHeader>Lawyer Actions</CardHeader>
        <CardBody style={{ padding: 0 }}>
          <ListGroup flush={true}>
            {myUser.isIntakeOffice && (
              <ListGroupItem className={'bg-primary-light'}>
                <Button
                  color='success'
                  className='ml-auto'
                  onClick={() => {
                    setAddLawyer(true);
                    setCheckDocumentsModal(true);
                  }}
                >
                  Add Another Lawyer
                </Button>
              </ListGroupItem>
            )}
            <ListGroupItem className={'bg-primary-light'}>
              <Label for={'reason'} style={{ fontWeight: 'initial' }}>
                Reason for Removing Current Lawyer
              </Label>
              <Input
                value={removeLawyerReason}
                placeholder={'Add reason here...'}
                type={'text'}
                name={'Reason'}
                onChange={(e: any) => setRemoveLawyerReason(e.target.value)}
              />
              {tryRemoveLawyer && removeLawyerReason === '' && (
                <Row className={'ml-1'}>
                  <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                </Row>
              )}
              <Button
                className={'mt-3'}
                color='danger'
                onClick={() => {
                  if (removeLawyerReason === '') {
                    setTryRemoveLawyer(true);
                  } else {
                    setRemoveLawyerBool(true);
                    setCheckDocumentsModal(true);
                  }
                }}
              >
                Remove Lawyer
              </Button>
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>

      <ConfirmModal
        isOpen={addAnotherLawyerModal}
        title={'Add Another Lawyer'}
        onConfirm={() => {
          setAddAnotherLawyerModal(false);
          createNewFile();
        }}
        onCancel={() => setAddAnotherLawyerModal(false)}
        confirmButton={'Yes'}
      >
        <div className={'mb-4'}>Are you sure you want to add another lawyer to this file?</div>
      </ConfirmModal>
      <ConfirmModal
        isOpen={checkDocumentsModal}
        title={'Check Documents'}
        onConfirm={() => {
          if (removeLawyerBool) {
            setRemoveLawyerBool(false);
            setRejectLawyerModalOpen(true);
          } else if (addLawyer) {
            setAddLawyer(false);
            setAddAnotherLawyerModal(true);
          }
          setCheckDocumentsModal(false);
        }}
        onCancel={() => {
          setAddLawyer(false);
          setRemoveLawyerBool(false);
          setCheckDocumentsModal(false);
        }}
        confirmButton={'Documents are marked correctly'}
      >
        <div className={'mb-4'}>
          If there are documents associated with this file, please ensure that documents that should not be included in
          the transfer are marked as <b>Privileged</b>.
        </div>
      </ConfirmModal>

      <Modal isOpen={rejectLawyerModalOpen} style={{ width: '60rem', maxWidth: '60rem' }}>
        <ModalHeader
          toggle={() => {
            setRejectLawyerModalOpen((s) => !s);
          }}
        >
          Remove Lawyer
        </ModalHeader>
        <ModalBody>
          {`Are you sure that you want to remove ${
            users.find((lawyer) => lawyer.userId === file.lawyerId)?.displayName
          } from this File?`}
        </ModalBody>
        <ModalFooter>
          <Button
            color='success'
            onClick={() => {
              removeLawyer();
            }}
          >
            Confirm
          </Button>
          <Button
            color='light'
            onClick={() => {
              setRejectLawyerModalOpen((s) => !s);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
