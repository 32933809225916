import * as H from 'history';
import * as React from 'react';
import { Link, Route, Switch, useParams } from 'react-router-dom';
import { Col, Container, Nav, NavItem, NavLink, Row } from 'reactstrap';

import { SupportWorkerRequestDetails } from 'src/components/SupportRequests/SupportWorkerRequestDetails';
import { useContext, useEffect } from 'react';
import {
  ISupportWorkerRequestReminder,
  SupportWorkerRequestContext,
} from 'src/components/SupportRequests/SupportWorkerRequestContext';
import { authAxios } from 'src/services/AxiosService';
import { SUPPORT_REQUEST } from 'src/contexts/DomainContext';
import { useWindowDimensions } from 'src/hooks/WindowHooks';
import { SupportWorkerRequestInterventionsTable } from 'src/components/SupportRequests/SupportWorkerRequestInterventionsTable';
import { SupportWorkerRequestInterventionsDetails } from 'src/components/SupportRequests/SupportWorkerRequestInterventionsDetails';
import { IIndividual } from 'src/components/Individuals/IndividualContext';
import { IndividualsContext } from 'src/contexts/IndividualsContext';
import { clientSelectValue } from 'src/formatters/PeopleFormatter';
import { InterventionNotesDetails } from 'src/components/SupportRequests/InterventionNotesDetails';
import { MyUserContext } from 'src/contexts/MyUserContext';

const getReminders = (supportWorkerRequestId: string): Promise<ISupportWorkerRequestReminder[]> => {
  return authAxios
    .get('/api/support-worker-requests/admin/' + supportWorkerRequestId + '/active-onscreen-reminders')
    .then((response) => {
      const reminders: ISupportWorkerRequestReminder[] = response.data.reminders;
      return reminders;
    });
};

export const SupportWorkerRequest = () => {
  const params = useParams<{ supportWorkerRequestId: string; tab?: string }>();
  const {
    supportWorkerRequest,
    setSupportWorkerRequest,
    setInterventions,
    setActiveOnscreenSupportWorkerRequestReminders,
  } = useContext(SupportWorkerRequestContext);
  const individualsContext = useContext(IndividualsContext);
  const myUserContext = useContext(MyUserContext);
  const { xs, sm } = useWindowDimensions();
  const verticalMenu = xs || sm;

  useEffect(() => {
    authAxios.get('/api/support-worker-requests/admin/' + params.supportWorkerRequestId).then((response) => {
      const request = response.data;
      setSupportWorkerRequest(request);
    });
  }, [params.supportWorkerRequestId, setSupportWorkerRequest]);

  useEffect(() => {
    authAxios
      .get('/api/support-worker-requests/admin/' + params.supportWorkerRequestId + '/interventions')
      .then((response) => {
        if (response.data) {
          setInterventions(response.data);
        }
      });
  }, [params.supportWorkerRequestId, setInterventions]);

  useEffect(() => {
    if (!myUserContext.isSupportWorker) {
      console.log('Mounting the support worker requests table');
      const interval = setInterval(
        () =>
          getReminders(params.supportWorkerRequestId).then((e) => setActiveOnscreenSupportWorkerRequestReminders(e)),
        1000 * 60
      );
      return () => clearInterval(interval);
    }
  }, [myUserContext.isSupportWorker, params.supportWorkerRequestId, setActiveOnscreenSupportWorkerRequestReminders]);

  useEffect(() => {
    getReminders(params.supportWorkerRequestId).then((e) => setActiveOnscreenSupportWorkerRequestReminders(e));
  }, [params.supportWorkerRequestId, setActiveOnscreenSupportWorkerRequestReminders]);

  const tab: string = params.tab || 'details';

  const NavItemLink = (to: H.LocationDescriptor, active: boolean, name: any) => {
    return (
      <NavItem>
        <NavLink tag={Link} to={to} active={active}>
          {name}
        </NavLink>
      </NavItem>
    );
  };

  const client = individualsContext.state.individuals.find(
    (i: IIndividual) => i.individualId === supportWorkerRequest.clientId
  );

  return (
    <article className='page'>
      <header className='page-header'>
        <Container fluid={true}>
          {client && (
            <Row className={'align-items-center'}>
              <Col>
                <div className='d-flex flex-column py-3'>
                  <h1
                    className='page-header__title h3'
                    style={{ color: 'black', textAlign: 'left', fontWeight: 'normal' }}
                  >
                    {clientSelectValue(client)}
                  </h1>
                </div>
              </Col>
            </Row>
          )}
          <div className={verticalMenu ? 'd-block d-md-none pb-4 d-print-none' : 'd-none d-md-block d-print-none'}>
            <Nav tabs={!verticalMenu} pills={verticalMenu} vertical={verticalMenu}>
              <Nav tabs={true}>
                {NavItemLink(
                  '/support-worker-requests/' + params.supportWorkerRequestId + '/details',
                  tab === 'details',
                  'Details'
                )}
                {(supportWorkerRequest.status === SUPPORT_REQUEST.ACCEPTED ||
                  supportWorkerRequest.status === SUPPORT_REQUEST.COMPLETED ||
                  supportWorkerRequest.status === SUPPORT_REQUEST.INCOMPLETE) &&
                  NavItemLink(
                    '/support-worker-requests/' + params.supportWorkerRequestId + '/interventions',
                    tab === 'interventions',
                    'Interventions'
                  )}
              </Nav>
            </Nav>
          </div>
        </Container>
      </header>

      <Route
        exact={true}
        path='/support-worker-requests/:supportWorkerRequestId/(details)?'
        component={SupportWorkerRequestDetails}
      />
      <Switch>
        <Route
          exact={true}
          path='/support-worker-requests/:supportWorkerRequestId/interventions'
          component={SupportWorkerRequestInterventionsTable}
        />
        <Route
          exact={true}
          path='/support-worker-requests/:supportWorkerRequestId/interventions/add'
          component={SupportWorkerRequestInterventionsDetails}
        />
        <Route
          exact={true}
          path='/support-worker-requests/:supportWorkerRequestId/interventions/:interventionId'
          component={SupportWorkerRequestInterventionsDetails}
        />
      </Switch>
      <Route
        exact={true}
        path='/support-worker-requests/:supportWorkerRequestId/interventions/:interventionId/notes/:noteId'
        component={InterventionNotesDetails}
      />
    </article>
  );
};
