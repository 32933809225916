import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import { defaultAnonAxios } from '../../services/AxiosService';
import * as AuthenticationService from '../../services/AuthenticationService';
import { ConfirmModal } from '../../components/ConfirmModal/ConfirmModal';
import {
  AUTH_ACTION_EXPIRED,
  AUTH_ACTION_EXPIRING_SOON,
  AUTH_ACTION_SIGN_OUT,
  AUTH_STATE_SIGNED_IN,
  AuthContext,
} from '../../contexts/AuthContext';

export const SessionTimeout: React.FC = () => {
  const authContext = useContext(AuthContext);
  const [sessionExpiringSoonModal, setSessionExpiringSoonModal] = useState(false);
  const [sessionExpiredModal, setSessionExpiredModal] = useState(false);
  const [stopExpiringSoonModal, setStopExpiringSoonModal] = useState(false);

  const checkTokenExpiring = () => {
    if (authContext.state.type === AUTH_STATE_SIGNED_IN) {
      const jwt = AuthenticationService.getJwtFromStorage();
      if (jwt) {
        defaultAnonAxios.get('/api/users/check-expiring/' + jwt).then(
          (response) => {
            if (response.data.status === 'expiring') {
              authContext.dispatch(AUTH_ACTION_EXPIRING_SOON);
              setSessionExpiringSoonModal(true);
            } else if (response.data.status === 'expired') {
              authContext.dispatch(AUTH_ACTION_EXPIRED);
              setSessionExpiredModal(true);
            }
          },
          (error) => {
            // authContext.setSessionStatus('error'); TODO
          }
        );
      } else {
        authContext.dispatch('AUTH_ACTION_EXPIRED');
      }
    }
  };

  useEffect(() => {
    checkTokenExpiring();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => checkTokenExpiring(), 60 * 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext.state]);

  return (
    <React.Fragment>
      <ConfirmModal
        isOpen={sessionExpiringSoonModal && !stopExpiringSoonModal && !sessionExpiredModal}
        title={'Expiring Soon'}
        onConfirm={() => {
          AuthenticationService.renew();
          setSessionExpiringSoonModal(false);
        }}
        onCancel={() => {
          setStopExpiringSoonModal(true);
          setSessionExpiringSoonModal(false);
        }}
        onDismiss={() => setSessionExpiringSoonModal(false)}
        confirmButton={'Yes'}
        cancelButton={'No'}
      >
        Your session will expire soon, would you like to extend it?
      </ConfirmModal>
      <ConfirmModal
        isOpen={sessionExpiredModal}
        title={'Session Expired'}
        onConfirm={() => {
          AuthenticationService.removeJwtFromStorage();
          authContext.dispatch(AUTH_ACTION_SIGN_OUT);
        }}
        onCancel={() => {
          setSessionExpiredModal(false);
          setSessionExpiringSoonModal(false);
        }}
        onDismiss={() => {
          setSessionExpiredModal(false);
          setSessionExpiringSoonModal(false);
        }}
        confirmButton={'Ok'}
        cancelButton={'Cancel'}
      >
        Your session has expired. Please login again to the application.
      </ConfirmModal>
    </React.Fragment>
  );
};
