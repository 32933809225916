import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Col, Navbar, NavbarBrand, Row } from 'reactstrap';
import { Column, useSortBy, useTable } from 'react-table';
import { useParams } from 'react-router';
import { authAxios } from '../../services/AxiosService';
import { useHistory } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { formatDateFromISO } from '../../formatters/DateTimeFormatter';

export interface IEmail {
  emailMessageId: string;
  sentTo: string;
  email: string;
  subject: string;
  content: string;
  emailMessageType: string;
  emailRecipientType?: string;
  sentDate: string;
  stageUploaded: string;
}

export const UserEmailHistory: React.FC = () => {
  const [emails, setEmails] = useState<IEmail[]>([]);
  const params = useParams<{ userId: string }>();
  const history = useHistory();

  useEffect(() => {
    if (params.userId) {
      authAxios.get('/api/users/admin/' + params.userId + '/emails').then((response) => {
        console.log('emails: ', response.data);
        setEmails(response.data);
      });
    }
  }, [params.userId]);

  const columns: Column<IEmail>[] = useMemo(
    () => [
      {
        Header: 'Email Type',
        accessor: 'emailMessageType',
      },
      {
        Header: 'Subject',
        accessor: 'subject',
      },
      {
        Header: 'Sent Date',
        id: 'sentDate',
        accessor: (c: IEmail) => (c.sentDate ? c.sentDate : ''),
        Cell: (c: any) => (c.row.original.sentDate ? formatDateFromISO(c.row.original.sentDate) : ''),
      },
    ],
    []
  );
  const data: IEmail[] = useMemo(() => emails, [emails]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  const selectRow = (emailMessageId: string) => {
    history.push('/users/' + params.userId + '/emails/' + emailMessageId);
  };

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'}>
        <Row className='flex-fill'>
          <Col md={3} xl={2}>
            <NavbarBrand>Email History</NavbarBrand>
          </Col>
        </Row>
      </Navbar>
      <div className={'table-responsive'}>
        <table className={'table table-bordered table-hover'} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`users-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={`users-table-thead-tr-${index}-${column.id}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any) => {
              prepareRow(row);
              console.log('ROW');
              console.log(row);

              return (
                <tr key={`users-table-tr-${row.id}`} {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    console.log('CELL');
                    console.log(cell);
                    return (
                      <td
                        key={`users-table-td-${cell.row.id}-${cell.column.id}`}
                        {...cell.getCellProps()}
                        onClick={() => selectRow(row.original.emailMessageId)}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
