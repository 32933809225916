import * as React from 'react';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { authAxios } from '../../services/AxiosService';
import * as ToastService from '../../services/ToastService';
import { useHistory, useParams } from 'react-router';
import * as FormHelpers from '../../services/FormHelpers';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import { IAddress, IIndividual, IndividualContext } from './IndividualContext';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Select from 'react-select';
import { FaTimes } from 'react-icons/all';
import { RequiredLabel } from '../RequiredLabel';
import CreatableSelect from 'react-select/creatable';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { formatDateTime } from '../../formatters/DateTimeFormatter';
import { IndividualsContext } from '../../contexts/IndividualsContext';
import { DomainContext, editableStages, IGender, IYukonFirstNation } from '../../contexts/DomainContext';
import { FileContext } from '../Files/FileContext';
import { Link } from 'react-router-dom';
import { MyUserContext } from '../../contexts/MyUserContext';
import { FaPlus } from 'react-icons/fa';

interface IProps {
  createCompleteAction?: (individual: IIndividual) => void;
  editCompleteAction?: (individual: IIndividual) => void;
  cancel?: () => void;
  individualId?: string;
  setIndividualIsDirty?: Dispatch<SetStateAction<boolean>>;
  hideDetails?: boolean;
  allowCreateFile: boolean;
  readOnly?: boolean;
}

interface IAlias {
  aliasId: string;
  name: string;
}

export interface IPhoneNumber {
  number: string;
  description: string;
}

export const IndividualDetails: React.FC<IProps> = (props) => {
  const params = useParams<{ fileId?: string; individualId: string }>();
  const individualId = props.individualId || params.individualId;
  const individualContext = useContext(IndividualContext);
  const individualsContext = useContext(IndividualsContext);
  const domainContext = useContext(DomainContext);
  const myUserContext = useContext(MyUserContext);
  const fileContext = useContext(FileContext);
  const file = fileContext.file;
  const addresses = individualContext.addresses;
  const setAddresses = individualContext.setAddresses;
  const genders = domainContext.genders;
  const yukonFirstNations = domainContext.yukonFirstNations;
  const history = useHistory();

  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumbers, setPhoneNumbers] = useState<IPhoneNumber[]>([{} as IPhoneNumber]);
  const [active, setActive] = useState(true);
  const [visibleMinority, setVisibleMinority] = useState(false);
  const [indigenous, setIndigenous] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>();
  const [isDirty, setIsDirty] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);
  const [tryAddAddress, setTryAddAddress] = useState(false);
  const [newAddressLabel, setNewAddressLabel] = useState('');
  const [newAddressLine1, setNewAddressLine1] = useState('');
  const [newAddressLine2, setNewAddressLine2] = useState('');
  const [newAddressCity, setNewAddressCity] = useState('');
  const [newAddressTerritory, setNewAddressTerritory] = useState('');
  const [newAddressPostalCode, setNewAddressPostalCode] = useState('');
  const [newAddressIsCurrent, setNewAddressIsCurrent] = useState(true);
  const [showNewAddressFields, setShowNewAddressFields] = useState(true);
  const [aliases, setAliases] = useState<IAlias[]>([{} as IAlias]);
  const [leavePageConfirmOpen, setLeavePageConfirmOpen] = useState(false);
  const [genderId, setGenderId] = useState('');
  const [overrideShowDetails, setOverRideShowDetails] = useState(false);
  const [deleteAddressModal, setDeleteAddressModal] = useState(false);
  const [deleteAddressId, setDeleteAddressId] = useState<string | undefined>('');
  const [lastUpdated, setLastUpdated] = useState<number | undefined>(undefined);
  const [isEditingAllowed, setIsEditingAllowed] = useState(
    props.readOnly ? false : params.fileId ? editableStages.includes(file.stageId) : true
  );
  const [yukonFNId, setYukonFNId] = useState<string | undefined>(undefined);

  useEffect(() => {
    setIsEditingAllowed(props.readOnly ? false : params.fileId ? editableStages.includes(file.stageId) : true);
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file.stageId, params.fileId]);

  const disabledSelectStyles = {
    control: (styles: any, state: any) => {
      return {
        ...styles,
        borderColor: '#ced4da',
      };
    },
    menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
    singleValue: (styles: any, state: any) => {
      return {
        ...styles,
        color: '#495057',
      };
    },
  };

  const isEditingAllowedOnlySuperAdminIntake = () => {
    return props.readOnly ? false : isEditingAllowed && !myUserContext.isLegalAssistant && !myUserContext.isLawyer;
  };

  const createIndividualAction = (individual: IIndividual) => {
    individualsContext.dispatch({ type: 'INDIVIDUAL_ACTION_CREATE_INDIVIDUAL', data: individual });
  };

  const editIndividualAction = (individual: IIndividual) => {
    individualsContext.dispatch({ type: 'INDIVIDUAL_ACTION_UPDATE_INDIVIDUAL', data: individual });
  };

  const phoneOptions = [
    { value: 'Home', label: 'Home' },
    { value: 'Work', label: 'Work' },
    { value: 'Cell', label: 'Cell' },
  ];

  useEffect(() => {
    if (individualId) {
      authAxios.get('/api/individuals/' + individualId).then((response) => {
        if (response.data) {
          setFirstName(response.data.firstName);
          setMiddleName(response.data.middleName);
          setLastName(response.data.lastName);
          setEmail(response.data.email);
          setGenderId(response.data.genderId);
          setPhoneNumbers(formatIncomingPhoneNumbers(response.data.phoneNumbers || []));
          if (response.data.birthDate) {
            setDateOfBirth(new Date(response.data.birthDate));
          }
          setVisibleMinority(response.data.visibleMinority);
          setIndigenous(response.data.indigenous);
          setAddresses(response.data.addresses);
          const aliases: string[] = response.data.aliases;
          const aliasList: IAlias[] = aliases.map((a: string) => {
            return { aliasId: Math.random().toString(), name: a };
          });
          setAliases(aliasList);
          setLastUpdated(response.data.lastUpdated);
          setActive(response.data.active);
          setYukonFNId(response.data.yukonFirstNationId);
        }
      });
    } else {
      setAddresses([]);
    }
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individualId, setAddresses, genders, yukonFirstNations]);

  const setIndividualIsDirty = props.setIndividualIsDirty;

  useEffect(() => {
    setIndividualIsDirty && setIndividualIsDirty(isDirty);
  }, [isDirty, setIndividualIsDirty]);

  const addressesLength = addresses.length;
  useEffect(() => {
    if (addressesLength > 0) {
      setShowNewAddressFields(false);
    }
  }, [addressesLength]);

  const formFullyFilled = () => {
    return firstName !== '' && lastName !== '' && FormHelpers.isValidEmail(email);
  };

  const addressFullyFilled = () => {
    return newAddressLabel !== '' && newAddressLine1 !== '';
  };

  const formatIncomingPhoneNumbers = (phoneNumbers: IPhoneNumber[]) => {
    return phoneNumbers.map((pn) => {
      if (!isNaN(parseFloat(pn.number))) {
        return { number: formatPhoneNumber(pn.number), description: pn.description };
      } else {
        return { number: pn.number, description: pn.description };
      }
    });
  };

  const unFormatPhoneNumbers = (phoneNumbers: IPhoneNumber[]) => {
    return phoneNumbers.map((pn) => {
      return { number: pn.number.replace(/[^\d]/g, ''), description: pn.description };
    });
  };

  const formatPhoneNumber = (number: string) => {
    const phoneNumber = number.replace(/[^\d]/g, '');
    if (phoneNumber.length < 7) {
      return phoneNumber;
    } else {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    }
  };

  const addressFieldsEmpty = () => {
    return (
      newAddressLabel === '' &&
      newAddressLine1 === '' &&
      newAddressLine2 === '' &&
      newAddressCity === '' &&
      newAddressTerritory === '' &&
      newAddressPostalCode === '' &&
      newAddressIsCurrent
    );
  };

  const resetAddressFields = () => {
    setNewAddressLabel('');
    setNewAddressLine1('');
    setNewAddressLine2('');
    setNewAddressCity('');
    setNewAddressTerritory('');
    setNewAddressPostalCode('');
    setNewAddressIsCurrent(true);
    setTryAddAddress(false);
  };

  const removeExtraFields = (a: IAddress) => {
    return {
      addressId: a.addressId,
      individualId: a.individualId,
      label: a.label,
      line1: a.line1,
      line2: a.line2,
      city: a.city,
      territory: a.territory,
      postalCode: a.postalCode,
      isCurrent: a.isCurrent,
      dateCreated: 0,
    };
  };

  const addressesWithEditingFields = () => {
    if (showNewAddressFields) {
      if (addressFieldsEmpty()) {
        return addresses.map((a) => removeExtraFields(a));
      } else if (addressFullyFilled()) {
        const newAddress: IAddress = {
          addressId: Math.random().toString(),
          individualId: individualId ? individualId : '',
          label: newAddressLabel,
          line1: newAddressLine1,
          line2: newAddressLine2,
          city: newAddressCity,
          territory: newAddressTerritory,
          postalCode: newAddressPostalCode,
          isCurrent: newAddressIsCurrent,
        };
        setAddresses((s) => [newAddress].concat(s));
        return [newAddress].concat(addresses).map((a) => removeExtraFields(a));
      } else {
        return addresses.map((a) => removeExtraFields(a));
      }
    } else if (isAddressEditing() !== undefined) {
      return setEditAddressFalse(true, isAddressEditing()).map((a) => removeExtraFields(a));
    } else {
      return addresses.map((a) => removeExtraFields(a));
    }
  };

  const isAddressEditing = () => {
    let addressId = undefined;
    addresses.forEach((a) => {
      if (a.isEditing) {
        addressId = a.addressId;
      }
    });
    return addressId;
  };

  const saveIndividualDetails = () => {
    if (!formFullyFilled()) {
      setTrySubmit(true);
      return;
    }

    if (!addressFieldsEmpty() && !addressFullyFilled()) {
      setTryAddAddress(true);
      setTrySubmit(true);
      return;
    }

    setAliases((s) => s.filter((e) => !!e.name));
    setPhoneNumbers((s) => s.filter((ph) => !!ph.number || !!ph.description));
    if (individualId) {
      authAxios
        .post('/api/individuals/update', {
          individualId,
          firstName: firstName,
          middleName,
          lastName,
          email,
          phoneNumbers: unFormatPhoneNumbers(phoneNumbers.filter((ph) => !!ph.number || !!ph.description)),
          birthDate: dateOfBirth ? dateOfBirth.getTime() : undefined,
          visibleMinority,
          indigenous,
          addresses: addressesWithEditingFields(),
          aliases: aliases.filter((e) => !!e.name).map((a) => a.name),
          genderId,
          active,
          yukonFirstNationId: yukonFNId,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            ToastService.notifySuccess('Individual Updated');
            setIsDirty(false);
            if (file.stageId && !editableStages.includes(file.stageId)) {
              setIsEditingAllowed(!params.fileId);
            }
            const lastUpdatedUpdated = new Date().getTime();
            setLastUpdated(lastUpdatedUpdated);
            const individual = {
              individualId: individualId,
              firstName: firstName,
              birthDate: dateOfBirth ? dateOfBirth.getTime() : undefined,
              middleName,
              lastName,
              email,
              phoneNumbers,
              visibleMinority,
              indigenous,
              aliases: aliases.filter((e) => !!e.name).map((a) => a.name),
              genderId,
              lastUpdated: lastUpdatedUpdated,
              active,
              yukonFirstNationId: yukonFNId,
            } as IIndividual;

            editIndividualAction(individual);
            if (props.editCompleteAction) {
              props.editCompleteAction(individual);
            }
          } else {
            ToastService.notifyError(response.data.errorMessage);
          }
        })
        .catch((error) => {
          ToastService.notifyError('Error:' + error);
        });
    } else {
      authAxios
        .post('/api/individuals/create', {
          firstName: firstName,
          middleName,
          lastName,
          email,
          phoneNumbers: phoneNumbers.filter((ph) => !!ph.number || !!ph.description),
          birthDate: dateOfBirth ? dateOfBirth.getTime() : undefined,
          visibleMinority,
          indigenous,
          addresses: addressesWithEditingFields(),
          aliases: aliases.filter((e) => !!e.name).map((a) => a.name),
          genderId,
          active,
          yukonFirstNationId: yukonFNId,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            ToastService.notifySuccess('Individual Created');
            setIsDirty(false);
            const lastUpdatedUpdated = new Date().getTime();
            setLastUpdated(lastUpdatedUpdated);
            const individual = {
              individualId: response.data.individualId,
              firstName: firstName,
              birthDate: dateOfBirth ? dateOfBirth.getTime() : undefined,
              middleName,
              lastName,
              email,
              phoneNumbers,
              visibleMinority,
              indigenous,
              aliases: aliases.filter((e) => !!e.name).map((a) => a.name),
              genderId,
              lastUpdated: lastUpdatedUpdated,
              active,
              yukonFirstNationId: yukonFNId,
            } as IIndividual;

            createIndividualAction(individual);
            if (props.createCompleteAction) {
              props.createCompleteAction(individual);
            } else {
              history.push('/individuals/' + response.data.individualId);
            }
          } else {
            ToastService.notifyError('Error Creating Individual - ' + response.data.desc);
          }
        });
    }
  };

  const saveNewAddress = () => {
    if (addressFullyFilled()) {
      setTryAddAddress(false);
      setShowNewAddressFields(false);
      const newAddress: IAddress = {
        addressId: Math.random().toString(),
        individualId: individualId ? individualId : '',
        label: newAddressLabel,
        line1: newAddressLine1,
        line2: newAddressLine2,
        city: newAddressCity,
        territory: newAddressTerritory,
        postalCode: newAddressPostalCode,
        isCurrent: newAddressIsCurrent,
        isEditing: false,
      };
      setAddresses((s) => [newAddress, ...s]);
      resetAddressFields();
    } else {
      setTryAddAddress(true);
    }
  };

  const deleteAddress = (addressId?: string) => {
    if (addressId) {
      setIsDirty(true);
      setAddresses((s) => {
        const newList = s.filter((e) => e.addressId !== addressId);
        if (newList.length === 0) {
          resetAddressFields();
          setShowNewAddressFields(true);
        }
        return newList;
      });
    }
  };

  const setEditAddressTrue = (addressId?: string) => {
    setShowNewAddressFields(false);
    const updatedAddresses = addresses.map((address: IAddress) => {
      if (addressId && address.addressId === addressId) {
        setNewAddressLabel(address.label);
        setNewAddressLine1(address.line1);
        setNewAddressLine2(address.line2);
        setNewAddressCity(address.city);
        setNewAddressTerritory(address.territory);
        setNewAddressPostalCode(address.postalCode);
        setNewAddressIsCurrent(address.isCurrent);
        return {
          addressId: address.addressId,
          individualId: address.individualId ? address.individualId : individualId,
          label: address.label,
          line1: address.line1,
          line2: address.line2,
          city: address.city,
          territory: address.territory,
          postalCode: address.postalCode,
          isCurrent: address.isCurrent,
          isEditing: true,
        };
      } else {
        return {
          addressId: address.addressId,
          individualId: address.individualId ? address.individualId : individualId,
          label: address.label,
          line1: address.line1,
          line2: address.line2,
          city: address.city,
          territory: address.territory,
          postalCode: address.postalCode,
          isCurrent: address.isCurrent,
          isEditing: false,
        };
      }
    });
    setAddresses(updatedAddresses);
  };

  const setEditAddressFalse = (isSaving: boolean, addressId?: string) => {
    setShowNewAddressFields(false);
    resetAddressFields();
    const updatedAddresses = addresses.map((address: IAddress) => {
      if (isSaving) {
        if (addressId && address.addressId === addressId) {
          return {
            addressId: address.addressId,
            individualId: address.individualId ? address.individualId : individualId,
            label: newAddressLabel,
            line1: newAddressLine1,
            line2: newAddressLine2,
            city: newAddressCity,
            territory: newAddressTerritory,
            postalCode: newAddressPostalCode,
            isCurrent: newAddressIsCurrent,
            isEditing: false,
          };
        } else {
          return {
            addressId: address.addressId,
            individualId: address.individualId ? address.individualId : individualId,
            label: address.label,
            line1: address.line1,
            line2: address.line2,
            city: address.city,
            territory: address.territory,
            postalCode: address.postalCode,
            isCurrent: address.isCurrent,
            isEditing: false,
          };
        }
      } else {
        return {
          addressId: address.addressId,
          individualId: address.individualId ? address.individualId : individualId,
          label: address.label,
          line1: address.line1,
          line2: address.line2,
          city: address.city,
          territory: address.territory,
          postalCode: address.postalCode,
          isCurrent: address.isCurrent,
          isEditing: false,
        };
      }
    });
    setAddresses(updatedAddresses);
    return updatedAddresses;
  };

  const editingAddressFields = (isNew: boolean, addressId?: string) => {
    return (
      <ListGroupItem>
        <Form>
          <FormGroup>
            <Label>
              Address Label <span className='text-required'>(required)</span>
            </Label>
            <Input
              type='text'
              placeholder=''
              name='label'
              value={newAddressLabel}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewAddressLabel(e.target.value);
                setIsDirty(true);
              }}
              invalid={tryAddAddress && newAddressLabel === ''}
            />
            <FormFeedback>This field is mandatory</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>
              Address Line 1 <span className='text-required'>(required)</span>
            </Label>
            <Input
              type='text'
              placeholder=''
              name='line1'
              value={newAddressLine1}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewAddressLine1(e.target.value);
                setIsDirty(true);
              }}
              invalid={tryAddAddress && newAddressLine1 === ''}
            />
            <FormFeedback>This field is mandatory</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>Address Line 2</Label>
            <Input
              type='text'
              placeholder=''
              name='line2'
              value={newAddressLine2}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewAddressLine2(e.target.value);
                setIsDirty(true);
              }}
            />
          </FormGroup>
          {props.createCompleteAction && (
            <FormGroup>
              <Label>City</Label>
              <Input
                type='text'
                placeholder=''
                name='city'
                value={newAddressCity}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNewAddressCity(e.target.value);
                  setIsDirty(true);
                }}
              />
            </FormGroup>
          )}
          <Row>
            {!props.createCompleteAction && (
              <Col>
                <FormGroup>
                  <Label>City</Label>
                  <Input
                    type='text'
                    placeholder=''
                    name='city'
                    value={newAddressCity}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setNewAddressCity(e.target.value);
                      setIsDirty(true);
                    }}
                  />
                </FormGroup>
              </Col>
            )}
            <Col>
              <FormGroup>
                <Label>Province/Territory</Label>
                <Input
                  type='text'
                  placeholder=''
                  name='province'
                  value={newAddressTerritory}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setNewAddressTerritory(e.target.value);
                    setIsDirty(true);
                  }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Postal Code</Label>
                <Input
                  type='text'
                  placeholder=''
                  name='postalCode'
                  value={newAddressPostalCode.toUpperCase()}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setNewAddressPostalCode(e.target.value.toUpperCase());
                    setIsDirty(true);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <CustomInput
                  type='checkbox'
                  id={'isCurrent'}
                  name={'isCurrent'}
                  label={'Is Current Address'}
                  checked={newAddressIsCurrent}
                  onChange={() => {
                    setNewAddressIsCurrent(!newAddressIsCurrent);
                    setIsDirty(true);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          {isNew ? (
            <div className={'d-flex flex-row-reverse flex-start'}>
              <Button color='primary' className='ml-3' onClick={() => saveNewAddress()}>
                Done
              </Button>
              {addressesLength > 0 && (
                <Button
                  color={'link'}
                  className='ml-2'
                  onClick={() => {
                    setShowNewAddressFields(false);
                    resetAddressFields();
                  }}
                >
                  Cancel
                </Button>
              )}
            </div>
          ) : (
            <div className={'d-flex flex-row-reverse flex-start'}>
              <Button color='primary' className='ml-3' onClick={() => setEditAddressFalse(true, addressId)}>
                Done
              </Button>
              <Button
                color='danger'
                className='ml-3'
                onClick={() => {
                  setDeleteAddressModal(true);
                  setDeleteAddressId(addressId);
                }}
              >
                Delete
              </Button>
              <Button
                color={'link'}
                className='ml-2'
                onClick={() => {
                  setEditAddressFalse(false);
                  resetAddressFields();
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </Form>
      </ListGroupItem>
    );
  };

  const sortAddresses = (ads: IAddress[]) => {
    const activeAddresses = ads.filter((ad) => ad.isCurrent);
    const nonActiveAddresses = ads.filter((ad) => !ad.isCurrent);
    return activeAddresses.concat(nonActiveAddresses);
  };

  const setConfirmModalOpen = () => {
    setLeavePageConfirmOpen(true);
  };

  return (
    <div>
      <LeavePageConfirm isDirty={isDirty} />
      <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom d-flex'}>
        <NavbarBrand>
          {individualId ? 'Edit Individual' : 'Add New Individual'}
          {individualId && (
            <small className={'text-muted'}>
              &nbsp;&nbsp;Last Updated: {lastUpdated !== undefined ? formatDateTime(new Date(lastUpdated)) : ''}
            </small>
          )}
        </NavbarBrand>
        {!individualId && !props.createCompleteAction ? (
          <div className={'d-flex ml-auto navbar-actions'}>
            <Button color={'link'} onClick={() => history.push('/individuals')}>
              Cancel
            </Button>
            <Button color='success' className={'ml-2'} onClick={() => saveIndividualDetails()}>
              Save Details
            </Button>
          </div>
        ) : isEditingAllowed ? (
          <div className={'d-flex ml-auto navbar-actions'}>
            {props.cancel !== undefined && (
              <Button color={'link'} className={'ml-auto'} onClick={isDirty ? setConfirmModalOpen : props.cancel}>
                Cancel
              </Button>
            )}
            {props.allowCreateFile && !isDirty && active && (
              <div>
                <Button
                  color={'success'}
                  className={'ml-2'}
                  tag={Link}
                  to={{
                    pathname: '/files/add',
                    state: { dutyCounsel: true, createIndividualId: individualId },
                  }}
                >
                  <FaPlus className='mr-2' />
                  Add Duty Counsel
                </Button>
                <Button
                  color='success'
                  className={'ml-3'}
                  tag={Link}
                  to={{ pathname: '/files/add', state: { createIndividualId: individualId } }}
                >
                  Add File
                </Button>
              </div>
            )}
            {isDirty && (
              <Button color='success' className={'ml-3'} onClick={() => saveIndividualDetails()}>
                Save Details
              </Button>
            )}
          </div>
        ) : (
          <div className={'d-flex ml-auto navbar-actions'}>
            {!file.isLockedDown && !props.readOnly && (
              <Button color='primary' className={'ml-3'} onClick={() => setIsEditingAllowed(true)}>
                Edit Details
              </Button>
            )}
          </div>
        )}
      </Navbar>
      <Container fluid={true} className={'my-3'}>
        <Row>
          <Col md={4}>
            <FormGroup>
              <RequiredLabel required={firstName.length === 0}>First Name</RequiredLabel>
              <Input
                type='text'
                placeholder=''
                name='name'
                autoComplete={'off'}
                disabled={!isEditingAllowedOnlySuperAdminIntake()}
                value={firstName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFirstName(e.target.value);
                  setIsDirty(true);
                }}
                invalid={trySubmit && firstName === ''}
              />
              <FormFeedback>This field is mandatory</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Middle Name</Label>
              <Input
                type='text'
                placeholder=''
                name='middleName'
                autoComplete={'off'}
                disabled={!isEditingAllowedOnlySuperAdminIntake()}
                value={middleName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setMiddleName(e.target.value);
                  setIsDirty(true);
                }}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <RequiredLabel required={lastName.length === 0}>Last Name</RequiredLabel>
              <Input
                type='text'
                placeholder=''
                name='lastName'
                autoComplete={'off'}
                disabled={!isEditingAllowedOnlySuperAdminIntake()}
                value={lastName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLastName(e.target.value);
                  setIsDirty(true);
                }}
                invalid={trySubmit && lastName === ''}
              />
              <FormFeedback>This field is mandatory</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label>Date of Birth</Label>
              <DatePicker
                selected={dateOfBirth}
                onChange={(date) => {
                  if (date) {
                    setDateOfBirth(date);
                    setIsDirty(true);
                  } else {
                    setDateOfBirth(undefined);
                    setIsDirty(true);
                  }
                }}
                peekNextMonth
                showMonthDropdown
                disabled={!isEditingAllowedOnlySuperAdminIntake()}
                showYearDropdown={true}
                isClearable={true}
                dateFormat='MMM dd, yyyy'
                dropdownMode='select'
                maxDate={new Date()}
                className={
                  isEditingAllowedOnlySuperAdminIntake()
                    ? 'date-picker rounded mb-0'
                    : 'date-picker-disabled rounded mb-0'
                }
                placeholderText={'MMM dd, yyyy'}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Gender</Label>
              <Select
                styles={disabledSelectStyles}
                name={'gender'}
                options={genders}
                value={genders.find((g) => g.genderId === genderId)}
                onChange={(value: any) => {
                  setGenderId(value?.genderId || '');
                  setIsDirty(true);
                }}
                getOptionValue={(option: IGender) => option.genderId}
                getOptionLabel={(option: IGender) => option.name}
                isClearable={true}
                isDisabled={!isEditingAllowedOnlySuperAdminIntake()}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Email</Label>
              <Input
                type='text'
                placeholder=''
                name='email'
                autoComplete={'off'}
                disabled={!isEditingAllowed}
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.target.value);
                  setIsDirty(true);
                }}
                invalid={trySubmit && !FormHelpers.isValidEmail(email)}
              />
              <FormFeedback>Invalid Email</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label>Yukon First Nation</Label>
              <Select
                styles={disabledSelectStyles}
                name={'yukonFN'}
                options={yukonFirstNations}
                value={yukonFirstNations.find((yfn) => yfn.yukonFirstNationId === yukonFNId)}
                onChange={(value: any) => {
                  setYukonFNId(value?.yukonFirstNationId || '');
                  setIsDirty(true);
                }}
                getOptionValue={(option: IYukonFirstNation) => option.yukonFirstNationId}
                getOptionLabel={(option: IYukonFirstNation) => option.name}
                isClearable={true}
                isDisabled={!isEditingAllowed}
              />
            </FormGroup>
          </Col>
        </Row>
        {props.hideDetails && !overrideShowDetails && (
          <Button onClick={() => setOverRideShowDetails(true)}>Show Details</Button>
        )}
        {(!props.hideDetails || overrideShowDetails) && (
          <>
            <CustomInput
              type='checkbox'
              id={'active'}
              name={'active'}
              disabled={!isEditingAllowedOnlySuperAdminIntake()}
              label={'Active'}
              checked={active}
              onChange={() => {
                setActive((s) => !s);
                setIsDirty(true);
              }}
            />
            <CustomInput
              type='checkbox'
              id={'visibleMinority'}
              name={'visibleMinority'}
              disabled={!isEditingAllowed}
              label={'Does the individual belong to a visible minority?'}
              checked={visibleMinority}
              onChange={() => {
                setVisibleMinority((s) => !s);
                setIsDirty(true);
              }}
            />
            <CustomInput
              type='checkbox'
              id={'indigenous'}
              name={'indigenous'}
              disabled={!isEditingAllowed}
              label={'Does the individual identify as Indigenous?'}
              checked={indigenous}
              onChange={() => {
                setIndigenous((s) => !s);
                setIsDirty(true);
              }}
            />
            <Row className={'mt-3'}>
              <Col md={6}>
                <Card className={'mb-3'}>
                  <CardHeader className={'d-flex align-items-center'}>
                    Addresses
                    <div className={'card-header__actions'}>
                      {addresses.length > 0 && isEditingAllowed && (
                        <Button
                          color={'primary'}
                          className='btn-sm'
                          onClick={() => {
                            setEditAddressFalse(false);
                            setShowNewAddressFields(true);
                          }}
                          disabled={showNewAddressFields}
                        >
                          Add Address
                        </Button>
                      )}
                    </div>
                  </CardHeader>
                  <ListGroup flush={true}>
                    {showNewAddressFields && isEditingAllowed && editingAddressFields(true)}
                    {sortAddresses(addresses).map((address: IAddress) =>
                      !address.isEditing ? (
                        <ListGroupItem className={'d-flex align-items-start'}>
                          <div>
                            <span>
                              <b>{address.label}</b>
                            </span>
                            {address.isCurrent ? (
                              <Badge color='secondary' className='ml-2'>
                                Current Address
                              </Badge>
                            ) : (
                              <Badge color='light' className='ml-2'>
                                Past Address
                              </Badge>
                            )}
                            <div className={'street-address'}>{address.line1}</div>
                            {address.line2 && <div className={'extended-address'}>{address.line2}</div>}
                            {(address.city !== '' || address.territory !== '' || address.postalCode !== '') && (
                              <div>
                                {address.city + ', ' + address.territory + ', ' + address.postalCode.toUpperCase()}
                              </div>
                            )}
                          </div>
                          {isEditingAllowed && !showNewAddressFields && !isAddressEditing() && (
                            <Button
                              color='light'
                              size={'sm'}
                              onClick={() => setEditAddressTrue(address.addressId)}
                              className={'ml-auto'}
                            >
                              Edit
                            </Button>
                          )}
                        </ListGroupItem>
                      ) : (
                        editingAddressFields(false, address.addressId)
                      )
                    )}
                  </ListGroup>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardHeader className={'d-flex align-items-center'}>
                    Aliases
                    {isEditingAllowed && (
                      <div className={'card-header__actions'}>
                        <Button
                          color={'primary'}
                          className='btn-sm'
                          onClick={() => {
                            const newAlias: IAlias[] = [
                              {
                                aliasId: Math.random().toString(),
                                name: '',
                              },
                            ];
                            setAliases(newAlias.concat(aliases));
                            setIsDirty(true);
                          }}
                        >
                          Add Alias
                        </Button>
                      </div>
                    )}
                  </CardHeader>
                  <ListGroup flush={true}>
                    {aliases.map((a: IAlias) => (
                      <ListGroupItem key={a.aliasId} className={'d-flex flex-row align-items-center'}>
                        <div className={'flex-fill'}>
                          <Input
                            type='text'
                            placeholder='Enter alias ... '
                            name='name'
                            value={a.name}
                            disabled={!isEditingAllowed}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const updatedAliases = aliases.map((al) => {
                                if (al.aliasId === a.aliasId) {
                                  return {
                                    aliasId: al.aliasId,
                                    name: e.target.value,
                                  };
                                } else {
                                  return al;
                                }
                              });
                              setAliases(updatedAliases);
                              setIsDirty(true);
                            }}
                          />
                        </div>
                        {isEditingAllowed && (
                          <Button
                            color={'link'}
                            className={'pr-0 text-danger'}
                            onClick={() => {
                              setIsDirty(true);
                              const updatedAliases = aliases.filter((al) => al.aliasId !== a.aliasId);
                              setAliases(updatedAliases);
                            }}
                          >
                            <FaTimes />
                          </Button>
                        )}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Card>

                <Card className={'mt-3'}>
                  <CardHeader className={'d-flex align-items-center'}>
                    Phone Numbers
                    {isEditingAllowed && (
                      <div className={'card-header__actions'}>
                        <Button
                          color={'primary'}
                          className='btn-sm'
                          onClick={() => {
                            setPhoneNumbers((s) => [
                              ...s,
                              {
                                number: '',
                                description: '',
                              } as IPhoneNumber,
                            ]);
                            setIsDirty(true);
                          }}
                        >
                          Add Phone Number
                        </Button>
                      </div>
                    )}
                  </CardHeader>
                  <ListGroup flush={true}>
                    {(phoneNumbers || []).map((p: IPhoneNumber, index: number) => (
                      <ListGroupItem key={`phoneNumber_${index}`} className={'d-flex flex-row align-items-center'}>
                        <div className={'mr-2 flex-fill'}>
                          <Row>
                            <Col>
                              <CreatableSelect
                                formatCreateLabel={(inputValue: string) => inputValue}
                                options={[
                                  ...phoneOptions,
                                  ...(!p.description || phoneOptions.some((e) => e.value === p.description)
                                    ? []
                                    : [
                                        {
                                          value: p.description,
                                          label: p.description,
                                        },
                                      ]),
                                ]}
                                value={
                                  p.description
                                    ? {
                                        value: p.description,
                                        label: p.description,
                                      }
                                    : undefined
                                }
                                onChange={(val) => {
                                  setIsDirty(true);
                                  setPhoneNumbers((s: IPhoneNumber[]) => {
                                    return [
                                      ...s.slice(0, index),
                                      {
                                        ...s[index],
                                        description: (val as { value: string })?.value || '',
                                      } as IPhoneNumber,
                                      ...s.slice(index + 1),
                                    ];
                                  });
                                }}
                                isDisabled={!isEditingAllowed}
                                styles={disabledSelectStyles}
                              />
                            </Col>
                            <Col>
                              <Input
                                type='tel'
                                placeholder='Enter number ... '
                                name='phone'
                                autoComplete={'off'}
                                value={p.number || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  const number = formatPhoneNumber(e.target.value);
                                  setPhoneNumbers((s: IPhoneNumber[]) => {
                                    return [
                                      ...s.slice(0, index),
                                      { ...s[index], number } as IPhoneNumber,
                                      ...s.slice(index + 1),
                                    ];
                                  });
                                  setIsDirty(true);
                                }}
                                disabled={!isEditingAllowed}
                              />
                              <FormFeedback>This field is mandatory</FormFeedback>
                            </Col>
                          </Row>
                        </div>
                        {isEditingAllowed && (
                          <Button
                            color={'link'}
                            className={'pr-0 text-danger'}
                            onClick={() => {
                              setIsDirty(true);
                              setPhoneNumbers((s) => [...s.slice(0, index), ...s.slice(index + 1)]);
                            }}
                          >
                            <FaTimes />
                          </Button>
                        )}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>
      <Modal isOpen={leavePageConfirmOpen}>
        <ModalHeader>You have unsaved changes, are you sure you want to leave?</ModalHeader>
        <ModalBody>Your current changes will be discarded. You won&apos;t be able to undo this action.</ModalBody>
        <ModalFooter>
          <Button color='danger' onClick={props.cancel}>
            Yes, Leave Page
          </Button>
          <Button color='light' onClick={() => setLeavePageConfirmOpen((s: boolean) => !s)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <ConfirmModal
        isOpen={deleteAddressModal}
        title={'Delete Address'}
        onConfirm={() => {
          setDeleteAddressModal(false);
          deleteAddress(deleteAddressId);
          setDeleteAddressId(undefined);
        }}
        onCancel={() => setDeleteAddressModal(false)}
        confirmButton={'Delete'}
      >
        <div>Are you sure you want to delete this address?</div>
      </ConfirmModal>
    </div>
  );
};
