import * as React from 'react';
import { useContext, useState } from 'react';
import { Button, Card, Col, Container, Navbar, NavbarBrand, Row } from 'reactstrap';
import { authAxios } from '../../services/AxiosService';
import * as ToastService from '../../services/ToastService';
import { useHistory } from 'react-router';
import 'react-datepicker/dist/react-datepicker.css';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import { IIndividual } from './IndividualContext';
import Select, { components, createFilter, OptionProps, SingleValueProps } from 'react-select';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { IndividualsContext } from '../../contexts/IndividualsContext';
import { clientSelectValue } from '../../formatters/PeopleFormatter';
import { IndividualDetails } from './IndividualDetails';
import { IndividualConflictCheck } from './IndividualConflictCheck';

export const IndividualsMerge: React.FC = (props) => {
  const individualsContext = useContext(IndividualsContext);

  const [isDirty, setIsDirty] = useState(false);
  const [mergeClientsModal, setMergeClientsModal] = useState(false);
  const [clientInputValue1, setClientInputValue1] = useState('');
  const [clientInputValue2, setClientInputValue2] = useState('');
  const [individual1, setIndividual1] = useState('');
  const [individual2, setIndividual2] = useState('');
  const individuals = individualsContext.state.individuals;

  const history = useHistory();

  const mergeIndividuals = () => {
    setIsDirty(true);
    authAxios
      .post('/api/individuals/merge', { individualId1: individual1, individualId2: individual2 })
      .then((response) => {
        if (response.data.status === 'OK') {
          const ind1 = individuals.find((i) => i.individualId === individual1);
          const ind2 = individuals.find((i) => i.individualId === individual2);
          if (ind1) {
            individualsContext.dispatch({
              type: 'INDIVIDUAL_ACTION_UPDATE_INDIVIDUAL',
              data: { ...ind1, active: false } as IIndividual,
            });
          }
          if (ind2) {
            individualsContext.dispatch({
              type: 'INDIVIDUAL_ACTION_UPDATE_INDIVIDUAL',
              data: { ...ind2, aliases: ind2.aliases.concat(ind1 ? ind1.aliases : []) } as IIndividual,
            });
          }
          ToastService.notifySuccess('Individuals Merged');
          setIsDirty(false);
          history.push('/individuals');
        } else {
          ToastService.notifyError('Error Merging Individuals - ' + response.data.desc);
        }
      });
  };

  const SingleValue = (props: SingleValueProps<IIndividual>) => {
    const { data } = props;
    return <components.SingleValue {...props}>{clientSelectValue(data)}</components.SingleValue>;
  };

  const Option = (props: OptionProps<IIndividual, boolean>) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <div>{clientSelectValue(data)}</div>
      </components.Option>
    );
  };

  return (
    <div>
      <LeavePageConfirm isDirty={isDirty} />
      <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom d-flex'}>
        <NavbarBrand>Merge Individuals</NavbarBrand>
        <div className={'d-flex ml-auto navbar-actions'}>
          <Button color={'link'} onClick={() => history.push('/individuals')}>
            Cancel
          </Button>
          <Button
            color='primary'
            disabled={!individual1 || !individual2}
            className={'ml-2'}
            onClick={() => setMergeClientsModal(true)}
          >
            Merge Individuals
          </Button>
        </div>
      </Navbar>
      <Container fluid={true} className={'my-3'}>
        <Row>
          <Col>
            <h5>Individual 1</h5>
            <span>The individual who’s files will be moved and aliases copied over and will be made inactive.</span>
            <Select
              inputValue={clientInputValue1}
              onInputChange={(e) => setClientInputValue1(e)}
              className={'flex-fill mr-4 mt-3 mb-3'}
              classNamePrefix={'people-select'}
              name={'clientId'}
              options={
                !!clientInputValue1 && clientInputValue1.length >= 3
                  ? individuals.filter(
                      (e) => e.searchString?.includes(clientInputValue1.toUpperCase()) && e.individualId !== individual2
                    )
                  : []
              }
              filterOption={createFilter({ ignoreAccents: false })}
              value={individuals.find((i: IIndividual) => i.individualId === individual1)}
              onChange={(value: any) => {
                setIndividual1(value ? value.individualId || undefined : undefined);
                setIsDirty(true);
              }}
              getOptionValue={(option: IIndividual) => option.individualId}
              getOptionLabel={(o: IIndividual) => `${o.searchString}`}
              isClearable={true}
              components={{ SingleValue, Option }}
              noOptionsMessage={() =>
                !!clientInputValue1 && clientInputValue1.length >= 3
                  ? 'No individuals match your search. Add a new individual or try again.'
                  : 'Enter at least 3 characters to search.'
              }
              styles={{
                singleValue: (base) => ({
                  ...base,
                  position: 'relative',
                  top: 0,
                  transform: 'translateY(0)',
                  height: '100%',
                  padding: '0.25em 0',
                }),
              }}
            />
            {individual1 && (
              <div>
                <Card className={'mt-2 mr-4'}>
                  <IndividualDetails
                    setIndividualIsDirty={setIsDirty}
                    individualId={individual1}
                    allowCreateFile={false}
                    hideDetails={false}
                    readOnly={true}
                  />
                </Card>
                <Card className={'my-3 mr-4'}>
                  <IndividualConflictCheck individualId={individual1} />
                </Card>
              </div>
            )}
          </Col>
          <Col>
            <h5>Individual 2</h5>
            <span>The individual who will remain active and be associated with all files and aliases.</span>
            <Select
              inputValue={clientInputValue2}
              onInputChange={(e) => setClientInputValue2(e)}
              className={'flex-fill mr-2 mt-3 mb-3'}
              classNamePrefix={'people-select'}
              name={'clientId'}
              options={
                !!clientInputValue2 && clientInputValue2.length >= 3
                  ? individuals.filter(
                      (e) => e.searchString?.includes(clientInputValue2.toUpperCase()) && e.individualId !== individual1
                    )
                  : []
              }
              filterOption={createFilter({ ignoreAccents: false })}
              value={individuals.find((i: IIndividual) => i.individualId === individual2)}
              onChange={(value: any) => {
                setIndividual2(value ? value.individualId || undefined : undefined);
                setIsDirty(true);
              }}
              getOptionValue={(option: IIndividual) => option.individualId}
              getOptionLabel={(o: IIndividual) => `${o.searchString}`}
              isClearable={true}
              components={{ SingleValue, Option }}
              noOptionsMessage={() =>
                !!clientInputValue2 && clientInputValue2.length >= 3
                  ? 'No individuals match your search. Add a new individual or try again.'
                  : 'Enter at least 3 characters to search.'
              }
              styles={{
                singleValue: (base) => ({
                  ...base,
                  position: 'relative',
                  top: 0,
                  transform: 'translateY(0)',
                  height: '100%',
                  padding: '0.25em 0',
                }),
              }}
            />
            {individual2 && (
              <div>
                <Card className={'mt-2 mr-2'}>
                  <IndividualDetails
                    setIndividualIsDirty={setIsDirty}
                    individualId={individual2}
                    allowCreateFile={false}
                    hideDetails={false}
                    readOnly={true}
                  />
                </Card>
                <Card className={'mt-3 mr-2'}>
                  <IndividualConflictCheck individualId={individual2} />
                </Card>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <ConfirmModal
        isOpen={mergeClientsModal}
        title={'Merge Individuals'}
        onConfirm={() => {
          setMergeClientsModal(false);
          mergeIndividuals();
        }}
        onCancel={() => setMergeClientsModal(false)}
        confirmButton={'Merge'}
      >
        <div>
          Are you sure you want to merge individuals? All files associated with Individual 1 will be merged to
          Individual 2. All aliases associated with Individual 1 will be copied to Individual 2. Any instances where
          Individual 1 was a third party will now be updated to Individual 2. Individual 1 will be made inactive.{' '}
          <b>This action cannot be undone.</b>
        </div>
      </ConfirmModal>
    </div>
  );
};
