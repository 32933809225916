import * as React from 'react';
import { FormEvent, useContext, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import Select, { components, createFilter, OptionProps, SingleValueProps } from 'react-select';
import { authAxios } from '../../services/AxiosService';
import { notifyError, notifySuccess } from '../../services/ToastService';
import {
  DomainContext,
  editableStages,
  IApplicationType,
  ICircuitLocation,
  IFileType,
  STAGE_APPROVED,
  STAGE_ASSIGNED_TO_LAWYER,
  STAGE_BOARD_APPROVED,
  STAGE_BOARD_REFUSED,
  STAGE_BOARD_REVIEW,
  STAGE_CANCELLED_COUNSEL_REQUEST,
  STAGE_CLOSED,
  STAGE_COUNSEL_REQUEST,
  STAGE_COUNSEL_REQUEST_DRAFT,
  STAGE_DUTY_COUNSEL,
  STAGE_LAWYER_REQUESTED,
  STAGE_PENDING,
  STAGE_READY_FOR_REVIEW,
  STAGE_REFUSED,
  STAGE_RETURN_FOR_CONFLICT,
  STAGE_STOPPED,
} from '../../contexts/DomainContext';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { IUser } from '../Users/UsersTable';
import {
  FileContext,
  IDutyCounselAppearance,
  REMINDER_TYPE_EXTENSION_REQUESTED,
  REMINDER_TYPE_FILE_EXPIRY,
} from './FileContext';
import { FileThirdParties } from './FileThirdParties';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import { IIndividual } from '../Individuals/IndividualContext';
import { IndividualDetails } from '../Individuals/IndividualDetails';
import { ReviewActions } from './Actions/ReviewActions';
import { FileConflictCheck } from './FileConflictCheck';
import { SelectLawyerActions } from './Actions/SelectLawyerActions';
import { LawyerRequestActions } from './Actions/LawyerRequestActions';
import { RecordHours } from './Actions/RecordHours';
import { CloseFile } from './Actions/CloseFile';
import { RemoveAssignedLawyer } from './Actions/RemoveAssignedLawyer';
import DatePicker from 'react-datepicker';
import { DutyCounselAppearances } from './DutyCounselAppearances';
import { RequiredLabel } from '../RequiredLabel';
import { FileCharges } from './FileCharges';
import { clientSelectValue, lawyerSelectValue } from '../../formatters/PeopleFormatter';
import { BoardReviewActions } from './Actions/BoardReviewActions';
import { PendingActions } from './Actions/PendingActions';
import { StoppedActions } from './Actions/StoppedActions';
import { RefusedActions } from './Actions/RefusedActions';
import { ContributionAgreement } from './ContributionAgreement';
import { FileChildren } from './FileChildren';
import { CounselRequestActions } from './Actions/CounselRequestActions';
import { CounselRequestLawyer } from './Actions/CounselRequestLawyer';
import { MyUserContext } from '../../contexts/MyUserContext';
import { BoardRefused } from './BoardRefused';
import { IndividualsContext } from '../../contexts/IndividualsContext';
import { FileLawyerHistory } from './FileLawyerHistory';
import { ReturnForConflictActions } from './Actions/ReturnForConflictActions';
import { PeopleContext } from '../../contexts/PeopleContext';
import { FilesContext } from '../../contexts/FilesContext';
import { ErrorFile } from './Actions/ErrorFile';
import { setHours, setMinutes } from 'date-fns';
import { FileReasonRejected } from './FileReasonRejected';
import { getUserId } from '../../services/AuthenticationService';
import { FileCounselRequestRejectedReasons } from './FileCounselRequestRejectedReasons';
import { ExtensionRequest } from './Actions/ExtensionRequest';
import { ExtensionRequestAction } from './Actions/ExtensionRequestAction';
import { FileSupportWorkers } from './FileSupportWorkers';
import { formatDate } from 'src/formatters/DateTimeFormatter';

export const FileDetails: React.FC = () => {
  const domainContext = useContext(DomainContext);
  const fileContext = useContext(FileContext);
  const filesContext = useContext(FilesContext);
  const myUserContext = useContext(MyUserContext);
  const individualsContext = useContext(IndividualsContext);
  const file = fileContext.file;
  const setFile = fileContext.setFile;

  const applicationTypes = domainContext.applicationTypes;
  const fileTypes = domainContext.fileTypes;
  const circuitLocations = domainContext.circuitLocations;
  const individuals = individualsContext.state.individuals;

  const peopleContext = useContext(PeopleContext);
  const users = peopleContext.staff;

  const [trySubmit, setTrySubmit] = useState(false);
  const [trySave, setTrySave] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [individualIsDirty, setIndividualIsDirty] = useState(false);
  const [clientInputValue, setClientInputValue] = useState('');

  const regex = /[^0-9]+/;

  const history = useHistory();
  const params = useParams<{ fileId: string }>();
  const location = useLocation<{
    dutyCounsel?: boolean;
    counselRequest?: boolean;
    overrideReload?: boolean;
    createIndividualId?: string;
  }>();
  const counselRequest = location?.state?.counselRequest || file.stageId === STAGE_COUNSEL_REQUEST_DRAFT;
  const [isEditingAllowed, setIsEditingAllowed] = useState(
    myUserContext.isManagement ? false : params.fileId ? editableStages.includes(file.stageId) : true
  );
  const [dutyCounsel, setDutyCounsel] = useState(location?.state?.dutyCounsel || file.stageId === STAGE_DUTY_COUNSEL);
  const [showAddIndividualForm, setShowAddIndividualForm] = useState(false);
  const [showCancelCounselRequestModal, setShowCancelCounselRequestModal] = useState(false);
  const [moveToApplicationModal, setMoveToApplicationModal] = useState(false);
  const [submitCounselRequestModal, setSubmitCounselRequestModal] = useState(false);

  useEffect(() => {
    if (location.state?.createIndividualId) {
      fileContext.setClientId(location.state?.createIndividualId);
      fileContext.setFile((f) => ({ ...f, clientId: location.state?.createIndividualId || '' }));
      history.replace(history.location.pathname, { createIndividualId: undefined });
    }

    if (location?.state?.dutyCounsel) {
      fileContext.setFileTypeId('f0ec6294-a2a0-4e27-b4ae-21015d4ea029');
      setDutyCounsel(location?.state?.dutyCounsel || file.stageId === STAGE_DUTY_COUNSEL);
    }

    if (counselRequest && myUserContext.isLawyer && !params.fileId) {
      fileContext.setFile((f) => ({ ...f, lawyerId: getUserId() }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsEditingAllowed(
      myUserContext.isManagement ? false : params.fileId ? editableStages.includes(file.stageId) : true
    );
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.fileId, file.stageId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [file.fileId]);

  useEffect(() => {
    if (location?.state?.dutyCounsel) {
      fileContext.setDutyCounselAppearances((s) => [
        ...s,
        {
          dutyCounselAppearanceId: Math.random().toString(),
          dutyCounselCourtTypeId: undefined,
          dutyCounselAppearanceDate: undefined,
          lawyerId: '',
        } as IDutyCounselAppearance,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabledSelectStyles = {
    control: (styles: any, state: any) => {
      return {
        ...styles,
        borderColor: '#ced4da',
      };
    },
    singleValue: (styles: any, state: any) => {
      return {
        ...styles,
        color: '#495057',
      };
    },
  };

  const isEditingAllowedOnlySuperAdminIntake = () => {
    return (
      isEditingAllowed &&
      ((!myUserContext.isLegalAssistant && !myUserContext.isLawyer) ||
        !file.stageId ||
        editableStages.includes(file?.stageId))
    );
  };

  const isEditingAllowedNextAppearance =
    isEditingAllowedOnlySuperAdminIntake() ||
    (isEditingAllowed &&
      (myUserContext.isLegalAssistant || myUserContext.isLawyer) &&
      file.stageId === STAGE_ASSIGNED_TO_LAWYER);

  const saveDutyCounselAppearances = (fileId: string) => {
    const data = { fileId, dutyCounselAppearances: fileContext.dutyCounselAppearances };
    return authAxios.post('/api/files/admin/update-duty-counsel-appearances', data);
  };

  const saveDutyCounsel = () => {
    if (params.fileId) {
      if (
        !!fileContext.clientId &&
        !!fileContext.applicationTypeId &&
        !fileContext.dutyCounselAppearances.some(
          (e) => !e.dutyCounselAppearanceDate || !e.dutyCounselCourtTypeId || !e.lawyerId
        )
      ) {
        const data = fileContext.getEditData();
        authAxios.post('/api/files/admin/update', data).then((response) => {
          if (response.data.status === 'OK') {
            fileContext.setFile((f) => ({
              ...f,
              appDate: fileContext.appDate.getTime(),
              appDateFormatted: formatDate(new Date(fileContext.appDate.getTime())),
            }));
            notifySuccess('File updated.');
            setIsDirty(false);
          } else {
            notifyError(response.data.errorMessage);
          }
        });
        saveDutyCounselAppearances(params.fileId).then();
      } else {
        setTrySubmit(true);
      }
    } else {
      if (
        !!fileContext.clientId &&
        !!fileContext.applicationTypeId &&
        !fileContext.dutyCounselAppearances.some(
          (e) => !e.dutyCounselAppearanceDate || !e.dutyCounselCourtTypeId || !e.lawyerId
        )
      ) {
        const data = {
          ...fileContext.getCreateData(),
          dutyCounselAppearances: fileContext.dutyCounselAppearances,
        };
        authAxios.post('/api/files/admin/create-duty-counsel', data).then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            fileContext.setFile({ ...response.data.file, stageId: STAGE_DUTY_COUNSEL });
            const fileId = response.data.file.fileId;
            notifySuccess('File created.');
            history.replace({ pathname: '/files/' + fileId, state: { overrideReload: true } });
          } else {
            notifyError(response.data.errorMessage);
          }
        });
      } else {
        setTrySubmit(true);
      }
    }
  };

  const saveCounselRequest = () => {
    if (!!fileContext.clientId && !!fileContext.applicationTypeId && !!fileContext.file.lawyerId) {
      if (params.fileId) {
        const data = fileContext.getEditData();
        const lawyerData = { fileId: fileContext.file.fileId, lawyerId: fileContext.file.lawyerId };
        authAxios.post('/api/files/admin/set-lawyer', lawyerData);
        authAxios.post('/api/files/admin/update', data).then((response) => {
          if (response.data.status === 'OK') {
            fileContext.setFile((f) => ({
              ...f,
              appDate: fileContext.appDate.getTime(),
              appDateFormatted: formatDate(new Date(fileContext.appDate.getTime())),
            }));
            setIsDirty(false);
            notifySuccess('File updated.');
          } else {
            notifyError(response.data.errorMessage);
          }
        });
      } else {
        const lawyerId = fileContext.file.lawyerId;
        const data = fileContext.getCreateData();
        const counselRequestCreate = { ...data, lawyerId };

        authAxios.post('/api/files/admin/create-counsel-request', counselRequestCreate).then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            const file = response.data.file;
            setFile({ ...file, lawyerId });
            notifySuccess('Counsel request created.');
            history.push({ pathname: '/files/' + file.fileId, state: { overrideReload: true } });
          } else {
            notifyError(response.data.errorMessage);
          }
        });
      }
    } else {
      setTrySave(true);
    }
  };

  const cancelCounselRequest = () => {
    const stageId = STAGE_CANCELLED_COUNSEL_REQUEST;
    if (params.fileId) {
      const data = { fileId: params.fileId, stageId };
      authAxios.post('/api/files/admin/start-stage', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('File has been cancelled.');
          setFile((f) => ({ ...f, stageId: stageId }));
        } else {
          notifyError(response.data.status);
        }
      });
    }
  };

  const saveFile = () => {
    if (!!fileContext.clientId && !!fileContext.applicationTypeId) {
      if (params.fileId) {
        const data = fileContext.getEditData();

        const contributionAgreementData = {
          fileId: fileContext.file.fileId,
          contributionAgreement: fileContext.file.contributionAgreement,
          contributionAgreementInitialPayment: fileContext.file.contributionAgreementInitialPayment,
          contributionAgreementMonthlyPayment: fileContext.file.contributionAgreementMonthlyPayment,
        };
        authAxios.post('/api/files/admin/update-contribution-agreement', contributionAgreementData);

        authAxios.post('/api/files/admin/update', data).then((response) => {
          if (response.data.status === 'OK') {
            fileContext.setFile((f) => ({
              ...f,
              appDate: fileContext.appDate.getTime(),
              appDateFormatted: formatDate(new Date(fileContext.appDate.getTime())),
            }));
            setIsDirty(false);
            notifySuccess('File updated.');
            if (!editableStages.includes(file.stageId)) {
              setIsEditingAllowed(false);
            }
          } else {
            notifyError(response.data.errorMessage);
          }
        });

        if (
          file.reasonId !== fileContext.originalReasonId ||
          file.reasonRejected !== fileContext.originalReasonRejected
        ) {
          authAxios
            .post('/api/files/admin/update-reason-rejected', {
              fileId: file.fileId,
              reasonId: file.reasonId,
              reasonRejected: file.reasonRejected,
            })
            .then((response) => {
              if (response.data.status === 'OK') {
                setIsDirty(false);
                fileContext.setOriginalReasonId(file.reasonId);
                fileContext.setOriginalReasonRejected(file.reasonRejected);
              }
            });
        }

        if (
          file.reasonIdBoardRejected !== fileContext.originalReasonIdBoardRejected ||
          file.reasonBoardRejected !== fileContext.originalReasonBoardRejected
        ) {
          authAxios
            .post('/api/files/admin/update-reason-board-rejected', {
              fileId: file.fileId,
              reasonIdBoardRejected: file.reasonIdBoardRejected,
              reasonBoardRejected: file.reasonBoardRejected,
            })
            .then((response) => {
              if (response.data.status === 'OK') {
                setIsDirty(false);
                fileContext.setOriginalReasonIdBoardRejected(file.reasonIdBoardRejected);
                fileContext.setOriginalReasonBoardRejected(file.reasonBoardRejected);
              }
            });
        }

        if (
          fileContext.lawOfficeNumber &&
          fileContext.lawOfficeNumber !== fileContext.file.lawOfficeNumber &&
          myUserContext.isLegalAssistant
        ) {
          const lawOfficeNumberData = {
            fileId: fileContext.file.fileId,
            lawOfficeNumber: !Number.isNaN(Number(fileContext.lawOfficeNumber))
              ? Number(fileContext.lawOfficeNumber)
              : Number(fileContext.file.lawOfficeNumber),
          };
          authAxios.post('/api/files/admin/update-law-office-number', lawOfficeNumberData);
        }
      } else {
        const data = fileContext.getCreateData();

        authAxios.post('/api/files/admin/create', data).then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            const file = response.data.file;
            setFile(file);
            filesContext.dispatch({ type: 'FILE_ACTION_CREATE_FILE', data: response.data.fileBasic });
            notifySuccess('File created.');
            history.push({ pathname: '/files/' + file.fileId, state: { overrideReload: true } });
          } else {
            notifyError(response.data.errorMessage);
          }
        });
      }
    } else {
      setTrySave(true);
    }
  };

  const resolveReminder = (reminderId: string) => {
    authAxios
      .post('/api/files/resolve-reminder', { fileId: params.fileId, reminderId: reminderId })
      .then((response) => {
        if (response.data.status === 'OK') {
          fileContext.setActiveOnscreenReminders((r) => r.filter((reminder) => reminder.reminderId !== reminderId));
        }
      });
  };

  const lawyerLabel = () => {
    const lawyer = users.find((user: IUser) => user.userId === file.lawyerId);
    if (lawyer) {
      return lawyerSelectValue(lawyer, domainContext.offices);
    } else {
      return 'Lawyer Not Assigned';
    }
  };

  const moveToApplication = () => {
    const stageId = STAGE_PENDING;
    if (params.fileId) {
      const data = { fileId: params.fileId, stageId };
      authAxios.post('/api/files/admin/start-stage', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('File stage has been updated.');
          setDutyCounsel(false);
          setFile((f) => ({ ...f, stageId: stageId }));
        } else {
          notifyError(response.data.status);
        }
      });
    }
  };

  const submitCounselRequest = () => {
    const stageId = STAGE_COUNSEL_REQUEST;
    if (params.fileId) {
      const data = { fileId: params.fileId, stageId };
      authAxios.post('/api/files/admin/start-stage', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('File stage has been updated.');

          setFile((f) => ({ ...f, stageId: stageId }));
        } else {
          notifyError(response.data.status);
        }
      });
    }
  };

  const createIndividualAction = (individual: IIndividual) => {
    fileContext.setClientId(individual.individualId);
    setFile((f) => ({ ...f, clientId: individual.individualId }));
  };

  const Option = (props: OptionProps<IIndividual, boolean>) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <div>{clientSelectValue(data)}</div>
      </components.Option>
    );
  };

  const SingleValue = (props: SingleValueProps<IIndividual>) => {
    const { data } = props;
    return <components.SingleValue {...props}>{clientSelectValue(data)}</components.SingleValue>;
  };

  return (
    <div>
      <LeavePageConfirm isDirty={isDirty} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
          <Row className={'flex-fill'}>
            <Col md={3} xl={2}>
              <NavbarBrand>
                {params.fileId
                  ? 'Edit File Details'
                  : dutyCounsel
                  ? 'Add New Duty Counsel'
                  : counselRequest
                  ? 'Add Counsel Request'
                  : 'Add New File'}
              </NavbarBrand>
            </Col>
            {params.fileId && file.stageId === STAGE_PENDING && !file.isLockedDown && (
              <Col className={'d-flex'}>
                <Button color='success' className={'ml-auto'} onClick={() => saveFile()} disabled={individualIsDirty}>
                  Save Application
                </Button>
              </Col>
            )}
            {!isEditingAllowed && file.stageId !== STAGE_STOPPED && !file.isLockedDown ? (
              <Col className={'d-flex'}>
                <Button color='primary' className={'ml-auto'} onClick={() => setIsEditingAllowed(true)}>
                  Edit Details
                </Button>
              </Col>
            ) : (
              params.fileId &&
              file.stageId !== STAGE_PENDING &&
              file.stageId !== STAGE_STOPPED &&
              file.stageId !== STAGE_BOARD_REVIEW &&
              file.stageId !== STAGE_COUNSEL_REQUEST_DRAFT &&
              file.stageId !== STAGE_DUTY_COUNSEL &&
              !file.isLockedDown && (
                <Col className={'d-flex'}>
                  <Button
                    color='success'
                    className={'ml-auto'}
                    onClick={() => {
                      saveFile();
                    }}
                    disabled={individualIsDirty}
                  >
                    Save Details
                  </Button>
                </Col>
              )
            )}
            {dutyCounsel && !myUserContext.isManagement && (
              <Col className={'d-flex'}>
                <Button className={'ml-auto grey-button'} onClick={() => history.push('/files')}>
                  Cancel
                </Button>
                {params.fileId && (
                  <Button color='success' className={'ml-2'} onClick={() => setMoveToApplicationModal(true)}>
                    Move to Application
                  </Button>
                )}
                <Button color='success' className={'ml-2'} onClick={() => saveDutyCounsel()}>
                  Save Duty Counsel
                </Button>
              </Col>
            )}
            {counselRequest && !myUserContext.isManagement && (
              <Col className={'d-flex'}>
                <Button
                  className={'ml-auto'}
                  color={'secondary'}
                  onClick={() => setShowCancelCounselRequestModal(true)}
                >
                  Cancel Counsel Request
                </Button>
                {params.fileId && (
                  <Button color='success' className={'ml-2'} onClick={() => setSubmitCounselRequestModal(true)}>
                    Submit Counsel Request
                  </Button>
                )}
                <Button color='success' className={'ml-2'} onClick={() => saveCounselRequest()}>
                  Save Counsel Request
                </Button>
              </Col>
            )}

            {!params.fileId && !dutyCounsel && !counselRequest && (
              <Col className={'d-flex'}>
                <Button color={'light'} className={'ml-auto'} onClick={() => history.push('/files')}>
                  Cancel
                </Button>
                <Button color='success' className={'ml-2'} onClick={() => saveFile()} disabled={individualIsDirty}>
                  Save Application
                </Button>
              </Col>
            )}
          </Row>
        </Navbar>
        <Container fluid={true} className={'my-3'}>
          {fileContext.activeOnscreenReminders.map((r) => (
            <Alert
              color={
                r.reminderTypeId === REMINDER_TYPE_FILE_EXPIRY || r.reminderTypeId === REMINDER_TYPE_EXTENSION_REQUESTED
                  ? 'danger'
                  : 'success'
              }
              isOpen={true}
              key={r.reminderId}
            >
              <div className={'d-flex'}>
                NOTIFICATION: {r.text}
                {r.reminderTypeId !== REMINDER_TYPE_FILE_EXPIRY && (
                  <Button className={'ml-auto p-0'} color={'link'} onClick={() => resolveReminder(r.reminderId)}>
                    Resolve
                  </Button>
                )}
              </div>
            </Alert>
          ))}
          <Row>
            <Col lg={5}>
              <Card className={'mb-3'}>
                <CardBody>
                  {file.fileId === undefined && (
                    <>
                      <RequiredLabel for={'client'} required={fileContext.clientId === undefined}>
                        Select Client
                      </RequiredLabel>
                      <Select
                        inputValue={clientInputValue}
                        onInputChange={(e) => setClientInputValue(e)}
                        className={'flex-fill'}
                        classNamePrefix={'people-select'}
                        name={'clientId'}
                        options={
                          !!clientInputValue && clientInputValue.length >= 3
                            ? individuals.filter(
                                (e) => e.searchString?.includes(clientInputValue.toUpperCase()) && e.active
                              )
                            : []
                        }
                        filterOption={createFilter({ ignoreAccents: false })}
                        value={individuals.find((i: IIndividual) => i.individualId === fileContext.clientId)}
                        onChange={(value: any) => {
                          fileContext.setClientId(value?.individualId || undefined);
                          setShowAddIndividualForm(false);
                          setIsDirty(true);
                        }}
                        isDisabled={params.fileId !== undefined}
                        getOptionValue={(option: IIndividual) => option.individualId}
                        getOptionLabel={(o: IIndividual) => `${o.searchString}`}
                        isClearable={true}
                        components={{ SingleValue, Option }}
                        noOptionsMessage={() =>
                          !!clientInputValue && clientInputValue.length >= 3
                            ? 'No individuals match your search. Add a new individual or try again.'
                            : 'Enter at least 3 characters to search.'
                        }
                        styles={{
                          singleValue: (base) => ({
                            ...base,
                            position: 'relative',
                            top: 0,
                            transform: 'translateY(0)',
                            height: '100%',
                            padding: '0.25em 0',
                          }),
                        }}
                      />
                      {trySubmit && fileContext.clientId === '' && (
                        <span
                          style={{
                            fontSize: 13,
                            color: '#B22222',
                          }}
                        >
                          This field is mandatory
                        </span>
                      )}
                      {trySave && fileContext.clientId === '' && (
                        <span style={{ fontSize: 13, color: '#B22222' }}>
                          This field is mandatory to save application
                        </span>
                      )}
                      <FormFeedback>This field is mandatory</FormFeedback>
                    </>
                  )}
                  {fileContext.clientId === undefined && !myUserContext.isLawyer && !myUserContext.isLegalAssistant && (
                    <p className={'text-muted mb-3 mt-3'}>
                      or{' '}
                      <Button
                        color='link'
                        onClick={() => setShowAddIndividualForm(true)}
                        aria-label={'Add New Client'}
                        className={'p-0 border-0 align-baseline'}
                      >
                        Add New Individual
                      </Button>
                    </p>
                  )}
                  {fileContext.clientId && (
                    <>
                      <div className={'d-md-flex'}>
                        <FormGroup className={'w-100 mr-3'}>
                          <RequiredLabel for={'application-date'} required={fileContext.appDate === undefined}>
                            Application Date
                          </RequiredLabel>
                          <DatePicker
                            selected={fileContext.appDate}
                            name={'application-date'}
                            onChange={(date) => {
                              if (date) {
                                fileContext.setAppDate(date);
                                const newDate = new Date(date);
                                newDate.setMonth(newDate.getMonth() + 6);
                                fileContext.setExpiryDate(newDate);
                                setIsDirty(true);
                              }
                            }}
                            peekNextMonth
                            showMonthDropdown
                            disabled={!isEditingAllowedOnlySuperAdminIntake()}
                            showYearDropdown
                            dateFormat='MMM dd, yyyy'
                            dropdownMode='select'
                            className={
                              isEditingAllowedOnlySuperAdminIntake()
                                ? 'date-picker rounded mb-0'
                                : 'date-picker-disabled rounded mb-0'
                            }
                            placeholderText={'MMM dd, yyyy'}
                          />
                          {trySubmit && fileContext.appDate === undefined && (
                            <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                          )}
                        </FormGroup>
                        <FormGroup className={'w-100 mr-3'}>
                          <RequiredLabel for={'applicationType'} required={!fileContext.applicationTypeId}>
                            Application Type
                          </RequiredLabel>
                          <Select
                            styles={disabledSelectStyles}
                            name={'applicationTypeId'}
                            options={applicationTypes.filter((at) => at.active)}
                            value={applicationTypes.find(
                              (ft: IApplicationType) => ft.applicationTypeId === fileContext.applicationTypeId
                            )}
                            onChange={(value: any) => {
                              fileContext.setApplicationTypeId(value?.applicationTypeId);
                              setIsDirty(true);
                            }}
                            getOptionValue={(option: IApplicationType) => option.applicationTypeId}
                            getOptionLabel={(option: IApplicationType) => option.name}
                            isDisabled={!isEditingAllowedOnlySuperAdminIntake()}
                            isClearable={true}
                          />
                          {(trySubmit || trySave) && !fileContext.applicationTypeId && (
                            <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                          )}
                          <FormFeedback>This field is mandatory</FormFeedback>
                        </FormGroup>
                        <FormGroup className={'w-100'}>
                          <RequiredLabel for={'fileType'} required={!fileContext.fileTypeId}>
                            File Type
                          </RequiredLabel>
                          <Select
                            styles={disabledSelectStyles}
                            name={'fileTypeId'}
                            options={fileTypes}
                            value={fileTypes.find((ft: IFileType) => ft.fileTypeId === fileContext.fileTypeId)}
                            onChange={(value: any) => {
                              fileContext.setFileTypeId(value?.fileTypeId || '');
                              setIsDirty(true);
                            }}
                            getOptionValue={(option: IFileType) => option.fileTypeId}
                            getOptionLabel={(option: IFileType) => option.name}
                            isDisabled={!isEditingAllowedOnlySuperAdminIntake()}
                            isClearable={true}
                          />
                          {(trySubmit || trySave) && !fileContext.fileTypeId && (
                            <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                          )}
                          <FormFeedback>This field is mandatory</FormFeedback>
                        </FormGroup>
                      </div>
                      <div className={'d-md-flex'}>
                        <FormGroup className={'w-100 mr-3'}>
                          <Label>Next Appearance Date</Label>
                          <DatePicker
                            selected={fileContext.nextAppearanceDate}
                            onChange={(date) => {
                              if (date) {
                                if (
                                  date.getHours() < 9 ||
                                  date.getHours() > 17 ||
                                  (date.getHours() === 17 && date.getMinutes() > 0)
                                ) {
                                  fileContext.setNextAppearanceDate(setHours(setMinutes(date, 0), 9));
                                } else {
                                  fileContext.setNextAppearanceDate(date);
                                }
                                setIsDirty(true);
                              } else {
                                fileContext.setNextAppearanceDate(undefined);
                                setIsDirty(true);
                              }
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            minTime={
                              fileContext.nextAppearanceDate
                                ? setHours(setMinutes(fileContext.nextAppearanceDate, 0), 9)
                                : setHours(setMinutes(new Date(), 0), 9)
                            }
                            maxTime={
                              fileContext.nextAppearanceDate
                                ? setHours(setMinutes(fileContext.nextAppearanceDate, 0), 17)
                                : setHours(setMinutes(new Date(), 0), 17)
                            }
                            minDate={
                              fileContext.nextAppearanceDate ? undefined : new Date(new Date().getFullYear() - 2, 0, 1)
                            }
                            maxDate={
                              fileContext.nextAppearanceDate
                                ? undefined
                                : new Date(new Date().getFullYear() + 2, 11, 31)
                            }
                            disabled={!isEditingAllowedNextAppearance}
                            dateFormat='MMM dd, yyyy hh:mm aa'
                            showTimeSelect={true}
                            isClearable={true}
                            dropdownMode='select'
                            className={
                              isEditingAllowedNextAppearance
                                ? 'date-picker rounded mb-0'
                                : 'date-picker-disabled rounded mb-0'
                            }
                            placeholderText={isEditingAllowedNextAppearance ? 'MMM dd, yyyy hh:mm aa' : ''}
                          />
                        </FormGroup>
                        <FormGroup className={'w-100'}>
                          <Label for={'courtFileNumber'}>Court File Number</Label>
                          <Input
                            value={fileContext.courtFileNumber}
                            type={'text'}
                            autoComplete={'off'}
                            disabled={!isEditingAllowedOnlySuperAdminIntake()}
                            name={'Court File Number'}
                            onChange={(e: any) => {
                              fileContext.setCourtFileNumber(e.target.value);
                              setIsDirty(true);
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className={'d-md-flex'}>
                        <FormGroup className={'w-100'}>
                          <Label for={'circuitLocation'}>Circuit Location</Label>
                          <Select
                            styles={disabledSelectStyles}
                            name={'circuitLocation'}
                            options={circuitLocations}
                            placeholder={isEditingAllowedOnlySuperAdminIntake() ? 'Select...' : ''}
                            value={circuitLocations.find(
                              (cl: ICircuitLocation) => cl.circuitLocationId === fileContext.circuitLocationId
                            )}
                            onChange={(value: any) => {
                              fileContext.setCircuitLocationId(value?.circuitLocationId);
                              setIsDirty(true);
                            }}
                            getOptionValue={(option: ICircuitLocation) => option.circuitLocationId}
                            getOptionLabel={(option: ICircuitLocation) => option.name}
                            isDisabled={!isEditingAllowedOnlySuperAdminIntake()}
                            isClearable={true}
                          />
                        </FormGroup>
                        <FormGroup className={'w-100 ml-3'}>
                          <RequiredLabel for={'expiry-date'} required={fileContext.expiryDate === undefined}>
                            Expiry Date
                          </RequiredLabel>
                          <DatePicker
                            selected={fileContext.expiryDate}
                            name={'expiry-date'}
                            onChange={(date) => {
                              if (date) {
                                fileContext.setExpiryDate(date);
                                setIsDirty(true);
                              }
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dateFormat='MMM dd, yyyy'
                            dropdownMode='select'
                            className={
                              isEditingAllowedOnlySuperAdminIntake()
                                ? 'date-picker rounded mb-0'
                                : 'date-picker-disabled rounded mb-0'
                            }
                            placeholderText={'MMM dd, yyyy'}
                            disabled={!isEditingAllowedOnlySuperAdminIntake()}
                          />
                        </FormGroup>
                      </div>
                      {params.fileId && (
                        <div className={'d-md-flex'}>
                          <FormGroup className={'flex-fill'}>
                            <Label for={'lawyerId'}>Assigned Lawyer</Label>
                            <br />
                            <div className={'div--read-only'}>{lawyerLabel()}</div>
                          </FormGroup>
                          <FormGroup className={'flex-fill ml-3 mb-0'}>
                            <Label for={'lawOfficeNumber'}>Law Office Number</Label>
                            <Input
                              value={fileContext.lawOfficeNumber}
                              onChange={(e: any) => {
                                fileContext.setLawOfficeNumber(e.target.value.replace(regex, ''));
                                setIsDirty(true);
                              }}
                              disabled={
                                !myUserContext.isLegalAssistant || file.stageId === STAGE_CLOSED || !isEditingAllowed
                              }
                              style={{ textAlign: 'right' }}
                            />
                          </FormGroup>
                        </div>
                      )}
                      <div className={'d-md-flex'}>
                        <FormGroup
                          className={
                            fileContext.file.stageId === STAGE_ASSIGNED_TO_LAWYER &&
                            (myUserContext.isLawyer || myUserContext.isLegalAssistant)
                              ? 'mr-3'
                              : 'mr-3 mb-0'
                          }
                          style={{ zIndex: 0 }}
                        >
                          <CustomInput
                            type='checkbox'
                            id={'offSite'}
                            name={'offSite'}
                            label={'Offsite'}
                            disabled={!isEditingAllowedOnlySuperAdminIntake()}
                            checked={fileContext.offSite}
                            onChange={() => {
                              fileContext.setOffSite(!fileContext.offSite);
                              setIsDirty(true);
                            }}
                          />
                        </FormGroup>
                        <FormGroup
                          className={
                            fileContext.file.stageId === STAGE_ASSIGNED_TO_LAWYER &&
                            (myUserContext.isLawyer || myUserContext.isLegalAssistant)
                              ? 'flex-fill'
                              : 'mb-0 flex-fill'
                          }
                          style={{ zIndex: 0 }}
                        >
                          <CustomInput
                            type='checkbox'
                            id={'inCustody'}
                            name={'inCustody'}
                            label={'In Custody'}
                            disabled={!isEditingAllowedOnlySuperAdminIntake()}
                            checked={fileContext.inCustody}
                            onChange={() => {
                              fileContext.setInCustody(!fileContext.inCustody);
                              setIsDirty(true);
                            }}
                          />
                        </FormGroup>
                      </div>
                      {fileContext.file.stageId === STAGE_ASSIGNED_TO_LAWYER &&
                        (myUserContext.isLawyer || myUserContext.isLegalAssistant) && (
                          <div className={'d-md-flex'}>
                            <FormGroup className={'mb-0 mr-3'} style={{ zIndex: 0 }}>
                              <CustomInput
                                type='checkbox'
                                id={'retainerSigned'}
                                name={'retainerSigned'}
                                label={'Retainer Signed'}
                                disabled={!isEditingAllowed}
                                checked={fileContext.retainerSigned}
                                onChange={() => {
                                  fileContext.setRetainerSigned(!fileContext.retainerSigned);
                                  setIsDirty(true);
                                }}
                              />
                            </FormGroup>
                            <FormGroup className={'flex-fill mb-0'} style={{ zIndex: 0 }}>
                              <CustomInput
                                type='checkbox'
                                id={'designationFiled'}
                                name={'designationFiled'}
                                label={'Designation Filed'}
                                disabled={!isEditingAllowed}
                                checked={fileContext.designationFiled}
                                onChange={() => {
                                  fileContext.setDesignationFiled(!fileContext.designationFiled);
                                  setIsDirty(true);
                                }}
                              />
                            </FormGroup>
                          </div>
                        )}
                    </>
                  )}
                </CardBody>
              </Card>
              {(fileContext.file.stageId === STAGE_ASSIGNED_TO_LAWYER || fileContext.file.stageId === STAGE_CLOSED) && (
                <FileSupportWorkers />
              )}
              {fileContext.clientId && (
                <FileCharges
                  trySubmit={trySubmit}
                  setIsDirty={setIsDirty}
                  applicationTypeId={fileContext.applicationTypeId ? fileContext.applicationTypeId : ''}
                  isEditingAllowed={isEditingAllowed}
                />
              )}
              {fileContext.clientId && (
                <FileThirdParties
                  trySubmit={trySubmit}
                  setIsDirty={setIsDirty}
                  counselRequest={counselRequest}
                  isEditingAllowed={isEditingAllowedOnlySuperAdminIntake()}
                />
              )}
              {(fileContext.applicationTypeId === '6f088973-47f3-4b54-98ca-a35ed221a602' ||
                fileContext.applicationTypeId === 'f0c30b77-1c5c-45bf-a87f-fb981e234013') && (
                <FileChildren
                  trySubmit={trySubmit}
                  setIsDirty={setIsDirty}
                  isEditingAllowed={isEditingAllowedOnlySuperAdminIntake()}
                />
              )}
              {params.fileId &&
                file?.stageId !== STAGE_PENDING &&
                file?.stageId !== STAGE_COUNSEL_REQUEST_DRAFT &&
                file?.stageId !== STAGE_COUNSEL_REQUEST && (
                  <ContributionAgreement
                    trySubmit={trySubmit}
                    setIsDirty={setIsDirty}
                    isEditingAllowed={isEditingAllowedOnlySuperAdminIntake()}
                  />
                )}
              {counselRequest && <CounselRequestLawyer trySubmit={trySubmit || trySave} />}
            </Col>

            <Col>
              {showAddIndividualForm && fileContext.clientId === undefined && (
                <Card style={{ marginBottom: '1rem' }}>
                  <IndividualDetails
                    createCompleteAction={createIndividualAction}
                    setIndividualIsDirty={setIndividualIsDirty}
                    cancel={() => setShowAddIndividualForm(false)}
                    allowCreateFile={false}
                    hideDetails={dutyCounsel}
                  />
                </Card>
              )}
              {fileContext.clientId && file?.stageId === undefined && (
                <Card style={{ marginBottom: '1rem' }}>
                  <IndividualDetails
                    createCompleteAction={createIndividualAction}
                    individualId={fileContext.clientId}
                    setIndividualIsDirty={setIndividualIsDirty}
                    hideDetails={dutyCounsel}
                    allowCreateFile={false}
                  />
                </Card>
              )}
              {(dutyCounsel || myUserContext.isSupportWorker) && <DutyCounselAppearances trySubmit={trySubmit} />}
              {file?.stageId === STAGE_COUNSEL_REQUEST && myUserContext.isIntakeOffice && <CounselRequestActions />}
              {file?.stageId === STAGE_READY_FOR_REVIEW && myUserContext.isIntakeOffice && <ReviewActions />}
              {file?.stageId === STAGE_BOARD_REVIEW && myUserContext.isIntakeOffice && <BoardReviewActions />}
              {(file?.stageId === STAGE_APPROVED ||
                (file?.stageId === STAGE_RETURN_FOR_CONFLICT && !file.lawyerId) ||
                file?.stageId === STAGE_BOARD_APPROVED) &&
                myUserContext.isIntakeOffice && (
                  <>
                    <SelectLawyerActions />
                    <FileConflictCheck />
                  </>
                )}
              {(file?.stageId === STAGE_COUNSEL_REQUEST_DRAFT || file?.stageId === STAGE_COUNSEL_REQUEST) &&
                file?.reasonCounselRequestRejected &&
                file?.reasonCounselRequestRejected.length > 0 && <FileCounselRequestRejectedReasons />}

              {file?.stageId === STAGE_RETURN_FOR_CONFLICT && myUserContext.isIntakeOffice && file.lawyerId && (
                <>
                  <ReturnForConflictActions />
                </>
              )}
              {params.fileId && file.stageId === STAGE_PENDING && myUserContext.isIntakeOffice && (
                <PendingActions isDirty={isDirty} setIsDirty={setIsDirty} setTrySubmit={setTrySubmit} />
              )}
              {params.fileId && file.stageId === STAGE_REFUSED && myUserContext.isIntakeOffice && (
                <RefusedActions isEditing={isEditingAllowed} />
              )}
              {params.fileId && file.stageId === STAGE_BOARD_REFUSED && <BoardRefused isEditing={isEditingAllowed} />}
              {params.fileId && file.stageId === STAGE_STOPPED && myUserContext.isIntakeOffice && <StoppedActions />}
              {file?.stageId === STAGE_LAWYER_REQUESTED && (
                <>
                  {!myUserContext.isManagement && <LawyerRequestActions />}
                  <FileConflictCheck />
                </>
              )}
              {myUserContext.isSupportWorker && (
                <Row className={'mt-4'}>
                  <Col>
                    <RecordHours />
                  </Col>
                </Row>
              )}
              {(file?.stageId === STAGE_ASSIGNED_TO_LAWYER || file?.stageId === STAGE_CLOSED) &&
                !myUserContext.isSupportWorker && (
                  <Col>
                    {!myUserContext.isManagement && (
                      <Row className={'mb-4'}>
                        <Col className={'pl-0'}>
                          <CloseFile />
                        </Col>
                        {file?.stageId === STAGE_ASSIGNED_TO_LAWYER && (
                          <Col className={'pr-0'}>
                            <RemoveAssignedLawyer />
                          </Col>
                        )}
                      </Row>
                    )}
                    <Row>
                      <RecordHours />
                    </Row>
                    {file?.stageId === STAGE_ASSIGNED_TO_LAWYER &&
                      !myUserContext.isSuperAdmin &&
                      !myUserContext.isManagement && (
                        <Row className={'mt-4'}>
                          <ExtensionRequest />
                        </Row>
                      )}
                    {myUserContext.isSuperAdmin && file.extensionRequests.length > 0 && (
                      <Row className={'mt-4'}>
                        <ExtensionRequestAction />
                      </Row>
                    )}
                  </Col>
                )}
              {file.stageId &&
                file?.stageId !== STAGE_PENDING &&
                file?.stageId !== STAGE_READY_FOR_REVIEW &&
                !myUserContext.isLegalAssistant &&
                !myUserContext.isLawyer &&
                !myUserContext.isSupportWorker && <FileLawyerHistory />}
              {file.stageId &&
                file.stageId === STAGE_RETURN_FOR_CONFLICT &&
                myUserContext.isLegalAssistant &&
                file.lawyerId && <FileReasonRejected />}
              {file.stageId && myUserContext.isSuperAdmin && <ErrorFile />}
            </Col>
          </Row>
        </Container>
      </form>

      <Modal
        isOpen={showCancelCounselRequestModal}
        toggle={() => setShowCancelCounselRequestModal(!showCancelCounselRequestModal)}
        className='logout-modal'
      >
        <ModalHeader toggle={() => setShowCancelCounselRequestModal(!showCancelCounselRequestModal)}>
          Cancel Counsel Request
        </ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>Are you sure you want to cancel this counsel request? This cannot be undone.</div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='success'
            onClick={() => {
              cancelCounselRequest();
              setShowCancelCounselRequestModal(false);
              history.push({ pathname: '/files', state: { deletedFileId: params.fileId } });
            }}
          >
            Yes, Cancel Request
          </Button>
          <Button color={'light'} onClick={() => setShowCancelCounselRequestModal(false)}>
            No
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={moveToApplicationModal}
        toggle={() => setMoveToApplicationModal(!moveToApplicationModal)}
        className='logout-modal'
      >
        <ModalHeader toggle={() => setMoveToApplicationModal(!moveToApplicationModal)}>Move To Application</ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>Are you sure you want to move this duty counsel file to an application?</div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='success'
            onClick={() => {
              moveToApplication();
              setMoveToApplicationModal(false);
            }}
          >
            Move To Application
          </Button>
          <Button color={'light'} onClick={() => setMoveToApplicationModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={submitCounselRequestModal}
        toggle={() => setSubmitCounselRequestModal((e) => !e)}
        className='logout-modal'
      >
        <ModalHeader toggle={() => setSubmitCounselRequestModal((e) => !e)}>Submit Counsel Request</ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>Are you sure you want to submit this counsel request to intake?</div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='success'
            onClick={() => {
              submitCounselRequest();
              setSubmitCounselRequestModal(false);
            }}
          >
            Submit Counsel Request
          </Button>
          <Button color={'light'} onClick={() => setSubmitCounselRequestModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
