import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import { formatDate, formatDateForServer } from '../../../formatters/DateTimeFormatter';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { FileContext, IHoursRecord } from '../FileContext';
import { IUser } from '../../Users/UsersTable';
import { authAxios } from '../../../services/AxiosService';
import { notifyError, notifySuccess } from '../../../services/ToastService';
import { PeopleContext } from '../../../contexts/PeopleContext';
import Select, { components, OptionProps, SingleValueProps } from 'react-select';
import { DomainContext, ITimeTrackingActivity, IOffice } from '../../../contexts/DomainContext';
import { lawyerSelectValue } from '../../../formatters/PeopleFormatter';
import DatePicker from 'react-datepicker';
import { FaTimes } from 'react-icons/fa';
import { MyUserContext } from '../../../contexts/MyUserContext';

export const RecordHours = () => {
  const params = useParams<{ fileId: string }>();
  const peopleContext = useContext(PeopleContext);
  const fileContext = useContext(FileContext);
  const domainContext = useContext(DomainContext);
  const myUserContext = useContext(MyUserContext);
  const offices = domainContext.offices;
  const timeTrackingActivities = domainContext.timeTrackingActivities.filter((t) => t.activityType === 'File');
  const file = fileContext.file;
  const [totalHours, setTotalHours] = useState(0);
  const [hours, setHours] = useState<number>();
  const [userId, setUserId] = useState<string | undefined>(file.lawyerId);
  const [date, setDate] = useState<Date | undefined>();
  const [timeTrackingActivityId, setTimeTrackingActivityId] = useState<string | undefined | null>(undefined);
  const [tryAddHours, setTryAddHours] = useState(false);

  useEffect(() => {
    let total = 0;
    fileContext.hoursRecords?.forEach((l) => {
      total = total + l.hours;
    });
    setTotalHours(total);
  }, [fileContext.hoursRecords]);

  const recordHours = () => {
    if (hours && userId && date && timeTrackingActivityId) {
      authAxios
        .post('/api/files/admin/record-hours', {
          fileId: params.fileId,
          hours,
          userId,
          date: formatDateForServer(date),
          timeTrackingActivityId: timeTrackingActivityId,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            notifySuccess('Hours updated.');
            fileContext.setHoursRecords((s) => [
              ...s,
              {
                hoursRecordId: response.data.hourRecordId,
                userId: userId,
                timeTrackingActivityId: timeTrackingActivityId,
                hours: hours,
                date: date?.getTime(),
                timestamp: new Date().getTime(),
              } as IHoursRecord,
            ]);
            setTimeTrackingActivityId(undefined);
            setDate(undefined);
            setTryAddHours(false);
            setHours(undefined);
          } else {
            notifyError(response.data.errorMessage);
          }
        });
    } else {
      setTryAddHours(true);
    }
  };

  const deleteHoursRecord = (hoursRecordId: string) => {
    authAxios
      .post('/api/files/admin/delete-hour-record', {
        fileId: params.fileId,
        hoursRecordId,
      })
      .then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('Hours deleted.');
          fileContext.setHoursRecords((s) => s.filter((e) => e.hoursRecordId !== hoursRecordId));
        } else {
          notifyError(response.data.errorMessage);
        }
      });
  };

  const LawyerOption = (props: OptionProps<IUser, boolean>) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <div>{lawyerSelectValue(data, domainContext.offices)}</div>
      </components.Option>
    );
  };

  const LawyerSingleValue = (props: SingleValueProps<IUser>) => {
    const { data } = props;
    return <components.SingleValue {...props}>{lawyerSelectValue(data, domainContext.offices)}</components.SingleValue>;
  };

  return (
    <Card className={'bg-primary-light'} style={{ width: '100%' }}>
      <CardHeader>Lawyer Hours</CardHeader>
      {!myUserContext.isSupportWorker && (
        <CardBody>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label for={'lawyerId'}>Lawyer</Label>
                <Select
                  classNamePrefix={'people-select'}
                  id={'lawyerId'}
                  name={'lawyerId'}
                  options={
                    peopleContext.lawyers.sort((a: IUser, b: IUser) =>
                      a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase())
                    ) as IUser[]
                  }
                  value={peopleContext.lawyers.find((i: IUser) => i.userId === userId)}
                  onChange={(value: any) => setUserId(value?.userId || '')}
                  getOptionValue={(option: IUser) => option.userId}
                  getOptionLabel={(option: IUser) =>
                    option.lastName.toUpperCase() +
                    ', ' +
                    option.firstName +
                    ' (' +
                    offices.find((o: IOffice) => o.officeId === option.officeId)?.name +
                    ')'
                  }
                  components={{ SingleValue: LawyerSingleValue, Option: LawyerOption }}
                  styles={{
                    singleValue: (base) => ({
                      ...base,
                      position: 'relative',
                      top: 0,
                      transform: 'translateY(0)',
                      height: '100%',
                      padding: '0.25em 0',
                    }),
                  }}
                  isClearable={true}
                  menuPlacement={'auto'}
                  isDisabled={file.isLockedDown}
                />
                {tryAddHours && !userId && <FormText color={'danger'}>This field is mandatory</FormText>}
              </FormGroup>
            </Col>
            <Col sm={4}>
              <FormGroup>
                <Label for={'timeTrackingActivityId'}>Activity</Label>
                <Select
                  id={'timeTrackingActivityId'}
                  name={'timeTrackingActivityId'}
                  options={timeTrackingActivities.filter((t) => t.active)}
                  value={
                    timeTrackingActivities.find((lt) => lt.timeTrackingActivityId === timeTrackingActivityId) ||
                    //null needed to clear the display value
                    (null as any)
                  }
                  onChange={(value: any) => setTimeTrackingActivityId(value?.timeTrackingActivityId || '')}
                  getOptionValue={(option: ITimeTrackingActivity) => option.timeTrackingActivityId}
                  getOptionLabel={(option: ITimeTrackingActivity) => option.name}
                  isClearable={true}
                  menuPlacement={'auto'}
                  isDisabled={file.isLockedDown}
                />
                {tryAddHours && !timeTrackingActivityId && (
                  <FormText color={'danger'}>This field is mandatory</FormText>
                )}
              </FormGroup>
            </Col>
            <Col sm={3}>
              <FormGroup>
                <Label>Date</Label>
                <DatePicker
                  selected={date}
                  onChange={(date) => {
                    if (date) {
                      setDate(date);
                    }
                  }}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dateFormat='MMM dd, yyyy'
                  dropdownMode='select'
                  className='date-picker rounded mb-0'
                  placeholderText={'Select...'}
                  disabled={file.isLockedDown}
                />
                {tryAddHours && date === undefined && <FormText color={'danger'}>This field is mandatory</FormText>}
              </FormGroup>
            </Col>
            <Col sm={3}>
              <FormGroup>
                <Label>Hours</Label>
                <Input
                  type='number'
                  name='userHours'
                  id='exampleNumber'
                  value={hours ? hours : ''}
                  onChange={(e: any) => {
                    const val = e.target.value;
                    setHours(val ? Number(parseFloat(val).toFixed(2)) : 0);
                  }}
                  disabled={file.isLockedDown}
                />
                {tryAddHours && !hours && <FormText color={'danger'}>This field is mandatory</FormText>}
              </FormGroup>
            </Col>
            <Col sm={2}>
              <FormGroup>
                <Button
                  style={{ marginTop: '27px' }}
                  color={'primary'}
                  onClick={() => recordHours()}
                  disabled={file.isLockedDown}
                >
                  Add Hours
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      )}
      {fileContext.hoursRecords?.length > 0 && (
        <ListGroup flush={true} style={{ borderTop: '1px solid lightgrey' }}>
          <ListGroupItem className={'grey-background'}>
            <Row>
              <Col xs={3}>
                <Label style={{ fontSize: '1rem' }}>Date</Label>
              </Col>
              <Col xs={4}>
                <Label style={{ fontSize: '1rem' }}>Lawyer</Label>
              </Col>
              <Col xs={3}>
                <Label style={{ fontSize: '1rem' }}>Time Type</Label>
              </Col>
              <Col xs={1} className={'d-flex justify-content-end'}>
                <Label style={{ fontSize: '1rem' }}>Hours</Label>
              </Col>
              {!myUserContext.isSupportWorker && <Col xs={1}> </Col>}
            </Row>
          </ListGroupItem>
          {fileContext.hoursRecords.map((entry) => {
            return (
              <ListGroupItem key={entry.hoursRecordId}>
                <Row className={'align-items-center'}>
                  <Col xs={3}>{formatDate(new Date(entry.date))}</Col>
                  <Col xs={4}>
                    {peopleContext.lawyers.find((l) => l.userId === (entry.userId ? entry.userId : ''))
                      ? peopleContext.lawyers
                          .find((l) => l.userId === (entry.userId ? entry.userId : ''))
                          ?.lastName.toUpperCase() +
                        ', ' +
                        peopleContext.lawyers.find((l) => l.userId === (entry.userId ? entry.userId : ''))?.firstName
                      : 'No Lawyer Selected'}
                  </Col>
                  <Col xs={3}>
                    {
                      timeTrackingActivities.find((lt) => lt.timeTrackingActivityId === entry.timeTrackingActivityId)
                        ?.name
                    }
                  </Col>
                  <Col xs={1} className={'d-flex justify-content-end'}>
                    {entry.hours}
                  </Col>
                  {!myUserContext.isSupportWorker && (
                    <Col xs={1}>
                      <Button
                        color={'link'}
                        disabled={file.isLockedDown}
                        className={'p-0 text-danger'}
                        onClick={() => {
                          deleteHoursRecord(entry.hoursRecordId);
                        }}
                      >
                        <FaTimes />
                      </Button>
                    </Col>
                  )}
                </Row>
              </ListGroupItem>
            );
          })}
          <ListGroupItem className={'grey-background'}>
            <Row>
              <Col xs={10}>
                <Label style={{ fontSize: '1rem' }}>TOTAL</Label>
              </Col>

              <Col xs={1} className={'d-flex justify-content-end'}>
                <Label style={{ fontSize: '1rem' }}>{totalHours}</Label>
              </Col>
              <Col xs={1}> </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      )}
    </Card>
  );
};
