import * as React from 'react';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { authAxios } from '../../services/AxiosService';
import { Column, TableInstance, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { formatDateTime } from '../../formatters/DateTimeFormatter';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { Col, Navbar, NavbarBrand, Row } from 'reactstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { TablePagination } from '../TablePagination/TablePagination';
import { useParams } from 'react-router-dom';
import { DateRangeDropdown } from '../DateRangeDropdown/DateRangeDropdown';

interface IDocumentAccessEntry {
  fileId: string;
  documentId: string;
  event: string;
  user: string;
  auditTimestamp: number;
}

export const DocumentAccessLog: React.FC = (props) => {
  const [documentEvents, setDocumentEvents] = useState<IDocumentAccessEntry[]>([]);
  const params = useParams<{ fileId: string }>();

  useEffect(() => {
    authAxios.get(`/api/files/document-access-log/${params.fileId}`).then((response) => {
      if (response.data) {
        const docs: IDocumentAccessEntry[] = response.data;
        setDocumentEvents(docs);
      }
    });
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: Column<IDocumentAccessEntry>[] = useMemo(
    () => [
      {
        Header: 'Event',
        accessor: 'event',
        width: undefined,
      },
      {
        Header: 'User',
        accessor: 'user',
        width: 225,
      },
      {
        Header: 'Date',
        id: 'auditTimestamp',
        width: 250,
        accessor: 'auditTimestamp',
        Cell: (cell: any) => {
          if (cell && cell.row.original && cell.row.original.auditTimestamp) {
            return <span>{formatDateTime(new Date(cell.row.original.auditTimestamp))}</span>;
          } else {
            return <span />;
          }
        },
        filter: (rows: any, id: any, filterValue: { startDate?: Date; endDate?: Date }) => {
          return rows.filter((row: any) => {
            if (!filterValue.startDate || !filterValue.endDate) {
              return true;
            } else {
              const rowValue = row.original['auditTimestamp'];
              return rowValue > filterValue.startDate.getTime() && rowValue < filterValue.endDate.getTime();
            }
          });
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => (
          <DateRangeDropdown
            startDate={filterValue?.startDate}
            endDate={filterValue?.endDate}
            setRange={(dates: { startDate?: Date; endDate?: Date }) => {
              setFilter(dates);
            }}
            keyPrefix={'auditTimestamp'}
          />
        ),
      },
    ],
    []
  );

  const data: IDocumentAccessEntry[] = useMemo(() => documentEvents, [documentEvents]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: TableInstance<IDocumentAccessEntry> = useTable(
    {
      columns: columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,

        sortBy: [
          {
            id: 'uploadDate',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <>
      <div>
        <form
          onSubmit={(e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }}
        >
          <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
            <Row className={'flex-fill'}>
              <Col md={3} xl={2}>
                <NavbarBrand>Document Access Log</NavbarBrand>
              </Col>
            </Row>
          </Navbar>
          <div className={'table-responsive'} style={{ minHeight: '500px' }}>
            <table className={'table table-bordered table-hover'} {...getTableProps()}>
              <thead style={{ overflow: 'visible' }}>
                {headerGroups.map((headerGroup: any, index: number) => (
                  <tr key={`files-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <th
                        key={`files-table-thead-tr-${index}-${column.id}`}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        style={{
                          width: column.width,
                          ...column.getHeaderProps(column.getSortByToggleProps()).style,
                        }}
                      >
                        {column.render('Header')}
                        <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                        <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
                {page.map((row: any) => {
                  prepareRow(row);
                  return (
                    <tr key={`files-table-tr-${row.id}`} {...row.getRowProps()}>
                      {row.cells.map((cell: any) => {
                        return (
                          <td key={`files-table-td-${cell.row.id}-${cell.column.id}`} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <TablePagination
            pageCount={pageCount}
            pageOptions={pageOptions}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageSize={pageSize}
            pageSizes={[20, 50, 100, 500]}
          />
        </form>
      </div>
    </>
  );
};
