import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Link, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import * as H from 'history';
import { Badge, Button, Col, Container, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { authAxios } from '../../services/AxiosService';
import { IFileMeta } from './NonClientFileContext';
import { FileActivityLog } from './FileActivityLog';
import { MyUserContext } from '../../contexts/MyUserContext';
import { FileNotes } from './FileNotes';
import { FileNotesDetails } from './FileNotesDetails';
import { INonClientFile, NonClientFileContext } from './NonClientFileContext';
import { NonClientFileDetails } from './NonClientFileDetails';

export const NonClientFile = () => {
  const fileContext = useContext(NonClientFileContext);
  const myUserContext = useContext(MyUserContext);
  const userRole = myUserContext.role;
  const params = useParams<{ fileId: string; tab?: string }>();
  const file = fileContext.file;
  const location = useLocation<{ overrideReload?: boolean }>();
  const history = useHistory();
  const overrideReload = location?.state?.overrideReload;

  useEffect(() => {
    if (!overrideReload) {
      authAxios.get('/api/non-client-files/' + params.fileId).then((response) => {
        const file: INonClientFile = response.data;
        fileContext.setFileAndAttributes(file);
      });
    } else {
      console.log('SKIPPING RELOAD');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.fileId]);

  useEffect(() => {
    if (!overrideReload) {
      authAxios.get('/api/files/admin/' + params.fileId + '/meta').then((response) => {
        const file: IFileMeta = response.data;
        fileContext.setFileMeta(file);
      });
    } else {
      console.log('SKIPPING RELOAD');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.fileId]);

  useEffect(() => {
    history.replace(history.location.pathname, { overrideReload: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tab: string = params.tab || 'details';

  const NavItemLink = (to: H.LocationDescriptor, active: boolean, name: any, count?: number) => {
    return (
      <NavItem>
        <NavLink tag={Link} to={to} active={active}>
          {name}
          {!!count && count > 0 && (
            <Badge color={'success'} className={'ml-2'}>
              {count}
            </Badge>
          )}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <>
      {file ? (
        <article className='page'>
          <header className='page-header d-print-none'>
            <Container fluid={true}>
              {file !== undefined && (
                <Row className={'align-items-center'}>
                  <Col>
                    <div className='d-flex flex-column py-3'>
                      <h1 className='page-header__title h3 order-2'>{file.description}</h1>
                      <h2 className='h5 order-1 text-primary m-0'>
                        <Button
                          tag={Link}
                          color={'link'}
                          className={'p-0 font-weight-bold'}
                          to={'/files/' + file.fileId}
                        >
                          {file?.lawOfficeNumber}
                        </Button>
                      </h2>
                    </div>
                  </Col>
                </Row>
              )}
              <div className='d-none d-md-block '>
                <Nav tabs={true}>
                  {NavItemLink('/non-client-files/' + params.fileId + '/details', tab === 'details', 'Details')}
                  {NavItemLink(
                    '/non-client-files/' + params.fileId + '/notes',
                    tab === 'notes',
                    'Notes',
                    fileContext.fileMeta.noteCount
                  )}
                  {(userRole === 'Intake' || userRole === 'SuperAdmin') &&
                    NavItemLink(
                      '/non-client-files/' + params.fileId + '/activity-log',
                      tab === 'activity-log',
                      'Activity Log'
                    )}
                </Nav>
              </div>
              <div className='d-block d-md-none pb-4'>
                <Nav pills={true} vertical={true}>
                  {NavItemLink('/non-client-files/' + params.fileId + '/details', tab === 'details', 'Details')}
                  {NavItemLink(
                    '/non-client-files/' + params.fileId + '/notes',
                    tab === 'notes',
                    'Notes',
                    fileContext.fileMeta.noteCount
                  )}
                  {(userRole === 'Intake' || userRole === 'SuperAdmin') &&
                    NavItemLink(
                      '/non-client-files/' + params.fileId + '/activity-log',
                      tab === 'activity-log',
                      'Activity Log'
                    )}
                </Nav>
              </div>
            </Container>
          </header>

          <Route exact={true} path='/non-client-files/:fileId/(details)?' component={NonClientFileDetails} />
          <Route exact={true} path='/non-client-files/:fileId/activity-log' component={FileActivityLog} />
          <Switch>
            <Route exact={true} path='/non-client-files/:fileId/notes'>
              <FileNotes entityType={'non-client-files'} />
            </Route>
            <Route exact={true} path='/non-client-files/:fileId/notes/add'>
              <FileNotesDetails
                createCompleteAction={() =>
                  fileContext.setFileMeta((m: IFileMeta) => ({
                    ...m,
                    noteCount: m?.noteCount ? m.noteCount + 1 : 1,
                  }))
                }
                deleteCompleteAction={() =>
                  fileContext.setFileMeta((m: IFileMeta) => ({ ...m, noteCount: m.noteCount - 1 }))
                }
                entityType={'non-client-files'}
              />
            </Route>
            <Route exact={true} path='/non-client-files/:fileId/notes/:noteId'>
              <FileNotesDetails
                createCompleteAction={() =>
                  fileContext.setFileMeta((m: IFileMeta) => ({
                    ...m,
                    noteCount: m?.noteCount ? m.noteCount + 1 : 1,
                  }))
                }
                deleteCompleteAction={() =>
                  fileContext.setFileMeta((m: IFileMeta) => ({ ...m, noteCount: m.noteCount - 1 }))
                }
                entityType={'non-client-files'}
              />
            </Route>
          </Switch>
        </article>
      ) : (
        <div>Loading</div>
      )}
    </>
  );
};
