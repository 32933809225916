import * as React from 'react';
import { useContext } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { NavigationBar } from './components/NavigationBar/NavigationBar';
import { SessionTimeout } from './components/SessionTimout/SessionTimeout';
import { UsersTable } from './components/Users/UsersTable';
import { MyAccount } from './components/MyAccount/MyAccount';
import { ToastContainer } from 'react-toastify';
import { SignOut } from './components/ChangePassword/SignOut';
import { OfficesTable } from './components/Offices/OfficesTable';
import { OfficeDetails } from './components/Offices/OfficeDetails';
import { Office } from './components/Offices/Office';
import { UserDetails } from './components/Users/UserDetails';
import { User } from './components/Users/User';
import { IndividualsTable } from './components/Individuals/IndividualsTable';
import { IndividualDetails } from './components/Individuals/IndividualDetails';
import { Individual } from './components/Individuals/Individual';
import { File } from './components/Files/File';
import { FileDetails } from './components/Files/FileDetails';
import { FileProvider } from './components/Files/FileContext';
import { OffenceTable } from './components/Offences/OffenceTable';
import { OffenceDetails } from './components/Offences/OffenceDetails';
import { Offence } from './components/Offences/Offence';
import { IndividualProvider } from './components/Individuals/IndividualContext';
import { PeopleContext } from './contexts/PeopleContext';
import { DomainContext } from './contexts/DomainContext';
import { WorkloadReport } from './components/Workloads/WorkloadReport';
import { MyUserContext } from './contexts/MyUserContext';
import { IndividualsContext } from './contexts/IndividualsContext';
import { IndividualsTableProvider } from './components/Individuals/IndividualsTableContext';
import { FilesTableProvider } from './components/Files/FilesTableContext';
import { FilesProvider } from './contexts/FilesContext';
import { FilesTable } from './components/FilesTable/FilesTable';
import { NonClientFileDetails } from './components/Files/NonClientFileDetails';
import { NonClientFile } from './components/Files/NonClientFile';
import { NonClientFileProvider } from './components/Files/NonClientFileContext';
import { NonClientFilesTable } from './components/Files/NonClientFilesTable';
import { IntakeReport } from './components/Files/IntakeReport';
import { NonClientFilesTableProvider } from './components/Files/NonClientFilesTableContext';
import { IndividualsMerge } from './components/Individuals/IndividualsMerge';
import { ActiveFilesByLawyer } from './components/Reports/ActiveFilesByLawyer';
import { ClosedFilesByLawyer } from './components/Reports/ClosedFilesByLawyer';
import { TotalPeopleServed } from './components/Reports/TotalPeopleServed';
import { Applications } from './components/Reports/Applications';
import { CriminalFiles } from './components/Reports/CriminalFiles';
import { OffencesProvider } from './components/Offences/OffencesContext';
import { TimeTracking } from './components/TimeTracking/TimeTracking';
import { SpecializedCourt } from './components/Reports/SpecializedCourt';
import { ReportProvider } from './components/Reports/ReportContext';
import { FamilyMatters } from './components/Reports/FamilyMatters';
import { DutyCounsel } from './components/Reports/DutyCounsel';
import { Exports } from './components/Reports/Exports';
import { FilesForDestruction } from './components/Reports/FilesForDestruction';
import { RecordFileTime } from './components/TimeTracking/RecordFileTime';
import { BoardReport } from './components/Reports/BoardReport';
import { SupportWorkerRequestsTable } from 'src/components/SupportRequests/SupportWorkerRequestsTable';
import { SupportWorkerRequestProvider } from 'src/components/SupportRequests/SupportWorkerRequestContext';
import { SupportWorkerRequestDetails } from 'src/components/SupportRequests/SupportWorkerRequestDetails';
import { SupportWorkerRequest } from 'src/components/SupportRequests/SupportWorkerRequest';
import { SupportWorkerRequestsTableProvider } from 'src/components/SupportRequests/SupportWorkerRequestsTableContext';

export const SignedInLayout = () => {
  const domainContext = useContext(DomainContext);
  const peopleContext = useContext(PeopleContext);
  const myUserContext = useContext(MyUserContext);
  const individualsContext = useContext(IndividualsContext);

  if (
    domainContext.loaded &&
    peopleContext.loaded &&
    myUserContext.loaded &&
    individualsContext.state.type !== 'INDIVIDUAL_STATE_LOADING'
  ) {
    return (
      <Router>
        <FilesProvider>
          <Route path='/login'>
            <Redirect to='/' />
          </Route>
          <Route path='/'>
            <NavigationBar />
            <SessionTimeout />
          </Route>
          <Route path='/change-password/:token'>
            <SignOut />
          </Route>
          <Route path='/create-password/:token'>
            <SignOut />
          </Route>
          <main className={'content-wrapper'} id={'main'}>
            {!myUserContext.isReportingOnly && (
              <div>
                <Route path='/workload-report'>
                  <WorkloadReport />
                </Route>
                <Route path='/intake-report'>
                  <IntakeReport />
                </Route>
                <Route path={['/(files)?', '/files/:fileId/:tab?/:tabId?']} exact={true}>
                  <FilesTableProvider>
                    <Switch>
                      {!myUserContext.isSupportWorker && (
                        <Route path='/(files)?' exact={true}>
                          <FilesTable />
                        </Route>
                      )}
                      <Route path={'/files/:singleFile'}>
                        <FileProvider>
                          <IndividualProvider>
                            <Switch>
                              <Route path='/files/add' exact={true}>
                                <FileDetails />
                              </Route>
                              <Route path='/files/:fileId/:tab?/:tabId?'>
                                <File />
                              </Route>
                            </Switch>
                          </IndividualProvider>
                        </FileProvider>
                      </Route>
                    </Switch>
                  </FilesTableProvider>
                </Route>
                <Route path={['/non-client-files', '/non-client-files/:fileId/:tab?/:tabId?']} exact={true}>
                  <NonClientFilesTableProvider>
                    <Switch>
                      <Route path='/non-client-files' exact={true}>
                        <NonClientFilesTable />
                      </Route>
                      <Route path={'/non-client-files/:singleFile'}>
                        <NonClientFileProvider>
                          <Switch>
                            <Route path='/non-client-files/add' exact={true}>
                              <NonClientFileDetails />
                            </Route>
                            <Route path='/non-client-files/:fileId/:tab?/:tabId?'>
                              <NonClientFile />
                            </Route>
                          </Switch>
                        </NonClientFileProvider>
                      </Route>
                    </Switch>
                  </NonClientFilesTableProvider>
                </Route>
                <Route
                  path={[
                    myUserContext.isSupportWorker ? '/(support-worker-requests)?' : '/support-worker-requests',
                    '/support-worker-requests/:supportWorkerRequestId/:tab?/:tabId?',
                  ]}
                >
                  <SupportWorkerRequestsTableProvider>
                    <Route
                      path={myUserContext.isSupportWorker ? '/(support-worker-requests)?' : '/support-worker-requests'}
                      exact={true}
                    >
                      <SupportWorkerRequestsTable />
                    </Route>
                  </SupportWorkerRequestsTableProvider>
                  <SupportWorkerRequestProvider>
                    <Switch>
                      <Route path='/support-worker-requests/add' exact={true}>
                        <SupportWorkerRequestDetails />
                      </Route>
                      <Route path='/support-worker-requests/:supportWorkerRequestId/:tab?/:tabId?'>
                        <SupportWorkerRequest />
                      </Route>
                    </Switch>
                  </SupportWorkerRequestProvider>
                </Route>
                <Switch>
                  <Route path='/users' exact={true}>
                    <UsersTable />
                  </Route>
                  <Route path='/users/add' exact={true}>
                    <UserDetails />
                  </Route>
                  <Route path='/users/:userId/:tab?/:tabId?'>
                    <User />
                  </Route>
                </Switch>
                <Switch>
                  <Route path='/offices' exact={true}>
                    <OfficesTable />
                  </Route>
                  <Route path='/offices/add' exact={true}>
                    <OfficeDetails />
                  </Route>
                  <Route path='/offices/:officeId/:tab?'>
                    <Office />
                  </Route>
                </Switch>
                <Route path='/individuals'>
                  <IndividualsTableProvider>
                    <Switch>
                      <Route path='/individuals' exact={true}>
                        <IndividualsTable />
                      </Route>
                      <Route path='/individuals/merge' exact={true}>
                        <IndividualsMerge />
                      </Route>
                      <IndividualProvider>
                        <Switch>
                          <Route path='/individuals/add' exact={true}>
                            <IndividualDetails allowCreateFile={false} />
                          </Route>
                          <Route path='/individuals/:individualId/:tab?'>
                            <Individual />
                          </Route>
                        </Switch>
                      </IndividualProvider>
                    </Switch>
                  </IndividualsTableProvider>
                </Route>
                <OffencesProvider>
                  <Switch>
                    <Route path='/offences' exact={true}>
                      <OffenceTable />
                    </Route>
                    <Route path='/offences/add' exact={true}>
                      <OffenceDetails />
                    </Route>
                    <Route path='/offences/:offenceId/:tab?'>
                      <Offence />
                    </Route>
                  </Switch>
                </OffencesProvider>
                <Route path={'/time-tracking'}>
                  <TimeTracking />
                </Route>
                <Route path={'/time-tracking-multiple-files'}>
                  <RecordFileTime />
                </Route>
              </div>
            )}
            <Route path='/my-account'>
              <MyAccount />
            </Route>
            <Route path={myUserContext.isReportingOnly ? '/(reports)?' : '/reports'}>
              <ReportProvider>
                <Switch>
                  {myUserContext.isReportingOnly ? (
                    <div>
                      <Route path={['/', '/reports/total-people-served']} exact={true}>
                        <TotalPeopleServed />
                      </Route>
                      <Route path={'/reports/applications'}>
                        <Applications />
                      </Route>
                      <Route path={'/reports/criminal-files'}>
                        <CriminalFiles />
                      </Route>
                      <Route path={'/reports/specialized-court'}>
                        <SpecializedCourt />
                      </Route>
                      <Route path={'/reports/family-matters'}>
                        <FamilyMatters />
                      </Route>
                      <Route path={'/reports/duty-counsel'}>
                        <DutyCounsel />
                      </Route>
                      <Route path={'/reports/exports'}>
                        <Exports />
                      </Route>
                      <Route path={'/reports/files-for-destruction'}>
                        <FilesForDestruction />
                      </Route>
                      <Route path={'/reports/board-report'}>
                        <BoardReport />
                      </Route>
                    </div>
                  ) : (
                    <div>
                      <Route path={'/reports/active-files-reports'}>
                        <ActiveFilesByLawyer />
                      </Route>
                      <Route path={'/reports/closed-files-reports'}>
                        <ClosedFilesByLawyer />
                      </Route>
                      <Route path={'/reports/total-people-served'} exact={true}>
                        <TotalPeopleServed />
                      </Route>
                      <Route path={'/reports/applications'}>
                        <Applications />
                      </Route>
                      <Route path={'/reports/criminal-files'}>
                        <CriminalFiles />
                      </Route>
                      <Route path={'/reports/specialized-court'}>
                        <SpecializedCourt />
                      </Route>
                      <Route path={'/reports/family-matters'}>
                        <FamilyMatters />
                      </Route>
                      <Route path={'/reports/duty-counsel'}>
                        <DutyCounsel />
                      </Route>
                      <Route path={'/reports/exports'}>
                        <Exports />
                      </Route>
                      <Route path={'/reports/files-for-destruction'}>
                        <FilesForDestruction />
                      </Route>
                      <Route path={'/reports/board-report'}>
                        <BoardReport />
                      </Route>
                    </div>
                  )}
                </Switch>
              </ReportProvider>
            </Route>
          </main>
          <ToastContainer autoClose={8000} />
        </FilesProvider>
      </Router>
    );
  } else {
    return <div>Loading</div>;
  }
};
