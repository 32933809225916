import * as React from 'react';
import { FormEvent, useContext, useEffect, useState } from 'react';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { useParams } from 'react-router';
import { authAxios } from 'src/services/AxiosService';
import { Link, useHistory } from 'react-router-dom';
import { notifyError, notifySuccess } from 'src/services/ToastService';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { IFile, REMINDER_TYPE_EXTENSION_REQUESTED, REMINDER_TYPE_FILE_EXPIRY } from '../Files/FileContext';
import { PeopleContext } from 'src/contexts/PeopleContext';
import { IUser } from '../Users/UsersTable';
import Select, { components, createFilter, OptionProps, SingleValueProps } from 'react-select';
import {
  disabledSelectStyles,
  DomainContext,
  ISupportRequestAssistanceType,
  ISupportRequestCriteria,
  STAGE_ASSIGNED_TO_LAWYER,
  SUPPORT_REQUEST,
} from 'src/contexts/DomainContext';
import { MyUserContext } from 'src/contexts/MyUserContext';
import { formatDate, formatDateTime } from 'src/formatters/DateTimeFormatter';
import * as AuthenticationService from 'src/services/AuthenticationService';
import { getUserId } from 'src/services/AuthenticationService';
import DatePicker from 'react-datepicker';
import {
  ISupportWorkerRequest,
  ISupportWorkerRequestHistoryItem,
  SupportWorkerRequestContext,
} from 'src/components/SupportRequests/SupportWorkerRequestContext';
import { RequiredLabel } from 'src/components/RequiredLabel';
import { IIndividual } from 'src/components/Individuals/IndividualContext';
import { IndividualsContext } from 'src/contexts/IndividualsContext';
import { clientSelectValue } from 'src/formatters/PeopleFormatter';

export const supportWorkerStateColor = (state: string) => {
  if (state === SUPPORT_REQUEST.PENDING) {
    return 'light';
  } else if (state === SUPPORT_REQUEST.SUBMITTED) {
    return 'primary';
  } else if (state === SUPPORT_REQUEST.ACCEPTED) {
    return 'success';
  } else if (state === SUPPORT_REQUEST.REJECTED) {
    return 'danger';
  } else if (state === SUPPORT_REQUEST.REASSIGNED) {
    return 'warning';
  } else if (state === SUPPORT_REQUEST.COMPLETED) {
    return 'success';
  } else if (state === SUPPORT_REQUEST.INCOMPLETE) {
    return 'danger';
  } else {
    return 'light';
  }
};

export const SupportWorkerRequestDetails: React.FC = () => {
  const peopleContext = useContext(PeopleContext);
  const domainContext = useContext(DomainContext);
  const myUserContext = useContext(MyUserContext);
  const individualsContext = useContext(IndividualsContext);
  const authUserId = AuthenticationService.getUserId();
  const supportWorkerRequestContext = useContext(SupportWorkerRequestContext);
  const {
    setActiveOnscreenSupportWorkerRequestReminders,
    activeOnscreenSupportWorkerRequestReminders,
    supportWorkerRequest,
  } = useContext(SupportWorkerRequestContext);

  const individuals = individualsContext.state.individuals;
  const supportRequestCriteria = domainContext.supportRequestCriteria;
  const supportRequestAssistanceTypes = domainContext.supportRequestAssistanceTypes;
  const [isDirty, setIsDirty] = useState(false);
  const params = useParams<{ fileId: string; supportRequestId?: string; supportWorkerRequestId?: string }>();
  const [request, setRequest] = useState<ISupportWorkerRequest | undefined>({
    clientId: '',
    supportWorkerRequestId: '',
    supportWorkerId: '',
    fileIds: [],
    criteriaForAssistance: [],
    assistanceTypes: [],
    status: SUPPORT_REQUEST.PENDING,
    userCreated: '',
    submittedDate: 0,
    details: '',
    requestHistory: [],
    lastUpdated: 0,
    createdById: '',
    lawyerId: myUserContext.isLawyer ? authUserId : '',
    interventions: [],
  } as ISupportWorkerRequest);
  const history = useHistory();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [trySave, setTrySave] = useState(false);
  const [tryReject, setTryReject] = useState(false);
  const [tryReassign, setTryReassign] = useState(false);
  const [tryComplete, setTryComplete] = useState(false);
  const [tryIncomplete, setTryIncomplete] = useState(false);
  const [originalRequest, setOriginalRequest] = useState<ISupportWorkerRequest | undefined>();

  const [fileOptions, setFileOptions] = useState<IFile[]>([]);
  const [lawyerIdOptions, setLawyerIdOptions] = useState<(string | undefined)[]>([]);

  const [clientInputValue, setClientInputValue] = useState('');

  const [rejectReason, setRejectReason] = useState('');
  const [reassignReason, setReassignReason] = useState('');
  const [supportWorkerReassignTo, setSupportWorkerReassignTo] = useState('');
  const [completedDate, setCompletedDate] = useState<Date | undefined>();
  const [incompleteReason, setIncompleteReason] = useState('');
  const [incompletedDate, setIncompletedDate] = useState<Date | undefined>();
  const [reassignConfirmModal, setReassignConfirmModal] = useState(false);

  const SELECT_ALL_ID = 'SELECT_ALL_ID';

  useEffect(() => {
    if (params.supportWorkerRequestId) {
      setRequest(supportWorkerRequest);
      setOriginalRequest(supportWorkerRequest);
    }
  }, [params.supportWorkerRequestId, supportWorkerRequest, myUserContext.isSupportWorker]);

  const showActionCard =
    myUserContext.isSupportWorker &&
    supportWorkerRequest?.status &&
    (supportWorkerRequest.status === SUPPORT_REQUEST.REASSIGNED ||
      supportWorkerRequest.status === SUPPORT_REQUEST.SUBMITTED ||
      supportWorkerRequest.status === SUPPORT_REQUEST.ACCEPTED ||
      supportWorkerRequest.status === SUPPORT_REQUEST.INCOMPLETE ||
      supportWorkerRequest.status === SUPPORT_REQUEST.COMPLETED);

  useEffect(() => {
    if (request?.clientId) {
      authAxios.get(`/api/files/${request.clientId}/client-files`).then((response) => {
        const filteredFileOptions: IFile[] = response.data
          .map((e: any) => e.file)
          .filter((d: IFile) => d.stageId === STAGE_ASSIGNED_TO_LAWYER);

        if (myUserContext.isLegalAssistant) {
          //get array of unique lawyerIds associated with files
          const uniqueLawyerIds = Array.from(new Set(filteredFileOptions.map((f) => f.lawyerId)));
          //if a client only has files with one lawyer, automatically select that lawyer
          if (uniqueLawyerIds.length === 1) {
            setRequest(
              (s) =>
                ({
                  ...s,
                  lawyerId: uniqueLawyerIds[0],
                } as ISupportWorkerRequest)
            );
          }
          setLawyerIdOptions(uniqueLawyerIds);
        }
        setFileOptions(filteredFileOptions);
      });
    }
  }, [request?.clientId, myUserContext.isLegalAssistant]);

  const formFullyFilled = () => {
    return (
      request?.clientId &&
      request?.supportWorkerId !== undefined &&
      request?.supportWorkerId !== '' &&
      request?.criteriaForAssistance.length > 0 &&
      request?.assistanceTypes.length > 0
    );
  };

  const acceptRequest = () => {
    if (
      request &&
      request?.supportWorkerId !== undefined &&
      request?.supportWorkerId !== '' &&
      request?.criteriaForAssistance.length > 0 &&
      request?.assistanceTypes.length > 0 &&
      params.supportWorkerRequestId &&
      myUserContext.isSupportWorker
    ) {
      const newStatus = SUPPORT_REQUEST.ACCEPTED;
      authAxios
        .post('/api/support-worker-requests/admin/accept-support-worker-request', {
          supportWorkerRequestId: params.supportWorkerRequestId,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            setRequest(
              (s) =>
                ({
                  ...s,
                  status: newStatus,
                  requestHistory: s?.requestHistory.concat({
                    supportWorkerId: request?.supportWorkerId,
                    status: newStatus,
                    auditUserId: getUserId(),
                    auditTimestamp: new Date().getTime(),
                  } as ISupportWorkerRequestHistoryItem),
                } as ISupportWorkerRequest)
            );
            supportWorkerRequestContext.setSupportWorkerRequest({ ...request, status: newStatus });
            notifySuccess('Support Request Accepted.');
          } else {
            notifyError('Error updating support request.');
          }
        });
    }
  };

  const completeRequest = () => {
    if (
      request &&
      request?.supportWorkerId !== undefined &&
      request?.supportWorkerId !== '' &&
      request?.criteriaForAssistance.length > 0 &&
      request?.assistanceTypes.length > 0 &&
      completedDate !== undefined &&
      params.supportWorkerRequestId &&
      myUserContext.isSupportWorker
    ) {
      const newStatus = SUPPORT_REQUEST.COMPLETED;
      authAxios
        .post('/api/support-worker-requests/admin/complete-support-worker-request', {
          clientId: request?.clientId,
          supportWorkerRequestId: params.supportWorkerRequestId,
          supportWorkerId: request?.supportWorkerId,
          fileIds: request?.fileIds,
          criteriaForAssistance: request?.criteriaForAssistance,
          assistanceTypes: request?.assistanceTypes,
          status: newStatus,
          details: request?.details,
          completedDate: completedDate.getTime(),
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            setRequest(
              (s) =>
                ({
                  ...s,
                  status: newStatus,
                  requestHistory: s?.requestHistory.concat({
                    supportWorkerId: request?.supportWorkerId,
                    status: newStatus,
                    auditUserId: getUserId(),
                    auditTimestamp: new Date().getTime(),
                    completedDate: completedDate.getTime(),
                  } as ISupportWorkerRequestHistoryItem),
                } as ISupportWorkerRequest)
            );
            setCompletedDate(undefined);
            supportWorkerRequestContext.setSupportWorkerRequest({ ...request, status: newStatus });
            notifySuccess('Support Worker Request Completed.');
          } else {
            notifyError('Error updating support request.');
          }
        });
    } else {
      setTryComplete(true);
    }
  };

  const incompleteRequest = () => {
    if (
      request &&
      request?.supportWorkerId !== undefined &&
      request?.supportWorkerId !== '' &&
      request?.criteriaForAssistance.length > 0 &&
      request?.assistanceTypes.length > 0 &&
      incompleteReason !== '' &&
      incompletedDate !== undefined &&
      params.supportWorkerRequestId &&
      myUserContext.isSupportWorker
    ) {
      const newStatus = SUPPORT_REQUEST.INCOMPLETE;
      authAxios
        .post('/api/support-worker-requests/admin/incomplete-support-worker-request', {
          supportWorkerRequestId: params.supportWorkerRequestId,
          reason: incompleteReason,
          incompleteDate: incompletedDate.getTime(),
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            setRequest(
              (s) =>
                ({
                  ...s,
                  status: newStatus,
                  requestHistory: s?.requestHistory.concat({
                    supportWorkerId: request?.supportWorkerId,
                    status: newStatus,
                    reason: incompleteReason,
                    auditUserId: getUserId(),
                    auditTimestamp: new Date().getTime(),
                    incompleteDate: incompletedDate.getTime(),
                  } as ISupportWorkerRequestHistoryItem),
                } as ISupportWorkerRequest)
            );
            setIncompletedDate(undefined);
            setIncompleteReason('');
            supportWorkerRequestContext.setSupportWorkerRequest({ ...request, status: newStatus });
            notifySuccess('Support Request set to Incomplete.');
          } else {
            notifyError('Error updating support request.');
          }
        });
    } else {
      setTryIncomplete(true);
    }
  };

  const rejectRequest = () => {
    if (
      request &&
      request?.supportWorkerId !== undefined &&
      request?.supportWorkerId !== '' &&
      request?.criteriaForAssistance.length > 0 &&
      request?.assistanceTypes.length > 0 &&
      rejectReason !== '' &&
      params.supportWorkerRequestId &&
      myUserContext.isSupportWorker
    ) {
      const newStatus = SUPPORT_REQUEST.REJECTED;
      authAxios
        .post('/api/support-worker-requests/admin/reject-support-worker-request', {
          supportWorkerRequestId: params.supportWorkerRequestId,
          reason: rejectReason,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            setRequest(
              (s) =>
                ({
                  ...s,
                  status: newStatus,
                  requestHistory: s?.requestHistory.concat({
                    supportWorkerId: request?.supportWorkerId,
                    status: newStatus,
                    reason: rejectReason,
                    auditUserId: getUserId(),
                    auditTimestamp: new Date().getTime(),
                  } as ISupportWorkerRequestHistoryItem),
                } as ISupportWorkerRequest)
            );
            supportWorkerRequestContext.setSupportWorkerRequest({ ...request, status: newStatus });
            setRejectReason('');
            notifySuccess('Support Request Rejected.');
            history.replace('/support-worker-requests', {
              supportWorkerRequestToDelete: params.supportWorkerRequestId,
            });
          } else {
            notifyError('Error updating support request.');
          }
        });
    } else {
      setTryReject(true);
    }
  };

  const reassignRequestWithModal = () => {
    if (request && supportWorkerReassignTo !== undefined && supportWorkerReassignTo !== '' && reassignReason !== '') {
      setReassignConfirmModal(true);
    } else {
      setTryReassign(true);
    }
  };

  const reassignRequest = () => {
    if (
      request &&
      request?.supportWorkerId !== undefined &&
      request?.supportWorkerId !== '' &&
      request?.criteriaForAssistance.length > 0 &&
      request?.assistanceTypes.length > 0 &&
      reassignReason !== '' &&
      supportWorkerReassignTo !== undefined &&
      supportWorkerReassignTo !== '' &&
      params.supportWorkerRequestId &&
      myUserContext.isSupportWorker
    ) {
      doReassignRequest();
    } else {
      setTryReassign(true);
    }
  };

  const doReassignRequest = () => {
    if (request) {
      const newStatus =
        request.status === SUPPORT_REQUEST.ACCEPTED ? SUPPORT_REQUEST.ACCEPTED : SUPPORT_REQUEST.REASSIGNED;
      authAxios
        .post('/api/support-worker-requests/admin/reassign-support-worker-request', {
          supportWorkerRequestId: params.supportWorkerRequestId,
          supportWorkerId: supportWorkerReassignTo,
          reason: reassignReason,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            setRequest(
              (s) =>
                ({
                  ...s,
                  status: newStatus,
                  requestHistory: s?.requestHistory.concat({
                    supportWorkerId: supportWorkerReassignTo,
                    status: newStatus,
                    reason: reassignReason,
                    auditUserId: getUserId(),
                    auditTimestamp: new Date().getTime(),
                  } as ISupportWorkerRequestHistoryItem),
                } as ISupportWorkerRequest)
            );
            supportWorkerRequestContext.setSupportWorkerRequest({ ...request, status: newStatus });
            setReassignReason('');
            setSupportWorkerReassignTo('');
            notifySuccess('Support Request Reassigned.');
            history.replace('/support-worker-requests', {
              supportWorkerRequestToDelete: params.supportWorkerRequestId,
              remindersToDelete: response.data.resolvedReminders,
            });
          } else {
            notifyError('Error reassigning support request.');
          }
        });
    }
  };

  const resubmit = () => {
    if (formFullyFilled()) {
      if (params.supportWorkerRequestId && request) {
        //update
        const newStatus = SUPPORT_REQUEST.SUBMITTED;
        authAxios
          .post('/api/support-worker-requests/admin/resubmit-support-worker-request', {
            clientId: request?.clientId,
            supportWorkerRequestId: params.supportWorkerRequestId,
            supportWorkerId: request?.supportWorkerId,
            fileIds: request?.fileIds,
            criteriaForAssistance: request?.criteriaForAssistance,
            assistanceTypes: request?.assistanceTypes,
            details: request?.details,
          })
          .then((response) => {
            if (response.data.status === 'OK') {
              setIsDirty(false);
              setRequest(
                (s) =>
                  ({
                    ...s,
                    status: newStatus,
                    requestHistory: s?.requestHistory.concat({
                      supportWorkerId: request?.supportWorkerId,
                      status: newStatus,
                      auditUserId: getUserId(),
                      auditTimestamp: new Date().getTime(),
                    } as ISupportWorkerRequestHistoryItem),
                  } as ISupportWorkerRequest)
              );
              supportWorkerRequestContext.setSupportWorkerRequest({ ...request, status: newStatus });
              notifySuccess('Support Request updated.');
            } else {
              notifyError('Error updating support request.');
            }
          });
      }
    } else {
      setTrySave(true);
    }
  };

  const create = () => {
    if (formFullyFilled()) {
      //create
      authAxios
        .post('/api/support-worker-requests/admin/create', {
          clientId: request?.clientId,
          supportWorkerRequestId: params.supportWorkerRequestId,
          supportWorkerId: request?.supportWorkerId,
          fileIds: request?.fileIds,
          criteriaForAssistance: request?.criteriaForAssistance,
          assistanceTypes: request?.assistanceTypes,
          details: request?.details,
          createdById: authUserId,
          lawyerId: request?.lawyerId,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            notifySuccess('Support Request created.');
            history.push({ pathname: '/support-worker-requests/' + response.data.supportWorkerRequestId });
          } else {
            notifyError('Error creating support request.');
          }
        });
    } else {
      setTrySave(true);
    }
  };

  const deleteSupportWorkerRequest = () => {
    if (
      params.supportWorkerRequestId &&
      request?.status !== SUPPORT_REQUEST.COMPLETED &&
      request?.status !== SUPPORT_REQUEST.INCOMPLETE
    ) {
      authAxios
        .post('/api/support-worker-requests/admin/delete', {
          supportWorkerRequestId: params.supportWorkerRequestId,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            notifySuccess('Support Request deleted.');
            history.replace('/support-worker-requests', {
              supportWorkerRequestToDelete: params.supportWorkerRequestId,
            });
          } else {
            notifyError('Error deleting support request.');
          }
        });
    }
  };

  const resolveReminder = (reminderId: string) => {
    authAxios
      .post('/api/support-worker-requests/admin/resolve-reminder', {
        supportWorkerRequestId: params.supportWorkerRequestId,
        reminderId: reminderId,
      })
      .then((response) => {
        if (response.data.status === 'OK') {
          setActiveOnscreenSupportWorkerRequestReminders((r) =>
            r.filter((reminder) => reminder.reminderId !== reminderId)
          );
        }
      });
  };

  const selectAllOption = { supportRequestCriteriaId: SELECT_ALL_ID, name: 'Select All' } as ISupportRequestCriteria;

  const areAllCriteriaOptionsSelected = () => {
    if (request) {
      return supportRequestCriteria.every((c) => request.criteriaForAssistance.includes(c.supportRequestCriteriaId));
    } else {
      return false;
    }
  };

  const Option = (props: OptionProps<IIndividual, boolean>) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <div>{clientSelectValue(data)}</div>
      </components.Option>
    );
  };

  const SingleValue = (props: SingleValueProps<IIndividual>) => {
    const { data } = props;
    return <components.SingleValue {...props}>{clientSelectValue(data)}</components.SingleValue>;
  };

  return (
    <div>
      <ConfirmModal
        isOpen={reassignConfirmModal}
        title={'Reassign Request'}
        onConfirm={() => {
          setReassignConfirmModal(false);
          doReassignRequest();
        }}
        onCancel={() => setReassignConfirmModal(false)}
        confirmButton={'Reassign Request'}
      >
        <div>
          Are you sure you want to reassign this support request? You will no longer have access to the request and
          associated files.
        </div>
      </ConfirmModal>
      <LeavePageConfirm isDirty={isDirty} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
          <NavbarBrand>{params.supportWorkerRequestId ? 'Edit Support Request' : 'Add Support Request'}</NavbarBrand>
          {params.supportWorkerRequestId && (
            <Badge
              color={supportWorkerStateColor(request?.status ? request?.status : SUPPORT_REQUEST.PENDING)}
              style={{ height: 'min-content', marginTop: '0px' }}
            >
              {request?.status}
            </Badge>
          )}
          {params.supportWorkerRequestId ? (
            <Col className={'d-flex'}>
              {isDirty && !showActionCard && (
                <Button
                  color='link'
                  className={'ml-auto'}
                  onClick={() => {
                    setIsDirty(false);
                    setRequest(originalRequest);
                  }}
                >
                  Cancel Changes
                </Button>
              )}
              {!myUserContext.isSupportWorker &&
                request?.status !== SUPPORT_REQUEST.INCOMPLETE &&
                request?.status !== SUPPORT_REQUEST.COMPLETED && (
                  <Button
                    color='danger'
                    className={isDirty ? 'ml-2' : 'ml-auto'}
                    onClick={() => setDeleteModalOpen(true)}
                  >
                    Delete Support Request
                  </Button>
                )}
              {request?.status === SUPPORT_REQUEST.REJECTED && !myUserContext.isSupportWorker && (
                <Button
                  color='success'
                  className={myUserContext.isSupportWorker && !isDirty ? 'ml-auto' : 'ml-2'}
                  onClick={() => resubmit()}
                >
                  Resubmit Support Request
                </Button>
              )}
            </Col>
          ) : (
            <Col className={'d-flex'}>
              <Button color='link' className={'ml-auto'} onClick={() => history.push('/support-worker-requests')}>
                Cancel
              </Button>
              <Button color='success' className={'ml-2'} onClick={() => create()}>
                Submit Support Request
              </Button>
            </Col>
          )}
        </Navbar>
        <div className={'m-3'}>
          {params.supportWorkerRequestId &&
            activeOnscreenSupportWorkerRequestReminders.map((r) => (
              <Alert
                color={
                  r.reminderTypeId === REMINDER_TYPE_FILE_EXPIRY ||
                  r.reminderTypeId === REMINDER_TYPE_EXTENSION_REQUESTED
                    ? 'danger'
                    : 'success'
                }
                isOpen={true}
                key={r.reminderId}
              >
                <div className={'d-flex'}>
                  NOTIFICATION: {r.text}
                  {r.reminderTypeId !== REMINDER_TYPE_FILE_EXPIRY && (
                    <Button className={'ml-auto p-0'} color={'link'} onClick={() => resolveReminder(r.reminderId)}>
                      Resolve
                    </Button>
                  )}
                </div>
              </Alert>
            ))}
          {showActionCard ? (
            <Row className={'mb-3'}>
              <Col>
                <Card>
                  <CardHeader>Details</CardHeader>
                  <CardBody>
                    {!params.supportWorkerRequestId && (
                      <FormGroup>
                        <RequiredLabel for={'client'} required={request?.clientId === undefined}>
                          Select Client
                        </RequiredLabel>
                        <Select
                          inputValue={clientInputValue}
                          onInputChange={(e) => setClientInputValue(e)}
                          className={'flex-fill'}
                          classNamePrefix={'people-select'}
                          name={'clientId'}
                          options={
                            !!clientInputValue && clientInputValue.length >= 3
                              ? individuals.filter(
                                  (e) => e.searchString?.includes(clientInputValue.toUpperCase()) && e.active
                                )
                              : []
                          }
                          filterOption={createFilter({ ignoreAccents: false })}
                          value={individuals.find((i: IIndividual) => i.individualId === request?.clientId)}
                          onChange={(value: any) => {
                            setRequest(
                              (s) =>
                                ({
                                  ...s,
                                  clientId: value?.individualId || undefined,
                                } as ISupportWorkerRequest)
                            );
                            setIsDirty(true);
                          }}
                          isDisabled={params.supportWorkerRequestId !== undefined}
                          getOptionValue={(option: IIndividual) => option.individualId}
                          getOptionLabel={(o: IIndividual) => `${o.searchString}`}
                          isClearable={true}
                          components={{ SingleValue, Option }}
                          noOptionsMessage={() =>
                            !!clientInputValue && clientInputValue.length >= 3
                              ? 'No individuals match your search. Add a new individual or try again.'
                              : 'Enter at least 3 characters to search.'
                          }
                          styles={{
                            singleValue: (base) => ({
                              ...base,
                              position: 'relative',
                              top: 0,
                              transform: 'translateY(0)',
                              height: '100%',
                              padding: '0.25em 0',
                            }),
                          }}
                        />
                        {trySave && !request?.clientId && (
                          <span style={{ fontSize: 13, color: '#B22222' }}>
                            This field is mandatory to save application
                          </span>
                        )}
                        <FormFeedback>This field is mandatory</FormFeedback>
                      </FormGroup>
                    )}
                    <FormGroup>
                      <Label>Support Worker Requested</Label>
                      <Select
                        classNamePrefix={'user-select'}
                        id={'supportWorkerId'}
                        name={'supportWorkerId'}
                        styles={{
                          singleValue: (base) => ({
                            ...base,
                            position: 'relative',
                            top: 0,
                            transform: 'translateY(0)',
                            height: '100%',
                            padding: '0.25em 0',
                          }),
                          menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
                        }}
                        options={
                          peopleContext.supportWorkers
                            .filter((sw) => sw.active)
                            .sort((a: IUser, b: IUser) =>
                              a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase())
                            ) as IUser[]
                        }
                        value={peopleContext.supportWorkers.find((i: IUser) => request?.supportWorkerId === i.userId)}
                        onChange={(value: any) => {
                          const newSupportWorkerId = value ? value.userId : '';
                          setRequest(
                            (s) =>
                              ({
                                ...s,
                                supportWorkerId: newSupportWorkerId,
                                status:
                                  params.supportWorkerRequestId && request?.status !== SUPPORT_REQUEST.REJECTED
                                    ? SUPPORT_REQUEST.REASSIGNED
                                    : s?.status,
                              } as ISupportWorkerRequest)
                          );
                          setIsDirty(true);
                        }}
                        getOptionValue={(option: IUser) => option.userId}
                        getOptionLabel={(option: IUser) => {
                          const supportWorkerType = domainContext.supportWorkerTypes.find(
                            (swt) => swt.supportWorkerTypeId === option.supportWorkerTypeId
                          );
                          return (
                            option.lastName.toUpperCase() +
                            ', ' +
                            option.firstName +
                            (supportWorkerType ? ' (' + supportWorkerType?.name + ')' : '') +
                            (option.active ? '' : ' (Inactive)')
                          );
                        }}
                        isClearable={true}
                        menuPlacement={'auto'}
                        isDisabled={
                          myUserContext.isSupportWorker ||
                          ((myUserContext.isLawyer || myUserContext.isLegalAssistant) &&
                            params.supportWorkerRequestId !== undefined &&
                            request?.status !== SUPPORT_REQUEST.PENDING &&
                            request?.status !== SUPPORT_REQUEST.REJECTED &&
                            (request?.status !== SUPPORT_REQUEST.REASSIGNED ||
                              (request?.status === SUPPORT_REQUEST.REASSIGNED && !isDirty)))
                        }
                      />
                      {trySave && request?.supportWorkerId === '' && (
                        <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                          This field is mandatory
                        </span>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for={'lawyerId'}>Lawyer</Label>
                      <Select
                        className={'w-100'}
                        classNamePrefix={'people-select'}
                        name={'lawyerId'}
                        options={peopleContext.lawyers.filter((e) => e.active && lawyerIdOptions.includes(e.userId))}
                        value={peopleContext.lawyers.find((i: IUser) => i.userId === request?.lawyerId)}
                        onChange={(value: any) => {
                          setRequest(
                            (s) =>
                              ({
                                ...s,
                                lawyerId: value?.userId,
                              } as ISupportWorkerRequest)
                          );
                          setIsDirty(true);
                        }}
                        getOptionValue={(option: IUser) => option.userId}
                        getOptionLabel={(o: IUser) => o.displayName}
                        isDisabled={
                          myUserContext.isSupportWorker ||
                          myUserContext.isLawyer ||
                          (myUserContext.isLegalAssistant &&
                            params.supportWorkerRequestId !== undefined &&
                            request?.status !== SUPPORT_REQUEST.PENDING &&
                            request?.status !== SUPPORT_REQUEST.REJECTED &&
                            (request?.status !== SUPPORT_REQUEST.REASSIGNED ||
                              (request?.status === SUPPORT_REQUEST.REASSIGNED && !isDirty)))
                        }
                        isClearable={true}
                        styles={{
                          menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Files</Label>
                      <div className='d-flex flex-column'>
                        {fileOptions
                          .filter((f: IFile) => request?.fileIds.includes(f.fileId))
                          .map((file, number) => (
                            <div key={number}>
                              <Link target='_blank' to={{ pathname: '/files/' + file.fileId }}>
                                {file.courtNumber ? file.courtNumber : 'Law Office #: ' + file.lawOfficeNumber}
                              </Link>
                            </div>
                          ))}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label>Criteria For Assistance</Label>
                      <Select
                        styles={disabledSelectStyles}
                        name={'supportRequestCriteria'}
                        options={supportRequestCriteria}
                        placeholder={<span className='placeholder'>Select...</span>}
                        value={supportRequestCriteria.filter((d: ISupportRequestCriteria) =>
                          request?.criteriaForAssistance
                            ? request?.criteriaForAssistance.includes(d.supportRequestCriteriaId)
                            : false
                        )}
                        onChange={(value: any) => {
                          setIsDirty(true);
                          setRequest(
                            (s) =>
                              ({
                                ...s,
                                criteriaForAssistance: value
                                  ? value.map((c: ISupportRequestCriteria) => c.supportRequestCriteriaId)
                                  : [],
                              } as ISupportWorkerRequest)
                          );
                        }}
                        isMulti={true}
                        isClearable={true}
                        getOptionValue={(option: ISupportRequestCriteria) => option.supportRequestCriteriaId}
                        getOptionLabel={(option: ISupportRequestCriteria) => option.name}
                        isDisabled={
                          myUserContext.isSupportWorker ||
                          ((myUserContext.isLawyer || myUserContext.isLegalAssistant) &&
                            params.supportWorkerRequestId !== undefined &&
                            request?.status !== SUPPORT_REQUEST.PENDING &&
                            request?.status !== SUPPORT_REQUEST.REJECTED &&
                            (request?.status !== SUPPORT_REQUEST.REASSIGNED || !isDirty))
                        }
                      />
                      {trySave && request?.criteriaForAssistance.length === 0 && (
                        <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                          This field is mandatory
                        </span>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label>Assistance Type</Label>
                      <Select
                        styles={disabledSelectStyles}
                        name={'supportRequestAssistanceTypes'}
                        options={supportRequestAssistanceTypes}
                        placeholder={<span className='placeholder'>Select...</span>}
                        value={supportRequestAssistanceTypes.filter((d: ISupportRequestAssistanceType) =>
                          request?.assistanceTypes?.includes(d.supportRequestAssistanceTypeId)
                        )}
                        onChange={(value: any) => {
                          setIsDirty(true);
                          setRequest(
                            (s) =>
                              ({
                                ...s,
                                assistanceTypes: value
                                  ? value.map((c: ISupportRequestAssistanceType) => c.supportRequestAssistanceTypeId)
                                  : [],
                              } as ISupportWorkerRequest)
                          );
                        }}
                        isMulti={true}
                        isClearable={true}
                        getOptionValue={(option: ISupportRequestAssistanceType) =>
                          option.supportRequestAssistanceTypeId
                        }
                        getOptionLabel={(option: ISupportRequestAssistanceType) => option.name}
                        isDisabled={
                          myUserContext.isSupportWorker ||
                          ((myUserContext.isLawyer || myUserContext.isLegalAssistant) &&
                            params.supportWorkerRequestId !== undefined &&
                            request?.status !== SUPPORT_REQUEST.PENDING &&
                            request?.status !== SUPPORT_REQUEST.REJECTED &&
                            (request?.status !== SUPPORT_REQUEST.REASSIGNED || !isDirty))
                        }
                      />
                      {trySave && request?.assistanceTypes.length === 0 && (
                        <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                          This field is mandatory
                        </span>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label>Details</Label>
                      <Input
                        value={request?.details}
                        type={'textarea'}
                        aria-rowcount={2}
                        placeholder={'Add text here...'}
                        name={'details'}
                        id={'details'}
                        style={{ minHeight: '65px', height: '65px' }}
                        onChange={(e: any) => {
                          const value = e.target.value;
                          setRequest(
                            (s) =>
                              ({
                                ...s,
                                details: value || '',
                              } as ISupportWorkerRequest)
                          );
                        }}
                        disabled={
                          myUserContext.isSupportWorker ||
                          ((myUserContext.isLawyer || myUserContext.isLegalAssistant) &&
                            params.supportWorkerRequestId !== undefined &&
                            request?.status !== SUPPORT_REQUEST.PENDING &&
                            request?.status !== SUPPORT_REQUEST.REJECTED &&
                            (request?.status !== SUPPORT_REQUEST.REASSIGNED || !isDirty))
                        }
                      />
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardHeader>Actions</CardHeader>
                  {request?.status === SUPPORT_REQUEST.ACCEPTED && (
                    <CardBody className={'p-0'}>
                      <ListGroup flush={true}>
                        <ListGroupItem className={'bg-primary-light'}>
                          <FormGroup>
                            <Label style={{ fontWeight: 'initial' }}>Completed Date</Label>
                            <DatePicker
                              selected={completedDate}
                              onChange={(date) => {
                                if (date) {
                                  setCompletedDate(date);
                                }
                              }}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dateFormat='MMM dd, yyyy'
                              dropdownMode='select'
                              className='date-picker rounded mb-0'
                              placeholderText={'Select...'}
                            />
                            {tryComplete && completedDate === undefined && (
                              <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                                This field is mandatory
                              </span>
                            )}
                          </FormGroup>
                          <Button
                            color={supportWorkerStateColor(SUPPORT_REQUEST.COMPLETED)}
                            onClick={() => completeRequest()}
                          >
                            Complete Request
                          </Button>
                        </ListGroupItem>
                        <ReassignListGroupItem
                          tryReassign={tryReassign}
                          reassignReason={reassignReason}
                          setReassignReason={setReassignReason}
                          supportWorkerReassignTo={supportWorkerReassignTo}
                          setSupportWorkerReassignTo={setSupportWorkerReassignTo}
                          setIsDirty={setIsDirty}
                          reassignRequest={reassignRequestWithModal}
                        />
                        <ListGroupItem className={'bg-primary-light'}>
                          <FormGroup>
                            <Label style={{ fontWeight: 'initial' }}>Incomplete Date</Label>
                            <DatePicker
                              selected={incompletedDate}
                              onChange={(date) => {
                                if (date) {
                                  setIncompletedDate(date);
                                }
                              }}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dateFormat='MMM dd, yyyy'
                              dropdownMode='select'
                              className='date-picker rounded mb-0'
                              placeholderText={'Select...'}
                            />
                            {tryIncomplete && incompletedDate === undefined && (
                              <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                                This field is mandatory
                              </span>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label>Reason</Label>
                            <Input
                              value={incompleteReason}
                              type={'text'}
                              placeholder={''}
                              name={'incompleteReason'}
                              id={'incompleteReason'}
                              onChange={(e: any) => {
                                const value = e.target.value;
                                setIncompleteReason(value);
                                setIsDirty(true);
                              }}
                            />
                            {tryIncomplete && (incompleteReason === '' || incompleteReason === undefined) && (
                              <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                                This field is mandatory
                              </span>
                            )}
                          </FormGroup>
                          <Button
                            color={supportWorkerStateColor(SUPPORT_REQUEST.INCOMPLETE)}
                            onClick={() => incompleteRequest()}
                          >
                            Incomplete Request
                          </Button>
                        </ListGroupItem>
                      </ListGroup>
                    </CardBody>
                  )}
                  {request?.status === SUPPORT_REQUEST.COMPLETED && (
                    <CardBody className={'p-0'}>
                      <ListGroup flush={true}>
                        <ListGroupItem className={'bg-primary-light'}>
                          <Button color={'success'} onClick={() => acceptRequest()}>
                            Return Request to Accepted Stage
                          </Button>
                        </ListGroupItem>
                      </ListGroup>
                    </CardBody>
                  )}
                  {request?.status === SUPPORT_REQUEST.INCOMPLETE && (
                    <CardBody className={'p-0'}>
                      <ListGroup flush={true}>
                        <ListGroupItem className={'bg-primary-light'}>
                          <Button color={'success'} onClick={() => acceptRequest()}>
                            Return Request to Accepted Stage
                          </Button>
                        </ListGroupItem>
                        <ListGroupItem className={'bg-primary-light'}>
                          <FormGroup>
                            <Label style={{ fontWeight: 'initial' }}>Completed Date</Label>
                            <DatePicker
                              selected={completedDate}
                              onChange={(date) => {
                                if (date) {
                                  setCompletedDate(date);
                                }
                              }}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dateFormat='MMM dd, yyyy'
                              dropdownMode='select'
                              className='date-picker rounded mb-0'
                              placeholderText={'Select...'}
                            />
                            {tryComplete && completedDate === undefined && (
                              <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                                This field is mandatory
                              </span>
                            )}
                          </FormGroup>
                          <Button
                            color={supportWorkerStateColor(SUPPORT_REQUEST.COMPLETED)}
                            onClick={() => completeRequest()}
                          >
                            Complete Request
                          </Button>
                        </ListGroupItem>
                      </ListGroup>
                    </CardBody>
                  )}
                  {(request?.status === SUPPORT_REQUEST.SUBMITTED ||
                    request?.status === SUPPORT_REQUEST.REASSIGNED) && (
                    <CardBody className={'p-0'}>
                      <ListGroup flush={true}>
                        <ListGroupItem className={'bg-primary-light'}>
                          <Button color={'success'} onClick={() => acceptRequest()}>
                            Accept Request
                          </Button>
                        </ListGroupItem>
                        <ListGroupItem className={'bg-primary-light'}>
                          <FormGroup>
                            <Label>Reason</Label>
                            <Input
                              value={rejectReason}
                              type={'text'}
                              placeholder={''}
                              name={'rejectReason'}
                              id={'rejectReason'}
                              onChange={(e: any) => {
                                const value = e.target.value;
                                setRejectReason(value);
                                setIsDirty(true);
                              }}
                            />
                            {tryReject && (rejectReason === '' || rejectReason === undefined) && (
                              <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                                This field is mandatory
                              </span>
                            )}
                          </FormGroup>
                          <Button color={'danger'} onClick={() => rejectRequest()}>
                            Reject Request
                          </Button>
                        </ListGroupItem>
                        <ReassignListGroupItem
                          tryReassign={tryReassign}
                          reassignReason={reassignReason}
                          setReassignReason={setReassignReason}
                          supportWorkerReassignTo={supportWorkerReassignTo}
                          setSupportWorkerReassignTo={setSupportWorkerReassignTo}
                          setIsDirty={setIsDirty}
                          reassignRequest={reassignRequest}
                        />
                      </ListGroup>
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
          ) : (
            <Row className={'mb-3'}>
              <Col>
                <Card>
                  <CardHeader>Details</CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        {!params.supportWorkerRequestId && (
                          <FormGroup>
                            <RequiredLabel for={'client'} required={!request?.clientId}>
                              Select Client
                            </RequiredLabel>
                            <Select
                              inputValue={clientInputValue}
                              onInputChange={(e) => setClientInputValue(e)}
                              className={'flex-fill'}
                              classNamePrefix={'people-select'}
                              name={'clientId'}
                              options={
                                !!clientInputValue && clientInputValue.length >= 3
                                  ? individuals.filter(
                                      (e) => e.searchString?.includes(clientInputValue.toUpperCase()) && e.active
                                    )
                                  : []
                              }
                              filterOption={createFilter({ ignoreAccents: false })}
                              value={individuals.find((i: IIndividual) => i.individualId === request?.clientId)}
                              onChange={(value: any) => {
                                setRequest(
                                  (s) =>
                                    ({
                                      ...s,
                                      clientId: value?.individualId || undefined,
                                    } as ISupportWorkerRequest)
                                );
                                setIsDirty(true);
                              }}
                              isDisabled={params.supportWorkerRequestId !== undefined}
                              getOptionValue={(option: IIndividual) => option.individualId}
                              getOptionLabel={(o: IIndividual) => `${o.searchString}`}
                              isClearable={true}
                              components={{ SingleValue, Option }}
                              noOptionsMessage={() =>
                                !!clientInputValue && clientInputValue.length >= 3
                                  ? 'No individuals match your search. Add a new individual or try again.'
                                  : 'Enter at least 3 characters to search.'
                              }
                              styles={{
                                singleValue: (base) => ({
                                  ...base,
                                  position: 'relative',
                                  top: 0,
                                  transform: 'translateY(0)',
                                  height: '100%',
                                  padding: '0.25em 0',
                                }),
                              }}
                            />
                            {trySave && request?.clientId === '' && (
                              <span style={{ fontSize: 13, color: '#B22222' }}>
                                This field is mandatory to save application
                              </span>
                            )}
                            <FormFeedback>This field is mandatory</FormFeedback>
                          </FormGroup>
                        )}
                      </Col>
                      <Col />
                    </Row>
                    {request?.clientId && (
                      <>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for={'lawyerId'}>Lawyer</Label>
                              <Select
                                className={'w-100'}
                                classNamePrefix={'people-select'}
                                name={'lawyerId'}
                                options={peopleContext.lawyers.filter(
                                  (e) => e.active && lawyerIdOptions.includes(e.userId)
                                )}
                                value={peopleContext.lawyers.find((i: IUser) => i.userId === request?.lawyerId)}
                                onChange={(value: any) => {
                                  setRequest(
                                    (s) =>
                                      ({
                                        ...s,
                                        lawyerId: value?.userId,
                                      } as ISupportWorkerRequest)
                                  );
                                  setIsDirty(true);
                                }}
                                getOptionValue={(option: IUser) => option.userId}
                                getOptionLabel={(o: IUser) => o.displayName}
                                isDisabled={
                                  myUserContext.isSupportWorker ||
                                  myUserContext.isLawyer ||
                                  (myUserContext.isLegalAssistant &&
                                    params.supportWorkerRequestId !== undefined &&
                                    request?.status !== SUPPORT_REQUEST.PENDING &&
                                    request?.status !== SUPPORT_REQUEST.REJECTED &&
                                    (request?.status !== SUPPORT_REQUEST.REASSIGNED ||
                                      (request?.status === SUPPORT_REQUEST.REASSIGNED && !isDirty)))
                                }
                                isClearable={true}
                                styles={{
                                  menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col />
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>Support Worker Requested</Label>
                              <Select
                                classNamePrefix={'user-select'}
                                id={'supportWorkerId'}
                                name={'supportWorkerId'}
                                styles={{
                                  singleValue: (base) => ({
                                    ...base,
                                    position: 'relative',
                                    top: 0,
                                    transform: 'translateY(0)',
                                    height: '100%',
                                    padding: '0.25em 0',
                                  }),
                                  menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
                                }}
                                options={
                                  peopleContext.supportWorkers
                                    .filter((sw) => sw.active)
                                    .sort((a: IUser, b: IUser) =>
                                      a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase())
                                    ) as IUser[]
                                }
                                value={peopleContext.supportWorkers.find(
                                  (i: IUser) => request?.supportWorkerId === i.userId
                                )}
                                onChange={(value: any) => {
                                  const newSupportWorkerId = value ? value.userId : '';
                                  setRequest(
                                    (s) =>
                                      ({
                                        ...s,
                                        supportWorkerId: newSupportWorkerId,
                                        status:
                                          params.supportWorkerRequestId && request?.status !== SUPPORT_REQUEST.REJECTED
                                            ? SUPPORT_REQUEST.REASSIGNED
                                            : s?.status,
                                      } as ISupportWorkerRequest)
                                  );
                                  setIsDirty(true);
                                }}
                                getOptionValue={(option: IUser) => option.userId}
                                getOptionLabel={(option: IUser) => {
                                  const supportWorkerType = domainContext.supportWorkerTypes.find(
                                    (swt) => swt.supportWorkerTypeId === option.supportWorkerTypeId
                                  );
                                  return (
                                    option.lastName.toUpperCase() +
                                    ', ' +
                                    option.firstName +
                                    (supportWorkerType ? ' (' + supportWorkerType?.name + ')' : '') +
                                    (option.active ? '' : ' (Inactive)')
                                  );
                                }}
                                isClearable={true}
                                menuPlacement={'auto'}
                                isDisabled={
                                  myUserContext.isSupportWorker ||
                                  ((myUserContext.isLawyer || myUserContext.isLegalAssistant) &&
                                    params.supportWorkerRequestId !== undefined &&
                                    request?.status !== SUPPORT_REQUEST.PENDING &&
                                    request?.status !== SUPPORT_REQUEST.REJECTED &&
                                    (request?.status !== SUPPORT_REQUEST.REASSIGNED ||
                                      (request?.status === SUPPORT_REQUEST.REASSIGNED && !isDirty)))
                                }
                              />
                              {trySave && request?.supportWorkerId === '' && (
                                <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                                  This field is mandatory
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                          <Col>
                            {!myUserContext.isSupportWorker && (
                              <FormGroup>
                                <Label>Files</Label>
                                <Select
                                  styles={disabledSelectStyles}
                                  name={'supportRequestCriteria'}
                                  options={fileOptions.filter((f) => f.lawyerId === request?.lawyerId)}
                                  placeholder={<span className='placeholder'>Select...</span>}
                                  value={fileOptions.filter((f: IFile) => request?.fileIds.includes(f.fileId))}
                                  onChange={(value: any) => {
                                    setRequest(
                                      (s) =>
                                        ({
                                          ...s,
                                          fileIds: value ? value.map((f: IFile) => f.fileId) : [],
                                        } as ISupportWorkerRequest)
                                    );
                                    setIsDirty(true);
                                  }}
                                  isMulti={true}
                                  isClearable={true}
                                  getOptionValue={(option: IFile) => option.fileId}
                                  getOptionLabel={(option: IFile) =>
                                    option.courtNumber ? option.courtNumber : 'Law Office #: ' + option.lawOfficeNumber
                                  }
                                  isDisabled={
                                    !request?.lawyerId ||
                                    ((myUserContext.isLawyer ||
                                      myUserContext.isLegalAssistant ||
                                      myUserContext.isSupportWorker) &&
                                      params.supportWorkerRequestId !== undefined &&
                                      request?.status !== SUPPORT_REQUEST.PENDING &&
                                      request?.status !== SUPPORT_REQUEST.REJECTED &&
                                      (request?.status !== SUPPORT_REQUEST.REASSIGNED || !isDirty))
                                  }
                                />
                                {trySave && request?.criteriaForAssistance.length === 0 && (
                                  <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                                    This field is mandatory
                                  </span>
                                )}
                              </FormGroup>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}
                    {request?.clientId && (
                      <>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>Criteria For Assistance</Label>
                              <Select
                                styles={disabledSelectStyles}
                                name={'supportRequestCriteria'}
                                options={
                                  areAllCriteriaOptionsSelected()
                                    ? supportRequestCriteria
                                    : [selectAllOption, ...supportRequestCriteria]
                                }
                                placeholder={<span className='placeholder'>Select...</span>}
                                value={supportRequestCriteria.filter((d: ISupportRequestCriteria) =>
                                  request?.criteriaForAssistance.includes(d.supportRequestCriteriaId)
                                )}
                                onChange={(value: any) => {
                                  setIsDirty(true);
                                  setRequest(
                                    (s) =>
                                      ({
                                        ...s,
                                        criteriaForAssistance: value
                                          ? value
                                              .map((c: ISupportRequestCriteria) => c.supportRequestCriteriaId)
                                              .includes(SELECT_ALL_ID)
                                            ? supportRequestCriteria.map(
                                                (c: ISupportRequestCriteria) => c.supportRequestCriteriaId
                                              )
                                            : value.map((c: ISupportRequestCriteria) => c.supportRequestCriteriaId)
                                          : [],
                                      } as ISupportWorkerRequest)
                                  );
                                }}
                                isMulti={true}
                                isClearable={true}
                                getOptionValue={(option: ISupportRequestCriteria) => option.supportRequestCriteriaId}
                                getOptionLabel={(option: ISupportRequestCriteria) => option.name}
                                isDisabled={
                                  (myUserContext.isLawyer ||
                                    myUserContext.isLegalAssistant ||
                                    myUserContext.isSupportWorker) &&
                                  params.supportWorkerRequestId !== undefined &&
                                  request?.status !== SUPPORT_REQUEST.PENDING &&
                                  request?.status !== SUPPORT_REQUEST.REJECTED &&
                                  (request?.status !== SUPPORT_REQUEST.REASSIGNED || !isDirty)
                                }
                              />
                              {trySave && request?.criteriaForAssistance.length === 0 && (
                                <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                                  This field is mandatory
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>Assistance Type</Label>
                              <Select
                                styles={disabledSelectStyles}
                                name={'supportRequestAssistanceTypes'}
                                options={supportRequestAssistanceTypes}
                                placeholder={<span className='placeholder'>Select...</span>}
                                value={supportRequestAssistanceTypes.filter((d: ISupportRequestAssistanceType) =>
                                  request?.assistanceTypes.includes(d.supportRequestAssistanceTypeId)
                                )}
                                onChange={(value: any) => {
                                  setIsDirty(true);
                                  setRequest(
                                    (s) =>
                                      ({
                                        ...s,
                                        assistanceTypes: value
                                          ? value.map(
                                              (c: ISupportRequestAssistanceType) => c.supportRequestAssistanceTypeId
                                            )
                                          : [],
                                      } as ISupportWorkerRequest)
                                  );
                                }}
                                isMulti={true}
                                isClearable={true}
                                getOptionValue={(option: ISupportRequestAssistanceType) =>
                                  option.supportRequestAssistanceTypeId
                                }
                                getOptionLabel={(option: ISupportRequestAssistanceType) => option.name}
                                isDisabled={
                                  (myUserContext.isLawyer ||
                                    myUserContext.isLegalAssistant ||
                                    myUserContext.isSupportWorker) &&
                                  params.supportWorkerRequestId !== undefined &&
                                  request?.status !== SUPPORT_REQUEST.PENDING &&
                                  request?.status !== SUPPORT_REQUEST.REJECTED &&
                                  (request?.status !== SUPPORT_REQUEST.REASSIGNED || !isDirty)
                                }
                              />
                              {trySave && request?.assistanceTypes.length === 0 && (
                                <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                                  This field is mandatory
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>Details</Label>
                              <Input
                                value={request?.details}
                                type={'textarea'}
                                aria-rowcount={2}
                                placeholder={'Add text here...'}
                                name={'details'}
                                id={'details'}
                                style={{ minHeight: '65px', height: '65px' }}
                                onChange={(e: any) => {
                                  const value = e.target.value;
                                  setRequest(
                                    (s) =>
                                      ({
                                        ...s,
                                        details: value || '',
                                      } as ISupportWorkerRequest)
                                  );
                                }}
                                disabled={
                                  (myUserContext.isLawyer ||
                                    myUserContext.isLegalAssistant ||
                                    myUserContext.isSupportWorker) &&
                                  params.supportWorkerRequestId !== undefined &&
                                  request?.status !== SUPPORT_REQUEST.PENDING &&
                                  request?.status !== SUPPORT_REQUEST.REJECTED &&
                                  (request?.status !== SUPPORT_REQUEST.REASSIGNED || !isDirty)
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        {myUserContext.isSupportWorker && (
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label>Files</Label>
                                <div className='d-flex flex-column'>
                                  {fileOptions
                                    .filter((f: IFile) => request?.fileIds.includes(f.fileId))
                                    .map((file, number) => (
                                      <div key={number}>
                                        <Link target='_blank' to={{ pathname: '/files/' + file.fileId }}>
                                          {file.courtNumber
                                            ? file.courtNumber
                                            : 'Law Office #: ' + file.lawOfficeNumber}
                                        </Link>
                                      </div>
                                    ))}
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {request?.requestHistory && request?.requestHistory.length > 0 && (
            <Row>
              <Col>
                <Card>
                  <CardHeader>Request History</CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Row>
                            <Col>
                              <Label>Requested Support Worker</Label>
                            </Col>
                            <Col>
                              <Label>Status</Label>
                            </Col>
                            <Col>
                              <Label>Reason</Label>
                            </Col>
                            <Col>
                              <Label>Date Updated</Label>
                            </Col>
                            <Col>
                              <Label>Updated by</Label>
                            </Col>
                          </Row>
                          <ListGroup>
                            {request?.requestHistory.map((rh) => (
                              <ListGroupItem key={rh.auditTimestamp}>
                                <Row>
                                  <Col>
                                    {
                                      peopleContext.supportWorkers.find((i: IUser) => rh.supportWorkerId === i.userId)
                                        ?.displayName
                                    }
                                  </Col>
                                  <Col>{rh.status}</Col>
                                  <Col>{rh.reason}</Col>
                                  <Col>
                                    {rh.status === SUPPORT_REQUEST.COMPLETED && rh.completedDate
                                      ? formatDate(new Date(rh.completedDate))
                                      : rh.status === SUPPORT_REQUEST.INCOMPLETE && rh.incompleteDate
                                      ? formatDate(new Date(rh.incompleteDate))
                                      : formatDateTime(new Date(rh.auditTimestamp))}
                                  </Col>
                                  <Col>
                                    {peopleContext.staff.find((i: IUser) => rh.auditUserId === i.userId)?.displayName}
                                  </Col>
                                </Row>
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </form>
      <ConfirmModal
        isOpen={deleteModalOpen}
        title={'Delete Support Request'}
        onConfirm={() => {
          setDeleteModalOpen(false);
          deleteSupportWorkerRequest();
        }}
        onCancel={() => setDeleteModalOpen(false)}
        confirmButton={'Delete Support Request'}
      >
        <div>Are you sure you want to delete this support request?</div>
      </ConfirmModal>
    </div>
  );
};

const ReassignListGroupItem: React.FC<{
  tryReassign: boolean;
  reassignReason: string;
  setReassignReason: React.Dispatch<React.SetStateAction<string>>;
  supportWorkerReassignTo: string;
  setSupportWorkerReassignTo: React.Dispatch<React.SetStateAction<string>>;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  reassignRequest: () => void;
}> = ({
  tryReassign,
  supportWorkerReassignTo,
  reassignReason,
  setReassignReason,
  setSupportWorkerReassignTo,
  setIsDirty,
  reassignRequest,
}) => {
  const peopleContext = useContext(PeopleContext);
  const domainContext = useContext(DomainContext);
  return (
    <ListGroupItem className={'bg-primary-light'}>
      <FormGroup>
        <Label>Support Worker To Reassign To</Label>
        <Select
          classNamePrefix={'user-select'}
          id={'supportWorkerId'}
          name={'supportWorkerId'}
          styles={{
            singleValue: (base) => ({
              ...base,
              position: 'relative',
              top: 0,
              transform: 'translateY(0)',
              height: '100%',
              padding: '0.25em 0',
            }),
            menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
          }}
          options={
            peopleContext.supportWorkers
              .filter((sw) => sw.active && sw.userId !== getUserId())
              .sort((a: IUser, b: IUser) => a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase())) as IUser[]
          }
          value={peopleContext.supportWorkers.find((i: IUser) => supportWorkerReassignTo === i.userId)}
          onChange={(value: any) => {
            const newSupportWorkerId = value ? value.userId : '';
            setSupportWorkerReassignTo(newSupportWorkerId);
            setIsDirty(true);
          }}
          getOptionValue={(option: IUser) => option.userId}
          getOptionLabel={(option: IUser) => {
            const supportWorkerType = domainContext.supportWorkerTypes.find(
              (swt) => swt.supportWorkerTypeId === option.supportWorkerTypeId
            );
            return (
              option.lastName.toUpperCase() +
              ', ' +
              option.firstName +
              (supportWorkerType ? ' (' + supportWorkerType?.name + ')' : '') +
              (option.active ? '' : ' (Inactive)')
            );
          }}
          isClearable={true}
          menuPlacement={'auto'}
        />
        {tryReassign && supportWorkerReassignTo === '' && (
          <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
            This field is mandatory
          </span>
        )}
      </FormGroup>
      <FormGroup>
        <Label>Reason</Label>
        <Input
          value={reassignReason}
          type={'text'}
          placeholder={''}
          name={'reassignReason'}
          id={'reassignReason'}
          onChange={(e: any) => {
            const value = e.target.value;
            setReassignReason(value);
            setIsDirty(true);
          }}
        />
        {tryReassign && (reassignReason === '' || reassignReason === undefined) && (
          <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
            This field is mandatory
          </span>
        )}
      </FormGroup>
      <Button color={'warning'} onClick={() => reassignRequest()}>
        Reassign Request
      </Button>
    </ListGroupItem>
  );
};
