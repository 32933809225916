import * as React from 'react';
import { useContext, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import { DomainContext, IReason, STAGE_APPROVED, STAGE_REFUSED } from '../../../contexts/DomainContext';
import { authAxios } from '../../../services/AxiosService';
import { notifyError, notifySuccess } from '../../../services/ToastService';
import { FileContext } from '../FileContext';
import { useParams } from 'react-router';
import Select from 'react-select';
import { ConfirmModal } from '../../ConfirmModal/ConfirmModal';

export const ReviewActions: React.FC = () => {
  const params = useParams<{ fileId: string }>();
  const fileContext = useContext(FileContext);
  const domainContext = useContext(DomainContext);
  const setFile = fileContext.setFile;
  const [reviewReason, setReviewReason] = useState('');
  const [reasonId, setReasonId] = useState('');
  const [trySubmit, setTrySubmit] = useState(false);
  const reasons = domainContext.reasons;
  const [approveModal, setApproveModal] = useState(false);
  const [refuseModal, setRefuseModal] = useState(false);

  const approveApplication = () => {
    if (params.fileId) {
      const data = { fileId: params.fileId, stageId: STAGE_APPROVED };
      authAxios.post('/api/files/admin/start-stage', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('Stage updated.');
          setFile((f) => ({ ...f, stageId: STAGE_APPROVED, approvalType: 'Executive Director' }));
        } else {
          notifyError(response.data.errorMessage);
        }
      });
    }
  };

  const refuseApplication = (reasonId: string, reason: string) => {
    if (params.fileId) {
      const data = { fileId: params.fileId, stageId: STAGE_REFUSED, reasonId, reason };
      authAxios.post('/api/files/admin/start-stage', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('Stage updated.');
          setFile((f) => ({ ...f, stageId: STAGE_REFUSED, reasonId: reasonId, reasonRejected: reason }));
        } else {
          notifyError(response.data.errorMessage);
        }
      });
    }
  };

  return (
    <>
      <Card className={'bg-primary-light'}>
        <CardHeader>Application Process</CardHeader>
        <CardBody style={{ padding: 0 }}>
          <ListGroup flush={true}>
            <ListGroupItem className={'bg-primary-light'}>
              <Button color='success' className='ml-auto' onClick={() => setApproveModal(true)}>
                Approve Application
              </Button>
            </ListGroupItem>
            <ListGroupItem className={'bg-primary-light'}>
              <FormGroup>
                <Label for={'reasonId'}>
                  Reason for Refusal <span className='text-required'> (required)</span>
                </Label>
                <Select
                  name={'reasonId'}
                  options={reasons}
                  placeholder={'If refusing, select a reason'}
                  value={reasons.find((r: IReason) => r.reasonId === reasonId)}
                  onChange={(value: any) => {
                    setReasonId(value?.reasonId || '');
                  }}
                  getOptionValue={(option: IReason) => option.reasonId}
                  getOptionLabel={(option: IReason) => option.name}
                  isClearable={true}
                />
                {trySubmit && reasonId === '' && (
                  <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                )}
                <FormFeedback>This field is mandatory</FormFeedback>
              </FormGroup>

              <FormGroup>
                <Label for={'reason'}>Refusal Notes</Label>
                <Input
                  value={reviewReason}
                  type={'text'}
                  name={'reason'}
                  id={'reason'}
                  onChange={(e: any) => setReviewReason(e.target.value)}
                />
              </FormGroup>
              <Button
                color='danger'
                className='ml-auto'
                onClick={() => {
                  if (reasonId === '') {
                    setTrySubmit(true);
                  } else {
                    setRefuseModal(true);
                  }
                }}
              >
                Refuse Application
              </Button>
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>
      <ConfirmModal
        isOpen={approveModal}
        title={'Approve'}
        onConfirm={() => {
          setApproveModal(false);
          approveApplication();
        }}
        onCancel={() => setApproveModal(false)}
        confirmButton={'Approve'}
      >
        <div>Are you sure you want to Approve?</div>
      </ConfirmModal>
      <ConfirmModal
        isOpen={refuseModal}
        title={'Refuse'}
        onConfirm={() => {
          setRefuseModal(false);
          refuseApplication(reasonId, reviewReason);
        }}
        onCancel={() => setRefuseModal(false)}
        confirmButton={'Refuse'}
      >
        <div>Are you sure you want to Refuse?</div>
      </ConfirmModal>
    </>
  );
};
