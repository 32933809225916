import * as React from 'react';
import { useContext, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import { STAGE_COUNSEL_REQUEST_DRAFT, STAGE_LAWYER_REQUESTED } from '../../../contexts/DomainContext';
import { authAxios } from '../../../services/AxiosService';
import { notifyError, notifySuccess } from '../../../services/ToastService';
import { FileContext } from '../FileContext';
import { useParams } from 'react-router';
import { ConfirmModal } from '../../ConfirmModal/ConfirmModal';

export const CounselRequestActions: React.FC = () => {
  const params = useParams<{ fileId: string }>();
  const fileContext = useContext(FileContext);
  const setFile = fileContext.setFile;
  const [returnReason, setReturnReason] = useState('');
  const [trySubmit, setTrySubmit] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [returnModal, setReturnModal] = useState(false);

  const acceptApplication = () => {
    if (params.fileId) {
      const data = { fileId: params.fileId, stageId: STAGE_LAWYER_REQUESTED };
      authAxios.post('/api/files/admin/accept-counsel-request', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('Stage updated.');
          setFile((f) => ({ ...f, stageId: STAGE_LAWYER_REQUESTED, fileNumber: response.data.fileNumber }));
        } else {
          notifyError(response.data.errorMessage);
        }
      });
    }
  };

  const returnCounselRequest = (reason: string) => {
    if (params.fileId) {
      const data = { fileId: params.fileId, stageId: STAGE_COUNSEL_REQUEST_DRAFT, reason };
      authAxios.post('/api/files/admin/start-stage', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('Stage updated.');
          setFile((f) => ({ ...f, stageId: STAGE_COUNSEL_REQUEST_DRAFT }));
        } else {
          notifyError(response.data.errorMessage);
        }
      });
    }
  };

  return (
    <>
      <Card className={'bg-primary-light mb-3'}>
        <CardHeader>Application Process</CardHeader>
        <CardBody style={{ padding: 0 }}>
          <ListGroup flush={true}>
            <ListGroupItem className={'bg-primary-light'}>
              <Button color='success' className='ml-auto' onClick={() => setAcceptModal(true)}>
                Accept Counsel Request
              </Button>
            </ListGroupItem>
            <ListGroupItem className={'bg-primary-light'}>
              <FormGroup>
                <Label for={'reason'}>Return Notes</Label>
                <Input
                  value={returnReason}
                  type={'text'}
                  name={'reason'}
                  id={'reason'}
                  onChange={(e: any) => setReturnReason(e.target.value)}
                  invalid={trySubmit && !returnReason}
                />
                <FormFeedback>This field is mandatory</FormFeedback>
              </FormGroup>
              <Button
                color='danger'
                className='ml-auto'
                onClick={() => {
                  if (returnReason === '') {
                    setTrySubmit(true);
                  } else {
                    setReturnModal(true);
                  }
                }}
              >
                Return Counsel Request
              </Button>
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>
      <ConfirmModal
        isOpen={acceptModal}
        title={'Accept'}
        onConfirm={() => {
          setAcceptModal(false);
          acceptApplication();
        }}
        onCancel={() => setAcceptModal(false)}
        confirmButton={'Accept'}
      >
        <div>Are you sure you want to Accept this counsel request?</div>
      </ConfirmModal>
      <ConfirmModal
        isOpen={returnModal}
        title={'Return'}
        onConfirm={() => {
          setReturnModal(false);
          returnCounselRequest(returnReason);
        }}
        onCancel={() => setReturnModal(false)}
        confirmButton={'Return'}
      >
        <div>Are you sure you want to return this request?</div>
      </ConfirmModal>
    </>
  );
};
