import { Button, Card, CardBody, CardHeader, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import * as React from 'react';
import { useContext, useState } from 'react';
import { STAGE_ERROR, STAGE_PENDING } from '../../../contexts/DomainContext';
import { authAxios } from '../../../services/AxiosService';
import { notifyError, notifySuccess } from '../../../services/ToastService';
import { useParams } from 'react-router';
import { FileContext } from '../FileContext';
import { ConfirmModal } from '../../ConfirmModal/ConfirmModal';
import { RequiredLabel } from '../../RequiredLabel';

export const ErrorFile = () => {
  const params = useParams<{ fileId: string }>();
  const fileContext = useContext(FileContext);
  const setFile = fileContext.setFile;

  const [tryError, setTryError] = useState(false);
  const [modal, setModal] = useState(false);
  const [reopenModal, setReopenModal] = useState(false);
  const [errorReason, setErrorReason] = useState('');

  const errorFile = () => {
    if (errorReason && params.fileId) {
      const data = { fileId: params.fileId, stageId: STAGE_ERROR, reason: errorReason };
      authAxios.post('/api/files/admin/start-stage', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('Stage updated.');
          setFile((f) => ({ ...f, stageId: STAGE_ERROR, errorReason: errorReason }));
        } else {
          notifyError(response.data.errorMessage);
        }
      });
    } else {
      setTryError(true);
    }
  };

  const updateStage = (stageId: string) => {
    if (params.fileId) {
      const data = { fileId: params.fileId, stageId };
      authAxios.post('/api/files/admin/start-stage', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('File stage has been updated.');
          setFile((f) => ({ ...f, stageId: stageId }));
        } else {
          notifyError(response.data.status);
        }
      });
    }
  };

  return (
    <>
      <Card className={'my-4'}>
        <CardHeader>Error File</CardHeader>
        <CardBody className={'pt-2'}>
          {fileContext.file.stageId === STAGE_ERROR ? (
            <>
              <Label>Error Reason</Label>
              <div className={'div--read-only'}>{fileContext.file.errorReason || ''}</div>
              <Button color='success' className={'mt-3 ml-auto'} onClick={() => setReopenModal(true)}>
                Reopen File
              </Button>
            </>
          ) : (
            <>
              <FormGroup>
                <RequiredLabel required={!errorReason} for={'reason'}>
                  Reason
                </RequiredLabel>
                <Input
                  value={errorReason}
                  placeholder={'Add reason here...'}
                  type={'text'}
                  name={'Reason'}
                  onChange={(e: any) => setErrorReason(e.target.value)}
                  invalid={tryError && !errorReason}
                />
                <FormFeedback>This field is mandatory</FormFeedback>
              </FormGroup>
              <Button
                color={'danger'}
                className={'mt-3'}
                onClick={() => {
                  if (errorReason) {
                    setModal(true);
                  } else {
                    setTryError(true);
                  }
                }}
              >
                Error File
              </Button>
            </>
          )}
        </CardBody>
      </Card>{' '}
      <ConfirmModal
        isOpen={modal}
        title={'Error File'}
        onConfirm={() => {
          setModal(false);
          errorFile();
        }}
        onCancel={() => setModal(false)}
        confirmButton={'Error File'}
      >
        <div>Are you sure you want to error this file?</div>
      </ConfirmModal>
      <ConfirmModal
        isOpen={reopenModal}
        title={'Reopen File'}
        onConfirm={() => {
          setReopenModal(false);
          updateStage(STAGE_PENDING);
        }}
        onCancel={() => setReopenModal(false)}
        confirmButton={'Reopen File'}
      >
        <div>
          Re-opening this file will move it back to the pending stage. Are you sure that you want to re-open this file?
        </div>
      </ConfirmModal>
    </>
  );
};
