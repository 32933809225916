import * as React from 'react';
import { useState } from 'react';
import { IPhoneNumber } from './IndividualDetails';

export interface IIndividual {
  individualId: string;
  formattedName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  birthDate?: number;
  birthDateFormatted: string;
  email: string;
  phoneNumbers: IPhoneNumber[];
  visibleMinority: boolean;
  indigenous: boolean;
  active: boolean;
  aliases: string[];
  aliasesFormatted: string;
  genderId?: string;
  lastUpdated: number;
  lastUpdatedFormatted?: string;
  searchString: string;
  yukonFirstNationId?: string;
}

export interface IAddress {
  addressId: string;
  individualId: string;
  label: string;
  line1: string;
  line2: string;
  city: string;
  territory: string;
  postalCode: string;
  isCurrent: boolean;
  isEditing?: boolean;
}

type ContextType = {
  individual: IIndividual;
  setIndividual: React.Dispatch<React.SetStateAction<IIndividual>>;
  addresses: IAddress[];
  setAddresses: React.Dispatch<React.SetStateAction<IAddress[]>>;
};

export const IndividualContext = React.createContext<ContextType>({
  individual: {} as IIndividual,
  setIndividual: () => {},
  addresses: [] as IAddress[],
  setAddresses: () => {},
});

export const IndividualProvider = (props: any) => {
  const [individual, setIndividual] = useState({} as IIndividual);
  const [addresses, setAddresses] = useState([] as IAddress[]);

  return (
    <IndividualContext.Provider
      value={{
        individual,
        setIndividual,
        addresses,
        setAddresses,
      }}
    >
      {props.children}
    </IndividualContext.Provider>
  );
};

export const IndividualConsumer = IndividualContext.Consumer;
