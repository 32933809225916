import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, CustomInput, Navbar, NavbarBrand } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { FaChevronDown, FaChevronUp, FaPlus } from 'react-icons/fa';
import { Column, TableInstance, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { TablePagination } from '../TablePagination/TablePagination';
import { IIndividual } from './IndividualContext';
import { IndividualsContext } from '../../contexts/IndividualsContext';
import { IndividualsTableContext } from './IndividualsTableContext';
import { PersistantTableGlobalFilter } from '../TableGlobalFilter/PersistantTableGlobalFilter';
import { DateRangeDropdown } from '../DateRangeDropdown/DateRangeDropdown';
import { MyUserContext } from '../../contexts/MyUserContext';

export const IndividualsTable: React.FC = () => {
  const history = useHistory();
  const individualsContext = useContext(IndividualsContext);
  const searchContext = useContext(IndividualsTableContext);
  const individuals = individualsContext.state.individuals;
  const myUserContext = useContext(MyUserContext);
  const [showInactive, setShowInactive] = useState(true);

  const columns: Column<IIndividual>[] = useMemo(
    () => [
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
        Cell: (cell: any) => {
          if (cell && cell.row.original && !cell.row.original.active) {
            return cell.row.original.firstName + ' (Inactive)';
          } else {
            return cell.row.original.firstName;
          }
        },
      },
      {
        Header: 'Middle Name',
        accessor: 'middleName',
      },
      {
        Header: 'Aliases',
        accessor: 'aliasesFormatted',
      },
      {
        Header: 'Birth Date',
        accessor: 'birthDateFormatted',
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          if (rowA.original['birthDate'] && rowB.original['birthDate']) {
            if (rowA.original['birthDate'] > rowB.original['birthDate']) return 1;
            if (rowB.original['birthDate'] > rowA.original['birthDate']) return -1;
            return 0;
          } else if (rowA.original['birthDate'] && !rowB.original['birthDate']) {
            return 1;
          } else if (rowB.original['birthDate'] && !rowA.original['birthDate']) {
            return -1;
          } else {
            return 0;
          }
        },
        filter: (rows: any, id: any, filterValue: { startDate?: Date; endDate?: Date }) => {
          return rows.filter((row: any) => {
            if (!filterValue.startDate || !filterValue.endDate) {
              return true;
            } else {
              const rowValue = row.original['birthDate'];
              return rowValue > filterValue.startDate.getTime() && rowValue < filterValue.endDate.getTime();
            }
          });
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => (
          <DateRangeDropdown
            startDate={filterValue?.startDate}
            endDate={filterValue?.endDate}
            setRange={(dates: { startDate?: Date; endDate?: Date }) => {
              setFilter(dates);
            }}
            keyPrefix={'birthDate'}
          />
        ),
      },
      {
        Header: 'Last Updated',
        accessor: 'lastUpdatedFormatted',
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          if (rowA.original['lastUpdated'] && rowB.original['lastUpdated']) {
            if (rowA.original['lastUpdated'] > rowB.original['lastUpdated']) return 1;
            if (rowB.original['lastUpdated'] > rowA.original['lastUpdated']) return -1;
            return 0;
          } else if (rowA.original['lastUpdated'] && !rowB.original['lastUpdated']) {
            return 1;
          } else if (rowB.original['lastUpdated'] && !rowA.original['lastUpdated']) {
            return -1;
          } else {
            return 0;
          }
        },
        filter: (rows: any, id: any, filterValue: { startDate?: Date; endDate?: Date }) => {
          return rows.filter((row: any) => {
            if (!filterValue.startDate || !filterValue.endDate) {
              return true;
            } else {
              const rowValue = row.original['lastUpdated'];
              return rowValue > filterValue.startDate.getTime() && rowValue < filterValue.endDate.getTime();
            }
          });
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => (
          <DateRangeDropdown
            startDate={filterValue?.startDate}
            endDate={filterValue?.endDate}
            setRange={(dates: { startDate?: Date; endDate?: Date }) => {
              setFilter(dates);
            }}
            keyPrefix={'lastUpdated'}
          />
        ),
      },
      {
        Header: 'Formatted Name',
        accessor: 'formattedName',
      },
    ],
    []
  );

  const defaultColumn = useDefaultColumn();

  const data: IIndividual[] = useMemo(
    () => individuals.filter((i) => (showInactive ? true : i.active)),
    [individuals, showInactive]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,

    prepareRow,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, filters, globalFilter },
    setAllFilters,
  }: TableInstance<IIndividual> = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        filters: searchContext.filters,
        globalFilter: searchContext.selectedGlobalSearch,
        hiddenColumns: ['formattedName'],
        sortBy: [
          {
            id: 'lastUpdatedFormatted',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    searchContext.setFilters(filters);
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const selectRow = (individualId: string) => {
    if (myUserContext.isIntakeOffice || myUserContext.isManagement) {
      history.push('/individuals/' + individualId);
    } else {
      history.push('/individuals/' + individualId + '/associated-files');
    }
  };

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'}>
        <NavbarBrand className='mr-auto'>Individuals</NavbarBrand>
        <CustomInput
          className={'mr-3'}
          type={'checkbox'}
          id={'show-inactive'}
          key={'show-inactive'}
          name={'show-inactive'}
          label={'Show inactive individuals'}
          checked={showInactive}
          onChange={() => setShowInactive(!showInactive)}
        />
        <Button
          className={'mr-2'}
          color={'primary'}
          tag={Link}
          onClick={() => {
            searchContext.setSelectedGlobalSearch('');
            setGlobalFilter('');
            setAllFilters([]);
          }}
        >
          Clear Filters
        </Button>
        {myUserContext.isSuperAdmin && (
          <Button className={'mr-2'} color={'secondary'} tag={Link} to={{ pathname: '/individuals/merge' }}>
            Merge Individuals
          </Button>
        )}
        {!myUserContext.isManagement && (
          <Button color={'success'} tag={Link} to={{ pathname: '/individuals/add' }}>
            <FaPlus className='mr-2' />
            Add Individual
          </Button>
        )}
      </Navbar>
      <div className={'table-responsive'} style={{ minHeight: '800px' }}>
        <table className={'table table-bordered table-hover'} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`individuals-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={`individuals-table-thead-tr-${index}-${column.id}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                    <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
            <tr>
              <PersistantTableGlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                colSpan={visibleColumns.length}
                value={searchContext.selectedGlobalSearch}
                setValue={searchContext.setSelectedGlobalSearch}
              />
            </tr>
          </thead>
          <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
            {page.map((row: any) => {
              prepareRow(row);

              return (
                <tr key={`individuals-table-tr-${row.id}`} {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        key={`individuals-table-td-${cell.row.id}-${cell.column.id}`}
                        {...cell.getCellProps()}
                        onClick={() => selectRow(row.original.individualId)}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizes={[20, 50, 100, 500]}
      />
    </div>
  );
};
