import { ICharge } from '../components/Files/FileContext';
import { useCallback, useContext } from 'react';
import { DomainContext } from '../contexts/DomainContext';

export const useMostSerious = () => {
  const { offences } = useContext(DomainContext);

  return useCallback(
    (applicationTypeId: string, charges: ICharge[]) => {
      if (
        applicationTypeId === '5ed8bcb6-87e3-42d6-9aea-3ffa3939391a' ||
        applicationTypeId === '2a4a2269-40a4-4718-9884-da2d51c0d5ab'
      ) {
        const groups = charges.map((c: ICharge) => {
          const offence = offences.find((o) => o.offenceId === c.offenceId);
          if (offence && offence.group) {
            return offence.group;
          } else {
            return 6;
          }
        });
        let lowestGroup = 6;
        groups.forEach((g: any) => {
          if (g < lowestGroup) {
            lowestGroup = g;
          }
        });
        return lowestGroup < 6 && lowestGroup >= 0 ? lowestGroup : '';
      } else {
        return '';
      }
    },
    [offences]
  );
};
