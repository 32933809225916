import * as React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  CustomInput,
  Label,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { FaPrint } from 'react-icons/fa';
import { useContext, useEffect, useState } from 'react';
import { IReportingFile } from '../Files/FileContext';
import {
  APPLICATION_TYPE_ID_ADULT_CRIMINAL,
  APPLICATION_TYPE_ID_TERRITORIAL_STATUTES,
  APPLICATION_TYPE_ID_YOUTH_CRIMINAL,
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_OTHER,
  ICircuitLocation,
  IOffenceCategoryType,
  STAGE_BOARD_REFUSED,
  STAGE_BOARD_REVIEW,
  STAGE_CANCELLED_COUNSEL_REQUEST,
  STAGE_COUNSEL_REQUEST,
  STAGE_COUNSEL_REQUEST_DRAFT,
  STAGE_DUTY_COUNSEL,
  STAGE_ERROR,
  STAGE_PENDING,
  STAGE_READY_FOR_REVIEW,
  STAGE_REFUSED,
  STAGE_STOPPED,
} from '../../contexts/DomainContext';
import { IndividualsContext } from '../../contexts/IndividualsContext';
import DatePicker from 'react-datepicker';
import { DomainContext } from '../../contexts/DomainContext';
import { Link } from 'react-router-dom';
import { authAxios } from '../../services/AxiosService';
import { IOffence } from '../Offences/OffenceTable';
import { ReportContext } from './ReportContext';
import { formatDate } from '../../formatters/DateTimeFormatter';
import { MyUserContext } from '../../contexts/MyUserContext';

export const CriminalFiles = () => {
  const individualsContext = useContext(IndividualsContext);
  const domainContext = useContext(DomainContext);
  const myUserContext = useContext(MyUserContext);
  const circuitLocations = domainContext.circuitLocations;
  const [filteredFiles, setFilteredFiles] = useState<IReportingFile[]>([]);
  const [total, setTotal] = useState<IReportingFile[]>([]);
  const [notIncluded, setNotIncluded] = useState<IReportingFile[]>([]);
  const [categoryTypes, setCategoryTypes] = useState<IOffenceCategoryType[]>([]);
  const [showLocationBreakdown, setShowLocationBreakdown] = useState(false);
  const reportContext = useContext(ReportContext);
  const allFiles = reportContext.allFiles;
  const fromDate = reportContext.fromDate;
  const setFromDate = reportContext.setFromDate;
  const toDate = reportContext.toDate;
  const setToDate = reportContext.setToDate;
  useEffect(() => {
    authAxios.get('/api/offences/category-types').then((response) => {
      setCategoryTypes(
        response.data.filter(
          (ct: IOffenceCategoryType) =>
            ct.applicationTypes.includes(APPLICATION_TYPE_ID_YOUTH_CRIMINAL) ||
            ct.applicationTypes.includes(APPLICATION_TYPE_ID_ADULT_CRIMINAL) ||
            ct.applicationTypes.includes(APPLICATION_TYPE_ID_TERRITORIAL_STATUTES)
        )
      );
    });
  }, []);

  useEffect(() => {
    let totalFiltered: IReportingFile[] = [];
    allFiles.forEach((f) => {
      if (
        f.appDate &&
        f.appDate >= fromDate.getTime() &&
        f.appDate <= toDate.getTime() &&
        f.sId !== STAGE_PENDING &&
        f.sId !== STAGE_READY_FOR_REVIEW &&
        f.sId !== STAGE_REFUSED &&
        f.sId !== STAGE_BOARD_REVIEW &&
        f.sId !== STAGE_BOARD_REFUSED &&
        f.sId !== STAGE_COUNSEL_REQUEST_DRAFT &&
        f.sId !== STAGE_COUNSEL_REQUEST &&
        f.sId !== STAGE_CANCELLED_COUNSEL_REQUEST &&
        f.sId !== STAGE_DUTY_COUNSEL &&
        f.sId !== STAGE_STOPPED &&
        f.sId !== STAGE_ERROR &&
        domainContext.offences
          .filter((o) => f.chrgs.map((c) => c.offenceId).includes(o.offenceId))
          .filter(
            (o) =>
              o.applicationTypeId === APPLICATION_TYPE_ID_ADULT_CRIMINAL ||
              o.applicationTypeId === APPLICATION_TYPE_ID_YOUTH_CRIMINAL ||
              o.applicationTypeId === APPLICATION_TYPE_ID_TERRITORIAL_STATUTES
          ).length > 0
      ) {
        totalFiltered = totalFiltered.concat(f);
      }
    });
    //filter duplicate files
    totalFiltered = totalFiltered.filter((f) => {
      if (f.fNum !== undefined) {
        const duplicates: IReportingFile[] = totalFiltered.filter(
          (file) => file.fNum !== undefined && file.fNum === f.fNum
        );
        if (duplicates.length > 0) {
          if (!duplicates.some((file) => file.fSeqNum > f.fSeqNum)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    });

    let totalFiles: IReportingFile[] = [];
    let notIncludedTotal: IReportingFile[] = [];
    setFilteredFiles(totalFiltered);
    totalFiltered.forEach((f) => {
      const client = individualsContext.state.individuals.find((i) => i.individualId === f.cId);
      if (
        domainContext.offences
          .filter((o) => f.chrgs.map((c) => c.offenceId).includes(o.offenceId))
          .filter((o) => o.categoryTypeId !== undefined).length > 0 &&
        (client ? client.birthDate && client.genderId : false)
      ) {
        totalFiles = totalFiles.concat(f);
      } else {
        notIncludedTotal = notIncludedTotal.concat(f);
      }
    });
    setTotal(totalFiles);
    setNotIncluded(notIncludedTotal);
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFiles, fromDate, toDate]);

  let totalMaleAgeGroup1Indigenous: IOffence[] = [];
  let totalMaleAgeGroup1Non: IOffence[] = [];
  let totalMaleAgeGroup2Indigenous: IOffence[] = [];
  let totalMaleAgeGroup2Non: IOffence[] = [];
  let totalMaleAgeGroup3Indigenous: IOffence[] = [];
  let totalMaleAgeGroup3Non: IOffence[] = [];
  let totalMaleAgeGroup4Indigenous: IOffence[] = [];
  let totalMaleAgeGroup4Non: IOffence[] = [];
  let totalMaleAgeGroup5Indigenous: IOffence[] = [];
  let totalMaleAgeGroup5Non: IOffence[] = [];

  let totalFemaleAgeGroup1Indigenous: IOffence[] = [];
  let totalFemaleAgeGroup1Non: IOffence[] = [];
  let totalFemaleAgeGroup2Indigenous: IOffence[] = [];
  let totalFemaleAgeGroup2Non: IOffence[] = [];
  let totalFemaleAgeGroup3Indigenous: IOffence[] = [];
  let totalFemaleAgeGroup3Non: IOffence[] = [];
  let totalFemaleAgeGroup4Indigenous: IOffence[] = [];
  let totalFemaleAgeGroup4Non: IOffence[] = [];
  let totalFemaleAgeGroup5Indigenous: IOffence[] = [];
  let totalFemaleAgeGroup5Non: IOffence[] = [];

  let totalOtherAgeGroup1Indigenous: IOffence[] = [];
  let totalOtherAgeGroup1Non: IOffence[] = [];
  let totalOtherAgeGroup2Indigenous: IOffence[] = [];
  let totalOtherAgeGroup2Non: IOffence[] = [];
  let totalOtherAgeGroup3Indigenous: IOffence[] = [];
  let totalOtherAgeGroup3Non: IOffence[] = [];
  let totalOtherAgeGroup4Indigenous: IOffence[] = [];
  let totalOtherAgeGroup4Non: IOffence[] = [];
  let totalOtherAgeGroup5Indigenous: IOffence[] = [];
  let totalOtherAgeGroup5Non: IOffence[] = [];

  const dateDiffInYears = (dateOld: Date, dateNew: Date) => {
    const ynew = dateNew.getFullYear();
    const mnew = dateNew.getMonth();
    const dnew = dateNew.getDate();
    const yold = dateOld.getFullYear();
    const mold = dateOld.getMonth();
    const dold = dateOld.getDate();
    let diff = Math.abs(ynew - yold);
    if (mold > mnew) {
      diff = diff - 1;
    } else {
      if (mold === mnew) {
        if (dold > dnew) {
          diff = diff - 1;
        }
      }
    }
    return diff;
  };

  const undefinedCircuitLcations = () => {
    const locationFiles = filteredFiles.filter((ff) =>
      showLocationBreakdown ? !circuitLocations.some((c) => c.circuitLocationId === ff.cirLocId) : true
    );

    let locationMaleAgeGroup1Indigenous: IOffence[] = [];
    let locationMaleAgeGroup1Non: IOffence[] = [];
    let locationMaleAgeGroup2Indigenous: IOffence[] = [];
    let locationMaleAgeGroup2Non: IOffence[] = [];
    let locationMaleAgeGroup3Indigenous: IOffence[] = [];
    let locationMaleAgeGroup3Non: IOffence[] = [];
    let locationMaleAgeGroup4Indigenous: IOffence[] = [];
    let locationMaleAgeGroup4Non: IOffence[] = [];
    let locationMaleAgeGroup5Indigenous: IOffence[] = [];
    let locationMaleAgeGroup5Non: IOffence[] = [];

    let locationFemaleAgeGroup1Indigenous: IOffence[] = [];
    let locationFemaleAgeGroup1Non: IOffence[] = [];
    let locationFemaleAgeGroup2Indigenous: IOffence[] = [];
    let locationFemaleAgeGroup2Non: IOffence[] = [];
    let locationFemaleAgeGroup3Indigenous: IOffence[] = [];
    let locationFemaleAgeGroup3Non: IOffence[] = [];
    let locationFemaleAgeGroup4Indigenous: IOffence[] = [];
    let locationFemaleAgeGroup4Non: IOffence[] = [];
    let locationFemaleAgeGroup5Indigenous: IOffence[] = [];
    let locationFemaleAgeGroup5Non: IOffence[] = [];

    let locationOtherAgeGroup1Indigenous: IOffence[] = [];
    let locationOtherAgeGroup1Non: IOffence[] = [];
    let locationOtherAgeGroup2Indigenous: IOffence[] = [];
    let locationOtherAgeGroup2Non: IOffence[] = [];
    let locationOtherAgeGroup3Indigenous: IOffence[] = [];
    let locationOtherAgeGroup3Non: IOffence[] = [];
    let locationOtherAgeGroup4Indigenous: IOffence[] = [];
    let locationOtherAgeGroup4Non: IOffence[] = [];
    let locationOtherAgeGroup5Indigenous: IOffence[] = [];
    let locationOtherAgeGroup5Non: IOffence[] = [];

    locationFiles.forEach((f) => {
      const client = individualsContext.state.individuals.find((i) => i.individualId === f.cId);
      const age =
        client && client.birthDate ? dateDiffInYears(new Date(client.birthDate), new Date(f.appDate)) : undefined;
      f.chrgs.forEach((c) => {
        const offence = domainContext.offences.find((o) => o.offenceId === c.offenceId);
        if (
          client &&
          age !== undefined &&
          offence &&
          categoryTypes.map((ct) => ct.categoryTypeId).includes(offence.categoryTypeId ? offence.categoryTypeId : '')
        ) {
          if (client.genderId && client.genderId === GENDER_MALE) {
            if (age !== undefined && age < 18) {
              if (client.indigenous || client.visibleMinority) {
                locationMaleAgeGroup1Indigenous = locationMaleAgeGroup1Indigenous.concat(offence);
                totalMaleAgeGroup1Indigenous = totalMaleAgeGroup1Indigenous.concat(offence);
              } else {
                locationMaleAgeGroup1Non = locationMaleAgeGroup1Non.concat(offence);
                totalMaleAgeGroup1Non = totalMaleAgeGroup1Non.concat(offence);
              }
            } else if (age !== undefined && age >= 18 && age <= 29) {
              if (client.indigenous || client.visibleMinority) {
                locationMaleAgeGroup2Indigenous = locationMaleAgeGroup2Indigenous.concat(offence);
                totalMaleAgeGroup2Indigenous = totalMaleAgeGroup2Indigenous.concat(offence);
              } else {
                locationMaleAgeGroup2Non = locationMaleAgeGroup2Non.concat(offence);
                totalMaleAgeGroup2Non = totalMaleAgeGroup2Non.concat(offence);
              }
            } else if (age !== undefined && age >= 30 && age <= 34) {
              if (client.indigenous || client.visibleMinority) {
                locationMaleAgeGroup3Indigenous = locationMaleAgeGroup3Indigenous.concat(offence);
                totalMaleAgeGroup3Indigenous = totalMaleAgeGroup3Indigenous.concat(offence);
              } else {
                locationMaleAgeGroup3Non = locationMaleAgeGroup3Non.concat(offence);
                totalMaleAgeGroup3Non = totalMaleAgeGroup3Non.concat(offence);
              }
            } else if (age !== undefined && age >= 35 && age <= 49) {
              if (client.indigenous || client.visibleMinority) {
                locationMaleAgeGroup4Indigenous = locationMaleAgeGroup4Indigenous.concat(offence);
                totalMaleAgeGroup4Indigenous = totalMaleAgeGroup4Indigenous.concat(offence);
              } else {
                locationMaleAgeGroup4Non = locationMaleAgeGroup4Non.concat(offence);
                totalMaleAgeGroup4Non = totalMaleAgeGroup4Non.concat(offence);
              }
            } else if (age !== undefined && age >= 50) {
              if (client.indigenous || client.visibleMinority) {
                locationMaleAgeGroup5Indigenous = locationMaleAgeGroup5Indigenous.concat(offence);
                totalMaleAgeGroup5Indigenous = totalMaleAgeGroup5Indigenous.concat(offence);
              } else {
                locationMaleAgeGroup5Non = locationMaleAgeGroup5Non.concat(offence);
                totalMaleAgeGroup5Non = totalMaleAgeGroup5Non.concat(offence);
              }
            }
          } else if (client.genderId && client.genderId === GENDER_FEMALE) {
            if (age !== undefined && age < 18) {
              if (client.indigenous || client.visibleMinority) {
                locationFemaleAgeGroup1Indigenous = locationFemaleAgeGroup1Indigenous.concat(offence);
                totalFemaleAgeGroup1Indigenous = totalFemaleAgeGroup1Indigenous.concat(offence);
              } else {
                locationFemaleAgeGroup1Non = locationFemaleAgeGroup1Non.concat(offence);
                totalFemaleAgeGroup1Non = totalFemaleAgeGroup1Non.concat(offence);
              }
            } else if (age !== undefined && age >= 18 && age <= 29) {
              if (client.indigenous || client.visibleMinority) {
                locationFemaleAgeGroup2Indigenous = locationFemaleAgeGroup2Indigenous.concat(offence);
                totalFemaleAgeGroup2Indigenous = totalFemaleAgeGroup2Indigenous.concat(offence);
              } else {
                locationFemaleAgeGroup2Non = locationFemaleAgeGroup2Non.concat(offence);
                totalFemaleAgeGroup2Non = totalFemaleAgeGroup2Non.concat(offence);
              }
            } else if (age !== undefined && age >= 30 && age <= 34) {
              if (client.indigenous || client.visibleMinority) {
                locationFemaleAgeGroup3Indigenous = locationFemaleAgeGroup3Indigenous.concat(offence);
                totalFemaleAgeGroup3Indigenous = totalFemaleAgeGroup3Indigenous.concat(offence);
              } else {
                locationFemaleAgeGroup3Non = locationFemaleAgeGroup3Non.concat(offence);
                totalFemaleAgeGroup3Non = totalFemaleAgeGroup3Non.concat(offence);
              }
            } else if (age !== undefined && age >= 35 && age <= 49) {
              if (client.indigenous || client.visibleMinority) {
                locationFemaleAgeGroup4Indigenous = locationFemaleAgeGroup4Indigenous.concat(offence);
                totalFemaleAgeGroup4Indigenous = totalFemaleAgeGroup4Indigenous.concat(offence);
              } else {
                locationFemaleAgeGroup4Non = locationFemaleAgeGroup4Non.concat(offence);
                totalFemaleAgeGroup4Non = totalFemaleAgeGroup4Non.concat(offence);
              }
            } else if (age !== undefined && age >= 50) {
              if (client.indigenous || client.visibleMinority) {
                locationFemaleAgeGroup5Indigenous = locationFemaleAgeGroup5Indigenous.concat(offence);
                totalFemaleAgeGroup5Indigenous = totalFemaleAgeGroup5Indigenous.concat(offence);
              } else {
                locationFemaleAgeGroup5Non = locationFemaleAgeGroup5Non.concat(offence);
                totalFemaleAgeGroup5Non = totalFemaleAgeGroup5Non.concat(offence);
              }
            }
          } else if (client.genderId && client.genderId === GENDER_OTHER) {
            if (age !== undefined && age < 18) {
              if (client.indigenous || client.visibleMinority) {
                locationOtherAgeGroup1Indigenous = locationOtherAgeGroup1Indigenous.concat(offence);
                totalOtherAgeGroup1Indigenous = totalOtherAgeGroup1Indigenous.concat(offence);
              } else {
                locationOtherAgeGroup1Non = locationOtherAgeGroup1Non.concat(offence);
                totalOtherAgeGroup1Non = totalOtherAgeGroup1Non.concat(offence);
              }
            } else if (age !== undefined && age >= 18 && age <= 29) {
              if (client.indigenous || client.visibleMinority) {
                locationOtherAgeGroup2Indigenous = locationOtherAgeGroup2Indigenous.concat(offence);
                totalOtherAgeGroup2Indigenous = totalOtherAgeGroup2Indigenous.concat(offence);
              } else {
                locationOtherAgeGroup2Non = locationOtherAgeGroup2Non.concat(offence);
                totalOtherAgeGroup2Non = totalOtherAgeGroup2Non.concat(offence);
              }
            } else if (age !== undefined && age >= 30 && age <= 34) {
              if (client.indigenous || client.visibleMinority) {
                locationOtherAgeGroup3Indigenous = locationOtherAgeGroup3Indigenous.concat(offence);
                totalOtherAgeGroup3Indigenous = totalOtherAgeGroup3Indigenous.concat(offence);
              } else {
                locationOtherAgeGroup3Non = locationOtherAgeGroup3Non.concat(offence);
                totalOtherAgeGroup3Non = totalOtherAgeGroup3Non.concat(offence);
              }
            } else if (age !== undefined && age >= 35 && age <= 49) {
              if (client.indigenous || client.visibleMinority) {
                locationOtherAgeGroup4Indigenous = locationOtherAgeGroup4Indigenous.concat(offence);
                totalOtherAgeGroup4Indigenous = totalOtherAgeGroup4Indigenous.concat(offence);
              } else {
                locationOtherAgeGroup4Non = locationOtherAgeGroup4Non.concat(offence);
                totalOtherAgeGroup4Non = totalOtherAgeGroup4Non.concat(offence);
              }
            } else if (age !== undefined && age >= 50) {
              if (client.indigenous || client.visibleMinority) {
                locationOtherAgeGroup5Indigenous = locationOtherAgeGroup5Indigenous.concat(offence);
                totalOtherAgeGroup5Indigenous = totalOtherAgeGroup5Indigenous.concat(offence);
              } else {
                locationOtherAgeGroup5Non = locationOtherAgeGroup5Non.concat(offence);
                totalOtherAgeGroup5Non = totalOtherAgeGroup5Non.concat(offence);
              }
            }
          }
        }
      });
    });
    return categoryTypes
      .sort((a: IOffenceCategoryType, b: IOffenceCategoryType) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      )
      .map((category, index) => {
        const locationMaleAgeGroup1IndigenousApplication = locationMaleAgeGroup1Indigenous.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationMaleAgeGroup1NonApplication = locationMaleAgeGroup1Non.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationMaleAgeGroup2IndigenousApplication = locationMaleAgeGroup2Indigenous.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationMaleAgeGroup2NonApplication = locationMaleAgeGroup2Non.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationMaleAgeGroup3IndigenousApplication = locationMaleAgeGroup3Indigenous.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationMaleAgeGroup3NonApplication = locationMaleAgeGroup3Non.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationMaleAgeGroup4IndigenousApplication = locationMaleAgeGroup4Indigenous.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationMaleAgeGroup4NonApplication = locationMaleAgeGroup4Non.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationMaleAgeGroup5IndigenousApplication = locationMaleAgeGroup5Indigenous.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationMaleAgeGroup5NonApplication = locationMaleAgeGroup5Non.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const totalMale =
          locationMaleAgeGroup1IndigenousApplication.length +
          locationMaleAgeGroup1NonApplication.length +
          locationMaleAgeGroup2IndigenousApplication.length +
          locationMaleAgeGroup2NonApplication.length +
          locationMaleAgeGroup3IndigenousApplication.length +
          locationMaleAgeGroup3NonApplication.length +
          locationMaleAgeGroup4IndigenousApplication.length +
          locationMaleAgeGroup4NonApplication.length +
          locationMaleAgeGroup5IndigenousApplication.length +
          locationMaleAgeGroup5NonApplication.length;

        const locationFemaleAgeGroup1IndigenousApplication = locationFemaleAgeGroup1Indigenous.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationFemaleAgeGroup1NonApplication = locationFemaleAgeGroup1Non.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationFemaleAgeGroup2IndigenousApplication = locationFemaleAgeGroup2Indigenous.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationFemaleAgeGroup2NonApplication = locationFemaleAgeGroup2Non.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationFemaleAgeGroup3IndigenousApplication = locationFemaleAgeGroup3Indigenous.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationFemaleAgeGroup3NonApplication = locationFemaleAgeGroup3Non.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationFemaleAgeGroup4IndigenousApplication = locationFemaleAgeGroup4Indigenous.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationFemaleAgeGroup4NonApplication = locationFemaleAgeGroup4Non.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationFemaleAgeGroup5IndigenousApplication = locationFemaleAgeGroup5Indigenous.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationFemaleAgeGroup5NonApplication = locationFemaleAgeGroup5Non.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );

        const totalFemale =
          locationFemaleAgeGroup1IndigenousApplication.length +
          locationFemaleAgeGroup1NonApplication.length +
          locationFemaleAgeGroup2IndigenousApplication.length +
          locationFemaleAgeGroup2NonApplication.length +
          locationFemaleAgeGroup3IndigenousApplication.length +
          locationFemaleAgeGroup3NonApplication.length +
          locationFemaleAgeGroup4IndigenousApplication.length +
          locationFemaleAgeGroup4NonApplication.length +
          locationFemaleAgeGroup5IndigenousApplication.length +
          locationFemaleAgeGroup5NonApplication.length;

        const locationOtherAgeGroup1IndigenousApplication = locationOtherAgeGroup1Indigenous.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationOtherAgeGroup1NonApplication = locationOtherAgeGroup1Non.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationOtherAgeGroup2IndigenousApplication = locationOtherAgeGroup2Indigenous.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationOtherAgeGroup2NonApplication = locationOtherAgeGroup2Non.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationOtherAgeGroup3IndigenousApplication = locationOtherAgeGroup3Indigenous.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationOtherAgeGroup3NonApplication = locationOtherAgeGroup3Non.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationOtherAgeGroup4IndigenousApplication = locationOtherAgeGroup4Indigenous.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationOtherAgeGroup4NonApplication = locationOtherAgeGroup4Non.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationOtherAgeGroup5IndigenousApplication = locationOtherAgeGroup5Indigenous.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );
        const locationOtherAgeGroup5NonApplication = locationOtherAgeGroup5Non.filter(
          (o) => o.categoryTypeId === category.categoryTypeId
        );

        const totalOther =
          locationOtherAgeGroup1IndigenousApplication.length +
          locationOtherAgeGroup1NonApplication.length +
          locationOtherAgeGroup2IndigenousApplication.length +
          locationOtherAgeGroup2NonApplication.length +
          locationOtherAgeGroup3IndigenousApplication.length +
          locationOtherAgeGroup3NonApplication.length +
          locationOtherAgeGroup4IndigenousApplication.length +
          locationOtherAgeGroup4NonApplication.length +
          locationOtherAgeGroup5IndigenousApplication.length +
          locationOtherAgeGroup5NonApplication.length;

        return (
          <tr key={category.categoryTypeId}>
            {showLocationBreakdown && index === 0 && (
              <th className={'header-side-bottom'} rowSpan={categoryTypes.length + 1}>
                Undefined Location
              </th>
            )}
            <th className={'header-side'}>{category.name}</th>
            <td className={'report-row'}>{locationMaleAgeGroup1IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationMaleAgeGroup1NonApplication.length}</td>
            <td className={'report-row'}>{locationMaleAgeGroup2IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationMaleAgeGroup2NonApplication.length}</td>
            <td className={'report-row'}>{locationMaleAgeGroup3IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationMaleAgeGroup3NonApplication.length}</td>
            <td className={'report-row'}>{locationMaleAgeGroup4IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationMaleAgeGroup4NonApplication.length}</td>
            <td className={'report-row'}>{locationMaleAgeGroup5IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationMaleAgeGroup5NonApplication.length}</td>
            <td className={'total'}>
              <b>{totalMale}</b>
            </td>
            <td className={'report-row'}>{locationFemaleAgeGroup1IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationFemaleAgeGroup1NonApplication.length}</td>
            <td className={'report-row'}>{locationFemaleAgeGroup2IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationFemaleAgeGroup2NonApplication.length}</td>
            <td className={'report-row'}>{locationFemaleAgeGroup3IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationFemaleAgeGroup3NonApplication.length}</td>
            <td className={'report-row'}>{locationFemaleAgeGroup4IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationFemaleAgeGroup4NonApplication.length}</td>
            <td className={'report-row'}>{locationFemaleAgeGroup5IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationFemaleAgeGroup5NonApplication.length}</td>
            <td className={'total'}>
              <b>{totalFemale}</b>
            </td>
            <td className={'report-row'}>{locationOtherAgeGroup1IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationOtherAgeGroup1NonApplication.length}</td>
            <td className={'report-row'}>{locationOtherAgeGroup2IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationOtherAgeGroup2NonApplication.length}</td>
            <td className={'report-row'}>{locationOtherAgeGroup3IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationOtherAgeGroup3NonApplication.length}</td>
            <td className={'report-row'}>{locationOtherAgeGroup4IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationOtherAgeGroup4NonApplication.length}</td>
            <td className={'report-row'}>{locationOtherAgeGroup5IndigenousApplication.length}</td>
            <td className={'report-row'}>{locationOtherAgeGroup5NonApplication.length}</td>
            <td className={'total'}>
              <b>{totalOther}</b>
            </td>
            <td className={'total'}>
              <b>{totalMale + totalFemale + totalOther}</b>
            </td>
          </tr>
        );
      })
      .concat(
        <tr key={'total-undefined'}>
          <th className={'header-side-bottom'} />
          <td className={'total-bottom'}>
            <b>{locationMaleAgeGroup1Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationMaleAgeGroup1Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationMaleAgeGroup2Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationMaleAgeGroup2Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationMaleAgeGroup3Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationMaleAgeGroup3Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationMaleAgeGroup4Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationMaleAgeGroup4Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationMaleAgeGroup5Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationMaleAgeGroup5Non.length}</b>
          </td>
          <td className={'total-bottom'} />
          <td className={'total-bottom'}>
            <b>{locationFemaleAgeGroup1Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationFemaleAgeGroup1Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationFemaleAgeGroup2Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationFemaleAgeGroup2Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationFemaleAgeGroup3Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationFemaleAgeGroup3Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationFemaleAgeGroup4Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationFemaleAgeGroup4Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationFemaleAgeGroup5Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationFemaleAgeGroup5Non.length}</b>
          </td>
          <td className={'total-bottom'} />
          <td className={'total-bottom'}>
            <b>{locationOtherAgeGroup1Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationOtherAgeGroup1Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationOtherAgeGroup2Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationOtherAgeGroup2Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationOtherAgeGroup3Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationOtherAgeGroup3Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationOtherAgeGroup4Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationOtherAgeGroup4Non.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationOtherAgeGroup5Indigenous.length}</b>
          </td>
          <td className={'total-bottom'}>
            <b>{locationOtherAgeGroup5Non.length}</b>
          </td>
          <td className={'total-bottom'} />
          <td className={'total-bottom'} />
        </tr>
      );
  };

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
        <Row className={'flex-fill'}>
          <Col md={3} xl={2}>
            <NavbarBrand>Criminal Offences</NavbarBrand>
          </Col>
          <Col className={'d-flex'}>
            <Button className={'ml-auto'} color={'warning'} onClick={() => window.print()}>
              Print <FaPrint />
            </Button>
          </Col>
        </Row>
      </Navbar>
      <Container className={'container--no-margin-print'}>
        <Row className={'my-5 '}>
          <Col>
            <Card className={'no-print'}>
              <CardHeader>Select File Application Date Range</CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Label className={'flex second-label'} style={{ paddingRight: '10px' }}>
                      From
                    </Label>
                    <DatePicker
                      className={'form-control date-select flex'}
                      selected={fromDate}
                      onChange={(date) => {
                        if (date) {
                          setFromDate(date);
                        }
                      }}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      shouldCloseOnSelect={true}
                      dateFormat={'yyyy-MM-dd'}
                    />
                  </Col>
                  <Col>
                    <Label
                      className={'flex second-label'}
                      for={'EndDate'}
                      style={{ paddingRight: '10px', paddingLeft: '10px' }}
                    >
                      To
                    </Label>
                    <DatePicker
                      className={'form-control date-select flex'}
                      selected={toDate}
                      onChange={(date) => {
                        if (date) {
                          setToDate(date);
                        }
                      }}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      shouldCloseOnSelect={true}
                      dateFormat={'yyyy-MM-dd'}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <CustomInput
              className={'mt-2'}
              type='checkbox'
              id={'showLocationBreakdown'}
              name={'showLocationBreakdown'}
              label={'Show Circuit Location Breakdown'}
              checked={showLocationBreakdown}
              onChange={() => {
                setShowLocationBreakdown(!showLocationBreakdown);
              }}
            />
          </Col>
          <Col className={'no-print'}>
            <p className={'text-muted'}>
              *This report includes all charges on files with application dates in the range selected. It only includes
              files approved by intake meaning:
              <ul>
                <li>Files with criminal charges with a category</li>
                <li>Files in Approved Stage</li>
                <li>Files in Lawyer Requested Stage</li>
                <li>Files in Board Approved Stage</li>
                <li>Files in Return for Conflict Stage</li>
                <li>Files in Assigned to Lawyer Stage</li>
                <li>Files in Closed Stage</li>
                <li>Client with birthdate</li>
                <li>Client with gender</li>
              </ul>
            </p>
          </Col>
        </Row>
      </Container>
      <div className={'report mt-5 mx-3'}>
        <header className={'report-header'}>
          <h2 className={'text-center'}>Criminal Offences</h2>
          <h3 className={'text-center h5 text-muted'}>
            {'Total files in table: ' +
              total.length +
              ', Not shown: ' +
              notIncluded.length +
              ' for dates between: ' +
              formatDate(fromDate) +
              ' and ' +
              formatDate(toDate)}
          </h3>
        </header>

        <table className={'report1'}>
          <thead>
            <tr>
              <th className={'header-side'} colSpan={showLocationBreakdown ? 2 : 1} rowSpan={3} />
              <th className={'header'} colSpan={11}>
                Male
              </th>
              <th className={'header'} colSpan={11}>
                Female
              </th>
              <th className={'header'} colSpan={11}>
                Other
              </th>
              <th className={'header'} rowSpan={3}>
                Total
              </th>
            </tr>
            <tr>
              <th className={'headerspan-row1'}>17 & Under</th>
              <th className={'headerspan-row1'}>17 & Under</th>
              <th className={'headerspan-row1'}>18 - 29</th>
              <th className={'headerspan-row1'}>18 - 29</th>
              <th className={'headerspan-row1'}>30 - 34</th>
              <th className={'headerspan-row1'}>30 - 34</th>
              <th className={'headerspan-row1'}>35 - 49</th>
              <th className={'headerspan-row1'}>35 - 49</th>
              <th className={'headerspan-row1'}>50 & Above</th>
              <th className={'headerspan-row1'}>50 & Above</th>
              <th className={'headerspan-row1'} />
              <th className={'headerspan-row1'}>17 & Under</th>
              <th className={'headerspan-row1'}>17 & Under</th>
              <th className={'headerspan-row1'}>18 - 29</th>
              <th className={'headerspan-row1'}>18 - 29</th>
              <th className={'headerspan-row1'}>30 - 34</th>
              <th className={'headerspan-row1'}>30 - 34</th>
              <th className={'headerspan-row1'}>35 - 49</th>
              <th className={'headerspan-row1'}>35 - 49</th>
              <th className={'headerspan-row1'}>50 & Above</th>
              <th className={'headerspan-row1'}>50 & Above</th>
              <th className={'headerspan-row1'} />
              <th className={'headerspan-row1'}>17 & Under</th>
              <th className={'headerspan-row1'}>17 & Under</th>
              <th className={'headerspan-row1'}>18 - 29</th>
              <th className={'headerspan-row1'}>18 - 29</th>
              <th className={'headerspan-row1'}>30 - 34</th>
              <th className={'headerspan-row1'}>30 - 34</th>
              <th className={'headerspan-row1'}>35 - 49</th>
              <th className={'headerspan-row1'}>35 - 49</th>
              <th className={'headerspan-row1'}>50 & Above</th>
              <th className={'headerspan-row1'}>50 & Above</th>
              <th className={'headerspan-row1'} />
            </tr>
            <tr>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'} />
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'} />
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'} />
            </tr>
          </thead>
          <tbody>
            {showLocationBreakdown ? (
              <>
                {circuitLocations
                  .sort((a: ICircuitLocation, b: ICircuitLocation) =>
                    b.name.toUpperCase().localeCompare(a.name.toUpperCase())
                  )
                  .map((location) => {
                    const locationFiles = filteredFiles.filter((ff) => ff.cirLocId === location.circuitLocationId);

                    let locationMaleAgeGroup1Indigenous: IOffence[] = [];
                    let locationMaleAgeGroup1Non: IOffence[] = [];
                    let locationMaleAgeGroup2Indigenous: IOffence[] = [];
                    let locationMaleAgeGroup2Non: IOffence[] = [];
                    let locationMaleAgeGroup3Indigenous: IOffence[] = [];
                    let locationMaleAgeGroup3Non: IOffence[] = [];
                    let locationMaleAgeGroup4Indigenous: IOffence[] = [];
                    let locationMaleAgeGroup4Non: IOffence[] = [];
                    let locationMaleAgeGroup5Indigenous: IOffence[] = [];
                    let locationMaleAgeGroup5Non: IOffence[] = [];

                    let locationFemaleAgeGroup1Indigenous: IOffence[] = [];
                    let locationFemaleAgeGroup1Non: IOffence[] = [];
                    let locationFemaleAgeGroup2Indigenous: IOffence[] = [];
                    let locationFemaleAgeGroup2Non: IOffence[] = [];
                    let locationFemaleAgeGroup3Indigenous: IOffence[] = [];
                    let locationFemaleAgeGroup3Non: IOffence[] = [];
                    let locationFemaleAgeGroup4Indigenous: IOffence[] = [];
                    let locationFemaleAgeGroup4Non: IOffence[] = [];
                    let locationFemaleAgeGroup5Indigenous: IOffence[] = [];
                    let locationFemaleAgeGroup5Non: IOffence[] = [];

                    let locationOtherAgeGroup1Indigenous: IOffence[] = [];
                    let locationOtherAgeGroup1Non: IOffence[] = [];
                    let locationOtherAgeGroup2Indigenous: IOffence[] = [];
                    let locationOtherAgeGroup2Non: IOffence[] = [];
                    let locationOtherAgeGroup3Indigenous: IOffence[] = [];
                    let locationOtherAgeGroup3Non: IOffence[] = [];
                    let locationOtherAgeGroup4Indigenous: IOffence[] = [];
                    let locationOtherAgeGroup4Non: IOffence[] = [];
                    let locationOtherAgeGroup5Indigenous: IOffence[] = [];
                    let locationOtherAgeGroup5Non: IOffence[] = [];

                    locationFiles.forEach((f) => {
                      const client = individualsContext.state.individuals.find((i) => i.individualId === f.cId);
                      const age =
                        client && client.birthDate
                          ? dateDiffInYears(new Date(client.birthDate), new Date(f.appDate))
                          : undefined;
                      f.chrgs.forEach((c) => {
                        const offence = domainContext.offences.find((o) => o.offenceId === c.offenceId);
                        if (
                          client &&
                          age !== undefined &&
                          offence &&
                          categoryTypes
                            .map((ct) => ct.categoryTypeId)
                            .includes(offence.categoryTypeId ? offence.categoryTypeId : '')
                        ) {
                          if (client.genderId && client.genderId === GENDER_MALE) {
                            if (age !== undefined && age < 18) {
                              if (client.indigenous || client.visibleMinority) {
                                locationMaleAgeGroup1Indigenous = locationMaleAgeGroup1Indigenous.concat(offence);
                                totalMaleAgeGroup1Indigenous = totalMaleAgeGroup1Indigenous.concat(offence);
                              } else {
                                locationMaleAgeGroup1Non = locationMaleAgeGroup1Non.concat(offence);
                                totalMaleAgeGroup1Non = totalMaleAgeGroup1Non.concat(offence);
                              }
                            } else if (age !== undefined && age >= 18 && age <= 29) {
                              if (client.indigenous || client.visibleMinority) {
                                locationMaleAgeGroup2Indigenous = locationMaleAgeGroup2Indigenous.concat(offence);
                                totalMaleAgeGroup2Indigenous = totalMaleAgeGroup2Indigenous.concat(offence);
                              } else {
                                locationMaleAgeGroup2Non = locationMaleAgeGroup2Non.concat(offence);
                                totalMaleAgeGroup2Non = totalMaleAgeGroup2Non.concat(offence);
                              }
                            } else if (age !== undefined && age >= 30 && age <= 34) {
                              if (client.indigenous || client.visibleMinority) {
                                locationMaleAgeGroup3Indigenous = locationMaleAgeGroup3Indigenous.concat(offence);
                                totalMaleAgeGroup3Indigenous = totalMaleAgeGroup3Indigenous.concat(offence);
                              } else {
                                locationMaleAgeGroup3Non = locationMaleAgeGroup3Non.concat(offence);
                                totalMaleAgeGroup3Non = totalMaleAgeGroup3Non.concat(offence);
                              }
                            } else if (age !== undefined && age >= 35 && age <= 49) {
                              if (client.indigenous || client.visibleMinority) {
                                locationMaleAgeGroup4Indigenous = locationMaleAgeGroup4Indigenous.concat(offence);
                                totalMaleAgeGroup4Indigenous = totalMaleAgeGroup4Indigenous.concat(offence);
                              } else {
                                locationMaleAgeGroup4Non = locationMaleAgeGroup4Non.concat(offence);
                                totalMaleAgeGroup4Non = totalMaleAgeGroup4Non.concat(offence);
                              }
                            } else if (age !== undefined && age >= 50) {
                              if (client.indigenous || client.visibleMinority) {
                                locationMaleAgeGroup5Indigenous = locationMaleAgeGroup5Indigenous.concat(offence);
                                totalMaleAgeGroup5Indigenous = totalMaleAgeGroup5Indigenous.concat(offence);
                              } else {
                                locationMaleAgeGroup5Non = locationMaleAgeGroup5Non.concat(offence);
                                totalMaleAgeGroup5Non = totalMaleAgeGroup5Non.concat(offence);
                              }
                            }
                          } else if (client.genderId && client.genderId === GENDER_FEMALE) {
                            if (age !== undefined && age < 18) {
                              if (client.indigenous || client.visibleMinority) {
                                locationFemaleAgeGroup1Indigenous = locationFemaleAgeGroup1Indigenous.concat(offence);
                                totalFemaleAgeGroup1Indigenous = totalFemaleAgeGroup1Indigenous.concat(offence);
                              } else {
                                locationFemaleAgeGroup1Non = locationFemaleAgeGroup1Non.concat(offence);
                                totalFemaleAgeGroup1Non = totalFemaleAgeGroup1Non.concat(offence);
                              }
                            } else if (age !== undefined && age >= 18 && age <= 29) {
                              if (client.indigenous || client.visibleMinority) {
                                locationFemaleAgeGroup2Indigenous = locationFemaleAgeGroup2Indigenous.concat(offence);
                                totalFemaleAgeGroup2Indigenous = totalFemaleAgeGroup2Indigenous.concat(offence);
                              } else {
                                locationFemaleAgeGroup2Non = locationFemaleAgeGroup2Non.concat(offence);
                                totalFemaleAgeGroup2Non = totalFemaleAgeGroup2Non.concat(offence);
                              }
                            } else if (age !== undefined && age >= 30 && age <= 34) {
                              if (client.indigenous || client.visibleMinority) {
                                locationFemaleAgeGroup3Indigenous = locationFemaleAgeGroup3Indigenous.concat(offence);
                                totalFemaleAgeGroup3Indigenous = totalFemaleAgeGroup3Indigenous.concat(offence);
                              } else {
                                locationFemaleAgeGroup3Non = locationFemaleAgeGroup3Non.concat(offence);
                                totalFemaleAgeGroup3Non = totalFemaleAgeGroup3Non.concat(offence);
                              }
                            } else if (age !== undefined && age >= 35 && age <= 49) {
                              if (client.indigenous || client.visibleMinority) {
                                locationFemaleAgeGroup4Indigenous = locationFemaleAgeGroup4Indigenous.concat(offence);
                                totalFemaleAgeGroup4Indigenous = totalFemaleAgeGroup4Indigenous.concat(offence);
                              } else {
                                locationFemaleAgeGroup4Non = locationFemaleAgeGroup4Non.concat(offence);
                                totalFemaleAgeGroup4Non = totalFemaleAgeGroup4Non.concat(offence);
                              }
                            } else if (age !== undefined && age >= 50) {
                              if (client.indigenous || client.visibleMinority) {
                                locationFemaleAgeGroup5Indigenous = locationFemaleAgeGroup5Indigenous.concat(offence);
                                totalFemaleAgeGroup5Indigenous = totalFemaleAgeGroup5Indigenous.concat(offence);
                              } else {
                                locationFemaleAgeGroup5Non = locationFemaleAgeGroup5Non.concat(offence);
                                totalFemaleAgeGroup5Non = totalFemaleAgeGroup5Non.concat(offence);
                              }
                            }
                          } else if (client.genderId && client.genderId === GENDER_OTHER) {
                            if (age !== undefined && age < 18) {
                              if (client.indigenous || client.visibleMinority) {
                                locationOtherAgeGroup1Indigenous = locationOtherAgeGroup1Indigenous.concat(offence);
                                totalOtherAgeGroup1Indigenous = totalOtherAgeGroup1Indigenous.concat(offence);
                              } else {
                                locationOtherAgeGroup1Non = locationOtherAgeGroup1Non.concat(offence);
                                totalOtherAgeGroup1Non = totalOtherAgeGroup1Non.concat(offence);
                              }
                            } else if (age !== undefined && age >= 18 && age <= 29) {
                              if (client.indigenous || client.visibleMinority) {
                                locationOtherAgeGroup2Indigenous = locationOtherAgeGroup2Indigenous.concat(offence);
                                totalOtherAgeGroup2Indigenous = totalOtherAgeGroup2Indigenous.concat(offence);
                              } else {
                                locationOtherAgeGroup2Non = locationOtherAgeGroup2Non.concat(offence);
                                totalOtherAgeGroup2Non = totalOtherAgeGroup2Non.concat(offence);
                              }
                            } else if (age !== undefined && age >= 30 && age <= 34) {
                              if (client.indigenous || client.visibleMinority) {
                                locationOtherAgeGroup3Indigenous = locationOtherAgeGroup3Indigenous.concat(offence);
                                totalOtherAgeGroup3Indigenous = totalOtherAgeGroup3Indigenous.concat(offence);
                              } else {
                                locationOtherAgeGroup3Non = locationOtherAgeGroup3Non.concat(offence);
                                totalOtherAgeGroup3Non = totalOtherAgeGroup3Non.concat(offence);
                              }
                            } else if (age !== undefined && age >= 35 && age <= 49) {
                              if (client.indigenous || client.visibleMinority) {
                                locationOtherAgeGroup4Indigenous = locationOtherAgeGroup4Indigenous.concat(offence);
                                totalOtherAgeGroup4Indigenous = totalOtherAgeGroup4Indigenous.concat(offence);
                              } else {
                                locationOtherAgeGroup4Non = locationOtherAgeGroup4Non.concat(offence);
                                totalOtherAgeGroup4Non = totalOtherAgeGroup4Non.concat(offence);
                              }
                            } else if (age !== undefined && age >= 50) {
                              if (client.indigenous || client.visibleMinority) {
                                locationOtherAgeGroup5Indigenous = locationOtherAgeGroup5Indigenous.concat(offence);
                                totalOtherAgeGroup5Indigenous = totalOtherAgeGroup5Indigenous.concat(offence);
                              } else {
                                locationOtherAgeGroup5Non = locationOtherAgeGroup5Non.concat(offence);
                                totalOtherAgeGroup5Non = totalOtherAgeGroup5Non.concat(offence);
                              }
                            }
                          }
                        }
                      });
                    });
                    return categoryTypes
                      .sort((a: IOffenceCategoryType, b: IOffenceCategoryType) =>
                        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
                      )
                      .map((category, index) => {
                        const locationMaleAgeGroup1IndigenousApplication = locationMaleAgeGroup1Indigenous.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationMaleAgeGroup1NonApplication = locationMaleAgeGroup1Non.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationMaleAgeGroup2IndigenousApplication = locationMaleAgeGroup2Indigenous.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationMaleAgeGroup2NonApplication = locationMaleAgeGroup2Non.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationMaleAgeGroup3IndigenousApplication = locationMaleAgeGroup3Indigenous.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationMaleAgeGroup3NonApplication = locationMaleAgeGroup3Non.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationMaleAgeGroup4IndigenousApplication = locationMaleAgeGroup4Indigenous.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationMaleAgeGroup4NonApplication = locationMaleAgeGroup4Non.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationMaleAgeGroup5IndigenousApplication = locationMaleAgeGroup5Indigenous.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationMaleAgeGroup5NonApplication = locationMaleAgeGroup5Non.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const totalMale =
                          locationMaleAgeGroup1IndigenousApplication.length +
                          locationMaleAgeGroup1NonApplication.length +
                          locationMaleAgeGroup2IndigenousApplication.length +
                          locationMaleAgeGroup2NonApplication.length +
                          locationMaleAgeGroup3IndigenousApplication.length +
                          locationMaleAgeGroup3NonApplication.length +
                          locationMaleAgeGroup4IndigenousApplication.length +
                          locationMaleAgeGroup4NonApplication.length +
                          locationMaleAgeGroup5IndigenousApplication.length +
                          locationMaleAgeGroup5NonApplication.length;

                        const locationFemaleAgeGroup1IndigenousApplication = locationFemaleAgeGroup1Indigenous.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationFemaleAgeGroup1NonApplication = locationFemaleAgeGroup1Non.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationFemaleAgeGroup2IndigenousApplication = locationFemaleAgeGroup2Indigenous.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationFemaleAgeGroup2NonApplication = locationFemaleAgeGroup2Non.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationFemaleAgeGroup3IndigenousApplication = locationFemaleAgeGroup3Indigenous.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationFemaleAgeGroup3NonApplication = locationFemaleAgeGroup3Non.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationFemaleAgeGroup4IndigenousApplication = locationFemaleAgeGroup4Indigenous.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationFemaleAgeGroup4NonApplication = locationFemaleAgeGroup4Non.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationFemaleAgeGroup5IndigenousApplication = locationFemaleAgeGroup5Indigenous.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationFemaleAgeGroup5NonApplication = locationFemaleAgeGroup5Non.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );

                        const totalFemale =
                          locationFemaleAgeGroup1IndigenousApplication.length +
                          locationFemaleAgeGroup1NonApplication.length +
                          locationFemaleAgeGroup2IndigenousApplication.length +
                          locationFemaleAgeGroup2NonApplication.length +
                          locationFemaleAgeGroup3IndigenousApplication.length +
                          locationFemaleAgeGroup3NonApplication.length +
                          locationFemaleAgeGroup4IndigenousApplication.length +
                          locationFemaleAgeGroup4NonApplication.length +
                          locationFemaleAgeGroup5IndigenousApplication.length +
                          locationFemaleAgeGroup5NonApplication.length;

                        const locationOtherAgeGroup1IndigenousApplication = locationOtherAgeGroup1Indigenous.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationOtherAgeGroup1NonApplication = locationOtherAgeGroup1Non.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationOtherAgeGroup2IndigenousApplication = locationOtherAgeGroup2Indigenous.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationOtherAgeGroup2NonApplication = locationOtherAgeGroup2Non.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationOtherAgeGroup3IndigenousApplication = locationOtherAgeGroup3Indigenous.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationOtherAgeGroup3NonApplication = locationOtherAgeGroup3Non.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationOtherAgeGroup4IndigenousApplication = locationOtherAgeGroup4Indigenous.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationOtherAgeGroup4NonApplication = locationOtherAgeGroup4Non.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationOtherAgeGroup5IndigenousApplication = locationOtherAgeGroup5Indigenous.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );
                        const locationOtherAgeGroup5NonApplication = locationOtherAgeGroup5Non.filter(
                          (o) => o.categoryTypeId === category.categoryTypeId
                        );

                        const totalOther =
                          locationOtherAgeGroup1IndigenousApplication.length +
                          locationOtherAgeGroup1NonApplication.length +
                          locationOtherAgeGroup2IndigenousApplication.length +
                          locationOtherAgeGroup2NonApplication.length +
                          locationOtherAgeGroup3IndigenousApplication.length +
                          locationOtherAgeGroup3NonApplication.length +
                          locationOtherAgeGroup4IndigenousApplication.length +
                          locationOtherAgeGroup4NonApplication.length +
                          locationOtherAgeGroup5IndigenousApplication.length +
                          locationOtherAgeGroup5NonApplication.length;

                        return (
                          <tr key={category.categoryTypeId}>
                            {index === 0 && (
                              <th className={'header-side-bottom'} rowSpan={categoryTypes.length + 1}>
                                {location.name}
                              </th>
                            )}
                            <th className={'header-side'}>{category.name}</th>
                            <td className={'report-row'}>{locationMaleAgeGroup1IndigenousApplication.length}</td>
                            <td className={'report-row'}>{locationMaleAgeGroup1NonApplication.length}</td>
                            <td className={'report-row'}>{locationMaleAgeGroup2IndigenousApplication.length}</td>
                            <td className={'report-row'}>{locationMaleAgeGroup2NonApplication.length}</td>
                            <td className={'report-row'}>{locationMaleAgeGroup3IndigenousApplication.length}</td>
                            <td className={'report-row'}>{locationMaleAgeGroup3NonApplication.length}</td>
                            <td className={'report-row'}>{locationMaleAgeGroup4IndigenousApplication.length}</td>
                            <td className={'report-row'}>{locationMaleAgeGroup4NonApplication.length}</td>
                            <td className={'report-row'}>{locationMaleAgeGroup5IndigenousApplication.length}</td>
                            <td className={'report-row'}>{locationMaleAgeGroup5NonApplication.length}</td>
                            <td className={'total'}>
                              <b>{totalMale}</b>
                            </td>
                            <td className={'report-row'}>{locationFemaleAgeGroup1IndigenousApplication.length}</td>
                            <td className={'report-row'}>{locationFemaleAgeGroup1NonApplication.length}</td>
                            <td className={'report-row'}>{locationFemaleAgeGroup2IndigenousApplication.length}</td>
                            <td className={'report-row'}>{locationFemaleAgeGroup2NonApplication.length}</td>
                            <td className={'report-row'}>{locationFemaleAgeGroup3IndigenousApplication.length}</td>
                            <td className={'report-row'}>{locationFemaleAgeGroup3NonApplication.length}</td>
                            <td className={'report-row'}>{locationFemaleAgeGroup4IndigenousApplication.length}</td>
                            <td className={'report-row'}>{locationFemaleAgeGroup4NonApplication.length}</td>
                            <td className={'report-row'}>{locationFemaleAgeGroup5IndigenousApplication.length}</td>
                            <td className={'report-row'}>{locationFemaleAgeGroup5NonApplication.length}</td>
                            <td className={'total'}>
                              <b>{totalFemale}</b>
                            </td>
                            <td className={'report-row'}>{locationOtherAgeGroup1IndigenousApplication.length}</td>
                            <td className={'report-row'}>{locationOtherAgeGroup1NonApplication.length}</td>
                            <td className={'report-row'}>{locationOtherAgeGroup2IndigenousApplication.length}</td>
                            <td className={'report-row'}>{locationOtherAgeGroup2NonApplication.length}</td>
                            <td className={'report-row'}>{locationOtherAgeGroup3IndigenousApplication.length}</td>
                            <td className={'report-row'}>{locationOtherAgeGroup3NonApplication.length}</td>
                            <td className={'report-row'}>{locationOtherAgeGroup4IndigenousApplication.length}</td>
                            <td className={'report-row'}>{locationOtherAgeGroup4NonApplication.length}</td>
                            <td className={'report-row'}>{locationOtherAgeGroup5IndigenousApplication.length}</td>
                            <td className={'report-row'}>{locationOtherAgeGroup5NonApplication.length}</td>
                            <td className={'total'}>
                              <b>{totalOther}</b>
                            </td>
                            <td className={'total'}>
                              <b>{totalMale + totalFemale + totalOther}</b>
                            </td>
                          </tr>
                        );
                      })
                      .concat(
                        <tr key={'total-' + location.circuitLocationId}>
                          <th className={'header-side-bottom'} />
                          <td className={'total-bottom'}>
                            <b>{locationMaleAgeGroup1Indigenous.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationMaleAgeGroup1Non.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationMaleAgeGroup2Indigenous.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationMaleAgeGroup2Non.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationMaleAgeGroup3Indigenous.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationMaleAgeGroup3Non.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationMaleAgeGroup4Indigenous.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationMaleAgeGroup4Non.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationMaleAgeGroup5Indigenous.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationMaleAgeGroup5Non.length}</b>
                          </td>
                          <td className={'total-bottom'} />
                          <td className={'total-bottom'}>
                            <b>{locationFemaleAgeGroup1Indigenous.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationFemaleAgeGroup1Non.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationFemaleAgeGroup2Indigenous.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationFemaleAgeGroup2Non.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationFemaleAgeGroup3Indigenous.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationFemaleAgeGroup3Non.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationFemaleAgeGroup4Indigenous.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationFemaleAgeGroup4Non.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationFemaleAgeGroup5Indigenous.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationFemaleAgeGroup5Non.length}</b>
                          </td>
                          <td className={'total-bottom'} />
                          <td className={'total-bottom'}>
                            <b>{locationOtherAgeGroup1Indigenous.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationOtherAgeGroup1Non.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationOtherAgeGroup2Indigenous.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationOtherAgeGroup2Non.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationOtherAgeGroup3Indigenous.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationOtherAgeGroup3Non.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationOtherAgeGroup4Indigenous.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationOtherAgeGroup4Non.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationOtherAgeGroup5Indigenous.length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationOtherAgeGroup5Non.length}</b>
                          </td>
                          <td className={'total-bottom'} />
                          <td className={'total-bottom'} />
                        </tr>
                      );
                  })}
                {undefinedCircuitLcations()}
                <tr key={'total'}>
                  <th className={'header-side'} colSpan={2}>
                    Total
                  </th>
                  <td className={'total'}>
                    <b>{totalMaleAgeGroup1Indigenous.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalMaleAgeGroup1Non.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalMaleAgeGroup2Indigenous.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalMaleAgeGroup2Non.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalMaleAgeGroup3Indigenous.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalMaleAgeGroup3Non.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalMaleAgeGroup4Indigenous.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalMaleAgeGroup4Non.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalMaleAgeGroup5Indigenous.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalMaleAgeGroup5Non.length}</b>
                  </td>
                  <td className={'total'} />
                  <td className={'total'}>
                    <b>{totalFemaleAgeGroup1Indigenous.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalFemaleAgeGroup1Non.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalFemaleAgeGroup2Indigenous.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalFemaleAgeGroup2Non.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalFemaleAgeGroup3Indigenous.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalFemaleAgeGroup3Non.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalFemaleAgeGroup4Indigenous.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalFemaleAgeGroup4Non.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalFemaleAgeGroup5Indigenous.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalFemaleAgeGroup5Non.length}</b>
                  </td>
                  <td className={'total'} />
                  <td className={'total'}>
                    <b>{totalOtherAgeGroup1Indigenous.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalOtherAgeGroup1Non.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalOtherAgeGroup2Indigenous.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalOtherAgeGroup2Non.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalOtherAgeGroup3Indigenous.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalOtherAgeGroup3Non.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalOtherAgeGroup4Indigenous.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalOtherAgeGroup4Non.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalOtherAgeGroup5Indigenous.length}</b>
                  </td>
                  <td className={'total'}>
                    <b>{totalOtherAgeGroup5Non.length}</b>
                  </td>
                  <td className={'total'} />
                  <td className={'total'} />
                </tr>
              </>
            ) : (
              undefinedCircuitLcations()
            )}
          </tbody>
        </table>
      </div>
      <div className={'px-5 pb-5 no-print'}>
        <p>Files not included: </p>
        <ul>
          {notIncluded.map((f) => (
            <li key={f.fId}>
              {myUserContext.isReportingOnly ? (
                <div>{f.fNum + (f.fSeqNum > 1 ? '-' + f.fSeqNum : '')}</div>
              ) : (
                <Link to={'/files/' + f.fId}>{f.fNum + (f.fSeqNum > 1 ? '-' + f.fSeqNum : '')}</Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
