import * as React from 'react';
import { useState } from 'react';
import { Button, CustomInput, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { CSSProperties } from 'react';

interface IProps {
  options: Array<{ label: string; options: Array<{ value: string; display: string }> }>;
  values: string[];
  setValues: (s: string[]) => void;
  keyPrefix: string;
  style?: CSSProperties;
}

export const DropdownGroupedMultiSelectCheckboxes: React.FC<IProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((s) => !s);
  };

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  return (
    <Dropdown key={props.keyPrefix + '_dropdown'} isOpen={isOpen} toggle={toggleDropdown} style={props.style}>
      <DropdownToggle
        caret={true}
        className={'d-flex align-items-center justify-content-between w-100 text-left'}
        outline={props.values.length === 0}
        size={'sm'}
      >
        {props.values.length === 0 ? 'Show All' : 'Selected: ' + props.values.length}
      </DropdownToggle>
      <DropdownMenu className={'p-3'}>
        {props.options.map(({ label, options }) => {
          return (
            <div key={label}>
              <div style={groupStyles}>
                <span
                  onClick={() => {
                    if (options.every((o) => props.values.find((id: string) => o.value === id) !== undefined)) {
                      // all are selected, unselect them
                      props.setValues(props.values.filter((id: string) => !options.map((o) => o.value).includes(id)));
                    } else {
                      // all aren't select, select them all
                      const updatedList = props.values
                        .filter((id: string) => !options.map((o) => o.value).includes(id))
                        .concat(options.map((o) => o.value));
                      props.setValues(updatedList);
                    }
                  }}
                >
                  {label}
                </span>
                <span
                  style={{
                    backgroundColor: '#EBECF0',
                    borderRadius: '2em',
                    color: '#172B4D',
                    display: 'inline-block',
                    fontSize: 12,
                    fontWeight: 'normal',
                    lineHeight: '1',
                    minWidth: 1,
                    padding: '0.16666666666667em 0.5em',
                    textAlign: 'center',
                  }}
                >
                  {options.length}
                </span>
              </div>
              {options.map(({ value, display }) => (
                <CustomInput
                  type={'checkbox'}
                  id={props.keyPrefix + '_' + value}
                  key={props.keyPrefix + '_' + value}
                  name={value}
                  label={display}
                  checked={props.values.find((id: string) => value === id) !== undefined}
                  onChange={() => {
                    if (props.values.find((id: string) => value === id) !== undefined) {
                      props.setValues(props.values.filter((id: string) => id !== value));
                    } else {
                      props.setValues([...props.values, value]);
                    }
                  }}
                />
              ))}
            </div>
          );
        })}
        <Button onClick={() => props.setValues([])} block={true} outline={true} color={'primary'} className={'mt-2'}>
          Clear All
        </Button>
      </DropdownMenu>
    </Dropdown>
  );
};
