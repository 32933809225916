import { Button, Card, CardBody, CardHeader, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import * as React from 'react';
import { useContext, useState } from 'react';
import { STAGE_CLOSED, STAGE_PENDING } from '../../../contexts/DomainContext';
import { authAxios } from '../../../services/AxiosService';
import { notifyError, notifySuccess } from '../../../services/ToastService';
import { useParams } from 'react-router';
import { formatDate } from '../../../formatters/DateTimeFormatter';
import { ConfirmModal } from '../../ConfirmModal/ConfirmModal';
import { NonClientFileContext } from '../NonClientFileContext';

export const CloseNonClientFile = () => {
  const params = useParams<{ fileId: string }>();
  const fileContext = useContext(NonClientFileContext);
  const setFile = fileContext.setFile;

  const [closedDate, setClosedDate] = useState<Date | undefined>();
  const [tryClose, setTryClose] = useState(false);
  const [modal, setModal] = useState(false);
  const [reopenModal, setReopenModal] = useState(false);

  const closeFile = () => {
    if (closedDate && params.fileId) {
      const data = { fileId: params.fileId, stageId: STAGE_CLOSED, overrideDate: closedDate.getTime() };
      authAxios.post('/api/non-client-files/start-stage', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('Stage updated.');
          setFile((f) => ({ ...f, stageId: STAGE_CLOSED, closedDate: closedDate.getTime() }));
        } else {
          notifyError(response.data.errorMessage);
        }
      });
    } else {
      setTryClose(true);
    }
  };

  const updateStage = (stageId: string) => {
    if (params.fileId) {
      const data = { fileId: params.fileId, stageId };
      authAxios.post('/api/non-client-files/start-stage', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('File stage has been updated.');
          setFile((f) => ({ ...f, stageId: stageId }));
        } else {
          notifyError(response.data.status);
        }
      });
    }
  };

  return (
    <>
      <Card className={'bg-primary-light'}>
        <CardHeader>Close File</CardHeader>
        <CardBody className={'pt-2'}>
          {fileContext.file.stageId === STAGE_CLOSED ? (
            <>
              <Label>Closed Date</Label>
              <div className={'div--read-only'}>
                {fileContext.file.closedDate ? formatDate(new Date(fileContext.file.closedDate)) : ' '}
              </div>
              <Button color='success' className={'mt-3 ml-auto'} onClick={() => setReopenModal(true)}>
                Reopen File
              </Button>
            </>
          ) : (
            <>
              <Label style={{ fontWeight: 'initial' }}>Closed Date</Label>
              <DatePicker
                selected={closedDate}
                onChange={(date) => {
                  if (date) {
                    setClosedDate(date);
                  }
                }}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dateFormat='MMM dd, yyyy'
                dropdownMode='select'
                className='date-picker rounded mb-0'
                placeholderText={'Select...'}
              />
              {tryClose && closedDate === undefined && (
                <div style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                  This field is mandatory
                </div>
              )}
              <Button
                color={'success'}
                className={'mt-3'}
                onClick={() => {
                  if (closedDate) {
                    setModal(true);
                  } else {
                    setTryClose(true);
                  }
                }}
              >
                Close File
              </Button>
            </>
          )}
        </CardBody>
      </Card>{' '}
      <ConfirmModal
        isOpen={modal}
        title={'Close File'}
        onConfirm={() => {
          setModal(false);
          closeFile();
        }}
        onCancel={() => setModal(false)}
        confirmButton={'Close File'}
      >
        <div>Are you sure you want to close this file?</div>
      </ConfirmModal>
      <ConfirmModal
        isOpen={reopenModal}
        title={'Reopen File'}
        onConfirm={() => {
          setReopenModal(false);
          updateStage(STAGE_PENDING);
        }}
        onCancel={() => setReopenModal(false)}
        confirmButton={'Reopen File'}
      >
        <div>Are you sure that you want to re-open this file?</div>
      </ConfirmModal>
    </>
  );
};
