import * as React from 'react';
import { useEffect, useState } from 'react';
import { Input, InputProps } from 'reactstrap';

interface ICurrencyInputProps extends InputProps {
  value: number | undefined;
  setValue: (e: number | undefined) => void;
}

const formatCurrencyCommas = (amount: number | undefined) => {
  if (amount) {
    return new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(amount).replace(/\$/g, '');
  } else {
    return '';
  }
};

export const CurrencyInput: React.FC<ICurrencyInputProps> = ({ value, setValue, ...props }) => {
  const [display, setDisplay] = useState('');
  const [pauseDisplayUpdate, setPauseDisplayUpdate] = useState(false);

  useEffect(() => {
    if (!pauseDisplayUpdate) {
      setDisplay(formatCurrencyCommas(value));
    }
  }, [value, pauseDisplayUpdate]);

  return (
    <Input
      {...props}
      value={display}
      style={{ textAlign: 'right' }}
      type={'text'}
      placeholder={'0.00'}
      autoComplete={'off'}
      onChange={(e: any) => {
        const textValue = e.target.value.replace(/[^-,.0-9]/g, '');
        if (textValue) {
          const updatedAmount = Math.round(100 * parseFloat(textValue.replace(/,/g, ''))) / 100;
          setDisplay(textValue);
          setValue(updatedAmount);
        } else {
          setDisplay('');
          setValue(undefined);
        }
      }}
      onFocus={() => setPauseDisplayUpdate(true)}
      onBlur={() => {
        setPauseDisplayUpdate(false);
      }}
    />
  );
};
