import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Label, Nav, Navbar, NavbarBrand } from 'reactstrap';
import { authAxios } from '../../services/AxiosService';
import { FaChevronDown, FaChevronUp, FaPrint } from 'react-icons/fa';
import { Column, TableInstance, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { DomainContext, IFileType } from '../../contexts/DomainContext';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { TablePagination } from '../TablePagination/TablePagination';
import { PeopleContext } from '../../contexts/PeopleContext';
import { IUser } from '../Users/UsersTable';
import { DropdownMultiSelectCheckboxes } from '../DropdownMultiSelectCheckboxes/DropdownMultiSelectCheckboxes';
import DatePicker from 'react-datepicker';
import * as ToastService from '../../services/ToastService';
import Select from 'react-select';

interface IProps {
  selectRow?: (userId: string) => void;
}

export interface IReportData {
  userId: string;
  userName: string;
  dutyCounsel: number;
  filesAssignedByType: Array<{ applicationTypeId: string; count: number }>;
  filesClosedByType: Array<{ applicationTypeId: string; count: number }>;
  closed: number;
  assigned: number;
}

const ReportDatePicker: React.FC<{ dateValue: Date; setValue: (val: any) => void }> = (props) => {
  return (
    <DatePicker
      className={'form-control date-select flex date-picker-fixed'}
      selected={props.dateValue}
      onChange={(date) => {
        if (date) {
          props.setValue(date);
        }
      }}
      showMonthDropdown={true}
      showYearDropdown={true}
      shouldCloseOnSelect={true}
      dateFormat={'yyyy-MM-dd'}
    />
  );
};

export const IntakeReport: React.FC<IProps> = (props) => {
  const [reportData, setReportData] = useState<IReportData[]>([]);
  const domainContext = useContext(DomainContext);
  const fileTypes = domainContext.fileTypes;
  const peopleContext = useContext(PeopleContext);
  const [selectedFileTypes, setSelectedFileTypes] = useState<string[]>([]);
  const [reportFromDate, setReportFromDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 0)
  );
  const [reportToDate, setReportToDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59, 999)
  );
  const lawyerList = peopleContext.staffLawyers;
  const applicationTypes = domainContext.applicationTypes;

  const printLandscape = () => {
    const css = `body {zoom: 70%}
    @page { size: a3 landscape; }`;

    const style = document.createElement('style');
    style.media = 'print';
    style.id = 'page-orientation';
    style.innerHTML = css;
    document.head.appendChild(style);

    window.print();

    const child = document.getElementById('page-orientation');
    if (child) {
      if (child.parentNode) {
        child.parentNode.removeChild(child);
      }
    }
  };

  const updateData = (toaster: boolean) => {
    const fileTypes =
      (selectedFileTypes && selectedFileTypes.length > 0 && `&fileTypeIds=${selectedFileTypes.join(',')}`) || '';
    authAxios
      .get(
        `/api/reports/intake-report?startDate=${reportFromDate.getTime()}&endDate=${reportToDate.getTime()}${fileTypes}`
      )
      .then((response) => {
        setReportData(response.data);
        toaster && ToastService.notifySuccess('Report Updated');
      });
  };

  useEffect(() => {
    updateData(false);
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalFooter = (id: string) => {
    const returnFunction = (e: any) => {
      const total = e.rows.reduce((sum: any, row: any) => row.values[id] + sum, 0);
      return <>{total}</>;
    };
    return returnFunction;
  };

  const columns: Column<IReportData>[] = useMemo(
    () => [
      {
        Header: 'Lawyer',
        id: 'userId',
        Footer: 'Total',
        accessor: (f: IReportData) => {
          if (f.userId) {
            const lawyer = lawyerList.find((l: IUser) => l.userId === f.userId);
            return lawyer?.lastName.toUpperCase() + ', ' + lawyer?.firstName;
          } else {
            return '';
          }
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = [], preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='lawyerId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={lawyerList
                .filter((e) => e.active)
                .sort((a: IUser, b: IUser) => a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase()))
                .map((s: IUser) => ({
                  value: s.userId,
                  display: s.lastName.toUpperCase() + ', ' + s.firstName,
                }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['userId'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
      },
      {
        Header: `Duty Counsel Appearances`,
        accessor: `dutyCounsel`,
        id: 'duty_counsel',
        width: 50,
        disableSortBy: true,
        disableFilters: true,
        className: 'grey-background',
        Footer: totalFooter(`duty_counsel`),
      } as Column<IReportData>,
      ...applicationTypes.map((g) => {
        return {
          Header: `${g.name} Assigned`,
          id: `assigned_${g.applicationTypeId}`,
          width: 50,
          disableSortBy: true,
          disableFilters: true,
          accessor: (e: IReportData) => {
            return e.filesAssignedByType.find((t) => t.applicationTypeId === g.applicationTypeId)?.count || 0;
          },
          Footer: totalFooter(`assigned_${g.applicationTypeId}`),
        } as Column<IReportData>;
      }),
      {
        Header: `Total Assigned`,
        id: `assigned_total`,
        width: 50,
        disableSortBy: true,
        disableFilters: true,
        className: 'grey-background',
        accessor: (e: IReportData) => {
          return e.filesAssignedByType.find((t) => t.applicationTypeId === 'total')?.count || 0;
        },
        Footer: totalFooter(`assigned_total`),
      } as Column<IReportData>,
      ...applicationTypes.map((g) => {
        return {
          Header: `${g.name} Closed`,
          id: `closed_${g.applicationTypeId}`,
          width: 50,
          disableSortBy: true,
          disableFilters: true,
          accessor: (e: IReportData) => {
            return e.filesClosedByType.find((t) => t.applicationTypeId === g.applicationTypeId)?.count || 0;
          },
          Footer: totalFooter(`closed_${g.applicationTypeId}`),
        } as Column<IReportData>;
      }),
      {
        Header: `Total Closed`,
        id: `closed_total`,
        width: 50,
        disableSortBy: true,
        disableFilters: true,
        className: 'grey-background',
        accessor: (e: IReportData) => {
          return e.filesClosedByType.find((t) => t.applicationTypeId === 'total')?.count || 0;
        },
        Footer: totalFooter(`closed_total`),
      } as Column<IReportData>,
    ],
    [applicationTypes, lawyerList]
  );

  const data: IReportData[] = useMemo(() => reportData, [reportData]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: TableInstance<IReportData> = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageSize: 100 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'}>
        <NavbarBrand className='mr-auto'>Intake Report</NavbarBrand>
        <Nav className={'ml-auto'}>
          <Button
            color='warning'
            type='submit'
            onClick={() => {
              printLandscape();
            }}
          >
            <FaPrint /> Print
          </Button>
        </Nav>
      </Navbar>

      <div className='pb-4 pt-2 ml-4'>
        <div className={'d-flex flex-wrap'}>
          <div className={'mr-4'}>
            <Label style={{ paddingRight: '10px' }}>From</Label>
            <ReportDatePicker
              dateValue={reportFromDate}
              setValue={(date: Date) => {
                date.setHours(0, 0, 0);
                setReportFromDate(date);
              }}
            />
          </div>
          <div className={'mr-4'}>
            <Label for={'EndDate'} style={{ paddingRight: '10px' }}>
              To
            </Label>
            <ReportDatePicker
              dateValue={reportToDate}
              setValue={(date: Date) => {
                date.setHours(23, 59, 59);
                setReportToDate(date);
              }}
            />
          </div>
          <div className={'flex-grow-1 mr-4'} style={{ minWidth: 200, maxWidth: 500 }}>
            <Label for={'EndDate'} style={{ paddingRight: '10px' }}>
              File Types
            </Label>
            <Select
              options={fileTypes}
              placeholder={'All File Types'}
              isMulti={true}
              isClearable={true}
              value={fileTypes.filter((ft) => selectedFileTypes.find((id: string) => id === ft.fileTypeId))}
              onChange={(e: any) => {
                setSelectedFileTypes(e?.map((i: any) => i.fileTypeId) || []);
              }}
              getOptionLabel={(option: IFileType) => option.name}
              getOptionValue={(option: IFileType) => option.fileTypeId}
            />
          </div>
          <div className={'mr-4 d-flex align-items-end mt-2'}>
            <Button color='primary' onClick={() => updateData(true)}>
              Refresh Data
            </Button>
          </div>
        </div>
      </div>
      <div className={'table-responsive'} style={{ minHeight: '750px' }}>
        <table className={'table table-bordered table-hover'} {...getTableProps()}>
          <thead style={{ overflow: 'visible' }}>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`files-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={`files-table-thead-tr-${index}-${column.id}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    width={column.width}
                    style={{ verticalAlign: 'top' }}
                  >
                    {column.render('Header')}
                    <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                    <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
            {page.map((row: any) => {
              prepareRow(row);

              return (
                <tr key={`files-table-tr-${row.id}`} {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        key={`files-table-td-${cell.row.id}-${cell.column.id}`}
                        {...cell.getCellProps([{ className: cell.column.className }])}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((group, index) => (
              // @ts-ignore
              <tr key={`files-table-tfoot-tr-${index}`} {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td
                    // @ts-ignore
                    key={`files-table-tfoot-tr-${index}-${column.id}`}
                    {...column.getFooterProps()}
                    className={'grey-background'}
                  >
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
      {pageCount > 1 && (
        <TablePagination
          pageCount={pageCount}
          pageOptions={pageOptions}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageSizes={[20, 50, 100, 500]}
        />
      )}
    </div>
  );
};
