import { getJwtFromStorage } from '../../services/AuthenticationService';

export const downloadDocument = (fileId: string, documentId: string) => {
  const getUrl = () => '/api/uploads/download/' + fileId + '/' + documentId + '/' + getJwtFromStorage();
  const link = document.createElement('a');
  console.log(getUrl());
  link.href = getUrl();
  link.setAttribute('download', 'download');
  document.body.appendChild(link);
  link.click();
};
