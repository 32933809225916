import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, Route, useParams } from 'react-router-dom';
import * as H from 'history';
import { Container, Nav, NavItem, NavLink } from 'reactstrap';

import { authAxios } from '../../services/AxiosService';
import { OffenceDetails } from './OffenceDetails';
import { IOffence } from './OffenceTable';

export const Offence = () => {
  const params = useParams<{ offenceId: string; tab?: string }>();
  const [offence, setOffence] = useState<IOffence | undefined>(undefined);

  useEffect(() => {
    if (params.offenceId) {
      authAxios.get('/api/offences/admin/' + params.offenceId).then((response) => {
        setOffence(response.data);
      });
    }
  }, [params.offenceId]);

  const tab: string = params.tab || 'details';

  const NavItemLink = (to: H.LocationDescriptor, active: boolean, name: any) => {
    return (
      <NavItem>
        <NavLink tag={Link} to={to} active={active}>
          {name}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <article className='page'>
      <header className='page-header'>
        <Container fluid={true}>
          {offence !== undefined && (
            <div className='d-flex flex-column py-3'>
              <h1 className='page-header__title order-2'>{offence?.number}</h1>
            </div>
          )}
          <div className='d-none d-md-block'>
            <Nav tabs={true}>
              {NavItemLink('/offences/' + params.offenceId + '/details', tab === 'details', 'Details')}
            </Nav>
          </div>
          <div className='d-block d-md-none pb-4'>
            <Nav pills={true} vertical={true}>
              {NavItemLink('/offences/' + params.offenceId + '/details', tab === 'details', 'Details')}
            </Nav>
          </div>
        </Container>
      </header>

      <Route exact={true} path='/offences/:offenceId/(details)?' component={OffenceDetails} />
    </article>
  );
};
