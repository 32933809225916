import * as React from 'react';
import { Button, Card, CardBody, CardHeader, Form, FormGroup, Input, Label } from 'reactstrap';
import logo from '../Login/ylss-logo.png';
import { Link, useLocation } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import * as AxiosService from '../../services/AxiosService';
import { useTextFormInput } from '../../hooks/FormHooks';
import { useState } from 'react';

export const ForgotPassword: React.FC = () => {
  const location = useLocation<{ errorMessage: string }>();

  const email = useTextFormInput('');
  const [sent, setSent] = useState(false);

  const validateForm = () => {
    return email.value.length > 0;
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    AxiosService.anonAxios
      .post('/api/users/forgot-password', {
        email: email.value,
      })
      .then((response) => {
        if (response.data.status === 'OK') {
          setSent(true);
        } else {
          // this.setState({errorMsg: "Registration Error"})
        }
      })
      .catch((error) => {
        const errorMessage = error.response.data.userMessage;
        if (errorMessage) {
          console.log('An error happened');
          // this.setState({errorMsg: errorMessage});
        }
      });
  };

  return (
    <div className='ForgotPasswordWrapper LoginWrapper'>
      <div className='LogoBar'>
        <img src={logo} style={{ height: 210 }} className='ylss-logo' alt='YLSS Logo' />
      </div>
      <Card>
        <CardHeader>
          <Link to='/login' color={'secondary'}>
            <FaArrowLeft className='mr-1' />
            Back
          </Link>
        </CardHeader>
        <CardBody>
          <Form>
            {location?.state?.errorMessage && <p className='text-danger'>{location.state.errorMessage}</p>}
            {!sent ? (
              <div>
                <p>Enter the email address for your user account and we will email you a link to rest your password.</p>
                <FormGroup>
                  <Label>Email</Label>
                  <Input {...email} autoFocus={true} name='email' />
                </FormGroup>
                <Button
                  block={true}
                  color='primary'
                  disabled={!validateForm()}
                  onClick={(e: any) => handleSubmit(e)}
                  style={{ marginBottom: '1em' }}
                  type='submit'
                >
                  Reset Password
                </Button>
              </div>
            ) : (
              <div>
                <p className='mt-3'>
                  If you have entered an email with a valid user account, you will receive an email link to reset your
                  password.
                </p>
              </div>
            )}
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};
