import * as React from 'react';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import { FormEvent, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { useContext } from 'react';
import { FileContext, IAsset, IIncome, IIncomeType, IMonthlyIncome } from './FileContext';
import Select from 'react-select';
import { authAxios } from '../../services/AxiosService';
import { useParams } from 'react-router';
import { notifyError, notifySuccess } from '../../services/ToastService';
import { DomainContext, editableStages } from '../../contexts/DomainContext';
import { FaPlus } from 'react-icons/all';
import { MyUserContext } from '../../contexts/MyUserContext';

export const FinancialEligibility: React.FC = () => {
  const [isDirty, setIsDirty] = useState(false);
  const fileContext = useContext(FileContext);
  const myUserContext = useContext(MyUserContext);
  const file = fileContext.file;
  const domainContext = useContext(DomainContext);
  const params = useParams<{ fileId: string }>();

  const maritalStatusOptions = domainContext.maritalStatuses;
  const livingArrangementOptions = domainContext.livingArrangements;
  const incomeTypeOptions = domainContext.incomeTypes;

  const maritalStatus = fileContext.financialDetails.maritalStatusId || '';
  const livingArrangement = fileContext.financialDetails.livingArrangementId || '';
  const numberOfAdults = fileContext.financialDetails.numberOfAdults;
  const numberOfChildren = fileContext.financialDetails.numberOfChildren;
  const [assets, setAssets] = useState<IAsset[]>([]);
  const [newMonthId, setNewMonthId] = useState<string>('');
  const [isEditingAllowed, setIsEditingAllowed] = useState(
    myUserContext ? false : params.fileId ? editableStages.includes(file.stageId) : true
  );
  const [incomeByMonth, setIncomeByMonth] = useState<IMonthlyIncome[]>([]);
  const version1CutoffDate = new Date('10/01/2023');
  const isVersion1 = new Date(file.appDate).getTime() < version1CutoffDate.getTime();

  const setFinancialDetails = fileContext.setFinancialDetails;

  const disabledSelectStyles = {
    control: (styles: any, state: any) => {
      return {
        ...styles,
        borderColor: '#ced4da',
      };
    },
    singleValue: (styles: any, state: any) => {
      return {
        ...styles,
        color: '#495057',
      };
    },
  };

  const monthList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  useEffect(() => {
    authAxios.get('/api/files/admin/' + params.fileId + '/financial-details').then((response) => {
      if (response.data) {
        setFinancialDetails({
          maritalStatusId: response.data.maritalStatus,
          livingArrangementId: response.data.livingArrangement,
          numberOfAdults: response.data.numberOfAdults,
          numberOfChildren: response.data.numberOfChildren,
          monthlyIncomes: formatMonthlyIncomes(sortMonths(response.data.monthlyIncomes)),
          assets:
            response.data.assets.length === 0
              ? [
                  {
                    name: '',
                    amount: undefined,
                  },
                ]
              : formatAssets(response.data.assets),
          averageMonthlyIncome: response.data.averageMonthlyIncome,
          averageYearlyIncome: response.data.averageYearlyIncome,
          maxMonthlyIncome: response.data.maxMonthlyIncome,
          maxYearlyIncome: response.data.maxYearlyIncome,
          percentOverage: response.data.percentOverage,
        });
        setIncomeByMonth(formatMonthlyIncomes(sortMonths(response.data.monthlyIncomes)));
        setAssets(
          response.data.assets.length === 0
            ? [
                {
                  name: '',
                  amount: undefined,
                },
              ]
            : formatAssets(response.data.assets)
        );
      }
    });
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.fileId, setFinancialDetails]);

  useEffect(() => {
    const newElement = document.getElementById(newMonthId);
    newElement &&
      newElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
  }, [newMonthId]);

  const formatCurrencyCommasTwoDigits = (amount: string | undefined) => {
    if (amount) {
      const floatToUse = parseFloat(amount.toString().replace(/[, ]+/g, ''));
      return Number.isNaN(floatToUse)
        ? '0.00'
        : floatToUse
            .toFixed(2)
            .toString()
            .replace(/-[^0-9.]/g, '')
            .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    } else {
      return '';
    }
  };

  const formatCurrencyCommas = (amount: string | undefined) => {
    if (amount) {
      return amount
        .toString()
        .replace(/[, ]+/g, '')
        .replace(/-[^0-9.]/g, '')
        .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    } else {
      return '';
    }
  };

  const formattedAmountToNumber = (amount: string | undefined) => {
    if (amount) {
      if (amount.length > 0 && amount.charAt(0) === '-' && amount !== '-') {
        return -Math.abs(Number(amount.toString().replace(/[, ]+|[- ]+/g, '')));
      } else {
        return Number(amount.toString().replace(/[,]+|[- ]+/g, ''));
      }
    } else {
      return 0;
    }
  };

  const formatMonthlyIncomes = (monthlyIncomes: IMonthlyIncome[]) => {
    return monthlyIncomes.map((month) => {
      return {
        ...month,
        incomes: month.incomes.map((income) => {
          return {
            incomeTypeId: income.incomeTypeId,
            amount: formatCurrencyCommasTwoDigits(income.amount),
          } as IIncome;
        }),
      } as IMonthlyIncome;
    });
  };

  const formatAssets = (assetList: IAsset[]) => {
    return assetList.map((asset: IAsset) => {
      return { name: asset.name, amount: formatCurrencyCommasTwoDigits(asset.amount) } as IAsset;
    });
  };

  const monthsToSelectFrom = () => {
    const currentDate = new Date();
    let currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    let monthArray: any[] = [];
    while (currentMonth >= 0) {
      monthArray = monthArray.concat([{ month: monthList[currentMonth], year: currentYear }]);
      currentMonth--;
    }
    let previousYearMonth = 11;
    while (monthArray.length < 12) {
      monthArray = monthArray.concat([{ month: monthList[previousYearMonth], year: currentYear - 1 }]);
      previousYearMonth--;
    }
    monthArray = monthArray.filter((m) => !incomeByMonth.map((i) => i.month).includes(m.month));
    return monthArray;
  };

  const totalMonthlyIncome = (incomes: IIncome[], formatString: boolean) => {
    let total = 0;
    incomes.forEach((i) => {
      const numberAmount = i.amount ? formattedAmountToNumber(i.amount) : 0;
      const numberToShow = Number.isNaN(numberAmount) ? 0 : numberAmount;
      total += numberToShow;
    });
    return formatString ? formatCurrencyCommasTwoDigits(total.toString()) : total.toFixed(2);
  };

  const saveDetails = () => {
    const unformattedAssets = assets.map((a) => {
      const numToUse = formattedAmountToNumber(a.amount);
      return { name: a.name, amount: Number.isNaN(numToUse) ? 0 : numToUse };
    });
    const unformattedMonthlyIncomes = incomeByMonth.map((m) => {
      return {
        monthlyIncomeId: m.monthlyIncomeId,
        month: m.month,
        year: m.year,
        incomes: m.incomes.map((i) => {
          const numToUse = formattedAmountToNumber(i.amount);
          return {
            incomeTypeId: i.incomeTypeId,
            amount: Number.isNaN(numToUse) ? 0 : numToUse,
          };
        }),
      };
    });
    const data = {
      fileId: params.fileId,
      maritalStatusId: maritalStatus,
      livingArrangementId: livingArrangement,
      numAdults: numberOfAdults,
      numChildren: numberOfChildren,
      assets: unformattedAssets,
      monthlyIncomes: unformattedMonthlyIncomes,
      averageMonthlyIncome: calculateMonthlyAverage(true),
      averageYearlyIncome: calculateYearlyAverage(),
      maxMonthlyIncome: calculateMaxMonthlyIncome(true),
      maxYearlyIncome: calculateMaxYearlyIncome(),
      percentOverage: calculatePercentOverage(),
    };
    authAxios.post('/api/files/admin/update-financial-eligibility', data).then((response) => {
      if (response.data.status === 'OK') {
        notifySuccess('File has been updated.');
        setFinancialDetails((f) => ({ ...f, monthlyIncomes: incomeByMonth, assets: assets }));
        setIsDirty(false);
        if (!editableStages.includes(file.fileId)) {
          setIsEditingAllowed(false);
        }
      } else {
        notifyError(response.data.status);
      }
    });
  };

  const calculateMonthlyAverage = (formatString: boolean) => {
    const monthlyTotals = incomeByMonth.map((i) => totalMonthlyIncome(i.incomes, false));
    let total = 0;
    monthlyTotals.forEach((m) => {
      if (m) {
        total = total + Number(m);
      }
    });
    return monthlyTotals.length > 0
      ? formatString
        ? formatCurrencyCommasTwoDigits((total / monthlyTotals.length).toString())
        : (total / monthlyTotals.length).toFixed(2)
      : 0;
  };

  const calculatePercentOverage = () => {
    if (Number(calculateMonthlyAverage(false)) > Number(calculateMaxMonthlyIncome(false))) {
      return formatCurrencyCommas(
        (
          ((Number(calculateMonthlyAverage(false)) - Number(calculateMaxMonthlyIncome(false))) /
            Number(calculateMaxMonthlyIncome(false))) *
          100
        ).toFixed(0)
      );
    } else {
      return undefined;
    }
  };

  const calculateYearlyAverage = () => {
    const monthlyAverage = calculateMonthlyAverage(false);
    return monthlyAverage ? formatCurrencyCommasTwoDigits((Number(monthlyAverage) * 12).toString()) : undefined;
  };

  const sortMonths = (monthlyIncome: IMonthlyIncome[]) => {
    return monthlyIncome.sort((a: IMonthlyIncome, b: IMonthlyIncome) => {
      if (a.year < b.year) {
        return 1;
      } else if (a.year > b.year) {
        return -1;
      } else {
        if (
          monthList.indexOf(a.month) !== -1 &&
          monthList.indexOf(b.month) !== -1 &&
          monthList.indexOf(a.month) < monthList.indexOf(b.month)
        ) {
          return 1;
        } else if (
          monthList.indexOf(a.month) !== -1 &&
          monthList.indexOf(b.month) !== -1 &&
          monthList.indexOf(a.month) > monthList.indexOf(b.month)
        ) {
          return -1;
        }
      }
      return 0;
    });
  };

  const calculateMaxMonthlyIncome = (formatString: boolean) => {
    let result = 0;
    let base = 0;
    if (isVersion1) {
      if (numberOfAdults === undefined || Number.isNaN(numberOfAdults) || numberOfAdults === 1) {
        base = 1800;
      } else if (numberOfAdults === 2) {
        base = 2200;
      } else if (numberOfAdults > 2) {
        const extraAdults = numberOfAdults - 2;
        base = 2200 + extraAdults * 450;
      }
      if (numberOfChildren === undefined || Number.isNaN(numberOfChildren)) {
        result = base;
      } else {
        result = base + numberOfChildren * 300;
      }
    } else {
      if (numberOfAdults === undefined || Number.isNaN(numberOfAdults) || numberOfAdults === 1) {
        base = 2250;
      } else if (numberOfAdults === 2) {
        base = 2750;
      } else if (numberOfAdults > 2) {
        const extraAdults = numberOfAdults - 2;
        base = 2750 + extraAdults * 562.5;
      }
      if (numberOfChildren === undefined || Number.isNaN(numberOfChildren)) {
        result = base;
      } else {
        result = base + numberOfChildren * 375;
      }
    }

    if (formatString) {
      return formatCurrencyCommasTwoDigits(result.toString());
    } else {
      return result;
    }
  };

  const calculateMaxYearlyIncome = () => {
    return formatCurrencyCommasTwoDigits((Number(calculateMaxMonthlyIncome(false)) * 12).toString());
  };

  return (
    <div>
      <LeavePageConfirm isDirty={isDirty} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <Navbar color={'light'} light={true} expand={'xs'}>
          <Row className={'flex-fill'}>
            <Col md={3} xl={2}>
              <NavbarBrand>Financial Eligibility</NavbarBrand>
            </Col>
            {/*<Col className={'d-flex'}>*/}
            {!isEditingAllowed ? (
              <Col className={'d-flex'}>
                {!file.isLockedDown && (
                  <Button color='primary' className={'ml-auto'} onClick={() => setIsEditingAllowed(true)}>
                    Edit Details
                  </Button>
                )}
              </Col>
            ) : (
              <Col className={'d-flex'}>
                <Button color='success' className={'ml-auto'} onClick={() => saveDetails()}>
                  Save Details
                </Button>
              </Col>
            )}
            {/*</Col>*/}
          </Row>
        </Navbar>
        <Container fluid={true} className={'my-3'}>
          <Row>
            <Col sm={5} md={5} xl={5}>
              <Card>
                <CardHeader>Client Information</CardHeader>
                <CardBody>
                  <Form
                    onSubmit={(e: FormEvent<HTMLFormElement>) => {
                      e.preventDefault();
                    }}
                  >
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for={'maritalStatus'}>Marital Status</Label>
                          <Select
                            name={'maritalStatus'}
                            styles={disabledSelectStyles}
                            options={maritalStatusOptions}
                            placeholder={isEditingAllowed ? 'Select...' : ''}
                            value={maritalStatusOptions.find((o) => o.maritalStatusId === maritalStatus)}
                            onChange={(value: any) => {
                              setFinancialDetails((f) => ({
                                ...f,
                                maritalStatusId: value?.maritalStatusId || '',
                              }));
                              setIsDirty(true);
                            }}
                            getOptionValue={(option: any) => option.maritalStatusId}
                            getOptionLabel={(option: any) => option.name}
                            isDisabled={!isEditingAllowed}
                            isClearable={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Living Arrangement</Label>
                          <Select
                            name={'livingArrangement'}
                            styles={disabledSelectStyles}
                            options={livingArrangementOptions}
                            placeholder={isEditingAllowed ? 'Select...' : ''}
                            value={livingArrangementOptions.find((o) => o.livingArrangementId === livingArrangement)}
                            onChange={(value: any) => {
                              setFinancialDetails((f) => ({
                                ...f,
                                livingArrangementId: value?.livingArrangementId || '',
                              }));
                              setIsDirty(true);
                            }}
                            getOptionValue={(option: any) => option.livingArrangementId}
                            getOptionLabel={(option: any) => option.name}
                            isDisabled={!isEditingAllowed}
                            isClearable={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Number of Adults</Label>
                          <Input
                            type='number'
                            name='numAdults'
                            id='numAdults'
                            value={numberOfAdults}
                            onChange={(e: any) => {
                              const numAdults: number = parseFloat(e.target.value);
                              setFinancialDetails((f) => ({
                                ...f,
                                numberOfAdults: numAdults,
                              }));
                              setIsDirty(true);
                            }}
                            disabled={!isEditingAllowed}
                            placeholder={isEditingAllowed ? 'Type number here...' : ''}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Number of Youth</Label>
                          <Input
                            type='number'
                            name='numChildren'
                            id='numChildren'
                            value={numberOfChildren}
                            onChange={(e: any) => {
                              const numChildren: number = parseFloat(e.target.value);
                              setFinancialDetails((f) => ({
                                ...f,
                                numberOfChildren: numChildren,
                              }));
                              setIsDirty(true);
                            }}
                            disabled={!isEditingAllowed}
                            placeholder={isEditingAllowed ? 'Type number here...' : ''}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
              <Card className={'mt-3'}>
                <CardHeader className={'d-flex'}>
                  Assets
                  {isEditingAllowed && (
                    <Button
                      size={'sm'}
                      color={'primary'}
                      className={'ml-auto'}
                      onClick={() =>
                        setAssets(
                          assets
                            ? assets.concat({ name: '', amount: undefined })
                            : [
                                {
                                  name: '',
                                  amount: undefined,
                                },
                              ]
                        )
                      }
                    >
                      Add Asset
                    </Button>
                  )}
                </CardHeader>
                <CardBody className={'pb-0 pt-2'}>
                  {assets && assets.length > 0 && (
                    <Row>
                      <Col>
                        <Label>Asset</Label>
                      </Col>
                      <Col className={'ml-5'}>
                        <Label>Amount ($)</Label>
                      </Col>
                      <Col sm={1} md={1} lg={1} />
                    </Row>
                  )}
                  {assets &&
                    assets.length > 0 &&
                    assets.map((asset, assetIndex) => (
                      <Row className={'pb-3'} key={assetIndex}>
                        <Col>
                          <Input
                            value={asset.name}
                            type={'text'}
                            name={'Asset'}
                            placeholder={isEditingAllowed ? 'Type asset here...' : ''}
                            onChange={(e: any) => {
                              const updatedAsset = e.target.value;
                              setAssets((a) => [
                                ...a.slice(0, assetIndex),
                                { ...a[assetIndex], name: updatedAsset } as IAsset,
                                ...a.slice(assetIndex + 1),
                              ]);
                              setIsDirty(true);
                            }}
                            disabled={!isEditingAllowed}
                          />
                        </Col>
                        <Col>
                          <Input
                            value={asset.amount}
                            style={{ textAlign: 'right' }}
                            type={'text'}
                            name={'Asset Amount'}
                            placeholder={isEditingAllowed ? 'Type amount here...' : ''}
                            onChange={(e: any) => {
                              const updatedAmount = e.target.value;
                              setAssets((a) => [
                                ...a.slice(0, assetIndex),
                                {
                                  ...a[assetIndex],
                                  amount: formatCurrencyCommas(updatedAmount),
                                } as IAsset,
                                ...a.slice(assetIndex + 1),
                              ]);
                              setIsDirty(true);
                            }}
                            onBlur={(e: any) => {
                              const updatedAmount = e.target.value;
                              setAssets((a) => [
                                ...a.slice(0, assetIndex),
                                {
                                  ...a[assetIndex],
                                  amount: formatCurrencyCommasTwoDigits(updatedAmount),
                                } as IAsset,
                                ...a.slice(assetIndex + 1),
                              ]);
                            }}
                            disabled={!isEditingAllowed}
                          />
                        </Col>
                        {isEditingAllowed && (
                          <Col className={'third-party-x-button'}>
                            <button
                              className='close pt-1'
                              onClick={() => {
                                setAssets((a) => [...a.slice(0, assetIndex), ...a.slice(assetIndex + 1)]);
                                setIsDirty(true);
                              }}
                            >
                              &times;
                            </button>
                          </Col>
                        )}
                      </Row>
                    ))}
                </CardBody>
              </Card>
            </Col>
            <Col sm={7} md={7} xl={7}>
              <Card className={'bg-primary-light mb-3'}>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Maximum Monthly Income Based on Client Information ($)</Label>
                        <Input
                          type='text'
                          style={{ textAlign: 'right' }}
                          disabled={true}
                          name='maxMonthlyIncome'
                          id='maxMonthlyIncome'
                          value={calculateMaxMonthlyIncome(true)}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Maximum Yearly Income Based On Client Information ($)</Label>
                        <Input
                          type='text'
                          style={{ textAlign: 'right' }}
                          disabled={true}
                          name='maxMonthlyIncome'
                          id='maxMonthlyIncome'
                          value={calculateMaxYearlyIncome()}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className={'font-weight-normal'}>Average Monthly Income ($)</Label>
                        <Input
                          type='text'
                          style={{ textAlign: 'right' }}
                          disabled={true}
                          name='averageMonthlyIncome'
                          id='averageMonthlyIncome'
                          value={calculateMonthlyAverage(true) ? calculateMonthlyAverage(true) : ''}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className={'font-weight-normal'}>Average Yearly Income ($)</Label>
                        <Input
                          type='text'
                          disabled={true}
                          style={{ textAlign: 'right' }}
                          name='averageYearlyIncome'
                          id='averageYearlyIncome'
                          value={calculateYearlyAverage() ? calculateYearlyAverage() : ''}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {calculatePercentOverage() && (
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label className={'font-weight-normal'}>% of Overage</Label>
                          <Input
                            type='text'
                            style={{ textAlign: 'right' }}
                            disabled={true}
                            name='percentOverage'
                            id='percentOverage'
                            value={calculatePercentOverage()}
                          />
                        </FormGroup>
                      </Col>
                      <Col />
                    </Row>
                  )}
                </CardBody>
              </Card>
              <Card>
                <CardHeader className={'d-flex align-items-center'}>Income</CardHeader>
                <CardBody>
                  <Col>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label className={'font-weight-normal'}>Month Options</Label>
                          <Select
                            name={'monthSelect'}
                            styles={disabledSelectStyles}
                            placeholder={'Select month and year...'}
                            value={null}
                            options={monthsToSelectFrom()}
                            isClearable={true}
                            onChange={(value: any) => {
                              const monthlyIncomeId = Math.random().toString();
                              const sortedIncomes = sortMonths(
                                incomeByMonth.concat({
                                  monthlyIncomeId: monthlyIncomeId,
                                  month: value?.month,
                                  year: value?.year,
                                  incomes: [
                                    {
                                      incomeTypeId: '',
                                      amount: '',
                                    },
                                  ],
                                } as IMonthlyIncome)
                              );
                              setIncomeByMonth(sortedIncomes);
                              setIsDirty(true);
                              setNewMonthId(monthlyIncomeId);
                            }}
                            getOptionValue={(option: any) => option.month + ' ' + option.year}
                            getOptionLabel={(option: any) => option.month + ' ' + option.year}
                            isDisabled={!isEditingAllowed}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {incomeByMonth.length > 0 &&
                      incomeByMonth.map((income) => (
                        <div className={'mt-3'} key={income.monthlyIncomeId} id={income.monthlyIncomeId}>
                          <Row>
                            <ListGroup style={{ width: '100%' }}>
                              <ListGroupItem>
                                <ListGroup>
                                  <Row className={'d-flex'}>
                                    <b className={'mt-1 ml-3'}>{income.month + ' ' + income.year}</b>
                                    {isEditingAllowed && (
                                      <Button
                                        size={'sm'}
                                        color={'primary'}
                                        className={'ml-auto mr-3'}
                                        onClick={() => {
                                          setIncomeByMonth(
                                            incomeByMonth.map((i) => {
                                              if (i.monthlyIncomeId === income.monthlyIncomeId) {
                                                return {
                                                  monthlyIncomeId: i.monthlyIncomeId,
                                                  month: i.month,
                                                  year: i.year,
                                                  incomes: i.incomes.concat([{ incomeTypeId: '' }] as IIncome[]),
                                                } as IMonthlyIncome;
                                              } else {
                                                return i;
                                              }
                                            })
                                          );
                                        }}
                                      >
                                        Add Income <FaPlus />
                                      </Button>
                                    )}
                                  </Row>
                                  {income.incomes.length > 0 && (
                                    <Row style={{ width: '100%' }} className={'pl-4'}>
                                      <Col sm={5} md={5} lg={5}>
                                        <Label>Income Type</Label>
                                      </Col>
                                      <Col sm={5} md={5} lg={5}>
                                        <Label className='pl-1'>Amount ($)</Label>
                                      </Col>
                                    </Row>
                                  )}
                                  {income.incomes.map((i: IIncome, indexIncome) => (
                                    <ListGroupItem key={indexIncome}>
                                      <Row>
                                        <Col sm={5} md={5} lg={5}>
                                          <Select
                                            name={'incomeTypeId'}
                                            menuPlacement={'auto'}
                                            options={incomeTypeOptions}
                                            styles={disabledSelectStyles}
                                            value={incomeTypeOptions.find(
                                              (o: IIncomeType) => o.incomeTypeId === i.incomeTypeId
                                            )}
                                            onChange={(value: any) => {
                                              const updatedMonths = incomeByMonth.map((month) => {
                                                if (month.monthlyIncomeId === income.monthlyIncomeId) {
                                                  const updatedIncomes = month.incomes.map(
                                                    (incomeInMonth, indexToFind) => {
                                                      if (indexIncome === indexToFind) {
                                                        return {
                                                          incomeTypeId: value?.incomeTypeId,
                                                          amount: incomeInMonth.amount,
                                                        };
                                                      } else {
                                                        return incomeInMonth;
                                                      }
                                                    }
                                                  );
                                                  return {
                                                    monthlyIncomeId: month.monthlyIncomeId,
                                                    month: month.month,
                                                    year: month.year,
                                                    incomes: updatedIncomes,
                                                  } as IMonthlyIncome;
                                                } else {
                                                  return month;
                                                }
                                              });
                                              setIncomeByMonth(updatedMonths);
                                              setIsDirty(true);
                                            }}
                                            getOptionValue={(option: any) => option.incomeTypeId}
                                            getOptionLabel={(option: any) => option.name}
                                            isClearable={true}
                                            isDisabled={!isEditingAllowed}
                                          />
                                        </Col>
                                        <Col sm={5} md={5} lg={5}>
                                          <Input
                                            type='text'
                                            name='amount'
                                            id='amount'
                                            style={{ textAlign: 'right' }}
                                            value={i.amount ? i.amount : ''}
                                            onChange={(value: any) => {
                                              const updatedAmount = value.target.value;
                                              const updatedMonths = incomeByMonth.map((month) => {
                                                if (month.monthlyIncomeId === income.monthlyIncomeId) {
                                                  const updatedIncomes = month.incomes.map(
                                                    (incomeInMonth, indexToFind) => {
                                                      if (indexIncome === indexToFind) {
                                                        return {
                                                          incomeTypeId: incomeInMonth.incomeTypeId,
                                                          amount: formatCurrencyCommas(updatedAmount),
                                                        };
                                                      } else {
                                                        return incomeInMonth;
                                                      }
                                                    }
                                                  );
                                                  return {
                                                    monthlyIncomeId: month.monthlyIncomeId,
                                                    month: month.month,
                                                    year: month.year,
                                                    incomes: updatedIncomes,
                                                  } as IMonthlyIncome;
                                                } else {
                                                  return month;
                                                }
                                              });
                                              setIncomeByMonth(updatedMonths);
                                              setIsDirty(true);
                                            }}
                                            placeholder='Type here...'
                                            onBlur={(value: any) => {
                                              const updatedAmount = value.target.value;
                                              const updatedMonths = incomeByMonth.map((month) => {
                                                if (month.monthlyIncomeId === income.monthlyIncomeId) {
                                                  const updatedIncomes = month.incomes.map(
                                                    (incomeInMonth, indexToFind) => {
                                                      if (indexIncome === indexToFind) {
                                                        return {
                                                          incomeTypeId: incomeInMonth.incomeTypeId,
                                                          amount: formatCurrencyCommasTwoDigits(updatedAmount),
                                                        };
                                                      } else {
                                                        return incomeInMonth;
                                                      }
                                                    }
                                                  );
                                                  return {
                                                    monthlyIncomeId: month.monthlyIncomeId,
                                                    month: month.month,
                                                    year: month.year,
                                                    incomes: updatedIncomes,
                                                  } as IMonthlyIncome;
                                                } else {
                                                  return month;
                                                }
                                              });
                                              setIncomeByMonth(updatedMonths);
                                              setIsDirty(true);
                                            }}
                                            disabled={!isEditingAllowed}
                                          />
                                        </Col>
                                        {isEditingAllowed && (
                                          <Col className={'third-party-x-button'}>
                                            <button
                                              className='close pt-1'
                                              onClick={() => {
                                                setIsDirty(true);
                                                setIncomeByMonth(
                                                  incomeByMonth
                                                    .map((m) => {
                                                      if (m.monthlyIncomeId === income.monthlyIncomeId) {
                                                        return {
                                                          monthlyIncomeId: m.monthlyIncomeId,
                                                          month: m.month,
                                                          year: m.year,
                                                          incomes: [
                                                            ...m.incomes.slice(0, indexIncome),
                                                            ...m.incomes.slice(indexIncome + 1),
                                                          ],
                                                        } as IMonthlyIncome;
                                                      } else {
                                                        return m;
                                                      }
                                                    })
                                                    .filter((m) => m.incomes.length > 0)
                                                );
                                              }}
                                            >
                                              &times;
                                            </button>
                                          </Col>
                                        )}
                                      </Row>
                                    </ListGroupItem>
                                  ))}
                                  {income.incomes.length > 0 && (
                                    <ListGroupItem>
                                      <Row>
                                        <Col
                                          sm={5}
                                          md={5}
                                          lg={5}
                                          className={'pl-4 pt-2'}
                                          style={{ textAlign: 'right' }}
                                        >
                                          <span>Monthly Total ($)</span>
                                        </Col>
                                        <Col sm={5} md={5} lg={5}>
                                          <Input
                                            style={{ textAlign: 'right' }}
                                            value={
                                              totalMonthlyIncome(income.incomes, true)
                                                ? totalMonthlyIncome(income.incomes, true)
                                                : ''
                                            }
                                            type={'text'}
                                            name={'MonthlyIncome'}
                                            disabled={true}
                                          />
                                        </Col>
                                        <Col className={'third-party-x-button'} />
                                      </Row>
                                    </ListGroupItem>
                                  )}
                                </ListGroup>
                              </ListGroupItem>
                            </ListGroup>
                          </Row>
                        </div>
                      ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </form>
    </div>
  );
};
