import * as React from 'react';
import { Column, TableInstance, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { IRejectedLawyer } from '../Files/FileContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { formatDateTime } from '../../formatters/DateTimeFormatter';
import { DropdownMultiSelectCheckboxes } from '../DropdownMultiSelectCheckboxes/DropdownMultiSelectCheckboxes';
import { IUser } from '../Users/UsersTable';
import { DateRangeDropdown } from '../DateRangeDropdown/DateRangeDropdown';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { TablePagination } from '../TablePagination/TablePagination';
import { authAxios } from '../../services/AxiosService';
import { PeopleContext } from '../../contexts/PeopleContext';
import { useHistory } from 'react-router-dom';

interface IRejectionHistory {
  fileId: string;
  fileNumber: string;
  lawyerFormatted: string;
  lawyerId: string;
  reason: string;
  dateRejected: number;
}

interface IProps {
  selectRow?: (userId: string) => void;
  individualId: string;
}

export const IndividualRejectionHistory: React.FC<IProps> = (props) => {
  const [rejectedLawyersInfo, setRejectedLawyersInfo] = useState<IRejectionHistory[]>([]);
  const peopleContext = useContext(PeopleContext);
  const lawyerList = peopleContext.lawyers;
  const history = useHistory();

  useEffect(() => {
    if (props.individualId) {
      authAxios.get('/api/files/' + props.individualId + '/client-files').then((response) => {
        setRejectedLawyersInfo((s: IRejectionHistory[]) => {
          const result: IRejectionHistory[] = [];
          response.data.forEach((e: any) => {
            const rejectedLawyers = e.file.rejectedLawyers ? e.file.rejectedLawyers : [];

            rejectedLawyers.forEach((rl: IRejectedLawyer) => {
              const lawyer = lawyerList.find((l: IUser) => l.userId === rl.lawyerId);
              const lawyerFormatted = lawyer ? `${lawyer?.lastName.toUpperCase()}, ${lawyer?.firstName}` : '';
              result.push({
                fileId: e.file.fileId,
                fileNumber: e.file.fileNumber,
                lawyerFormatted: lawyerFormatted,
                lawyerId: rl.lawyerId,
                reason: rl.reason,
                dateRejected: rl.dateRejected,
              });
            });
          });
          return result;
        });
      });
    }
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: Column<IRejectionHistory>[] = useMemo(
    () => [
      {
        Header: 'File Number',
        accessor: 'fileNumber',
        width: 30,
      },
      {
        Header: 'Lawyer',
        accessor: 'lawyerFormatted',
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = [], preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='lawyerId'
              style={{ minWidth: 200 }}
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={lawyerList
                .filter((e) => e.active)
                .sort((a: IUser, b: IUser) => a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase()))
                .map((s: IUser) => ({
                  value: s.userId,
                  display: s.lastName.toUpperCase() + ', ' + s.firstName,
                }))
                .concat([{ value: '', display: 'Unassigned' }])}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['lawyerId'];
              if (rowValue === undefined) {
                return filterValue.includes('');
              } else {
                return filterValue.includes(rowValue);
              }
            }
          });
        },
      },
      {
        Header: 'Rejection Reason',
        accessor: 'reason',
      },
      {
        Header: 'Date Rejected',
        accessor: 'dateRejected',
        width: 75,
        Cell: (cell: any) => {
          if (cell && cell.row.original && cell.row.original.dateRejected) {
            return formatDateTime(new Date(cell.row.original.dateRejected)).replace('.', '');
          } else {
            return '';
          }
        },
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          if (rowA.original['dateRejected'] && rowB.original['dateRejected']) {
            if (rowA.original['dateRejected'] > rowB.original['dateRejected']) return 1;
            if (rowB.original['dateRejected'] > rowA.original['dateRejected']) return -1;
            return 0;
          } else if (rowA.original['dateRejected'] && !rowB.original['dateRejected']) {
            return 1;
          } else if (rowB.original['dateRejected'] && !rowA.original['dateRejected']) {
            return -1;
          } else {
            return 0;
          }
        },
        filter: (rows: any, id: any, filterValue: { startDate?: Date; endDate?: Date }) => {
          return rows.filter((row: any) => {
            if (!filterValue.startDate || !filterValue.endDate) {
              return true;
            } else {
              const rowValue = row.original['dateRejected'];
              return rowValue > filterValue.startDate.getTime() && rowValue < filterValue.endDate.getTime();
            }
          });
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => (
          <DateRangeDropdown
            startDate={filterValue?.startDate}
            endDate={filterValue?.endDate}
            setRange={(dates: { startDate?: Date; endDate?: Date }) => {
              setFilter(dates);
            }}
            keyPrefix={'dateRejected'}
          />
        ),
      },
    ],
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const data: IRejectionHistory[] = useMemo(() => {
    if (props.individualId) {
      return rejectedLawyersInfo;
    } else {
      return [];
    }
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectedLawyersInfo]);

  const selectRow = (fileId: string) => {
    if (props.selectRow) {
      props.selectRow(fileId);
    } else {
      history.push('/files/' + fileId);
    }
  };

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: TableInstance<IRejectionHistory> = useTable(
    {
      columns: columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        filters: [],
        sortBy: [{ id: 'dateRejected', desc: true }],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div>
      <div className={'table-responsive'} style={{ minHeight: '800px' }}>
        <table className={'table table-bordered table-hover'} {...getTableProps()}>
          <thead style={{ overflow: 'visible' }}>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`files-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={`files-table-thead-tr-${index}-${column.id}`}
                    width={column.width}
                    style={{ maxWidth: column.width }}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                    <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <tr key={`files-table-tr-${row.id}`} {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        key={`files-table-td-${cell.row.id}-${cell.column.id}`}
                        {...cell.getCellProps()}
                        onClick={() => selectRow(row.original.fileId)}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizes={[20, 50, 100, 500]}
        recordCount={rows.length}
      />
    </div>
  );
};
