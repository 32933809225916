import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Nav, Navbar, NavbarBrand, Row } from 'reactstrap';
import { FileContext, IAsset, IIncome, IMonthlyIncome } from './FileContext';
import * as DateTimeFormatter from '../../formatters/DateTimeFormatter';
import { DomainContext } from '../../contexts/DomainContext';
import { FaPrint } from 'react-icons/fa';
import { IndividualsContext } from '../../contexts/IndividualsContext';
import { authAxios } from '../../services/AxiosService';
import { IAddress, IIndividual } from '../Individuals/IndividualContext';
import { IPhoneNumber } from '../Individuals/IndividualDetails';
import { useParams } from 'react-router';

export const PrintApplication: React.FC = () => {
  const params = useParams<{ fileId: string }>();
  const fileContext = useContext(FileContext);
  const domainContext = useContext(DomainContext);
  const offences = domainContext.offences;
  const individualsContext = useContext(IndividualsContext);
  const individuals = individualsContext.state.individuals;
  const [addresses, setAddresses] = useState([] as IAddress[]);
  const [phoneNumbers, setPhoneNumbers] = useState([] as IPhoneNumber[]);
  const file = fileContext.file;
  const setFinancialDetails = fileContext.setFinancialDetails;

  const [applicant, setApplicant] = useState<IIndividual>({} as IIndividual);

  useEffect(() => {
    if (file.clientId) {
      authAxios.get('/api/individuals/' + file.clientId).then((response) => {
        if (response.data) {
          const allAddresses: IAddress[] = response.data.addresses;
          setAddresses(allAddresses.filter((a) => a.isCurrent));
          setPhoneNumbers(response.data.phoneNumbers || []);
          setApplicant(response.data);
        }
      });
    } else {
      setAddresses([]);
    }
  }, [file.clientId, setAddresses]);

  useEffect(() => {
    authAxios.get('/api/files/admin/' + params.fileId + '/financial-details').then((response) => {
      if (response.data) {
        setFinancialDetails({
          maritalStatusId: response.data.maritalStatus,
          livingArrangementId: response.data.livingArrangement,
          numberOfAdults: response.data.numberOfAdults,
          numberOfChildren: response.data.numberOfChildren,
          monthlyIncomes: formatMonthlyIncomes(sortMonths(response.data.monthlyIncomes)),
          assets:
            response.data.assets.length === 0
              ? [
                  {
                    name: '',
                    amount: undefined,
                  },
                ]
              : formatAssets(response.data.assets),
          averageMonthlyIncome: response.data.averageMonthlyIncome,
          averageYearlyIncome: response.data.averageYearlyIncome,
          maxMonthlyIncome: response.data.maxMonthlyIncome,
          maxYearlyIncome: response.data.maxYearlyIncome,
          percentOverage: response.data.percentOverage,
        });
      }
    });
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.fileId, setFinancialDetails]);

  const formatMonthlyIncomes = (monthlyIncomes: IMonthlyIncome[]) => {
    return monthlyIncomes.map((month) => {
      return {
        ...month,
        incomes: month.incomes.map((income) => {
          return {
            incomeTypeId: income.incomeTypeId,
            amount: formatCurrencyCommasTwoDigits(income.amount),
          } as IIncome;
        }),
      } as IMonthlyIncome;
    });
  };

  const formatAssets = (assetList: IAsset[]) => {
    return assetList.map((asset: IAsset) => {
      return { name: asset.name, amount: formatCurrencyCommasTwoDigits(asset.amount) } as IAsset;
    });
  };

  const formatCurrencyCommasTwoDigits = (amount: string | undefined) => {
    if (amount) {
      const floatToUse = parseFloat(amount.toString().replace(/[, ]+/g, ''));
      return Number.isNaN(floatToUse)
        ? '0.00'
        : floatToUse
            .toFixed(2)
            .toString()
            .replace(/-[^0-9.]/g, '')
            .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    } else {
      return '';
    }
  };

  const formattedAmountToNumber = (amount: string | undefined) => {
    if (amount) {
      if (amount.length > 0 && amount.charAt(0) === '-' && amount !== '-') {
        return -Math.abs(Number(amount.toString().replace(/[, ]+|[- ]+/g, '')));
      } else {
        return Number(amount.toString().replace(/[,]+|[- ]+/g, ''));
      }
    } else {
      return 0;
    }
  };

  const formatCurrencyCommas = (amount: string | undefined) => {
    if (amount) {
      return amount
        .toString()
        .replace(/[, ]+/g, '')
        .replace(/-[^0-9.]/g, '')
        .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    } else {
      return '';
    }
  };

  const calculatePercentOverage = () => {
    if (
      fileContext.financialDetails &&
      fileContext.financialDetails.averageMonthlyIncome &&
      fileContext.financialDetails.maxMonthlyIncome &&
      formattedAmountToNumber(fileContext.financialDetails.averageMonthlyIncome) >
        formattedAmountToNumber(fileContext.financialDetails.maxMonthlyIncome)
    ) {
      return formatCurrencyCommas(
        (
          ((formattedAmountToNumber(fileContext.financialDetails.averageMonthlyIncome) -
            formattedAmountToNumber(fileContext.financialDetails.maxMonthlyIncome)) /
            formattedAmountToNumber(fileContext.financialDetails.maxMonthlyIncome)) *
          100
        ).toFixed(0)
      );
    } else {
      return undefined;
    }
  };

  const monthList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const sortMonths = (monthlyIncome: IMonthlyIncome[]) => {
    return monthlyIncome.sort((a: IMonthlyIncome, b: IMonthlyIncome) => {
      if (a.year < b.year) {
        return 1;
      } else if (a.year > b.year) {
        return -1;
      } else {
        if (
          monthList.indexOf(a.month) !== -1 &&
          monthList.indexOf(b.month) !== -1 &&
          monthList.indexOf(a.month) < monthList.indexOf(b.month)
        ) {
          return 1;
        } else if (
          monthList.indexOf(a.month) !== -1 &&
          monthList.indexOf(b.month) !== -1 &&
          monthList.indexOf(a.month) > monthList.indexOf(b.month)
        ) {
          return -1;
        }
      }
      return 0;
    });
  };

  return (
    <div>
      <div className='d-print-none'>
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom'}>
          <NavbarBrand>{'Print Application'}</NavbarBrand>
          <Nav className={'ml-auto'}>
            <Button color='warning' type='submit' onClick={() => window.print()}>
              <FaPrint /> Print
            </Button>
          </Nav>
        </Navbar>
      </div>
      <Container fluid={true} className={'my-3 container--no-margin-print'}>
        <div className='report' style={{ padding: '15px' }}>
          <header className={'report-header'}>
            <hr className={'d-none d-print-block'} />
            <h2 className='text-center'>YUKON LEGAL SERVICES SOCIETY</h2>
            <h3 className='text-center h5 text-muted'>CLIENT APPLICATION</h3>
          </header>
          <Container fluid={true} style={{ borderTop: '1px solid #4f4f4f', borderLeft: '1px solid #4f4f4f' }}>
            <Row>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Date:</div>
                <div className={'print-application-col__value'}>
                  {DateTimeFormatter.formatDate(fileContext.appDate ? new Date(fileContext.appDate) : undefined)}
                </div>
              </Col>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Type:</div>
                <div className={'print-application-col__value'}>
                  {
                    domainContext.applicationTypes.find((e) => e.applicationTypeId === fileContext.applicationTypeId)
                      ?.name
                  }
                </div>
              </Col>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Application:</div>
                <div className={'print-application-col__value'}>{fileContext.file.fileNumber}</div>
              </Col>
            </Row>
            <Row>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Full Name:</div>
                <div className={'print-application-col__value'}>
                  {applicant?.lastName?.toUpperCase() + ', ' + applicant?.firstName}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Date of Birth:</div>
                {DateTimeFormatter.formatDate(applicant?.birthDate ? new Date(applicant.birthDate) : undefined, '-')}
              </Col>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Gender:</div>
                <div className={'print-application-col__value'}>
                  {domainContext.genders.find((g) => g.genderId === applicant?.genderId)
                    ? domainContext.genders.find((g) => g.genderId === applicant?.genderId)?.name
                    : '-'}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Visible Minority:</div>
                <div className={'print-application-col__value'}>{applicant?.visibleMinority ? 'Yes' : 'No'}</div>
              </Col>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Indigenous:</div>
                <div className={'print-application-col__value'}>{applicant?.indigenous ? 'Yes' : 'No'}</div>
              </Col>
            </Row>
            <Row>
              {addresses.length > 0 ? (
                addresses.map((a) => {
                  return (
                    <Col className={'print-application-col'} key={a.individualId}>
                      <div className={'print-application-col__label'}>{a.label} Address:</div>
                      <div className={'print-application-col__value'}>
                        {[a.line1, a.line2, a.city, a.territory, a.postalCode].filter((a) => a !== '').join(', ')}
                      </div>
                    </Col>
                  );
                })
              ) : (
                <Col className={'print-application-col'}>
                  <div className={'print-application-col__label'}>Address:</div>
                  <div className={'print-application-col__value'}>-</div>
                </Col>
              )}
            </Row>
            <Row>
              {phoneNumbers.length > 0 ? (
                phoneNumbers.map((p) => {
                  return (
                    <Col className={'print-application-col'} key={p.number}>
                      <div className={'print-application-col__label'}>{p.description} Phone Number:</div>
                      <div className={'print-application-col__value'}>
                        {p.number.replace(/\D/g, '').length > 6
                          ? '(' +
                            p.number.replace(/\D/g, '').substring(0, 3) +
                            ') ' +
                            p.number.replace(/\D/g, '').substring(3, 6) +
                            '-' +
                            p.number.replace(/\D/g, '').substring(6)
                          : p.number}
                      </div>
                    </Col>
                  );
                })
              ) : (
                <Col className={'print-application-col'}>
                  <div className={'print-application-col__label'}>Phone Number:</div>
                  <div className={'print-application-col__value'}>-</div>
                </Col>
              )}
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Email:</div>
                <div className={'print-application-col__value'}>{applicant?.email ? applicant?.email : '-'}</div>
              </Col>
            </Row>
            <Row>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Marital Status:</div>
                <div className={'print-application-col__value'}>
                  {domainContext.maritalStatuses.find(
                    (m) => m.maritalStatusId === fileContext.financialDetails.maritalStatusId
                  )
                    ? domainContext.maritalStatuses.find(
                        (m) => m.maritalStatusId === fileContext.financialDetails.maritalStatusId
                      )?.name
                    : '-'}
                </div>
              </Col>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Living Arrangement:</div>
                <div className={'print-application-col__value'}>
                  {domainContext.livingArrangements.find(
                    (l) => l.livingArrangementId === fileContext.financialDetails.livingArrangementId
                  )
                    ? domainContext.livingArrangements.find(
                        (l) => l.livingArrangementId === fileContext.financialDetails.livingArrangementId
                      )?.name
                    : '-'}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}># of Adults:</div>
                <div className={'print-application-col__value'}>
                  {fileContext.financialDetails.numberOfAdults ? fileContext.financialDetails.numberOfAdults : '-'}
                </div>
              </Col>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}># of Children:</div>
                <div className={'print-application-col__value'}>
                  {fileContext.financialDetails.numberOfChildren ? fileContext.financialDetails.numberOfChildren : '-'}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Matter/Charges:</div>
                <div className={'print-application-col__value'}>
                  {fileContext.charges
                    ? fileContext.charges
                        .map((c) => offences.find((o) => o.offenceId === c.offenceId)?.number)
                        .join(', ')
                    : '-'}
                </div>
              </Col>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Court File #:</div>
                <div className={'print-application-col__value'}>
                  {fileContext.courtFileNumber ? fileContext.courtFileNumber : '-'}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Third Parties:</div>
                <div className={'print-application-col__value'}>
                  {fileContext.thirdParties
                    .map((tp) => {
                      const i = individuals.find((i) => i.individualId === tp.individualId);
                      const relationship = domainContext.relationshipTypes.find(
                        (rt) => rt.relationshipTypeId === tp.relationshipTypeId
                      );
                      return i?.lastName.toUpperCase() + ', ' + i?.firstName + ' (' + relationship?.name + ')';
                    })
                    .join(', ')}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Children:</div>
                <div className={'print-application-col__value'}>
                  {fileContext.children && fileContext.children.length > 0
                    ? fileContext.children
                        .map(
                          (c) =>
                            c.name +
                            ' (' +
                            DateTimeFormatter.formatDate(c.birthDate ? new Date(c.birthDate) : undefined) +
                            ')'
                        )
                        .join(', ')
                    : '-'}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Next Appearance:</div>
                <div className={'print-application-col__value'}>
                  {fileContext.nextAppearanceDate
                    ? DateTimeFormatter.formatDateTime(new Date(fileContext.nextAppearanceDate))
                    : '-'}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Income Source(s):</div>
                <div className={'print-application-col__value'}>
                  {fileContext.financialDetails &&
                  fileContext.financialDetails.monthlyIncomes &&
                  fileContext.financialDetails.monthlyIncomes.length > 0 &&
                  fileContext.financialDetails.monthlyIncomes[0]?.incomes
                    ? fileContext.financialDetails.monthlyIncomes[0].incomes
                        .map((i) => {
                          return domainContext.incomeTypes.find((it) => it.incomeTypeId === i.incomeTypeId)?.name;
                        })
                        .join(', ')
                    : '-'}
                </div>
              </Col>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Monthly ($):</div>
                <div className={'print-application-col__value'}>
                  {fileContext.financialDetails && fileContext.financialDetails.averageMonthlyIncome
                    ? fileContext.financialDetails.averageMonthlyIncome
                    : '-'}
                </div>
              </Col>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Annual ($):</div>
                <div className={'print-application-col__value'}>
                  {fileContext.financialDetails && fileContext.financialDetails.averageYearlyIncome
                    ? fileContext.financialDetails.averageYearlyIncome
                    : '-'}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Eligibility Income:</div>
                <div className={'print-application-col__value'}>-</div>
              </Col>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Monthly ($):</div>
                <div className={'print-application-col__value'}>
                  {fileContext.financialDetails && fileContext.financialDetails.maxMonthlyIncome
                    ? fileContext.financialDetails.maxMonthlyIncome
                    : '-'}
                </div>
              </Col>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Annual ($):</div>
                <div className={'print-application-col__value'}>
                  {fileContext.financialDetails && fileContext.financialDetails.maxYearlyIncome
                    ? fileContext.financialDetails.maxYearlyIncome
                    : '-'}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Assets ($):</div>
                <div className={'print-application-col__value'}>
                  {fileContext.financialDetails &&
                  fileContext.financialDetails.assets &&
                  fileContext.financialDetails.assets.length > 0 &&
                  ((fileContext.financialDetails.assets.length === 1 &&
                    fileContext.financialDetails.assets[0]?.name !== '' &&
                    fileContext.financialDetails.assets[0]?.amount !== '' &&
                    fileContext.financialDetails.assets[0]?.amount !== undefined) ||
                    fileContext.financialDetails.assets.length > 1)
                    ? fileContext.financialDetails.assets.map((a) => a.name + ' (' + a.amount + ')').join(', ')
                    : '-'}
                </div>
              </Col>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>% Overage:</div>
                <div className={'print-application-col__value'}>
                  {fileContext.financialDetails.percentOverage
                    ? fileContext.financialDetails.percentOverage
                    : calculatePercentOverage()
                    ? calculatePercentOverage()
                    : '-'}
                </div>
              </Col>
            </Row>

            <Row>
              <Col className={'print-application-col'}>
                <p>Declaration of my duties and responsibilities to Yukon Legal Services Society (YLSS)</p>
                <p>
                  <ol>
                    <li>I am the applicant for Legal Aid, and I have duties and responsibilities to YLSS.</li>
                    <li>
                      It is a serious matter to give false information on this or any subsequent application for Legal
                      Aid services.
                    </li>
                    <li>
                      I understand that I must tell Legal Aid or my assigned lawyer of any changes in my financial
                      situation, including changes in my income, assets, residence, or family size within 7 days of any
                      change.
                    </li>
                    <li>
                      If I give false information now or in relation to any subsequent application or if I fail to
                      advise YLSS of any change to my financial situation, then I understand that YLSS can cancel my
                      certificate and/or take action to recover money paid or payable by YLSS on my behalf.
                    </li>
                    <li>
                      I understand that I may be required to contribute to the cost of Legal Aid provided to me and
                      repay YLSS for any monies expended on my behalf.
                    </li>
                    <li>
                      I must keep in regular contact with my assigned lawyer. Failure to do so may result in my legal
                      aid coverage being terminated.
                    </li>
                    <li>
                      I consent to the release of any information held in this application to my assigned counsel and to
                      those others as indicated.
                    </li>
                    <li>
                      Your coverage for Legal Aid will expire 6 months after the date of this application, and you may
                      be required to re-apply.
                    </li>
                  </ol>
                </p>
                <p>
                  SIGNED AND DECLARED at _______________________, this{' '}
                  {fileContext.appDate ? new Date(fileContext.appDate).getDate() : new Date().getDate()} day of{' '}
                  {fileContext.appDate
                    ? DateTimeFormatter.formatMonth(new Date(fileContext.appDate))
                    : DateTimeFormatter.formatMonth(new Date())}
                  , {fileContext.appDate ? new Date(fileContext.appDate).getFullYear() : new Date().getFullYear()}.
                </p>
              </Col>
            </Row>
            <Row>
              <Col className={'print-application-col'}>
                IN THE PRESENCE OF:
                <br />
                <br />
                <br />
                ________________________________
                <br />
              </Col>
              <Col className={'print-application-col'}>
                <br />
                <br />
                <br />
                ________________________________
                <br />
                Applicant
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </div>
  );
};
