import * as React from 'react';
import { Dispatch, SetStateAction, useContext } from 'react';
import { Card, CardBody, CardHeader, Col, CustomInput, FormGroup, Label, Row } from 'reactstrap';
import { FileContext } from './FileContext';
import { CurrencyInput } from '../CurrencyInput';

interface IProps {
  trySubmit: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  isEditingAllowed: boolean;
}

export const ContributionAgreement = (props: IProps) => {
  const fileContext = useContext(FileContext);

  return (
    <>
      <Card className={'mb-3'}>
        <CardHeader>
          Contribution Agreement
          {fileContext.file.approvalType &&
            fileContext.file.contributionAgreement &&
            ` - ${fileContext.file.approvalType} Approved`}
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <CustomInput
                type='checkbox'
                id={'contributionAgreement'}
                name={'contributionAgreement'}
                label={'Contribution Agreement'}
                disabled={!props.isEditingAllowed}
                checked={fileContext.file.contributionAgreement}
                onChange={() => {
                  fileContext.setFile((f) => ({
                    ...f,
                    contributionAgreement: !f.contributionAgreement,
                    contributionAgreementInitialPayment: !f.contributionAgreement
                      ? f.contributionAgreementInitialPayment
                      : undefined,
                    contributionAgreementMonthlyPayment: !f.contributionAgreement
                      ? f.contributionAgreementMonthlyPayment
                      : undefined,
                  }));
                  props.setIsDirty(true);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className={'mb-0'}>
                <Label>Initial Payment($)</Label>
                <CurrencyInput
                  name={'Contribution Initial Payment'}
                  disabled={!fileContext.file.contributionAgreement}
                  value={fileContext.file.contributionAgreementInitialPayment}
                  setValue={(e: number | undefined) => {
                    fileContext.setFile((f) => ({ ...f, contributionAgreementInitialPayment: e }));
                  }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className={'mb-0'}>
                <Label>Monthly Payment($)</Label>
                <CurrencyInput
                  value={fileContext.file.contributionAgreementMonthlyPayment}
                  disabled={!fileContext.file.contributionAgreement}
                  name={'Contribution Monthly Payment'}
                  setValue={(e: number | undefined) => {
                    fileContext.setFile((f) => ({ ...f, contributionAgreementMonthlyPayment: e }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};
