import { IIndividual } from '../components/Individuals/IndividualContext';
import { formatDate } from './DateTimeFormatter';
import * as React from 'react';
import { IOffice } from '../contexts/DomainContext';
import { IUser } from '../components/Users/UsersTable';

export const clientSelectValue = (individual: IIndividual) => {
  const alias = individual.aliases.length > 0 ? 'AKA ' + individual.aliases.join(', ') : undefined;
  return (
    <>
      <strong className={'d-block text-truncate'}>
        {individual.lastName.toUpperCase() + ', ' + individual.firstName}
      </strong>
      <div className={'text-muted small d-block text-truncate'}>
        {individual.birthDate && formatDate(new Date(individual.birthDate))}
        {individual.birthDate && alias && ` • `}
        {alias && `${alias}`}
      </div>
    </>
  );
};

export const clientSelectValuesValue = (name: String, aliases: String, birthDate: String) => {
  const alias = aliases ? 'AKA ' + aliases : undefined;
  return (
    <>
      <strong className={'d-block text-truncate'}>{name}</strong>
      <div style={{ maxWidth: 350 }} className={'text-muted small d-block text-truncate'}>
        {birthDate && birthDate}
        {birthDate && alias && ` • `}
        {alias && `${alias}`}
      </div>
    </>
  );
};

export const lawyerSelectValue = (lawyer: IUser, offices: IOffice[]) => {
  return (
    <>
      <strong className={'d-block text-truncate'}>{lawyer.lastName.toUpperCase() + ', ' + lawyer.firstName}</strong>
      <div className={'text-muted small d-block text-truncate'}>
        {offices.find((e) => e.officeId === lawyer.officeId)?.name}
      </div>
    </>
  );
};
