import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, Container, Nav, Navbar, NavbarBrand } from 'reactstrap';
import { FileContext, IFile } from './FileContext';
import { DomainContext } from '../../contexts/DomainContext';
import { FaPrint } from 'react-icons/fa';
import { authAxios } from '../../services/AxiosService';
import { IIndividual } from '../Individuals/IndividualContext';

import { printLabel } from '../../services/PrintLabel';

export const PrintIntakeLabel: React.FC = () => {
  const fileContext = useContext(FileContext);
  const domainContext = useContext(DomainContext);
  const offences = domainContext.offences;

  const file = fileContext.file;

  const [applicant, setApplicant] = useState<IIndividual>({} as IIndividual);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = '/DYMO.Label.Framework.latest.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (file.clientId) {
      authAxios.get('/api/individuals/' + file.clientId).then((response) => {
        if (response.data) {
          setApplicant(response.data);
        }
      });
    }
  }, [file.clientId]);

  const mostSerious = (file: IFile) => {
    const groups = fileContext.charges.map((c: any) => {
      const offence = offences.find((o) => o.offenceId === c.offenceId);
      if (offence && offence.group) {
        return offence.group;
      } else {
        return 6;
      }
    });
    let lowestGroup = 6;
    groups.forEach((g: any) => {
      if (g < lowestGroup) {
        lowestGroup = g;
      }
    });

    if (
      (file.applicationTypeId === '5ed8bcb6-87e3-42d6-9aea-3ffa3939391a' ||
        file.applicationTypeId === '2a4a2269-40a4-4718-9884-da2d51c0d5ab') &&
      lowestGroup < 6 &&
      lowestGroup >= 0
    ) {
      return fileContext.charges
        .map((c) => {
          const offence = offences.find((o) => o.offenceId === c.offenceId);
          if (offence && offence.group === lowestGroup) {
            return offence.number;
          } else {
            return undefined;
          }
        })
        .filter((e) => e)
        .join(', ');
    } else {
      return fileContext.charges
        .map((c) => {
          const offence = offences.find((o) => o.offenceId === c.offenceId);
          return offence?.number;
        })
        .filter((e) => e)
        .join(', ');
    }
  };

  return (
    <div>
      <div className='d-print-none'>
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom'}>
          <NavbarBrand>{'Print Label'}</NavbarBrand>
          <Nav className={'ml-auto'}>
            <Button
              color='warning'
              type='submit'
              onClick={() => {
                const name = applicant?.lastName?.toUpperCase() + ', ' + applicant?.firstName;
                const type = domainContext.applicationTypes.find(
                  (e) => e.applicationTypeId === file.applicationTypeId
                )?.name;
                const fileNumber = fileContext.file.fileNumber;
                const charges = mostSerious(file);
                printLabel(`${name}\n${type}\n${charges}\n${fileNumber}`);
              }}
            >
              <FaPrint /> Print
            </Button>
          </Nav>
        </Navbar>
      </div>
      <Container fluid={true} className={'my-3 container--no-margin-print'}>
        <div className='report' style={{ padding: '15px' }}>
          {applicant?.lastName?.toUpperCase() + ', ' + applicant?.firstName}
          <br />
          {domainContext.applicationTypes.find((e) => e.applicationTypeId === file.applicationTypeId)?.name}
          <br />
          {mostSerious(file)}
          <br />
          {fileContext.file.fileNumber}
        </div>
      </Container>
    </div>
  );
};
