import { useContext, useMemo } from 'react';
import { MOST_SERIOUS_GROUPS, IFile } from '../Files/FileContext';
import { clientSelectValuesValue } from '../../formatters/PeopleFormatter';
import { IOffence } from '../Offences/OffenceTable';
import {
  alwaysHiddenStagesIntake,
  defaultHiddenStagesIntake,
  DomainContext,
  IApplicationType,
  IFileType,
  IStage,
  STAGE_COUNSEL_REQUEST,
  STAGE_RETURN_FOR_CONFLICT,
} from '../../contexts/DomainContext';
import { DropdownMultiSelectCheckboxes } from '../DropdownMultiSelectCheckboxes/DropdownMultiSelectCheckboxes';
import * as React from 'react';
import { FilesTableContext } from '../Files/FilesTableContext';
import { Column } from 'react-table';
import { DateRangeDropdown } from '../DateRangeDropdown/DateRangeDropdown';
import { IUser } from '../Users/UsersTable';
import { PeopleContext } from '../../contexts/PeopleContext';
import { useMostSerious } from '../../hooks/useMostSerious';
import { DropdownGroupedMultiSelectCheckboxes } from '../DropdownMultiSelectCheckboxes/DropdownGroupedMultiSelectCheckboxes';

export const useIntakeColumns = (): Column<IFile>[] => {
  const domainContext = useContext(DomainContext);
  const offences = domainContext.offences;
  const stages = domainContext.stages;
  const applicationTypes = domainContext.applicationTypes;
  const fileTypes = domainContext.fileTypes;
  const searchContext = useContext(FilesTableContext);
  const peopleContext = useContext(PeopleContext);
  const lawyerList = peopleContext.lawyers;
  const staffLawyers = peopleContext.staffLawyers;
  const privateLawyers = peopleContext.privateLawyers;
  const mostSerious = useMostSerious();

  const lawyerGroupedOptions = [
    {
      label: 'Staff Lawyers',
      options: staffLawyers
        .filter((e) => e.active)
        .sort((a: IUser, b: IUser) => a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase()))
        .map((s: IUser) => ({
          value: s.userId,
          display: s.lastName.toUpperCase() + ', ' + s.firstName,
        })),
    },
    {
      label: 'Private Lawyers',
      options: privateLawyers
        .filter((e) => e.active)
        .sort((a: IUser, b: IUser) => a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase()))
        .map((s: IUser) => ({
          value: s.userId,
          display: s.lastName.toUpperCase() + ', ' + s.firstName,
        })),
    },
    { label: 'Unassigned', options: [{ value: '', display: 'Unassigned' }] },
  ];

  return useMemo(
    () => [
      {
        Header: 'File #',
        accessor: 'fileNumber',
        Cell: (cell: any) => {
          return cell.row.original.fileNumber;
        },
        width: 30,
      },
      {
        Header: 'Client',
        id: 'clientId',
        accessor: (file: IFile) => {
          return `${file.clientName} ${file.clientAliases} ${file.clientDateOfBirth}`;
        },
        Cell: (cell: any) => {
          if (cell && cell.row.original && cell.row.original.clientId) {
            return clientSelectValuesValue(
              cell.row.original.clientName,
              cell.row.original.clientAliases,
              cell.row.original.clientDateOfBirth
            );
          } else {
            return <span />;
          }
        },
      },
      {
        Header: 'Stage',
        id: 'stageId',
        accessor: (file: IFile) => {
          return stages.find((s: IStage) => s.stageId === file.stageId)?.name;
        },
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          const rowAStage = stages.find((s: IStage) => s.stageId === rowA.original[id]);
          const rowBStage = stages.find((s: IStage) => s.stageId === rowB.original[id]);

          if (rowAStage && rowBStage) {
            if (
              (rowAStage.stageId === STAGE_COUNSEL_REQUEST || rowAStage.stageId === STAGE_RETURN_FOR_CONFLICT) &&
              rowBStage.stageId !== STAGE_COUNSEL_REQUEST &&
              rowBStage.stageId !== STAGE_RETURN_FOR_CONFLICT
            ) {
              return -1;
            } else if (
              (rowBStage.stageId === STAGE_COUNSEL_REQUEST || rowBStage.stageId === STAGE_RETURN_FOR_CONFLICT) &&
              rowAStage.stageId !== STAGE_COUNSEL_REQUEST &&
              rowAStage.stageId !== STAGE_RETURN_FOR_CONFLICT
            ) {
              return 1;
            } else if (
              (rowAStage.stageId === STAGE_COUNSEL_REQUEST || rowAStage.stageId === STAGE_RETURN_FOR_CONFLICT) &&
              (rowBStage.stageId === STAGE_COUNSEL_REQUEST || rowBStage.stageId === STAGE_RETURN_FOR_CONFLICT)
            ) {
              if (rowAStage.name > rowBStage.name) {
                return 1;
              } else if (rowAStage.name < rowBStage.name) {
                return -1;
              } else {
                return 0;
              }
            } else if (
              rowBStage.stageId !== STAGE_COUNSEL_REQUEST &&
              rowBStage.stageId !== STAGE_RETURN_FOR_CONFLICT &&
              rowAStage.stageId !== STAGE_COUNSEL_REQUEST &&
              rowAStage.stageId !== STAGE_RETURN_FOR_CONFLICT
            ) {
              if (rowAStage.order > rowBStage.order) return 1;
              if (rowBStage.order > rowAStage.order) return -1;
              if (rowAStage.order === rowBStage.order) {
                if (rowAStage.name > rowBStage.name) {
                  return 1;
                } else if (rowAStage.name < rowBStage.name) {
                  return -1;
                } else {
                  return 0;
                }
              }
            }
          }
          return 0;
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue || []}
              keyPrefix='stageId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={stages
                .filter((s) =>
                  searchContext.includeClosed
                    ? !alwaysHiddenStagesIntake.includes(s.stageId)
                    : !defaultHiddenStagesIntake.includes(s.stageId)
                )
                .map((s: IStage) => ({
                  value: s.stageId,
                  display: s.name,
                }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (!filterValue || filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['stageId'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
      },
      {
        Header: 'Lawyer',
        accessor: 'lawyerFormatted',
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = [], preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownGroupedMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='lawyerId'
              style={{ minWidth: 200 }}
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={lawyerGroupedOptions}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['lawyerId'];
              if (rowValue === undefined) {
                return filterValue.includes('');
              } else {
                return filterValue.includes(rowValue);
              }
            }
          });
        },
      },
      {
        Header: 'App Type',
        id: 'applicationTypeId',
        width: 40,
        accessor: (file: IFile) => {
          return applicationTypes.find(
            (applicationType: IApplicationType) => applicationType.applicationTypeId === file.applicationTypeId
          )?.name;
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = [], preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='officeId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={applicationTypes.map((s: IApplicationType) => ({
                value: s.applicationTypeId,
                display: s.name,
              }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['applicationTypeId'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
      },
      {
        Header: 'File Type',
        id: 'fileTypeId',
        width: 40,
        accessor: (file: IFile) => {
          return fileTypes.find((fileType: IFileType) => fileType.fileTypeId === file.fileTypeId)?.name;
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = [], preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='officeId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={fileTypes.map((s: IFileType) => ({
                value: s.fileTypeId,
                display: s.name,
              }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['fileTypeId'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
      },
      {
        Header: 'Charges',
        id: 'charges',
        accessor: (f: IFile) => {
          const chargeList = f.charges
            ? f.charges.map((charge) => offences.find((o) => o.offenceId === charge.offenceId))
            : [];
          return chargeList
            .sort((a: IOffence | undefined, b: IOffence | undefined) => {
              if (a && b && a.group && b.group) {
                if (a.group > b.group) {
                  return 1;
                } else if (b.group > a.group) {
                  return -1;
                } else {
                  return 0;
                }
              }
              return 0;
            })
            .map((offence) => (offence ? offence?.number : ''))
            .join(', ');
        },
      },
      {
        Header: 'Most Serious',
        width: 50,
        accessor: 'mostSerious',
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = [], preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='charges'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={MOST_SERIOUS_GROUPS}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = mostSerious(row.original.applicationTypeId, row.original.charges).toString();
              return filterValue.includes(rowValue);
            }
          });
        },
      },
      {
        Header: 'App Date',
        accessor: 'appDateFormatted',
        width: 75,
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          if (rowA.original['appDate'] && rowB.original['appDate']) {
            if (rowA.original['appDate'] > rowB.original['appDate']) return 1;
            if (rowB.original['appDate'] > rowA.original['appDate']) return -1;
            return 0;
          } else if (rowA.original['appDate'] && !rowB.original['appDate']) {
            return 1;
          } else if (rowB.original['appDate'] && !rowA.original['appDate']) {
            return -1;
          } else {
            return 0;
          }
        },
        filter: (rows: any, id: any, filterValue: { startDate?: Date; endDate?: Date }) => {
          return rows.filter((row: any) => {
            if (!filterValue.startDate || !filterValue.endDate) {
              return true;
            } else {
              const rowValue = row.original['appDate'];
              return rowValue > filterValue.startDate.getTime() && rowValue < filterValue.endDate.getTime();
            }
          });
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => (
          <DateRangeDropdown
            startDate={filterValue?.startDate}
            endDate={filterValue?.endDate}
            setRange={(dates: { startDate?: Date; endDate?: Date }) => {
              setFilter(dates);
            }}
            keyPrefix={'applicationDate'}
          />
        ),
      },
      {
        Header: 'Expiry Date',
        accessor: 'expiryDateFormatted',
        width: 75,
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          if (rowA.original['expiryDateFilter'] && rowB.original['expiryDateFilter']) {
            if (rowA.original['expiryDateFilter'] > rowB.original['expiryDateFilter']) return 1;
            if (rowB.original['expiryDateFilter'] > rowA.original['expiryDateFilter']) return -1;
            return 0;
          } else if (rowA.original['expiryDateFilter'] && !rowB.original['expiryDateFilter']) {
            return 1;
          } else if (rowB.original['expiryDateFilter'] && !rowA.original['expiryDateFilter']) {
            return -1;
          } else {
            return 0;
          }
        },
        filter: (rows: any, id: any, filterValue: { startDate?: Date; endDate?: Date }) => {
          return rows.filter((row: any) => {
            if (!filterValue.startDate || !filterValue.endDate) {
              return true;
            } else {
              const rowValue = row.original['expiryDateFilter'];
              return rowValue > filterValue.startDate.getTime() && rowValue < filterValue.endDate.getTime();
            }
          });
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => (
          <DateRangeDropdown
            startDate={filterValue?.startDate}
            endDate={filterValue?.endDate}
            setRange={(dates: { startDate?: Date; endDate?: Date }) => {
              setFilter(dates);
            }}
            keyPrefix={'expiryDate'}
          />
        ),
      },
    ],
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offences, stages, applicationTypes, fileTypes, lawyerList, searchContext.includeClosed, mostSerious]
  );
};
