import * as React from 'react';
import {
  Button,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { IUser } from './UsersTable';
import {
  DomainContext,
  ITimeTrackingActivity,
  IOffice,
  TIME_TRACKING_ACTIVITY_INTERVENTION,
} from '../../contexts/DomainContext';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { PeopleContext } from '../../contexts/PeopleContext';
import { lawyerSelectValue } from '../../formatters/PeopleFormatter';
import Select, { components, OptionProps, SingleValueProps } from 'react-select';
import { getUserId } from '../../services/AuthenticationService';
import { MyUserContext } from '../../contexts/MyUserContext';
import DatePicker from 'react-datepicker';
import { authAxios } from '../../services/AxiosService';
import { formatDateForServer } from '../../formatters/DateTimeFormatter';
import { notifyError, notifySuccess } from '../../services/ToastService';
import { RequiredLabel } from '../RequiredLabel';

interface IProps {
  openTimeRecordedPopup: boolean;
  setOpenTimeRecordedPopup: Dispatch<SetStateAction<boolean>>;
  newHoursAdded?: (
    hoursRecordId: string,
    lawyerUserId: string,
    timeTrackingActivityId: string,
    date: number,
    hours: number,
    details?: string
  ) => void;
  updateExistingEntry?: (
    hoursRecordId: string,
    lawyerUserId: string,
    timeTrackingActivityId: string,
    date: number,
    hours: number,
    fileId?: string,
    details?: string,
    fileNumber?: string,
    clientName?: string
  ) => void;
  deleteExistingEntry?: (hoursRecordId: string, lawyerUserId: string) => void;
  entryToEdit?: any;
  setEntryToEdit?: Dispatch<SetStateAction<any>>;
}

export const RecordNonFileTime = (props: IProps) => {
  const peopleContext = useContext(PeopleContext);
  const domainContext = useContext(DomainContext);
  const myUserContext = useContext(MyUserContext);
  const offices = domainContext.offices;
  let timeTrackingActivities = myUserContext.isSupportWorker
    ? domainContext.timeTrackingActivities.filter(
        (t) => t.userRole === 'SupportWorker' && t.activityType !== 'Intervention'
      )
    : props.entryToEdit
    ? domainContext.timeTrackingActivities
    : domainContext.timeTrackingActivities.filter((t) => t.activityType === 'Generic');
  timeTrackingActivities =
    myUserContext.isSuperAdmin || myUserContext.isLegalAssistant || myUserContext.isLawyer
      ? timeTrackingActivities.filter((tta) => tta.userRole === 'Lawyer')
      : timeTrackingActivities;
  const [hours, setHours] = useState<number | undefined>(props.entryToEdit ? props.entryToEdit.hours : undefined);
  const dateToUse = props.entryToEdit ? new Date(props.entryToEdit.date) : new Date();
  dateToUse.setHours(12, 0, 0);
  const [date, setDate] = useState<Date | undefined>(dateToUse);
  const [userToAddTimeTo, setUserToAddTimeTo] = useState<string | undefined>(
    props.entryToEdit
      ? props.entryToEdit.userId
      : myUserContext.isLawyer || myUserContext.isSupportWorker
      ? getUserId()
      : undefined
  );
  const [timeTrackingActivityId, setTimeTrackingActivityId] = useState<string | undefined>(
    props.entryToEdit ? props.entryToEdit.timeTrackingActivityId : undefined
  );
  const [details, setDetails] = useState<string | undefined>(props.entryToEdit ? props.entryToEdit.details : undefined);
  const [tryAddLawyerTime, setTryAddLawyerTime] = useState(false);
  const [fieldsFilled, setFieldsFilled] = useState(false);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);

  useEffect(() => {
    if (
      userToAddTimeTo &&
      timeTrackingActivityId &&
      date &&
      hours &&
      (timeTrackingActivities.find((lt) => lt.timeTrackingActivityId === timeTrackingActivityId)?.requiresDescription
        ? details
        : true)
    ) {
      setFieldsFilled(true);
    } else {
      setFieldsFilled(false);
    }
  }, [userToAddTimeTo, timeTrackingActivityId, date, hours, details, timeTrackingActivities]);

  const recordHours = () => {
    if (fieldsFilled) {
      authAxios
        .post('/api/users/admin/record-non-file-hours', {
          userId: userToAddTimeTo,
          date: formatDateForServer(date),
          hours,
          timeTrackingActivityId: timeTrackingActivityId,
          details: details,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            notifySuccess('Hours updated.');
            if (props.newHoursAdded !== undefined) {
              props.newHoursAdded(
                response.data.hoursRecordId,
                userToAddTimeTo ? userToAddTimeTo : '',
                timeTrackingActivityId ? timeTrackingActivityId : '',
                date ? date.getTime() : 0,
                hours ? hours : 0,
                details
              );
            }
            props.setOpenTimeRecordedPopup(!props.openTimeRecordedPopup);
            setUserToAddTimeTo(undefined);
            setTimeTrackingActivityId(undefined);
            setDate(undefined);
            setTryAddLawyerTime(false);
            setHours(undefined);
          } else {
            notifyError(response.data.errorMessage);
          }
        });
    } else {
      setTryAddLawyerTime(true);
    }
  };

  const updateHours = () => {
    if (fieldsFilled && props.entryToEdit) {
      if (props.entryToEdit.fileId) {
        authAxios
          .post('/api/files/admin/update-hour-record', {
            fileId: props.entryToEdit.fileId,
            hoursRecordId: props.entryToEdit.hoursRecordId,
            userId: userToAddTimeTo,
            date: formatDateForServer(date),
            hours,
            timeTrackingActivityId: timeTrackingActivityId,
          })
          .then((response) => {
            if (response.data.status === 'OK') {
              notifySuccess('Hours updated.');
              if (props.updateExistingEntry !== undefined && props.entryToEdit) {
                props.updateExistingEntry(
                  props.entryToEdit.hoursRecordId,
                  userToAddTimeTo ? userToAddTimeTo : '',
                  timeTrackingActivityId ? timeTrackingActivityId : '',
                  date ? date.getTime() : 0,
                  hours ? hours : 0,
                  props.entryToEdit.fileId,
                  props.entryToEdit.details,
                  props.entryToEdit.fileNumber,
                  props.entryToEdit.clientName
                );
              }
              props.setOpenTimeRecordedPopup(!props.openTimeRecordedPopup);
              setUserToAddTimeTo(undefined);
              setTimeTrackingActivityId(undefined);
              setDate(undefined);
              setTryAddLawyerTime(false);
              setHours(undefined);
            } else {
              notifyError(response.data.errorMessage);
            }
          });
      } else if (
        props.entryToEdit.timeTrackingActivityId === TIME_TRACKING_ACTIVITY_INTERVENTION &&
        props.entryToEdit.supportWorkerRequestId &&
        props.entryToEdit.interventionId
      ) {
        authAxios
          .post('/api/support-worker-requests/admin/update-intervention-hours', {
            supportWorkerRequestId: props.entryToEdit.supportWorkerRequestId,
            interventionId: props.entryToEdit.interventionId,
            hoursRecordId: props.entryToEdit.hoursRecordId,
            userId: userToAddTimeTo,
            date: formatDateForServer(date),
            hours: hours,
            timeTrackingActivityId: TIME_TRACKING_ACTIVITY_INTERVENTION,
            details: details,
          })
          .then((response) => {
            if (response.data.status === 'OK') {
              notifySuccess('Hours updated.');
              if (props.updateExistingEntry !== undefined && props.entryToEdit) {
                props.updateExistingEntry(
                  props.entryToEdit.hoursRecordId,
                  userToAddTimeTo ? userToAddTimeTo : '',
                  timeTrackingActivityId ? timeTrackingActivityId : '',
                  date ? date.getTime() : 0,
                  hours ? hours : 0,
                  props.entryToEdit.fileId,
                  details
                );
              }
              props.setOpenTimeRecordedPopup(!props.openTimeRecordedPopup);
              setUserToAddTimeTo(undefined);
              setTimeTrackingActivityId(undefined);
              setDate(undefined);
              setTryAddLawyerTime(false);
              setHours(undefined);
              setDetails(undefined);
            } else {
              notifyError(response.data.errorMessage);
            }
          });
      } else {
        authAxios
          .post('/api/users/admin/update-non-file-hours', {
            hoursRecordId: props.entryToEdit.hoursRecordId,
            userId: userToAddTimeTo,
            date: formatDateForServer(date),
            hours,
            timeTrackingActivityId: timeTrackingActivityId,
            details: details,
          })
          .then((response) => {
            if (response.data.status === 'OK') {
              notifySuccess('Hours updated.');
              if (props.updateExistingEntry !== undefined && props.entryToEdit) {
                props.updateExistingEntry(
                  props.entryToEdit.hoursRecordId,
                  userToAddTimeTo ? userToAddTimeTo : '',
                  timeTrackingActivityId ? timeTrackingActivityId : '',
                  date ? date.getTime() : 0,
                  hours ? hours : 0,
                  props.entryToEdit.fileId,
                  details
                );
              }
              props.setOpenTimeRecordedPopup(!props.openTimeRecordedPopup);
              setUserToAddTimeTo(undefined);
              setTimeTrackingActivityId(undefined);
              setDate(undefined);
              setTryAddLawyerTime(false);
              setHours(undefined);
              setDetails(undefined);
            } else {
              notifyError(response.data.errorMessage);
            }
          });
      }
    } else {
      setTryAddLawyerTime(true);
    }
  };

  const deleteHours = () => {
    if (fieldsFilled && props.entryToEdit) {
      if (props.entryToEdit.fileId) {
        authAxios
          .post('/api/files/admin/delete-hour-record', {
            fileId: props.entryToEdit.fileId,
            hoursRecordId: props.entryToEdit.hoursRecordId,
          })
          .then((response) => {
            if (response.data.status === 'OK') {
              notifySuccess('Hours deleted.');
              if (props.deleteExistingEntry !== undefined && props.entryToEdit) {
                props.deleteExistingEntry(props.entryToEdit.hoursRecordId, userToAddTimeTo ? userToAddTimeTo : '');
              }
              if (props.setEntryToEdit) {
                props.setEntryToEdit(undefined);
              }
              setTryAddLawyerTime(true);
              props.setOpenTimeRecordedPopup(!props.openTimeRecordedPopup);
              setUserToAddTimeTo(undefined);
              setTimeTrackingActivityId(undefined);
              setDate(undefined);
              setTryAddLawyerTime(false);
              setHours(undefined);
            } else {
              notifyError(response.data.errorMessage);
            }
          });
      } else if (
        props.entryToEdit.timeTrackingActivityId === TIME_TRACKING_ACTIVITY_INTERVENTION &&
        props.entryToEdit.supportWorkerRequestId &&
        props.entryToEdit.interventionId
      ) {
        authAxios
          .post('/api/support-worker-requests/admin/delete-intervention-hours', {
            supportWorkerRequestId: props.entryToEdit.supportWorkerRequestId,
            interventionId: props.entryToEdit.interventionId,
            hoursRecordId: props.entryToEdit.hoursRecordId,
          })
          .then((response) => {
            if (response.data.status === 'OK') {
              notifySuccess('Hours deleted.');
              if (props.deleteExistingEntry !== undefined && props.entryToEdit) {
                props.deleteExistingEntry(props.entryToEdit.hoursRecordId, userToAddTimeTo ? userToAddTimeTo : '');
              }
              if (props.setEntryToEdit) {
                props.setEntryToEdit(undefined);
              }
              setTryAddLawyerTime(true);
              props.setOpenTimeRecordedPopup(!props.openTimeRecordedPopup);
              setUserToAddTimeTo(undefined);
              setTimeTrackingActivityId(undefined);
              setDate(undefined);
              setTryAddLawyerTime(false);
              setHours(undefined);
              setDetails(undefined);
            } else {
              notifyError(response.data.errorMessage);
            }
          });
      } else {
        authAxios
          .post('/api/users/admin/delete-non-file-hours', {
            hoursRecordId: props.entryToEdit.hoursRecordId,
            userId: userToAddTimeTo,
          })
          .then((response) => {
            if (response.data.status === 'OK') {
              notifySuccess('Hours deleted.');
              if (props.deleteExistingEntry !== undefined && props.entryToEdit) {
                props.deleteExistingEntry(props.entryToEdit.hoursRecordId, userToAddTimeTo ? userToAddTimeTo : '');
              }
              if (props.setEntryToEdit) {
                props.setEntryToEdit(undefined);
              }
              setTryAddLawyerTime(true);
              props.setOpenTimeRecordedPopup(!props.openTimeRecordedPopup);
              setUserToAddTimeTo(undefined);
              setTimeTrackingActivityId(undefined);
              setDate(undefined);
              setTryAddLawyerTime(false);
              setHours(undefined);
              setDetails(undefined);
            } else {
              notifyError(response.data.errorMessage);
            }
          });
      }
    } else {
      setTryAddLawyerTime(true);
    }
  };

  const LawyerOption = (props: OptionProps<IUser, boolean>) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <div>{lawyerSelectValue(data, domainContext.offices)}</div>
      </components.Option>
    );
  };

  const LawyerSingleValue = (props: SingleValueProps<IUser>) => {
    const { data } = props;
    return <components.SingleValue {...props}>{lawyerSelectValue(data, domainContext.offices)}</components.SingleValue>;
  };

  return (
    <div>
      <Row>
        <Col xs={12}>
          {(myUserContext.isLegalAssistant || myUserContext.isSuperAdmin) && (
            <FormGroup>
              <Label for={'lawyerId'}>Lawyer</Label>
              <Select
                classNamePrefix={'people-select'}
                id={'lawyerId'}
                name={'lawyerId'}
                options={
                  peopleContext.lawyers.sort((a: IUser, b: IUser) =>
                    a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase())
                  ) as IUser[]
                }
                value={peopleContext.lawyers.find((i: IUser) => i.userId === userToAddTimeTo)}
                onChange={(value: any) => setUserToAddTimeTo(value?.userId || '')}
                getOptionValue={(option: IUser) => option.userId}
                getOptionLabel={(option: IUser) =>
                  option.lastName.toUpperCase() +
                  ', ' +
                  option.firstName +
                  ' (' +
                  offices.find((o: IOffice) => o.officeId === option.officeId)?.name +
                  ')'
                }
                components={{ SingleValue: LawyerSingleValue, Option: LawyerOption }}
                styles={{
                  singleValue: (base) => ({
                    ...base,
                    position: 'relative',
                    top: 0,
                    transform: 'translateY(0)',
                    height: '100%',
                    padding: '0.25em 0',
                  }),
                }}
                isClearable={true}
                menuPlacement={'auto'}
                isDisabled={myUserContext.isLawyer || props.entryToEdit !== undefined}
              />
              {tryAddLawyerTime && !userToAddTimeTo && <FormText color={'danger'}>This field is mandatory</FormText>}
            </FormGroup>
          )}
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Label for={'timeTrackingActivityId'}>Activity</Label>
            <Select
              id={'timeTrackingActivityId'}
              name={'timeTrackingActivityId'}
              options={timeTrackingActivities}
              value={domainContext.timeTrackingActivities.find(
                (lt) => lt.timeTrackingActivityId === timeTrackingActivityId
              )}
              onChange={(value: any) => {
                if (
                  timeTrackingActivities.find((lt) => lt.timeTrackingActivityId === timeTrackingActivityId)
                    ?.requiresDescription &&
                  !value?.requiresDescription
                ) {
                  setDetails(undefined);
                }
                setTimeTrackingActivityId(value?.timeTrackingActivityId || '');
              }}
              getOptionValue={(option: ITimeTrackingActivity) => option.timeTrackingActivityId}
              getOptionLabel={(option: ITimeTrackingActivity) => option.name}
              isClearable={true}
              menuPlacement={'auto'}
              isDisabled={timeTrackingActivityId === TIME_TRACKING_ACTIVITY_INTERVENTION}
            />
            {tryAddLawyerTime && !timeTrackingActivityId && (
              <FormText color={'danger'}>This field is mandatory</FormText>
            )}
          </FormGroup>
        </Col>
        <Col sm={3}>
          <FormGroup>
            <Label>Date</Label>
            <DatePicker
              selected={date}
              onChange={(date) => {
                if (date) {
                  date.setHours(12, 0, 0, 0);
                  setDate(date);
                }
              }}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dateFormat='MMM dd, yyyy'
              dropdownMode='select'
              className='date-picker rounded mb-0'
              placeholderText={'Select...'}
            />
            {tryAddLawyerTime && date === undefined && <FormText color={'danger'}>This field is mandatory</FormText>}
          </FormGroup>
        </Col>
        <Col sm={3}>
          <FormGroup>
            <Label>Hours</Label>
            <Input
              type='number'
              name='userHours'
              id='exampleNumber'
              value={hours ? hours : ''}
              onChange={(e: any) => {
                const val = e.target.value;
                setHours(val ? Number(parseFloat(val).toFixed(2)) : 0);
              }}
            />
            {tryAddLawyerTime && !hours && <FormText color={'danger'}>This field is mandatory</FormText>}
          </FormGroup>
        </Col>
        {timeTrackingActivities.find((lt) => lt.timeTrackingActivityId === timeTrackingActivityId)
          ?.requiresDescription && (
          <Col xs={12}>
            <FormGroup>
              <RequiredLabel required={details === undefined} for={'details'}>
                Details
              </RequiredLabel>
              <Input
                value={details}
                type={'textarea'}
                aria-rowcount={2}
                placeholder={'Add text here...'}
                maxLength={200}
                name={'details'}
                id={'details'}
                style={{ minHeight: '65px', height: '65px' }}
                onChange={(e: any) => {
                  const value = e.target.value;
                  setDetails(value);
                }}
              />
              {tryAddLawyerTime && !details && <FormText color={'danger'}>This field is mandatory</FormText>}
            </FormGroup>
          </Col>
        )}
      </Row>
      <Row className={'my-3 d-flex px-3'}>
        {props.entryToEdit && (
          <Button
            className={'ml-2'}
            color='danger'
            onClick={() => {
              if (fieldsFilled) {
                setOpenDeleteConfirmationModal(true);
              }
            }}
          >
            Delete Time Entry
          </Button>
        )}
        <Button
          color='light'
          className={'ml-2'}
          onClick={() => {
            if (props.setEntryToEdit) {
              props.setEntryToEdit(undefined);
            }
            props.setOpenTimeRecordedPopup(!props.openTimeRecordedPopup);
          }}
        >
          Cancel
        </Button>
        {props.entryToEdit ? (
          <Button
            className={'ml-2'}
            color='primary'
            onClick={() => {
              if (props.setEntryToEdit) {
                props.setEntryToEdit(undefined);
              }
              setTryAddLawyerTime(true);
              if (fieldsFilled) {
                updateHours();
                //reset fields
              }
            }}
          >
            Update
          </Button>
        ) : (
          <Button
            className={'ml-2'}
            color='success'
            onClick={() => {
              if (props.setEntryToEdit) {
                props.setEntryToEdit(undefined);
              }
              setTryAddLawyerTime(true);
              if (fieldsFilled) {
                recordHours();
                //reset fields
              }
            }}
          >
            Add Time
          </Button>
        )}
      </Row>
      <Modal
        isOpen={openDeleteConfirmationModal}
        toggle={() => {
          setOpenDeleteConfirmationModal(!openDeleteConfirmationModal);
        }}
        className='logout-modal'
        style={{ width: '60rem', maxWidth: '60rem' }}
      >
        <ModalHeader>Delete Confirmation</ModalHeader>
        <ModalBody>Are you sure you want to delete this time entry?</ModalBody>
        <ModalFooter>
          <Button color='danger' onClick={() => deleteHours()}>
            Delete
          </Button>
          <Button color='light' onClick={() => setOpenDeleteConfirmationModal(!openDeleteConfirmationModal)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
