import * as React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import NavigationPrompt from 'react-router-navigation-prompt';
import { FileContext } from '../Files/FileContext';
import { useContext } from 'react';

interface IProps {
  isDirty: boolean;
}

export const LeavePageConfirm: React.FC<IProps> = (props) => {
  const fileContext = useContext(FileContext);

  return (
    <NavigationPrompt when={props.isDirty}>
      {({ onCancel, onConfirm }) => {
        return (
          <Modal isOpen={true}>
            <ModalHeader>You have unsaved changes, are you sure you want to leave?</ModalHeader>
            <ModalBody>Your current changes will be discarded. You won&apos;t be able to undo this action.</ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                onClick={() => {
                  fileContext.setFileAndAttributes(fileContext.file);
                  onConfirm();
                }}
              >
                Yes, Leave Page
              </Button>
              <Button color='light' onClick={onCancel}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </NavigationPrompt>
  );
};
