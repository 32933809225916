import * as React from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { FaCheck, FaCloudUploadAlt } from 'react-icons/fa';

export type OnFileDrop = <T extends File>(
  acceptedFiles: T[],
  fileRejections: FileRejection[],
  event: DropEvent
) => void;

interface IMyProps {
  isUploading: boolean;
  onDrop: OnFileDrop;
  uploadSuccessful: boolean;
}

export const FileUpload: React.FC<IMyProps> = (props) => {
  const { getRootProps, getInputProps, isDragAccept } = useDropzone({ onDrop: props.onDrop });

  const Icon = () => {
    // if (this.props.isUploading) {
    //     return <FaSpinner size={'4rem'} className={'dropzone-spinner p-2'} />
    // } else
    if (props.uploadSuccessful) {
      return <FaCheck size={'40px'} className={'dropzone-check rounded-circle'} />;
    } else {
      return <FaCloudUploadAlt size={'48px'} />;
    }
  };

  return (
    <div {...getRootProps({ className: `dropzone-container ${isDragAccept ? 'dropzone-container--accepted' : ''}` })}>
      <input {...getInputProps()} />
      <Icon />
      <strong className='drop-placeholder'>Drag document to&nbsp;upload</strong>
      <span>Or click to select</span>
    </div>
  );
};
