import * as React from 'react';
import { useMemo } from 'react';
import { Button, Navbar, NavbarBrand } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { FaChevronDown, FaChevronUp, FaPlus } from 'react-icons/fa';
import { Column, TableInstance, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { formatDateTime } from '../../formatters/DateTimeFormatter';
import { TablePagination } from '../TablePagination/TablePagination';
import { DomainContext, IApplicationType, IOffenceCategoryType } from '../../contexts/DomainContext';
import { useContext } from 'react';
import { OffencesContext } from './OffencesContext';
import { MyUserContext } from '../../contexts/MyUserContext';

export interface IOffence {
  offenceId: string;
  number: string;
  fileCount?: number;
  description?: string;
  applicationTypeId?: string;
  group?: number;
  dateCreated?: number;
  active: boolean;
  pleaTypeId?: string;
  outcomeTypeId?: string;
  categoryTypeId?: string;
}

export const OffenceTable: React.FC = () => {
  const domainContext = useContext(DomainContext);
  const offencesContext = useContext(OffencesContext);
  const myUserContext = useContext(MyUserContext);
  const offences = offencesContext.offences;
  const categoryTypes = offencesContext.categoryTypes;
  const applicationTypes = domainContext.applicationTypes;
  const history = useHistory();

  const columns: Column<IOffence>[] = useMemo(
    () => [
      {
        Header: 'Application Type',
        id: 'applicationTypeId',
        accessor: (file: IOffence) => {
          return applicationTypes.find(
            (applicationType: IApplicationType) => applicationType.applicationTypeId === file.applicationTypeId
          )?.name;
        },
      },
      {
        Header: 'Charge / Matter',
        accessor: 'number',
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          if (rowA.original['number'] && rowB.original['number']) {
            if (rowA.original['number'].toLowerCase() > rowB.original['number'].toLowerCase()) return 1;
            if (rowB.original['number'].toLowerCase() > rowA.original['number'].toLowerCase()) return -1;
            return 0;
          } else if (rowA.original['number'] && !rowB.original['number']) {
            return 1;
          } else if (rowB.original['number'] && !rowA.original['number']) {
            return -1;
          } else {
            return 0;
          }
        },
        Cell: (cell: any) => {
          if (cell && cell.row.original && !cell.row.original.active) {
            return cell.row.original.number + ' (Inactive)';
          } else {
            return cell.row.original.number;
          }
        },
      },
      {
        Header: 'Group',
        accessor: 'group',
      },
      {
        Header: '# of Times Used',
        accessor: 'fileCount',
      },
      {
        Header: 'Category',
        id: 'categoryTypeId',
        accessor: (category: IOffence) => {
          return categoryTypes.find((ct: IOffenceCategoryType) => ct.categoryTypeId === category.categoryTypeId)?.name;
        },
      },
      {
        Header: 'Date Created',
        accessor: 'dateCreated',
        Cell: (cell: any) => {
          if (cell && cell.row.original && cell.row.original.dateCreated) {
            return <span>{formatDateTime(new Date(cell.row.original.dateCreated)).replace('.', '')}</span>;
          } else {
            return <span />;
          }
        },
      },
    ],
    [applicationTypes, categoryTypes]
  );

  const data: IOffence[] = useMemo(() => offences, [offences]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,

    prepareRow,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: TableInstance<IOffence> = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        sortBy: [
          {
            id: 'number',
            desc: false,
          },
        ],
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const selectRow = (offenceId: string) => {
    history.push('/offences/' + offenceId);
  };

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'}>
        <NavbarBrand className='mr-auto'>Offences</NavbarBrand>
        {!myUserContext.isManagement && (
          <Button color={'success'} tag={Link} to={{ pathname: '/offences/add' }}>
            <FaPlus className='mr-2' />
            Add Offence
          </Button>
        )}
      </Navbar>
      <div className={'table-responsive'}>
        <table className={'table table-bordered table-hover'} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`offences-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={`offences-table-thead-tr-${index}-${column.id}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                    <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
            {page.map((row: any) => {
              prepareRow(row);

              return (
                <tr key={`offences-table-tr-${row.id}`} {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        key={`offences-table-td-${cell.row.id}-${cell.column.id}`}
                        {...cell.getCellProps()}
                        onClick={() => {
                          if (!myUserContext.isManagement) {
                            selectRow(row.original.offenceId);
                          }
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizes={[20, 50, 100, 500]}
      />
    </div>
  );
};
