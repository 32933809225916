import * as React from 'react';
import {
  Button,
  Col,
  Container,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { FormEvent, useEffect } from 'react';
import { authAxios } from '../../services/AxiosService';
import * as ToastService from '../../services/ToastService';
import { IOffice } from '../../contexts/DomainContext';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import * as FormHelpers from '../../services/FormHelpers';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';

export const OfficeDetails: React.FC = () => {
  const params = useParams<{ officeId: string }>();
  const history = useHistory();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [active, setActive] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  const [trySubmit, setTrySubmit] = useState(false);

  useEffect(() => {
    if (params.officeId) {
      authAxios.get('/api/offices/admin/' + params.officeId).then((response) => {
        if (response.data) {
          setName(response.data.name);
          setEmail(response.data.email);
          setPhone(response.data.phone);
          setActive(response.data.active);
        }
      });
    }
  }, [params.officeId]);

  const formFullyFilled = () => {
    return name !== '' && email !== '' && FormHelpers.isValidEmail(email);
  };

  const saveOfficeDetails = () => {
    if (!formFullyFilled()) {
      setTrySubmit(true);
      return;
    }
    if (params.officeId) {
      authAxios
        .post('/api/offices/admin/update', { officeId: params.officeId, name, email, phone, active } as IOffice)
        .then((response) => {
          if (response.data.status === 'OK') {
            ToastService.notifySuccess('Office Updated');
            setIsDirty(false);
          } else {
            ToastService.notifyError(response.data.errorMessage);
          }
        })
        .catch((error) => {
          ToastService.notifyError('Error:' + error);
        });
    } else {
      authAxios.post('/api/offices/admin/create', { name, email, phone, active } as IOffice).then((response) => {
        if (response.data.status === 'OK') {
          ToastService.notifySuccess('Office Created');
          setIsDirty(false);
          history.push('/offices/' + response.data.officeId);
        } else {
          ToastService.notifyError('Error Creating Office - ' + response.data.desc);
        }
      });
    }
  };

  return (
    <div>
      <LeavePageConfirm isDirty={isDirty} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <Navbar color={'light'} light={true} expand={'xs'}>
          <Row className={'flex-fill'}>
            <Col md={3} xl={2}>
              <NavbarBrand>{params.officeId ? 'Edit Office' : 'Add New Office'}</NavbarBrand>
            </Col>
            {params.officeId ? (
              <Col className={'d-flex'}>
                <Button color='success' className={'ml-auto'} onClick={() => saveOfficeDetails()}>
                  Save Details
                </Button>
              </Col>
            ) : (
              <Col className={'d-flex'}>
                <Button className={'ml-auto grey-button'} onClick={() => history.push('/offices')}>
                  Cancel
                </Button>
                <Button color='success' className={'ml-2'} onClick={() => saveOfficeDetails()}>
                  Save Details
                </Button>
              </Col>
            )}
          </Row>
        </Navbar>
        <Container fluid={true} className={'my-3'}>
          <Row>
            <Col md={{ size: 9, offset: 3 }} xl={{ size: 10, offset: 2 }}>
              <Row className='py-3'>
                <FormGroup className='col-sm-6'>
                  <Label>
                    Name <span className='text-required'>(required)</span>
                  </Label>
                  <Input
                    type='text'
                    placeholder=''
                    name='name'
                    value={name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setName(e.target.value);
                      setIsDirty(true);
                    }}
                    invalid={trySubmit && name === ''}
                  />
                  <FormFeedback>This field is mandatory</FormFeedback>
                </FormGroup>
              </Row>

              <Row>
                <FormGroup className='col-sm-6'>
                  <Label>Phone Number</Label>
                  <Input
                    type='text'
                    placeholder=''
                    name='phone'
                    value={phone}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setPhone(e.target.value);
                      setIsDirty(true);
                    }}
                  />
                </FormGroup>
              </Row>
              <Row>
                <FormGroup className='col-sm-6'>
                  <Label>
                    Email <span className='text-required'>(required)</span>
                  </Label>
                  <Input
                    type='text'
                    placeholder=''
                    name='email'
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmail(e.target.value);
                      setIsDirty(true);
                    }}
                    invalid={trySubmit && !FormHelpers.isValidEmail(email)}
                  />
                  <FormFeedback>
                    {!FormHelpers.isValidEmail(email) ? 'Invalid Email' : 'This field is mandatory'}
                  </FormFeedback>
                </FormGroup>
              </Row>
              <Row>
                <FormGroup className='col-sm-6'>
                  <CustomInput
                    type='checkbox'
                    id={'active'}
                    name={'active'}
                    label={'Active'}
                    checked={active}
                    onChange={() => {
                      setActive((s) => !s);
                      setIsDirty(true);
                    }}
                  />
                  <FormFeedback>This field is mandatory</FormFeedback>
                </FormGroup>
              </Row>
            </Col>
          </Row>
        </Container>
      </form>
    </div>
  );
};
