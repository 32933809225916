import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import { authAxios } from '../../services/AxiosService';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Column, TableInstance, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { DomainContext } from '../../contexts/DomainContext';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { TablePagination } from '../TablePagination/TablePagination';
import { PeopleContext } from '../../contexts/PeopleContext';
import { IUser } from '../Users/UsersTable';
import { DropdownMultiSelectCheckboxes } from '../DropdownMultiSelectCheckboxes/DropdownMultiSelectCheckboxes';

interface IProps {
  selectRow?: (userId: string) => void;
}

export interface IReportData {
  userId: string;
  userName: string;
  filesByType: Array<{ applicationTypeId: string; group: number; count: number }>;
}

export const WorkloadReport: React.FC<IProps> = (props) => {
  const [reportData, setReportData] = useState<IReportData[]>([]);
  const domainContext = useContext(DomainContext);
  const peopleContext = useContext(PeopleContext);
  const lawyerList = peopleContext.staffLawyers;
  const applicationTypes = domainContext.applicationTypes;

  useEffect(() => {
    authAxios.get('/api/reports/workload-report').then((response) => {
      setReportData(response.data);
    });
  }, []);

  const totalFooter = (id: string) => {
    const returnFunction = (e: any) => {
      const total = e.rows.reduce((sum: any, row: any) => row.values[id] + sum, 0);
      return <>{total}</>;
    };
    return returnFunction;
  };

  const columns: Column<IReportData>[] = useMemo(
    () => [
      {
        Header: 'Lawyer',
        id: 'userId',
        Footer: 'Total',
        accessor: (f: IReportData) => {
          if (f.userId) {
            const lawyer = lawyerList.find((l: IUser) => l.userId === f.userId);
            return lawyer?.lastName.toUpperCase() + ', ' + lawyer?.firstName;
          } else {
            return '';
          }
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = [], preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='lawyerId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={lawyerList
                .filter((e) => e.active)
                .sort((a: IUser, b: IUser) => a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase()))
                .map((s: IUser) => ({
                  value: s.userId,
                  display: s.lastName.toUpperCase() + ', ' + s.firstName,
                }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['userId'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
      },
      ...[1, 2, 3, 4].map((g) => {
        return {
          Header: `A ${g}`,
          id: `charges_5ed8bcb6-87e3-42d6-9aea-3ffa3939391a_${g}`,
          width: 25,
          disableSortBy: true,
          disableFilters: true,
          accessor: (e: IReportData) => {
            return (
              e.filesByType.find((t) => t.applicationTypeId === '5ed8bcb6-87e3-42d6-9aea-3ffa3939391a' && t.group === g)
                ?.count || 0
            );
          },
          Footer: totalFooter(`charges_5ed8bcb6-87e3-42d6-9aea-3ffa3939391a_${g}`),
        } as Column<IReportData>;
      }),

      ...[1, 2, 3, 4].map((g) => {
        return {
          Header: `Y ${g}`,
          id: `charges_2a4a2269-40a4-4718-9884-da2d51c0d5ab_${g}`,
          width: 25,
          disableSortBy: true,
          disableFilters: true,
          accessor: (e: IReportData) => {
            return (
              e.filesByType.find((t) => t.applicationTypeId === '2a4a2269-40a4-4718-9884-da2d51c0d5ab' && t.group === g)
                ?.count || 0
            );
          },
          Footer: totalFooter(`charges_2a4a2269-40a4-4718-9884-da2d51c0d5ab_${g}`),
        } as Column<IReportData>;
      }),

      ...applicationTypes
        .filter(
          (g) =>
            g.applicationTypeId !== '2a4a2269-40a4-4718-9884-da2d51c0d5ab' &&
            g.applicationTypeId !== '5ed8bcb6-87e3-42d6-9aea-3ffa3939391a'
        )
        .map((g) => {
          return {
            Header: `${g.name}`,
            id: `charges_${g.applicationTypeId}`,
            width: 50,
            disableSortBy: true,
            disableFilters: true,
            accessor: (e: IReportData) => {
              return e.filesByType.find((t) => t.applicationTypeId === g.applicationTypeId)?.count || 0;
            },
            Footer: totalFooter(`charges_${g.applicationTypeId}`),
          } as Column<IReportData>;
        }),
      {
        Header: 'BW',
        id: 'charges_warrant',
        width: 25,
        disableSortBy: true,
        disableFilters: true,
        accessor: (e: IReportData) => {
          return e.filesByType.find((t) => t.applicationTypeId === 'Warrant')?.count || 0;
        },
        Footer: totalFooter(`charges_warrant`),
      },
      {
        Header: `Total`,
        id: `total`,
        width: 40,
        disableSortBy: true,
        disableFilters: true,
        className: 'grey-background',
        accessor: (e: IReportData) => {
          return e.filesByType.reduce((acc, c) => {
            return acc + c.count;
          }, 0);
        },
        Footer: totalFooter(`total`),
      } as Column<IReportData>,
    ],
    [applicationTypes, lawyerList]
  );

  const data: IReportData[] = useMemo(() => reportData, [reportData]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: TableInstance<IReportData> = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageSize: 100 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className={'workloads'}>
      <Navbar color={'light'} light={true} expand={'xs'}>
        <NavbarBrand className='mr-auto'>Workload Report</NavbarBrand>
      </Navbar>
      <div className={'table-responsive'} style={{ minHeight: '750px' }}>
        <table className={'table table-bordered table-hover'} {...getTableProps()}>
          <thead style={{ overflow: 'wrap' }} className={'print-font'}>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`files-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={`files-table-thead-tr-${index}-${column.id}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    width={column.width}
                  >
                    {column.render('Header')}
                    <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                    <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }} className={'print-font'}>
            {page.map((row: any) => {
              prepareRow(row);

              return (
                <tr key={`files-table-tr-${row.id}`} {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        key={`files-table-td-${cell.row.id}-${cell.column.id}`}
                        {...cell.getCellProps([{ className: cell.column.className }])}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot className={'print-font'}>
            {footerGroups.map((group, index) => (
              // @ts-ignore
              <tr key={`files-table-tfoot-tr-${index}`} {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td
                    // @ts-ignore
                    key={`files-table-tfoot-tr-${index}-${column.id}`}
                    {...column.getFooterProps()}
                    className={'grey-background'}
                  >
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
      {pageCount > 1 && (
        <TablePagination
          pageCount={pageCount}
          pageOptions={pageOptions}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageSizes={[20, 50, 100, 500]}
        />
      )}
    </div>
  );
};
