import * as React from 'react';
import { Input } from 'reactstrap';

// Define a default UI for filtering
export const DefaultColumnFilter = ({ column: { filterValue, setFilter } }: any) => {
  return (
    <Input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search...`}
      bsSize='sm'
    />
  );
};

export const useDefaultColumn = () =>
  React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
