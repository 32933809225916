import * as React from 'react';
import { useContext } from 'react';
import { Card, CardBody, CardHeader, Col, FormGroup, Input, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';
import Select from 'react-select';
import { FileContext } from './FileContext';
import { DomainContext, IReason } from '../../contexts/DomainContext';

interface IProps {
  isEditing?: boolean;
}

export const BoardRefused: React.FC<IProps> = (props: IProps) => {
  const fileContext = useContext(FileContext);
  const file = fileContext.file;
  const domainContext = useContext(DomainContext);
  const reasons = domainContext.reasons;

  return (
    <>
      <Card className={'bg-primary-light'}>
        <CardHeader>Board Refused</CardHeader>
        <CardBody style={{ padding: 0 }}>
          <ListGroup flush={true}>
            <ListGroupItem className={'bg-primary-light'}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Reason for Refusal</Label>
                    <Select
                      name={'reasonId'}
                      options={reasons}
                      value={reasons.find((r: IReason) => r.reasonId === file.reasonIdBoardRejected)}
                      onChange={(value: any) => {
                        fileContext.setFile((f) => ({ ...f, reasonIdBoardRejected: value?.reasonId || '' }));
                      }}
                      getOptionValue={(option: IReason) => option.reasonId}
                      getOptionLabel={(option: IReason) => option.name}
                      isDisabled={!props.isEditing}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Refusal Notes</Label>
                    <Input
                      value={file.reasonBoardRejected}
                      type={'text'}
                      readOnly={!props.isEditing}
                      onChange={(e: any) => {
                        const reasonBoardRejected = e.target.value;
                        fileContext.setFile((f) => ({ ...f, reasonBoardRejected }));
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>
    </>
  );
};
