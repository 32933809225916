import * as React from 'react';
import { Button, Input, Label } from 'reactstrap';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from 'react-icons/fa';

interface IProps {
  pageCount: number;
  pageOptions: number[];
  canPreviousPage: boolean;
  canNextPage: boolean;
  gotoPage: (updater: ((pageIndex: number) => number) | number) => void;
  previousPage: () => void;
  nextPage: () => void;
  setPageSize: (pageSize: number) => void;
  pageIndex: number;
  pageSize: number;
  pageSizes: number[];
  recordCount?: number;
}

export const TablePagination: React.FC<IProps> = (props) => {
  return (
    <div className='d-flex flex-wrap align-items-center flex-row ml-4 mt-2 mb-4'>
      <Button
        className={'mr-2'}
        outline
        color='secondary'
        onClick={() => props.gotoPage(0)}
        disabled={!props.canPreviousPage}
      >
        <FaAngleDoubleLeft className={'mb-1'} />
      </Button>
      <Button
        className={'flex align-items-center mr-2'}
        outline
        color='secondary'
        onClick={() => props.previousPage()}
        disabled={!props.canPreviousPage}
      >
        <FaAngleLeft className={'mb-1'} />
      </Button>
      <Button
        className={'mr-2'}
        outline
        color='secondary'
        onClick={() => props.nextPage()}
        disabled={!props.canNextPage}
      >
        <FaAngleRight className={'mb-1'} />
      </Button>
      <Button
        className={'mr-2'}
        outline
        color='secondary'
        onClick={() => props.gotoPage(props.pageCount - 1)}
        disabled={!props.canNextPage}
      >
        <FaAngleDoubleRight className={'mb-1'} />
      </Button>
      <div className={'mr-4'}>
        Page&nbsp;
        <strong>
          {props.pageIndex + 1} of {props.pageOptions.length}
        </strong>
      </div>
      {!!props.recordCount && <div className={'mr-4'}>{`${props.recordCount} records`}</div>}
      <div className={'d-flex align-items-center mr-2'}>
        <Label for={'page'} className={'mr-2 mb-0'}>
          Go to page:
        </Label>
        <Input
          type='number'
          defaultValue={props.pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            props.gotoPage(page);
          }}
          style={{ width: '100px' }}
        />
      </div>
      <div>
        <Input
          type={'select'}
          value={props.pageSize}
          onChange={(e) => {
            props.setPageSize(Number(e.target.value));
          }}
        >
          {props.pageSizes.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Input>
      </div>
    </div>
  );
};
