import * as React from 'react';
import { FormEvent } from 'react';
import { Button, Col, Navbar, NavbarBrand, Row } from 'reactstrap';
import { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { TablePagination } from '../TablePagination/TablePagination';
import { useMemo } from 'react';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { Column, TableInstance, usePagination, useSortBy, useTable } from 'react-table';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { authAxios } from '../../services/AxiosService';
import { useParams } from 'react-router';
import { formatDateTime } from '../../formatters/DateTimeFormatter';
import { IUser } from '../Users/UsersTable';
import { useContext } from 'react';
import { PeopleContext } from '../../contexts/PeopleContext';
import { MyUserContext } from '../../contexts/MyUserContext';

export interface INote {
  noteId: string;
  text: string;
  dateCreated: number;
  userCreated: string;
  dateLastUpdated: number;
  userUpdated: string;
}

export const FileNotes: React.FC<{ entityType: string }> = (props) => {
  const params = useParams<{ fileId: string }>();
  const [notes, setNotes] = useState([]);
  const peopleContext = useContext(PeopleContext);
  const myUserContext = useContext(MyUserContext);
  const location = useLocation<{ noteToDelete?: string }>();
  const noteToDelete = location?.state?.noteToDelete;
  const users = peopleContext.staff;
  const history = useHistory();

  useEffect(() => {
    authAxios.get('/api/files/admin/' + params.fileId + '/notes').then((response) => {
      if (response.data.status === 'OK') {
        if (noteToDelete) {
          setNotes(response.data.notes.filter((n: INote) => n.noteId !== noteToDelete));
        } else {
          setNotes(response.data.notes);
        }
      }
    });
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.fileId]);

  const columns: Column<INote>[] = useMemo(
    () => [
      {
        Header: 'Date Added',
        id: 'dateCreated',
        width: 140,
        accessor: 'dateCreated',
        Cell: (cell: any) => {
          if (cell && cell.row.original && cell.row.original.dateCreated) {
            return <span>{formatDateTime(new Date(cell.row.original.dateCreated))}</span>;
          } else {
            return <span />;
          }
        },
      },
      {
        Header: 'Text',
        accessor: 'text',
      },
      {
        Header: 'User',
        id: 'userCreated',
        width: 100,
        accessor: (f: INote) => {
          if (f.userCreated) {
            return users.find((l: IUser) => l.userId === f.userCreated)?.displayName;
          } else {
            return '';
          }
        },
      },
    ],
    [users]
  );

  const data: INote[] = useMemo(() => notes, [notes]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: TableInstance<INote> = useTable(
    {
      columns: columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        sortBy: [
          {
            id: 'dateCreated',
            desc: true,
          },
        ],
      },
    },
    // useFilters,
    // useGlobalFilter,
    useSortBy,
    usePagination
  );

  const selectRow = (noteId: string) => {
    history.push('/' + props.entityType + '/' + params.fileId + '/notes/' + noteId);
  };

  return (
    <div>
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
          <Row className={'flex-fill'}>
            <Col md={3} xl={2}>
              <NavbarBrand>Notes</NavbarBrand>
            </Col>
            {!myUserContext.isManagement && (
              <Col className={'d-flex'}>
                <Button
                  color='success'
                  className={'ml-auto'}
                  onClick={() => history.push('/' + props.entityType + '/' + params.fileId + '/notes/add')}
                >
                  New Note
                </Button>
              </Col>
            )}
          </Row>
        </Navbar>
        <div className={'table-responsive'} style={{ minHeight: '500px' }}>
          <table className={'table table-bordered table-hover'} {...getTableProps()}>
            <thead style={{ overflow: 'visible' }}>
              {headerGroups.map((headerGroup: any, index: number) => (
                <tr key={`files-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      key={`files-table-thead-tr-${index}-${column.id}`}
                      width={column.width}
                      style={{ maxWidth: column.width }}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                      <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
              {page.map((row: any) => {
                prepareRow(row);
                return (
                  <tr key={`files-table-tr-${row.id}`} {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      return (
                        <td
                          key={`files-table-td-${cell.row.id}-${cell.column.id}`}
                          {...cell.getCellProps()}
                          onClick={() => selectRow(row.original.noteId)}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <TablePagination
          pageCount={pageCount}
          pageOptions={pageOptions}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageSizes={[20, 50, 100, 500]}
        />
      </form>
    </div>
  );
};
