import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Nav, Navbar, NavbarBrand, Row } from 'reactstrap';
import { FileContext, IAsset, IIncome, IMonthlyIncome } from './FileContext';
import * as DateTimeFormatter from '../../formatters/DateTimeFormatter';
import { DomainContext } from '../../contexts/DomainContext';
import { FaPrint } from 'react-icons/fa';
import { IndividualsContext } from '../../contexts/IndividualsContext';
import { authAxios } from '../../services/AxiosService';
import { IAddress, IIndividual } from '../Individuals/IndividualContext';
import { IPhoneNumber } from '../Individuals/IndividualDetails';
import { useParams } from 'react-router';
import { IUser } from '../Users/UsersTable';
import { PeopleContext } from '../../contexts/PeopleContext';

export const PrintFileDetails: React.FC = () => {
  const params = useParams<{ fileId: string }>();
  const fileContext = useContext(FileContext);
  const domainContext = useContext(DomainContext);
  const peopleContext = useContext(PeopleContext);
  const offences = domainContext.offences;
  const individualsContext = useContext(IndividualsContext);
  const individuals = individualsContext.state.individuals;
  const [addresses, setAddresses] = useState([] as IAddress[]);
  const [phoneNumbers, setPhoneNumbers] = useState([] as IPhoneNumber[]);
  const circuitLocations = domainContext.circuitLocations;
  const file = fileContext.file;
  const setFinancialDetails = fileContext.setFinancialDetails;

  const [applicant, setApplicant] = useState<IIndividual>({} as IIndividual);

  useEffect(() => {
    if (file.clientId) {
      authAxios.get('/api/individuals/' + file.clientId).then((response) => {
        if (response.data) {
          const allAddresses: IAddress[] = response.data.addresses;
          setAddresses(allAddresses.filter((a) => a.isCurrent));
          setPhoneNumbers(response.data.phoneNumbers || []);
          setApplicant(response.data);
        }
      });
    } else {
      setAddresses([]);
    }
  }, [file.clientId, setAddresses]);

  useEffect(() => {
    authAxios.get('/api/files/admin/' + params.fileId + '/financial-details').then((response) => {
      if (response.data) {
        setFinancialDetails({
          maritalStatusId: response.data.maritalStatus,
          livingArrangementId: response.data.livingArrangement,
          numberOfAdults: response.data.numberOfAdults,
          numberOfChildren: response.data.numberOfChildren,
          monthlyIncomes: formatMonthlyIncomes(sortMonths(response.data.monthlyIncomes)),
          assets:
            response.data.assets.length === 0
              ? [
                  {
                    name: '',
                    amount: undefined,
                  },
                ]
              : formatAssets(response.data.assets),
          averageMonthlyIncome: response.data.averageMonthlyIncome,
          averageYearlyIncome: response.data.averageYearlyIncome,
          maxMonthlyIncome: response.data.maxMonthlyIncome,
          maxYearlyIncome: response.data.maxYearlyIncome,
          percentOverage: response.data.percentOverage,
        });
      }
    });
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.fileId, setFinancialDetails]);

  const formatMonthlyIncomes = (monthlyIncomes: IMonthlyIncome[]) => {
    return monthlyIncomes.map((month) => {
      return {
        ...month,
        incomes: month.incomes.map((income) => {
          return {
            incomeTypeId: income.incomeTypeId,
            amount: formatCurrencyCommasTwoDigits(income.amount),
          } as IIncome;
        }),
      } as IMonthlyIncome;
    });
  };

  const formatAssets = (assetList: IAsset[]) => {
    return assetList.map((asset: IAsset) => {
      return { name: asset.name, amount: formatCurrencyCommasTwoDigits(asset.amount) } as IAsset;
    });
  };

  const formatCurrencyCommasTwoDigits = (amount: string | undefined) => {
    if (amount) {
      const floatToUse = parseFloat(amount.toString().replace(/[, ]+/g, ''));
      return Number.isNaN(floatToUse)
        ? '0.00'
        : floatToUse
            .toFixed(2)
            .toString()
            .replace(/-[^0-9.]/g, '')
            .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    } else {
      return '';
    }
  };

  const monthList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const sortMonths = (monthlyIncome: IMonthlyIncome[]) => {
    return monthlyIncome.sort((a: IMonthlyIncome, b: IMonthlyIncome) => {
      if (a.year < b.year) {
        return 1;
      } else if (a.year > b.year) {
        return -1;
      } else {
        if (
          monthList.indexOf(a.month) !== -1 &&
          monthList.indexOf(b.month) !== -1 &&
          monthList.indexOf(a.month) < monthList.indexOf(b.month)
        ) {
          return 1;
        } else if (
          monthList.indexOf(a.month) !== -1 &&
          monthList.indexOf(b.month) !== -1 &&
          monthList.indexOf(a.month) > monthList.indexOf(b.month)
        ) {
          return -1;
        }
      }
      return 0;
    });
  };

  return (
    <div>
      <div className='d-print-none'>
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom'}>
          <NavbarBrand>{'Print File Details'}</NavbarBrand>
          <Nav className={'ml-auto'}>
            <Button color='warning' type='submit' onClick={() => window.print()}>
              <FaPrint /> Print
            </Button>
          </Nav>
        </Navbar>
      </div>
      <Container fluid={true} className={'my-3 container--no-margin-print'}>
        <div className='report' style={{ padding: '15px' }}>
          <header className={'report-header'}>
            <hr className={'d-none d-print-block'} />
            <h2 className='text-center'>YUKON LEGAL SERVICES SOCIETY</h2>
            <h3 className='text-center h5 text-muted'>FILE DETAILS</h3>
          </header>
          <Container fluid={true} style={{ borderTop: '1px solid #4f4f4f', borderLeft: '1px solid #4f4f4f' }}>
            <Row>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Date:</div>
                <div className={'print-application-col__value'}>
                  {DateTimeFormatter.formatDate(file.appDate ? new Date(file.appDate) : undefined)}
                </div>
              </Col>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}> Application Type:</div>
                <div className={'print-application-col__value'}>
                  {domainContext.applicationTypes.find((e) => e.applicationTypeId === file.applicationTypeId)?.name}
                </div>
              </Col>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Application #:</div>
                <div className={'print-application-col__value'}>{fileContext.file.fileNumber}</div>
              </Col>
            </Row>
            <Row>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Lawyer:</div>
                <div className={'print-application-col__value'}>
                  {peopleContext.lawyers.find((u: IUser) => u.userId === fileContext.file.lawyerId)?.displayName || '-'}
                </div>
              </Col>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>File Type:</div>
                <div className={'print-application-col__value'}>
                  {domainContext.fileTypes.find((e) => e.fileTypeId === file.fileTypeId)?.name}
                </div>
              </Col>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Law Office File #:</div>
                <div className={'print-application-col__value'}>{fileContext.file.lawOfficeNumber || '-'}</div>
              </Col>
            </Row>
            <Row>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Full Name:</div>
                <div className={'print-application-col__value'}>
                  {applicant?.lastName?.toUpperCase() + ', ' + applicant?.firstName}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Date of Birth:</div>
                {DateTimeFormatter.formatDate(applicant?.birthDate ? new Date(applicant.birthDate) : undefined, '-')}
              </Col>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Gender:</div>
                <div className={'print-application-col__value'}>
                  {domainContext.genders.find((g) => g.genderId === applicant?.genderId)
                    ? domainContext.genders.find((g) => g.genderId === applicant?.genderId)?.name
                    : '-'}
                </div>
              </Col>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Circuit Location:</div>
                <div className={'print-application-col__value'}>
                  {circuitLocations.find((c) => c.circuitLocationId === file.circuitLocationId)
                    ? circuitLocations.find((c) => c.circuitLocationId === file.circuitLocationId)?.name
                    : '-'}
                </div>
              </Col>
            </Row>
            <Row>
              {addresses.length > 0 ? (
                addresses.map((a, i) => {
                  return (
                    <Col className={i === 0 ? 'print-application-col' : 'print-application-col'} key={a.individualId}>
                      <div className={'print-application-col__label'}>{a.label} Address:</div>
                      <div className={'print-application-col__value'}>
                        {[a.line1, a.line2, a.city, a.territory, a.postalCode].filter((a) => a !== '').join(', ')}
                      </div>
                    </Col>
                  );
                })
              ) : (
                <Col className={'print-application-col'}>
                  <div className={'print-application-col__label'}>Address:</div>
                  <div className={'print-application-col__value'}>-</div>
                </Col>
              )}
            </Row>
            <Row>
              {phoneNumbers.length > 0 ? (
                phoneNumbers.map((p, i) => {
                  return (
                    <Col className={i === 0 ? 'print-application-col' : 'print-application-col'} key={p.number}>
                      <div className={'print-application-col__label'}>{p.description} Phone Number:</div>
                      <div className={'print-application-col__value'}>
                        {p.number.replace(/\D/g, '').length > 6
                          ? '(' +
                            p.number.replace(/\D/g, '').substring(0, 3) +
                            ') ' +
                            p.number.replace(/\D/g, '').substring(3, 6) +
                            '-' +
                            p.number.replace(/\D/g, '').substring(6)
                          : p.number}
                      </div>
                    </Col>
                  );
                })
              ) : (
                <Col className={'print-application-col'}>
                  <div className={'print-application-col__label'}>Phone Number:</div>
                  <div className={'print-application-col__value'}>-</div>
                </Col>
              )}
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Email:</div>
                <div className={'print-application-col__value'}>{applicant.email || '-'}</div>
              </Col>
            </Row>
            <Row>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Matter/Charges:</div>
                <div className={'print-application-col__value'}>
                  {fileContext.charges
                    ? fileContext.charges
                        .map((c) => {
                          const offence = offences.find((o) => o.offenceId === c.offenceId);
                          if (!c.breachNumber) {
                            return offence?.number;
                          } else {
                            return offence?.number + ' (' + c.breachNumber + ')';
                          }
                        })
                        .join(', ')
                    : '-'}
                </div>
              </Col>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Court File #:</div>
                <div className={'print-application-col__value'}>{file.courtNumber ? file.courtNumber : '-'}</div>
              </Col>
            </Row>
            <Row>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Third Parties:</div>
                <div className={'print-application-col__value'}>
                  {fileContext.thirdParties
                    .map((tp) => {
                      const i = individuals.find((i) => i.individualId === tp.individualId);
                      const relationship = domainContext.relationshipTypes.find(
                        (rt) => rt.relationshipTypeId === tp.relationshipTypeId
                      );
                      return i?.lastName.toUpperCase() + ', ' + i?.firstName + ' (' + relationship?.name + ')';
                    })
                    .join(', ')}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Children:</div>
                <div className={'print-application-col__value'}>
                  {file.children && file.children.length > 0
                    ? file.children
                        .map(
                          (c) =>
                            c.name +
                            ' (' +
                            DateTimeFormatter.formatDate(c.birthDate ? new Date(c.birthDate) : undefined) +
                            ')'
                        )
                        .join(', ')
                    : '-'}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={'print-application-col'}>
                <div className={'print-application-col__label'}>Next Appearance:</div>
                <div className={'print-application-col__value'}>
                  {file.nextAppearance ? DateTimeFormatter.formatDateTime(new Date(file.nextAppearance)) : '-'}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </div>
  );
};
