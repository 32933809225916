import * as React from 'react';
import { Dispatch, FormEvent, SetStateAction, useContext, useEffect, useState } from 'react';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import { Button, Col, Container, FormGroup, Input, Label, Navbar, NavbarBrand, Row } from 'reactstrap';
import { useParams } from 'react-router';
import { authAxios } from '../../services/AxiosService';
import { useHistory } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../services/ToastService';
import { formatDateTime } from '../../formatters/DateTimeFormatter';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import DatePicker from 'react-datepicker';
import { INote } from 'src/components/Files/FileNotes';
import * as AuthenticationService from 'src/services/AuthenticationService';
import { SupportWorkerRequestContext } from 'src/components/SupportRequests/SupportWorkerRequestContext';
import { SUPPORT_REQUEST } from 'src/contexts/DomainContext';

interface IInterventionNotesDetails {
  onSave: () => void;
  onCancel: () => void;
  setNotes: Dispatch<SetStateAction<INote[]>>;
}

export const InterventionNotesDetails: React.FC<IInterventionNotesDetails> = (props) => {
  const [isDirty, setIsDirty] = useState(false);
  const [lastUpdated, setLastUpdated] = useState<number>();
  const [date, setDate] = useState<Date>(new Date());
  const [text, setText] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const { supportWorkerRequest } = useContext(SupportWorkerRequestContext);
  const params = useParams<{ supportWorkerRequestId: string; interventionId?: string; noteId?: string }>();
  const history = useHistory();
  const [printOption, setPrintOption] = useState('');
  const authUserId = AuthenticationService.getUserId();

  useEffect(() => {
    if (params.noteId) {
      authAxios
        .get(
          '/api/support-worker-requests/admin/' +
            params.supportWorkerRequestId +
            '/interventions/' +
            params.interventionId +
            '/notes/' +
            params.noteId
        )
        .then((response) => {
          const note: INote = response.data;
          setText(note.text);

          setLastUpdated(note.dateLastUpdated);
        });
    }
  }, [params.supportWorkerRequestId, params.interventionId, params.noteId]);

  useEffect(() => {
    if (printOption !== '') {
      window.print();
      setPrintOption('');
    }
  }, [printOption]);

  const save = () => {
    if (params.noteId) {
      //update
      authAxios
        .post('/api/support-worker-requests/admin/update-intervention-note', {
          supportWorkerRequestId: params.supportWorkerRequestId,
          interventionId: params.interventionId,
          noteId: params.noteId,
          text: text,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            notifySuccess('Note updated.');
            setLastUpdated(new Date().getTime());
          } else {
            notifyError('Error updating note.');
          }
        });
    } else {
      //create
      authAxios
        .post('/api/support-worker-requests/admin/create-intervention-note', {
          supportWorkerRequestId: params.supportWorkerRequestId,
          interventionId: params.interventionId,
          text: text,
          dateCreated: date.getTime(),
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            notifySuccess('Note created.');
            props.setNotes((n) =>
              n.concat([
                {
                  noteId: response.data.noteId,
                  text: text,
                  dateCreated: date.getTime(),
                  userCreated: authUserId,
                  dateLastUpdated: new Date().getTime(),
                  userUpdated: authUserId,
                },
              ])
            );
          } else {
            notifyError('Error creating note.');
          }
        });
      props.onSave();
    }
  };

  const deleteNote = () => {
    if (params.noteId) {
      authAxios
        .post('/api/support-worker-requests/admin/delete-intervention-note', {
          supportWorkerRequestId: params.supportWorkerRequestId,
          interventionId: params.interventionId,
          noteId: params.noteId,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            notifySuccess('Note deleted.');
            history.push({
              pathname:
                '/support-worker-requests/' + params.supportWorkerRequestId + '/interventions/' + params.interventionId,
              state: { noteToDelete: params.noteId },
            });
          } else {
            notifyError('Error deleting note.');
          }
        });
    }
  };

  const handleCancel = () => {
    if (isDirty) {
      setCancelModalOpen(true);
    } else {
      props.onCancel();
    }
  };

  const handleBack = () => {
    history.push({
      pathname: '/support-worker-requests/' + params.supportWorkerRequestId + '/interventions/' + params.interventionId,
    });
  };

  return (
    <>
      <LeavePageConfirm isDirty={isDirty} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
        className={'no-print'}
      >
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
          <NavbarBrand>{params.noteId ? 'Edit Note' : 'Add Note'}</NavbarBrand>
          <div className={'ml-auto d-flex flex-row-reverse'}>
            <Button
              color={'primary'}
              className={'ml-2'}
              onClick={save}
              disabled={!isDirty || supportWorkerRequest.status !== SUPPORT_REQUEST.ACCEPTED}
            >
              Save Note
            </Button>
            {params.noteId ? (
              <div>
                <Button color='link' className={'ml-auto'} onClick={handleBack}>
                  Back
                </Button>
                <Button
                  color='danger'
                  className={'ml-2'}
                  onClick={() => setDeleteModalOpen(true)}
                  disabled={supportWorkerRequest.status !== SUPPORT_REQUEST.ACCEPTED}
                >
                  Delete Note
                </Button>
              </div>
            ) : (
              <Button color='link' className={'ml-auto'} onClick={handleCancel}>
                Cancel
              </Button>
            )}
          </div>
        </Navbar>
        <Container fluid={true}>
          <Row className={'mt-3'}>
            <Col md={12} lg={2} className={'text-lg-right'}>
              <Label htmlFor={'note'} className={'text-body h6 mb-0 font-weight-bold'}>
                Note
              </Label>
              <br />
              {params.noteId && (
                <div className={'text-muted small mb-2'}>
                  &nbsp;&nbsp;Last Updated:
                  <br /> {lastUpdated !== undefined ? formatDateTime(new Date(lastUpdated)) : ''}
                </div>
              )}
            </Col>
            <Col md={8} lg={7} xl={6}>
              <FormGroup>
                <Label>Details</Label>
                <Input
                  type={'textarea'}
                  placeholder='Type here...'
                  name='Details'
                  value={text}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const noteDetails = e.target.value;
                    setText(noteDetails);
                    setIsDirty(true);
                  }}
                  disabled={supportWorkerRequest.status !== SUPPORT_REQUEST.ACCEPTED}
                />
              </FormGroup>
              {!params.noteId && (
                <FormGroup>
                  <Label htmlFor={'noteDate'}>Date</Label>
                  <DatePicker
                    selected={date}
                    id={'noteDate'}
                    onChange={(date) => {
                      if (date) {
                        setDate(date);
                      }
                    }}
                    className={'form-control'}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dateFormat='MMM dd, yyyy'
                    disabled={supportWorkerRequest.status !== SUPPORT_REQUEST.ACCEPTED}
                  />
                </FormGroup>
              )}
            </Col>
          </Row>
        </Container>
      </form>
      <ConfirmModal
        isOpen={deleteModalOpen}
        title={'Delete Note'}
        onConfirm={() => {
          setDeleteModalOpen(false);
          deleteNote();
        }}
        onCancel={() => setDeleteModalOpen(false)}
        confirmButton={'Delete Note'}
        confirmButtonColor={'danger'}
      >
        <div>Are you sure you want to delete this note?</div>
      </ConfirmModal>
      <ConfirmModal
        isOpen={cancelModalOpen}
        title={`You have unsaved changes.`}
        onConfirm={() => {
          setCancelModalOpen(false);
          props.onCancel();
        }}
        onCancel={() => setDeleteModalOpen(false)}
        confirmButton={'Leave this Page'}
        cancelButton={'Stay on Page'}
      />
    </>
  );
};
