import * as React from 'react';
import { FormEvent } from 'react';
import { Col, Navbar, NavbarBrand, Row } from 'reactstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { TableGlobalFilter } from '../TableGlobalFilter/TableGlobalFilter';
import { TablePagination } from '../TablePagination/TablePagination';
import { useMemo } from 'react';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { Column, TableInstance, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { IUser } from '../Users/UsersTable';
import { formatDateTime } from '../../formatters/DateTimeFormatter';
import { useState } from 'react';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { authAxios } from '../../services/AxiosService';
import { IEvent } from '../Files/FileActivityLog';
import { useContext } from 'react';
import { PeopleContext } from '../../contexts/PeopleContext';

export const IndividualActivityLog: React.FC = () => {
  const [events, setEvents] = useState<IEvent[]>([]);
  const peopleContext = useContext(PeopleContext);
  const users = peopleContext.staff;
  const params = useParams<{ individualId: string }>();

  useEffect(() => {
    authAxios.get('/api/individuals/' + params.individualId + '/activity-log').then((response) => {
      setEvents(response.data);
    });
  }, [params.individualId]);

  const columns: Column<IEvent>[] = useMemo(
    () => [
      {
        Header: 'Event',
        accessor: 'event',
      },
      {
        Header: 'Description',
        id: 'description',
        accessor: (event: IEvent) => {
          if (event.details) {
            const user = users.find((u) => u.userId === event.details);
            return user ? event.description + user.lastName.toUpperCase() + ', ' + user.firstName : event.description;
          } else {
            return event.description;
          }
        },
      },
      {
        Header: 'User',
        id: 'auditUserId',
        accessor: (event: IEvent) => {
          const user = users.find((u: IUser) => event.auditUserId === u.userId);
          return user?.lastName.toUpperCase() + ', ' + user?.firstName;
        },
      },
      {
        Header: 'Date',
        accessor: 'auditTimestamp',
        Cell: (cell: any) => {
          if (cell && cell.row.original && cell.row.original.auditTimestamp) {
            return <span>{formatDateTime(new Date(cell.row.original.auditTimestamp))}</span>;
          } else {
            return <span />;
          }
        },
      },
    ],
    [users]
  );

  const data: IEvent[] = useMemo(() => events, [events]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,

    prepareRow,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,

    state,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: TableInstance<IEvent> = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        sortBy: [
          {
            id: 'auditTimestamp',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div>
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom'}>
          <Row className={'flex-fill'}>
            <Col md={3} xl={2}>
              <NavbarBrand>{'Activity Log'}</NavbarBrand>
            </Col>
          </Row>
        </Navbar>
        <div className={'table-responsive'}>
          <table className={'table table-bordered'} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup: any, index: number) => (
                <tr key={`files-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      key={`files-table-thead-tr-${index}-${column.id}`}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                    </th>
                  ))}
                </tr>
              ))}
              <tr>
                <TableGlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  colSpan={visibleColumns.length}
                />
              </tr>
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row: any) => {
                prepareRow(row);

                return (
                  <tr key={`files-table-tr-${row.id}`} {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      return (
                        <td
                          key={`files-table-td-${cell.row.id}-${cell.column.id}`}
                          {...cell.getCellProps()}
                          // onClick={() => selectRow(row.original.fileId)}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <TablePagination
          pageCount={pageCount}
          pageOptions={pageOptions}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageSizes={[20, 50, 100, 500]}
        />
      </form>
    </div>
  );
};
