import * as React from 'react';
import { useEffect, useState } from 'react';
import { authAxios } from '../services/AxiosService';
import { IUser } from '../components/Users/UsersTable';
import { OFFICE_PRIVATE_LAWYER } from './DomainContext';

type ContextType = {
  loaded: boolean;
  lawyers: IUser[];
  staffLawyers: IUser[];
  privateLawyers: IUser[];
  staff: IUser[];
  supportWorkers: IUser[];
};

export const PeopleContext = React.createContext<ContextType>({
  loaded: false,
  lawyers: [] as IUser[],
  staffLawyers: [] as IUser[],
  privateLawyers: [] as IUser[],
  staff: [] as IUser[],
  supportWorkers: [] as IUser[],
});

export const PeopleProvider = (props: any) => {
  const [lawyers, setLawyers] = useState<IUser[]>([]);
  const [staffLawyers, setStaffLawyers] = useState<IUser[]>([]);
  const [privateLawyers, setPrivateLawyers] = useState<IUser[]>([]);
  const [lawyersLoaded, setLawyersLoaded] = useState(false);
  const [staffLoaded, setStaffLoaded] = useState(false);
  const [staff, setStaff] = useState<IUser[]>([]);
  const [supportWorkers, setSupportWorkers] = useState<IUser[]>([]);
  const loaded = lawyersLoaded && staffLoaded;

  useEffect(() => {
    authAxios.get('/api/users/admin/all-staff').then((response) => {
      const users: IUser[] = response.data;
      setStaff(users);
      setStaffLoaded(true);
      setSupportWorkers(users.filter((u) => u.role === 'SupportWorker'));
    });
  }, []);

  useEffect(() => {
    authAxios.get('/api/users/admin/staff').then((response) => {
      const users: IUser[] = response.data;
      setLawyers(users.filter((user: IUser) => user.role === 'Lawyer'));
      setStaffLawyers(users.filter((user: IUser) => user.role === 'Lawyer' && user.officeId !== OFFICE_PRIVATE_LAWYER));
      setPrivateLawyers(
        users.filter((user: IUser) => user.role === 'Lawyer' && user.officeId === OFFICE_PRIVATE_LAWYER)
      );
      setLawyersLoaded(true);
    });
  }, []);

  return (
    <PeopleContext.Provider
      value={{
        loaded,
        lawyers,
        staffLawyers,
        privateLawyers,
        staff,
        supportWorkers,
      }}
    >
      {props.children}
    </PeopleContext.Provider>
  );
};

export const PeopleConsumer = PeopleContext.Consumer;
