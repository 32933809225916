import * as React from 'react';
import { useEffect, useState } from 'react';
import { authAxios } from 'src/services/AxiosService';
import { IReportingFile } from '../Files/FileContext';

type ContextType = {
  allFiles: IReportingFile[];
  fromDate: Date;
  setFromDate: React.Dispatch<React.SetStateAction<Date>>;
  toDate: Date;
  setToDate: React.Dispatch<React.SetStateAction<Date>>;
};

export const ReportContext = React.createContext<ContextType>({
  allFiles: [],
  fromDate: new Date(),
  setFromDate: () => {},
  toDate: new Date(),
  setToDate: () => {},
});

export const ReportProvider = (props: any) => {
  const [allFiles, setAllFiles] = useState<IReportingFile[]>([]);
  const defaultFromDate = new Date();
  const [fromDate, setFromDate] = useState<Date>(
    new Date(defaultFromDate.getMonth() < 3 ? defaultFromDate.getFullYear() - 1 : defaultFromDate.getFullYear(), 3, 1)
  );
  const defaultToDate = new Date();
  defaultToDate.setUTCHours(23, 59, 59, 999);
  const [toDate, setToDate] = useState<Date>(defaultToDate);

  useEffect(() => {
    authAxios.get('/api/files/reporting').then((response) => {
      setAllFiles(response.data);
    });
  }, []);

  return (
    <ReportContext.Provider
      value={{
        allFiles,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
      }}
    >
      {props.children}
    </ReportContext.Provider>
  );
};

export const ReportConsumer = ReportContext.Consumer;
