import * as React from 'react';
import { FormEvent, useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  FormText,
  Input,
  Label,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import Select, { components, OptionProps, SingleValueProps } from 'react-select';
import { authAxios } from '../../services/AxiosService';
import { notifyError, notifySuccess } from '../../services/ToastService';
import {
  DomainContext,
  editableStages,
  ICircuitLocation,
  IOffice,
  STAGE_BOARD_REVIEW,
  STAGE_CLOSED,
  STAGE_COUNSEL_REQUEST_DRAFT,
  STAGE_DUTY_COUNSEL,
  STAGE_PENDING,
  STAGE_STOPPED,
} from '../../contexts/DomainContext';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import DatePicker from 'react-datepicker';
import { RequiredLabel } from '../RequiredLabel';
import { MyUserContext } from '../../contexts/MyUserContext';
import { FilesContext } from '../../contexts/FilesContext';
import { NonClientFileContext } from './NonClientFileContext';
import { CloseNonClientFile } from './Actions/CloseNonClientFile';
import { IUser } from '../Users/UsersTable';
import { lawyerSelectValue } from '../../formatters/PeopleFormatter';
import { PeopleContext } from '../../contexts/PeopleContext';

export const NonClientFileDetails: React.FC = () => {
  const domainContext = useContext(DomainContext);
  const fileContext = useContext(NonClientFileContext);
  const filesContext = useContext(FilesContext);
  const myUserContext = useContext(MyUserContext);
  const peopleContext = useContext(PeopleContext);
  const file = fileContext.file;
  const setFile = fileContext.setFile;
  const offices = domainContext.offices;

  const circuitLocations = domainContext.circuitLocations;

  const [trySave, setTrySave] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const history = useHistory();
  const params = useParams<{ fileId: string }>();

  const [isEditingAllowed, setIsEditingAllowed] = useState(
    params.fileId ? editableStages.includes(file.stageId) : true
  );

  useEffect(() => {
    setIsEditingAllowed(params.fileId ? editableStages.includes(file.stageId) : true);
  }, [params.fileId, file.stageId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [file.fileId]);

  const disabledSelectStyles = {
    control: (styles: any, state: any) => {
      return {
        ...styles,
        borderColor: '#ced4da',
      };
    },
    singleValue: (styles: any, state: any) => {
      return {
        ...styles,
        color: '#495057',
      };
    },
  };

  const isEditingAllowedOnlySuperAdminIntake = () => {
    return (
      isEditingAllowed &&
      ((!myUserContext.isLegalAssistant && !myUserContext.isLawyer) ||
        !file.stageId ||
        editableStages.includes(file?.stageId))
    );
  };

  const LawyerOption = (props: OptionProps<IUser, boolean>) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <div>{lawyerSelectValue(data, domainContext.offices)}</div>
      </components.Option>
    );
  };

  const LawyerSingleValue = (props: SingleValueProps<IUser>) => {
    const { data } = props;
    return <components.SingleValue {...props}>{lawyerSelectValue(data, domainContext.offices)}</components.SingleValue>;
  };

  const saveFile = () => {
    if (fileContext.description) {
      if (params.fileId) {
        const data = fileContext.getNonClientFileUpdateData();

        authAxios.post('/api/non-client-files/update', data).then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            notifySuccess('File updated.');
            if (!editableStages.includes(file.stageId)) {
              setIsEditingAllowed(false);
            }
          } else {
            notifyError(response.data.errorMessage);
          }
        });

        if (
          fileContext.lawOfficeNumber &&
          fileContext.lawOfficeNumber !== fileContext.file.lawOfficeNumber &&
          myUserContext.isLegalAssistant
        ) {
          const lawOfficeNumberData = {
            fileId: fileContext.file.fileId,
            lawOfficeNumber: fileContext.lawOfficeNumber,
          };
          authAxios.post('/api/files/admin/update-law-office-number', lawOfficeNumberData);
        }
      } else {
        const data = fileContext.getNonClientFileCreateData();

        authAxios.post('/api/files/admin/create-non-client', data).then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            const file = response.data.file;
            setFile(file);
            fileContext.setLawOfficeNumber(file.lawOfficeNumber);
            filesContext.dispatch({ type: 'FILE_ACTION_CREATE_FILE', data: response.data.fileBasic });
            notifySuccess('File created.');
            history.push({ pathname: '/non-client-files/' + file.fileId, state: { overrideReload: true } });
          } else {
            notifyError(response.data.errorMessage);
          }
        });
      }
    } else {
      setTrySave(true);
    }
  };

  return (
    <div>
      <LeavePageConfirm isDirty={isDirty} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
          <Row className={'flex-fill'}>
            <Col md={3} xl={2}>
              <NavbarBrand>{params.fileId ? 'Edit File Details' : 'Add New File'}</NavbarBrand>
            </Col>
            {params.fileId && file.stageId === STAGE_PENDING && (
              <Col className={'d-flex'}>
                <Button color='success' className={'ml-auto'} onClick={() => saveFile()}>
                  Save File
                </Button>
              </Col>
            )}
            {!isEditingAllowed && file.stageId !== STAGE_STOPPED ? (
              <Col className={'d-flex'}>
                <Button color='primary' className={'ml-auto'} onClick={() => setIsEditingAllowed(true)}>
                  Edit Details
                </Button>
              </Col>
            ) : (
              params.fileId &&
              file.stageId !== STAGE_PENDING &&
              file.stageId !== STAGE_STOPPED &&
              file.stageId !== STAGE_BOARD_REVIEW &&
              file.stageId !== STAGE_COUNSEL_REQUEST_DRAFT &&
              file.stageId !== STAGE_DUTY_COUNSEL && (
                <Col className={'d-flex'}>
                  <Button color='success' className={'ml-auto'} onClick={() => saveFile()}>
                    Save Details
                  </Button>
                </Col>
              )
            )}
            {!params.fileId && (
              <Col className={'d-flex'}>
                <Button color={'light'} className={'ml-auto'} onClick={() => history.push('/files')}>
                  Cancel
                </Button>
                <Button color='success' className={'ml-2'} onClick={() => saveFile()}>
                  Save File
                </Button>
              </Col>
            )}
          </Row>
        </Navbar>
        <Container fluid={true} className={'my-3'}>
          <Row>
            <Col lg={5}>
              <Card className={'mb-3'}>
                <CardBody>
                  <>
                    <div className={'d-md-flex'}>
                      <FormGroup className={'w-100 mr-3'}>
                        <RequiredLabel for={'file-date'} required={fileContext.fileDate === undefined}>
                          File Date
                        </RequiredLabel>
                        <DatePicker
                          selected={fileContext.fileDate}
                          name={'application-date'}
                          onChange={(date) => {
                            if (date) {
                              fileContext.setFileDate(date);
                              setIsDirty(true);
                            }
                          }}
                          peekNextMonth
                          showMonthDropdown
                          disabled={!isEditingAllowedOnlySuperAdminIntake()}
                          showYearDropdown
                          dateFormat='MMM dd, yyyy'
                          dropdownMode='select'
                          className={
                            isEditingAllowedOnlySuperAdminIntake()
                              ? 'date-picker rounded mb-0'
                              : 'date-picker-disabled rounded mb-0'
                          }
                          placeholderText={'MMM dd, yyyy'}
                        />
                        {trySave && fileContext.fileDate === undefined && (
                          <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                        )}
                      </FormGroup>
                      <FormGroup className={'w-100'}>
                        <Label for={'lawOfficeNumber'}>Law Office Number</Label>
                        <Input
                          type={'number'}
                          value={fileContext.lawOfficeNumber}
                          onChange={(e: any) => {
                            if (!Number.isNaN(e.target.value)) {
                              fileContext.setLawOfficeNumber(Number(e.target.value));
                            }
                            setIsDirty(true);
                          }}
                          disabled={!myUserContext.isLegalAssistant || !isEditingAllowed || !fileContext.file?.fileId}
                          style={{ textAlign: 'right' }}
                        />
                      </FormGroup>
                    </div>
                    <div className={'d-md-flex'}>
                      <FormGroup className={'w-100'}>
                        <RequiredLabel required={!fileContext.description} for={'description'}>
                          Description
                        </RequiredLabel>
                        <Input
                          value={fileContext.description}
                          type={'text'}
                          autoComplete={'off'}
                          disabled={!isEditingAllowedOnlySuperAdminIntake()}
                          name={'Description'}
                          required={true}
                          onChange={(e: any) => {
                            fileContext.setDescription(e.target.value);
                            setIsDirty(true);
                          }}
                        />
                        {trySave && !fileContext.description && (
                          <FormText color={'danger'}>This field is mandatory</FormText>
                        )}
                      </FormGroup>
                    </div>
                    <div className={'d-md-flex'}>
                      <FormGroup className={'w-100'}>
                        <Label for={'circuitLocation'}>Circuit Location</Label>
                        <Select
                          styles={disabledSelectStyles}
                          name={'circuitLocation'}
                          options={circuitLocations}
                          placeholder={isEditingAllowedOnlySuperAdminIntake() ? 'Select...' : ''}
                          value={circuitLocations.find(
                            (cl: ICircuitLocation) => cl.circuitLocationId === file.circuitLocationId
                          )}
                          onChange={(value: any) => {
                            fileContext.setFile((f) => ({
                              ...f,
                              circuitLocationId: value?.circuitLocationId,
                            }));
                            setIsDirty(true);
                          }}
                          getOptionValue={(option: ICircuitLocation) => option.circuitLocationId}
                          getOptionLabel={(option: ICircuitLocation) => option.name}
                          isDisabled={!isEditingAllowedOnlySuperAdminIntake()}
                          isClearable={true}
                        />
                      </FormGroup>
                      <FormGroup className={'w-100 ml-3'}>
                        <RequiredLabel for={'expiry-date'} required={fileContext.expiryDate === undefined}>
                          Expiry Date
                        </RequiredLabel>
                        <DatePicker
                          selected={fileContext.expiryDate}
                          name={'expiry-date'}
                          onChange={(date) => {
                            if (date) {
                              fileContext.setExpiryDate(date);
                              setIsDirty(true);
                            }
                          }}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dateFormat='MMM dd, yyyy'
                          dropdownMode='select'
                          className={
                            isEditingAllowedOnlySuperAdminIntake()
                              ? 'date-picker rounded mb-0'
                              : 'date-picker-disabled rounded mb-0'
                          }
                          placeholderText={'MMM dd, yyyy'}
                          disabled={!isEditingAllowedOnlySuperAdminIntake()}
                        />
                      </FormGroup>
                    </div>
                    <div className={'d-md-flex'}>
                      <FormGroup className={'w-100'}>
                        <Label for={'lawyerId'}>Lawyer</Label>
                        <Select
                          name={'lawyerId'}
                          className={'mb-3'}
                          options={
                            peopleContext.lawyers
                              .filter((e) => e.active)
                              .sort((a: IUser, b: IUser) =>
                                a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase())
                              ) as IUser[]
                          }
                          value={peopleContext.lawyers.find((i: IUser) => i.userId === fileContext.lawyerId)}
                          onChange={(value: any) => fileContext.setLawyerId(value?.userId || '')}
                          getOptionValue={(option: IUser) => option.userId}
                          getOptionLabel={(option: IUser) =>
                            option.lastName.toUpperCase() +
                            ', ' +
                            option.firstName +
                            ' (' +
                            offices.find((o: IOffice) => o.officeId === option.officeId)?.name +
                            ')'
                          }
                          components={{ SingleValue: LawyerSingleValue, Option: LawyerOption }}
                          styles={{
                            singleValue: (base) => ({
                              ...base,
                              position: 'relative',
                              top: 0,
                              transform: 'translateY(0)',
                              height: '100%',
                              padding: '0.25em 0',
                            }),
                          }}
                          isClearable={true}
                          menuPlacement={'auto'}
                          isDisabled={myUserContext.isLawyer}
                        />
                      </FormGroup>
                    </div>
                  </>
                </CardBody>
              </Card>
            </Col>
            <Col>{(file?.stageId === STAGE_PENDING || file?.stageId === STAGE_CLOSED) && <CloseNonClientFile />}</Col>
          </Row>
        </Container>
      </form>
    </div>
  );
};
