import * as React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Navbar, NavbarBrand, Row } from 'reactstrap';
import { FaPrint } from 'react-icons/fa';
import { useContext, useEffect, useState } from 'react';
import { IReportingFile } from '../Files/FileContext';
import { DomainContext, STAGE_CLOSED } from '../../contexts/DomainContext';
import { IndividualsContext } from '../../contexts/IndividualsContext';
import { formatDate } from '../../formatters/DateTimeFormatter';
import DatePicker from 'react-datepicker';
import { ReportContext } from './ReportContext';
import { MyUserContext } from '../../contexts/MyUserContext';
import { PeopleContext } from '../../contexts/PeopleContext';

export const FilesForDestruction = () => {
  const individualsContext = useContext(IndividualsContext);
  const domainContext = useContext(DomainContext);
  const myUserContext = useContext(MyUserContext);
  const peopleContext = useContext(PeopleContext);
  const [filteredFiles, setFilteredFiles] = useState<IReportingFile[]>([]);
  const [fileCount, setFileCount] = useState<number>(0);
  const individuals = individualsContext.state.individuals;
  const reportContext = useContext(ReportContext);
  const allFiles = reportContext.allFiles;
  const fromDate = reportContext.fromDate;
  const setFromDate = reportContext.setFromDate;
  const toDate = reportContext.toDate;
  const setToDate = reportContext.setToDate;

  useEffect(() => {
    setFilteredFiles(
      allFiles.filter(
        (f) =>
          f.sId === STAGE_CLOSED &&
          f.clsDate &&
          f.destructionDate &&
          f.destructionDate >= fromDate.getTime() &&
          f.destructionDate <= toDate.getTime()
      )
    );
    setFileCount(
      allFiles.filter(
        (f) =>
          f.sId === STAGE_CLOSED &&
          f.clsDate &&
          f.destructionDate &&
          f.destructionDate >= fromDate.getTime() &&
          f.destructionDate <= toDate.getTime()
      ).length
    );
  }, [allFiles, fromDate, toDate]);

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
        <Row className={'flex-fill'}>
          <Col md={3} xl={2}>
            <NavbarBrand>Files For Destruction</NavbarBrand>
          </Col>
          <Col className={'d-flex'}>
            <Button className={'ml-auto'} color={'warning'} onClick={() => window.print()}>
              Print <FaPrint />
            </Button>
          </Col>
        </Row>
      </Navbar>
      <Container className={'my-5 container--no-margin-print'}>
        <Row>
          <Col>
            <Card className={'no-print'}>
              <CardHeader>Select Date for Destruction Date Range</CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Label className={'flex second-label'} style={{ paddingRight: '10px' }}>
                      From
                    </Label>
                    <DatePicker
                      className={'form-control date-select flex'}
                      selected={fromDate}
                      onChange={(date) => {
                        if (date) {
                          setFromDate(date);
                        }
                      }}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      shouldCloseOnSelect={true}
                      dateFormat={'yyyy-MM-dd'}
                    />
                  </Col>
                  <Col>
                    <Label
                      className={'flex second-label'}
                      for={'EndDate'}
                      style={{ paddingRight: '10px', paddingLeft: '10px' }}
                    >
                      To
                    </Label>
                    <DatePicker
                      className={'form-control date-select flex'}
                      selected={toDate}
                      onChange={(date) => {
                        if (date) {
                          setToDate(date);
                        }
                      }}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      shouldCloseOnSelect={true}
                      dateFormat={'yyyy-MM-dd'}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className={'report mt-5'}>
          <header className={'report-header'}>
            <h2 className={'text-center'}>Files For Destruction</h2>
            <h3 className={'text-center h5 text-muted'}>
              {'Number of Files to be Destroyed Between ' +
                formatDate(fromDate) +
                ' and ' +
                formatDate(toDate) +
                ' at ' +
                domainContext.offices.find((o) => o.officeId === myUserContext.officeId)?.name +
                ': ' +
                fileCount}
            </h3>
          </header>

          <table className={'report1'}>
            <tr>
              <th className={'header'}>Law Office Number</th>
              <th className={'header'}>Client</th>
              <th className={'header'}>Lawyer</th>
              <th className={'header'}>Closed Date</th>
              <th className={'header'}>Destruction Date</th>
            </tr>
            {filteredFiles
              .sort((a, b) => (a.clsDate! < b.clsDate! ? 1 : -1))
              .map((f) => {
                return (
                  <tr key={f.fId}>
                    <td className={'report-row'}>{f.lawOfficeNumber}</td>
                    <td className={'report-row'}>
                      {individuals.find((i) => i.individualId === f.cId)?.lastName.toUpperCase() +
                        ', ' +
                        individuals.find((i) => i.individualId === f.cId)?.firstName}
                    </td>
                    <td className={'report-row'}>
                      {peopleContext.lawyers.find((p) => p.userId === f.lId)?.lastName.toUpperCase() +
                        ', ' +
                        peopleContext.lawyers.find((p) => p.userId === f.lId)?.firstName}
                    </td>
                    <td className={'report-row'}>{formatDate(new Date(f.clsDate!))}</td>
                    <td className={'report-row'}>{f.destructionDate ? formatDate(new Date(f.destructionDate)) : ''}</td>
                  </tr>
                );
              })}
          </table>
        </div>
      </Container>
    </div>
  );
};
