import * as React from 'react';
import { useContext, useEffect } from 'react';
import { authAxios } from '../services/AxiosService';
import { IFile } from '../components/Files/FileContext';
import { IUser } from '../components/Users/UsersTable';
import { PeopleContext } from './PeopleContext';
import { useMostSerious } from '../hooks/useMostSerious';

interface IFileState {
  type: FileState;
  files: IFile[];
  activeOnly: boolean;
}

interface FileStateWithDispatch {
  state: IFileState;
  dispatch: React.Dispatch<FileAction>;
}

export const FILE_STATE_LOADING = 'FILE_STATE_LOADING';
export const FILE_STATE_LOADED = 'FILE_STATE_LOADED';
export const FILE_STATE_RELOADING = 'FILE_STATE_RELOADING';
export type FileState = 'FILE_STATE_LOADING' | 'FILE_STATE_LOADED' | 'FILE_STATE_RELOADING';

export const FILE_ACTION_LOADING = { type: 'FILE_ACTION_LOADING' };
export const FILE_ACTION_ALL_FILES = { type: 'FILE_ACTION_ALL_FILES' };
export const FILE_ACTION_ACTIVE_ONLY = { type: 'FILE_ACTION_ACTIVE_ONLY' };
export const FILE_ACTION_DO_RELOAD = { type: 'FILE_ACTION_DO_RELOAD' };
export const FILE_ACTION_SET_DATA = { type: 'FILE_ACTION_SET_DATA', data: [] as IFile[] };
export const FILE_ACTION_CREATE_FILE = {
  type: 'FILE_ACTION_CREATE_FILE',
  data: {} as IFile,
};
export const FILE_ACTION_UPDATE_FILE = {
  type: 'FILE_ACTION_UPDATE_FILE',
  data: {} as IFile,
};

export type FileAction = {
  type:
    | 'FILE_ACTION_LOADING'
    | 'FILE_ACTION_DO_RELOAD'
    | 'FILE_ACTION_SET_DATA'
    | 'FILE_ACTION_UPDATE_FILE'
    | 'FILE_ACTION_CREATE_FILE'
    | 'FILE_ACTION_ALL_FILES'
    | 'FILE_ACTION_ACTIVE_ONLY';
  data?: any;
};

export const FilesContext = React.createContext<FileStateWithDispatch>({
  state: { type: 'FILE_STATE_LOADED', files: [] as IFile[], activeOnly: true },
  dispatch: () => ({}),
});

const filesReducer: React.Reducer<IFileState, FileAction> = (state: IFileState, action: FileAction) => {
  switch (action.type) {
    case FILE_ACTION_ACTIVE_ONLY.type:
      return { ...state, activeOnly: true, type: FILE_STATE_RELOADING };
    case FILE_ACTION_ALL_FILES.type:
      return { ...state, activeOnly: false, type: FILE_STATE_RELOADING };
    case FILE_ACTION_LOADING.type:
      return { ...state, type: FILE_STATE_LOADING };
    case FILE_ACTION_DO_RELOAD.type:
      return { ...state, type: FILE_STATE_RELOADING, activeOnly: true };
    case FILE_ACTION_SET_DATA.type:
      return { ...state, files: action.data, type: FILE_STATE_LOADED };
    case FILE_ACTION_UPDATE_FILE.type:
      return {
        ...state,
        files: [...state.files.filter((e) => e.fileId !== action.data.fileId), action.data],
      };
    case FILE_ACTION_CREATE_FILE.type:
      return { ...state, files: [...state.files, action.data] };
    default:
      return state;
  }
};

export const FilesProvider: React.FC = (props) => {
  const [state, dispatch] = React.useReducer(filesReducer, { type: FILE_STATE_LOADED, files: [], activeOnly: true });
  const peopleContext = useContext(PeopleContext);
  const lawyerList = peopleContext.lawyers;
  const mostSerious = useMostSerious();

  useEffect(() => {
    if (state.type === FILE_STATE_LOADING || state.type === FILE_STATE_RELOADING) {
      console.log('DOING FILE CALL:' + new Date().getMinutes() + ' ' + new Date().getSeconds());

      authAxios.get(state.activeOnly ? '/api/files/basic-active' : '/api/files/basic').then((response) => {
        console.log(' RESPONSE DOING FILE CALL:' + new Date().getMinutes() + ' ' + new Date().getSeconds());
        const files: IFile[] = response.data.map((e: any) => {
          const lawyer = lawyerList.find((l: IUser) => l.userId === e.lawyerId);
          const lawyerFormatted = lawyer ? `${lawyer?.lastName.toUpperCase()}, ${lawyer?.firstName}` : '';

          return {
            ...e,
            mostSerious: mostSerious(e.applicationTypeId, e.charges),
            lawyerFormatted,
          };
        });
        dispatch({ type: 'FILE_ACTION_SET_DATA', data: files });
      });
    }
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.type, state.activeOnly]);

  const value: FileStateWithDispatch = { state, dispatch };
  return <FilesContext.Provider value={value}>{props.children}</FilesContext.Provider>;
};
