import * as React from 'react';
import { useEffect, useState } from 'react';
import { authAxios } from '../services/AxiosService';

type ContextType = {
  loaded: boolean;
  officeId: string;
  role: string;
  isLegalAssistant: boolean;
  isLawyer: boolean;
  isIntakeOffice: boolean;
  isSuperAdmin: boolean;
  isSupportWorker: boolean;
  isManagement: boolean;
  isReportingOnly: boolean;
};

export const MyUserContext = React.createContext<ContextType>({
  loaded: false,
  role: '',
  officeId: '',
  isLegalAssistant: false,
  isLawyer: false,
  isIntakeOffice: false,
  isSuperAdmin: false,
  isSupportWorker: false,
  isManagement: false,
  isReportingOnly: false,
});

export const MyUserProvider = (props: any) => {
  const [role, setRole] = useState('');
  const [officeId, setOfficeId] = useState('');
  const [loaded, setLoaded] = useState(false);

  const isLegalAssistant = role === 'LegalAssistant';
  const isLawyer = role === 'Lawyer';
  const isIntakeOffice =
    officeId === '70311ff3-d930-43ca-879a-5c96148ff1da' && (role === 'Intake' || role === 'SuperAdmin'); // Not Management
  const isSuperAdmin = role === 'SuperAdmin';
  const isSupportWorker = role === 'SupportWorker';
  const isManagement = role === 'Management';
  const isReportingOnly = role === 'Reporting';

  useEffect(() => {
    authAxios.get('/api/users/admin/my-user').then((response) => {
      setRole(response.data.role);
      setOfficeId(response.data.officeId);
      setLoaded(true);
    });
  }, []);

  return (
    <MyUserContext.Provider
      value={{
        loaded,
        role,
        officeId,
        isLegalAssistant,
        isLawyer,
        isIntakeOffice,
        isSuperAdmin,
        isSupportWorker,
        isManagement,
        isReportingOnly,
      }}
    >
      {props.children}
    </MyUserContext.Provider>
  );
};

export const MyUserConsumer = MyUserContext.Consumer;
