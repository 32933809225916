import React from 'react';
import { AppLayout } from './AppLayout';
import { AuthProvider } from './contexts/AuthContext';
import { LoadingModal } from './components/Loading/LoadingModal';

const App = () => {
  return (
    <AuthProvider>
      <div>
        <AppLayout />
        <LoadingModal />
      </div>
    </AuthProvider>
  );
};

export default App;
