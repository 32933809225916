import * as React from 'react';
import { useState } from 'react';

export interface ISupportWorkerRequestReminder {
  supportWorkerRequestId: string;
  reminderId: string;
  text: string;
  date?: number;
  staffToNotify: string[];
  notificationTypeIds: string[];
  resolved: boolean;
  reminderTypeId: string;
}

export interface IIntervention {
  supportWorkerRequestId: string;
  interventionId: string;
  interventionTypeId: string;
  details: string;
  interventionStatusId: string;
  noteIds: string[];
  reason?: string;
}

export interface ISupportWorkerRequest {
  clientId: string;
  supportWorkerRequestId: string;
  supportWorkerId: string;
  fileIds: string[];
  criteriaForAssistance: string[];
  assistanceTypes: string[];
  status: string;
  submittedDate: number;
  details: string;
  createdById: string;
  lastUpdated: number;
  requestHistory: ISupportWorkerRequestHistoryItem[];
  lawyerId: string;
  interventions: IIntervention[];
}

export interface ISupportWorkerRequestHistoryItem {
  supportWorkerId: string;
  status: string;
  reason?: string;
  auditUserId: string;
  auditTimestamp: number;
  completedDate?: number;
  incompleteDate?: number;
}

type ContextType = {
  supportWorkerRequests: ISupportWorkerRequest[];
  setSupportWorkerRequests: React.Dispatch<React.SetStateAction<ISupportWorkerRequest[]>>;

  supportWorkerRequest: ISupportWorkerRequest;
  setSupportWorkerRequest: React.Dispatch<React.SetStateAction<ISupportWorkerRequest>>;

  interventions: IIntervention[];
  setInterventions: React.Dispatch<React.SetStateAction<IIntervention[]>>;

  activeOnscreenSupportWorkerRequestReminders: ISupportWorkerRequestReminder[];
  setActiveOnscreenSupportWorkerRequestReminders: React.Dispatch<React.SetStateAction<ISupportWorkerRequestReminder[]>>;
};

export const SupportWorkerRequestContext = React.createContext<ContextType>({
  supportWorkerRequests: {} as ISupportWorkerRequest[],
  setSupportWorkerRequests: () => {},

  supportWorkerRequest: {} as ISupportWorkerRequest,
  setSupportWorkerRequest: () => {},

  interventions: {} as IIntervention[],
  setInterventions: () => {},

  activeOnscreenSupportWorkerRequestReminders: {} as ISupportWorkerRequestReminder[],
  setActiveOnscreenSupportWorkerRequestReminders: () => {},
});

export const SupportWorkerRequestProvider = (props: any) => {
  const [supportWorkerRequests, setSupportWorkerRequests] = useState<ISupportWorkerRequest[]>([]);
  const [supportWorkerRequest, setSupportWorkerRequest] = useState({} as ISupportWorkerRequest);
  const [interventions, setInterventions] = useState<IIntervention[]>([]);
  const [activeOnscreenSupportWorkerRequestReminders, setActiveOnscreenSupportWorkerRequestReminders] = useState<
    ISupportWorkerRequestReminder[]
  >([]);

  return (
    <SupportWorkerRequestContext.Provider
      value={{
        supportWorkerRequests,
        setSupportWorkerRequests,

        supportWorkerRequest,
        setSupportWorkerRequest,

        interventions,
        setInterventions,

        activeOnscreenSupportWorkerRequestReminders,
        setActiveOnscreenSupportWorkerRequestReminders,
      }}
    >
      {props.children}
    </SupportWorkerRequestContext.Provider>
  );
};

export const SupportWorkerRequestConsumer = SupportWorkerRequestContext.Consumer;
