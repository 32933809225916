import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Navbar, NavbarBrand } from 'reactstrap';
import { FaArrowDown, FaArrowUp, FaPlus } from 'react-icons/fa';
import { TablePagination } from '../TablePagination/TablePagination';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { Column, TableInstance, usePagination, useSortBy, useTable } from 'react-table';
import { authAxios } from '../../services/AxiosService';
import { useParams } from 'react-router';
import { IUser } from '../Users/UsersTable';
import { formatDateTime } from '../../formatters/DateTimeFormatter';
import { PeopleContext } from 'src/contexts/PeopleContext';
import { InterventionNotesDetails } from 'src/components/SupportRequests/InterventionNotesDetails';
import { useHistory, useLocation } from 'react-router-dom';
import { INote } from 'src/components/Files/FileNotes';
import { MyUserContext } from 'src/contexts/MyUserContext';
import { SUPPORT_REQUEST } from 'src/contexts/DomainContext';
import { SupportWorkerRequestContext } from 'src/components/SupportRequests/SupportWorkerRequestContext';

export const InterventionNotes: React.FC = () => {
  const params = useParams<{ supportWorkerRequestId: string; interventionId: string }>();
  const location = useLocation<{ noteToDelete?: string }>();
  const noteToDelete = location?.state?.noteToDelete;
  const peopleContext = useContext(PeopleContext);
  const myUserContext = useContext(MyUserContext);
  const { supportWorkerRequest } = useContext(SupportWorkerRequestContext);

  const users = peopleContext.staff;
  const [showNewNote, setShowNewNote] = useState(false);
  const [notes, setNotes] = useState<INote[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (params.interventionId) {
      authAxios
        .get(
          '/api/support-worker-requests/admin/' +
            params.supportWorkerRequestId +
            '/interventions/' +
            params.interventionId +
            '/notes'
        )
        .then((response) => {
          if (noteToDelete) {
            setNotes(response.data.notes.filter((n: INote) => n.noteId !== noteToDelete));
          } else {
            setNotes(response.data.notes);
          }
        });
    }
  }, [params.supportWorkerRequestId, params.interventionId, noteToDelete]);

  const columns: Column<INote>[] = useMemo(
    () => [
      {
        Header: 'Date Added',
        id: 'dateCreated',
        width: 80,
        accessor: 'dateCreated',
        Cell: (cell: any) => {
          if (cell && cell.row.original && cell.row.original.dateCreated) {
            return <span>{formatDateTime(new Date(cell.row.original.dateCreated))}</span>;
          } else {
            return <span />;
          }
        },
      },
      {
        Header: 'Text',
        accessor: 'text',
      },
      {
        Header: 'User',
        id: 'userCreated',
        width: 120,
        accessor: (f: INote) => {
          if (f.userCreated) {
            return users.find((l: IUser) => l.userId === f.userCreated)?.displayName;
          } else {
            return '';
          }
        },
      },
    ],
    [users]
  );

  const data: INote[] = useMemo(() => notes, [notes]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: TableInstance<INote> = useTable(
    {
      columns: columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        sortBy: [
          {
            id: 'dateCreated',
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    usePagination
  );

  const selectRow = (noteId: string) => {
    if (myUserContext.isSupportWorker) {
      history.push(
        '/support-worker-requests/' +
          params.supportWorkerRequestId +
          '/interventions/' +
          params.interventionId +
          '/notes/' +
          noteId
      );
    }
  };

  return (
    <Card>
      <div className={'no-print'}>
        <Navbar
          color={'light'}
          light={true}
          expand={'xs'}
          className={'border-bottom d-flex flex-wrap sticky-top'}
          style={{ zIndex: 0 }}
        >
          <NavbarBrand className={'w-mobile-100'}>Notes</NavbarBrand>
          {!showNewNote && supportWorkerRequest.status === SUPPORT_REQUEST.ACCEPTED && (
            <div
              className={'d-flex flex-grow-1 justify-content-end flex-wrap navbar-actions'}
              style={{ rowGap: '0.5rem', columnGap: '0.5rem' }}
            >
              <Button color={'primary'} className={'w-mobile-100'} onClick={() => setShowNewNote(true)}>
                <FaPlus /> New Note
              </Button>
            </div>
          )}
        </Navbar>

        {showNewNote && (
          <InterventionNotesDetails
            setNotes={setNotes}
            onSave={() => setShowNewNote(false)}
            onCancel={() => setShowNewNote(false)}
          />
        )}
        <table className={'table table-bordered'} {...getTableProps()}>
          <thead style={{ overflow: 'visible' }} className={'no-print'}>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`interventions-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={`interventions-table-thead-tr-${index}-${column.id}`}
                    width={column.width}
                    style={{ maxWidth: column.width }}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    {column.isSorted &&
                      (column.isSortedDesc ? <FaArrowDown className={'ml-2'} /> : <FaArrowUp className={'ml-2'} />)}
                    <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }} id={'table-body'}>
            {page.length === 0 && <tr style={{ display: 'block', padding: '6px' }}>No records</tr>}
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={`intervention-table-tr-${row.id}`}
                  className={row.original.userCreated ? 'bg-primary-light' : ''}
                >
                  {row.cells.map((cell: any, index: number) => {
                    return (
                      <td
                        key={`intervention-table-td-${cell.row.id}-${cell.column.id}`}
                        colSpan={index === row.cells.length - 1 ? 2 : 1}
                        onClick={() => selectRow(row.original.noteId)}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <TablePagination
          pageCount={pageCount}
          pageOptions={pageOptions}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageSizes={[20, 50, 100, 500]}
        />
      </div>
    </Card>
  );
};
