import * as React from 'react';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  Row,
} from 'reactstrap';
import {
  DomainContext,
  IRelationshipType,
  STAGE_COUNSEL_REQUEST,
  STAGE_COUNSEL_REQUEST_DRAFT,
} from '../../contexts/DomainContext';
import { FileContext, IFile, IThirdParty } from './FileContext';
import Select, { components, OptionProps, SingleValueProps } from 'react-select';
import { IIndividual } from '../Individuals/IndividualContext';
import { FaTimes } from 'react-icons/all';
import { IndividualDetails } from '../Individuals/IndividualDetails';
import { clientSelectValue } from '../../formatters/PeopleFormatter';
import { IndividualsContext } from '../../contexts/IndividualsContext';
import { MyUserContext } from '../../contexts/MyUserContext';

interface IProps {
  trySubmit: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  isEditingAllowed: boolean;
  counselRequest: boolean;
}

export const FileThirdParties = (props: IProps) => {
  const fileContext = useContext(FileContext);
  const thirdParties = fileContext.thirdParties;
  const myUserContext = useContext(MyUserContext);

  const individualsContext = useContext(IndividualsContext);
  const individuals = individualsContext.state.individuals;

  const [modal, setModal] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const [addIndividualId, setAddIndividualId] = useState<string | undefined>(undefined);
  const [addRelationshipTypeId, setAddRelationshipTypeId] = useState<string | undefined>(undefined);
  const domainContext = useContext(DomainContext);
  const relationships = domainContext.relationshipTypes;
  const [thirdPartyInputValue, setThirdPartyInputValue] = useState('');

  const addThirdParty = () => {
    if (addIndividualId && addRelationshipTypeId) {
      fileContext.setThirdParties((e) => [
        ...e,
        { individualId: addIndividualId, relationshipTypeId: addRelationshipTypeId } as IThirdParty,
      ]);
      setAddIndividualId(undefined);
      setAddRelationshipTypeId(undefined);
      setShowForm(false);
      props.setIsDirty(true);
    }
  };

  const Option = (props: OptionProps<IIndividual, boolean>) => {
    const { data } = props;
    return <components.Option {...props}>{clientSelectValue(data)}</components.Option>;
  };

  const SingleValue = (props: SingleValueProps<IIndividual>) => {
    const { data } = props;
    return <components.SingleValue {...props}>{clientSelectValue(data)}</components.SingleValue>;
  };

  const displayIndividual = (individual?: IIndividual) => {
    if (individual) {
      return clientSelectValue(individual);
    } else {
      return '';
    }
  };
  return (
    <>
      <Card className={'mb-3'}>
        <CardHeader className={'d-flex align-items-center'}>
          Third Parties
          {props.isEditingAllowed && (
            <div className={'card-header__actions'}>
              <Button
                color={'primary'}
                className='btn-sm'
                onClick={() => {
                  setShowForm(true);
                }}
                disabled={showForm}
              >
                Add Third Party
              </Button>
            </div>
          )}
        </CardHeader>
        {showForm &&
          ((fileContext.file.stageId &&
            fileContext.file.stageId !== STAGE_COUNSEL_REQUEST_DRAFT &&
            fileContext.file.stageId !== STAGE_COUNSEL_REQUEST) ||
            (fileContext.file.stageId === undefined && !props.counselRequest) ||
            myUserContext.isSuperAdmin) && (
            <CardBody className={'border-bottom pb-3'}>
              <div className={'d-flex flex-column'}>
                <Select
                  id={'add-third-party-individual-id'}
                  className={'flex-fill'}
                  classNamePrefix={'people-select'}
                  name={'clientId'}
                  inputValue={thirdPartyInputValue}
                  onInputChange={(e) => setThirdPartyInputValue(e)}
                  options={
                    !!thirdPartyInputValue && thirdPartyInputValue.length >= 3
                      ? individuals.filter(
                          (e) => e.searchString?.includes(thirdPartyInputValue.toUpperCase()) && e.active
                        )
                      : []
                  }
                  value={individuals.find((i: IIndividual) => i.individualId === addIndividualId)}
                  onChange={(value: any) => {
                    setAddIndividualId(value?.individualId || undefined);
                  }}
                  getOptionValue={(option: IIndividual) => option.individualId}
                  getOptionLabel={(o: IIndividual) => `${o.searchString}`}
                  isClearable={true}
                  placeholder={'Select Individual...'}
                  components={{ SingleValue, Option }}
                  noOptionsMessage={() =>
                    !!thirdPartyInputValue && thirdPartyInputValue.length >= 3
                      ? 'No individuals match your search. Add a new individual or try again.'
                      : 'Enter at least 3 characters to search.'
                  }
                  styles={{
                    singleValue: (base) => ({
                      ...base,
                      position: 'relative',
                      top: 0,
                      transform: 'translateY(0)',
                      height: '100%',
                      padding: '0.25em 0',
                    }),
                  }}
                  menuPlacement={'auto'}
                />
                <p className={'text-muted mb-3 mt-1 ml-1'}>
                  or{' '}
                  <Button
                    color='link'
                    onClick={() => setModal(true)}
                    aria-label={'Add New Individual'}
                    className={'p-0 border-0 align-baseline'}
                  >
                    Add New Individual
                  </Button>
                </p>
                <Select
                  id={'add-relationshipType-id'}
                  key={`add-individual-id-${addIndividualId}`} //TODO fix this hack
                  name={'add-relationshipType-id'}
                  options={relationships}
                  getOptionLabel={(option: IRelationshipType) => `${option.name}`}
                  getOptionValue={(option: IRelationshipType) => option.relationshipTypeId}
                  isMulti={false}
                  isClearable={true}
                  placeholder={'Select Relationship...'}
                  value={relationships.find((i: IRelationshipType) => i.relationshipTypeId === addRelationshipTypeId)}
                  onChange={(sel: any) => {
                    setAddRelationshipTypeId(sel?.relationshipTypeId || undefined);
                  }}
                  menuPlacement={'auto'}
                />
                <div className={'d-flex flex-row-reverse justify-content-start mt-3'}>
                  <Button color='primary' onClick={() => addThirdParty()}>
                    Add
                  </Button>
                  <Button color={'link'} onClick={() => setShowForm(false)}>
                    Cancel
                  </Button>
                </div>
              </div>
            </CardBody>
          )}
        {thirdParties.length > 0 && (
          <ListGroup flush={true}>
            {thirdParties.map((thirdParty: IThirdParty, index) => (
              <ListGroupItem key={`thirdparty-listgroupitem-${index}`}>
                <Row className={'align-items-center'}>
                  <Col>
                    {displayIndividual(
                      individuals.find((i: IIndividual) => i.individualId === thirdParty.individualId)
                    )}
                  </Col>
                  <Col className={'d-flex align-items-center'}>
                    <Input
                      style={{ backgroundColor: props.isEditingAllowed ? 'white' : '#f2f2f2' }}
                      value={thirdParty.relationshipTypeId}
                      type={'select'}
                      name={'Third Parties Relationship'}
                      className={'custom-select'}
                      disabled={!props.isEditingAllowed}
                      onChange={(e: any) => {
                        const newRelationship = e.target.value;
                        fileContext.setThirdParties((tp) => [
                          ...tp.slice(0, index),
                          { ...tp[index], relationshipTypeId: newRelationship } as IThirdParty,
                          ...tp.slice(index + 1),
                        ]);
                      }}
                      invalid={props.trySubmit && thirdParties.length === 0}
                    >
                      {relationships.length === 0 ? (
                        <option>No Relationships to Select From</option>
                      ) : (
                        relationships.map((option: IRelationshipType) => (
                          <option key={option.relationshipTypeId} value={option.relationshipTypeId}>
                            {option.name}
                          </option>
                        ))
                      )}
                    </Input>
                    {props.isEditingAllowed && (
                      <Button
                        color={'link'}
                        className={'pr-0 text-danger'}
                        onClick={() => {
                          props.setIsDirty(true);
                          fileContext.setThirdParties((tp) => [...tp.slice(0, index), ...tp.slice(index + 1)]);
                        }}
                      >
                        <FaTimes />
                      </Button>
                    )}
                  </Col>
                </Row>
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
        {((fileContext.file.stageId === STAGE_COUNSEL_REQUEST_DRAFT &&
          (showForm || fileContext.file.thirdPartyCounselRequest)) ||
          (fileContext.file.stageId === undefined &&
            props.counselRequest &&
            (showForm || (myUserContext.isSuperAdmin && fileContext.file.thirdPartyCounselRequest))) ||
          (fileContext.file.stageId &&
            fileContext.file.stageId !== STAGE_COUNSEL_REQUEST_DRAFT &&
            fileContext.file.thirdPartyCounselRequest)) && (
          <CardBody className={'border-bottom '}>
            <Row>
              <Col className={'d-flex'} style={{ flexDirection: 'column' }}>
                <Label for={'third-party-details'}>Counsel Request Third Parties</Label>
                <Input
                  value={fileContext.file.thirdPartyCounselRequest}
                  disabled={fileContext.file.isLockedDown || !props.isEditingAllowed}
                  type={'textarea'}
                  className={''}
                  name={'third-party-details'}
                  id={'third-party-details'}
                  style={{ minHeight: '100px' }}
                  placeholder={'Enter names and relationships to the client here...'}
                  onChange={(e: any) => {
                    const value = e.target.value;
                    props.setIsDirty(true);
                    fileContext.setFile((f: IFile) => ({ ...f, thirdPartyCounselRequest: value }));
                  }}
                />
              </Col>
              {!props.counselRequest && props.isEditingAllowed && myUserContext.isSuperAdmin && (
                <Col style={{ width: '32px', maxWidth: '32px' }} className={'px-0'}>
                  <Button
                    color={'link'}
                    className={'px-0 text-danger'}
                    onClick={() => {
                      props.setIsDirty(true);
                      fileContext.setFile((f: IFile) => ({ ...f, thirdPartyCounselRequest: undefined }));
                    }}
                  >
                    <FaTimes />
                  </Button>
                </Col>
              )}
            </Row>
          </CardBody>
        )}
      </Card>
      <Modal isOpen={modal} style={{ width: '60rem', maxWidth: '60rem' }}>
        <ModalBody style={{ padding: 0, borderRadius: '0.3rem' }}>
          <IndividualDetails
            createCompleteAction={(individual: IIndividual) => {
              setAddIndividualId(individual.individualId);
              setModal(false);
            }}
            cancel={() => setModal(false)}
            hideDetails={true}
            allowCreateFile={false}
          />
        </ModalBody>
      </Modal>
    </>
  );
};
