import * as React from 'react';
import { useContext, useState } from 'react';
import { Button, Col, Container, Nav, Navbar, NavbarBrand, Row } from 'reactstrap';
import { DutyCounselAppearances } from './DutyCounselAppearances';
import { authAxios } from '../../services/AxiosService';
import { FileContext } from './FileContext';
import { notifyError, notifySuccess } from '../../services/ToastService';

export const DutyCounselAppearancesPage: React.FC = () => {
  const fileContext = useContext(FileContext);
  const file = fileContext.file;

  const [trySubmit, setTrySubmit] = useState(false);

  const saveDutyCounselAppearances = () => {
    console.log(!fileContext.dutyCounselAppearances.some((e) => !e.dutyCounselAppearanceDate || !e.lawyerId));
    if (
      fileContext.dutyCounselAppearances.some(
        (e) => !e.dutyCounselAppearanceDate || !e.dutyCounselCourtTypeId || !e.lawyerId
      )
    ) {
      setTrySubmit(true);
    } else {
      setTrySubmit(false);
      const data = { fileId: fileContext.file.fileId, dutyCounselAppearances: fileContext.dutyCounselAppearances };
      authAxios.post('/api/files/admin/update-duty-counsel-appearances', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('Duty Counsel Appearances have been saved.');
        } else {
          notifyError(response.data.status);
        }
      });
    }
  };

  return (
    <div>
      <div className='d-print-none'>
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom'}>
          <NavbarBrand>Duty Counsel Appearances</NavbarBrand>
          <Nav className={'ml-auto'}>
            <Col className={'d-flex'}>
              {!file.isLockedDown && (
                <Button color='success' className={'ml-auto'} onClick={() => saveDutyCounselAppearances()}>
                  Save Details
                </Button>
              )}
            </Col>
          </Nav>
        </Navbar>
      </div>
      <Container fluid={true} className={'my-3 container--no-margin-print'}>
        <Row>
          <Col>
            <DutyCounselAppearances trySubmit={trySubmit} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
