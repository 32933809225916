import * as React from 'react';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import { useContext } from 'react';
import { FileContext, IChild } from './FileContext';
import { FaTimes } from 'react-icons/all';

interface IProps {
  trySubmit: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  isEditingAllowed: boolean;
}

export const FileChildren = (props: IProps) => {
  const fileContext = useContext(FileContext);
  const [showForm, setShowForm] = useState(false);
  const [newChildName, setNewChildName] = useState();
  const [childBirthDate, setChildBirthDate] = useState<Date | undefined>();

  const addChild = () => {
    if (newChildName && childBirthDate) {
      fileContext.setChildren((c) =>
        [{ name: newChildName, birthDate: childBirthDate.getTime() } as IChild].concat(c ? c : [])
      );
      setNewChildName(undefined);
      setChildBirthDate(undefined);
      setShowForm(false);
      props.setIsDirty(true);
    }
  };

  const cancel = () => {
    setNewChildName(undefined);
    setChildBirthDate(undefined);
    setShowForm(false);
  };

  return (
    <Card className={'mb-3'}>
      <CardHeader className={'d-flex align-items-center'}>
        Children
        {props.isEditingAllowed && (
          <div className={'card-header__actions'}>
            <Button
              color={'primary'}
              className='btn-sm'
              onClick={() => {
                setShowForm(true);
              }}
              disabled={showForm}
            >
              Add Child
            </Button>
          </div>
        )}
      </CardHeader>
      {showForm && (
        <CardBody className={'border-bottom pb-3'}>
          <div className={'d-flex flex-row'}>
            <FormGroup className={'flex-fill'}>
              <Label>Name</Label>
              <Input
                value={newChildName}
                type={'text'}
                name={'childName'}
                id={'childName'}
                onChange={(e: any) => {
                  props.setIsDirty(true);
                  setNewChildName(e.target.value);
                }}
              />
            </FormGroup>
            <FormGroup className={'flex-fill ml-2'}>
              <Label>Birth Date</Label>
              <DatePicker
                selected={childBirthDate}
                onChange={(date) => {
                  if (date) {
                    props.setIsDirty(true);
                    setChildBirthDate(date);
                  }
                }}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dateFormat='MMM dd, yyyy'
                dropdownMode='select'
                className='date-picker rounded mb-0'
                placeholderText={'Select...'}
              />
            </FormGroup>
            <Button
              className={'w-10 ml-2 mr-2'}
              style={{ height: '38px', top: '27px', position: 'relative' }}
              color={'link'}
              onClick={() => cancel()}
            >
              Cancel
            </Button>
            <Button
              className={'w-10 ml-2 mr-2'}
              style={{ height: '38px', top: '27px', position: 'relative' }}
              color='primary'
              onClick={() => addChild()}
            >
              Add
            </Button>
          </div>
        </CardBody>
      )}
      {fileContext.children && fileContext.children.length > 0 && (
        <ListGroup flush={true}>
          {fileContext.children.map((child: IChild, index) => (
            <ListGroupItem key={`thirdparty-listgroupitem-${index}`}>
              <Row className={'align-items-center'}>
                <Col>
                  <Input
                    type='text'
                    value={child.name}
                    onChange={(e: any) => {
                      props.setIsDirty(true);
                      const name = e.target.value;
                      fileContext.setChildren((c) => [
                        ...c.slice(0, index),
                        { ...c[index], name: name } as IChild,
                        ...c.slice(index + 1),
                      ]);
                    }}
                    disabled={!props.isEditingAllowed}
                  />
                </Col>
                <Col>
                  <DatePicker
                    selected={new Date(child.birthDate)}
                    onChange={(date) => {
                      if (date) {
                        props.setIsDirty(true);
                        fileContext.setChildren((c) => [
                          ...c.slice(0, index),
                          { ...c[index], birthDate: date.getTime() } as IChild,
                          ...c.slice(index + 1),
                        ]);
                      }
                    }}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dateFormat='MMM dd, yyyy'
                    dropdownMode='select'
                    className={
                      props.isEditingAllowed ? 'date-picker rounded mb-0' : 'date-picker-disabled rounded mb-0'
                    }
                    placeholderText={'Select...'}
                    disabled={!props.isEditingAllowed}
                  />
                </Col>
                {props.isEditingAllowed && (
                  <Button
                    color={'link'}
                    className={'pl-0 text-danger'}
                    onClick={() => {
                      props.setIsDirty(true);
                      fileContext.setChildren((c) => [...c.slice(0, index), ...c.slice(index + 1)]);
                    }}
                  >
                    <FaTimes />
                  </Button>
                )}
              </Row>
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </Card>
  );
};
