import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, ListGroup, ListGroupItem, Row } from 'reactstrap';
import * as React from 'react';
import { useContext, useState } from 'react';
import { FileContext, IExtensionRequest, REMINDER_TYPE_FILE_EXPIRY } from '../FileContext';
import { RequiredLabel } from '../../RequiredLabel';
import { authAxios } from '../../../services/AxiosService';
import { formatDateTime } from '../../../formatters/DateTimeFormatter';
import { notifySuccess } from '../../../services/ToastService';
import { useParams } from 'react-router';

export const ExtensionRequest = () => {
  const fileContext = useContext(FileContext);
  const [reason, setReason] = useState('');
  const params = useParams<{ fileId: string }>();

  const hasPendingRequest = () => {
    let hasPending = false;
    fileContext.extensionRequests.forEach((er) => {
      if (er.approved === undefined) {
        hasPending = true;
      }
    });
    return hasPending;
  };

  const submitExtension = () => {
    if (reason !== '') {
      authAxios
        .post('/api/files/admin/submit-extension', {
          fileId: params.fileId,
          reason: reason,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            notifySuccess('Extension Requested.');
            fileContext.setExtensionRequests((s) => [
              ...s,
              {
                extensionRequestId: response.data.extensionRequestId,
                reasonForExtension: reason,
                approved: undefined,
                reasonForRejection: undefined,
                timestamp: new Date().getTime(),
              } as IExtensionRequest,
            ]);
            setReason('');
            fileContext.setActiveOnscreenReminders((s) =>
              s.filter((s) => s.reminderTypeId !== REMINDER_TYPE_FILE_EXPIRY)
            );
          }
        });
    }
  };

  return (
    <Card className={'bg-primary-light'} style={{ width: '100%' }}>
      <CardHeader>Extension Requests</CardHeader>
      <CardBody>
        <Row>
          <Col sm={10}>
            <FormGroup className={'mr-2'}>
              <RequiredLabel for={'application-date'} required={fileContext.appDate === undefined}>
                Reason for Extension
              </RequiredLabel>
              <Input
                type='text'
                name='extensionReason'
                id='extensionReason'
                placeholder={'Add reason here...'}
                value={reason}
                onChange={(e: any) => {
                  setReason(e.target.value);
                }}
                disabled={fileContext.file.isLockedDown || hasPendingRequest()}
              />
            </FormGroup>
          </Col>
          <Col sm={2} className={'pl-0'}>
            <Button
              color='primary'
              className={'mb-2'}
              style={{ marginTop: '27px' }}
              onClick={() => {
                submitExtension();
              }}
              disabled={fileContext.file.isLockedDown || hasPendingRequest()}
            >
              Request Extension
            </Button>
          </Col>
        </Row>
        {fileContext.extensionRequests.length > 0 && (
          <Row>
            <ListGroup flush={true} className={'mx-3 w-100'}>
              <ListGroupItem style={{ border: '1px solid lightgrey' }}>
                <Row>
                  <Col>Reason</Col>
                  <Col>Date Requested</Col>
                  <Col>Approval</Col>
                </Row>
              </ListGroupItem>
              {fileContext.extensionRequests.map((er) => {
                return (
                  <ListGroupItem
                    key={er.extensionRequestId}
                    style={{
                      borderBottom: '1px solid lightgrey',
                      borderLeft: '1px solid lightgrey',
                      borderRight: '1px solid lightgrey',
                    }}
                  >
                    <Row>
                      <Col>{er.reasonForExtension}</Col>
                      <Col>{formatDateTime(new Date(er.timestamp))}</Col>
                      <Col>
                        {er.approved === undefined
                          ? 'Pending'
                          : er.approved
                          ? 'Approved to: ' + (er.newExpiryDate ? er.newExpiryDate : '')
                          : 'Denied: ' + er.reasonForRejection}
                      </Col>
                    </Row>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};
