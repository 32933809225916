import * as React from 'react';
import { useEffect, useState } from 'react';
import { authAxios } from '../services/AxiosService';
import { IOffence } from '../components/Offences/OffenceTable';
import { IIncomeType, ILivingArrangement, IMaritalStatus, IPaymentFrequency } from '../components/Files/FileContext';

export const STAGE_PENDING = 'e1695623-a138-4992-8a1c-ba9e747c982c';
export const STAGE_READY_FOR_REVIEW = '32355ea0-0c1c-44c7-b70c-faa2dbeb76cb';
export const STAGE_APPROVED = '335ef874-e505-432c-9cd5-3b0c74d4729b';
export const STAGE_REFUSED = '5b1396a3-0998-48a2-b0e1-1ad3c08706cd';
export const STAGE_BOARD_REVIEW = '4457abc0-8f74-41a1-9dd6-42415caee16e';
export const STAGE_BOARD_APPROVED = '55304cae-28f1-4039-8836-23d506d6dc8d';
export const STAGE_BOARD_REFUSED = '45270624-88d5-4b6f-846d-31c2e9d5b905';
export const STAGE_LAWYER_REQUESTED = '40d82907-a55c-4596-89ab-67d25a627302';
export const STAGE_RETURN_FOR_CONFLICT = '6c2fe17e-1c33-46e9-9fb5-84e2066a911f';
export const STAGE_ASSIGNED_TO_LAWYER = '3846086c-34c4-42d1-ba56-77b5db45c070';
export const STAGE_CLOSED = '58c6b2a2-2526-4332-98dd-83f623c5dfb5';
export const STAGE_STOPPED = '53748a1b-7e2b-409f-bf82-392ee3b72299';
export const STAGE_DUTY_COUNSEL = 'd38193d2-96d7-4c7a-b8e6-75dc61118774';
export const STAGE_COUNSEL_REQUEST_DRAFT = 'd4e08577-475e-4d4f-8a34-365358b33109';
export const STAGE_COUNSEL_REQUEST = 'c231f6ac-9bf9-433f-8bc1-1a890181df56';
export const STAGE_CANCELLED_COUNSEL_REQUEST = '6d162cbc-1825-49c4-a13b-0ff164f6dc7e';
export const STAGE_ERROR = '8318cf59-6c0f-446c-b0e4-3e6933411868';
export const editableStages = [STAGE_PENDING, STAGE_DUTY_COUNSEL, STAGE_COUNSEL_REQUEST_DRAFT, STAGE_COUNSEL_REQUEST];
export const defaultHiddenStagesIntake = [
  STAGE_REFUSED,
  STAGE_COUNSEL_REQUEST_DRAFT,
  STAGE_BOARD_REFUSED,
  STAGE_CLOSED,
  STAGE_STOPPED,
  STAGE_ERROR,
];

export const alwaysHiddenStagesIntake = [STAGE_COUNSEL_REQUEST_DRAFT];
export const defaultStagesLawOfficeNoClosed = [
  STAGE_COUNSEL_REQUEST_DRAFT,
  STAGE_COUNSEL_REQUEST,
  STAGE_LAWYER_REQUESTED,
  STAGE_ASSIGNED_TO_LAWYER,
];
export const defaultStagesLawOfficeWithClosed = [
  STAGE_COUNSEL_REQUEST_DRAFT,
  STAGE_COUNSEL_REQUEST,
  STAGE_LAWYER_REQUESTED,
  STAGE_ASSIGNED_TO_LAWYER,
  STAGE_CLOSED,
];

export namespace SUPPORT_REQUEST {
  export const PENDING = 'Pending';
  export const SUBMITTED = 'Submitted';
  export const ACCEPTED = 'Accepted';
  export const REASSIGNED = 'Reassigned';
  export const REJECTED = 'Rejected';
  export const COMPLETED = 'Completed';
  export const INCOMPLETE = 'Incomplete';

  export const STATES: Array<{ value: string; label: string; order: number }> = [
    { value: PENDING, label: PENDING, order: 1 },
    { value: SUBMITTED, label: SUBMITTED, order: 2 },
    { value: REJECTED, label: REJECTED, order: 3 },
    { value: REASSIGNED, label: REASSIGNED, order: 4 },
    { value: ACCEPTED, label: ACCEPTED, order: 5 },
    { value: COMPLETED, label: COMPLETED, order: 6 },
    { value: INCOMPLETE, label: INCOMPLETE, order: 7 },
  ];
}

export const supportWorkerVisibleStagesWithClosed = [STAGE_ASSIGNED_TO_LAWYER, STAGE_CLOSED];
export const supportWorkerVisibleStagesNoClosed = [STAGE_ASSIGNED_TO_LAWYER];

export const NOTIFICATION_TYPE_EMAIL = '4d7fba47-fad6-4a5f-971a-f8c53eccaec7';
export const NOTIFICATION_TYPE_ONSCREEN = 'c0aad959-c735-4334-85e8-225fcc6cc4c4';

export const CHARGE_OUTCOME_INCOMPLETE = '1e1703ab-70dc-4643-8129-52afee277463';
export const CHARGE_OUTCOME_SOP_ACQUITTAL = '63ec626e-ac2f-43c8-870b-35910e388f8a';

export const FILE_TYPE_DUTY_COUNSEL = 'f0ec6294-a2a0-4e27-b4ae-21015d4ea029';

export const GENDER_MALE = 'a6b92401-519e-4ad1-8119-2705a9c01d0c';
export const GENDER_FEMALE = '4b5b6dd1-e169-41df-8b12-7e4ddc268231';
export const GENDER_OTHER = '26fbd87e-4d4b-448c-9eaa-67b71510c871';
export const GENDER_NONE = 'NO_GENDER';

export const APPLICATION_TYPE_ID_ADULT_CRIMINAL = '5ed8bcb6-87e3-42d6-9aea-3ffa3939391a';
export const APPLICATION_TYPE_ID_YOUTH_CRIMINAL = '2a4a2269-40a4-4718-9884-da2d51c0d5ab';
export const APPLICATION_TYPE_ID_CIVIL_FAMILY = 'f0c30b77-1c5c-45bf-a87f-fb981e234013';
export const APPLICATION_TYPE_ID_POVERTY_LAW = '308e30f5-66bb-4117-8065-7c153beebeed';
export const APPLICATION_TYPE_ID_CHILD_PROTECTION = '6f088973-47f3-4b54-98ca-a35ed221a602';
export const APPLICATION_TYPE_ID_TERRITORIAL_STATUTES = '453b9a8f-fc9d-4984-8cdc-726fd5d46fed';
export const APPLICATION_TYPE_ID_CIVIL_OTHER = 'd29bfcdc-ed5a-478f-877e-076a76000290';

export const DUTY_COUNSEL_COURT_TYPE_CWC = 'daa5f77c-174f-4c2e-a94d-479faa3bc959';
export const DUTY_COUNSEL_COURT_TYPE_DVTO = '2cad6f08-4633-4a90-a32b-2d318433b5f3';
export const DUTY_COUNSEL_COURT_TYPE_YOUTH = 'a5b16866-7767-4b53-82fd-fbd50bb689b1';

export const LAWYER_TIME_TYPE_CWC = '63ddab0f-2ff3-40f0-80ae-2ed064da62a9';
export const LAWYER_TIME_TYPE_DVTO = 'd450d1b8-ebc1-4306-8144-eda554e4cb3';
export const LAWYER_TIME_TYPE_YOUTH = '3954e5ad-5e6b-4e82-9b71-61f205cc78ef';

export const GENERIC_TIME_TYPE_DC_BAIL = 'cb3442ee-6995-4013-a039-9108b8b1604b';
export const GENERIC_TIME_TYPE_DC_CHAMBERS = '8b0f47bf-07bf-497f-a011-175e46756395';
export const GENERIC_TIME_TYPE_DC_CHILD_PROTECTION = 'ec6740a3-25fa-40b5-b289-986fcea0226a';
export const GENERIC_TIME_TYPE_DC_CWC = 'afedbe99-4252-4539-8ef5-eede69f3a904';
export const GENERIC_TIME_TYPE_DC_DOCKET = '254a709f-f176-40aa-ba19-f93710d09a6b';
export const GENERIC_TIME_TYPE_DC_DVTO = 'b0e4f6f9-a485-4190-8abc-a58b6d70f8a0';
export const GENERIC_TIME_TYPE_DC_GENERAL_PREP = 'e0e387ce-eebb-489a-a842-87b8157ab902';
export const GENERIC_TIME_TYPE_DC_YOUTH = '238f7002-f735-493d-8d54-39f85e0a109f';
export const GENERIC_TIME_TYPE_DC_TRAVEL = 'c0dc35bd-453d-42aa-b554-7fb9a164a558';
export const GENERIC_TIME_TYPE_DC_CIRCUIT = 'b8870b84-71b5-4e6b-9a44-8d0acd8bb19e';

export const OFFICE_INTAKE = '70311ff3-d930-43ca-879a-5c96148ff1da';
export const OFFICE_PRIVATE_LAWYER = '119e0701-eaa6-4741-92e7-67f61e3e82be';
export const OFFICE_SUPPORT_WORKER = 'ec2c45f4-96f6-4880-a173-22fcd0ce0850';

export const SUPPORT_WORKER_TYPE_ICW = '1966f599-2ff7-4d56-a589-5e1fc6211066';
export const SUPPORT_WORKER_TYPE_CC = '0919b2c9-a4fe-48b6-a265-65e2593d7251';

export const INTERVENTION_STATUS_IN_PROGRESS = '8909a558-c5dd-4d1e-b764-90b24d9f4883';
export const INTERVENTION_STATUS_INCOMPLETE = 'b5c32111-b97d-424c-afd1-ac7682790721';

export const TIME_TRACKING_ACTIVITY_INTERVENTION = '1ac70e6f-b59a-434a-ac9d-10c6ce481e49';

export const disabledSelectStyles = {
  control: (styles: any, state: any) => {
    return {
      ...styles,
      borderColor: '#ced4da',
    };
  },
  singleValue: (styles: any, state: any) => {
    return {
      ...styles,
      color: '#495057',
    };
  },
};

export interface IDutyCounselCourtType {
  dutyCounselCourtTypeId: string;
  name: string;
}

export interface INotificationType {
  notificationTypeId: string;
  name: string;
}

export interface IOffice {
  officeId: string;
  name: string;
  email: string;
  phone: string;
  active: boolean;
}

export interface IApplicationType {
  applicationTypeId: string;
  name: string;
  active: boolean;
}

export interface IFileType {
  fileTypeId: string;
  name: string;
}

export interface IReason {
  reasonId: string;
  name: string;
}

export interface IStage {
  stageId: string;
  name: string;
  order: number;
}

export interface IRelationshipType {
  relationshipTypeId: string;
  name: string;
}

export interface ITimeTrackingActivity {
  timeTrackingActivityId: string;
  name: string;
  active: boolean;
  activityType: 'File' | 'Generic' | 'Intervention';
  userRole: string;
  requiresDescription: boolean;
}

export interface IGender {
  genderId: string;
  name: string;
}

export interface ICircuitLocation {
  circuitLocationId: string;
  name: string;
}

export interface IOffenceCategoryType {
  categoryTypeId: string;
  applicationTypes: string[];
  name: string;
}

export interface IYukonFirstNation {
  yukonFirstNationId: string;
  name: string;
}

export interface IDocumentType {
  documentTypeId: string;
  name: string;
}

export interface ISupportWorkerType {
  supportWorkerTypeId: string;
  name: string;
}

export interface ISupportRequestCriteria {
  supportRequestCriteriaId: string;
  name: string;
}

export interface ISupportRequestAssistanceType {
  supportRequestAssistanceTypeId: string;
  name: string;
}

export interface IInterventionStatus {
  interventionStatusId: string;
  name: string;
}

export interface IInterventionType {
  interventionTypeId: string;
  name: string;
}

type ContextType = {
  loaded: boolean;
  offices: IOffice[];
  setOffices: React.Dispatch<React.SetStateAction<IOffice[]>>;
  applicationTypes: IApplicationType[];
  fileTypes: IFileType[];
  stages: IStage[];
  relationshipTypes: IRelationshipType[];
  roles: string[];
  offences: IOffence[];
  reasons: IReason[];
  timeTrackingActivities: ITimeTrackingActivity[];
  circuitLocations: ICircuitLocation[];

  maritalStatuses: IMaritalStatus[];
  livingArrangements: ILivingArrangement[];
  incomeTypes: IIncomeType[];
  paymentFrequencies: IPaymentFrequency[];
  genders: IGender[];
  notificationTypes: INotificationType[];
  dutyCounselCourtTypes: IDutyCounselCourtType[];
  yukonFirstNations: IYukonFirstNation[];
  documentTypes: IDocumentType[];
  supportWorkerTypes: ISupportWorkerType[];
  supportRequestCriteria: ISupportRequestCriteria[];
  supportRequestAssistanceTypes: ISupportRequestAssistanceType[];

  interventionStatuses: IInterventionStatus[];
  interventionTypes: IInterventionType[];
};

export const DomainContext = React.createContext<ContextType>({
  loaded: false,
  offices: [] as IOffice[],
  setOffices: () => {},
  applicationTypes: [] as IApplicationType[],
  fileTypes: [] as IFileType[],
  stages: [] as IStage[],
  relationshipTypes: [] as IRelationshipType[],
  roles: [] as string[],
  offences: [] as IOffence[],
  reasons: [] as IReason[],
  timeTrackingActivities: [] as ITimeTrackingActivity[],
  circuitLocations: [] as ICircuitLocation[],

  maritalStatuses: [] as IMaritalStatus[],
  livingArrangements: [] as ILivingArrangement[],
  incomeTypes: [] as IIncomeType[],
  paymentFrequencies: [] as IPaymentFrequency[],
  genders: [] as IGender[],
  notificationTypes: [] as INotificationType[],
  dutyCounselCourtTypes: [] as IDutyCounselCourtType[],
  yukonFirstNations: [] as IYukonFirstNation[],
  documentTypes: [] as IDocumentType[],
  supportWorkerTypes: [] as ISupportWorkerType[],
  supportRequestCriteria: [] as ISupportRequestCriteria[],
  supportRequestAssistanceTypes: [] as ISupportRequestAssistanceType[],

  interventionStatuses: [] as IInterventionStatus[],
  interventionTypes: [] as IInterventionType[],
});

export const DomainProvider = (props: any) => {
  const [offices, setOffices] = useState<IOffice[]>([]);
  const [applicationTypes, setApplicationTypes] = useState<IApplicationType[]>([]);
  const [fileTypes, setFileTypes] = useState<IFileType[]>([]);
  const [stages, setStages] = useState<IStage[]>([]);
  const [relationshipTypes, setRelationshipTypes] = useState<IRelationshipType[]>([]);
  const [roles, setRoles] = useState([]);
  const [offences, setOffences] = useState<IOffence[]>([]);
  const [reasons, setReasons] = useState([]);
  const [maritalStatuses, setMaritalStatuses] = useState<IMaritalStatus[]>([]);
  const [livingArrangements, setLivingArrangements] = useState<ILivingArrangement[]>([]);
  const [incomeTypes, setIncomeTypes] = useState<IIncomeType[]>([]);
  const [paymentFrequencies, setPaymentFrequencies] = useState<IPaymentFrequency[]>([]);
  const [domainLoaded, setDomainLoaded] = useState(false);
  const [officesLoaded, setOfficesLoaded] = useState(false);
  const [rolesLoaded, setRolesLoaded] = useState(false);
  const [offencesLoaded, setOffencesLoaded] = useState(false);
  const loaded = domainLoaded && officesLoaded && rolesLoaded && offencesLoaded;
  const [timeTrackingActivities, setTimeTrackingActivities] = useState<ITimeTrackingActivity[]>([]);
  const [genders, setGenders] = useState([] as IGender[]);
  const [circuitLocations, setCircuitLocations] = useState<ICircuitLocation[]>([]);
  const [notificationTypes, setNotificationTypes] = useState<INotificationType[]>([]);
  const [dutyCounselCourtTypes, setDutyCounselCourtTypes] = useState<IDutyCounselCourtType[]>([]);
  const [yukonFirstNations, setYukonFirstNations] = useState<IYukonFirstNation[]>([]);
  const [documentTypes, setDocumentTypes] = useState<IDocumentType[]>([]);
  const [supportWorkerTypes, setSupportWorkerTypes] = useState<ISupportWorkerType[]>([]);
  const [supportRequestCriteria, setSupportRequestCriteria] = useState<ISupportRequestCriteria[]>([]);
  const [supportRequestAssistanceTypes, setSupportRequestAssistanceTypes] = useState<ISupportRequestAssistanceType[]>(
    []
  );
  const [interventionStatuses, setInterventionStatuses] = useState<IInterventionStatus[]>([]);
  const [interventionTypes, setInterventionTypes] = useState<IInterventionType[]>([]);

  useEffect(() => {
    authAxios.get('/api/files/domain').then((response) => {
      let applicationTypes = response.data.applicationTypes;
      applicationTypes = applicationTypes.sort((a: IApplicationType, b: IApplicationType) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IApplicationType[];
      setApplicationTypes(applicationTypes);

      let fileTypes = response.data.fileTypes;
      fileTypes = fileTypes.sort((a: IFileType, b: IFileType) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IFileType[];
      setFileTypes(fileTypes);

      let stages = response.data.stages;
      stages = stages.sort((a: IStage, b: IStage) => {
        if (a.order < b.order) {
          return -1;
        } else if (a.order > b.order) {
          return 1;
        } else {
          return 0;
        }
      }) as IStage[];
      setStages(stages);

      let relationships = response.data.relationshipTypes;
      relationships = relationships.sort((a: IRelationshipType, b: IRelationshipType) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IRelationshipType[];
      setRelationshipTypes(relationships);

      let reasonList = response.data.reasons;
      reasonList = reasonList.sort((a: IReason, b: IReason) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IReason[];
      setReasons(reasonList);

      let maritalStatusTypes = response.data.maritalStatuses;
      maritalStatusTypes = maritalStatusTypes.sort((a: IMaritalStatus, b: IMaritalStatus) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IMaritalStatus[];
      setMaritalStatuses(maritalStatusTypes);

      let livingArrangementTypes = response.data.livingArrangements;
      livingArrangementTypes = livingArrangementTypes.sort((a: ILivingArrangement, b: ILivingArrangement) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as ILivingArrangement[];
      setLivingArrangements(livingArrangementTypes);

      let incomeTypes = response.data.incomeTypes;
      incomeTypes = incomeTypes.sort((a: IIncomeType, b: IIncomeType) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IIncomeType[];
      setIncomeTypes(incomeTypes);

      let trackingActivities = response.data.timeTrackingActivities;
      trackingActivities = trackingActivities.sort((a: ITimeTrackingActivity, b: ITimeTrackingActivity) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as ITimeTrackingActivity[];
      setTimeTrackingActivities(trackingActivities);

      let circuitLocations = response.data.circuitLocations;
      circuitLocations = circuitLocations.sort((a: ICircuitLocation, b: ICircuitLocation) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as ICircuitLocation[];
      setCircuitLocations(circuitLocations);

      setPaymentFrequencies(response.data.paymentFrequencies);

      let genders = response.data.genders;
      genders = genders.sort((a: IGender, b: IGender) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IGender[];
      setGenders(genders);

      let yukonfns = response.data.yukonFirstNations;
      setYukonFirstNations(yukonfns);

      let notifications = response.data.notificationTypes;
      notifications = notifications.sort((a: INotificationType, b: INotificationType) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as INotificationType[];
      setNotificationTypes(notifications);

      let courtTypes = response.data.dutyCounselCourtTypes;
      courtTypes = courtTypes.sort((a: IDutyCounselCourtType, b: IDutyCounselCourtType) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IDutyCounselCourtType[];
      setDutyCounselCourtTypes(courtTypes);

      let documentTypes: IDocumentType[] = response.data.documentTypes;
      documentTypes = documentTypes.sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));
      setDocumentTypes(documentTypes);

      let supportWorkerTypes: ISupportWorkerType[] = response.data.supportWorkerTypes;
      supportWorkerTypes = supportWorkerTypes.sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));
      setSupportWorkerTypes(supportWorkerTypes);

      let supportRequestCriteriaTypes: ISupportRequestCriteria[] = response.data.supportRequestCriteria;
      supportRequestCriteriaTypes = supportRequestCriteriaTypes.sort((a, b) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      );
      setSupportRequestCriteria(supportRequestCriteriaTypes);

      let supportRequestAssistanceTypesList: ISupportRequestAssistanceType[] =
        response.data.supportRequestAssistanceTypes;
      supportRequestAssistanceTypesList = supportRequestAssistanceTypesList.sort((a, b) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      );
      setSupportRequestAssistanceTypes(supportRequestAssistanceTypesList);

      let interventionStatuses = response.data.interventionStatuses;
      setInterventionStatuses(interventionStatuses);

      let interventionTypesList: IInterventionType[] = response.data.interventionTypes;
      interventionTypesList = interventionTypesList.sort((a, b) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      );
      setInterventionTypes(interventionTypesList);

      setDomainLoaded(true);
    });
  }, []);

  useEffect(() => {
    authAxios.get('/api/offices/admin').then((response) => {
      let offices = response.data;
      offices = offices.sort((a: IOffice, b: IOffice) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IOffice[];
      setOffices(offices);
      setOfficesLoaded(true);
    });

    authAxios.get('/api/users/roles').then((response) => {
      const roles: [] = response.data;
      setRoles(roles);
      setRolesLoaded(true);
    });
    authAxios.get('/api/offences/basic').then((response) => {
      let offenceList = response.data;
      offenceList = offenceList.sort((a: IOffence, b: IOffence) =>
        a.number.toUpperCase().localeCompare(b.number.toUpperCase())
      ) as IOffence[];
      setOffences(offenceList);
      setOffencesLoaded(true);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DomainContext.Provider
      value={{
        loaded,
        offices,
        setOffices,
        applicationTypes,
        fileTypes,
        stages,
        relationshipTypes,
        roles,
        offences,
        reasons,
        maritalStatuses,
        livingArrangements,
        incomeTypes,
        paymentFrequencies,
        timeTrackingActivities,
        genders,
        circuitLocations,
        notificationTypes,
        dutyCounselCourtTypes,
        yukonFirstNations,
        documentTypes,
        supportWorkerTypes,
        supportRequestCriteria,
        supportRequestAssistanceTypes,
        interventionStatuses,
        interventionTypes,
      }}
    >
      {props.children}
    </DomainContext.Provider>
  );
};

export const DomainConsumer = DomainContext.Consumer;
