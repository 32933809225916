import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Container, Navbar, NavbarBrand } from 'reactstrap';
import { FaChevronDown, FaChevronUp, FaPlus } from 'react-icons/fa';
import { TablePagination } from '../TablePagination/TablePagination';
import { useDefaultColumn } from 'src/hooks/ReactTableHooks';
import { TableInstance, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { authAxios } from 'src/services/AxiosService';
import { MyUserContext } from 'src/contexts/MyUserContext';
import * as AuthenticationService from 'src/services/AuthenticationService';
import {
  ISupportWorkerRequest,
  ISupportWorkerRequestReminder,
} from 'src/components/SupportRequests/SupportWorkerRequestContext';
import { useSupportWorkerRequestColumns } from 'src/components/SupportRequests/useSupportWorkerRequestColumns';
import { useLegalSystemWorkerRequestColumns } from 'src/components/SupportRequests/useLegalSystemWorkerRequestColumns';
import { REMINDER_TYPE_EXTENSION_REQUESTED, REMINDER_TYPE_FILE_EXPIRY } from 'src/components/Files/FileContext';

interface IProps {
  selectRow?: (userId: string) => void;
}

const getReminders = (): Promise<ISupportWorkerRequestReminder[]> => {
  return authAxios.get('/api/support-worker-requests/active-onscreen-reminders').then((response) => {
    const reminders: ISupportWorkerRequestReminder[] = response.data.reminders;
    return reminders;
  });
};

export const SupportWorkerRequestsTable: React.FC<IProps> = (props) => {
  const myUserContext = useContext(MyUserContext);

  const [activeOnscreenSupportWorkerRequestReminders, setActiveOnscreenSupportWorkerRequestReminders] = useState<
    ISupportWorkerRequestReminder[]
  >([]);
  const location = useLocation<{ supportWorkerRequestToDelete?: string; remindersToDelete?: string[] }>();
  const requestToDelete = location?.state?.supportWorkerRequestToDelete;
  const remindersToDelete = location?.state?.remindersToDelete;
  const authUserId = AuthenticationService.getUserId();
  const legalSystemWorkerRequestColumns = useLegalSystemWorkerRequestColumns();
  const supportWorkerRequestColumns = useSupportWorkerRequestColumns();
  const [supportWorkerRequests, setSupportWorkerRequests] = useState<ISupportWorkerRequest[]>([]);
  const history = useHistory();

  useEffect(() => {
    authAxios.get(`/api/support-worker-requests/${authUserId}/user-support-requests`).then((response) => {
      if (requestToDelete) {
        setSupportWorkerRequests(
          response.data.filter((n: ISupportWorkerRequest) => n.supportWorkerRequestId !== requestToDelete)
        );
      } else {
        setSupportWorkerRequests(response.data);
      }
    });
  }, [authUserId, requestToDelete]);

  useEffect(() => {
    getReminders().then((e) => {
      setActiveOnscreenSupportWorkerRequestReminders(
        e.filter((r) => !(remindersToDelete || []).some((f) => f === r.reminderId))
      );
    });
  }, [remindersToDelete, setActiveOnscreenSupportWorkerRequestReminders]);

  const resolveReminder = (supportWorkerRequestId: string, reminderId: string) => {
    authAxios
      .post('/api/support-worker-requests/admin/resolve-reminder', {
        supportWorkerRequestId: supportWorkerRequestId,
        reminderId: reminderId,
      })
      .then((response) => {
        if (response.data.status === 'OK') {
          setActiveOnscreenSupportWorkerRequestReminders((r) =>
            r.filter((reminder) => reminder.reminderId !== reminderId)
          );
        }
      });
  };

  const data: ISupportWorkerRequest[] = useMemo(() => supportWorkerRequests, [supportWorkerRequests]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: TableInstance<ISupportWorkerRequest> = useTable(
    {
      columns: myUserContext.isSupportWorker
        ? supportWorkerRequestColumns
        : myUserContext.role === 'LegalAssistant' || myUserContext.role === 'Lawyer'
        ? legalSystemWorkerRequestColumns
        : [],
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        sortBy: [
          {
            id: 'status',
            desc: false,
          },
        ],
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const selectRow = (supportWorkerRequestId: string) => {
    if (props.selectRow) {
      props.selectRow(supportWorkerRequestId);
    } else {
      history.push('/support-worker-requests/' + supportWorkerRequestId);
    }
  };

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'}>
        <NavbarBrand className='mr-auto'>Support Requests</NavbarBrand>
        {!myUserContext.isSupportWorker && (
          <Button color={'success'} className={'ml-2'} tag={Link} to={{ pathname: '/support-worker-requests/add' }}>
            <FaPlus className='mr-2' />
            Add Request
          </Button>
        )}
      </Navbar>
      {activeOnscreenSupportWorkerRequestReminders.length > 0 && (
        <Container fluid={true} className={'my-3'}>
          {activeOnscreenSupportWorkerRequestReminders.map((r) => (
            <Alert
              color={
                r.reminderTypeId === REMINDER_TYPE_FILE_EXPIRY || r.reminderTypeId === REMINDER_TYPE_EXTENSION_REQUESTED
                  ? 'danger'
                  : 'success'
              }
              isOpen={true}
              key={r.reminderId}
            >
              <div className={'d-flex'}>
                NOTIFICATION: {r.text}
                <Button
                  className={'ml-auto p-0'}
                  color={'link'}
                  onClick={() => history.push('/support-worker-requests/' + r.supportWorkerRequestId)}
                >
                  View Support Request
                </Button>
                {r.reminderTypeId !== REMINDER_TYPE_FILE_EXPIRY && (
                  <Button
                    className={'ml-4 p-0'}
                    color={'link'}
                    onClick={() => resolveReminder(r.supportWorkerRequestId, r.reminderId)}
                  >
                    Resolve
                  </Button>
                )}
              </div>
            </Alert>
          ))}
        </Container>
      )}
      <div className={'table-responsive'} style={{ minHeight: '800px' }}>
        <table className={'table table-bordered table-hover'} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`offices-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={`offices-table-thead-tr-${index}-${column.id}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                    <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
            {page.map((row: any) => {
              prepareRow(row);

              return (
                <tr key={`offices-table-tr-${row.id}`} {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        key={`support-worker-requests-table-td-${cell.row.id}-${cell.column.id}`}
                        {...cell.getCellProps()}
                        onClick={() => {
                          if (!myUserContext.isManagement) {
                            selectRow(row.original.supportWorkerRequestId);
                          }
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizes={[20, 50, 100, 500]}
      />
    </div>
  );
};
