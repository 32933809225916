import * as React from 'react';
import { useState } from 'react';
import { formatDateForServer } from 'src/formatters/DateTimeFormatter';

export const PAYMENT_DAILY = 'ae8f31ac-db26-405c-a660-88c6427ca918';
export const PAYMENT_WEEKLY = 'c15a0f6c-1c8b-4400-b6ad-811e537bcef0';
export const PAYMENT_BIMONTHLY = 'ea6974af-6450-40b3-bb54-69c95076f51b';
export const PAYMENT_MONTHLY = '605ff6a3-014d-4e43-b08a-03476e378de8';
export const PAYMENT_QUARTERLY = '2ebe4ebF-1dd0-4265-98af-89f5c71a3c4e';
export const PAYMENT_SEMIYEARLY = '7a37f9fa-6cbd-4f0d-8657-5c6d49a4dd63';
export const PAYMENT_YEARLY = '358efbc1-4c81-4e6f-b783-0719e4b9e7e0';

export const REMINDER_TYPE_FILE_EXPIRY = 'd08c7292-2648-4297-894c-fdd7f2c26708';
export const REMINDER_TYPE_EXTENSION_REQUESTED = '7fa46c0b-7dee-44da-bbd5-f28834afc3dd';

export const MOST_SERIOUS_GROUPS = [
  { value: '1', display: '1' },
  { value: '2', display: '2' },
  { value: '3', display: '3' },
  { value: '4', display: '4' },
  { value: '', display: 'None' },
];

export interface IReminder {
  fileId: string;
  reminderId: string;
  text: string;
  date?: number;
  staffToNotify: string[];
  notificationTypeIds: string[];
  resolved: boolean;
  reminderTypeId: string;
}

export interface IThirdParty {
  thirdPartyId: string;
  individualId: string;
  relationshipTypeId: string;
}

export interface IMaritalStatus {
  maritalStatusId: string;
  name: string;
}

export interface ILivingArrangement {
  livingArrangementId: string;
  name: string;
}

export interface IIncomeType {
  incomeTypeId: string;
  name: string;
}

export interface IPaymentFrequency {
  paymentFrequencyId: string;
  name: string;
}

export interface IHoursRecord {
  hoursRecordId: string;
  userId: string;
  timeTrackingActivityId: string;
  hours: number;
  date: number;
  timestamp: number;
  fileId?: string;
  fileNumber?: string;
  clientName?: string;
  details?: string;
  supportRequestId?: string;
  interventionsId?: string;
}

export interface IExtensionRequest {
  extensionRequestId: string;
  reasonForExtension: string;
  approved?: boolean;
  newExpiryDate?: string;
  reasonForRejection?: string;
  timestamp: number;
}

export interface IRejectedLawyer {
  lawyerId: string;
  reason: string;
  dateRejected: number;
}

export interface ICharge {
  offenceId: string;
  pleaTypeId?: string;
  outcomes: string[];
  breachNumber?: string;
  electionTypeId?: string;
}

export interface IChild {
  name: string;
  birthDate: number;
}

export interface IFilePost {
  fileId?: string;
  fileNumber: string;
  clientId: string;
  stageId: string;
  applicationTypeId: string;
  fileTypeId: string;
  thirdParties: IThirdParty[];
  submittedDate: string;
  charges: ICharge[];
  isAppealRecommended: boolean;
  lawyerId?: string;
  officeId?: string;
  rejectedLawyers?: IRejectedLawyer[];
  applicationDate: string;
  expiryDate: string;
  courtNumber: string;
  nextAppearance: number;
  children: IChild[];
  circuitLocationId?: string;
  chargeFindings?: string;
  reasonId?: string;
  reasonRejected?: string;
  thirdPartyCounselRequest?: string;
  referredToRestorativeJustice?: boolean;
  offSite?: boolean;
  inCustody?: boolean;
  retainerSigned?: boolean;
  designationFiled?: boolean;
}

export interface IFileMeta {
  noteCount: number;
}

export interface IFile {
  fileId: string;
  fileNumber?: string;
  fileSeqNumber: number;
  clientId: string;
  stageId: string;
  reasonId?: string;
  reasonRejected?: string;
  reasonBoardRejected?: string;
  reasonIdBoardRejected?: string;
  reasonStopped?: string;
  reasonCounselRequestRejected?: string[];
  applicationTypeId: string;
  fileTypeId: string;
  thirdParties: IThirdParty[];
  submittedDate: string;
  charges: ICharge[];
  isAppealRecommended: boolean;
  lawyerId?: string;
  hoursRecorded: IHoursRecord[];
  rejectedLawyers?: IRejectedLawyer[];
  appDate: number; // applicationDate
  expiryDate: number;
  lawOfficeNumber: string;
  courtNumber: string;
  nextAppearance: number;
  lawyerRequestedDate?: number;
  lawyerAssignedDate?: number;
  closedDate?: number;
  errorReason?: string;
  boardReviewed: boolean;
  approvalType?: string;
  counselRequest: boolean;
  contributionAgreement: boolean;
  contributionAgreementInitialPayment?: number;
  contributionAgreementMonthlyPayment?: number;
  children: IChild[];
  circuitLocationId?: string;
  chargeFindings?: string;
  expiryDateFormatted?: string;
  appDateFormatted?: string;
  mostSerious: string;
  lawyerFormatted: string;
  clientName: string;
  clientAliases: string;
  clientDateOfBirth: string;
  lawyerAssignedDateFormatted: string;
  lawyerRequestedDateFormatted: string;
  relationship?: string;
  isLockedDown?: boolean;
  thirdPartyCounselRequest?: string;
  extensionRequests: IExtensionRequest[];
  referredToRestorativeJustice?: boolean;
  offSite?: boolean;
  inCustody?: boolean;
  retainerSigned?: boolean;
  designationFiled?: boolean;
}

export interface IReportingFile {
  fId: string; // fileId
  appDate: number; // applicationDate
  fNum?: number; // fileNumber
  cfNum?: string; // courtFileNumber
  fSeqNum: number; // fileSeqNumber
  cId: string; // clientId
  sId: string; // stageId
  cirLocId?: string; // circuitLocationId
  rsnId?: string; // reasonId
  rsnIdBrdRej?: string; // reasonIdBoardRejected
  chrgs: ICharge[]; // charges
  appTypeId: string; // applicationTypeId
  clsDate?: number; // closedDate
  lId?: string; // lawyerId
  fTId: string; // fileTypeId
  eDate: string; // expiryDate
  lawOfficeNumber?: string;
  destructionDate?: number;
}

export interface IReportingFileWithClientName {
  fId: string; // fileId
  appDate: number; // applicationDate
  fNum?: number; // fileNumber
  cfNum?: string; // courtFileNumber
  fSeqNum: number; // fileSeqNumber
  cId: string; // clientId
  cFName: string;
  cLName: string;
  sId: string; // stageId
  cirLocId?: string; // circuitLocationId
  rsnId?: string; // reasonId
  rsnIdBrdRej?: string; // reasonIdBoardRejected
  chrgs: ICharge[]; // charges
  appTypeId: string; // applicationTypeId
  clsDate?: number; // closedDate
  lId?: string; // lawyerId
  fTId: string; // fileTypeId
  eDate: string; // expiryDate
  lawOfficeNumber?: string;
  destructionDate?: number;
}

export interface IMonthlyIncome {
  monthlyIncomeId: string;
  month: string;
  year: number;
  incomes: IIncome[];
}

export interface IIncome {
  incomeTypeId: string;
  amount: string | undefined;
}

export interface IDutyCounselAppearance {
  dutyCounselAppearanceId: string;
  dutyCounselAppearanceDate?: number;
  dutyCounselCourtTypeId?: string;
  lawyerId: string;
}

export interface IAsset {
  name: string;
  amount: string | undefined;
}

export interface IFinancialDetails {
  maritalStatusId: string;
  livingArrangementId: string;
  numberOfAdults?: number;
  numberOfChildren?: number;
  assets: IAsset[];
  monthlyIncomes: IMonthlyIncome[];
  averageMonthlyIncome: string;
  averageYearlyIncome: string;
  maxMonthlyIncome: string;
  maxYearlyIncome: string;
  percentOverage?: string;
}

type ContextType = {
  file: IFile;
  setFile: React.Dispatch<React.SetStateAction<IFile>>;
  fileMeta: IFileMeta;
  setFileMeta: React.Dispatch<React.SetStateAction<IFileMeta>>;
  setFileAndAttributes: (file: IFile) => void;
  getCreateData: () => IFilePost;
  getEditData: () => IFilePost;

  appDate: Date;
  setAppDate: React.Dispatch<React.SetStateAction<Date>>;

  expiryDate: Date | undefined;
  setExpiryDate: React.Dispatch<React.SetStateAction<Date | undefined>>;

  clientId: string | undefined;
  setClientId: React.Dispatch<React.SetStateAction<string | undefined>>;

  applicationTypeId: string | undefined;
  setApplicationTypeId: React.Dispatch<React.SetStateAction<string | undefined>>;

  fileTypeId: string | undefined;
  setFileTypeId: React.Dispatch<React.SetStateAction<string | undefined>>;

  charges: ICharge[];
  setCharges: React.Dispatch<React.SetStateAction<ICharge[]>>;

  isAppealRecommended: boolean | undefined;
  setIsAppealRecommended: React.Dispatch<React.SetStateAction<boolean | undefined>>;

  referredToRestorativeJustice: boolean | undefined;
  setReferredToRestorativeJustice: React.Dispatch<React.SetStateAction<boolean | undefined>>;

  chargeFindings: string | undefined;
  setChargeFindings: React.Dispatch<React.SetStateAction<string | undefined>>;

  circuitLocationId: string | undefined;
  setCircuitLocationId: React.Dispatch<React.SetStateAction<string | undefined>>;

  children: IChild[];
  setChildren: React.Dispatch<React.SetStateAction<IChild[]>>;

  courtFileNumber: string | undefined;
  setCourtFileNumber: React.Dispatch<React.SetStateAction<string | undefined>>;

  lawOfficeNumber: string | undefined;
  setLawOfficeNumber: React.Dispatch<React.SetStateAction<string | undefined>>;

  nextAppearanceDate: Date | undefined;
  setNextAppearanceDate: React.Dispatch<React.SetStateAction<Date | undefined>>;

  thirdParties: IThirdParty[];
  setThirdParties: React.Dispatch<React.SetStateAction<IThirdParty[]>>;

  financialDetails: IFinancialDetails;
  setFinancialDetails: React.Dispatch<React.SetStateAction<IFinancialDetails>>;

  dutyCounselAppearances: IDutyCounselAppearance[];
  setDutyCounselAppearances: React.Dispatch<React.SetStateAction<IDutyCounselAppearance[]>>;

  hoursRecords: IHoursRecord[];
  setHoursRecords: React.Dispatch<React.SetStateAction<IHoursRecord[]>>;

  extensionRequests: IExtensionRequest[];
  setExtensionRequests: React.Dispatch<React.SetStateAction<IExtensionRequest[]>>;

  originalReasonId: string | undefined;
  setOriginalReasonId: React.Dispatch<React.SetStateAction<string | undefined>>;

  originalReasonRejected: string | undefined;
  setOriginalReasonRejected: React.Dispatch<React.SetStateAction<string | undefined>>;

  originalReasonIdBoardRejected: string | undefined;
  setOriginalReasonIdBoardRejected: React.Dispatch<React.SetStateAction<string | undefined>>;

  originalReasonBoardRejected: string | undefined;
  setOriginalReasonBoardRejected: React.Dispatch<React.SetStateAction<string | undefined>>;

  activeOnscreenReminders: IReminder[];
  setActiveOnscreenReminders: React.Dispatch<React.SetStateAction<IReminder[]>>;

  supportWorkers: string[];
  setSupportWorkers: React.Dispatch<React.SetStateAction<string[]>>;

  offSite: boolean | undefined;
  setOffSite: React.Dispatch<React.SetStateAction<boolean | undefined>>;

  inCustody: boolean | undefined;
  setInCustody: React.Dispatch<React.SetStateAction<boolean | undefined>>;

  retainerSigned: boolean | undefined;
  setRetainerSigned: React.Dispatch<React.SetStateAction<boolean | undefined>>;

  designationFiled: boolean | undefined;
  setDesignationFiled: React.Dispatch<React.SetStateAction<boolean | undefined>>;
};

export const FileContext = React.createContext<ContextType>({
  file: {} as IFile,
  setFile: () => {},
  fileMeta: {} as IFileMeta,
  setFileMeta: () => {},
  setFileAndAttributes: () => {},
  getCreateData: () => ({} as IFilePost),
  getEditData: () => ({} as IFilePost),

  thirdParties: [] as IThirdParty[],
  setThirdParties: () => {},

  financialDetails: {} as IFinancialDetails,
  setFinancialDetails: () => {},
  dutyCounselAppearances: [] as IDutyCounselAppearance[],
  setDutyCounselAppearances: () => {},
  appDate: new Date(),
  setAppDate: () => {},

  expiryDate: undefined,
  setExpiryDate: () => {},

  clientId: undefined,
  setClientId: () => {},

  applicationTypeId: undefined,
  setApplicationTypeId: () => {},

  fileTypeId: undefined,
  setFileTypeId: () => {},

  charges: [] as ICharge[],
  setCharges: () => {},

  isAppealRecommended: undefined,
  setIsAppealRecommended: () => {},

  referredToRestorativeJustice: undefined,
  setReferredToRestorativeJustice: () => {},

  chargeFindings: undefined,
  setChargeFindings: () => {},

  circuitLocationId: undefined,
  setCircuitLocationId: () => {},

  children: [],
  setChildren: () => {},

  courtFileNumber: undefined,
  setCourtFileNumber: () => {},

  lawOfficeNumber: undefined,
  setLawOfficeNumber: () => {},

  nextAppearanceDate: undefined,
  setNextAppearanceDate: () => {},

  hoursRecords: [] as IHoursRecord[],
  setHoursRecords: () => {},

  originalReasonId: undefined,
  setOriginalReasonId: () => {},

  originalReasonRejected: undefined,
  setOriginalReasonRejected: () => {},

  originalReasonIdBoardRejected: undefined,
  setOriginalReasonIdBoardRejected: () => {},

  originalReasonBoardRejected: undefined,
  setOriginalReasonBoardRejected: () => {},

  activeOnscreenReminders: [] as IReminder[],
  setActiveOnscreenReminders: () => {},

  extensionRequests: [] as IExtensionRequest[],
  setExtensionRequests: () => {},

  supportWorkers: [] as string[],
  setSupportWorkers: () => {},

  offSite: undefined,
  setOffSite: () => {},

  inCustody: undefined,
  setInCustody: () => {},

  retainerSigned: undefined,
  setRetainerSigned: () => {},

  designationFiled: undefined,
  setDesignationFiled: () => {},
});

export const FileProvider = (props: any) => {
  let expireDate = new Date();
  expireDate.setMonth(expireDate.getMonth() + 6);
  const [file, setFile] = useState({} as IFile);
  const [fileMeta, setFileMeta] = useState({} as IFileMeta);
  const [appDate, setAppDate] = useState<Date>(new Date());
  const [expiryDate, setExpiryDate] = useState<Date | undefined>(expireDate);
  const [lawOfficeNumber, setLawOfficeNumber] = useState<string | undefined>();
  const [clientId, setClientId] = useState<string | undefined>();
  const [applicationTypeId, setApplicationTypeId] = useState<string | undefined>(undefined);
  const [fileTypeId, setFileTypeId] = useState<string | undefined>(undefined);

  const [courtFileNumber, setCourtFileNumber] = useState<string | undefined>();
  const [nextAppearanceDate, setNextAppearanceDate] = useState<Date | undefined>();

  const [thirdParties, setThirdParties] = useState<IThirdParty[]>([]);
  const [charges, setCharges] = useState<ICharge[]>([]);
  const [isAppealRecommended, setIsAppealRecommended] = useState<boolean | undefined>(false);
  const [referredToRestorativeJustice, setReferredToRestorativeJustice] = useState<boolean | undefined>(false);
  const [chargeFindings, setChargeFindings] = useState<string | undefined>(undefined);
  const [circuitLocationId, setCircuitLocationId] = useState<string | undefined>(undefined);
  const [children, setChildren] = useState<IChild[]>([]);
  const [offSite, setOffSite] = useState<boolean | undefined>(false);
  const [inCustody, setInCustody] = useState<boolean | undefined>(false);
  const [retainerSigned, setRetainerSigned] = useState<boolean | undefined>(false);
  const [designationFiled, setDesignationFiled] = useState<boolean | undefined>(false);

  const [financialDetails, setFinancialDetails] = useState({} as IFinancialDetails);
  const [dutyCounselAppearances, setDutyCounselAppearances] = useState<IDutyCounselAppearance[]>([]);
  const [hoursRecords, setHoursRecords] = useState<IHoursRecord[]>([]);
  const [originalReasonId, setOriginalReasonId] = useState<string | undefined>(undefined);
  const [originalReasonRejected, setOriginalReasonRejected] = useState<string | undefined>(undefined);
  const [originalReasonIdBoardRejected, setOriginalReasonIdBoardRejected] = useState<string | undefined>(undefined);
  const [originalReasonBoardRejected, setOriginalReasonBoardRejected] = useState<string | undefined>(undefined);
  const [activeOnscreenReminders, setActiveOnscreenReminders] = useState<IReminder[]>([]);
  const [extensionRequests, setExtensionRequests] = useState<IExtensionRequest[]>([]);
  const [supportWorkers, setSupportWorkers] = useState<string[]>([]);

  const setFileAndAttributes = (pFile: IFile) => {
    setFile(pFile);
    setClientId(pFile.clientId);
    setApplicationTypeId(pFile.applicationTypeId);
    setFileTypeId(pFile.fileTypeId);
    setThirdParties(pFile.thirdParties);
    setLawOfficeNumber(pFile.lawOfficeNumber);
    setAppDate(new Date(pFile.appDate));
    if (pFile.expiryDate) {
      setExpiryDate(new Date(pFile.expiryDate.toString().replace(/-/g, '/') + ' 12:00'));
    } else {
      const expireDate = pFile.appDate ? new Date(pFile.appDate) : new Date();
      expireDate.setMonth(expireDate.getMonth() + 6);
      setExpiryDate(expireDate);
    }
    setCourtFileNumber(pFile.courtNumber);
    setNextAppearanceDate(pFile.nextAppearance ? new Date(pFile.nextAppearance) : undefined);
    setOriginalReasonId(pFile.reasonId);
    setOriginalReasonRejected(pFile.reasonRejected);
    setOriginalReasonIdBoardRejected(pFile.reasonIdBoardRejected);
    setOriginalReasonBoardRejected(pFile.reasonBoardRejected);
    setCharges(pFile.charges);
    setIsAppealRecommended(pFile.isAppealRecommended);
    setReferredToRestorativeJustice(pFile.referredToRestorativeJustice);
    setChargeFindings(pFile.chargeFindings);
    setCircuitLocationId(pFile.circuitLocationId);
    setChildren(pFile.children);
    setExtensionRequests(pFile.extensionRequests);
    setOffSite(pFile.offSite);
    setInCustody(pFile.inCustody);
    setRetainerSigned(pFile.retainerSigned);
    setDesignationFiled(pFile.designationFiled);
  };

  const getCreateData = (): IFilePost => {
    const data = {
      applicationDate: formatDateForServer(appDate),
      clientId: clientId,
      applicationTypeId: applicationTypeId,
      fileTypeId: fileTypeId,
      thirdParties: thirdParties,
      charges: charges,
      isAppealRecommended: isAppealRecommended,
      courtNumber: courtFileNumber,
      nextAppearance: nextAppearanceDate ? nextAppearanceDate.getTime() : undefined,
      children: children,
      circuitLocationId: circuitLocationId,
      chargeFindings: chargeFindings,
      expiryDate: formatDateForServer(expiryDate),
      thirdPartyCounselRequest: file.thirdPartyCounselRequest,
      referredToRestorativeJustice: referredToRestorativeJustice,
      offSite: offSite,
      inCustody: inCustody,
      retainerSigned: retainerSigned,
      designationFiled: designationFiled,
    };
    return data as IFilePost;
  };

  const getEditData = (): IFilePost => {
    return { fileId: file.fileId, ...getCreateData() };
  };

  return (
    <FileContext.Provider
      value={{
        file,
        setFile,
        fileMeta,
        setFileMeta,
        setFileAndAttributes,
        getCreateData,
        getEditData,
        thirdParties,
        setThirdParties,
        financialDetails,
        setFinancialDetails,
        dutyCounselAppearances,
        setDutyCounselAppearances,
        appDate,
        setAppDate,
        expiryDate,
        setExpiryDate,
        clientId,
        setClientId,
        applicationTypeId,
        setApplicationTypeId,
        fileTypeId,
        setFileTypeId,
        charges,
        setCharges,
        isAppealRecommended,
        setIsAppealRecommended,
        referredToRestorativeJustice,
        setReferredToRestorativeJustice,
        chargeFindings,
        setChargeFindings,
        circuitLocationId,
        setCircuitLocationId,
        children,
        setChildren,

        courtFileNumber,
        setCourtFileNumber,
        lawOfficeNumber,
        setLawOfficeNumber,
        nextAppearanceDate,
        setNextAppearanceDate,
        hoursRecords,
        setHoursRecords,

        originalReasonId,
        setOriginalReasonId,
        originalReasonRejected,
        setOriginalReasonRejected,
        originalReasonIdBoardRejected,
        setOriginalReasonIdBoardRejected,
        originalReasonBoardRejected,
        setOriginalReasonBoardRejected,

        activeOnscreenReminders,
        setActiveOnscreenReminders,

        extensionRequests,
        setExtensionRequests,

        supportWorkers,
        setSupportWorkers,

        offSite,
        setOffSite,

        inCustody,
        setInCustody,

        retainerSigned,
        setRetainerSigned,

        designationFiled,
        setDesignationFiled,
      }}
    >
      {props.children}
    </FileContext.Provider>
  );
};

export const FileConsumer = FileContext.Consumer;
