import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, Card, CardBody, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';

import logo from '../Login/ylss-logo.png';
import * as AuthenticationService from '../../services/AuthenticationService';
import { anonAxios } from '../../services/AxiosService';
import CardHeader from 'reactstrap/lib/CardHeader';
import * as PasswordService from '../../services/PasswordService';
import {
  AUTH_ACTION_LOADING,
  AUTH_ACTION_SIGN_IN,
  AUTH_ACTION_SIGN_OUT,
  AUTH_STATE_SIGNED_IN,
  AuthContext,
} from '../../contexts/AuthContext';
import { useHistory, useParams } from 'react-router';

export const ChangePassword: React.FC<{ changeType: 'reset' | 'create' }> = (props) => {
  useEffect(() => {
    AuthenticationService.removeJwtFromStorage();
    authContext.dispatch(AUTH_ACTION_SIGN_OUT);

    anonAxios
      .get('/api/users/check-' + props.changeType + '-password/' + params.token)
      .then((response) => {
        console.log('Response', response);
        setValidResetPasswordToken(true);
      })
      .catch((error) => {
        history.push({
          pathname: '/forgot-password',
          state: { errorMessage: 'Expired reset password link, please make new request.' },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authContext = useContext(AuthContext);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [validResetPasswordToken, setValidResetPasswordToken] = useState(false);

  const history = useHistory();
  const params = useParams<{ token: string }>();

  const validateForm = () => {
    return PasswordService.isValidPassword(password) && password === confirmPassword && validResetPasswordToken;
  };

  const getUserId = () => {
    const token = params.token;
    if (token) {
      const tokenSplit = token.split('.')[1];
      if (tokenSplit) {
        return JSON.parse(atob(tokenSplit)).userId;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    anonAxios
      .post('/api/users/' + props.changeType + '-password/' + params.token, {
        plainPassword: password,
        userId: getUserId(),
      })
      .then((response) => {
        if (response.data.status === 'OK') {
          authContext.dispatch(AUTH_ACTION_LOADING);
          history.push({ pathname: '/', state: { message: 'Password has been set' } });
          AuthenticationService.setJwtInStorage(response.data.token);
          authContext.dispatch(AUTH_ACTION_SIGN_IN);
        } else {
          setErrorMessage('Password Change Error');
        }
      })
      .catch((error) => {
        const errorMessage = error.response.data.userMessage;
        if (errorMessage) {
          console.log('An error happened');
          setErrorMessage(errorMessage);
        }
      });
  };

  if (authContext.state.type === AUTH_STATE_SIGNED_IN) {
    return <div>Currently signed in</div>;
  }

  return (
    <div className='LoginWrapper'>
      <div className='LogoBar'>
        <img src={logo} style={{ height: 210 }} className='ylss-logo' alt='YLSS Logo' />
      </div>
      {validResetPasswordToken && (
        <Card>
          <CardHeader>
            <strong>Change Password</strong>
          </CardHeader>
          <CardBody>
            <Form>
              <FormGroup>
                <Label>Password</Label>
                <Input
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                  type='password'
                  invalid={password.length > 0 && !PasswordService.isValidPassword(password)}
                />
                {password.length > 0 ? (
                  <FormFeedback>
                    Password needs to be at least 8 characters long and have at least 1 letter and 1 number
                  </FormFeedback>
                ) : (
                  <div className='password-requirements'>
                    Password needs to be at least 8 characters long and have at least 1 letter and 1 number
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Confirm Password</Label>
                <Input
                  value={confirmPassword}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
                  type='password'
                  invalid={confirmPassword.length > 0 && password !== confirmPassword}
                />
                <FormFeedback>Passwords don&apos;t match</FormFeedback>
              </FormGroup>
              <Button
                block={true}
                color='primary'
                disabled={!validateForm()}
                onClick={(e: any) => handleSubmit(e)}
                style={{ marginBottom: '1em' }}
                type='submit'
              >
                {props.changeType === 'create' ? 'Create Password' : 'Update Password'}
              </Button>
              {!validResetPasswordToken && (
                <span className='text-danger'>Expired reset password link, please make new request.</span>
              )}
              <span className='text-danger'>{errorMessage}</span>
            </Form>
          </CardBody>
        </Card>
      )}
    </div>
  );
};
