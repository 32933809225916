import { useContext, useMemo } from 'react';
import { clientSelectValue } from '../../formatters/PeopleFormatter';
import { DomainContext, SUPPORT_REQUEST } from '../../contexts/DomainContext';
import * as React from 'react';
import { Column } from 'react-table';
import { ISupportWorkerRequest } from 'src/components/SupportRequests/SupportWorkerRequestContext';
import { PeopleContext } from 'src/contexts/PeopleContext';
import { formatDateFromISONumber, formatDateTime } from 'src/formatters/DateTimeFormatter';
import { Badge } from 'reactstrap';

import { INDIVIDUAL_STATE_LOADED, IndividualsContext } from 'src/contexts/IndividualsContext';
import { IIndividual } from 'src/components/Individuals/IndividualContext';
import { DropdownMultiSelectCheckboxes } from 'src/components/DropdownMultiSelectCheckboxes/DropdownMultiSelectCheckboxes';
import { SupportWorkerRequestsTableContext } from 'src/components/SupportRequests/SupportWorkerRequestsTableContext';
import { IUser } from 'src/components/Users/UsersTable';
import { supportWorkerStateColor } from 'src/components/SupportRequests/SupportWorkerRequestDetails';

export const useSupportWorkerRequestColumns = (): Column<ISupportWorkerRequest>[] => {
  const peopleContext = useContext(PeopleContext);
  const domainContext = useContext(DomainContext);
  const users = peopleContext.staff;
  const individualsContext = useContext(IndividualsContext);
  const individuals = individualsContext.state.individuals;
  const { selectedStatuses, setSelectedStatuses } = useContext(SupportWorkerRequestsTableContext);
  const filteredStates = SUPPORT_REQUEST.STATES.filter((s) => s.value !== SUPPORT_REQUEST.PENDING);

  //sort options in dropdown
  const sortMethod: (a: string, b: string) => number = (a, b) => {
    if (a === b) {
      return 0;
    }
    const statusA = filteredStates.find((status) => status.value === a);
    const statusB = filteredStates.find((status) => status.value === b);
    if (statusA && statusB) {
      return statusA.order < statusB.order ? -1 : 1;
    } else if (statusA) {
      return 1;
    } else if (statusB) {
      return -1;
    }
    return 0;
  };

  return useMemo(
    () => [
      {
        Header: 'Client',
        id: 'clientId',
        accessor: (request: ISupportWorkerRequest) => {
          const client = individuals.find((i: IIndividual) => i.individualId === request.clientId);

          if (!client && individualsContext.state.type === INDIVIDUAL_STATE_LOADED) {
            console.log('CANT FIND CLIENT RELOADING');
            individualsContext.dispatch({ type: 'INDIVIDUAL_ACTION_DO_RELOADED' });
          }
          return client
            ? client.lastName.toUpperCase() +
                ', ' +
                client.firstName +
                ' ' +
                (client.birthDate ? formatDateFromISONumber(client.birthDate) : '') +
                ' ' +
                client.aliases.join(', ')
            : '';
        },
        Cell: (cell: any) => {
          if (cell && cell.row.original && cell.row.original.clientId) {
            const client = individuals.find((c) => c.individualId === cell.row.original.clientId);
            if (client) {
              return clientSelectValue(client);
            } else {
              return <span />;
            }
          } else {
            return <span />;
          }
        },
      },
      {
        Header: 'Submitted Date',
        id: 'submittedDate',
        width: 140,
        accessor: 'submittedDate',
        Cell: (cell: any) => {
          if (cell && cell.row.original && cell.row.original.submittedDate) {
            return <span>{formatDateTime(new Date(cell.row.original.submittedDate))}</span>;
          } else {
            return <span />;
          }
        },
      },
      {
        Header: 'Assistance Types',
        id: 'assistanceType',
        accessor: (sr: ISupportWorkerRequest) => {
          return domainContext.supportRequestAssistanceTypes
            .filter((at) => sr.assistanceTypes.includes(at.supportRequestAssistanceTypeId))
            .map((at) => at.name)
            .join(', ');
        },
      },
      {
        Header: 'Lawyer',
        id: 'lawyerId',
        accessor: (request: ISupportWorkerRequest) => {
          const staffUser = users.find((i: IUser) => i.userId === request.lawyerId);

          return staffUser ? `${staffUser?.lastName.toUpperCase()}, ${staffUser?.firstName}` : '';
        },
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: 'status',
        Cell: (p: any) => {
          const myStatus = filteredStates.find((status) => status.value === p.value);
          if (myStatus) {
            return <Badge color={supportWorkerStateColor(myStatus.value)}>{myStatus.value}</Badge>;
          } else {
            return undefined;
          }
        },
        sortMethod,
        //sort requests in table
        sortType: (rowA: any, rowB: any, value: any, desc: any) => {
          const statusA = filteredStates.find((status) => status.value === rowA.original[value]);
          const statusB = filteredStates.find((status) => status.value === rowB.original[value]);
          if (statusA && statusB) {
            return statusA.order < statusB.order ? -1 : 1;
          } else if (statusA) {
            return 1;
          } else if (statusB) {
            return -1;
          }
          return 0;
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { setFilter } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={selectedStatuses}
              keyPrefix='status'
              setValues={(val: string[]) => {
                setSelectedStatuses(val);
                setFilter(val);
              }}
              options={filteredStates.map((s) => ({
                value: s.value,
                display: s.label,
              }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (selectedStatuses.length === 0) {
              return true;
            } else {
              const rowValue = row.original['status'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
      },
      {
        Header: 'Last Updated',
        id: 'lastUpdated',
        width: 140,
        accessor: 'lastUpdated',
        Cell: (cell: any) => {
          if (cell && cell.row.original && cell.row.original.lastUpdated) {
            return <span>{formatDateTime(new Date(cell.row.original.lastUpdated))}</span>;
          } else {
            return <span />;
          }
        },
      },
    ],
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users, selectedStatuses]
  );
};
