import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { InputType } from 'reactstrap/lib/Input';
import { authAxios } from '../../services/AxiosService';
import { notifyError, notifySuccess } from '../../services/ToastService';
import {
  DomainContext,
  IOffice,
  ISupportWorkerType,
  OFFICE_INTAKE,
  OFFICE_PRIVATE_LAWYER,
  OFFICE_SUPPORT_WORKER,
} from '../../contexts/DomainContext';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { FormEvent } from 'react';
import Select from 'react-select';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { MyUserContext } from '../../contexts/MyUserContext';

export const UserDetails: React.FC = () => {
  const domainContext = useContext(DomainContext);
  const myUserContext = useContext(MyUserContext);
  const offices = domainContext.offices;
  const supportWorkerTypes = domainContext.supportWorkerTypes;

  const [trySubmit, setTrySubmit] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [officeId, setOfficeId] = useState('');
  const [newOfficeId, setNewOfficeId] = useState('');
  const [active, setActive] = useState<boolean>(true);
  const history = useHistory();
  const params = useParams<{ userId: string }>();
  const [isDirty, setIsDirty] = useState(false);
  const [openTransferLawOffice, setOpenTransferLawOffice] = useState(false);
  const [supportWorkerTypeId, setSupportWorkerTypeId] = useState();
  const isEditable = !myUserContext.isManagement;

  useEffect(() => {
    if (params.userId) {
      authAxios.get('/api/users/admin/' + params.userId).then((response) => {
        if (response.data) {
          setFirstName(response.data.firstName);
          setLastName(response.data.lastName);
          setEmail(response.data.email);
          setRole(response.data.role);
          setOfficeId(response.data.officeId);
          setActive(response.data.active);
          setSupportWorkerTypeId(response.data.supportWorkerTypeId);
        }
      });
    }
  }, [params.userId]);

  const roles = [
    { val: 'LegalAssistant', display: 'Legal Assistant' },
    { val: 'Lawyer', display: 'Lawyer' },
    { val: 'Management', display: 'Management' },
    { val: 'Reporting', display: 'Reporting' },
    { val: 'SuperAdmin', display: 'Super Admin' },
    { val: 'SupportWorker', display: 'Support Worker' },
  ];

  const formGroupInput = (
    name: string,
    label: string,
    type: InputType,
    val: any,
    onChange: any,
    required: boolean,
    disabled: boolean
  ) => (
    <FormGroup>
      <Label for={name}>
        {label}
        {required && <span className='text-required'> (required)</span>}
      </Label>
      <Input
        label={label}
        value={val}
        type={type}
        name={name}
        id={name}
        onChange={onChange}
        disabled={disabled}
        invalid={required ? trySubmit && val === '' : false}
      />
      <FormFeedback>This field is mandatory</FormFeedback>
    </FormGroup>
  );

  const formGroupInputCheckbox = (
    name: string,
    label: string,
    type: InputType,
    val: any,
    onChange: any,
    disabled: boolean
  ) => (
    <FormGroup>
      <CustomInput
        type='checkbox'
        id={name}
        name={name}
        label={label}
        checked={val}
        onChange={onChange}
        invalid={trySubmit && val === ''}
        disabled={disabled}
      />
      <FormFeedback>This field is mandatory</FormFeedback>
    </FormGroup>
  );

  const saveDetails = () => {
    if (!formFullyFilled()) {
      setTrySubmit(true);
    } else {
      if (params.userId) {
        const data = {
          userId: params.userId,
          email,
          firstName,
          lastName,
          role,
          officeId,
          active,
          supportWorkerTypeId,
        };
        authAxios.post('/api/users/admin/update-staff-user', data).then((response) => {
          console.log('response: ', response);
          if (response.data.status === 'OK') {
            notifySuccess('User details updated.');
            setIsDirty(false);
          } else {
            notifyError(response.data.errorMessage);
          }
        });
      } else {
        const data = { email, firstName, lastName, role, officeId, active, supportWorkerTypeId };
        authAxios.post('/api/users/admin/create-staff-user', data).then((response) => {
          if (response.data.status === 'OK') {
            const userId = response.data.userId;
            setIsDirty(false);
            if (role === 'Lawyer' && officeId === OFFICE_PRIVATE_LAWYER) {
              notifySuccess('User created.');
            } else {
              notifySuccess('User created. Account activation email sent.');
            }
            history.push('/users/' + userId);
          } else {
            notifyError(response.data.errorMessage);
          }
        });
      }
    }
  };

  const transferOffices = (newOfficeId: string) => {
    authAxios
      .post('/api/users/admin/transfer-offices', { userId: params.userId, officeId: newOfficeId })
      .then((response) => {
        console.log('response: ', response);
        if (response.data.status === 'OK') {
          setOpenTransferLawOffice(false);
          setOfficeId(newOfficeId);
          setIsDirty(false);
          notifySuccess('Office Updated');
        } else {
          notifyError(response.data.status);
        }
      });
  };

  const formFullyFilled = () => {
    return (
      firstName !== '' &&
      lastName !== '' &&
      email !== '' &&
      officeId !== '' &&
      ((role === 'SupportWorker' && supportWorkerTypeId !== undefined && supportWorkerTypeId !== '') ||
        (role !== 'SupportWorker' && role !== ''))
    );
  };

  const dropdownSelectStyles = {
    menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
  };

  return (
    <div>
      <LeavePageConfirm isDirty={isDirty} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom'}>
          <Row className={'flex-fill'}>
            <Col md={3} xl={2}>
              <NavbarBrand>{params.userId ? 'Edit User' : 'Add New User'}</NavbarBrand>
            </Col>
            {!myUserContext.isManagement ? (
              params.userId ? (
                <Col className={'d-flex'}>
                  <Button color='success' className={'ml-auto'} onClick={() => saveDetails()}>
                    Save Details
                  </Button>
                </Col>
              ) : (
                <Col className={'d-flex'}>
                  <Button className={'ml-auto grey-button'} onClick={() => history.push('/users')}>
                    Cancel
                  </Button>
                  <Button color='success' className={'ml-2'} onClick={() => saveDetails()}>
                    Save Details
                  </Button>
                </Col>
              )
            ) : (
              <div />
            )}
          </Row>
        </Navbar>
        <Container fluid={true} className={'my-3'}>
          <Row>
            <Col md={{ size: 9, offset: 3 }} xl={{ size: 10, offset: 2 }}>
              <Row>
                <Col md={6}>
                  {formGroupInput(
                    'firstName',
                    'First Name',
                    'text',
                    firstName,
                    (e: any) => {
                      setFirstName(e.target.value);
                      setIsDirty(true);
                    },
                    true,
                    !isEditable
                  )}
                </Col>
                <Col md={6}>
                  {formGroupInput(
                    'lastName',
                    'Last Name',
                    'text',
                    lastName,
                    (e: any) => {
                      setLastName(e.target.value);
                      setIsDirty(true);
                    },
                    true,
                    !isEditable
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  {formGroupInput(
                    'email',
                    'Email',
                    'text',
                    email,
                    (e: any) => {
                      setEmail(e.target.value);
                      setIsDirty(true);
                    },
                    true,
                    !isEditable
                  )}
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for={'role'}>
                      Role <span className='text-required'> (required)</span>
                    </Label>
                    <Select
                      name={'role'}
                      options={roles}
                      value={roles.find((r: any) => r.val === role)}
                      onChange={(value: any) => {
                        if (value?.val === 'SupportWorker') {
                          setOfficeId(OFFICE_SUPPORT_WORKER);
                        } else if (
                          value?.val === 'SuperAdmin' ||
                          value?.val === 'Management' ||
                          value?.val === 'Reporting'
                        ) {
                          setOfficeId(OFFICE_INTAKE);
                        } else if (value?.val !== 'SupportWorker' && role === 'SupportWorker') {
                          setOfficeId('');
                        }
                        setRole(value?.val || '');
                        setIsDirty(true);
                      }}
                      getOptionValue={(option: any) => option.val}
                      getOptionLabel={(option: any) => option.display}
                      isClearable={true}
                      isDisabled={!isEditable}
                    />
                    <FormFeedback>This field is mandatory</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              {role !== 'SupportWorker' && (
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for={'officeId'}>
                        Office <span className='text-required'> (required)</span>
                      </Label>
                      <Select
                        styles={dropdownSelectStyles}
                        name={'officeId'}
                        options={offices}
                        value={offices.find((o: IOffice) => o.officeId === officeId)}
                        onChange={(value: any) => {
                          setOfficeId(value?.officeId || '');
                          setIsDirty(true);
                        }}
                        getOptionValue={(option: IOffice) => option.officeId}
                        getOptionLabel={(option: IOffice) => option.name}
                        isClearable={true}
                        isDisabled={
                          (!!params.userId && role === 'Lawyer') ||
                          !isEditable ||
                          role === 'SuperAdmin' ||
                          role === 'Management' ||
                          role === 'Reporting'
                        }
                      />
                      <FormFeedback>This field is mandatory</FormFeedback>
                    </FormGroup>
                  </Col>
                  {params.userId && myUserContext.isSuperAdmin && role === 'Lawyer' && (
                    <Col md={6}>
                      <Button style={{ marginTop: '27px' }} onClick={() => setOpenTransferLawOffice(true)}>
                        Transfer Law Office
                      </Button>
                    </Col>
                  )}
                </Row>
              )}
              {role === 'SupportWorker' && (
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for={'supportWorkerTypeId'}>
                        Support Worker Type <span className='text-required'> (required)</span>
                      </Label>
                      <Select
                        styles={dropdownSelectStyles}
                        name={'supportWorkerTypeId'}
                        options={supportWorkerTypes}
                        value={supportWorkerTypes.find(
                          (swt: ISupportWorkerType) => swt.supportWorkerTypeId === supportWorkerTypeId
                        )}
                        onChange={(value: any) => {
                          setSupportWorkerTypeId(value?.supportWorkerTypeId || '');
                          setIsDirty(true);
                        }}
                        getOptionValue={(option: ISupportWorkerType) => option.supportWorkerTypeId}
                        getOptionLabel={(option: ISupportWorkerType) => option.name}
                        isClearable={true}
                        isDisabled={!isEditable}
                      />
                      {trySubmit && (supportWorkerTypeId === undefined || supportWorkerTypeId === '') && (
                        <span
                          style={{
                            fontSize: 13,
                            color: '#B22222',
                          }}
                        >
                          This field is mandatory
                        </span>
                      )}
                      <FormFeedback>This field is mandatory</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={6}>
                  {formGroupInputCheckbox(
                    'active',
                    'Active',
                    'checkbox',
                    active,
                    (e: any) => {
                      setActive(!active);
                      setIsDirty(true);
                    },
                    !isEditable
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </form>
      <Modal isOpen={openTransferLawOffice}>
        <ModalHeader>Transfer Law Office</ModalHeader>
        <ModalBody>
          Select the office to transfer to.
          <Select
            name={'officeId'}
            options={offices.filter((o) => o.officeId !== officeId)}
            defaultValue={offices.find((o: IOffice) => o.officeId === officeId)}
            value={offices.find((o: IOffice) => o.officeId === newOfficeId)}
            onChange={(value: any) => {
              setNewOfficeId(value?.officeId || '');
              setIsDirty(true);
            }}
            getOptionValue={(option: IOffice) => option.officeId}
            getOptionLabel={(option: IOffice) => option.name}
            isClearable={true}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            onClick={() => {
              if (newOfficeId !== '') {
                transferOffices(newOfficeId);
              }
            }}
          >
            Confirm
          </Button>
          <Button color='secondary' onClick={() => setOpenTransferLawOffice(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
