import * as React from 'react';
import { useContext } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormFeedback, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { FileContext, IDutyCounselAppearance } from './FileContext';
import Select, { components, OptionProps, SingleValueProps } from 'react-select';
import DatePicker from 'react-datepicker';
import { IUser } from '../Users/UsersTable';
import { DomainContext, IDutyCounselCourtType, IOffice } from '../../contexts/DomainContext';
import { PeopleContext } from '../../contexts/PeopleContext';
import { lawyerSelectValue } from '../../formatters/PeopleFormatter';
import { setHours, setMinutes } from 'date-fns';

export const DutyCounselAppearances: React.FC<{ style?: React.CSSProperties; trySubmit?: boolean }> = (props) => {
  const domainContext = useContext(DomainContext);
  const dutyCounselCourtTypes = domainContext.dutyCounselCourtTypes;
  const peopleContext = useContext(PeopleContext);
  const fileContext = useContext(FileContext);
  const file = fileContext.file;

  const dutyCounselAppearances = fileContext.dutyCounselAppearances || [];

  const LawyerOption = (props: OptionProps<IUser, boolean>) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <div>{lawyerSelectValue(data, domainContext.offices)}</div>
      </components.Option>
    );
  };

  const LawyerSingleValue = (props: SingleValueProps<IUser>) => {
    const { data } = props;
    return <components.SingleValue {...props}>{lawyerSelectValue(data, domainContext.offices)}</components.SingleValue>;
  };

  const disabledSelectStyles = {
    control: (styles: any, state: any) => {
      return {
        ...styles,
        borderColor: '#ced4da',
      };
    },
    singleValue: (styles: any, state: any) => {
      return {
        ...styles,
        color: '#495057',
      };
    },
  };

  return (
    <Card style={props.style}>
      <CardHeader className={'d-flex align-items-center'}>
        Duty Counsel Appearances
        <div className={'card-header__actions'}>
          {!file.isLockedDown && (
            <Button
              color={'primary'}
              className='btn-sm'
              onClick={() => {
                fileContext.setDutyCounselAppearances((s) => [
                  ...s,
                  {
                    dutyCounselAppearanceId: Math.random().toString(),
                    dutyCounselAppearanceDate: undefined,
                    lawyerId: '',
                  } as IDutyCounselAppearance,
                ]);
              }}
            >
              New Duty Counsel Appearance
            </Button>
          )}
        </div>
      </CardHeader>
      <CardBody>
        {dutyCounselAppearances.length > 0 ? (
          <Row className={'pl-4'}>
            <Col sm={4} lg={4}>
              Date
            </Col>
            <Col sm={3} lg={3}>
              Court Type
            </Col>
            <Col sm={4} lg={4}>
              Lawyer
            </Col>

            <Col className={'third-party-x-button'} />
          </Row>
        ) : (
          <span>There are no Duty Counsel Appearances.</span>
        )}
        <ListGroup>
          {dutyCounselAppearances.map((i: IDutyCounselAppearance, index) => (
            <ListGroupItem key={i.dutyCounselAppearanceId}>
              <Row>
                <Col sm={4} md={4} lg={4}>
                  <DatePicker
                    disabled={file.isLockedDown}
                    selected={i.dutyCounselAppearanceDate ? new Date(i.dutyCounselAppearanceDate) : undefined}
                    onChange={(date) => {
                      if (date) {
                        const dutyCounselAppearanceDate = date.getTime();
                        fileContext.setDutyCounselAppearances((s) => {
                          return [
                            ...s.slice(0, index),
                            {
                              ...s[index],
                              dutyCounselAppearanceDate,
                            } as IDutyCounselAppearance,
                            ...s.slice(index + 1),
                          ];
                        });
                      }
                    }}
                    minTime={setHours(setMinutes(new Date(), 0), 9)}
                    maxTime={setHours(setMinutes(new Date(), 0), 17)}
                    minDate={i.dutyCounselAppearanceDate ? undefined : new Date(new Date().getFullYear() - 2, 0, 1)}
                    maxDate={i.dutyCounselAppearanceDate ? undefined : new Date(new Date().getFullYear() + 2, 11, 31)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dateFormat='MMM dd, yyyy hh:mm aa'
                    showTimeSelect={true}
                    dropdownMode='select'
                    className='date-picker rounded mb-0'
                    popperClassName={'react-datepicker__time-min-width'}
                    strictParsing={true}
                    placeholderText={'MMM dd, yyyy hh:mm aa'}
                  />
                  {props.trySubmit && !i.dutyCounselAppearanceDate && (
                    <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                  )}
                  <FormFeedback>This field is mandatory</FormFeedback>
                </Col>
                <Col sm={3} md={3} lg={3}>
                  <Select
                    styles={disabledSelectStyles}
                    name={'dutyCounselCourtTypeId'}
                    options={dutyCounselCourtTypes}
                    value={dutyCounselCourtTypes.find(
                      (ft: IDutyCounselCourtType) => ft.dutyCounselCourtTypeId === i.dutyCounselCourtTypeId
                    )}
                    onChange={(value: any) => {
                      const courtType = value?.dutyCounselCourtTypeId || '';
                      fileContext.setDutyCounselAppearances((s) => {
                        return [
                          ...s.slice(0, index),
                          {
                            ...s[index],
                            dutyCounselCourtTypeId: courtType,
                          } as IDutyCounselAppearance,
                          ...s.slice(index + 1),
                        ];
                      });
                    }}
                    getOptionValue={(option: IDutyCounselCourtType) => option.dutyCounselCourtTypeId}
                    getOptionLabel={(option: IDutyCounselCourtType) => option.name}
                    isClearable={true}
                    isDisabled={file.isLockedDown}
                  />
                  {props.trySubmit && !i.dutyCounselCourtTypeId && (
                    <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                  )}
                </Col>
                <Col sm={4} md={4} lg={4}>
                  <Select
                    isDisabled={file.isLockedDown}
                    name={'lawyerId'}
                    options={
                      peopleContext.lawyers
                        .filter((e) => e.active)
                        .sort((a: IUser, b: IUser) =>
                          a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase())
                        ) as IUser[]
                    }
                    value={peopleContext.lawyers.find((u: IUser) => u.userId === i.lawyerId)}
                    onChange={(value: any) => {
                      const lawyerId = value?.userId || '';
                      fileContext.setDutyCounselAppearances((s) => {
                        return [
                          ...s.slice(0, index),
                          {
                            ...s[index],
                            lawyerId,
                          } as IDutyCounselAppearance,
                          ...s.slice(index + 1),
                        ];
                      });
                    }}
                    getOptionValue={(option: IUser) => option.userId}
                    getOptionLabel={(option: IUser) =>
                      option.lastName.toUpperCase() +
                      ', ' +
                      option.firstName +
                      ' (' +
                      domainContext.offices.find((o: IOffice) => o.officeId === option.officeId)?.name +
                      ')'
                    }
                    components={{ SingleValue: LawyerSingleValue, Option: LawyerOption }}
                    styles={{
                      singleValue: (base) => ({
                        ...base,
                        position: 'relative',
                        top: 0,
                        transform: 'translateY(0)',
                        height: '100%',
                        padding: '0.25em 0',
                      }),
                    }}
                    isClearable={true}
                  />
                  {props.trySubmit && !i.lawyerId && (
                    <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                  )}
                </Col>
                <Col className={'third-party-x-button'}>
                  {!file.isLockedDown && (
                    <button
                      className='close pt-1'
                      onClick={() => {
                        fileContext.setDutyCounselAppearances((f) => [...f.slice(0, index), ...f.slice(index + 1)]);
                      }}
                    >
                      &times;
                    </button>
                  )}
                </Col>
              </Row>
            </ListGroupItem>
          ))}
        </ListGroup>
      </CardBody>
    </Card>
  );
};
