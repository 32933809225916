import { useContext, useEffect, useState } from 'react';
import { authAxios } from '../../services/AxiosService';
import { Button, Col, Container, Navbar, NavbarBrand, Row } from 'reactstrap';
import { FaPrint } from 'react-icons/fa';
import * as React from 'react';
import {
  APPLICATION_TYPE_ID_ADULT_CRIMINAL,
  APPLICATION_TYPE_ID_CIVIL_FAMILY,
  APPLICATION_TYPE_ID_CIVIL_OTHER,
  DomainContext,
  FILE_TYPE_DUTY_COUNSEL,
} from '../../contexts/DomainContext';
import { formatDate } from '../../formatters/DateTimeFormatter';

interface IBoardReportData {
  applicationType: string;
  fileType: string;
  staffLawyerCount: number;
  privateLawyerCount: number;
  total: number;
}

export const BoardReport = () => {
  const domainContext = useContext(DomainContext);
  const filteredApplicationTypes = domainContext.applicationTypes.filter(
    (a) =>
      a.applicationTypeId !== APPLICATION_TYPE_ID_ADULT_CRIMINAL &&
      a.applicationTypeId !== APPLICATION_TYPE_ID_CIVIL_FAMILY &&
      a.applicationTypeId !== APPLICATION_TYPE_ID_CIVIL_OTHER
  );
  const filteredFileTypes = domainContext.fileTypes.filter((f) => f.fileTypeId !== FILE_TYPE_DUTY_COUNSEL);

  const [boardReportData, setBoardReportData] = useState<IBoardReportData[]>([]);

  useEffect(() => {
    authAxios.get(`/api/reports/board-report`).then((response) => {
      setBoardReportData(response.data);
    });
  }, []);

  let totalFilesCount = 0;
  boardReportData.forEach((d) => {
    totalFilesCount += d.total;
  });

  const getPrivateLawyerCount = (data: IBoardReportData | undefined) => {
    return data ? data.privateLawyerCount : 0;
  };

  const getStaffLawyerCount = (data: IBoardReportData | undefined) => {
    return data ? data.staffLawyerCount : 0;
  };

  const getTotal = (data: IBoardReportData | undefined) => {
    return data ? data.total : 0;
  };

  const getTotalPrivateLawyerCount = (data: IBoardReportData[]) => {
    let totalPrivateLawyerCount = 0;
    data.forEach((d) => {
      totalPrivateLawyerCount += d.privateLawyerCount;
    });
    return totalPrivateLawyerCount;
  };

  const getTotalStaffLawyerCount = (data: IBoardReportData[]) => {
    let totalStaffLawyerCount = 0;
    data.forEach((d) => {
      totalStaffLawyerCount += d.staffLawyerCount;
    });
    return totalStaffLawyerCount;
  };

  const getCumulativeTotal = (data: IBoardReportData[]) => {
    let cumulativeTotal = 0;
    data.forEach((d) => {
      cumulativeTotal += d.total;
    });
    return cumulativeTotal;
  };

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
        <Row className={'flex-fill'}>
          <Col md={3} xl={2}>
            <NavbarBrand>Board Report</NavbarBrand>
          </Col>
          <Col className={'d-flex'}>
            <Button className={'ml-auto'} color={'warning'} onClick={() => window.print()}>
              Print <FaPrint />
            </Button>
          </Col>
        </Row>
      </Navbar>
      <Container className={'container--no-margin-print'}>
        <Row className={'my-5 '}>
          <Col className={'no-print'}>
            <p className={'text-muted'}>
              *This report includes all files currently in the Assigned To Lawyer stage that do not have the Duty
              Counsel file type.
            </p>
          </Col>
        </Row>
        <div className={'report my-5 mx-3'}>
          <header className={'report-header'}>
            <h2 className={'text-center'}>Board Report</h2>
            <h3 className={'text-center h5 text-muted'}>
              Total files in table: {totalFilesCount} on {formatDate(new Date())}
            </h3>
          </header>
          <table className={'report1'}>
            <thead>
              <tr>
                <th className={'headerspan'} colSpan={2} />
                <th className={'header'} colSpan={3}>
                  Private Lawyer
                </th>
                <th className={'header'} colSpan={3}>
                  Staff Lawyer
                </th>
                <th className={'header'} colSpan={3}>
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredFileTypes.map((ft, index) => {
                return (
                  <tr key={'Adult_Criminal_' + ft.fileTypeId.toString()}>
                    {index === 0 && (
                      <th className={'headerspan'} rowSpan={filteredFileTypes.length + 1}>
                        Adult Criminal
                      </th>
                    )}
                    <th className={'header-side'}>{ft.name}</th>
                    <td className={'report-row'} colSpan={3}>
                      {getPrivateLawyerCount(
                        boardReportData.find(
                          (b) =>
                            b.applicationType === APPLICATION_TYPE_ID_ADULT_CRIMINAL && b.fileType === ft.fileTypeId
                        )
                      )}
                    </td>
                    <td className={'report-row'} colSpan={3}>
                      {getStaffLawyerCount(
                        boardReportData.find(
                          (b) =>
                            b.applicationType === APPLICATION_TYPE_ID_ADULT_CRIMINAL && b.fileType === ft.fileTypeId
                        )
                      )}
                    </td>
                    <td className={'total'} colSpan={3}>
                      <b>
                        {getTotal(
                          boardReportData.find(
                            (b) =>
                              b.applicationType === APPLICATION_TYPE_ID_ADULT_CRIMINAL && b.fileType === ft.fileTypeId
                          )
                        )}
                      </b>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <th className={'header-side'}>Total Criminal</th>
                <td className={'total'} colSpan={3}>
                  <b>
                    {getTotalPrivateLawyerCount(
                      boardReportData.filter((b) => b.applicationType === APPLICATION_TYPE_ID_ADULT_CRIMINAL)
                    )}
                  </b>
                </td>
                <td className={'total'} colSpan={3}>
                  <b>
                    {getTotalStaffLawyerCount(
                      boardReportData.filter((b) => b.applicationType === APPLICATION_TYPE_ID_ADULT_CRIMINAL)
                    )}
                  </b>
                </td>
                <td className={'total'} colSpan={3}>
                  <b>
                    {getCumulativeTotal(
                      boardReportData.filter((b) => b.applicationType === APPLICATION_TYPE_ID_ADULT_CRIMINAL)
                    )}
                  </b>
                </td>
              </tr>
              {filteredFileTypes.map((ft, index) => {
                return (
                  <tr key={'Civil_Family_Ties_' + ft.fileTypeId.toString()}>
                    {index === 0 && (
                      <th className={'headerspan'} rowSpan={filteredFileTypes.length + 1}>
                        Civil Family
                      </th>
                    )}
                    <th className={'header-side'}>{ft.name}</th>
                    <td className={'report-row'} colSpan={3}>
                      {getPrivateLawyerCount(
                        boardReportData.find(
                          (b) => b.applicationType === APPLICATION_TYPE_ID_CIVIL_FAMILY && b.fileType === ft.fileTypeId
                        )
                      )}
                    </td>
                    <td className={'report-row'} colSpan={3}>
                      {getStaffLawyerCount(
                        boardReportData.find(
                          (b) => b.applicationType === APPLICATION_TYPE_ID_CIVIL_FAMILY && b.fileType === ft.fileTypeId
                        )
                      )}
                    </td>
                    <td className={'total'} colSpan={3}>
                      <b>
                        {getTotal(
                          boardReportData.find(
                            (b) =>
                              b.applicationType === APPLICATION_TYPE_ID_CIVIL_FAMILY && b.fileType === ft.fileTypeId
                          )
                        )}
                      </b>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <th className={'header-side'}>Total Family</th>
                <td className={'total'} colSpan={3}>
                  <b>
                    {getTotalPrivateLawyerCount(
                      boardReportData.filter((b) => b.applicationType === APPLICATION_TYPE_ID_CIVIL_FAMILY)
                    )}
                  </b>
                </td>
                <td className={'total'} colSpan={3}>
                  <b>
                    {getTotalStaffLawyerCount(
                      boardReportData.filter((b) => b.applicationType === APPLICATION_TYPE_ID_CIVIL_FAMILY)
                    )}
                  </b>
                </td>
                <td className={'total'} colSpan={3}>
                  <b>
                    {getCumulativeTotal(
                      boardReportData.filter((b) => b.applicationType === APPLICATION_TYPE_ID_CIVIL_FAMILY)
                    )}
                  </b>
                </td>
              </tr>
              {filteredApplicationTypes.map((a, index) => {
                return (
                  <tr key={a.applicationTypeId.toString()}>
                    {/*{index === 0 && <th className={'headerspan'} rowSpan={filteredApplicationTypes.length + 1}></th>}*/}
                    <th className={'headerspan'} colSpan={2}>
                      {a.name}
                    </th>
                    <td className={'report-row'} colSpan={3}>
                      {getPrivateLawyerCount(boardReportData.find((b) => b.applicationType === a.applicationTypeId))}
                    </td>
                    <td className={'report-row'} colSpan={3}>
                      {getStaffLawyerCount(boardReportData.find((b) => b.applicationType === a.applicationTypeId))}
                    </td>
                    <td className={'total'} colSpan={3}>
                      <b>{getTotal(boardReportData.find((b) => b.applicationType === a.applicationTypeId))}</b>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <th className={'header-side'} colSpan={2}>
                  Total Files
                </th>
                <td className={'total'} colSpan={3}>
                  <b>{getTotalPrivateLawyerCount(boardReportData)}</b>
                </td>
                <td className={'total'} colSpan={3}>
                  <b>{getTotalStaffLawyerCount(boardReportData)}</b>
                </td>
                <td className={'total'} colSpan={3}>
                  <b>{getCumulativeTotal(boardReportData)}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Container>
    </div>
  );
};
