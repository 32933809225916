import * as React from 'react';
import { useContext, useEffect } from 'react';
import * as AuthenticationService from '../../services/AuthenticationService';
import { AUTH_ACTION_SIGN_OUT, AuthContext } from '../../contexts/AuthContext';

export const SignOut: React.FC = () => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    AuthenticationService.removeJwtFromStorage();
    authContext.dispatch(AUTH_ACTION_SIGN_OUT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>Signing Out</div>;
};
