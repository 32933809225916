import { Card, CardHeader, CustomInput } from 'reactstrap';
import { FileContext, IFile } from './FileContext';
import {
  DomainContext,
  IRelationshipType,
  STAGE_CANCELLED_COUNSEL_REQUEST,
  STAGE_LAWYER_REQUESTED,
} from '../../contexts/DomainContext';
import { IUser } from '../Users/UsersTable';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { authAxios } from '../../services/AxiosService';
import { IIndividual } from '../Individuals/IndividualContext';
import { PeopleContext } from '../../contexts/PeopleContext';
import { clientSelectValue, lawyerSelectValue } from '../../formatters/PeopleFormatter';
import { Link } from 'react-router-dom';
import { IndividualsContext } from '../../contexts/IndividualsContext';
import { MyUserContext } from '../../contexts/MyUserContext';

interface IConflictCheckIndividual extends IIndividual {
  relationship: string | undefined;
}

interface IRelationshipFile {
  file: IFile;
  relationship: string | undefined;
}

interface IConflictIndividualFiles {
  individualId: string;
  files: IRelationshipFile[];
}

export const FileConflictCheck = () => {
  const domainContext = useContext(DomainContext);
  const peopleContext = useContext(PeopleContext);
  const individualsContext = useContext(IndividualsContext);
  const fileContext = useContext(FileContext);
  const myUserContext = useContext(MyUserContext);

  const relationships = [
    ...domainContext.relationshipTypes,
    { relationshipTypeId: 'Client', name: 'Client' } as IRelationshipType,
  ];

  const file = fileContext.file;

  const [individualFilesList, setIndividualFilesList] = useState<IConflictIndividualFiles[]>([]);
  const [clientOnly, setClientOnly] = useState<boolean>(!!myUserContext.isIntakeOffice);

  const userIdList = [
    { individualId: file.clientId, relationshipTypeId: 'Client' },
    ...fileContext.thirdParties.map((e) => ({
      individualId: e.individualId,
      relationshipTypeId: e.relationshipTypeId,
    })),
  ];

  useEffect(() => {
    //TODO this list is a copy of the above on, but causes tonne of calls is you use the above for some reason
    const list = [
      { individualId: file.clientId, relationshipTypeId: 'Client' },
      ...fileContext.thirdParties.map((e) => ({
        individualId: e.individualId,
        relationshipTypeId: e.relationshipTypeId,
      })),
    ];
    list.map((e) =>
      authAxios.get('/api/files/' + e.individualId + '/conflict-check-files/' + file.fileId).then((response) => {
        const userFiles: IRelationshipFile[] = response.data;
        const filteredFiles: IRelationshipFile[] = userFiles.filter(
          (f: IRelationshipFile) => f.file.fileId !== file.fileId && f.file.stageId !== STAGE_CANCELLED_COUNSEL_REQUEST
        );
        setIndividualFilesList((m: IConflictIndividualFiles[]) => [
          ...m,
          { individualId: e.individualId, files: filteredFiles },
        ]);
      })
    );
  }, [file.fileId, file.clientId, fileContext.thirdParties]);

  const individualList = userIdList
    .map((e) => {
      const individual: IIndividual | undefined = individualsContext.state.individuals.find(
        (i: IIndividual) => i.individualId === e.individualId
      );
      const relationship = relationships.find(
        (rt: IRelationshipType) => rt.relationshipTypeId === e.relationshipTypeId
      );

      if (individual) {
        return { ...individual, relationship: relationship?.name } as IConflictCheckIndividual;
      } else {
        return {} as IConflictCheckIndividual;
      }
    })
    .filter((e) => e.individualId);

  return (
    <div>
      <Card className={'mb-3'}>
        <CardHeader>
          <div className='d-flex'>
            <span>Individual History</span>

            <div className='ml-auto'>
              <CustomInput
                type='checkbox'
                id={'clientOnly'}
                name={'clientOnly'}
                label={'Show Client Relationship Only?'}
                checked={clientOnly}
                onChange={() => {
                  setClientOnly((s) => !s);
                }}
              />
            </div>
          </div>
        </CardHeader>
        <table className='table'>
          <thead>
            <tr>
              <th className={'pl-3'}>Name</th>
              <th>Relationship</th>
              <th colSpan={2}>File</th>
              <th>Lawyer</th>
            </tr>
          </thead>
          <tbody>
            {individualList.map((conflictUser: IConflictCheckIndividual) => {
              const individualFiles = (
                individualFilesList.find(
                  (userFile: IConflictIndividualFiles) => userFile.individualId === conflictUser.individualId
                )?.files || []
              )
                .filter((e) => !clientOnly || e.relationship?.includes('Client'))
                .sort((a: IRelationshipFile, b: IRelationshipFile) => {
                  if (a.file.fileNumber && b.file.fileNumber) {
                    if (a.file.fileNumber === b.file.fileNumber) {
                      return 0;
                    } else if (a.file.fileNumber < b.file.fileNumber) {
                      return 1;
                    } else if (a.file.fileNumber > b.file.fileNumber) {
                      return -1;
                    } else {
                      return 0;
                    }
                  } else if (a.file.fileNumber && !b.file.fileNumber) {
                    return 1;
                  } else if (!a.file.fileNumber && b.file.fileNumber) {
                    return -1;
                  } else {
                    return 0;
                  }
                });

              if (individualFiles.length === 0) {
                return (
                  <tr key={conflictUser.individualId}>
                    <td>{clientSelectValue(conflictUser)}</td>
                    <td>{conflictUser.relationship}</td>
                    <td colSpan={3}>No Other Associated Files</td>
                  </tr>
                );
              } else {
                return individualFiles.map((relationshipFile, i) => {
                  const fileLawyer = peopleContext.lawyers.find(
                    (l: IUser) => l.userId === relationshipFile.file.lawyerId
                  );
                  const first = i === 0;
                  return (
                    <tr key={`${conflictUser.individualId}_${relationshipFile.file.fileId}`}>
                      <td style={!first ? { borderTop: 0, paddingTop: 0 } : {}}>
                        {first ? clientSelectValue(conflictUser) : null}
                      </td>
                      <td style={!first ? { borderTop: 0, paddingTop: 0 } : {}}>{relationshipFile.relationship}</td>
                      <td style={!first ? { borderTop: 0, paddingTop: 0 } : {}}>
                        {myUserContext.isIntakeOffice ? (
                          <Link
                            className={'p-0 m-0 font-weight-bold border-0'}
                            to={'/files/' + relationshipFile.file.fileId}
                          >
                            {relationshipFile.file.fileNumber}
                          </Link>
                        ) : (
                          <span> {relationshipFile.file.fileNumber}</span>
                        )}
                        {relationshipFile.file.stageId === STAGE_LAWYER_REQUESTED && (
                          <span className='text-required' style={{ marginLeft: 5 }}>
                            (Requested)
                          </span>
                        )}
                      </td>
                      <td style={!first ? { borderTop: 0, paddingTop: 0 } : {}}>
                        {
                          domainContext.applicationTypes.find(
                            (e) => e.applicationTypeId === relationshipFile.file.applicationTypeId
                          )?.name
                        }
                      </td>
                      <td style={!first ? { borderTop: 0, paddingTop: 0 } : {}}>
                        {fileLawyer ? lawyerSelectValue(fileLawyer, domainContext.offices) : <b>No Assigned Lawyer</b>}
                      </td>
                    </tr>
                  );
                });
              }
            })}
          </tbody>
        </table>
      </Card>
    </div>
  );
};
