import { Button, Card, CardBody, CardHeader, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import * as React from 'react';
import { useContext, useState } from 'react';
import {
  CHARGE_OUTCOME_INCOMPLETE,
  OFFICE_PRIVATE_LAWYER,
  STAGE_ASSIGNED_TO_LAWYER,
  STAGE_CLOSED,
} from '../../../contexts/DomainContext';
import { authAxios } from '../../../services/AxiosService';
import { notifyError, notifySuccess } from '../../../services/ToastService';
import { useParams } from 'react-router';
import { FileContext } from '../FileContext';
import { formatDate } from '../../../formatters/DateTimeFormatter';
import { ConfirmModal } from '../../ConfirmModal/ConfirmModal';
import { useCloseFile } from '../../../hooks/useCloseFile';
import { PeopleContext } from '../../../contexts/PeopleContext';

export const CloseFile = () => {
  const params = useParams<{ fileId: string }>();
  const fileContext = useContext(FileContext);
  const peopleContext = useContext(PeopleContext);
  const setFile = fileContext.setFile;

  const [closedDate, setClosedDate] = useState<Date | undefined>();
  const [tryClose, setTryClose] = useState(false);
  const [modal, setModal] = useState(false);
  const [reopenModal, setReopenModal] = useState(false);
  const [openAddHoursModal, setOpenAddHoursModal] = useState(false);
  const [openIncompleteFindingsModal, setOpenIncompleteFindingsModal] = useState(false);

  const requirementsMet = () => {
    const hasAnIncompleteOutcome = fileContext.charges
      .map((c) => c.outcomes.includes(CHARGE_OUTCOME_INCOMPLETE))
      .some((charge) => charge);
    return (
      !hasAnIncompleteOutcome ||
      (hasAnIncompleteOutcome && fileContext.chargeFindings !== undefined && fileContext.chargeFindings !== '')
    );
  };

  const closeFileFn = useCloseFile();
  const closeFile = () => {
    if (!requirementsMet()) {
      setOpenIncompleteFindingsModal(true);
    } else {
      if (closedDate && params.fileId) {
        closeFileFn(params.fileId, closedDate);
      } else {
        setTryClose(true);
      }
    }
  };

  const updateStage = (stageId: string) => {
    if (params.fileId) {
      const data = { fileId: params.fileId, stageId };
      authAxios.post('/api/files/admin/start-stage', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('File stage has been updated.');
          setFile((f) => ({ ...f, stageId: stageId }));
        } else {
          notifyError(response.data.status);
        }
      });
    }
  };

  return (
    <>
      <Card className={'bg-primary-light'}>
        <CardHeader>Close File</CardHeader>
        <CardBody className={'pt-2'}>
          {fileContext.file.stageId === STAGE_CLOSED ? (
            <>
              <Label>Closed Date</Label>
              <div className={'div--read-only'}>
                {fileContext.file.closedDate ? formatDate(new Date(fileContext.file.closedDate)) : ' '}
              </div>
              <Button
                color='success'
                className={'mt-3 ml-auto'}
                onClick={() => setReopenModal(true)}
                disabled={fileContext.file.isLockedDown}
              >
                Reopen File
              </Button>
            </>
          ) : (
            <>
              <>
                <Label style={{ fontWeight: 'initial' }}>Closed Date</Label>
                <DatePicker
                  selected={closedDate}
                  onChange={(date) => {
                    if (date) {
                      setClosedDate(date);
                    }
                  }}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dateFormat='MMM dd, yyyy'
                  dropdownMode='select'
                  className='date-picker rounded mb-0'
                  placeholderText={'Select...'}
                />
                {tryClose && closedDate === undefined && (
                  <span style={{ fontSize: 13, color: '#B22222' }} className={'mandatory-field'}>
                    This field is mandatory
                  </span>
                )}
              </>
              <Row>
                <Button
                  color={'success'}
                  className={'mt-3 ml-3'}
                  onClick={() => {
                    const lawyerOfficeId = peopleContext.lawyers.find(
                      (u) => u.userId === fileContext.file.lawyerId
                    )?.officeId;
                    if (!requirementsMet()) {
                      setOpenIncompleteFindingsModal(true);
                    } else {
                      if (fileContext.hoursRecords.length > 0 || lawyerOfficeId === OFFICE_PRIVATE_LAWYER) {
                        if (closedDate) {
                          setModal(true);
                        } else {
                          setTryClose(true);
                        }
                      } else {
                        setOpenAddHoursModal(true);
                      }
                    }
                  }}
                >
                  Close File
                </Button>
              </Row>
            </>
          )}
        </CardBody>
      </Card>{' '}
      <ConfirmModal
        isOpen={modal}
        title={'Close File'}
        onConfirm={() => {
          setModal(false);
          closeFile();
        }}
        onCancel={() => setModal(false)}
        confirmButton={'Close File'}
      >
        <div>Are you sure you want to close this file?</div>
      </ConfirmModal>
      <ConfirmModal
        isOpen={reopenModal}
        title={'Reopen File'}
        onConfirm={() => {
          setReopenModal(false);
          updateStage(STAGE_ASSIGNED_TO_LAWYER);
          setClosedDate(undefined);
        }}
        onCancel={() => setReopenModal(false)}
        confirmButton={'Reopen File'}
      >
        <div>
          Re-opening this file will move it back to the assigned stage. Are you sure that you want to re-open this file?
        </div>
      </ConfirmModal>
      <Modal isOpen={openAddHoursModal}>
        <ModalHeader
          toggle={() => {
            setOpenAddHoursModal(!openAddHoursModal);
          }}
        >
          Please add lawyer hours
        </ModalHeader>
        <ModalBody>Before a file can be closed, ensure that there is at least one record for Lawyer Hours.</ModalBody>
        <ModalFooter>
          <Button color='success' onClick={() => setOpenAddHoursModal(false)}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={openIncompleteFindingsModal}>
        <ModalHeader
          toggle={() => {
            setOpenIncompleteFindingsModal(!openIncompleteFindingsModal);
          }}
        >
          Please fill in Findings field for charges / matters
        </ModalHeader>
        <ModalBody>
          There is an incomplete outcome for this file, you must enter an explanation in the Findings field before you
          can close.
        </ModalBody>
        <ModalFooter>
          <Button color='success' onClick={() => setOpenIncompleteFindingsModal(false)}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
