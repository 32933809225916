import * as H from 'history';
import * as React from 'react';
import { Link, Redirect, Route, useParams } from 'react-router-dom';
import { Container, Nav, NavItem, NavLink } from 'reactstrap';

import { authAxios } from '../../services/AxiosService';
import { useContext, useEffect } from 'react';
import { IndividualDetails } from './IndividualDetails';
import { IndividualContext } from './IndividualContext';
import { IndividualFiles } from './IndividualFiles';
import { IndividualActivityLog } from './IndividualActivityLog';
import { useWindowDimensions } from '../../hooks/WindowHooks';
import { MyUserContext } from '../../contexts/MyUserContext';
import { IndividualRejectionHistory } from './IndividualRejectionHistory';

export const Individual = () => {
  const individualContext = useContext(IndividualContext);
  const individual = individualContext.individual;
  const setIndividual = individualContext.setIndividual;
  const params = useParams<{ individualId: string; tab?: string }>();
  const individualId = params.individualId;
  const { mobile: verticalMenu } = useWindowDimensions();
  const myUserContext = useContext(MyUserContext);

  useEffect(() => {
    authAxios.get('/api/individuals/' + individualId).then((response) => {
      const individual = response.data;
      setIndividual(individual);
    });
  }, [individualId, setIndividual]);

  const tab: string = params.tab || 'details';

  return (
    <article className='page'>
      <header className='page-header'>
        <Container fluid={true}>
          {individual !== undefined && (
            <div className='d-flex flex-column py-3'>
              <h1 className='page-header__title order-2'>
                {individual.firstName && individual.lastName ? (
                  <span>{individual.lastName.toUpperCase() + ', ' + individual.firstName}</span>
                ) : (
                  <span>
                    {[individual.firstName, individual.middleName, individual.lastName].filter((e) => !!e).join(' ')}
                  </span>
                )}
              </h1>
            </div>
          )}

          <div className={verticalMenu ? 'd-block d-md-none pb-4 d-print-none' : 'd-none d-md-block d-print-none'}>
            <Nav tabs={!verticalMenu} pills={verticalMenu} vertical={verticalMenu}>
              {(myUserContext.isIntakeOffice || myUserContext.isManagement) && (
                <NavItemLink
                  to={`/individuals/${params.individualId}/details`}
                  active={tab === 'details'}
                  name={'Details'}
                />
              )}
              <NavItemLink
                to={'/individuals/' + params.individualId + '/associated-files'}
                active={tab === 'associated-files'}
                name={'Associated Files'}
              />
              {(myUserContext.isIntakeOffice || myUserContext.isManagement) && (
                <NavItemLink
                  to={'/individuals/' + params.individualId + '/rejection-history'}
                  active={tab === 'rejection-history'}
                  name={'Rejection History'}
                />
              )}
              {(myUserContext.isIntakeOffice || myUserContext.isManagement) && (
                <NavItemLink
                  to={'/individuals/' + params.individualId + '/activity-log'}
                  active={tab === 'activity-log'}
                  name={'Activity Log'}
                />
              )}
            </Nav>
          </div>
        </Container>
      </header>
      {myUserContext.isIntakeOffice || myUserContext.isManagement ? (
        <Route exact={true} path='/individuals/:individualId/(details)?'>
          <IndividualDetails allowCreateFile={myUserContext.isIntakeOffice} readOnly={myUserContext.isManagement} />
        </Route>
      ) : (
        <Route exact={true} path='/individuals/:individualId/(details)?'>
          <Redirect to={`/individuals/${individualId}/associated-files`} />
        </Route>
      )}
      <Route exact={true} path='/individuals/:individualId/associated-files'>
        <IndividualFiles />
      </Route>
      {(myUserContext.isIntakeOffice || myUserContext.isManagement) && (
        <Route exact={true} path='/individuals/:individualId/rejection-history'>
          <IndividualRejectionHistory individualId={individualId} />
        </Route>
      )}
      {(myUserContext.isIntakeOffice || myUserContext.isManagement) && (
        <Route exact={true} path='/individuals/:individualId/activity-log'>
          <IndividualActivityLog />
        </Route>
      )}
    </article>
  );
};

const NavItemLink: React.FC<{ to: H.LocationDescriptor; active: boolean; name: string }> = (props) => {
  return (
    <NavItem>
      <NavLink tag={Link} to={props.to} active={props.active}>
        {props.name}
      </NavLink>
    </NavItem>
  );
};
