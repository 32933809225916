import { Card, CardHeader } from 'reactstrap';
import { FileContext } from './FileContext';
import * as React from 'react';
import { useContext } from 'react';

export const FileCounselRequestRejectedReasons = () => {
  const fileContext = useContext(FileContext);

  return (
    <div>
      <Card className={'mb-3'}>
        <CardHeader>
          <div className='d-flex'>
            <span>Counsel Request Rejection History</span>
          </div>
        </CardHeader>
        <table className='table'>
          <thead>
            <tr>
              <th className={'pl-3'}>Reasons</th>
            </tr>
          </thead>
          <tbody>
            {fileContext.file.reasonCounselRequestRejected &&
              fileContext.file.reasonCounselRequestRejected.map((reason: string, index: number) => {
                return (
                  <tr key={`${index}`}>
                    <td>{reason}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </Card>
    </div>
  );
};
