import LoadingService from '../Loading/LoadingService';
import * as ToastService from '../../services/ToastService';
import { authAxios } from '../../services/AxiosService';

// TODO ? return Promise<IDocument>
export const uploadFile = (file: File, url: string): Promise<any> => {
  const formData = new FormData();
  formData.append('file', file);
  const loadingKey = LoadingService.add('Uploading ' + file.name + '... 0%');
  const options = {
    onUploadProgress: (progressEvent: any) => {
      const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
      LoadingService.setText('Uploading ' + file.name + '... ' + percentCompleted + '%');
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  const promise = authAxios.put(url, formData, options);
  promise
    .then((response: any) => {
      console.log('File Upload result: ', response);
      ToastService.notifySuccess('Uploaded File: ' + file.name);
      LoadingService.remove(loadingKey);
    })
    .catch((error) => {
      console.log('File Upload failed: ', error);
      ToastService.notifySuccess('File Upload Failed: ' + file.name);
      LoadingService.remove(loadingKey);
    });
  return promise;
};
