import * as React from 'react';
import { useState } from 'react';
import { Button, ButtonGroup, Col, Dropdown, DropdownMenu, DropdownToggle, FormGroup, Label, Row } from 'reactstrap';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import ReactDatePicker from 'react-datepicker';
import { formatDate } from '../../formatters/DateTimeFormatter';

interface IProps {
  children?: any;
  startDate?: Date;
  endDate?: Date;
  setRange: (dates: { startDate?: Date; endDate?: Date }) => void;
  keyPrefix: string;
}

export const DateRangeDropdown: React.FC<IProps> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [custom, setCustom] = useState<boolean>(false);
  const [customStart, setCustomStart] = useState<Date | null>(props.startDate ? props.startDate : new Date());
  const [customEnd, setCustomEnd] = useState<Date | null>(props.endDate ? props.endDate : new Date());

  const handleToday = () => {
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);
    props.setRange({ startDate, endDate });
  };

  const handleYesterday = () => {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 1);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setDate(endDate.getDate() - 1);
    endDate.setHours(23, 59, 59, 999);
    props.setRange({ startDate, endDate });
  };

  const handleLast7Days = () => {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);
    props.setRange({ startDate, endDate });
  };

  const handleLast30Days = () => {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 30);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);
    props.setRange({ startDate, endDate });
  };

  /* const handleThisMonth = () => {
    const startDate = new Date();
    startDate.setDate(1);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 1, 1);
    endDate.setDate(endDate.getDate() - 1);
    endDate.setHours(23, 59, 59, 999);
    props.setRange({ startDate, endDate });
  };

  const handleLastMonth = () => {
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1, 1);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setDate(1);
    endDate.setDate(endDate.getDate() - 1);
    endDate.setHours(23, 59, 59, 999);
    props.setRange({ startDate, endDate });
  };*/

  const allDates = () => {
    props.setRange({ startDate: undefined, endDate: undefined });
  };

  const handleCustomRange = () => {
    if (customStart && customEnd) {
      customStart.setHours(0, 0, 0, 0);
      customEnd.setHours(23, 59, 59, 999);
      props.setRange({ startDate: customStart, endDate: customEnd });
    } else {
      console.log('Custom values are not defined.');
    }
  };

  return (
    <Dropdown isOpen={open} toggle={() => setOpen(!open)} style={{ width: '100%' }} key={props.keyPrefix}>
      <DropdownToggle
        caret={true}
        className={'d-flex align-items-center justify-content-between w-100 text-left'}
        outline={!props.startDate || !props.endDate}
        size={'sm'}
      >
        {props.startDate && props.endDate
          ? formatDate(props.startDate) + ' - ' + formatDate(props.endDate)
          : 'Show All'}
      </DropdownToggle>
      <DropdownMenu className={'p-3'} style={{ width: '300px' }}>
        <ButtonGroup className={'round-buttons'} vertical={true} style={{ width: '100%' }}>
          <Button
            color={'secondary'}
            className={'mb-1'}
            key={props.keyPrefix + '_all'}
            size={'sm'}
            onClick={() => {
              allDates();
              setCustom(false);
              setOpen(!open);
            }}
          >
            Show All
          </Button>
          <Button
            color={'secondary'}
            className={'mb-1'}
            key={props.keyPrefix + '_today'}
            size={'sm'}
            onClick={() => {
              handleToday();
              setCustom(false);
              setOpen(!open);
            }}
          >
            Today
          </Button>
          <Button
            color={'secondary'}
            className={'mb-1'}
            key={props.keyPrefix + '_yesterday'}
            size={'sm'}
            onClick={() => {
              handleYesterday();
              setCustom(false);
              setOpen(!open);
            }}
          >
            Yesterday
          </Button>
          <Button
            color={'secondary'}
            className={'mb-1'}
            key={props.keyPrefix + '_last_7'}
            size={'sm'}
            onClick={() => {
              handleLast7Days();
              setCustom(false);
              setOpen(!open);
            }}
          >
            Last 7 Days
          </Button>
          <Button
            color={'secondary'}
            className={'mb-1'}
            key={props.keyPrefix + '_last_30'}
            size={'sm'}
            onClick={() => {
              handleLast30Days();
              setCustom(false);
              setOpen(!open);
            }}
          >
            Last 30 Days
          </Button>
          {/*
          <Button
            color={'secondary'}
            className={'mb-1'}
            key={props.keyPrefix + '_this_month'}
            onClick={() => {
              handleThisMonth();
              setCustom(false);
              setOpen(!open);
            }}
          >
            This Month
          </Button>
          <Button
            color={'secondary'}
            className={'mb-1'}
            key={props.keyPrefix + '_last_month'}
            onClick={() => {
              handleLastMonth();
              setCustom(false);
              setOpen(!open);
            }}
          >
            Last Month
          </Button>
          */}
          <Button
            color={'secondary'}
            className={'mb-1'}
            key={props.keyPrefix + '_custom'}
            size={'sm'}
            onClick={() => setCustom(!custom)}
          >
            Custom Range {custom ? <FaCaretUp className={'pb-1'} /> : <FaCaretDown className={'pb-1'} />}
          </Button>
        </ButtonGroup>
        {custom && (
          <FormGroup className={'mb-0 mt-2'}>
            <Row>
              <Col>
                <Label style={{ fontWeight: 400 }}>From:</Label>
                <ReactDatePicker
                  dateFormat={'yyyy-MM-dd'}
                  onChange={(date) => {
                    setCustomStart(date);
                  }}
                  selected={customStart}
                  className='date-picker__small date-picker rounded mb-0'
                  popperPlacement={'left-end'}
                />
              </Col>
              <Col>
                <Label style={{ fontWeight: 400 }}>To:</Label>
                <ReactDatePicker
                  dateFormat={'yyyy-MM-dd'}
                  onChange={(date) => {
                    setCustomEnd(date);
                  }}
                  selected={customEnd}
                  className='date-picker__small date-picker rounded mb-0'
                  popperPlacement={'left-end'}
                />
              </Col>
            </Row>
            <Button
              color={'primary'}
              className={'mt-3'}
              onClick={() => {
                handleCustomRange();
                setOpen(!open);
              }}
              style={{ width: '100%' }}
              size={'sm'}
            >
              Apply
            </Button>
          </FormGroup>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};
