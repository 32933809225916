import * as React from 'react';
import { useContext, useState } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import Select, { components, OptionProps, SingleValueProps } from 'react-select';
import { IUser } from '../../Users/UsersTable';
import {
  DomainContext,
  IOffice,
  OFFICE_PRIVATE_LAWYER,
  STAGE_ASSIGNED_TO_LAWYER,
  STAGE_LAWYER_REQUESTED,
} from '../../../contexts/DomainContext';
import { authAxios } from '../../../services/AxiosService';
import { notifyError, notifySuccess } from '../../../services/ToastService';
import { useParams } from 'react-router';
import { FileContext } from '../FileContext';
import { lawyerSelectValue } from '../../../formatters/PeopleFormatter';
import { PeopleContext } from '../../../contexts/PeopleContext';
import { ConfirmModal } from '../../ConfirmModal/ConfirmModal';

export const SelectLawyerActions: React.FC = () => {
  const params = useParams<{ fileId: string }>();
  const fileContext = useContext(FileContext);
  const setFile = fileContext.setFile;
  const domainContext = useContext(DomainContext);
  const offices = domainContext.offices;
  const peopleContext = useContext(PeopleContext);
  const [selectModal, setSelectModal] = useState(false);
  const [selectingPrivateLawyer, setSelectingPrivateLawyer] = useState(false);
  const [lawyerId, setLawyerId] = useState(fileContext.file.lawyerId ? fileContext.file.lawyerId : '');

  const saveLawyerAndOffice = () => {
    const data = { fileId: params.fileId, lawyerId };
    authAxios.post('/api/files/admin/update-lawyer', data).then((response) => {
      if (response.data.status === 'OK') {
        notifySuccess('Lawyer Requested.');
        setFile((f) => ({
          ...f,
          lawyerId: lawyerId,
          stageId: STAGE_LAWYER_REQUESTED,
        }));
      } else {
        notifyError(response.data.errorMessage);
      }
    });
  };

  const assignLawyerAndAccept = () => {
    const data = { fileId: params.fileId, lawyerId };
    authAxios.post('/api/files/admin/update-to-private-lawyer', data).then((response) => {
      if (response.data.status === 'OK') {
        notifySuccess('Lawyer Assigned.');
        setFile((f) => ({
          ...f,
          lawyerId: lawyerId,
          stageId: STAGE_ASSIGNED_TO_LAWYER,
        }));
      } else {
        notifyError(response.data.errorMessage);
      }
    });
  };

  const LawyerOption = (props: OptionProps<IUser, boolean>) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <div>{lawyerSelectValue(data, domainContext.offices)}</div>
      </components.Option>
    );
  };

  const LawyerSingleValue = (props: SingleValueProps<IUser>) => {
    const { data } = props;
    return <components.SingleValue {...props}>{lawyerSelectValue(data, domainContext.offices)}</components.SingleValue>;
  };

  return (
    <>
      <Card className={'bg-primary-light mb-3'}>
        <CardHeader>Select Lawyer</CardHeader>
        <CardBody className={'d-flex flex-column'}>
          <Select
            name={'lawyerId'}
            className={'mb-3'}
            options={
              peopleContext.lawyers
                .filter((e) => e.active)
                .sort((a: IUser, b: IUser) =>
                  a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase())
                ) as IUser[]
            }
            value={peopleContext.lawyers.find((i: IUser) => i.userId === lawyerId)}
            onChange={(value: any) => {
              setLawyerId(value?.userId || '');
              if (value ? value.officeId === OFFICE_PRIVATE_LAWYER : false) {
                setSelectingPrivateLawyer(true);
              } else {
                setSelectingPrivateLawyer(false);
              }
            }}
            getOptionValue={(option: IUser) => option.userId}
            getOptionLabel={(option: IUser) =>
              option.lastName.toUpperCase() +
              ', ' +
              option.firstName +
              ' (' +
              offices.find((o: IOffice) => o.officeId === option.officeId)?.name +
              ')'
            }
            components={{ SingleValue: LawyerSingleValue, Option: LawyerOption }}
            styles={{
              singleValue: (base) => ({
                ...base,
                position: 'relative',
                top: 0,
                transform: 'translateY(0)',
                height: '100%',
                padding: '0.25em 0',
              }),
              menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
            }}
            isClearable={true}
            menuPlacement={'auto'}
          />
          <Button
            color='success'
            className={'ml-auto'}
            onClick={() => {
              setSelectModal(true);
            }}
            disabled={!lawyerId}
          >
            {selectingPrivateLawyer ? 'Approve & Assign' : 'Assign'}
          </Button>
        </CardBody>
      </Card>
      <ConfirmModal
        isOpen={selectModal}
        title={'Select Lawyer'}
        onConfirm={() => {
          setSelectModal(false);
          if (selectingPrivateLawyer) {
            assignLawyerAndAccept();
          } else {
            saveLawyerAndOffice();
          }
        }}
        onCancel={() => setSelectModal(false)}
        confirmButton={selectingPrivateLawyer ? 'Approve & Assign' : 'Assign'}
      >
        <div>Are you sure you want to assign this file?</div>
      </ConfirmModal>
    </>
  );
};
