import * as React from 'react';
import { useState } from 'react';
import { formatDateForServer } from '../../formatters/DateTimeFormatter';

export interface INonClientFilePost {
  fileId?: string;
  stageId: string;
  description: string;
  submittedDate: string;
  officeId?: string;
  fileDate: string;
  expiryDate: string;
  circuitLocationId?: string;
  lawyerId?: string;
}

export interface IFileMeta {
  noteCount: number;
}

export interface INonClientFile {
  fileId: string;
  stageId: string;
  fileTypeId: string;
  submittedDate: string;
  fileDate: number;
  fileDateFormatted: string;
  expiryDate: number;
  lawOfficeNumber: number;
  officeId: string;
  description?: string;
  circuitLocationId?: string;
  closedDate?: number;
  lawyerId?: string;
}

type ContextType = {
  file: INonClientFile;
  setFile: React.Dispatch<React.SetStateAction<INonClientFile>>;
  fileMeta: IFileMeta;
  setFileMeta: React.Dispatch<React.SetStateAction<IFileMeta>>;
  setFileAndAttributes: (file: INonClientFile) => void;
  getNonClientFileCreateData: () => INonClientFilePost;
  getNonClientFileUpdateData: () => INonClientFilePost;

  fileDate: Date | undefined;
  setFileDate: React.Dispatch<React.SetStateAction<Date | undefined>>;

  expiryDate: Date | undefined;
  setExpiryDate: React.Dispatch<React.SetStateAction<Date | undefined>>;

  fileTypeId: string | undefined;
  setFileTypeId: React.Dispatch<React.SetStateAction<string | undefined>>;

  description: string | undefined;
  setDescription: React.Dispatch<React.SetStateAction<string | undefined>>;

  lawOfficeNumber: number | undefined;
  setLawOfficeNumber: React.Dispatch<React.SetStateAction<number | undefined>>;

  lawyerId: string | undefined;
  setLawyerId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const NonClientFileContext = React.createContext<ContextType>({
  file: {} as INonClientFile,
  setFile: () => {},
  fileMeta: {} as IFileMeta,
  setFileMeta: () => {},
  setFileAndAttributes: () => {},
  getNonClientFileCreateData: () => ({} as INonClientFilePost),
  getNonClientFileUpdateData: () => ({} as INonClientFilePost),

  fileDate: undefined,
  setFileDate: () => {},

  expiryDate: undefined,
  setExpiryDate: () => {},

  fileTypeId: undefined,
  setFileTypeId: () => {},

  description: undefined,
  setDescription: () => {},

  lawOfficeNumber: undefined,
  setLawOfficeNumber: () => {},

  lawyerId: undefined,
  setLawyerId: () => {},
});

export const NonClientFileProvider = (props: any) => {
  let expireDate = new Date();
  expireDate.setMonth(expireDate.getMonth() + 6);
  const [file, setFile] = useState({} as INonClientFile);
  const [fileMeta, setFileMeta] = useState({} as IFileMeta);
  const [fileDate, setFileDate] = useState<Date | undefined>(new Date());
  const [expiryDate, setExpiryDate] = useState<Date | undefined>(expireDate);
  const [lawOfficeNumber, setLawOfficeNumber] = useState<number | undefined>();

  const [fileTypeId, setFileTypeId] = useState<string | undefined>(undefined);

  const [description, setDescription] = useState<string | undefined>();
  const [lawyerId, setLawyerId] = useState<string | undefined>();

  const setFileAndAttributes = (pFile: INonClientFile) => {
    setFile(pFile);
    setFileTypeId(pFile.fileTypeId);
    setDescription(pFile.description);
    setLawOfficeNumber(pFile.lawOfficeNumber);
    setFileDate(pFile.fileDate ? new Date(pFile.fileDate) : undefined);
    if (pFile.expiryDate) {
      setExpiryDate(new Date(pFile.expiryDate.toString().replace(/-/g, '/') + ' 12:00'));
    } else {
      const expireDate = pFile.fileDate ? new Date(pFile.fileDate) : new Date();
      expireDate.setMonth(expireDate.getMonth() + 6);
      setExpiryDate(expireDate);
    }
    setLawyerId(pFile.lawyerId);
  };

  const getNonClientFileCreateData = (): INonClientFilePost => {
    const data = {
      fileDate: formatDateForServer(fileDate),
      circuitLocationId: file.circuitLocationId,
      expiryDate: formatDateForServer(expiryDate),
      description,
      lawyerId: lawyerId,
    };
    return data as INonClientFilePost;
  };

  const getNonClientFileUpdateData = (): INonClientFilePost => {
    return { fileId: file.fileId, ...getNonClientFileCreateData() };
  };

  return (
    <NonClientFileContext.Provider
      value={{
        file,
        setFile,
        fileMeta,
        setFileMeta,
        setFileAndAttributes,
        getNonClientFileCreateData,
        getNonClientFileUpdateData,
        fileDate: fileDate,
        setFileDate: setFileDate,
        expiryDate,
        setExpiryDate,

        fileTypeId,
        setFileTypeId,

        description,
        setDescription,
        lawOfficeNumber,
        setLawOfficeNumber,
        lawyerId,
        setLawyerId,
      }}
    >
      {props.children}
    </NonClientFileContext.Provider>
  );
};

export const NonClientFileConsumer = NonClientFileContext.Consumer;
